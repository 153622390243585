import { useState, useContext, FC, FormEvent, useEffect } from 'react';
import clsx from 'clsx';
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import {
  Grid,
  Typography,
  Box,
  Divider,
  Button,
  Card,
  Container,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';

import RemoveParentModal from './RemoveParentModal';
import { TextField } from '../../../components/fields';
import Dropdown from '../../../components/Dropdown';
import Loader from '../../../components/Loader';
import AccountMenu from '../../../components/AccountMenu';
import FooterNew from '../../../components/layout/FooterNew';
import CustomAlert from '../../../components/CustomAlert';

import { AppContext } from '../../../contexts';
import { TOKEN, PARENT_TOKEN } from '../../../constants';
import AddIcon from '../../../img/add-icon.svg';
import Colors from '../../../common/colors';
import { useStyles } from './styles';

import { UPDATE_OR_CREATE_PARENT } from '../clientEnrollment/gql';
import { StudentParentsQuery } from './__generated__/StudentParentsQuery';
import { ConfirmUserQuery } from '../../main/account/__generated__/ConfirmUserQuery';
import {
  ImpersonateMutation,
  ImpersonateMutationVariables,
} from '../../../components/__generated__/ImpersonateMutation';
import { UpdateOrdCreateParent } from '../clientEnrollment/gql/__generated__/UpdateOrdCreateParent';
import { UpdateOrCreateChild } from './__generated__/UpdateOrCreateChild';
import SnackbarComponent from '../../../common/SnackBar';
import SpreadMenu from '../../../components/Menu';
import { regexNoSpace } from './ProfilePage';
import { cjConfig } from '../../../common/utility';

type Color = 'success' | 'info' | 'warning' | 'error';

export const STUDENT_PARENTS_QUERY = gql`
  query StudentParentsQuery {
    studentParents {
      id
      createdAt
      updatedAt
      firstName
      pictureUrl
      lastName
      phoneNumber
      isPremium
      highSchool
      referralSource
      paymentBy
      premiumPackageType
      isActive
      rank
      email
      pictureUrl
      gpa
      sat
      act
      minor
      major
      linkedInUrk
      stripeConnectAccount
      linkedInUrl
      isPasswordSet
    }
  }
`;

export const UPDATE_OR_CREATE_CHILD = gql`
  mutation UpdateOrCreateChild(
    $email: String
    $firstName: String
    $id: Int
    $lastName: String
    $highSchoolYear: String
    $phoneNumber: String
    $userToken: String
    $isAccountSettings: Boolean
    $isCheckout: Boolean
    $page: String
  ) {
    updateOrCreateChild(
      input: {
        lastName: $lastName
        email: $email
        firstName: $firstName
        highSchoolYear: $highSchoolYear
        phoneNumber: $phoneNumber
        id: $id
        userToken: $userToken
        isAccountSettings: $isAccountSettings
        isCheckout: $isCheckout
        page: $page
      }
    ) {
      success
      message
      user {
        id
        userToken
      }
    }
  }
`;

const CONFIRM_USER_QUERY = gql`
  query ConfirmUserQuery($email: String) {
    confirmUser(input: { email: $email }) {
      isAlreadyStudentParent
      isStudent
      studentRegistered
      parentId
    }
  }
`;

export const IMPERSONATE_MUTATION = gql`
  mutation ImpersonateMutation($studentId: Int!) {
    impersonate(input: { studentId: $studentId }) {
      status
      token
    }
  }
`;

const inviteInitialValues = {
  email: '',
  firstName: '',
  lastName: '',
  highSchoolYear: '',
};
const currentdate = new Date();
const currentYear = currentdate.getFullYear();
const temporaryArray = [1, 2, 3, 4, 5, 6];
const YEARS_ARRAY: any = [];
temporaryArray.map((val) => {
  YEARS_ARRAY.push({ label: val + currentYear, value: val + currentYear });
});
YEARS_ARRAY.push({ label: currentYear, value: currentYear });
YEARS_ARRAY.push({ label: 'Other', value: 'Other' });

const InviteFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(
      regexNoSpace,
      'Name may only contain letters, commas, apostrophes, and dashes.'
    )
    .required('Required')
    .nullable(),
  lastName: Yup.string()
    .matches(
      regexNoSpace,
      'Name may only contain letters, commas, apostrophes, and dashes.'
    )
    .required('Required')
    .nullable(),
  email: Yup.string()
    .required('Required')
    .nullable()
    .email('Email must be a valid email'),
});

const ParentAccounts: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();

  const {
    tableMainContainer,
    tableHead,
    tableCell,
    parentEmailInvite,
    dropdownClass,
    billingTable,
    parentEmailGutter,
  } = useStyles();

  const [variant, setVariant] = useState('');
  const [message, setMessage] = useState('');
  const [confirmUserResult, setConfirmUserResult] = useState<any>();
  const { user } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [deleteParentId, setDeleteParentId] = useState();
  const [snackOpen, setSnackOpen] = useState<boolean>(false);
  const [severity, setSeverity] = useState<Color | undefined>('info');
  const [snackMessage, setSnackMessage] = useState<string>('');
  const [yearValue, setYearValue] = useState('');
  const [openParentForm, setOpenParentForm] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const addNewUser = queryParams.get('add');

  useEffect(() => {
    if (addNewUser) {
      setOpenParentForm(true);
    }
    cjConfig({ userId: user?.id });
  }, []);

  if (!user) return null;
  const { isParent } = user;

  function toggleAlert(variant?: string, message?: string) {
    if (variant && message) window.scrollTo(0, 0);
    setVariant(variant || '');
    setMessage(message || '');
  }
  const handleParentFormOpen = () => {
    setOpenParentForm(!openParentForm);
  };
  const toggleModal = () => {
    setOpen(!open);
  };

  const performDeleteAction = (id: any) => {
    setDeleteParentId(id);
    toggleModal();
  };

  const handleClose = () => {
    setSnackOpen(false);
    setSnackMessage('');
  };

  const invitaionFields = (
    name: string,
    label: string,
    setFieldValue: Function,
    errors: any,
    touched: any
  ) => (
    <Box>
      <Typography variant="body2" className={classes.texfieldLabels}>
        {label}
      </Typography>
      <Field
        label={false}
        name={name}
        component={TextField}
        placeholder={label}
        variant="outlined"
        fullWidth
        onChange={(e: FormEvent<HTMLInputElement>) => {
          const {
            currentTarget: { value },
          } = e;
          setFieldValue(name, value);
        }}
        className={
          errors[name] && touched[name]
            ? `inputError ${parentEmailInvite}`
            : parentEmailInvite
        }
      />
    </Box>
  );

  return (
    <>
      <Container maxWidth={false}>
        <Box className={classes.mainContainer}>
          <Box className={classes.myAccountHeader}>
            <Typography variant="h3">My Account</Typography>
          </Box>

          <CustomAlert
            variant={variant}
            message={message}
            toggleAlert={toggleAlert}
          />

          <Grid container spacing={3}>
            <Grid item lg={3} md={4} sm={12} xs={12}>
              <AccountMenu />
            </Grid>

            <Grid item lg={9} md={8} sm={12} xs={12}>
              <Card className={classes.cardTitle}>
                <Box className="info-fields">
                  <Typography variant="h4">
                    {!isParent
                      ? 'Parent/Guardian Accounts'
                      : 'Student Accounts'}
                    <Typography
                      className="subtitleParentAccount"
                      variant="body1"
                    >
                      {isParent
                        ? 'Please select a student account to enter.'
                        : 'Please add any parents or guardians that should have access to your account.'}
                    </Typography>
                  </Typography>

                  <Divider></Divider>
                  <Query<StudentParentsQuery>
                    query={STUDENT_PARENTS_QUERY}
                    fetchPolicy="network-only"
                  >
                    {({ data, loading, refetch, networkStatus }) => {
                      if (loading && networkStatus !== 4) return <Loader isSmallLoader={true} isTransparent={true} forceLoaderText={true} />;
                      if (!data || !data.studentParents) {
                        return <Box fontSize={16}>No Parent Found!</Box>;
                      }

                      const { studentParents } = data;

                      return (
                        <>
                          <Box
                            className={`${tableMainContainer} ${billingTable}`}
                          >
                            <RemoveParentModal
                              id={deleteParentId}
                              open={open}
                              onClose={toggleModal}
                              refetch={refetch}
                            />
                            <Grid md={12} xs={12} lg={12} sm={12}>
                              <Hidden only={['xl', 'lg', 'md']}>
                                <Box>
                                  {studentParents &&
                                    studentParents.map((parent: any) => {
                                      const {
                                        id,
                                        firstName,
                                        lastName,
                                        email,
                                        isPasswordSet,
                                      } = parent;

                                      return (
                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          alignItems="flex-start"
                                          className={
                                            classes.mobileContentDivider
                                          }
                                        >
                                          <Box
                                            className={
                                              classes.mobileContentGuardians
                                            }
                                          >
                                            <Typography
                                              variant="h6"
                                              className="name"
                                            >
                                              {firstName || '--'}{' '}
                                              {lastName || '--'}
                                            </Typography>

                                            <Typography
                                              variant="body1"
                                              className="email"
                                            >
                                              {email}
                                            </Typography>

                                            <Typography
                                              variant="body1"
                                              className="email"
                                            >
                                              Status: Active User
                                            </Typography>

                                            {isParent && (
                                              <Grid>
                                                <Mutation<
                                                  ImpersonateMutation,
                                                  ImpersonateMutationVariables
                                                >
                                                  mutation={
                                                    IMPERSONATE_MUTATION
                                                  }
                                                >
                                                  {(
                                                    impersonateMutation: Function
                                                  ) => (
                                                    <>
                                                      {false &&
                                                        !isPasswordSet && (
                                                          <Box display="inline-block">
                                                            <Typography variant="body2">
                                                              Pending approval
                                                            </Typography>
                                                          </Box>
                                                        )}
                                                      {
                                                        <Box
                                                          display="inline-block"
                                                          color={
                                                            Colors.BLUE_SEVEN
                                                          }
                                                          // className={
                                                          //   classes.profileLink
                                                          // }
                                                        >
                                                          <Button
                                                            // disabled={
                                                            //   !isPasswordSet
                                                            //     ? true
                                                            //     : false
                                                            // }
                                                            onClick={async () => {
                                                              try {
                                                                const result =
                                                                  await impersonateMutation(
                                                                    {
                                                                      variables:
                                                                        {
                                                                          studentId:
                                                                            id,
                                                                        },
                                                                    }
                                                                  );

                                                                const {
                                                                  data: {
                                                                    impersonate:
                                                                      {
                                                                        token,
                                                                        status,
                                                                      },
                                                                  },
                                                                } = result;
                                                                if (
                                                                  status ===
                                                                  true
                                                                ) {
                                                                  if (
                                                                    user.isParent ===
                                                                    true
                                                                  ) {
                                                                    const oldToken =
                                                                      await localStorage.getItem(
                                                                        TOKEN
                                                                      );
                                                                    oldToken &&
                                                                      (await localStorage.setItem(
                                                                        PARENT_TOKEN,
                                                                        oldToken
                                                                      ));
                                                                  }
                                                                  await localStorage.setItem(
                                                                    TOKEN,
                                                                    token
                                                                  );
                                                                  window.location.pathname =
                                                                    '/home';
                                                                  // setIsLoggedIn(true);
                                                                }
                                                              } catch (error) {
                                                                console.log(
                                                                  'error: ',
                                                                  error
                                                                );
                                                              }
                                                            }}
                                                            className={
                                                              classes.selectButton
                                                            }
                                                            variant="outlined"
                                                          >
                                                            Select
                                                          </Button>
                                                        </Box>
                                                      }
                                                    </>
                                                  )}
                                                </Mutation>
                                              </Grid>
                                            )}
                                          </Box>

                                          {!isParent && (
                                            <SpreadMenu
                                              handleDelete={() =>
                                                performDeleteAction(id)
                                              }
                                            ></SpreadMenu>
                                          )}
                                        </Box>
                                      );
                                    })}
                                </Box>
                              </Hidden>

                              <Hidden only={['sm', 'xs']}>
                                <Table className={classes.billingTableWrapper}>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className={tableHead}>
                                        <Typography variant="h6">
                                          First Name
                                        </Typography>
                                      </TableCell>
                                      <TableCell className={tableHead}>
                                        <Typography variant="h6">
                                          {' '}
                                          Last Name
                                        </Typography>
                                      </TableCell>
                                      <TableCell className={tableHead}>
                                        <Typography variant="h6">
                                          {' '}
                                          Email
                                        </Typography>
                                      </TableCell>
                                      <TableCell className={tableHead}>
                                        <Typography variant="h6">
                                          Status
                                        </Typography>
                                      </TableCell>
                                      <TableCell className={tableHead}>
                                        <Typography variant="h6">
                                          Action
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {studentParents &&
                                      studentParents.map((parent: any) => {
                                        const {
                                          id,
                                          firstName,
                                          lastName,
                                          email,
                                          isPasswordSet,
                                        } = parent;

                                        return (
                                          <TableRow
                                            key={id}
                                            className={classes.billingTableRow}
                                          >
                                            <TableCell className={tableCell}>
                                              <Typography variant="body2">
                                                {firstName || '--'}
                                              </Typography>
                                            </TableCell>

                                            <TableCell className={tableCell}>
                                              <Typography variant="body2">
                                                {lastName || '--'}
                                              </Typography>
                                            </TableCell>
                                            <TableCell className={tableCell}>
                                              <Typography variant="body2">
                                                {email}
                                              </Typography>
                                            </TableCell>
                                            <TableCell className={tableCell}>
                                              <Typography variant="body2">
                                                Active User
                                                {/* {isPasswordSet
                                                  ? 'Active User'
                                                  : 'Invite Sent'} */}
                                              </Typography>
                                            </TableCell>
                                            {isParent && (
                                              <TableCell className={tableCell}>
                                                <Mutation<
                                                  ImpersonateMutation,
                                                  ImpersonateMutationVariables
                                                >
                                                  mutation={
                                                    IMPERSONATE_MUTATION
                                                  }
                                                >
                                                  {(
                                                    impersonateMutation: Function
                                                  ) => (
                                                    <>
                                                      {false &&
                                                        !isPasswordSet && (
                                                          <Box display="inline-block">
                                                            <Typography variant="body2">
                                                              Pending approval
                                                            </Typography>
                                                          </Box>
                                                        )}
                                                      {
                                                        <Box>
                                                          <Button
                                                            // disabled={
                                                            //   !isPasswordSet
                                                            //     ? true
                                                            //     : false
                                                            // }
                                                            className={
                                                              classes.selectButton
                                                            }
                                                            onClick={async () => {
                                                              try {
                                                                const result =
                                                                  await impersonateMutation(
                                                                    {
                                                                      variables:
                                                                        {
                                                                          studentId:
                                                                            id,
                                                                        },
                                                                    }
                                                                  );

                                                                const {
                                                                  data: {
                                                                    impersonate:
                                                                      {
                                                                        token,
                                                                        status,
                                                                      },
                                                                  },
                                                                } = result;
                                                                if (
                                                                  status ===
                                                                  true
                                                                ) {
                                                                  if (
                                                                    user.isParent ===
                                                                    true
                                                                  ) {
                                                                    const oldToken =
                                                                      await localStorage.getItem(
                                                                        TOKEN
                                                                      );
                                                                    oldToken &&
                                                                      (await localStorage.setItem(
                                                                        PARENT_TOKEN,
                                                                        oldToken
                                                                      ));
                                                                  }
                                                                  await localStorage.setItem(
                                                                    TOKEN,
                                                                    token
                                                                  );
                                                                  window.location.pathname =
                                                                    '/home';
                                                                  // setIsLoggedIn(true);
                                                                }
                                                              } catch (error) {
                                                                console.log(
                                                                  'error: ',
                                                                  error
                                                                );
                                                              }
                                                            }}
                                                            variant="outlined"
                                                          >
                                                            Select
                                                          </Button>
                                                        </Box>
                                                      }
                                                    </>
                                                  )}
                                                </Mutation>
                                              </TableCell>
                                            )}
                                            {!isParent && (
                                              <TableCell className={tableCell}>
                                                <SpreadMenu
                                                  handleDelete={() =>
                                                    performDeleteAction(id)
                                                  }
                                                ></SpreadMenu>
                                              </TableCell>
                                            )}
                                          </TableRow>
                                        );
                                      })}
                                  </TableBody>
                                </Table>
                              </Hidden>
                            </Grid>
                          </Box>

                          <Box className={classes.addStudentSpacing}>
                            <Grid md={12} xs={12} lg={12} sm={12}>
                              <Button
                                variant="text"
                                color="primary"
                                startIcon={<img src={AddIcon} alt="Add"></img>}
                                onClick={handleParentFormOpen}
                              >
                                ADD NEW{' '}
                                {isParent ? 'STUDENT' : 'PARENT/GUARDIAN'}{' '}
                                ACCOUNT
                              </Button>
                              {openParentForm && (
                                <Mutation<UpdateOrdCreateParent>
                                  mutation={UPDATE_OR_CREATE_PARENT}
                                >
                                  {(updateOrCreateParent) => {
                                    return (
                                      <Mutation<UpdateOrCreateChild>
                                        mutation={UPDATE_OR_CREATE_CHILD}
                                      >
                                        {(updateOrCreateChild: Function) => {
                                          let triedSubmit = false;
                                          return (
                                            <Formik
                                              initialValues={
                                                inviteInitialValues
                                              }
                                              validationSchema={
                                                InviteFormSchema
                                              }
                                              onSubmit={async (
                                                variables,
                                                { setSubmitting, resetForm }
                                              ) => {
                                                try {
                                                  triedSubmit = true;
                                                  setSubmitting(true);
                                                  if (isParent) {
                                                    if (
                                                      !variables.highSchoolYear
                                                    ) {
                                                      return;
                                                    }
                                                    await updateOrCreateChild({
                                                      variables: {
                                                        ...variables,
                                                        isAccountSettings: true,
                                                      },
                                                    });

                                                    setSnackMessage(
                                                      'Invitation sent successfully'
                                                    );
                                                    setSeverity('success');
                                                    setSnackOpen(true);
                                                    await refetch();
                                                    setYearValue('');
                                                    resetForm();
                                                    setOpenParentForm(false);
                                                  } else {
                                                    await updateOrCreateParent({
                                                      variables: {
                                                        ...variables,
                                                        isAccountSettings: true,
                                                      },
                                                    });

                                                    setSnackMessage(
                                                      'Invitation sent successfully'
                                                    );
                                                    setSnackOpen(true);
                                                    setSeverity('success');
                                                    await refetch();
                                                    setYearValue('');
                                                    resetForm();
                                                    setOpenParentForm(false);
                                                  }
                                                } catch (error) {
                                                  setSnackMessage(
                                                    error?.message ||
                                                      'An error occured'
                                                  );
                                                  setSnackOpen(true);
                                                  setSeverity('error');
                                                }

                                                setSubmitting(false);
                                              }}
                                            >
                                              {({
                                                isSubmitting,
                                                values,
                                                setFieldValue,
                                                touched,
                                                errors,
                                              }) => {
                                                return (
                                                  <Form
                                                    className={
                                                      classes.parentEmailBox
                                                    }
                                                  >
                                                    <Box pt={2}>
                                                      <Grid>
                                                        <Grid
                                                          container
                                                          spacing={2}
                                                        >
                                                          <Grid
                                                            xs={12}
                                                            sm={6}
                                                            md={4}
                                                            lg={4}
                                                            item
                                                          >
                                                            {invitaionFields(
                                                              'firstName',
                                                              'First Name',
                                                              setFieldValue,
                                                              errors,
                                                              touched
                                                            )}
                                                          </Grid>
                                                          <Grid
                                                            xs={12}
                                                            sm={6}
                                                            md={4}
                                                            lg={4}
                                                            item
                                                          >
                                                            {invitaionFields(
                                                              'lastName',

                                                              'Last Name',
                                                              setFieldValue,
                                                              errors,
                                                              touched
                                                            )}
                                                          </Grid>
                                                        </Grid>

                                                        <Grid
                                                          container
                                                          spacing={2}
                                                        >
                                                          {isParent && (
                                                            <Grid
                                                              xs={12}
                                                              sm={12}
                                                              md={8}
                                                              lg={4}
                                                              item
                                                            >
                                                              <Box
                                                                className={`${parentEmailInvite} ${dropdownClass}`}
                                                                textAlign="left"
                                                              >
                                                                <Dropdown
                                                                  label={`High School Grad Year`}
                                                                  errorValue={`Required`}
                                                                  selectedValue={
                                                                    yearValue ||
                                                                    ''
                                                                  }
                                                                  options={
                                                                    YEARS_ARRAY
                                                                  }
                                                                  isError={
                                                                    triedSubmit &&
                                                                    yearValue ===
                                                                      ''
                                                                  }
                                                                  name="highSchoolYear"
                                                                  onSelect={(
                                                                    evt: any
                                                                  ) => {
                                                                    const {
                                                                      target: {
                                                                        value,
                                                                      },
                                                                    } = evt;
                                                                    setYearValue(
                                                                      value
                                                                    );
                                                                    if (
                                                                      value !==
                                                                      'Other'
                                                                    )
                                                                      setFieldValue(
                                                                        'highSchoolYear',
                                                                        value.toString()
                                                                      );
                                                                  }}
                                                                />
                                                              </Box>
                                                            </Grid>
                                                          )}
                                                        </Grid>

                                                        <Grid
                                                          container
                                                          spacing={2}
                                                        >
                                                          <Grid
                                                            item
                                                            md={8}
                                                            lg={4}
                                                            sm={12}
                                                            xs={12}
                                                            className={
                                                              classes.parentEmail
                                                            }
                                                          >
                                                            <Typography variant="body2">
                                                              {isParent
                                                                ? `Student’s Email Address`
                                                                : `Parent Email`}
                                                            </Typography>
                                                            <Field
                                                              variant="outlined"
                                                              className={clsx(
                                                                parentEmailInvite,
                                                                parentEmailGutter
                                                              )}
                                                              type="email"
                                                              name="email"
                                                              placeholder="Email"
                                                              component={
                                                                TextField
                                                              }
                                                              label={false}
                                                            />

                                                            {confirmUserResult &&
                                                              confirmUserResult.studentRegistered &&
                                                              !confirmUserResult.isStudent &&
                                                              isParent && (
                                                                <Box className="errorMessage">
                                                                  <Typography color="error">
                                                                    {
                                                                      'A student with this email already exists'
                                                                    }
                                                                  </Typography>
                                                                </Box>
                                                              )}

                                                            {confirmUserResult &&
                                                              confirmUserResult.isStudent &&
                                                              !isParent && (
                                                                <Box className="errorMessage">
                                                                  <Typography color="error">
                                                                    {
                                                                      'We already have a student account with this email so it cannot be invited'
                                                                    }
                                                                  </Typography>
                                                                </Box>
                                                              )}
                                                            {confirmUserResult &&
                                                              !isParent &&
                                                              confirmUserResult.isAlreadyStudentParent && (
                                                                <Box className="errorMessage">
                                                                  <Typography color="error">
                                                                    {
                                                                      'You have already added this user as your parent'
                                                                    }
                                                                  </Typography>
                                                                </Box>
                                                              )}

                                                            {confirmUserResult &&
                                                              confirmUserResult.isStudent &&
                                                              isParent && (
                                                                <Box className="errorMessage">
                                                                  <Typography color="error">
                                                                    {
                                                                      'You have already added a student with this email'
                                                                    }
                                                                  </Typography>
                                                                </Box>
                                                              )}
                                                            {confirmUserResult &&
                                                              confirmUserResult.isAlreadyStudentParent ===
                                                                false &&
                                                              isParent && (
                                                                <Box className="errorMessage">
                                                                  <Typography color="error">
                                                                    {
                                                                      'We already have a parent account with this email so it cannot be invited'
                                                                    }
                                                                  </Typography>
                                                                </Box>
                                                              )}
                                                          </Grid>
                                                          {isParent && (
                                                            <Query<ConfirmUserQuery>
                                                              query={
                                                                CONFIRM_USER_QUERY
                                                              }
                                                              fetchPolicy="no-cache"
                                                              variables={{
                                                                email:
                                                                  values.email,
                                                              }}
                                                              onCompleted={(
                                                                data
                                                              ) => {
                                                                if (
                                                                  data &&
                                                                  data.confirmUser
                                                                ) {
                                                                  setConfirmUserResult(
                                                                    data.confirmUser
                                                                  );
                                                                }
                                                              }}
                                                            >
                                                              {({
                                                                data,
                                                                loading,
                                                              }) => {
                                                                return null;
                                                              }}
                                                            </Query>
                                                          )}

                                                          {!isParent && (
                                                            <Query<ConfirmUserQuery>
                                                              query={
                                                                CONFIRM_USER_QUERY
                                                              }
                                                              fetchPolicy="no-cache"
                                                              variables={{
                                                                email:
                                                                  values.email,
                                                              }}
                                                              onCompleted={(
                                                                data
                                                              ) => {
                                                                if (
                                                                  data &&
                                                                  data.confirmUser
                                                                ) {
                                                                  setConfirmUserResult(
                                                                    data.confirmUser
                                                                  );
                                                                }
                                                              }}
                                                            >
                                                              {({
                                                                data,
                                                                loading,
                                                              }) => {
                                                                return null;
                                                              }}
                                                            </Query>
                                                          )}
                                                          <Grid xs={12}>
                                                            <Box pl={1} pt={2}>
                                                              <Button
                                                                disabled={
                                                                  isSubmitting ||
                                                                  (confirmUserResult &&
                                                                    confirmUserResult?.studentRegistered &&
                                                                    isParent) ||
                                                                  (confirmUserResult &&
                                                                    confirmUserResult?.isStudent &&
                                                                    !isParent) ||
                                                                  (!isParent &&
                                                                    confirmUserResult &&
                                                                    confirmUserResult?.isAlreadyStudentParent) ||
                                                                  (confirmUserResult &&
                                                                    confirmUserResult?.isStudent &&
                                                                    isParent) ||
                                                                  (confirmUserResult &&
                                                                    confirmUserResult?.isAlreadyStudentParent ===
                                                                      false &&
                                                                    isParent)
                                                                }
                                                                variant="contained"
                                                                color="primary"
                                                                type="submit"
                                                              >
                                                                Send Invite
                                                              </Button>
                                                            </Box>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                    </Box>
                                                  </Form>
                                                );
                                              }}
                                            </Formik>
                                          );
                                        }}
                                      </Mutation>
                                    );
                                  }}
                                </Mutation>
                              )}
                            </Grid>
                          </Box>
                        </>
                      );
                    }}
                  </Query>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <SnackbarComponent
          message={snackMessage}
          open={snackOpen}
          handleClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={4000}
          severity={severity}
        />
      </Container>

      <FooterNew />
    </>
  );
};

export default withRouter(ParentAccounts);
