import React from 'react';
import styled from 'styled-components';

import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import { Modal, Button } from '@material-ui/core';

import {
  DeleteParentMutation,
  DeleteParentMutationVariables,
} from './__generated__/DeleteParentMutation';

import Colors from '../../../common/colors';
import Fonts from '../../../common/fonts';

import { ButtonOutlineDark } from '../../../styles/common';

const ModalPaper = styled.div`
  position: absolute;
  max-width: 528px;
  width: 100%;
  padding: 0px 20px;
  background-color: ${Colors.WHITE_ONE};
  outline: none;
  overflow-y: auto;
  opacity: 1;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 767px) {
    height: 100vh;
    border-radius: 0px;
  }
`;

const ModalBody = styled.div`
  padding: 11px 0px 47px;

  @media only screen and (max-width: 767px) {
    padding: 62px 0px 40px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.3375px;
    color: ${Colors.BLACK_SIX};
    margin: 14px 0px 0px;
  }

  .delete_button {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.77px;
    color: ${Colors.WHITE_ONE} !important;
    border-radius: 100px;
    background: ${Colors.RED_ONE};
    padding: 8px 18px;
    text-decoration: none;
    margin-right: 10px;

    &:hover {
      opacity: 0.9;
      background: ${Colors.RED_ONE};
    }
  }

  button {
    width: 142px;

    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${Colors.RED_ONE};
  }

  .MuiCheckbox-root {
    color: ${Colors.GRAY_NINE};
  }

  .MuiIconButton-colorSecondary:hover {
    background-color: rgba(39, 174, 96, 0.08);
  }

  .MuiTypography-body1 {
    font-family: ${Fonts.POPPINS_FONT};
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.3375px;
    color: ${Colors.BLACK_SIX};
  }

  .checkbox_form_group {
    padding-left: 35px;
    margin-bottom: 41px;
    text-align: left;

    @media only screen and (max-width: 480px) {
      padding-left: 0px;
    }
  }
`;

const ModalContentTitle = styled.div`
  margin-bottom: 23px;

  @media only screen and (max-width: 767px) {
    margin-bottom: 17px;
  }

  h3 {
    font-weight: 600;
    font-size: 45px;
    line-height: 84px;
    text-align: center;
    letter-spacing: -2.5px;
    color: ${Colors.BLACK_THREE};
    margin: 0px;

    @media only screen and (max-width: 767px) {
      font-size: 26px;
      line-height: 30px;
      letter-spacing: 0px;
      margin-bottom: 7px;
    }
  }

  p {
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.3375px;
    color: ${Colors.BLACK_THREE};
    margin: 0px;

    @media only screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

const CollegeFormControl = styled.div`
  text-align: center;
`;

const DELETE_PARENT_MUTATION = gql`
  mutation DeleteParentMutation($id: Int!) {
    removeParentAccount(input: { id: $id }) {
      status
    }
  }
`;

interface Props {
  open: boolean;
  onClose: () => void;
  id?: number;
  refetch: Function;
}

const RemoveParentModal: React.FC<Props> = ({ open, onClose, id, refetch }) => {
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={onClose}
      disableBackdropClick={true}
    >
      <ModalPaper>
        <ModalBody>
          <ModalContentTitle className="text-center">
            <h3>Remove Parent?</h3>
            <p>This cannot be undone.</p>
          </ModalContentTitle>

          <CollegeFormControl>
            <Mutation<DeleteParentMutation, DeleteParentMutationVariables>
              mutation={DELETE_PARENT_MUTATION}
            >
              {(deleteParentMutation: Function) => {
                return (
                  <Button
                    onClick={async () => {
                      try {
                        await deleteParentMutation({
                          variables: { id },
                        });
                        refetch();
                        onClose();
                      } catch (error) {
                        console.log('error: ', error);
                        alert('Something went wrong, Please try again');
                      }
                    }}
                    variant="contained"
                    className="delete_button"
                  >
                    DELETE
                  </Button>
                );
              }}
            </Mutation>

            <ButtonOutlineDark onClick={onClose}>CANCEL</ButtonOutlineDark>
          </CollegeFormControl>
        </ModalBody>
      </ModalPaper>
    </Modal>
  );
};

export default RemoveParentModal;
