import gql from 'graphql-tag';

export const GET_STUDENT_SOURCES = gql`
  query GetStudentSources($StudentId: Int!) {
    getStudentSources(StudentId: $StudentId) {
      data {
        id
        card {
          brand
          country
          last4
        }
      }
    }
  }
`;

export const REPROCESS_PAYMENT = gql`
  mutation ReprocessPayment(
    $StudentId: Int!
    $InvoiceId: String!
    $SourceId: String!
  ) {
    reprocessPayment(
      input: {
        StudentId: $StudentId
        InvoiceId: $InvoiceId
        SourceId: $SourceId
      }
    ) {
      success
      message
      actionRequired
      clientSecret
    }
  }
`;

export const CHECK_STUDENT_PROFILE = gql`
  query checkStudentProfileCompletionQuery($userId: Int) {
    checkStudentProfileCompletion(userId: $userId) {
      details {
        id
        UserFields {
          gpa
          act
          sat
          highSchoolName
          extraCurricularActivities
          gpaWeighted
          gpaNonWeighted
        }
      }
    }
  }
`;
