import { FC, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';

import { ErrorMessage, Field, Form, Formik } from 'formik';

import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import * as Yup from 'yup';

import CustomAlert from '../../../components/CustomAlert';
import { TextField } from '../../../components/fields';

import { cjConfig } from '../../../common/utility';
import {
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables,
} from './__generated__/UpdatePasswordMutation';

import { useStyles } from './styles';

const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePasswordMutation(
    $oldPassword: String!
    $newPassword: String!
  ) {
    updatePassword(
      input: { oldPassword: $oldPassword, newPassword: $newPassword }
    ) {
      status
      message
    }
  }
`;

const ChangePasswordPage: FC = () => {
  const classes = useStyles();
  const initialValues = {
    newPassword: '',
    oldPassword: '',
  };
  const FormSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, 'Too Short!')
      .max(50, 'Too Long!')
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[ A-Za-z]/, 'Password requires a letter')
      .required('*New Password is Required'),
    oldPassword: Yup.string()
      .min(8, 'Too Short!')
      .max(50, 'Too Long!')
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[ A-Za-z]/, 'Password requires a letter')
      .required('*Old Password is Required'),
  });

  useEffect(() => {
    cjConfig({});
  }, []);

  const [variant, setVariant] = useState('');
  const [message, setMessage] = useState('');
  function toggleAlert(_variant?: string, _message?: string) {
    if (_variant && _message) window.scrollTo(0, 0);
    setVariant(_variant || '');
    setMessage(_message || '');
  }

  return (
    <Box className={classes.mainContainer} mt={3}>
      <CustomAlert
        variant={variant}
        message={message}
        toggleAlert={toggleAlert}
      />

      <Grid container className="loginPasswordContainer">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card className={classes.cardTitle}>
            <Mutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>
              mutation={UPDATE_PASSWORD_MUTATION}
            >
              {(updatePasswordMutation: Function) => {
                return (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={FormSchema}
                    validateOnChange
                    onSubmit={async (variables, { setSubmitting }) => {
                      try {
                        toggleAlert();
                        const result = await updatePasswordMutation({
                          variables,
                        });
                        const {
                          data: {
                            updatePassword: { status, message },
                          },
                        } = result;
                        console.log('status: ', status);

                        console.log('message: ', message);
                        if (message)
                          toggleAlert(status ? 'success' : 'danger', message);
                      } catch (error) {
                        toggleAlert(
                          'danger',
                          'Something went wrong, Please try again'
                        );
                        console.log('error: ', error);
                      }
                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting, errors, touched }) => (
                      <Form>
                        <Grid container>
                          <Box width="100%">
                            <Typography variant="h4">
                              {'Login & Password'}
                            </Typography>
                            <Divider />
                          </Box>

                          <Grid item md={5} sm={12} xs={12}>
                            <Box padding="30px 25px 0px">
                              <Typography
                                variant="body2"
                                color="inherit"
                                className={classes.passwordLabels}
                              >
                                Old Password
                              </Typography>
                              <Field
                                type="password"
                                name="oldPassword"
                                placeholder="Old password"
                                component={TextField}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                className={`${classes.textfield}, ${classes.textfieldMobile}`}
                                label={false}
                                variant="outlined"
                              />
                              <ErrorMessage
                                component="div"
                                name="oldPassword"
                                className={classes.errorText}
                              />
                            </Box>

                            <Box padding="19px 25px 0px">
                              <Typography
                                variant="body2"
                                color="inherit"
                                className={classes.passwordLabels}
                              >
                                New Password
                              </Typography>

                              <Field
                                type="password"
                                name="newPassword"
                                placeholder="New password"
                                component={TextField}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                className={`${classes.textfield}, ${classes.textfieldMobile}`}
                                label={false}
                                variant="outlined"
                              />
                              <ErrorMessage
                                component="div"
                                name="newPassword"
                                className={classes.errorText}
                              />
                            </Box>
                          </Grid>

                          <Grid item md={12} sm={12} xs={12}>
                            <Box padding="20px 25px 24px">
                              <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                Save Changes
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                );
              }}
            </Mutation>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChangePasswordPage;
