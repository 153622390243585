const SmallLogo = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 640 639"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M449.171 189.707L160.644 322.16L292.814 351.88L323.04 478.293L449.171 189.707Z"
        fill="#5CC583"
      />
      <path
        d="M319.684 580.92C175.202 580.92 57.6555 463.533 57.6555 319.24C57.6555 174.96 175.202 57.5735 319.684 57.5735C464.168 57.5735 581.715 174.96 581.715 319.24C581.715 463.533 464.168 580.92 319.684 580.92ZM319.684 6.10352e-05C143.41 6.10352e-05 0 143.213 0 319.24C0 495.28 143.41 638.493 319.684 638.493C495.954 638.493 639.364 495.28 639.364 319.24C639.364 143.213 495.954 6.10352e-05 319.684 6.10352e-05Z"
        fill="#222C5D"
      />
    </svg>
  );
};

export default SmallLogo;
