import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { lazy, useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import client from '../apollo';
import Loader from '../components/Loader';
import PrivateRoute from '../components/PrivateRoute';
import PublicRoute from '../components/PublicRoute';
import Header from '../components/layout/Header';
import { AppContext, User } from '../contexts';
import { GET_USER_FROM_USER_TOKEN_MAIN } from '../graphql/queries/main';
import { customTheme } from './MaterializeCss';
import { Error404 } from './errorPages';
import MBAFreeHomePage from './graduateMBAProgram/MBAFreeHomePage';
import MBAHomePage from './graduateMBAProgram/MBAHomePage';
import MBAHeader from './graduateMBAProgram/gradMBAHeader/MBAHeader';
import MBAHeaderFree from './graduateMBAProgram/gradMBAHeader/MBAHeaderFree';
import {
  ChangePasswordPage,
  ParentAccounts,
  SubscriptionPage,
} from './main/account';
import OldBillingPage from './main/account/OldBillingPage';
import WelcomToPackage from './main/clientEnrollment/WelcomToPackage';
import ConnectAccount from './main/connectAccount/ConnectAccount';
import IntakeForm from './main/intakeForm/IntakeForm';
import { MentorDetailPage } from './main/mentors';
import RedirectMentor from './main/mentors/RedirectMentor';

const WebinarsList = lazy(() => import('./webinar/WebinarsList'));
const WebinarDetailPage = lazy(() => import('./webinar/WebinarDetailPage'));
const SearchWebinars = lazy(() => import('./webinar/SearchWebinars'));
const WebinarLandingSeries = lazy(
  () => import('./webinar/WebinarLandingSeries')
);
const WebinarViewAll = lazy(() => import('./webinar/WebinarViewAll'));
const ClientEnrollment = lazy(
  () => import('./main/clientEnrollment/ClientEnrollment')
);
const MBAClientEnrollment = lazy(
  () => import('./graduateMBAProgram/MBAClientEnrollment')
);
const PackageDetails = lazy(
  () => import('./main/clientEnrollment/PackageDetails')
);
const MBAPackageDetails = lazy(
  () => import('./graduateMBAProgram/MBAPackageDetails')
);
const StudentHomePage = lazy(() => import('./main/home/StudentHomePage'));
const Bookings = lazy(() => import('./main/home/BookingMeeting/Bookings'));
const MBAProfilePage = lazy(
  () => import('./graduateMBAProgram/MBAProfilePage')
);
const ProfilePage = lazy(() => import('./main/account/ProfilePage'));
const BillingPage = lazy(() => import('./main/account/BillingPage'));
const MBAMyProfile = lazy(() => import('./graduateMBAProgram/MBAMyProfile'));
const MyProfile = lazy(() => import('./main/account/MyProfile'));

const MaterializeLayout = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);

  let { user: currentUser } = useContext(AppContext);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const userToken = params?.userToken;

  const url = new URL(window.location.href);
  const shouldDisplayHeader =
    !(
      url.pathname.includes('intake-form') || url.pathname.includes('/meeting/')
    ) && !user?.isMBAGrad;

  const fetchAndSaveUser = async () => {
    try {
      setLoading(true);
      const { data } = await client.query({
        query: GET_USER_FROM_USER_TOKEN_MAIN,
        variables: {
          userToken: userToken,
        },
        fetchPolicy: 'no-cache',
      });

      if (data && data?.getUserFromUserToken?.user) {
        setUser(data.getUserFromUserToken.user);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const apiCall = () => {
    if (!currentUser && userToken) {
      fetchAndSaveUser();
    } else {
      setUser(currentUser);
    }
  };

  useEffect(apiCall, []);

  return loading ? (
    <Loader isSmallLoader={true} isTransparent={true} forceLoaderText={true} />
  ) : (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />

      {shouldDisplayHeader && <Header />}

      {user?.isMBAGrad ? (
        user?.isPremium ? (
          <MBAHeader />
        ) : (
          <MBAHeaderFree />
        )
      ) : null}

      <Switch>
        <PublicRoute exact path={`/advisors/:id`} component={RedirectMentor} />
        <PublicRoute
          exact
          path={`/advisors/:id/:uuid`}
          component={MentorDetailPage}
        />
        <PublicRoute exact path={`/webinars`} component={WebinarsList} />
        <PublicRoute
          exact
          path={`/webinars/search`}
          component={SearchWebinars}
        />

        <PublicRoute
          exact
          path={`/webinar/:id`}
          component={WebinarDetailPage}
        />

        <PublicRoute
          exact
          path={`/webinar/series/:id`}
          component={WebinarLandingSeries}
        />

        <PublicRoute
          exact
          path={['/webinars/:series', '/my-webinars/:series']}
          component={WebinarViewAll}
        />

        <Route exact path={`/intake-form/:token`} component={IntakeForm} />

        {/* MBA_CONSTANT */}
        <PublicRoute
          exact
          path={`/client-enrollment`}
          component={user?.isMBAGrad ? MBAClientEnrollment : ClientEnrollment}
        />

        <PublicRoute
          exact
          path={`/client-enrollment/welcome`}
          component={WelcomToPackage}
        />

        {/* MBA_CONSTANT */}
        <PublicRoute
          exact
          path={`/client-enrollment/:id`}
          component={user?.isMBAGrad ? MBAPackageDetails : PackageDetails}
        />

        {!user?.isParent && !user?.isMBAGrad && (
          <PrivateRoute exact path={'/home'} component={StudentHomePage} />
        )}

        {user?.isMBAGrad && (
          <PrivateRoute
            exact
            path={'/home'}
            component={user.isPremium ? MBAHomePage : MBAFreeHomePage}
          />
        )}

        {!user?.isParent && user?.isPremium && (
          <PrivateRoute exact path={'/meetings'} component={Bookings} />
        )}
        <PrivateRoute
          exact
          path={'/account/profile'}
          component={user?.isMBAGrad ? MBAProfilePage : ProfilePage}
        />

        {process.env.REACT_APP_ENABLE_CE_R1 === 'TRUE' && (
          <PrivateRoute
            exact
            path={'/account/billing'}
            component={BillingPage}
          />
        )}

        {process.env.REACT_APP_ENABLE_CE_R1 !== 'TRUE' && (
          <PrivateRoute
            exact
            path={'/account/billing'}
            component={OldBillingPage}
          />
        )}

        <PrivateRoute
          exact
          path={'/account/subscription'}
          component={SubscriptionPage}
        />

        <PrivateRoute
          exact
          path={
            !user?.isParent
              ? '/account/parent-accounts'
              : '/account/student-accounts'
          }
          component={ParentAccounts}
        />

        <PrivateRoute
          exact
          path={'/account/change-password'}
          component={ChangePasswordPage}
        />

        <PrivateRoute
          exact
          path={'/account/my-profile'}
          component={user?.isMBAGrad ? MBAMyProfile : MyProfile}
        />

        <PublicRoute
          exact
          path={`/connect_account`}
          component={ConnectAccount}
        />
        <Route component={() => <Error404 hideLogo />} />
      </Switch>
    </ThemeProvider>
  );
};

export default MaterializeLayout;
