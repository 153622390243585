import { ChangeEvent, FC, useState } from 'react';

import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import Colors from '../../../common/colors';
import Loader from '../../../components/Loader';
import AddCard from '../../../pages/main/orders/StripeForm';
import { Q_AllPaymentSourcesQuery } from '../clientEnrollment/gql/__generated__/Q_AllPaymentSourcesQuery';
import MyCardAccount from './MyCardAccount';
import SelectCardDialog from './SelectCardDialog';
import { useStyles } from './styles';
import {
  UpdateCustomerDefaultCard,
  UpdateCustomerDefaultCardVariables,
} from './__generated__/UpdateCustomerDefaultCard';

import PLUS_ADD_IMG from '../../../img/add-plus-icon.svg';

const STRIPE_API_KEY =
  process.env.REACT_APP_STRIPE_CLIENT_KEY ||
  'pk_test_z08FEiGVjS2wpEVQiKxurepa00coYG4e6E';
const stripePromise = loadStripe(STRIPE_API_KEY);

export const ALL_PAYMENT_SOURCES_QUERY = gql`
  query QAllPaymentSourcesQuery($userId: Int, $getBanks: Boolean) {
    allPaymentSources(userId: $userId, getBanks: $getBanks) {
      id
      object
      card {
        country
        brand
        last4
      }
      owner {
        name
        address {
          city
          state
          postal_code
          line1
        }
      }
      isDefault
    }
  }
`;

export const UPDATE_CUSTOMER_DEFAULT_CARD = gql`
  mutation UpdateCustomerDefaultCard($sourceId: String!) {
    updateCustomerDefaultCard(sourceId: $sourceId) {
      success
      message
    }
  }
`;

interface Props {
  isBillingPage?: boolean;
}

const SelectCardAccount: FC<Props> = ({ isBillingPage }) => {
  const [addCard, setAddCard] = useState('');
  const [open, setOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const classes = useStyles();
  function updateCardInfo(sourceId: string) {
    setAddCard(sourceId);
    setShowForm(!sourceId);
  }

  return (
    <Grid className={classes.selectCardAdvisor}>
      <Typography variant="h4">Payment Method</Typography>

      <Query<Q_AllPaymentSourcesQuery>
        query={ALL_PAYMENT_SOURCES_QUERY}
        fetchPolicy="network-only"
        variables={{ getBanks: true }}
        onCompleted={(data) => {
          if (!data) return null;
          const { allPaymentSources } = data;
          if (!allPaymentSources || !allPaymentSources.length)
            setShowForm(true);
          else if (!addCard && !isBillingPage) {
            const source = allPaymentSources[0];
            if (source && source.card) {
              const { id } = source;
              updateCardInfo(id);
            }
          }
        }}
      >
        {({ data, loading, refetch, networkStatus }) => {
          if (loading && networkStatus !== 4)
            return (
              <Loader
                isSmallLoader={true}
                isTransparent={true}
                forceLoaderText={true}
              />
            );
          if (!data) return null;
          const { allPaymentSources } = data;
          const cardsLength = allPaymentSources && allPaymentSources.length;

          return (
            <Grid className={classes.paymentCardBody}>
              {isBillingPage &&
                allPaymentSources &&
                !!allPaymentSources.length && (
                  <Typography variant="h6" className="currentMethod">
                    Current Payment Method
                  </Typography>
                )}

              <RadioGroup
                aria-label="Referral"
                name="referral"
                onChange={(event: ChangeEvent<{}>, value: string) => {
                  if (allPaymentSources) {
                    allPaymentSources.filter((obj) => {
                      if (obj && obj.card && obj.id === value) {
                        setOpen(true);
                        return updateCardInfo(value);
                      }
                      return false;
                    });
                  }
                }}
              >
                {allPaymentSources &&
                  allPaymentSources.map((obj, i) => {
                    if (!obj || !obj.card) return null;

                    const {
                      card: { last4, brand },
                      id,
                      isDefault,
                    } = obj;
                    const cardHolderName = obj?.owner?.name;
                    const cardNumber = `**** **** **** ${last4}`;

                    return (
                      <>
                        <FormControlLabel
                          value={id}
                          name="payment_method"
                          checked={Boolean(isDefault)}
                          control={<Radio />}
                          className="labelWidthFlex"
                          label={
                            <MyCardAccount
                              key={i}
                              isBillingPage={Boolean(isBillingPage)}
                              brand={String(brand)}
                              last4={String(last4)}
                              id={id}
                              isDefault={isDefault}
                              cardNumber={cardNumber}
                              allPaymentSources={allPaymentSources}
                              refetch={refetch}
                              cardHolderName={cardHolderName}
                            />
                          }
                        />
                      </>
                    );
                  })}
              </RadioGroup>

              <Divider className="paymentDivider" />

              {!showForm && (
                <Button
                  onClick={() => {
                    updateCardInfo('');
                  }}
                  color="primary"
                  variant="contained"
                >
                  <img src={PLUS_ADD_IMG} alt="plusIcon" />
                  Add New Payment Method
                </Button>
              )}

              {showForm && (
                <Grid item md={12} sm={12} xs={12} className="mb-3">
                  {isBillingPage && (
                    <Box
                      marginTop={cardsLength ? 2 : 0}
                      paddingTop={cardsLength ? 3 : 0}
                      borderTop={
                        cardsLength ? `1px solid ${Colors.GRAY_NINE}` : ''
                      }
                      className="addNewCard"
                    >
                      Add new Card
                    </Box>
                  )}
                  <>
                    <Elements stripe={stripePromise}>
                      <AddCard
                        isBillingPage={!!isBillingPage}
                        refetch={() => {
                          refetch();

                          setShowForm(false);
                        }}
                      />
                    </Elements>
                  </>
                </Grid>
              )}
              <Mutation<
                UpdateCustomerDefaultCard,
                UpdateCustomerDefaultCardVariables
              >
                mutation={UPDATE_CUSTOMER_DEFAULT_CARD}
              >
                {(updateDefaultCard) => {
                  return (
                    <SelectCardDialog
                      open={open}
                      onClose={() => {
                        setOpen(false);
                      }}
                      description={
                        'Are you sure you want to make this payment method as your default payment method.'
                      }
                      closeBtnText={'Cancel'}
                      confirmBtnText={'Confirm'}
                      onConfirm={async () => {
                        await updateDefaultCard({
                          variables: {
                            sourceId: addCard,
                          },
                        });
                        await refetch();
                        setOpen(false);
                      }}
                    />
                  );
                }}
              </Mutation>
            </Grid>
          );
        }}
      </Query>
    </Grid>
  );
};

export default SelectCardAccount;
