import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core';
import React, { useContext } from 'react';

import { CURRENT_YEAR } from '../../common/constants';
import { AppContext } from '../../contexts';

import Images from '../../img';
import TWITTER from '../../img/footer-social-1.png';
import LINKEDIN from '../../img/footer-social-2.png';
import YOUTUBE from '../../img/footer-social-3.png';
import FACEBOOK from '../../img/footer-social-4.png';
import INSTAGRAM from '../../img/footer-social-5.png';
import LOGO from '../../img/logo-header.png';
import { footerStyles } from './styles';

const SOCIAL_LINKS = [
  {
    image: TWITTER,
    alt: 'twitter icon',
    link: 'https://twitter.com/_collegeadvisor',
  },
  {
    image: LINKEDIN,
    alt: 'facebook icon',
    link: 'http://linkedin.com/company/joincollegeadvisor',
  },
  {
    image: YOUTUBE,
    alt: 'instagram icon',
    link: 'https://www.youtube.com/c/CollegeAdvisor',
  },
  {
    image: FACEBOOK,
    alt: 'youtube icon',
    link: 'https://facebook.com/joincollegeadvisor',
  },
  {
    image: INSTAGRAM,
    alt: 'linkedin icon',
    link: 'http://instagram.com/joincollegeadvisor',
  },
];

const FooterNew: React.FC = () => {
  const classes = footerStyles();
  const { user } = useContext(AppContext);
  const premiumPackageType = user && user.premiumPackageType;

  return (
    <footer className={classes.footerWrapper}>
      <Container maxWidth={false}>
        <Hidden only={['sm', 'xs']}>
          <Grid container>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Box maxWidth={250}>
                <img
                  src={LOGO}
                  className={classes.footerLogo}
                  alt="footer main logo"
                />

                <Typography
                  variant="body1"
                  component="p"
                  className={classes.footerCopyRight}
                >
                  &copy; {CURRENT_YEAR} USN College Advisor, Inc.
                  <br />
                </Typography>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                className={classes.socialIcons}
              >
                {SOCIAL_LINKS.map((item) => (
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={item.alt}
                  >
                    <img src={item.image} alt={item.alt} loading="lazy" />
                  </a>
                ))}
              </Box>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <a
                className={classes.footerNavLinks}
                href="https://www.collegeadvisor.com/resources/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Resources
              </a>
              <a
                className={classes.footerNavLinks}
                href="https://app.collegeadvisor.com/colleges"
                target="_blank"
                rel="noopener noreferrer"
              >
                Popular Colleges
              </a>
              <a
                className={classes.footerNavLinks}
                href="https://www.collegeadvisor.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy and Cookie Notice
              </a>
              <a
                className={classes.footerNavLinks}
                href="https://www.collegeadvisor.com/privacy-ccpa/"
                target="_blank"
                rel="noopener noreferrer"
              >
                California Privacy Notice
              </a>
              <Box display={'flex'}>
                <a
                  className={classes.footerNavLinks}
                  href="https://www.collegeadvisor.com/privacy-do-not-sell/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img width={30} src={Images.PrivcacyIcon} />
                </a>
                <a
                  className={classes.footerNavLinks}
                  href="https://www.collegeadvisor.com/privacy-do-not-sell/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Your Privacy Choices
                </a>
              </Box>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <a
                href="https://collegeadvisor.typeform.com/to/G7kq0B"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footerNavLinks}
              >
                Advisor Application
              </a>

              <a
                className={classes.footerNavLinks}
                href="https://www.collegeadvisor.com/careers/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Careers
              </a>
              <a
                href="https://www.collegeadvisor.com/contact-us/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footerNavLinks}
              >
                Contact Us
              </a>
              {!user ? (
                <a
                  href="https://app.collegeadvisor.com/login"
                  rel="noopener noreferrer"
                  className={classes.footerNavLinks}
                >
                  Student Login
                </a>
              ) : null}
              <a
                href="https://www.collegeadvisor.com/terms-and-conditions/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footerNavLinks}
              >
                Terms and Conditions
              </a>
            </Grid>

            {user?.isPremium ? (
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Grid className={classes.helpPopover}>
                  <Typography variant="h4" className="title">
                    Help & Support
                  </Typography>

                  <Typography variant="body1" className="subTitle">
                    Questions? Reach out to us by calling{' '}
                    <a href="tel:(855) 412-6272" className="phoneNumber">
                      (855) 412-6272
                    </a>{' '}
                    or send us an email to{' '}
                    <a
                      href="mailto:success@collegeadvisor.com"
                      className="emailto"
                    >
                      success@collegeadvisor.com
                    </a>
                  </Typography>

                  <Typography variant="h5" className="subHeading">
                    Hours of Operation
                  </Typography>

                  <Typography variant="h6" className="dateText">
                    Monday - Friday: 8:00am - 5:00pm CT
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Hidden>

        <Hidden only={['xl', 'lg', 'md']}>
          <Grid container>
            <Grid item lg={2} md={2} sm={6} xs={6}>
              <a
                className={classes.footerNavLinks}
                href="https://www.collegeadvisor.com/resources/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Resources
              </a>
              <a
                className={classes.footerNavLinks}
                href="https://app.collegeadvisor.com/colleges"
                target="_blank"
                rel="noopener noreferrer"
              >
                Popular Colleges
              </a>
              <a
                className={classes.footerNavLinks}
                href="https://www.collegeadvisor.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy and Cookie Notice
              </a>
              <a
                className={classes.footerNavLinks}
                href="https://www.collegeadvisor.com/privacy-ccpa/"
                target="_blank"
                rel="noopener noreferrer"
              >
                California Privacy Notice
              </a>
              <Box display={'flex'}>
                <a
                  className={classes.footerNavLinks}
                  href="https://www.collegeadvisor.com/privacy-do-not-sell/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img width={30} src={Images.PrivcacyIcon} />
                </a>
                <a
                  className={classes.footerNavLinks}
                  href="https://www.collegeadvisor.com/privacy-do-not-sell/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Your Privacy Choices
                </a>
              </Box>
            </Grid>

            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={6}
              className={classes.footerMobilePadding}
            >
              <a
                href="https://collegeadvisor.typeform.com/to/G7kq0B"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footerNavLinks}
              >
                Advisor Application
              </a>

              <a
                className={classes.footerNavLinks}
                href="https://www.collegeadvisor.com/careers/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Careers
              </a>
              <a
                href="https://www.collegeadvisor.com/contact-us/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footerNavLinks}
              >
                Contact Us
              </a>
              {!user ? (
                <a
                  href="https://app.collegeadvisor.com/login"
                  rel="noopener noreferrer"
                  className={classes.footerNavLinks}
                >
                  Student Login
                </a>
              ) : null}
              <a
                href="https://www.collegeadvisor.com/terms-and-conditions/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footerNavLinks}
              >
                Terms and Conditions
              </a>
            </Grid>

            {premiumPackageType ? (
              <Box className={classes.helpPopover} paddingTop={2}>
                <Typography variant="h4" className="title">
                  Help & Support
                </Typography>

                <Typography variant="body1" className="subTitle">
                  Questions? Reach out to us by calling{' '}
                  <a href="tel:(855) 412-6272" className="phoneNumber">
                    (855) 412-6272
                  </a>{' '}
                  or send us an email to{' '}
                  <a
                    href="mailto:success@collegeadvisor.com"
                    className="emailto"
                  >
                    success@collegeadvisor.com
                  </a>
                </Typography>

                <Typography variant="h5" className="subHeading">
                  Hours of Operation
                </Typography>

                <Typography variant="h6" className="dateText">
                  Monday - Friday: 8:00am - 5:00pm CT
                </Typography>
              </Box>
            ) : (
              <></>
            )}

            <Grid
              item
              lg={5}
              md={5}
              sm={12}
              xs={12}
              className={classes.footerLogoMobile}
            >
              <img
                src={LOGO}
                className={classes.footerLogo}
                alt="footer main logo"
              />

              <Box>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.footerCopyRight}
                >
                  &copy; {CURRENT_YEAR} USN College Advisor, Inc.
                  <br />
                </Typography>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className={classes.socialIcons}
                >
                  {SOCIAL_LINKS.map((item) => (
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={item.alt}
                    >
                      <img src={item.image} alt={item.alt} loading="lazy" />
                    </a>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Hidden>
      </Container>
    </footer>
  );
};

export default FooterNew;
