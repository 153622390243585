import gql from 'graphql-tag';

export const IS_USER_LEAD_EXISTS = gql`
  query isUserExistsQuery($email: String!) {
    isUserExists(email: $email) {
      user {
        firstName
        lastName
        email
        roles
      }
      caLeadId
      success
      message
    }
  }
`;

export const GET_USER = gql`
  query getUserBySfOpportunityId($sfOpportunityId: String!) {
    getUserBySfOpportunityId(sfOpportunityId: $sfOpportunityId) {
      firstName
      lastName
      email
      phoneNumber
      highSchoolYear
      roles
      parents {
        firstName
        lastName
        email
        phoneNumber
        roles
      }
    }
  }
`;

export const UPDATE_OPT_OUT_SMS = gql`
  mutation updateOptOutSMS($email: String, $optOutSMS: Boolean) {
    updateOptOutSMS(email: $email, optOutSMS: $optOutSMS) {
      success
      message
    }
  }
`;
