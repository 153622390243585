import fonts from '../../../common/fonts';
import { Theme, makeStyles, createStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

export const stripeFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },

    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    zipCodeField: {
      '& input': {
        color: '#6e6e6e !important',
        fontSize: '14px !important',
        height: '27px !important',
        borderBottom: '1px solid #bdbdbd !important',
        opacity: '1 !important',
        width: '100%',

        '&::placeholder': {
          color: '#6e6e6e !important',
          opacity: '1 !important',
        },

        '&:hover': {
          borderBottom: '1px solid #2F80ED !important',
        },
        '&:focus': {
          borderBottom: '1px solid #2F80ED !important',
        },
      },
      '& div': {
        width: '100%',
      },
    },
  })
);

export const deleteCardModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteDialog: {
      '& div[role="dialog"]': {
        width: 551,
        maxWidth: '100%',
        borderRadius: 25,
        padding: '50px !important',
        boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

        [theme.breakpoints.down('xs')]: {
          borderRadius: 15,
          padding: '30px 16px',
          margin: '10px !important',
        },
      },
    },
    dialogHeading: {
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '32px',
      letterSpacing: '-0.019em',
      color: '#272929',
      marginBottom: 10,
      fontFamily: fonts.POPPINS_FONT,

      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        lineHeight: '24px',
      },
    },
    dialogActionRoot: {
      padding: '34px 0px 0px',
      justifyContent: 'flex-start',
      border: 'none',
    },
    actionDescription: {
      color: '#525454',
    },
  })
);