import { Theme, makeStyles } from '@material-ui/core';
import fonts from '../common/fonts';

export const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    width: '2em !important',
    color: '#FFF',
    height: '1.51em !important',
    marginBottom: '-1.51em !important',
  },

  tooltip: {
    fontSize: 14,
    fontWeight: 400,
    color: '#A9A9A9',
    fontFamily: fonts.INTER,
    letterSpacing: '-0.084px',
    borderRadius: 6,
    background: '#FFF !important',
    padding: 14,
    border: '1px solid #F6F8F9',
    boxShadow:
      '0px 40px 64px 0px rgba(91, 104, 113, 0.24), 0px 0px 1px 0px rgba(26, 32, 36, 0.32)',
  },

  image: {
    cursor: 'pointer',
    marginLeft: 14,
  },
}));
