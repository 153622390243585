import gql from 'graphql-tag';

export const UserContract = gql`
  query UserContract($packageId: Int) {
    userContract(packageId: $packageId) {
      contractUrl
      FullName
      Initials
      DateSigned
      packageId
      discountCouponId
    }
  }
`;
