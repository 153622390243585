import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { PropsWithChildren } from 'react';
import SEARCH_ICON from '../img/survey-search-icon.svg';
import colors from './colors';
import fonts from './fonts';

export const useCommonStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropStyleTable: {
      '& .react-select__control': {
        width: '174px !important',
      },
      '& .react-select__menu': {
        width: '174px !important',
      },
    },

    surveyForm: {
      maxWidth: 818,
      margin: '37px auto',
      borderRadius: 25,
      border: '1px solid #D4D4D4',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('sm')]: {
        maxWidth: '90%',
      },

      '& .surveyFormHeader': {
        padding: 44,
        borderRadius: '24px 24px 0px 0px',
        background: 'linear-gradient(90deg, #F4F4F4 0%, #EAEAEA 100%)',

        [theme.breakpoints.down('xs')]: {
          padding: 20,
        },

        "& [class*='MuiTypography-body1']": {
          fontSize: 14,
          color: '#7D7F7F',
          fontFamily: fonts.INTER,
          letterSpacing: '-0.084px',

          '& b': {
            fontWeight: 700,
            color: '#525454',
          },
        },

        '& .submitText': {
          marginLeft: 5,
        },
      },

      '& .surveyHeading': {
        fontSize: 36,
        fontWeight: 700,
        color: '#0A2463',
        lineHeight: '42px',
        letterSpacing: '-0.792px',
        fontFamily: fonts.POPPINS_FONT,

        [theme.breakpoints.down('xs')]: {
          fontSize: 25,
        },
      },

      '& .applicationResultsHeading': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#000000',
        fontFamily: fonts.INTER,

        [theme.breakpoints.down('sm')]: {
          marginBottom: 39,
        },
      },

      '& .applicationResults': {
        padding: '47px 44px',

        [theme.breakpoints.down('xs')]: {
          padding: '30px 20px',
        },
      },

      '& .subtitleText': {
        margin: '30px 0px 5px',
        color: '#272929',
      },

      '& .subtitleText, & .MuiFormControlLabel-root .MuiTypography-body1': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        fontFamily: fonts.INTER,
      },

      '& .MuiFormControlLabel-root .MuiTypography-body1': {
        color: '#525454',
      },

      '& .MuiRadio-colorSecondary': {
        color: '#d4d4d4',

        '&.Mui-checked': {
          color: '#2F80ED',
        },
      },

      '& .MuiIconButton-colorSecondary:hover': {
        backgroundColor: 'rgb(47, 128, 237, 0.04)',
      },

      '& textarea': {
        borderRadius: 6,
        height: 80,
        border: '1px solid #D4D4D4',
        fontSize: 14,
        letterSpacing: '-0.006em',
        fontFamily: fonts.INTER,
        padding: '6px 12px',
        boxSizing: 'border-box',
        marginTop: 8,

        '&::placeholder': {
          color: '#A9A9A9',
        },
      },
    },

    childBranches: {
      paddingLeft: 20,
      margin: '10px 0px 0px 64px',
      borderLeft: '2px solid #D4D4D4',

      [theme.breakpoints.down('sm')]: {
        margin: '10px 0px 0px 0px',
      },

      '& .acceptanceRate': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
      },

      '& .MuiTypography-body2': {
        fontFamily: fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        fontWeight: 400,
      },

      '& .collegeAvatarName': {
        marginBottom: 13,
      },

      '& .subCampusPercentage': {
        position: 'relative',
        left: 45,
        color: '#272929',
        fontSize: 16,
        letterSpacing: '-0.011em',
      },

      '& img': {
        marginRight: 14,
      },
    },

    addButton: {
      minWidth: 94,
      height: 40,
      background: '#2F80ED',
      borderRadius: 12,
      letterSpacing: '-0.006em',
      color: '#FFFFFF',
      boxShadow: 'none',
      textTransform: 'capitalize',
      marginLeft: 21,
      padding: '0px 10px',
      fontWeight: 700,

      '& .MuiButton-label': {
        fontSize: 14,
      },

      [theme.breakpoints.down('xs')]: {
        margin: '15px 0px 0px',
      },

      '&:hover': {
        background: '#2F80ED',
      },

      '& img': {
        marginRight: 12,
      },
    },

    addSchool: {
      padding: '20px 0px 70px',

      [theme.breakpoints.down('xs')]: {
        padding: '9px 0px 40px',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },

      '& .addSchoolButton': {
        marginLeft: 20,

        [theme.breakpoints.down('xs')]: {
          marginLeft: 0,
        },
      },
    },

    scholarshipInfo: {
      paddingTop: 53,

      '& .subtitleText': {
        marginBottom: 0,
      },
    },

    helpertext: {
      fontWeight: 400,
      fontSize: 12,
      letterSpacing: '-0.006em',
      color: '#A9A9A9',
      fontFamily: fonts.INTER,
    },

    submitSurvey: {
      minWidth: 169,
      height: 40,
      background: '#45CE93',
      borderRadius: 12,
      fontWeight: 'bold',
      letterSpacing: '-0.006em',
      color: '#FFFFFF',
      fontFamily: fonts.POPPINS_FONT,
      marginTop: 37,
      textTransform: 'capitalize',
      boxShadow: 'none',

      '& .MuiButton-label': {
        fontSize: 14,
      },

      '&:hover': {
        background: '#45CE93',
      },
    },

    selectSchool: {
      width: '512px !important',

      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
      },

      '& .react-select__control': {
        width: '512px !important',
        minHeight: '40px !important',
        height: '40px !important',

        [theme.breakpoints.down('sm')]: {
          width: '100% !important',
        },
      },

      '& .react-select__indicators': {
        height: '36px !important',
      },

      '& .react-select__value-container': {
        height: '36px !important',
      },

      '& .react-select__single-value, & .react-select__placeholder': {
        top: '18px !important',
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#A9A9A9',
        fontFamily: fonts.INTER,
      },
    },

    decisionSelectAdd: {
      width: '100% !important',
      position: 'relative',

      [theme.breakpoints.down('xs')]: {
        marginBottom: 20,
      },

      [theme.breakpoints.up('sm')]: {
        width: 'initial !important',
      },

      '& .react-select-container': {
        width: '100% !important',

        [theme.breakpoints.up('sm')]: {
          width: 'initial !important',
        },
      },

      '& .react-select__value-container': {
        height: '40px !important',
      },

      '& .react-select__control': {
        width: '280px !important',
        minHeight: '40px !important',
        height: '40px !important',
        border: '1px solid #d4d4d4 !important',

        [theme.breakpoints.down('xs')]: {
          width: '100% !important',
        },
      },

      '& .react-select__indicators': {
        height: '40px !important',
      },

      '& .react-select__input': {
        paddingLeft: 30,
      },

      '& .react-select__indicator': {
        width: 45,
        height: 40,
        backgroundSize: 17,
        backgroundImage: `url( ${SEARCH_ICON})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right 15px',
        position: 'absolute',
        left: 0,

        '& svg': {
          display: 'none',
        },
      },

      '& .react-select__placeholder': {
        top: '19px !important',
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#A9A9A9',
        fontFamily: fonts.INTER,
        left: 40,
      },

      '& .react-select__single-value': {
        left: 40,
        width: '82%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        top: '19px !important',
        textOverflow: 'ellipsis',
      },
    },

    decisionSelect: {
      width: 200,

      '& .select__control': {
        fontSize: 14,
        borderRadius: 6,
        borderColor: colors.WHITE_SEVEN,
      },

      '& .react-select__option': {
        color: colors.BLACK_THIRTEEN,
        '&--is-focused, &--is-selected': {
          backgroundColor: colors.SELECT_OPTION_ACTIVE,
        },
        '&:hover': {
          cursor: 'pointer',
          background: colors.GRAY_BOREDER,
        },
      },
      '& .react-select__control': {
        borderRadius: 6,
        width: 197,
        minHeight: 32,
        height: 32,
        '&--is-focused, &:hover': {
          borderColor: `${colors.WHITE_SEVEN}`,
          boxShadow: 'none',
        },
      },

      '& .react-select__value-container': {
        height: 32,
        padding: '0px 8px',
      },

      '& .react-select__single-value': {
        color: '#272929',
      },

      '& .react-select__single-value, & .react-select__placeholder': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        fontFamily: fonts.INTER,
        top: 14,
      },

      '& .react-select__placeholder': {
        color: '#A9A9A9',
      },

      '& .react-select__indicator-separator, & .react-select__loading-indicator, & .react-select__clear-indicator':
        {
          display: 'none',
        },

      '& .react-select__indicators': {
        height: 28,
      },

      '& .react-select__indicator': {
        '& svg': {
          color: '#7D7F7F',
          width: 15,
        },
      },

      '& .react-select__multi-value': {
        background: '#63A0F2',
        borderRadius: 4,

        '& svg': {
          color: '#FFFFFF',
          opacity: 0.6,
        },

        '& .react-select__multi-value__remove': {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },

      '& .react-select__multi-value__label': {
        fontWeight: 600,
        fontSize: 12,
        color: '#FFFFFF',
        fontFamily: fonts.INTER,
      },

      '& .react-select__value-container.react-select__value-container--is-multi.react-select__value-container--has-value':
        {
          padding: 10,
        },
    },
    campusesDropdown: {
      position: 'relative',
      top: '36px',
    },
    campusesDropDownSpacing: {
      '&:not(:first-child)': {
        paddingTop: 6,
      },
    },
    campusesStatusSpacing: {
      '&:not(:first-child)': {
        paddingTop: 16,
      },
    },
    enterSchools: {
      '& .react-select__control': {
        width: '100% !important',
        minHeight: 'auto !important',
        height: 'auto !important',
      },

      '& .react-select__indicators': {
        height: 'auto !important',
      },

      '& .react-select__placeholder': {
        top: '16px !important',
      },

      '& .react-select__value-container.react-select__value-container--is-multi.react-select__value-container--has-value':
        {
          padding: '0px 10px',
        },
    },

    surveyTable: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        overflowX: 'auto',
      },

      '& td': {
        '& .MuiTypography-body1': {
          [theme.breakpoints.down('sm')]: {
            whiteSpace: 'nowrap !important',
            fontSize: 14,
          },
        },
      },
    },
  })
);

export const useTooltipStyles = (props?: PropsWithChildren<any> | null) =>
  makeStyles(() =>
    createStyles({
      arrow: {
        color: '#fff',
      },

      tooltip: {
        fontSize: 12,
        fontWeight: 400,
        borderRadius: 6,
        color: '#7D7F7F',
        lineHeight: '20px',
        textAlign: 'center',
        padding: '11px 13px',
        background: '#FFFFFF',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        border: '1px solid #F6F8F9',
        maxWidth: props?.changeWidth ? '320px' : 'auto',
        boxShadow:
          '0px 40px 64px 0px rgba(91, 104, 113, 0.24), 0px 0px 1px 0px rgba(26, 32, 36, 0.32) !important',
      },
    })
  );
