import { useContext, useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Card,
  Grid,
  Theme,
  Tooltip,
  Typography,
  makeStyles,
  createStyles,
} from '@material-ui/core';

import {
  ClientStatus,
  associationTypeOptions,
} from '../../../common/constants';
import { AppContext } from '../../../contexts';
import { roundNumber, sortAdvisorsWithLevel } from '../../../common/utility';

import VideoHoursRemaining from '../../../components/VideoHoursRemaining';

import fonts from '../../../common/fonts';
import Colors from '../../../common/colors';

import NO_IMAGE from '../../../img/empty-badge.svg';
import Chat_Image from '../../../img/chat-image.svg';
import Calendar_Image from '../../../img/calendar-image.svg';

import {
  MentorDetailQuery_Mentor_currentCollege,
  MentorDetailQuery_Mentor_graduateSchool,
} from '../mentors/__generated__/MentorDetailQuery';
import client from '../../../apollo';
import { GET_ALL_STUDENT_MENTORS } from '../../../graphql/queries/studentMentors';
import { getAllStudentMentors_allStudentMentors } from '../../../graphql/queries/studentMentors/__generated__/getAllStudentMentors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    applicationsCard: {
      marginBottom: 26,
      borderRadius: 25,

      [theme.breakpoints.down('sm')]: {
        marginBottom: 17,
        borderRadius: 15,
      },
    },

    applicationsHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '24px 30px',
      borderBottom: `1px solid ${Colors.WHITE_SIX}`,

      [theme.breakpoints.down('sm')]: {
        padding: '15px 20px',
      },
    },

    mentorsListContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '&:not(:last-child)': {
        marginBottom: 10,
      },
    },

    mentorsList: {
      '& a': {
        display: 'flex',
        alignItems: 'flex-start',

        '&:not(:last-child)': {
          marginBottom: 10,
        },
      },

      '& h5': {
        lineHeight: '14px',
        marginBottom: 4,
      },

      '& .collegeTypography': { color: '#7D7F7F' },
    },

    advisorAvatar: {
      height: 48,
      width: 48,
      borderRadius: '50%',
      overflow: 'hidden',
      marginRight: 21,

      '& img': {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
      },

      '@media only screen and (max-width: 480px)': {
        marginRight: 10,
      },
    },

    advisorsWrapper: {
      paddingLeft: '30px',
      paddingRight: '30px',
      paddingBottom: '39px',

      [theme.breakpoints.down('sm')]: {
        padding: '0px 0px 20px 0px',
      },
    },

    packageTpesOpacity: {
      opacity: 0.5,
    },

    advisorsDisableButton: {
      '& .iconDisabled': {
        opacity: 0.1,
        cursor: 'not-allowed',
      },
    },

    packageTypes: {
      padding: '26px 30px 35px',
      borderTop: `1px solid ${Colors.WHITE_SEVEN}`,

      [theme.breakpoints.down('sm')]: {
        padding: '0px 15px 25px',
      },
    },
    packageTypes_h6: {
      fontFamily: fonts.INTER,
      fontWeight: 600,
      fontSize: 18,
      color: '#2F80ED',
      letterSpacing: '-0.014em',
      marginBottom: 4,
      textTransform: 'initial',

      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
        lineHeight: '19px',
      },
    },

    packageTypes_h6_completed: {
      color: '#E94444',
    },

    packageTypes_body1: {
      fontFamily: fonts.INTER,
      fontWeight: 400,
      fontSize: 16,
      color: '#A9A9A9',
      letterSpacing: '-0.011em',
    },

    timeSlots: {
      '& img': {
        marginRight: 20,
        cursor: 'pointer',
      },

      '& .iconDisabled': {
        opacity: 0.1,
        cursor: 'not-allowed',
      },
    },

    avatarContentGrid: {
      width: 220,
    },
  })
);

const filterMentorValues = (item: getAllStudentMentors_allStudentMentors) => {
  let id: number = NaN;
  let uuid: string | null = '';
  let mentorImage = null;
  let mentorName = '';
  const collegeName = `${item?.mentor?.currentCollege?.name || '_ _'}${
    item?.mentor?.intakeYear ? `, ${item?.mentor?.intakeYear}` : ''
  }`;
  if (item && item.mentor) {
    id = item.mentor.id;
    uuid = item.mentor.uuid;
    mentorImage = item.mentor.pictureUrl;
    const firstName = item.mentor.firstName ? item.mentor.firstName : '--';
    const lastName = item.mentor.lastName ? item.mentor.lastName : '--';
    mentorName = `${firstName} ${lastName}`;
  }
  const mentorLevel = item?.isExtraMatch
    ? associationTypeOptions.find((el) => el.value === item?.associateType)
        ?.label || ''
    : item?.mentor?.advisorLevel;

  return {
    id,
    uuid,
    mentorImage,
    collegeName,
    mentorName,
    mentorLevel,
  };
};

const AdvisorsInnerCard = ({
  id,
  uuid,
  mentorImage,
  mentorName,
  mentorLevel,
  collegeName,
  graduated,
  isPremiumUser,
  premiumVideoCountUsed,
  premiumTotalVideoCount,
  openCalendar,
  setAdvisorId,
  mentorId,
  Theme,
  currentCollege,
  graduateSchool,
}: any) => {
  const classes = useStyles();

  const themeColorSelection = (
    Theme: string | null,
    currentCollege: MentorDetailQuery_Mentor_currentCollege | null,
    graduateSchool: MentorDetailQuery_Mentor_graduateSchool | null
  ) => {
    if (!Theme) return null;
    if (Theme === 'underGraduation') {
      return currentCollege?.name || null;
    } else if (Theme === 'graduation') {
      return graduateSchool?.name || null;
    }
    return null;
  };

  return (
    <Box key={id} className={classes.mentorsListContainer}>
      <Box className={classes.mentorsList}>
        <Link to={`/advisors/${id}/${uuid}`}>
          <Box className={classes.advisorAvatar}>
            {mentorImage ? (
              <img src={mentorImage} alt={mentorName} />
            ) : (
              <img src={NO_IMAGE} alt="dummy user" />
            )}
          </Box>

          <Box className={classes.avatarContentGrid}>
            <Typography variant="h5">{mentorName}</Typography>
            <Typography variant="body1">{mentorLevel}</Typography>

            <Typography variant="body1" className="collegeTypography">
              {themeColorSelection(Theme, currentCollege, graduateSchool)}
            </Typography>
          </Box>
        </Link>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={
          graduated || !isPremiumUser ? classes.advisorsDisableButton : ''
        }
      >
        {
          <Box className={classes.timeSlots}>
            <Tooltip
              title={
                !isPremiumUser
                  ? ''
                  : (premiumVideoCountUsed || 0) < (premiumTotalVideoCount || 0)
                  ? 'Available TimeSlots'
                  : 'No video chat hours remaining'
              }
            >
              <img
                src={Calendar_Image}
                alt="calendar"
                width="23px"
                className={
                  isPremiumUser &&
                  !graduated &&
                  (premiumVideoCountUsed || 0) < (premiumTotalVideoCount || 0)
                    ? ''
                    : 'iconDisabled'
                }
                onClick={() => {
                  if (
                    !graduated &&
                    (premiumVideoCountUsed || 0) <
                      (premiumTotalVideoCount || 0) &&
                    isPremiumUser
                  ) {
                    openCalendar(true);
                    setAdvisorId(mentorId);
                  }
                }}
              />
            </Tooltip>
          </Box>
        }

        <Link
          to={'/chat'}
          onClick={(e) => (graduated || !isPremiumUser) && e.preventDefault()}
        >
          <img
            className={graduated || !isPremiumUser ? 'iconDisabled' : ''}
            src={Chat_Image}
            alt="chat"
            width="22px"
          />
        </Link>
      </Box>
    </Box>
  );
};

const PackageCard = ({
  openCalendar,
  setAdvisorId,
  Theme,
  graduateSchool,
}: {
  openCalendar: Function;
  setAdvisorId: Function;
  Theme: string | null;
  graduateSchool: MentorDetailQuery_Mentor_graduateSchool | null | undefined;
}) => {
  const { user } = useContext(AppContext);
  const classes = useStyles();

  const [allStudentMentors, setAllStudentMentors] = useState<
    (getAllStudentMentors_allStudentMentors | null)[] | null
  >(null);

  useEffect(() => {
    const fetchAllStudentMentors = async () => {
      const { data } = await client.query({
        query: GET_ALL_STUDENT_MENTORS,
        fetchPolicy: 'network-only',
      });
      if (data && data.allStudentMentors) {
        setAllStudentMentors(data.allStudentMentors);
      }
    };
    fetchAllStudentMentors();
  }, []);

  const hasMentors = user && allStudentMentors && allStudentMentors.length > 0;
  const premiumPackageType = user?.premiumPackageType;
  const hasAdditionalAdvisors = hasMentors
    ? allStudentMentors?.filter((record) =>
        associationTypeOptions.find(
          (option) => option.value === record?.associateType
        )
      )?.length
    : false;
  const total_hours = user?.premiumTotalVideoCount || 0;
  const total_hour_used = user?.premiumVideoCountUsed || 0;
  const remainingHours =
    total_hours - total_hour_used < 0
      ? 0
      : roundNumber(total_hours - total_hour_used, 1);
  const premiumText =
    remainingHours === 0
      ? 'You have no video chat hours remaining. Please contact your advisor to discuss adding additional hours.'
      : `${remainingHours} ${
          remainingHours === 1 ? 'hour' : 'hours'
        } remaining`;
  const progress =
    total_hour_used && total_hours
      ? (total_hour_used * 100) / total_hours > 100
        ? 100
        : (total_hour_used * 100) / total_hours
      : 0;
  const isPremiumUser =
    user?.isPremium &&
    user?.clientStatus !== ClientStatus.BAD_DEBT &&
    user?.clientStatus !== ClientStatus.CANCELED;

  return (
    <Card className={classes.applicationsCard}>
      <Box className={classes.applicationsHeader}>
        <Typography variant="h4">Package Details</Typography>
      </Box>

      {hasMentors && (
        <>
          <Box className={classes.advisorsWrapper} pt={'26px'}>
            <Box pb={2.3}>
              <Typography variant="h6">Advisors</Typography>
            </Box>

            {user &&
              !!allStudentMentors?.length &&
              sortAdvisorsWithLevel(
                allStudentMentors.filter(
                  (record) =>
                    !associationTypeOptions.find(
                      (option) => option.value === record?.associateType
                    )
                )
              ).map((item) => {
                const {
                  id,
                  uuid,
                  collegeName,
                  mentorImage,
                  mentorLevel,
                  mentorName,
                } = filterMentorValues(item);

                return (
                  <AdvisorsInnerCard
                    {...{
                      id,
                      uuid,
                      mentorImage,
                      mentorName,
                      mentorLevel,
                      collegeName,
                      graduated: user.graduated,
                      isPremiumUser,
                      premiumVideoCountUsed: user.premiumVideoCountUsed,
                      premiumTotalVideoCount: user.premiumTotalVideoCount,
                      openCalendar,
                      setAdvisorId,
                      mentorId: item?.mentor?.id,
                      currentCollege: item?.mentor?.currentCollege,
                      graduateSchool: item?.mentor?.graduateSchool,
                      Theme: item.mentor?.Theme,
                    }}
                  />
                );
              })}
          </Box>

          {hasAdditionalAdvisors ? (
            <Box className={classes.advisorsWrapper}>
              <Box pb={2.3}>
                <Typography variant="h6">SPECIALTY ADVISORS</Typography>
              </Box>

              {user &&
                allStudentMentors &&
                allStudentMentors?.length > 0 &&
                sortAdvisorsWithLevel(
                  allStudentMentors?.filter((record) =>
                    associationTypeOptions.find(
                      (option) => option.value === record?.associateType
                    )
                  )
                ).map((item) => {
                  const {
                    id,
                    uuid,
                    collegeName,
                    mentorImage,
                    mentorLevel,
                    mentorName,
                  } = filterMentorValues(item);

                  return (
                    <AdvisorsInnerCard
                      {...{
                        id,
                        uuid,
                        mentorImage,
                        mentorName,
                        mentorLevel,
                        collegeName,
                        graduated: user.graduated,
                        isPremiumUser,
                        premiumVideoCountUsed: user.premiumVideoCountUsed,
                        premiumTotalVideoCount: user.premiumTotalVideoCount,
                        openCalendar,
                        setAdvisorId,
                        mentorId: item?.mentor?.id,
                        currentCollege: item?.mentor?.currentCollege,
                        graduateSchool: item?.mentor?.graduateSchool,
                        Theme: item.mentor?.Theme,
                      }}
                    />
                  );
                })}
            </Box>
          ) : null}
        </>
      )}

      {premiumPackageType &&
        (+(user?.highSchoolYear || 0) >= 2023 || user?.isMBAGrad) && (
          <Grid className={user?.graduated ? classes.packageTpesOpacity : ''}>
            {!user?.graduated === true && (
              <Grid className={classes.packageTypes} container>
                <Grid xs={2}>
                  <VideoHoursRemaining progress={progress || 0} />
                </Grid>
                <Grid xs={10}>
                  <Box>
                    <Typography
                      variant="h6"
                      className={
                        progress === 100
                          ? classes.packageTypes_h6 +
                            ' ' +
                            classes.packageTypes_h6_completed
                          : classes.packageTypes_h6
                      }
                    >
                      {total_hour_used} of {total_hours} video chat hours used
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.packageTypes_body1}
                    >
                      {premiumText}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
    </Card>
  );
};
export default PackageCard;
