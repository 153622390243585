import { FC } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, createStyles, Box, Typography, Avatar } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    intakeUser: {
      display: 'flex',
      paddingBottom: 125,
    },

    userImage: {
      height: 44,
      width: 44,
    },
  })
);

interface Props {
  userImageUrl?: string | null;
  userName: string;
}

const IntakeFormUser: FC<Props> = ({ userImageUrl, userName }) => {
  const classes = useStyles();

  return (
    <Box component="section" className={classes.intakeUser}>
      <Box mr='13px'>
        {userImageUrl ?
          <Avatar className={classes.userImage} src={userImageUrl} />
          :
          <Avatar className={classes.userImage} src="" />
        }
      </Box>

      <Box>
        <Typography variant="h5">{userName}</Typography>
        <Typography variant="body2">
          <Link to="/login">Not you?</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default IntakeFormUser;
