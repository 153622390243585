import { Box, Grid, Typography } from '@material-ui/core';

import { MILESTONE_LIST } from '../../../common/constants';

export default function MilestoneTooltipContent() {
  return (
    <>
      <Typography variant="body1" className="tooltipHeading">
        <b>Milestone Teams</b> - Connect with{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.collegeadvisor.com/"
        >
          CollegeAdvisor.com
        </a>{' '}
        experts at key milestones throughout the application process. They
        provide support and insight on topics ranging from interview coaching,
        financial aid, exploring majors or colleges, essay support, and more!
      </Typography>

      <ul>
        <Grid container>
          {MILESTONE_LIST.map((item) => (
            <Grid item md={6} sm={6} xs={12}>
              <li>
                <Box display="flex" alignItems="center">
                  <Box display="flex" justifyContent="center" width={16} mr={2}>
                    <img src={item.icon} alt={item.title} />
                  </Box>
                  <Typography variant="body2" className="listText">
                    {item.title}
                  </Typography>
                </Box>
              </li>
            </Grid>
          ))}
        </Grid>
      </ul>
    </>
  );
}
