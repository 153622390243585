import { Box, Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import gql from 'graphql-tag';
import React, { useContext, useState } from 'react';
import { Mutation } from 'react-apollo';
import { convertTimeZoneForDB } from '../common/utility';
import { AppContext } from '../contexts';
import { useStyles } from '../pages/errorPages/style';
import {
  UpdateStudentAccountMutation,
  UpdateStudentAccountMutationVariables,
} from './__generated__/UpdateStudentAccountMutation';
import Dropdown from './Dropdown';
import { zones } from './TimeZonePicker';

export const UPDATE_STUDENT_ACCOUNT_MUTATION = gql`
  mutation UpdateStudentAccountMutation(
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $email: String
    $pictureUrl: String
    $highSchool: String
    $highSchoolYear: String
    $applyingYear: String
    $gpa: String
    $sat: String
    $act: String
    $timezone: String
    $intakeToken: String
  ) {
    updateStudent(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        pictureUrl: $pictureUrl
        phoneNumber: $phoneNumber
        highSchool: $highSchool
        highSchoolYear: $highSchoolYear
        applyingYear: $applyingYear
        gpa: $gpa
        sat: $sat
        act: $act
        timezone: $timezone
        intakeToken: $intakeToken
      }
    ) {
      firstName
      lastName
      email
      pictureUrl
      phoneNumber
      highSchool
      gpa
      sat
      act
      highSchoolYear
      applyingYear
      timezone
      parents {
        id
        email
        phoneNumber
        firstName
        lastName
      }
    }
  }
`;

const TimeZoneDialog: React.FC = () => {
  const { user, setUser } = useContext(AppContext);

  const [open, setOpen] = React.useState(true);
  const [timeZone, setTimeZone] = React.useState(user?.timezone || '');
  const [yearValue, setYearValue] = useState(user?.highSchoolYear || '');
  const [gradYear, setGradYear] = useState(user?.highSchoolYear || '');

  const classes = useStyles();

  const [otherYear, setOtherYear] = useState(false);
  let zoneArray: any = [];
  zones.forEach((val) => {
    zoneArray.push({ label: val, value: val });
  });

  interface zone_array {
    label: string;
    value: string;
  }
  const email = user && user.email;
  const firstName = user && user.firstName;
  const lastName = user && user.lastName;
  const pictureUrl = user && user.pictureUrl;
  const phoneNumber = user && user.phoneNumber;
  const highSchool = user && user.highSchool;
  const gpa = user && user.gpa;
  const sat = user && user.sat;
  const act = user && user.act;
  const highSchoolYear = user && user?.highSchoolYear;
  const applyingYear = user && user.applyingYear;
  const timezone = timeZone;
  const variables = {
    email,
    firstName,
    lastName,
    pictureUrl,
    phoneNumber,
    timezone,
    highSchool,
    gpa,
    sat,
    act,
    highSchoolYear,
    applyingYear,
  };

  const isTrial =
    user?.studentTrial?.trialStartedAt && !user?.studentTrial?.trialCompletedAt
      ? true
      : false;

  const currentdate = new Date();
  const currentYear = currentdate.getFullYear();

  const temporaryArray = [1, 2, 3, 4, 5, 6];
  let YEARS_ARRAY: any = [];
  YEARS_ARRAY.push({ label: currentYear, value: currentYear });
  temporaryArray.forEach((val) => {
    YEARS_ARRAY.push({ label: val + currentYear, value: val + currentYear });
  });
  YEARS_ARRAY.push({ label: 'Other', value: 'Other' });

  return (
    <>
      <Mutation<
        UpdateStudentAccountMutation,
        UpdateStudentAccountMutationVariables
      >
        mutation={UPDATE_STUDENT_ACCOUNT_MUTATION}
      >
        {(updateStudentAccountMutation: Function, { data }) => {
          return (
            <Dialog
              open={open}
              id="timeZoneDialog"
              className={classes.updateYourInfo}
            >
              <Grid className="updateInfoBody">
                <Typography variant="h4" className="modalHeading">
                  Update Your Info
                </Typography>

                <DialogContent className="time-zone-content">
                  {!highSchoolYear && (
                    <Grid className={classes.dropdownIcon}>
                      <Typography variant="caption" className="fieldCaption ">
                        Grad Year
                      </Typography>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className={!yearValue ? 'forLabelColor' : ''}
                      >
                        <Dropdown
                          gradYear={true}
                          label={''}
                          placeholder={!yearValue ? 'Select Grad Year' : ''}
                          selectedValue={yearValue || ''}
                          options={YEARS_ARRAY}
                          name="year"
                          onSelect={(evt: any) => {
                            const {
                              target: { value },
                            } = evt;
                            setYearValue(value);
                            setGradYear(value.toString());
                            if (value === 'Other') {
                              setGradYear('');
                              setOtherYear(true);
                            } else setOtherYear(false);
                          }}
                        />
                      </Grid>

                      {otherYear && (
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="fieldCaptionMargin"
                        >
                          <Typography
                            variant="caption"
                            className="fieldCaption"
                          >
                            Grad Year
                          </Typography>

                          <TextField
                            variant="outlined"
                            name="yearValue"
                            placeholder="Other"
                            helperText={
                              gradYear === '' ||
                              (+gradYear &&
                              parseInt(gradYear) <=
                                currentdate.getFullYear() + 10
                                ? ''
                                : `Grad year can not be greater than ${
                                    currentYear + 10
                                  }`)
                            }
                            FormHelperTextProps={{
                              className: classes.helperText,
                            }}
                            onChange={(e) => setGradYear(e.target.value)}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}

                  {!user?.timezone && (user?.isPremium || isTrial) && (
                    <Grid className="autocomplete fieldCaptionMargin">
                      <Typography variant="caption" className="fieldCaption">
                        Time Zone
                      </Typography>
                      <Autocomplete
                        id="timeZoneComboBox"
                        placeholder="Time Zone"
                        options={zoneArray}
                        onInputChange={(
                          event: any,
                          newValue: string | null
                        ) => {
                          if (newValue != null) setTimeZone(newValue);
                        }}
                        getOptionLabel={(option: zone_array) => option.label}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Select Time Zone"
                            {...params}
                            variant="outlined"
                            className="autoCompleteTimezone"
                          />
                        )}
                      />
                    </Grid>
                  )}
                </DialogContent>

                <Box textAlign="right" mt={3}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        user != null &&
                        gradYear &&
                        +gradYear &&
                        parseInt(gradYear) <= currentdate.getFullYear() + 10
                      ) {
                        if (user.isPremium && !timeZone) {
                          return;
                        } else {
                          variables.highSchoolYear = gradYear.toString();
                          setOpen(false);

                          updateStudentAccountMutation({
                            variables: {
                              ...variables,
                              timezone: convertTimeZoneForDB(timeZone),
                            },
                          });
                          setUser({
                            ...user,
                            timezone: timeZone,
                            highSchoolYear: gradYear.toString(),
                          });
                        }
                      }
                    }}
                    autoFocus
                    variant="contained"
                    color="primary"
                    disabled={!timezone}
                  >
                    Confirm
                  </Button>
                </Box>
              </Grid>
            </Dialog>
          );
        }}
      </Mutation>
    </>
  );
};
export default TimeZoneDialog;
