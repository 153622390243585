import { Theme, makeStyles, createStyles } from '@material-ui/core';
import Colors from '../../common/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      display: 'flex',
      padding: '4rem 0rem',
      minHeight: 'calc(100vh - 350px)',

      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      color: Colors.GRAY_TWO,

      '& > img': {
        maxWidth: 250,
        width: '100%',
      },
    },
    helperText: {
      color: 'red',
    },

    updateYourInfo: {
      '& .updateInfoBody': {
        padding: '31px 34px 35px',

        [theme.breakpoints.down('sm')]: {
          padding: '20px 16px',
        },
      },

      '& .modalHeading': {
        color: '#000000',
        marginBottom: 16,
      },

      '& .fieldCaption': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        marginBottom: 4,
        lineHeight: '24px',
        display: 'block',
      },

      '& .fieldCaptionMargin': {
        marginTop: 22,
      },
    },

    dropdownIcon: {
      '& svg': {
        width: '17px !important',
        color: '#7D7F7F',
        right: 17,
      },
    },
  })
);
