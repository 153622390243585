import {
  Box,
  Checkbox,
  Collapse,
  Container,
  createStyles,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Select as MaterialUISelect,
  MenuItem,
  TextField,
  Theme,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import gql from 'graphql-tag';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { Query } from 'react-apollo';
import Helmet from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import MentorsList from './MentorsList';

import FooterNew from '../../../components/layout/FooterNew';
import Header from '../../../components/layout/Header';
import Loader from '../../../components/Loader';

import {
  createTealiumCall,
  scrollToTop,
  selectColor,
} from '../../../common/utility';
import { AllMentorsListQuery } from './__generated__/AllMentorsListQuery';

import Colors from '../../../common/colors';
import fonts from '../../../common/fonts';

import AsyncSelect from 'react-select/async';
import client from '../../../apollo';
import { tealiumConstants } from '../../../common/constants';
import SEARCH_LIGHT_IMG from '../../../img/search-light.svg';
import { MenuProps } from '../../../styles/commonComponentStyles';
import { SUPER_MAJOR_CATEGORY_QUERY_WITH_ASSOCIATED_ADVISORS } from '../../CollegeFilter/gql';
import { customTheme } from '../../MaterializeCss';
import { AppContext } from '../../../contexts';
import MBAHeader from '../../graduateMBAProgram/gradMBAHeader/MBAHeader';
import MBAHeaderFree from '../../graduateMBAProgram/gradMBAHeader/MBAHeaderFree';

const MentorsMedia = styled.div`
  .MuiCardHeader-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: -0.5px;
    color: ${Colors.BLACK_THREE};
    font-family: ${fonts.POPPINS_FONT};
    @media only screen and (max-width: 991px) {
      font-size: 20px;
    }
  }
  .MuiCardHeader-subheader {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.3375px;
    color: ${Colors.BLACK_ONE};
    font-family: ${fonts.POPPINS_FONT};
    @media only screen and (max-width: 1200px) {
      font-size: 13px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .MuiCardHeader-avatar {
      margin-right: 0px;
    }
  }
`;

export const ALL_MENTORS_QUERY = gql`
  query AllMentorsListQuery(
    $limit: Int
    $page: Int
    $searchQuery: String
    $appId: Int
    $random: Boolean
    $sort: String
    $filter: [String]
    $filterType: String
    $excludeMentors: [Int]
    $filterAdvisorLevel: String
    $major: Int
    $areaOfInterest: Int
    $hasInternationalExperience: Boolean
    $studentId: Int
  ) {
    allMentors(
      input: {
        limit: $limit
        page: $page
        searchQuery: $searchQuery
        appId: $appId
        random: $random
        sort: $sort
        filter: $filter
        filterType: $filterType
        excludeMentors: $excludeMentors
        filterAdvisorLevel: $filterAdvisorLevel
        major: $major
        areaOfInterest: $areaOfInterest
        hasInternationalExperience: $hasInternationalExperience
        studentId: $studentId
      }
    ) {
      nextRecord
      mentors {
        id
        firstName
        lastName
        pictureUrl
        bio
        gpa
        major
        minor
        intakeYear
        isAcceptedTo
        advisorLevel
        previouslyWorkedColleges {
          college {
            id
            name
            logo
          }
        }
        graduateSchool {
          id
          name
          logo
        }
        graduateSchoolGradYear
        colleges {
          id
          logo
          name
        }
        currentCollege {
          id
          name
          logo
        }
        Theme
        uuid
      }
    }
  }
`;

interface OptionType {
  label: string;
  value: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    advisorFilterBox: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },

    advisorFilterTextField: {
      marginRight: 16,

      [theme.breakpoints.down('sm')]: {
        margin: '0px 0px 16px 0px',
      },
    },

    searchFilterAsync: {
      '& .select__value-container': {
        fontFamily: fonts.INTER,
      },

      '& .customDropDown': {
        width: 383,
        marginRight: 17,

        [theme.breakpoints.down('sm')]: {
          margin: 0,
          width: '100%',
        },
      },

      '& .customDropDown.specialty': {
        margin: 0,
        width: 316,

        [theme.breakpoints.down('xs')]: {
          width: '100%',
          marginTop: 18,
        },
      },

      '& .select__control': {
        borderColor: '#D4D4D4',
        backgroundColor: 'transparent',
      },

      '& .select__loading-indicator': {
        display: 'none',
      },

      '& .select__single-value': {
        maxWidth: 'calc(100% - 30px)',
      },
    },

    mentorsLoadMore: {
      textAlign: 'center',
      padding: '10px 0px 32px',
      fontSize: '24px',
      color: Colors.BLUE_SEVEN,

      '& .Mui-disabled': {
        color: Colors.GRAY_TWELVE,
        backgroundColor: `${Colors.GRAY_EIGHT} !important`,
      },
    },

    genericHeaderComponent: {
      padding: '65px 0px 76px',

      [theme.breakpoints.down('sm')]: {
        padding: '25px 0px 40px',
      },

      '& .selectAdvisorLevel': {
        [`@media only screen and (max-width: 767px)`]: {
          margin: 0,
          width: '100%',
        },
      },

      '& .selectSpecialty': {
        [theme.breakpoints.down('sm')]: {
          marginRight: '0px !important',
        },

        [`@media only screen and (max-width: 767px)`]: {
          marginTop: 18,
        },
      },

      '& .experienceInternational': {
        [`@media only screen and (max-width: 767px)`]: {
          marginTop: 18,
        },

        '& .MuiFormControlLabel-root': {
          margin: 0,
        },

        '& .MuiFormControlLabel-label': {
          fontSize: 16,
          marginLeft: 13,
          fontWeight: 400,
          color: '#A9A9A9',
          letterSpacing: '-0.011em',
        },

        '& svg path': {
          color: '#7D7F7F',
          fontFamily: fonts.INTER,
        },
      },

      '& .advancedBasicSearch': {
        fontSize: 14,
        fontWeight: 400,
        color: '#7D7F7F',
        cursor: 'pointer',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',

        '& span': {
          borderBottom: '1px solid #7D7F7F',
        },
      },

      '& .MuiTypography-h3': {
        fontSize: 36,
        color: '#272929',
        marginBottom: 36,
        fontWeight: 'bold',
        letterSpacing: '-0.022em',
        fontFamily: fonts.POPPINS_FONT,

        [theme.breakpoints.down('sm')]: {
          marginBottom: 24,
        },
      },

      "& [aria-haspopup='listbox']": {
        height: 40,
        backgroundPosition: 'center right 10px',
      },

      '& .drawerHeader': {
        marginBottom: 53,
      },

      '& .searchInput': {
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },

        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: Colors.WHITE_SEVEN,
        },

        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#d4d4d4',
        },

        '& .MuiOutlinedInput-root': {
          borderRadius: 6,
        },

        '& .MuiOutlinedInput-input': {
          width: 460,
          height: 40,
          fontSize: 14,
          fontWeight: 400,
          color: '#525454',
          fontFamily: fonts.INTER,
          boxSizing: 'border-box',
          letterSpacing: '-0.006em',
          backgroundRepeat: 'no-repeat',
          padding: '11px 12px 11px 40px',
          backgroundPosition: 'center left 12px',
          backgroundImage: `url(${SEARCH_LIGHT_IMG})`,

          '&::placeholder': {
            opacity: 1,
            color: '#A9A9A9',
          },

          [`@media only screen and (max-width: 767px)`]: {
            width: '100%',
          },
        },
      },
    },
  })
);

let currentPage = 1;
let hasNextRecord = true;

const sortBy = [
  'id',
  'firstName',
  'email',
  'createdAt',
  'updatedAt',
  'lastName',
];

const advisorLevels = [
  'Advisor',
  'Senior Advisor',
  'Former Admissions Officer',
];

type TParams = { invitationToken: string };

const Mentors: FC<RouteComponentProps<TParams>> = ({
  location: { search },
}) => {
  useEffect(() => {
    scrollToTop();

    createTealiumCall({
      site_vertical: tealiumConstants.SITE,
      site_portal: tealiumConstants.ADVISORS,
      site_product: tealiumConstants.ADVISORS_PRODUCT,
      site_product_section: tealiumConstants.ADVISORS_PRODUCT_SECTION,
      page_type: tealiumConstants.ADVISORS_PAGE_TYPE,
      content_type: tealiumConstants.ADVISORS_CONTENT_TYPE,
    });

    return () => {
      currentPage = 1;
      hasNextRecord = true;
    };
  }, []);
  const { user } = useContext(AppContext);

  let timeoutId: any = 0;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const randomNumber = Math.floor(Math.random() * 6);

  const [searchQuery, setSearchQuery] = useState('');
  const [filterAdvisorLevel, setAdvisorLevel] = useState<string | unknown>('');
  const [areaOfInterest, setAreaOfInterest] = useState();
  const [loadingMore, setLoadingMore] = useState(false);
  const [filterType, setFilterType] = useState('any');

  const [hasInternationalExperience, setHasInternationalExperience] =
    useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasInternationalExperience(event.target.checked);
  };

  const [sort, setSort] = useState(sortBy[randomNumber]);

  let isfilterApplied: boolean;
  let isMentorNull = true;

  const params = new URLSearchParams(search);

  const mentorFilter = params.get('mentorFilter') || '';
  const mentorFilterArray = mentorFilter ? mentorFilter.split(',') : [];
  isfilterApplied =
    mentorFilterArray && mentorFilterArray.length > 0 ? true : false;

  const onLevelChange = ({
    target: { value },
  }: ChangeEvent<{ value: unknown }>) => {
    setAdvisorLevel(value);
  };

  const loadAreaOfInterestOptions: any = (
    inputValue: string,
    exclude: number[]
  ) => {
    return new Promise((resolve) => {
      client
        .query({
          query: SUPER_MAJOR_CATEGORY_QUERY_WITH_ASSOCIATED_ADVISORS,
          variables: {
            limit: 100,
            searchQuery: inputValue,
            exclude,
          },
        })
        .then((res: any) => {
          if (
            res.data &&
            res.data.getSuperMajorCategoriesWithAssociatedAdvisors &&
            res.data.getSuperMajorCategoriesWithAssociatedAdvisors.length
          ) {
            let options: OptionType[] =
              res.data.getSuperMajorCategoriesWithAssociatedAdvisors.map(
                (item: any) => ({
                  value: item.id,
                  label: item.displayName,
                })
              );
            const uniqueCategories = options.filter(
              (major, index, array) =>
                !array.some((s, i) => s.label === major.label && i < index)
            );
            resolve(uniqueCategories);
          } else {
            resolve([]);
          }
        });
    });
  };

  return (
    <>
      {!user?.isMBAGrad ? (
        <Header />
      ) : user.isPremium ? (
        <MBAHeader />
      ) : (
        <MBAHeaderFree />
      )}

      <Container maxWidth={false}>
        <Helmet>
          <title>
            300+ Experienced Advisors Help with Applications, Essays, Financial
            Aid, Scholarships, and more.
          </title>
          <meta
            name="description"
            content="CollegeAdvisor.com's team of highly trained mentors has graduated from top tier colleges and is ready to help you with your applications, essays, financial aid, scholarships, and more.  They help through both 1-on-1 video sessions and essay reviews powered by the CollegeAdvisor.com Scorecard, a framework developed with Admissions Officers at the most prestigious colleges."
          />
        </Helmet>

        <Grid className={classes.genericHeaderComponent}>
          <Typography variant="h3">Advisors</Typography>

          <Box display="flex" className={classes.advisorFilterBox}>
            <Box className={classes.advisorFilterTextField}>
              <TextField
                className="searchInput"
                variant="outlined"
                placeholder="Search Advisor Name or College"
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  clearTimeout(timeoutId);
                  timeoutId = setTimeout(() => {
                    setSearchQuery(value);
                    currentPage = 1;
                  }, 600);
                }}
              />
            </Box>

            <Box className={classes.searchFilterAsync}>
              <AsyncSelect
                isClearable
                onChange={(interest) => {
                  setAreaOfInterest(interest?.value);
                }}
                defaultOptions
                loadOptions={(input: string) =>
                  loadAreaOfInterestOptions(input, [])
                }
                classNamePrefix="select"
                className="customDropDown"
                placeholder="Area of Interest"
              />
            </Box>
          </Box>

          <Box mt={2.25}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box display="flex" alignItems="center" flexWrap="wrap">
                <ThemeProvider theme={customTheme}>
                  <MaterialUISelect
                    value={filterAdvisorLevel}
                    displayEmpty
                    variant="outlined"
                    onChange={onLevelChange}
                    className={`selectAdvisorLevel ${selectColor(
                      filterAdvisorLevel
                    )}`}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="">Advisor Level</MenuItem>

                    {!!advisorLevels?.length &&
                      advisorLevels.map((item: string, i: number) => (
                        <MenuItem value={item} key={i}>
                          {item}
                        </MenuItem>
                      ))}
                  </MaterialUISelect>
                </ThemeProvider>

                <Grid className="experienceInternational">
                  <FormGroup row>
                    <FormControlLabel
                      label="Has international experience"
                      control={
                        <Checkbox
                          color="primary"
                          checked={hasInternationalExperience}
                          onChange={handleCheckboxChange}
                        />
                      }
                    />
                  </FormGroup>
                </Grid>
              </Box>
            </Collapse>

            <Box
              mt={2.25}
              onClick={() => setOpen(!open)}
              className="advancedBasicSearch"
            >
              <span>{open ? 'Basic Search' : 'Advanced Search'}</span>
            </Box>
          </Box>
        </Grid>

        <MentorsMedia>
          <Query<AllMentorsListQuery>
            query={ALL_MENTORS_QUERY}
            variables={{
              page: 1,
              limit: 9,
              searchQuery,
              sort,
              filter: mentorFilterArray,
              filterType,
              filterAdvisorLevel,
              areaOfInterest,
              hasInternationalExperience,
              studentId: user?.id,
            }}
          >
            {({ data, fetchMore, loading }) => {
              if (loading && !loadingMore)
                return <Loader isSmallLoader={true} />;
              if (!data || !data.allMentors)
                return (
                  <Grid>
                    <p className="text-center p-2">No Advisors Found</p>
                  </Grid>
                );
              const {
                allMentors: { mentors, nextRecord },
              } = data;
              hasNextRecord = !!nextRecord;
              if (mentors)
                mentors.map(async (item) => {
                  if (item !== null) isMentorNull = false;
                });
              if (!mentors || isMentorNull) {
                return isfilterApplied ? (
                  <p>We're sorry, no advisors currently match your filters.</p>
                ) : (
                  <Grid>
                    <p className="text-center p-2">No Advisors Found</p>
                  </Grid>
                );
              }
              const callFetchMore = async () => {
                await fetchMore({
                  variables: {
                    page: currentPage,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (
                      !fetchMoreResult ||
                      !fetchMoreResult.allMentors ||
                      !fetchMoreResult.allMentors.mentors
                    )
                      return prev;
                    const prevMentors =
                      (prev.allMentors && prev.allMentors.mentors) || [];

                    const {
                      allMentors: { mentors, nextRecord },
                    } = fetchMoreResult;

                    return Object.assign({}, prev, {
                      allMentors: {
                        mentors: [...prevMentors, ...mentors],
                        nextRecord,
                        __typename: 'Mentor',
                      },
                    });
                  },
                });
                setLoadingMore(false);
              };

              const loadMore = () => {
                if (!hasNextRecord || loadingMore) return;
                setLoadingMore(true);
                currentPage++;
                callFetchMore();
              };

              return (
                <>
                  <MentorsList
                    mentors={mentors}
                    loadMore={loadMore}
                    isLoadingMore={loadingMore}
                  />

                  {nextRecord && loadingMore && (
                    <Grid className={classes.mentorsLoadMore}>Loading...</Grid>
                  )}
                </>
              );
            }}
          </Query>
        </MentorsMedia>
      </Container>

      <FooterNew />
    </>
  );
};

export default Mentors;
