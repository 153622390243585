import { useContext, useEffect, lazy, Suspense } from 'react';
import Intercom from 'react-intercom';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { withSendBird } from 'sendbird-uikit';

import { AppContext } from '../contexts';

import PrivateRoute from '../components/PrivateRoute';
import PublicRoute from '../components/PublicRoute';
import { Error404, Maintenance } from './errorPages';
import HomePage from './main/HomePage';
import MainLayout from './main/layouts/MainLayout';

import {
  NewLeadParentRegisterPage,
  NewParentRegisterPage,
} from './main/account';

import { getPremiumInfo } from '../common/utility';

import {
  LeadRegisterPage,
  NewForgotPasswordPage,
  NewResetPasswordPage,
} from './login';
import NewLoginPage from './login/NewLoginPage';
import MaterializeLayout from './MaterializeLayout';
import SalesRegisterForm from './SalesRegistration/SalesRegisterForm';

import ScrollToTop from './ScrollToTop';
import ResetPasswordBookingPage from './login/ResetPasswordBookingPage';

const Colleges = lazy(() => import('./main/colleges/ListCollegesPage'));
const SummerOpportunities = lazy(() => import('./summer-opportunities'));
const FindOpportunities = lazy(
  () => import('./summer-opportunities/FindOpportunity')
);
const OpportunityDetail = lazy(
  () => import('./summer-opportunities/OpprtunityDetail')
);
const CollegeDetailPageNew = lazy(
  () => import('./main/colleges/CollegeDetailPageNew')
);
const CollegeDetailPage = lazy(
  () => import('./main/colleges/CollegeDetailPage')
);
const Mentors = lazy(() => import('./main/mentors/ListMentorsPage'));
const TimeSlotBookings = lazy(
  () => import('./main/timeSlotBookings/BookingsList')
);
const StudentConfirm = lazy(
  () => import('./studentConfirmation/studentConfirm')
);
const StudentDecline = lazy(
  () => import('./studentConfirmation/studentDecline')
);
const MyWebinars = lazy(() => import('./webinar/MyWebinars'));
const MBAMilestoneTeams = lazy(
  () => import('./graduateMBAProgram/MBAMilestoneTeams')
);
const MilestoneTeams = lazy(
  () => import('./main/MilestoneTeams/MilestoneTeams')
);
const ApplicationsPageNew = lazy(
  () => import('./main/applications/ApplicationsPage')
);
const ApplicationChecklistsPage = lazy(
  () => import('./main/applications/ApplicationChecklistsPage')
);
const ApplicationDetailPageNew = lazy(
  () => import('./main/applications/ApplicationDetailPage')
);
const MeetingSetLayout = lazy(() => import('./meetingSet/MeetingSetLayout'));
const CollegeFilter = lazy(() => import('./CollegeFilter'));
const ScheduleMeeting = lazy(() => import('./DigitalSet/ScheduleMeeting'));
const ScheduleMeetingMBA = lazy(
  () => import('./DigitalSet/ScheduleMeetingMBA')
);
const SuccessMeetingSent = lazy(
  () => import('./DigitalSet/SuccessMeetingSent')
);
const Pricing = lazy(() => import('./pricing'));
const Chat = lazy(() => import('./main/chat/chat'));
const CreateApplicationSurvey = lazy(
  () => import('./main/CollegeAcceptanceSurvey/CreateApplicationSurvey')
);

function Pages(props: any) {
  const { user } = useContext(AppContext);
  const { setUnreadMessagesCount } = useContext(AppContext);
  const urlParams = new URLSearchParams(window.location.search);
  const fromAdvisor = urlParams.get('adv');
  const isTrial =
    user?.studentTrial?.trialStartedAt && !user?.studentTrial?.trialCompletedAt
      ? true
      : false;

  let initialized: any;
  let sdk: any;

  if (props && props.state) {
    initialized = props.state.stores.sdkStore.initialized;
    sdk = props.state.stores.sdkStore.sdk;
  }

  const useEffectCallBack = () => {
    if (initialized) {
      const { userId } = sdk.currentUser;
      const UNIQUE_USER_HANDLER_ID = `${userId}-user-handler`;

      const meta = {
        userRole: 'Student',
      };
      const sbUser = sdk.currentUser;

      sbUser.createMetaData(meta).catch((error: Error) => console.log(error));

      const userEventHandler = new sdk.UserEventHandler();

      userEventHandler.onTotalUnreadMessageCountUpdated = function (
        totalCount: number
      ) {
        setUnreadMessagesCount(totalCount);
      };

      sdk.addUserEventHandler(UNIQUE_USER_HANDLER_ID, userEventHandler);
      return () => {
        sdk.removeChannelHandler(UNIQUE_USER_HANDLER_ID);
      };
    }
  };

  useEffect(useEffectCallBack, [user, sdk]);

  const name = user ? `${user.firstName || ''} ${user.lastName || ''}` : '';
  const userEmail = user ? user.email : '';
  const { essaysLeft, videosLeft, premiumPackageType } = getPremiumInfo(user);

  if (user && window.analytics) {
    window.analytics.identify(user.id, {
      'Full Name': name,
      'Package Type': premiumPackageType,
      'Number of Essay Credits Left': essaysLeft,
      'Number of Video Chat Sessions Left': videosLeft,
      email: userEmail,
      name,
    });
  }

  const intercomUser = {
    user_id: user && user.id,
    email: user && user.email,
    firstName: user && user.firstName,
    lastName: user && user.lastName,
    name: user && `${user.firstName} ${user.lastName}`,
  };

  return (
    <Router>
      <ScrollToTop />
      <MainLayout>
        {process.env.REACT_APP_DISABLE_INTERCOM_FOR_PREMIUM === 'TRUE'
          ? user &&
            !user.isPremium && <Intercom appID="rn50ve5d" {...intercomUser} />
          : user && <Intercom appID="rn50ve5d" {...intercomUser} />}
        <Suspense fallback={null}>
          <Switch>
            <PublicRoute exact path={'/'} component={HomePage} />
            <PublicRoute exact path={`/maintenance`} component={Maintenance} />

            {user?.isParent && (
              <Redirect
                exact
                path={'/colleges'}
                to={`/account/student-accounts`}
              />
            )}

            {user?.isParent && (
              <Redirect
                exact
                path={'/applications'}
                to={`/account/student-accounts`}
              />
            )}

            {user?.isParent && (
              <Redirect
                exact
                path={`/colleges/:id`}
                to={`/account/student-accounts`}
              />
            )}
            {user?.isParent && (
              <Redirect
                exact
                path={`/colleges/:id/essay-requirements`}
                to={`/account/student-accounts`}
              />
            )}

            {user?.isParent && (
              <Redirect
                exact
                path={`/advisors/calendar/:id`}
                to={`/account/student-accounts`}
              />
            )}
            {user?.isParent && (
              <Redirect
                exact
                path={`/time-slot-bookings`}
                to={`/account/student-accounts`}
              />
            )}
            {user?.isParent && (
              <Redirect path={'/search'} to={`/account/student-accounts`} />
            )}
            {user?.isParent && (
              <Redirect path={'/essays/:id'} to={`/account/student-accounts`} />
            )}
            {user?.isParent && (
              <Redirect path={`/my-orders`} to={`/account/student-accounts`} />
            )}
            {user?.isParent && (
              <Redirect path={`/my-essays`} to={`/account/student-accounts`} />
            )}
            {user?.isParent && (
              <Redirect
                path={`/create-order`}
                to={`/account/student-accounts`}
              />
            )}
            {user?.isParent && (
              <Redirect
                path={`/unsubscribe/application/:uuid`}
                to={`/account/student-accounts`}
              />
            )}
            {user?.isParent && (
              <Redirect
                path={`/unsubscribe/gameplan/:uuid`}
                to={`/account/student-accounts`}
              />
            )}

            {user?.isParent && (
              <Redirect
                exact
                path={'/my-webinars'}
                to={`/account/student-accounts`}
              />
            )}
            {user?.isParent && (
              <Redirect
                exact
                path={'/applications'}
                to={`/account/student-accounts`}
              />
            )}
            {user?.isParent && (
              <Redirect
                exact
                path={'/applications/:id'}
                to={`/account/student-accounts`}
              />
            )}
            {user?.isParent && (
              <Redirect
                exact
                path={'/applications/checklist'}
                to={`/account/student-accounts`}
              />
            )}

            {!user && !fromAdvisor && (
              <Redirect exact path={'/opportunity-detail/:id'} to={'/login'} />
            )}

            <PublicRoute exact path={`/colleges-old`} component={Colleges} />
            <PublicRoute exact path={`/login`} component={NewLoginPage} />
            <PublicRoute
              exact
              path={`/sales/register`}
              component={SalesRegisterForm}
            />

            <PublicRoute
              exact
              path={`/register`}
              component={LeadRegisterPage}
            />

            {!user?.isMBAGrad && (
              <PublicRoute
                exact
                path={`/extracurricular-opportunities`}
                component={SummerOpportunities}
              />
            )}

            {!user?.isMBAGrad && (
              <PublicRoute
                exact
                path={`/extracurricular-opportunities/find`}
                component={FindOpportunities}
              />
            )}
            <Redirect
              exact
              path={'/summer-opportunities'}
              to={`/extracurricular-opportunities`}
            />

            <Redirect
              exact
              path={'/summer-opportunities/find'}
              to={`/extracurricular-opportunities/find`}
            />
            <PublicRoute
              exact
              path={`/opportunity-detail/:id`}
              component={OpportunityDetail}
            />
            <PublicRoute
              exact
              path={`/colleges/:id`}
              component={CollegeDetailPageNew}
            />

            <PublicRoute
              exact
              path={`/colleges/:id/essay-requirements`}
              component={CollegeDetailPage}
            />
            {!isTrial && !user?.isMBAGrad && (
              <PublicRoute exact path={`/advisors`} component={Mentors} />
            )}
            {!isTrial && <Redirect exact path={'/mentors'} to={`/advisors`} />}

            <PrivateRoute
              exact
              path={`/time-slot-bookings`}
              component={TimeSlotBookings}
            />

            <PublicRoute
              path={`/parent-accept/:token`}
              component={StudentConfirm}
            />
            <PublicRoute
              path={`/parent-decline/:token`}
              component={StudentDecline}
            />

            <PublicRoute exact path={'/my-webinars'} component={MyWebinars} />

            <PrivateRoute
              exact
              path={'/milestone-teams'}
              component={user?.isMBAGrad ? MBAMilestoneTeams : MilestoneTeams}
            />

            <PublicRoute
              path={`/reset-password/:token`}
              component={NewResetPasswordPage}
            />

            <PublicRoute
              path={`/reset-password-booking/:token`}
              component={ResetPasswordBookingPage}
            />

            <PublicRoute
              path={`/invite/:invitationToken`}
              component={NewParentRegisterPage}
            />
            <PublicRoute
              path={`/leaduser/invite/:invitationToken`}
              component={NewLeadParentRegisterPage}
            />
            <PublicRoute
              exact
              path={`/forgot-password`}
              component={NewForgotPasswordPage}
            />
            {!isTrial && !user?.isMBAGrad && (
              <PrivateRoute
                exact
                path={'/applications'}
                component={ApplicationsPageNew}
              />
            )}

            {!user?.isMBAGrad && (
              <PrivateRoute
                exact
                path={'/applications/checklist'}
                component={ApplicationChecklistsPage}
              />
            )}
            <PrivateRoute
              exact
              path={'/applications/:id'}
              component={ApplicationDetailPageNew}
            />

            <PublicRoute
              exact
              path={'/meeting/:id'}
              component={MeetingSetLayout}
            />

            {!isTrial && !user?.isMBAGrad && (
              <PublicRoute exact path={'/colleges'} component={CollegeFilter} />
            )}

            {user?.isParent && (
              <Redirect exact path={'/schedule'} to={'/schedule/parent'} />
            )}

            {user?.isParent && (
              <Redirect
                exact
                path={'/schedule/student'}
                to={'/schedule/parent'}
              />
            )}

            {user && !user?.isParent && (
              <Redirect exact path={'/schedule'} to={'/schedule/student'} />
            )}

            {user && !user?.isParent && (
              <Redirect
                exact
                path={'/schedule/parent'}
                to={'/schedule/student'}
              />
            )}

            <PublicRoute
              exact
              path={['/schedule', '/schedule/parent', '/schedule/student']}
              component={ScheduleMeeting}
            />

            {process.env.REACT_APP_ENABLE_DIGITAL_MEETING_MBA === 'TRUE' && (
              <PublicRoute
                exact
                path={'/schedule/mba'}
                component={ScheduleMeetingMBA}
              />
            )}

            <PublicRoute
              exact
              path={'/meeting-confirmed'}
              component={SuccessMeetingSent}
            />

            <PrivateRoute exact path={`/pricing`} component={Pricing} />
            {user && !user.isParent && (
              <PrivateRoute exact path={`/chat`} component={Chat} />
            )}

            <Route
              exact
              path={`/survey-form/:usertoken`}
              component={CreateApplicationSurvey}
            />
            <MaterializeLayout />

            <Route component={Error404} />
          </Switch>
        </Suspense>
      </MainLayout>
    </Router>
  );
}

const mapSendbirdStateToProps = (state: any) => {
  return {
    state,
  };
};

export default withSendBird(Pages, mapSendbirdStateToProps);
