import { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import {
  AppBar,
  Box,
  Button,
  createMuiTheme,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  MuiThemeProvider,
  SwipeableDrawer,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import client from '../../apollo';
import { GET_USER_BY_TOKEN } from '../../pages/main/clientEnrollment/gql';

import CustomTooltip from '../CustomTooltip';
import ParentAccessAccountModal from '../ParentAccessAccountModal';
import Logo from './Logo';

import { MEETING_SET, PARENT_TOKEN, TOKEN } from '../../constants';
import { AppContext, User } from '../../contexts';

import { useStylesHeader } from './HeaderStyle';

import Colors from '../../common/colors';
import Fonts from '../../common/fonts';

import NO_IMAGE from '../../img/empty-badge.svg';
import MEETING from '../../img/meeting.svg';
import UPGRADE_ICON from '../../img/upgrade-icon.svg';
import { customTheme } from '../../pages/MaterializeCss';
import { GET_ALL_STUDENT_MENTORS } from '../../graphql/queries/studentMentors';
import { getAllStudentMentors_allStudentMentors } from '../../graphql/queries/studentMentors/__generated__/getAllStudentMentors';
import { ARROW_IC, CHAT_IC, DOWN_ARROW, LOCKED_CHAT, PHONE_IC, PROFILE_IC } from './HeaderIcons';

interface Props {
  navigateTo?: (path: string) => void;
}

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

const ESSAY_NAV = [
  {
    title: 'Extracurricular Opportunities',
    link: '/extracurricular-opportunities',
    hasIcon: false,
  },
  {
    title: 'Essay Guides',
    link: 'https://collegeadvisor.wpengine.com/essay-guides',
    hasIcon: true,
  },
];

const NAV_ITEMS = [
  {
    title: 'Advisors',
    link: '/advisors',
    hasIcon: false,
  },
  {
    title: 'Colleges',
    link: '/colleges',
    hasIcon: false,
  },
  {
    title: 'Webinars',
    link: '/webinars',
  },
];

const MOBILE_USER_NAV_ITEMS = [
  {
    title: 'Profile',
    link: '/account/profile',
    hasIcon: false,
  },

  {
    title: 'My Checklist',
    link: '/applications/checklist',
    hasIcon: false,
  },
];

MOBILE_USER_NAV_ITEMS.push({
  title: 'My Webinars',
  link: '/my-webinars?origin=user-menu',
  hasIcon: false,
});

const HeaderLoginRegister: FC<Props> = () => {
  const { user, setUser, setIsLoggedIn } = useContext(AppContext);
  const [tokenUser, setTokenUser] = useState<User | undefined>();
  const { unreadMessagesCount } = useContext(AppContext);
  const urlParams = new URLSearchParams(window.location.search);
  const goBack = urlParams.get('_goback');
  const currentYear = new Date().getFullYear();
  const [allStudentMentors, setAllStudentMentors] = useState<
    (getAllStudentMentors_allStudentMentors | null)[] | null
  >(null);

  const APPLICATION_NAV = [
    {
      title: 'Home',
      link: !user?.isParent ? '/home' : '/account/student-accounts',
      hasIcon: false,
    },
  ];

  APPLICATION_NAV.push({
    title: 'My Program',
    link: '/applications',
    hasIcon: false,
  });
  const mobileNavItems = user
    ? [...APPLICATION_NAV, ...NAV_ITEMS, ...ESSAY_NAV, ...MOBILE_USER_NAV_ITEMS]
    : NAV_ITEMS;
  const classes = useStylesHeader();
  if (process.env.REACT_APP_ACTIVATE_ANALYTICS && window.Intercom)
    window.Intercom('update');

  const [openParentAccountAccess, setOpenParentAccountAccess] = useState(false);
  const [drawer, setDrawer] = useState(false);

  const isPremiumUser = user && user.isPremium;
  const hasMentors =
    allStudentMentors && allStudentMentors.length > 0;
  const premiumPackageType = user && user.premiumPackageType;

  const toggleParentAccountAccessModel = () => {
    setOpenParentAccountAccess(!openParentAccountAccess);
  };

  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setDrawer(!drawer);
    };

  const popoverText = () => {
    if (isPremiumUser && hasMentors) {
      return premiumPackageType || 'MY PLAN';
    }
    if (!isPremiumUser && !hasMentors) {
      return 'Upgrade';
    }
    if (isPremiumUser) {
      return premiumPackageType || 'MY PLAN';
    }
    if (hasMentors) {
      return `Upgrade`;
    }
  };

  const packageColor = () => {
    if (premiumPackageType === 'Ivy Plus') {
      return Colors.GREEN_EIGHT;
    }
    if (premiumPackageType === 'Starter' || premiumPackageType === 'Scholar') {
      return Colors.BLACK_THIRTEEN;
    }
    if (premiumPackageType === 'Elite') {
      return Colors.BLUE_TWELVE;
    }
    if (premiumPackageType === 'Premium') {
      return Colors.LIGHT_GREEN_ONE;
    }
    if (premiumPackageType === 'Platinum') {
      return Colors.BLUE_SEVEN;
    }
    if (premiumPackageType === 'My Plan') {
      return Colors.BLUE_BETA;
    }
    if (premiumPackageType === 'Scholars') {
      return Colors.BLACK_THIRTEEN;
    }
    if (premiumPackageType === 'CWS') {
      return Colors.GRAY_TWENTYTWO;
    }
  };

  const getUserByToken = () => {
    if (params.userToken) {
      try {
        const apiCalling = async () => {
          const { data } = await client.query({
            query: GET_USER_BY_TOKEN,
            fetchPolicy: 'no-cache',
            variables: { userToken: params.userToken },
          });

          if (data && data.getUserByToken) {
            setTokenUser(data.getUserByToken);
          }
          if (user) {
            const { data: studentMentors } = await client.query({
              query: GET_ALL_STUDENT_MENTORS,
              fetchPolicy: 'network-only',
            });
            if (studentMentors && studentMentors.allStudentMentors) {
              setAllStudentMentors(studentMentors.allStudentMentors);
            }
          }
        };
        apiCalling();
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(getUserByToken, []);

  useEffect(() => {}, [unreadMessagesCount]);

  const theme = createMuiTheme({
    overrides: {
      MuiContainer: {
        root: {
          maxWidth: 1328,
        },
      },

      MuiAppBar: {
        positionRelative: {
          backgroundColor: 'transparent !important',
          zIndex: 'auto',
          boxShadow: 'none !important',
          width: 'auto',
        },

        root: {
          '@global': {
            a: {
              textDecoration: 'none !important',
            },

            button: {
              outline: 'none',
            },
          },
        },
      },

      MuiPaper: {
        elevation4: {
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05) !important',
          background: `${Colors.WHITE_ONE} !important`,
        },
      },

      MuiToolbar: {
        regular: {
          minHeight: 'auto !important',
          fontSize: 18,
          padding: 0,
        },
      },

      MuiDrawer: {
        paper: {
          paddingTop: 20,

          '@global': {
            a: {
              textDecoration: 'none !important',
            },
          },
        },
      },

      MuiPopover: {
        paper: {
          '@global': {
            a: {
              textDecoration: 'none !important',
            },
          },
        },
      },
    },
  });

  const EX_LINK = () => {
    return (
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.25">
          <path
            d="M14.3039 0H8.58958C8.1951 0 7.87529 0.31981 7.87529 0.714294C7.87529 1.10878 8.1951 1.42859 8.58958 1.42859H12.5794L5.22741 8.78067C4.94845 9.05963 4.94845 9.51184 5.22741 9.7908C5.36684 9.93027 5.54964 10 5.73244 10C5.91524 10 6.09808 9.9303 6.23754 9.79077L13.5896 2.43872V6.42859C13.5896 6.82307 13.9094 7.14288 14.3039 7.14288C14.6984 7.14288 15.0182 6.82307 15.0182 6.42859V0.714294C15.0182 0.31981 14.6983 0 14.3039 0Z"
            fill={Colors.BLACK_TWELVE}
          />
          <path
            d="M11.5909 6.8182C11.2144 6.8182 10.9091 7.12347 10.9091 7.50002V13.6364H1.36362V4.09092H7.50001C7.87656 4.09092 8.18183 3.78565 8.18183 3.40909C8.18183 3.03254 7.87656 2.72729 7.50001 2.72729H0.681827C0.305274 2.72729 0 3.03257 0 3.40912V14.3182C0 14.6948 0.305274 15 0.681827 15H11.5909C11.9675 15 12.2728 14.6948 12.2728 14.3182V7.50002C12.2727 7.12347 11.9675 6.8182 11.5909 6.8182Z"
            fill={Colors.BLACK_TWELVE}
          />
        </g>
      </svg>
    );
  };

  const notUpdatedSection = () => {
    return (
      <Box className={clsx(classes.graySection, classes.upgradeThemeSection)}>
        <Box className={classes.upGradeHeading}>
          <img src={UPGRADE_ICON} alt="upgrade" />
          Upgrade Today
        </Box>
        <Box>
          {(user?.highSchoolYear === (currentYear + 1).toString() ||
            user?.highSchoolYear === (currentYear + 2).toString() ||
            user?.highSchoolYear === (currentYear + 3).toString()) &&
          !user?.isPremium ? (
            <>
              <Typography variant="body1" className="upgradeSubtitle">
                Schedule a free strategy meeting with an Admissions Specialist
                to learn more about our admissions packages.
              </Typography>
              <a href="/schedule">
                <Button
                  className={classes.callButton}
                  variant="contained"
                  style={{ textTransform: 'inherit' }}
                >
                  <img src={MEETING} alt="" style={{ paddingRight: '10px' }} />{' '}
                  Schedule a Meeting
                </Button>
              </a>
            </>
          ) : (
            <>
              <Typography variant="body1" className="upgradeSubtitle">
                Talk to one of our Admissions Coordinators to learn more about
                our admissions packages.
              </Typography>
              <a href="tel: +1 (844) 343-6272">
                <Button
                  className={classes.callButton}
                  variant="contained"
                  style={{ textTransform: 'capitalize' }}
                >
                  <Box marginRight={1}>{PHONE_IC()}</Box> Call Now
                </Button>
              </a>
            </>
          )}
        </Box>

        <a href="tel:+1 (844) 343-6272">
          <Typography variant="body2" className="phoneNumberUpgrade">
            +1 (844) 343-6272
          </Typography>
        </a>
      </Box>
    );
  };

  return (
    <MuiThemeProvider theme={theme}>
      <AppBar position="relative" color="inherit">
        <Toolbar>
          <Box
            display="flex"
            flex={1}
            fontFamily={Fonts.POPPINS_FONT}
            className={classes.navItems}
          >
            {user &&
              APPLICATION_NAV.map((item) => (
                <Link
                  to={item.link}
                  className={classes.navLink}
                  key={item.title}
                >
                  {item.title}
                </Link>
              ))}

            {NAV_ITEMS.map((item) => (
              <Link to={item.link} className={classes.navLink} key={item.title}>
                {item.title}
              </Link>
            ))}

            {user && (
              <Box className={classes.dropDownLink}>
                <a className={classes.navLink}>
                  <Box display="flex" alignItems="center">
                    More <Box marginLeft={1}>{DOWN_ARROW()}</Box>
                  </Box>
                </a>

                <Box
                  className={`${classes.dropDownContainer} dropdown-container`}
                >
                  <ul>
                    {!user?.isPremium &&
                      process.env.REACT_APP_ENABLE_CLIENT_ENROLLMENT ===
                        'TRUE' &&
                      process.env.REACT_APP_ENABLE_CLIENT_ENROLLMENT_LINK ===
                        'TRUE' &&
                      !user.isParent && (
                        <li>
                          <Link
                            to="/client-enrollment"
                            className={classes.navLink}
                          >
                            Client Enrollment
                          </Link>
                        </li>
                      )}

                    {ESSAY_NAV.map((item, i) => (
                      <li key={item.title}>
                        {item.hasIcon ? (
                          <a
                            href={item.link}
                            className={classes.navLink}
                            target="_blank"
                          >
                            <Box display="flex" alignItems="center">
                              {item.title}

                              <Box marginLeft={1.4}>{EX_LINK()}</Box>
                            </Box>
                          </a>
                        ) : (
                          <Link to={item.link} className={classes.navLink}>
                            {item.title}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </Box>
              </Box>
            )}
          </Box>

          {user == null &&
            (!tokenUser ? (
              <Hidden only={['sm', 'xs']}>
                <ThemeProvider theme={customTheme}>
                  <Box>
                    <Link
                      to={`${
                        window.location.pathname !== '/login' &&
                        window.location.pathname !== '/forgot-password'
                          ? `/login?_goback=${window.location.pathname}`
                          : '/login'
                      }`}
                    >
                      <Button variant="outlined">LOG IN</Button>
                    </Link>

                    <Link
                      to={`${
                        goBack
                          ? `/register${window.location.search}`
                          : window.location.pathname !== '/login' &&
                            window.location.pathname !== '/forgot-password'
                          ? `/register?_goback=${window.location.pathname}`
                          : '/register'
                      }`}
                    >
                      <Button variant="contained" className="warningButton">
                        REGISTER
                      </Button>
                    </Link>
                  </Box>
                </ThemeProvider>
              </Hidden>
            ) : (
              <Box>
                <Typography variant="h6">
                  {tokenUser?.firstName + ' ' + tokenUser?.lastName}
                </Typography>
              </Box>
            ))}

          {user && (
            <Box display="flex" alignItems="center">
              <Box
                position="relative"
                marginLeft={2.5}
                className={classes.profileBadgeContainer}
              >
                <Box className={classes.profileAvtar}>
                  {!isPremiumUser ? (
                    <Box display="flex" alignItems="center">
                      {user?.highSchoolYear === (currentYear + 1).toString() ||
                      user?.highSchoolYear === (currentYear + 2).toString() ||
                      user?.highSchoolYear === (currentYear + 3).toString() ? (
                        <a href="/schedule">
                          <Button
                            variant="contained"
                            className={classes.upGrade}
                          >
                            {popoverText()}
                          </Button>
                        </a>
                      ) : (
                        <a
                          href="https://collegeadvisor.com/pricing?utm_source=product-upgrade"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <CustomTooltip title="Click to explore upgrade options">
                            <Button
                              variant="contained"
                              className={classes.upGrade}
                            >
                              {popoverText()}
                            </Button>
                          </CustomTooltip>
                        </a>
                      )}

                      <Box
                        className={`${classes.profileBadge} profile-picture`}
                        borderRadius="50%"
                        style={{
                          backgroundImage: user.pictureUrl
                            ? 'url(' + user.pictureUrl + ')'
                            : '',
                        }}
                        marginLeft={2.5}
                      >
                        {user.pictureUrl ? <></> : PROFILE_IC()}
                      </Box>
                    </Box>
                  ) : (
                    <Box className={classes.premiumPopupContainer}>
                      <Button
                        variant="contained"
                        className={`${classes.upGrade} with-profilePic`}
                        style={{
                          color: packageColor(),
                          backgroundColor: 'transparent',
                          border: '1px solid #ececec',
                        }}
                      >
                        <Box
                          component="span"
                          color={
                            user?.premiumPackageColor || Colors.BLACK_SEVEN
                          }
                        >
                          {popoverText()}
                        </Box>

                        <Box
                          className={`${classes.profileBadge}`}
                          borderRadius="50%"
                          style={{
                            backgroundImage: user.pictureUrl
                              ? 'url(' + user.pictureUrl + ')'
                              : '',
                          }}
                          marginLeft={2.5}
                        >
                          {user.pictureUrl ? <></> : PROFILE_IC()}
                        </Box>
                      </Button>
                    </Box>
                  )}

                  <Box
                    position="absolute"
                    top={45}
                    zIndex={99}
                    className={classes.profileBadgeMenu}
                    id="profileBadgeMenu"
                  >
                    <Grid className={classes.profilePopover}>
                      <Grid className={classes.profileCard}>
                        <Box width="100%">
                          <Box className={classes.userName} paddingBottom={2}>
                            <Box component="h3" paddingBottom={1}>
                              {`${user.firstName || ''} ${user.lastName || ''}`}
                            </Box>

                            {user.isParent && (
                              <Box
                                onClick={() => {
                                  toggleParentAccountAccessModel();
                                }}
                                className={classes.changeAccount}
                                color={packageColor() || Colors.BLUE_SEVEN}
                              >
                                CHANGE ACCOUNT {ARROW_IC()}
                              </Box>
                            )}

                            {localStorage.getItem(PARENT_TOKEN) && (
                              <Box
                                onClick={async () => {
                                  const oldToken =
                                    localStorage.getItem(PARENT_TOKEN);

                                  if (oldToken) {
                                    await localStorage.setItem(TOKEN, oldToken);
                                    localStorage.removeItem(PARENT_TOKEN);
                                    if ((window.location.pathname = '/home'))
                                      window.location.replace(
                                        '/account/student-accounts?parent=true'
                                      );
                                    else
                                      window.location.pathname =
                                        '/account/student-accounts?parent=true';
                                  }
                                }}
                                className={classes.changeAccount}
                                color={packageColor() || Colors.BLUE_SEVEN}
                              >
                                Continue to My Account {ARROW_IC()}
                              </Box>
                            )}
                          </Box>

                          {!isPremiumUser && notUpdatedSection()}

                          {(isPremiumUser || hasMentors) && (
                            <Box className={classes.graySection}>
                              {hasMentors && (
                                <Box>
                                  <Box
                                    className={classes.premiumHeading}
                                    component="h4"
                                    color={packageColor() || Colors.BLACK_THREE}
                                  >
                                    Advisors
                                  </Box>

                                  {user &&
                                    allStudentMentors &&
                                    allStudentMentors.length > 0 &&
                                    allStudentMentors.map((item) => {
                                      let id: number = NaN;
                                      let uuid: string | null = '';
                                      let mentorImage = null;
                                      let mentorName = '';
                                      if (item && item.mentor) {
                                        id = item.mentor.id;
                                        uuid = item.mentor.uuid;
                                        mentorImage = item.mentor.pictureUrl;
                                        const firstName = item.mentor.firstName
                                          ? item.mentor.firstName
                                          : '--';
                                        const lastName = item.mentor.lastName
                                          ? item.mentor.lastName
                                          : '--';
                                        mentorName = `${firstName} ${lastName}`;
                                      }

                                      return (
                                        <Box
                                          key={id}
                                          display="flex"
                                          alignItems="center"
                                          className={
                                            classes.mentorsListContainer
                                          }
                                        >
                                          <Box
                                            className={classes.mentorsList}
                                            flex={1}
                                            paddingRight={1.5}
                                          >
                                            <Link
                                              to={`/advisors/${id}/${uuid}`}
                                            >
                                              <Box className="advisor-image">
                                                {mentorImage ? (
                                                  <img
                                                    src={mentorImage}
                                                    alt={mentorName}
                                                    loading="lazy"
                                                  />
                                                ) : (
                                                  <img
                                                    src={NO_IMAGE}
                                                    alt="dummy user"
                                                  />
                                                )}
                                              </Box>

                                              <Box
                                                flex={1}
                                                fontSize={16}
                                                fontWeight={500}
                                                textAlign="left"
                                                paddingLeft={1.5}
                                                whiteSpace="nowrap"
                                                overflow="hidden"
                                                textOverflow="ellipsis"
                                              >
                                                {mentorName}
                                              </Box>
                                            </Link>
                                          </Box>
                                        </Box>
                                      );
                                    })}
                                </Box>
                              )}
                            </Box>
                          )}
                        </Box>
                      </Grid>

                      <Box padding="10px 6px 0">
                        <Box className={classes.linkMenuItem}>
                          <Link to="/account/profile">My Account</Link>
                        </Box>

                        <Box className={classes.linkMenuItem}>
                          <Link to="/applications/checklist">My Checklist</Link>
                        </Box>

                        <Box className={classes.linkMenuItem}>
                          <Link to="/my-Webinars?origin=user-menu">
                            My Webinars
                          </Link>
                        </Box>

                        <Box className={classes.linkMenuItem}>
                          <Link to="/meetings">My Meetings</Link>
                        </Box>

                        {window.location.hostname !==
                        'app.collegeadvisor.com' ? (
                          <Box paddingTop={5.2}>
                            <Link
                              to="/"
                              className={classes.popoverSignOut}
                              onClick={() => {
                                localStorage.removeItem(TOKEN);
                                localStorage.removeItem(PARENT_TOKEN);
                                localStorage.removeItem(MEETING_SET);
                                setIsLoggedIn(false);
                                setUser(null);
                                document.cookie =
                                  '_caLoggedIn=false;domain=collegeadvisor.com';
                              }}
                            >
                              <Box
                                component="span"
                                color={packageColor() || Colors.BLUE_SEVEN}
                              >
                                SIGN OUT
                              </Box>
                            </Link>
                          </Box>
                        ) : (
                          <Box
                            paddingTop={5.2}
                            color={packageColor() || Colors.BLACK_ONE}
                          >
                            <a
                              href="https://www.collegeadvisor.com"
                              className={classes.popoverSignOut}
                              onClick={() => {
                                localStorage.removeItem(TOKEN);
                                localStorage.removeItem(PARENT_TOKEN);
                                localStorage.removeItem(MEETING_SET);
                                setIsLoggedIn(false);
                                setUser(null);
                                document.cookie =
                                  '_caLoggedIn=false;domain=collegeadvisor.com';
                              }}
                            >
                              <Box
                                component="span"
                                color={packageColor() || Colors.BLUE_SEVEN}
                              >
                                SIGN OUT
                              </Box>
                            </a>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Box>
                </Box>
              </Box>

              {user &&
                !user.isParent &&
                (isPremiumUser ? (
                  <Grid className={classes.chatDisplay}>
                    <Box position="relative" marginLeft={2.5}>
                      <Link to={'/chat'}>
                        {CHAT_IC()}

                        {(user.unreadMessagesCount &&
                          user.unreadMessagesCount > 0) ||
                          (unreadMessagesCount && unreadMessagesCount > 0 && (
                            <Box className={classes.greenDot}></Box>
                          ))}
                      </Link>
                    </Box>
                  </Grid>
                ) : (
                  <Grid className={classes.chatDisplay}>
                    <Box marginLeft={2.5}>
                      <Link to={'/chat'}>{LOCKED_CHAT()}</Link>
                    </Box>
                  </Grid>
                ))}
            </Box>
          )}

          <Box
            className={clsx(classes.hamburgerMenu, classes.hamburgerMenuLogin)}
          >
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer()}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>

        <SwipeableDrawer
          anchor="left"
          open={drawer}
          onClose={toggleDrawer()}
          onOpen={toggleDrawer()}
        >
          <div
            role="presentation"
            onClick={toggleDrawer()}
            onKeyDown={toggleDrawer()}
            style={{ width: 250 }}
          >
            <List>
              {user && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  padding="0 16px 20px 16px"
                >
                  <Box
                    className={`${classes.profileBadge} profile-picture`}
                    borderRadius="50%"
                    style={{
                      backgroundImage: user.pictureUrl
                        ? 'url(' + user.pictureUrl + ')'
                        : '',
                    }}
                    minWidth={36}
                    margin="0 !important"
                  >
                    {user.pictureUrl ? <></> : PROFILE_IC()}
                  </Box>

                  {!user.isParent && (
                    <Box position="relative">
                      {isPremiumUser ? (
                        <Link to={'/chat'} className={classes.navLink}>
                          {CHAT_IC()}

                          {(user.unreadMessagesCount &&
                            user.unreadMessagesCount > 0) ||
                            (unreadMessagesCount && unreadMessagesCount > 0 && (
                              <Box className={classes.greenDot}></Box>
                            ))}
                        </Link>
                      ) : (
                        <Link to={'/chat'} className={classes.navLink}>
                          {LOCKED_CHAT()}
                        </Link>
                      )}
                    </Box>
                  )}
                </Box>
              )}

              {!user && (
                <ListItem>
                  <Link to="/applications" className={classes.navLink}>
                    Home
                  </Link>
                </ListItem>
              )}

              {mobileNavItems.map((item) => (
                <ListItem key={item.title}>
                  {item.hasIcon ? (
                    <a
                      href={item.link}
                      className={classes.navLink}
                      target="_blank"
                    >
                      <Box display="flex" alignItems="center">
                        {item.title}

                        <Box marginLeft={1.4}>{EX_LINK()}</Box>
                      </Box>
                    </a>
                  ) : (
                    <Link to={item.link} className={classes.navLink}>
                      {item.title}
                    </Link>
                  )}
                </ListItem>
              ))}

              {user && user.isParent && (
                <ListItem>
                  <Box
                    onClick={() => {
                      toggleParentAccountAccessModel();
                    }}
                    className={classes.navLink}
                  >
                    Change Account
                  </Box>
                </ListItem>
              )}

              {localStorage.getItem(PARENT_TOKEN) && (
                <ListItem>
                  <Box
                    onClick={async () => {
                      const oldToken = localStorage.getItem(PARENT_TOKEN);

                      if (oldToken) {
                        await localStorage.setItem(TOKEN, oldToken);
                        localStorage.removeItem(PARENT_TOKEN);
                        if ((window.location.pathname = '/home'))
                          window.location.replace(
                            '/account/student-accounts?parent=true'
                          );
                        else
                          window.location.pathname =
                            '/account/student-accounts?parent=true';
                      }
                    }}
                    className={classes.navLink}
                  >
                    Continue to My Account {ARROW_IC()}
                  </Box>
                </ListItem>
              )}

              {user && (
                <>
                  <ListItem>
                    {isPremiumUser ? (
                      <Button
                        variant="contained"
                        className={classes.upGrade}
                        style={{ backgroundColor: packageColor() }}
                      >
                        {popoverText()}
                      </Button>
                    ) : (
                      notUpdatedSection()
                    )}
                  </ListItem>

                  <ListItem>
                    <Link
                      to="/"
                      onClick={() => {
                        localStorage.removeItem(TOKEN);
                        localStorage.removeItem(PARENT_TOKEN);
                        localStorage.removeItem(MEETING_SET);
                        setUser(null);
                        document.cookie =
                          '_caLoggedIn=false;domain=collegeadvisor.com';
                      }}
                      className={classes.navLink}
                      style={{ color: `${packageColor()} !important` }}
                    >
                      Sign Out
                    </Link>
                  </ListItem>
                </>
              )}

              {!user && (
                <ThemeProvider theme={customTheme}>
                  <Box>
                    <ListItem>
                      <Link
                        to={`${
                          window.location.pathname !== '/login' &&
                          window.location.pathname !== '/forgot-password'
                            ? `/login?_goback=${window.location.pathname}`
                            : '/login'
                        }`}
                      >
                        <Button variant="outlined">LOG IN</Button>
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link
                        to={`${
                          goBack
                            ? `/register${window.location.search}`
                            : window.location.pathname !== '/login' &&
                              window.location.pathname !== '/forgot-password'
                            ? `/register?_goback=${window.location.pathname}`
                            : '/register'
                        }`}
                      >
                        <Button variant="contained" className="warningButton">
                          REGISTER
                        </Button>
                      </Link>
                    </ListItem>
                  </Box>
                </ThemeProvider>
              )}
            </List>

            <List>
              <ListItem>
                <Logo />
              </ListItem>
            </List>
          </div>
        </SwipeableDrawer>
      </AppBar>

      <ParentAccessAccountModal
        open={openParentAccountAccess}
        onClose={toggleParentAccountAccessModel}
        setOpenParentAccountAccess={setOpenParentAccountAccess}
      />
    </MuiThemeProvider>
  );
};

export default HeaderLoginRegister;
