import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import colors from '../../../common/colors';
import fonts from '../../../common/fonts';

import CALENDAR_ICON from '../../../img/calendar-picker.svg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    upGrade: {
      color: colors.WHITE_ONE,
      background: colors.YELLOW_THREE,
      width: 154,
      height: 46,
      padding: '5px 6px !important',
      fontSize: '18px !important',
      fontWeight: 600,
      borderRadius: 25,

      '& span': {
        fontSize: '18px !important',
        textTransform: 'uppercase',
      },

      '&:hover': {
        backgroundColor: colors.YELLOW_FOUR,
      },

      [theme.breakpoints.down('sm')]: {
        marginBottom: 14,
        fontSize: '14.1538px !important',
      },
    },

    cancelPlanBox: {
      padding: '16px 24px 20px 16px',
      background: '#FFF8E4',
      borderRadius: 15,
      marginBottom: 15,
      display: 'flex',
      alignItems: 'flex-start',

      '& img': {
        margin: '4px 20px 0px 0px',
      },

      '& .cautionTitle': {
        color: '#272929',
        fontFamily: fonts.INTER,
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 4,
      },

      '& .cautionDescription': {
        color: '#7D7F7F',
        fontWeight: 400,
        fontSize: 14,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
      },
      '&.badDebtPlan': {
        background: '#ffefeb',
      },
    },

    passwordLabels: {
      marginBottom: 4,
      fontFamily: fonts.INTER,
      fontSize: 14,
      letterSpacing: '-0.006em',
      color: '#272929',
    },

    texfieldLabels: {
      paddingLeft: 2,
      marginBottom: 4,
      lineHeight: '24px',
    },

    labelStyle: {
      '& p': {
        lineHeight: '24px',
        marginBottom: 4,
      },
    },

    videoChatTypography: {
      color: colors.BLACK_FOURTEEN,
    },
    planTypography: {
      fontFamily: fonts.POPPINS_FONT,
      fontWeight: 'bold',
      fontSize: 36,
      letterSpacing: '-0.022em',
      lineHeight: '42px',
      marginBottom: 4,
      '& h3': {
        display: 'inline-block',
      },
    },
    statusStyle: {
      textTransform: 'capitalize',
    },

    mainContainer: {
      marginBottom: 50,
      minHeight: 'calc(100vh - 480px)',

      '& .loginPasswordContainer': {
        maxWidth: 1328,
        margin: '0px auto',
      },

      '& .MuiInputLabel-formControl': {
        marginTop: -15,
      },

      '& .MuiInputLabel-shrink': {
        marginTop: -8,
      },

      '& .MuiButton-containedPrimary': {
        backgroundColor: `${colors.BLUE_SEVEN} !important`,
      },
    },

    actionButtons: {
      margin: '30px 0',
      border: '1px solid #D4D4D4',
      borderRadius: '15px',
      padding: '20px',
    },

    actionButtonsFile: {
      margin: '30px 0',
      border: '1px solid #2F80ED',
      borderRadius: '15px',
      padding: '20px',
    },

    profileLogo: {
      width: 62,
      height: 62,
      backgroundColor: '#e1e1e1',
      borderRadius: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      marginRight: 24,

      '& img': {
        objectFit: 'cover !important',
        objectPosition: 'top !important',
      },

      '@media only screen and (max-width: 767px)': {
        marginRight: 16,
      },
    },

    plansButton: {
      '@media only screen and (max-width: 991px)': {
        marginTop: 16,
      },
    },

    profileFooter: {
      padding: '0px 30px 20px',

      '@media only screen and (max-width: 767px)': {
        padding: '0px 0px 20px',
        marginTop: 45,
      },

      '& .profile_footer_inner': {
        borderTop: `1px solid ${colors.GRAY_EIGHT}`,
        width: '100%',
        paddingTop: 19,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        '@media only screen and (max-width: 767px)': {
          flexDirection: 'column-reverse',
          padding: '26px 20px 8px',

          '& button': {
            width: '100%',
            marginBottom: `28px !important`,
          },
        },
      },
    },

    AdvisorIconStyle: {
      marginRight: '20px',
    },
    packageList: {
      marginTop: '24px !important',
    },

    packageIncludeBox: {
      [theme.breakpoints.down('sm')]: {
        borderLeft: 0,
        paddingLeft: 0,
      },

      '& h6': {
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: '#525454',
        marginBottom: 19,
        fontFamily: fonts.POPPINS_FONT,
      },

      '& ul': {
        margin: 0,
        padding: '0px 0px 0px 20px',

        '& li': {
          fontWeight: 400,
          fontSize: 14,
          letterSpacing: '-0.006em',
          color: '#525454',
          marginBottom: 6,
          fontFamily: fonts.INTER,

          '&::marker': { color: '#525454' },

          '&:last-child': {
            margin: 0,
          },

          '& p': {
            color: '#525454',
          },
        },
      },
      '&.enabledPackageBox': {
        paddingLeft: 44,
        borderLeft: '1px solid #D4D4D4',
      },
    },

    callNowNumber: {
      color: colors.YELLOW_THREE,
    },

    cardTitle: {
      overflow: 'visible !important',

      '& .profileSubHeading': {
        fontSize: 18,
        fontWeight: 600,
        marginRight: 12,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.014em',
        color: colors.BLACK_TWELVE,
      },

      '& .profileDivier': {
        margin: '20px 0px 19px',
        backgroundColor: `1px solid ${colors.WHITE_SEVEN}`,
      },

      '& .gpaText': {
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 9,
        fontFamily: fonts.INTER,
        color: colors.BLACK_FIVE,
        letterSpacing: '-0.006em',
      },

      '& .courseBadgeInterset': {
        backgroundColor: `${colors.LIGHT_GREEN_ONE} !important`,
      },

      '& .courseBadge': {
        minWidth: 96,
        height: 32,
        fontSize: 14,
        borderRadius: 6,
        fontWeight: 600,
        padding: '4px 12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: colors.WHITE_ONE,
        letterSpacing: '-0.006em',
        margin: '0px 8px 9px 0px',
        background: colors.BLUE_DARK,
      },

      '& .iconTextButton': {
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: '0.006em',
        color: colors.BLUE_SEVEN,
        textTransform: 'uppercase',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .iconButton': {
        marginLeft: 10,
      },

      '& .secondaryText': {
        color: colors.BLACK_FOURTEEN,
      },

      '& h4': {
        padding: '20px 25px',
        fontSize: 24,

        [theme.breakpoints.down('sm')]: {
          padding: '23px 16px 21px',
        },
      },

      '& .subtitleParentAccount': {
        color: colors.GRAY_TWENTYTWO,
        paddingTop: 5,
        fontSize: 14,

        [theme.breakpoints.down('sm')]: {
          paddingTop: 13,
        },
      },
    },

    freeUserUpgrade: {
      paddingBottom: '0 !important',
    },
    profileForm: {
      border: `1px solid ${colors.GRAY_NINE}`,
      borderRadius: 15,
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      '& h4': {
        padding: '20px',
      },

      '& .info-fields': {
        padding: '23px 30px',

        '@media only screen and (max-width: 767px)': {
          padding: '23px 20px',
        },
      },

      '& .MuiFormControl-root': {
        width: '100%',
        marginBottom: 36,
      },

      '& label + .MuiInput-formControl': {
        marginTop: 5,
      },

      '& .parent_field_btn': {
        position: 'relative',

        '& .MuiFormControl-root': {
          marginBottom: 0,
        },
      },

      '& .button_invite': {
        '@media only screen and (min-width: 768px)': {
          position: 'absolute',
          right: 1,
          top: 12,
        },

        '@media only screen and (max-width: 767px)': {
          width: '100%',
          margin: 0,
          marginTop: 14,
        },
      },
    },

    textfieldMobile: {
      '@media only screen and (max-width: 767px)': {
        marginBottom: '0px !important',
      },
    },

    selectButton: {
      minWidth: 126,
      height: 32,
      background: '#45CE93',
      border: '1px solid #45CE93',
      borderRadius: 12,
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: '-0.006em',
      color: '#FFFFFF',
      fontFamily: fonts.POPPINS_FONT,

      '&.Mui-disabled': {
        background: '#45CE93',
        opacity: 0.5,
        color: '#ffffff',
        border: '1px solid #45CE93',
        cursor: 'not-allowed !important',
      },

      '&:hover': {
        color: '#FFFFFF',
        background: '#38A878',
        border: '1px solid #38A878',
        boxShadow: 'none',
      },
    },
    textfield: {
      marginBottom: 28,
    },

    parentEmail: {
      [theme.breakpoints.down('xs')]: {
        margin: '15px 0px !Important',
      },
    },

    parentEmailInvite: {
      marginBottom: '25px',
      width: '100%',

      [theme.breakpoints.down('xs')]: {
        margin: '0px !Important',
      },

      '& .MuiInputBase-input': {
        fontFamily: `${fonts.POPPINS_FONT}`,
        color: `${colors.GRAY_TWO}`,
        fontSize: '24px',
        lineHeight: '36px',
        height: '45px',
        padding: '0px',
        letterSpacing: '-0.172549px',

        '@media only screen and (max-width: 767px)': {
          fontSize: '20px',
        },
      },

      '& .MuiInputLabel-animated': {
        fontFamily: `${fonts.POPPINS_FONT}`,
        color: `${colors.GRAY_TWO}`,
        fontSize: '24px',
        lineHeight: '36px',
        letterSpacing: '-0.172549px',
        marginBottom: '0px',
        transform: 'translate(0, 20px) scale(1)',

        '@media only screen and (max-width: 767px)': {
          fontSize: '20px',
        },
      },

      '& .MuiInputLabel-shrink': {
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '-0.172549px',
        color: `${colors.WHITE_ONE}`,
        fontFamily: `${fonts.POPPINS_FONT}`,
        transform: 'translate(0, 0px) scale(0.75)',
      },

      '& label.Mui-focused': {
        color: `${colors.WHITE_ONE}`,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: `${colors.WHITE_ONE}`,
      },

      '& .MuiInput-underline:before': {
        borderBottomColor: `${colors.GRAY_ELEVEN}`,
      },

      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: `${colors.WHITE_ONE}`,
        },
        '&:hover fieldset': {
          borderColor: `${colors.WHITE_ONE}`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${colors.WHITE_ONE}`,
        },
      },

      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: `2px solid ${colors.WHITE_ONE}`,
      },

      '& label + .MuiInput-formControl': {
        marginTop: 5,
        paddingRight: 130,

        '@media only screen and (max-width: 767px)': {
          paddingRight: 0,
        },
      },
    },

    tableMainContainer: {
      overflow: 'auto',
      width: '100%',
    },

    billingTable: {
      padding: '21px 25px 20px',
      margin: 0,

      [theme.breakpoints.down('sm')]: {
        padding: '18px 16px',
      },
    },

    billingTableRow: {
      border: '1px solid #D4D4D4',
    },

    extraActivities: {
      '& tbody': {
        '& tr': {
          '& td.alignCenter': {
            verticalAlign: 'middle !important',
          },

          '& td.alignbaseline': {
            verticalAlign: 'baseline !important',
          },
        },
      },
    },

    billingTableWrapper: {
      borderCollapse: 'separate',
      borderSpacing: '0px 11px',

      '& tbody': {
        '& tr': {
          '& td': {
            height: 45,
            borderLeft: 0,
            borderRight: 0,
            fontWeight: 400,
            fontSize: 12,
            whiteSpace: 'nowrap',
            letterSpacing: '-0.006em',
            verticalAlign: 'middle',
            padding: '6px 15px 6px 23px',
            color: colors.BLACK_THIRTEEN,
            backgroundColor: colors.WHITE_ONE,
            border: `1px solid ${colors.WHITE_SEVEN}`,

            '& button': {
              padding: 0,
            },
            '&:first-child': {
              borderRadius: '12px 0px 0px 12px',
              borderLeft: `1px solid #d4d4d4`,
            },

            '&:last-child': {
              borderRadius: '0px 12px 12px 0px',
              borderRight: `1px solid #d4d4d4`,
            },
          },
        },
      },

      '& .unavailable': {
        '& td': {
          background: '#EAEAEA',
          border: 0,

          '&:first-child': {
            border: 0,
          },

          '&:last-child': {
            border: 0,
          },

          '& p': {
            color: '#7D7F7F',
          },
          '& button': {
            padding: 0,
          },
        },
      },

      '& td.emptyNoDataCell': {
        padding: 24,
        fontSize: 14,
        fontWeight: 400,
        background: '#F4F4F4',
        verticalAlign: 'middle',
        fontFamily: fonts.INTER,
        border: '0px !important',
        letterSpacing: '-0.006em',
        color: colors.BLACK_FOURTEEN,
        borderRadius: '10px !important',
      },
    },

    tableHead: {
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      padding: '0px 22px 0px',
    },

    tableCell: {
      color: colors.BLACK_THREE,
    },

    parentEmailBox: {
      '& .parent-box-inner': {
        padding: '24px 30px 30px',
        borderRadius: 4,

        '@media only screen and (max-width: 767px)': {
          padding: '24px 20px 30px',
        },
      },

      '& p': {
        '&.Mui-error': {
          marginBottom: 0,
          fontSize: 14,
        },
      },

      '& .MuiInputLabel-shrink': {
        marginTop: -9,
      },
    },
    errorText: {
      color: 'red',
    },

    subscriptionButtons: {
      '@media only screen and (max-width: 390px)': {
        flexDirection: 'column',

        '& > div': {
          width: '100%',
          margin: '0 0 15px',

          '& button': {
            width: '100%',
          },
        },
      },
    },
    dropdownClass: {
      '& label + .MuiInput-formControl': {
        marginTop: 5,
        paddingRight: '0px !important',
      },
    },

    myAccountHeader: {
      padding: '66px 0px 53px',
    },

    callNowGrid: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      maxWidth: 355,
      padding: '25px 25px 60px',

      '@media only screen and (max-width: 414px)': {
        flexWrap: 'wrap',
      },

      '& h5': {
        '@media only screen and (max-width: 414px)': {
          marginTop: 20,
        },
      },
    },

    myPlanDetail: {
      padding: '18px 25px 26px',

      '& .enrolledSince': {
        fontFamily: fonts.INTER,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#A9A9A9',
        fontWeight: 400,
        marginBottom: 24,
      },
    },

    planDetailEnroll: {
      '& .planEnrollText': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        marginBottom: 3,
        fontFamily: fonts.INTER,

        '& span': {
          fontWeight: 600,
          paddingRight: 8,
        },
      },

      '& .downloadAgreement': {
        fontSize: 14,
        fontWeight: 500,
        color: '#2F80ED',
        textDecoration: 'none',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.084px',

        '& span': {
          borderBottom: '1px solid #2F80ED',
        },

        '& img': {
          marginRight: 12,
        },
      },
    },

    advisorsSection: {
      width: '100%',
      marginBottom: 33,
      paddingTop: 24,

      '& h6': {
        marginBottom: 18,
      },
    },

    avatarAdvisor: {
      width: 76,
      height: 76,
      marginRight: 20,
    },

    myAccountCard: {
      border: '1px solid #D4D4D4',
      borderRadius: 15,
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',
      marginBottom: 30,

      '& h4': {
        padding: '20px 25px',
      },
    },

    selectCardAdvisor: {
      '& h4': {
        borderBottom: '1px solid #D4D4D4',
      },

      '& .addNewCard': {
        color: '#272929',
        fontSize: 14,
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        letterSpacing: '0.006em',
        marginBottom: 18,
        border: 0,
        padding: 'initial',
        margin: 'initial',
      },
    },

    paymentCardBody: {
      padding: '36px 26px 28px',

      '& .paymentDivider': {
        margin: '26px 0px',
      },

      '& .currentMethod': {
        marginBottom: 18,
      },

      "& [class*='MuiRadio-root']": {
        margin: '0px 25px 0px 10px',
        padding: 0,

        "& [class*='MuiSvgIcon-root']": {
          width: 25,
          height: 25,
        },
      },

      '& .Mui-checked': {
        "& [class*='MuiSvgIcon-root']": {
          color: '#2F80ED',
        },
      },

      '& label': {
        alignItems: 'flex-start',
        marginBottom: 27,
        maxWidth: 371,

        '&:last-child': {
          marginBottom: 0,
        },
      },

      '& .labelWidthFlex': {
        "& [class*='MuiTypography-body1']": {
          width: '100%',
        },
      },
    },

    visaCardComponent: {
      '& .cardImage': {
        marginRight: 12,
      },

      '& .visaNumber': {
        marginBottom: 5,
        color: '#272929',
      },

      '& p': {
        color: '#7D7F7F',
      },

      '& .cardTypeDefult': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '0.018em',
        textTransform: 'uppercase',
        color: '#2F80ED',
        marginTop: 8,
      },

      '& .deleteButton': {
        '& span': {
          padding: 0,
        },
      },
      '& .deleteButton.defaultButton': {
        opacity: 0.5,
        cursor: 'not-allowed !important',
      },
    },

    mobileContentGuardians: {
      '& .name': {
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        color: '#525454',
        textTransform: 'capitalize',
      },

      '& .email': {
        fontSize: 14,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        color: '#525454',
        lineHeight: '20px',
        marginBottom: 3,
      },
    },

    mobileContentDivider: {
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 18,
        borderBottom: '1px solid #F4F4F4',
      },

      '& button': {
        [theme.breakpoints.down('sm')]: {
          padding: 0,
        },
      },
    },
    packageTitle: {
      fontWeight: 600,
      fontSize: 24,
      letterSpacing: '-0.019em',
      textTransform: 'uppercase',
      fontFamily: `${fonts.POPPINS_FONT} !important`,
    },

    profileSaveDiscard: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',

      '& .profileButton': {
        padding: 0,
        height: 32,
        borderRadius: 12,
        fontSize: 14,
        letterSpacing: '-0.006em',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .savePicture': {
        width: 127,
        background: '#2F80ED',
        marginRight: 9,
        color: '#FFFFFF',
        fontWeight: 700,

        '&:hover': {
          backgroundColor: '#2668C1',
        },
      },

      '& .discardButton': {
        minWidth: 90,
        border: '1px solid #D4D4D4',
        borderRadius: 12,
        fontWeight: 600,
        color: '#272929',
        padding: '0px 10px',
      },
    },

    planBillingTable: {
      padding: '21px 25px 39px',

      '& .dialogText': {
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: '-0.006em',
        color: '#525454',
        fontFamily: fonts.INTER,
        textAlign: 'right',

        '& a': {
          color: '#525454',
        },
      },
    },

    addStudentSpacing: {
      padding: '0px 25px 45px',

      [theme.breakpoints.down('sm')]: {
        padding: '0px 16px 20px',
      },
    },

    accountInformation: {
      padding: '20px 25px',

      '& h4': {
        padding: 0,

        [theme.breakpoints.down('xs')]: {
          marginBottom: 10,
        },
      },

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },

    gapYearCheckbox: {
      paddingTop: '2rem',
      paddingLeft: 15,

      '& .MuiTypography-body1': {
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#7D7F7F',
        margin: 0,
      },

      '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: '#7D7F7F',
      },

      '& .MuiCheckbox-colorSecondary.Mui-checked:hover': {
        backgroundColor: 'rgba(125, 125, 127, 0.2)',
      },
    },

    parentEmailGutter: {
      marginBottom: '0px !important',
    },

    paymentsTableStyle: {
      width: '100%',
      overflowX: 'auto',
      padding: '41px 25px 37px',

      '& .refund-img': {
        width: 15,
        position: 'absolute',
        left: 30,
        marginTop: 17,
        [theme.breakpoints.down('sm')]: {
          position: 'initial',
        },
      },

      '& .caution-img': {
        width: 15,
        position: 'absolute',
        top: 12,
        left: '-30px',

        [theme.breakpoints.down('sm')]: {
          position: 'initial',
        },
      },
      '& .failure-img': {
        top: '29px',
        [theme.breakpoints.down('md')]: {
          top: '12px',
        },
      },
      '& .caution-img-0': {
        top: '19px',
      },

      '& .hidden': {
        visibility: 'hidden',
      },

      '& .payment-img': {
        height: '15px',
        position: 'absolute',
        left: '-30px',
      },

      '& .iconButtonPayment': {
        width: 30,
        height: 30,
        padding: 5,
        margin: '0px 15px 0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },

      '& .paymentsTableHead': {
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: '#272929',
        fontFamily: fonts.POPPINS_FONT,
        paddingBottom: 10,

        [theme.breakpoints.between('sm', 'md')]: {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },

      '& .capitalizeText': {
        textTransform: 'capitalize !important',
      },

      '& .emptyTableCell, & .MuiIconButton-root': {
        padding: 6,
        width: 40,
        height: 40,
      },

      '& .MuiTooltip-tooltip, & .MuiTooltip-popper': {
        fontWeight: 400,
        fontSize: 30,
        letterSpacing: '-0.006em',
        color: '#525454',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .paymentTableHeader': {
        padding: '0px 16px 0px 60px',

        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },

      '& .paymentsTableHeadFirst': {
        width: '30%',
      },

      '& .paymentsTableHeadSecond': {
        width: '15%',
      },

      '& .paymentsTableHeadThird': {
        width: '15%',
      },

      '& .paymentsTableHeadFour': {
        width: '20%',
      },

      '& .paymentsTableHeadFive': {
        width: '30%',
      },

      '& .paymentsTableHeadSix': {
        width: '20%',

        [theme.breakpoints.down('sm')]: {
          textAlign: 'left',
          justifyContent: 'flex-start',
        },
      },

      '& .mobileHeading': {
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: '#272929',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .cautionTableRow': {
        padding: '10px 0px',
        position: 'relative',
        minHeight: '30px !important',
        boxSizing: 'content-box',
      },
      '& .paymentsTableRow': {
        padding: '10px 0px',
        position: 'relative',
      },
      '& .failedPayment': {
        paddingBottom: '10px',
      },
    },

    paymentsTableBodyContainer: {
      border: '1px solid #D4D4D4',
      marginBottom: 9,
      borderRadius: 12,
    },

    paymentsTableBody: {
      background: '#FFFFFF',
      padding: '4px 16px 4px 60px',
      position: 'relative',
      borderRadius: 12,

      [theme.breakpoints.down('sm')]: {
        padding: 8,
      },

      '& .paymenyMobileFlex': {
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginBottom: 10,
        },
      },

      '& .paymentTableBodyCell': {
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: '-0.006em',
        color: '#525454',
        fontFamily: fonts.INTER,

        [theme.breakpoints.down('lg')]: {
          wordBreak: 'break-word',
        },

        [theme.breakpoints.between('sm', 'md')]: {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },

        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
        '& .statusBadge': {
          background: '#45CE93',
          borderRadius: 4,
          fontWeight: 600,
          fontSize: 12,
          color: '#FFFFFF',
          fontFamily: fonts.INTER,
          padding: '1px 5px',
          textTransform: 'capitalize',
        },
      },

      '& .statusBadge': {
        background: '#45CE93',
        borderRadius: 4,
        fontWeight: 600,
        fontSize: 12,
        color: '#FFFFFF',
        fontFamily: fonts.INTER,
        padding: '1px 5px',
        textTransform: 'capitalize',
      },
      '& .failedPayment': {
        paddingBottom: '10px',
      },
    },
    refundRow: {
      padding: '10px 0 !important',
      position: 'relative',
      minHeight: '30px',
      boxSizing: 'content-box',
    },
    collapseButton: {
      height: '30px',
      width: '30px !important',
      padding: '0px !important',
      position: 'absolute',
      right: 20,
      top: 10,
      zIndex: 999999,

      '& img': {
        width: 10,
      },
      '&.addPosition': {
        top: 20,
      },
    },

    collapseUp: {
      transform: 'rotate(180deg)',
    },

    coursesDialogContent: {
      padding: '40px 43px',

      [theme.breakpoints.down('xs')]: {
        padding: '20px 16px',
      },

      '& .roleTitle': {
        width: 431,
        marginBottom: 10,
      },

      '& .dialogHeadingText': {
        marginBottom: 5,
      },

      '& .dialogSubHeadingText': {
        fontSize: 14,
        fontWeight: 400,
        paddingBottom: 23,
        letterSpacing: '-0.006em',
        color: colors.GRAY_TWENTYTWO,
      },

      '& .profileSelect': {
        '& .select__placeholder': {
          fontSize: 14,
        },

        '& .select__input': {
          fontSize: 14,

          '& input': {
            fontSize: 14,
            marginBottom: '0px !important',
          },
        },

        '& .select__multi-value': {
          backgroundColor: colors.BLUE_DARK,
        },

        '& .select__control .select__multi-value .select__multi-value__label': {
          fontSize: 14,
          fontWeight: 600,
          letterSpacing: '-0.006em',
          color: colors.WHITE_EIGHT,
        },

        '& .select__multi-value__label': {
          fontFamily: `${fonts.INTER} !important`,
        },
      },

      '& input': {
        fontSize: 14,
        fontWeight: 400,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        color: colors.BLACK_TWELVE,

        '&::placeholder': {
          opacity: 1,
          color: colors.GRAY_TWENTYTWO,
        },
      },
    },

    profileDialog: {
      padding: '40px 43px',

      [theme.breakpoints.down('xs')]: {
        padding: '20px 16px',
      },

      '& .roleTitle': {
        width: 431,
        marginBottom: 10,
      },

      '& .coursesSelect': {
        '& .select__input': {
          '& input': {
            marginBottom: '0px !important',
          },
        },

        '& .select__multi-value': {
          backgroundColor: colors.BLUE_DARK,
        },

        '& .select__control .select__multi-value .select__multi-value__label': {
          fontSize: 14,
          fontWeight: 600,
          letterSpacing: '-0.006em',
          color: colors.WHITE_EIGHT,
        },

        '& .select__multi-value__label': {
          fontFamily: `${fonts.INTER} !important`,
        },

        '& .select__multi-value__remove svg': {
          width: 20,
          height: 20,
        },
      },

      '& .dialogHeadingText': {
        marginBottom: 5,
      },

      '& .dialogSubHeadingText': {
        fontSize: 14,
        fontWeight: 400,
        paddingBottom: 23,
        letterSpacing: '-0.006em',
        color: colors.GRAY_TWENTYTWO,
      },

      '& h4': {
        marginBottom: 20,
      },

      '& .fieldCaption': {
        fontWeight: 400,
        paddingBottom: 4,
        color: colors.BLACK_TWELVE,
      },

      '& input': {
        height: 26,
      },

      '& .profileInputBase': {
        '& input': {
          marginBottom: 0,
        },
      },

      '& .inputGrid': {
        margin: '0px 20px 20px 0px',
      },

      '& .fullWidth': {
        width: 167,
      },

      '& .halfWidth': {
        width: 121,
      },

      '& textarea, input': {
        fontSize: 14,
        borderRadius: 6,
        fontWeight: 400,
        marginBottom: 21,
        padding: '6px 12px',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        color: colors.BLACK_TWELVE,
        border: `1px solid ${colors.WHITE_SEVEN}`,

        '&::placeholder': {
          opacity: 1,
          color: colors.GRAY_TWENTYTWO,
        },
      },
      '& .saveButton': {
        minWidth: 134,
        marginLeft: 14,

        '&:hover': {
          backgroundColor: colors.BLUE_DARK,
        },
      },
    },

    activityDialog: {
      '& div[role="dialog"]': {
        width: 908,
        maxWidth: 908,
        overflow: 'visible',
      },

      '& input': {
        marginBottom: 3,
      },

      '& textarea': {
        marginBottom: 0,
      },

      '& .textareaInputBase': {
        padding: 0,
      },

      '& .Mui-error:after': {
        display: 'none',
      },

      '& .datePickerInputBase': {
        padding: '8px 0px 0px',

        '& [class*="MuiInputBase-root"]': {
          width: 155,
          marginRight: 16,
          position: 'relative',

          '&:before': {
            display: 'none',
          },
        },

        '& [class*="MuiSvgIcon-root"]': {
          display: 'none',
        },

        '& [class*="MuiInputAdornment-positionEnd"]': {
          left: 0,
          top: 5,
          padding: 0,
          width: 30,
          height: 30,
          position: 'absolute',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${CALENDAR_ICON})`,
        },

        '& [class*="MuiIconButton-root"]': {
          width: 30,
          height: 30,
          padding: 0,
        },

        '& input': {
          padding: '6px 12px 6px 40px',
        },
      },
    },

    coursesDialog: {
      '& div[role="dialog"]': {
        width: 812,
        maxWidth: 812,
        overflow: 'visible',
      },
    },

    deleteDialog: {
      '& div[role="dialog"]': {
        width: 551,
        maxWidth: '100%',
        borderRadius: 25,
        padding: '50px 46px 48px 50px !important',
        boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

        [theme.breakpoints.down('xs')]: {
          borderRadius: 15,
          padding: '30px 16px',
          margin: '10px !important',
        },
      },
    },

    dialogHeading: {
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '32px',
      letterSpacing: '-0.019em',
      color: colors.BLACK_TWELVE,
      marginBottom: 22,
      fontFamily: fonts.POPPINS_FONT,

      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        lineHeight: '24px',
      },
    },

    dialogActionRoot: {
      border: 'none',
      padding: '22px 0px 0px',
      justifyContent: 'flex-start',
    },

    containedButton: {
      minWidth: 151,
      fontWeight: 600,
      color: colors.WHITE_ONE,
      background: colors.RED_SEVEN,
    },

    outlinedButton: {
      minWidth: 109,
      marginLeft: 16,
      fontWeight: 600,
      padding: '4px 35px',
      color: colors.BLACK_TWELVE,
      background: colors.WHITE_ONE,
      border: `1px solid ${colors.WHITE_SEVEN}`,

      [theme.breakpoints.down('xs')]: {
        marginRight: 8,
      },
    },

    actionDescription: {
      color: colors.BLACK_THIRTEEN,
    },

    checkboxReview: {
      '& span': {
        fontWeight: 400,
        fontSize: 14,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        color: colors.BLACK_FOURTEEN,
      },

      '& [class*="MuiSvgIcon-root"]': {
        width: 24,
      },

      '& [class*="MuiIconButton-root"]': {
        minWidth: 50,
      },

      '& svg': {
        '& path': {
          fill: colors.GRAY_TWENTYTWO,
        },
      },

      '& .Mui-checked': {
        '& svg': {
          '& path': {
            fill: `${colors.BLACK_FOURTEEN}`,
          },
        },
      },
    },

    leadership: {
      paddingBottom: '0px !important',
    },

    extraActivitiesTable: {
      width: '100%',
      overflow: 'auto',
    },

    // CSS for disabled field background and font
    disabledFieldBackgroundColor: {
      '& [class*="MuiOutlinedInput-input"]': {
        backgroundColor: colors.GRAY_TWENTYFOUR,
        color: colors.GRAY_TWENTYTWO
      }
    },
  })
);
