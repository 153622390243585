import { Route } from 'react-router-dom';

/* 
  Note: We need to track some params at every public page
  for the user analytics, that's why we wrap our <Route>
  component into this PublicRoute component, this route will
  save the query params of the route in the localStorage
*/

/**
 * @author Wajeeh Aslam
 * convert entries array to single object
 * @param entries array of objects
 */
const _convertParamsToObject = (entries: any) => {
  const result: any = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

/**
 * Obtain query params form the current url and return object for params
 */
const _loadUrlParamsAndCreateObject = () => {
  const params = new URLSearchParams(window.location.search);
  const entries = params.entries();
  const _params = _convertParamsToObject(entries);
  return _params;
};

const PublicRoute = ({ component: Component, ...rest }: any): JSX.Element => {
  const _prevParams = window.localStorage.user_analytics_details;
  if (_prevParams) {
    let _oldParams = JSON.parse(_prevParams);
    const newParams = _loadUrlParamsAndCreateObject();
    localStorage.setItem(
      'user_analytics_details',
      JSON.stringify({
        ...newParams,
        first_visited_at: _oldParams.first_visited_at
          ? _oldParams.first_visited_at
          : new Date(),
        referral_url: document.referrer,
        first_visit_url: newParams._fvu
          ? newParams._fvu
          : _oldParams.first_visit_url
            ? _oldParams.first_visit_url
            : `${window.location.href.split('?')[0]}`,
      })
    );
  } else {
    const newParams = _loadUrlParamsAndCreateObject();
    window.localStorage.setItem(
      'user_analytics_details',
      JSON.stringify({
        ...newParams,
        first_visited_at: new Date(),
        referral_url: document.referrer,
        first_visit_url:
          newParams._fvu || `${window.location.href.split('?')[0]}`,
      })
    );
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PublicRoute;
