import { makeStyles, Tooltip } from '@material-ui/core';
import { FC } from 'react';

import Colors from '../common/colors';
import Fonts from '../common/fonts';

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: Colors.BLACK_THREE,
    boxShadow: `0px 2px 10px ${Colors.BOX_SHADOW_THREE}`,
    borderRadius: 3,
    padding: 10,
    fontFamily: Fonts.POPPINS_FONT,
    fontSize: 12,
    lineHeight: '20px',
    letterSpacing: '0.3375px',
    color: Colors.WHITE_ONE,
  },
}));

interface PropsType {
  title?: any;
}

const CustomTooltip: FC<PropsType> = ({ children, title }) => {
  const tooltipClasses = useTooltipStyles();

  return (
    <Tooltip classes={tooltipClasses} title={title}>
      <span style={{ cursor: 'pointer'}}>{children}</span>
    </Tooltip>
  );
};

export default CustomTooltip;
