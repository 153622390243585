import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { ApolloProvider } from 'react-apollo';
import client from './apollo';
import AppContainer from './AppContainer';
import { AppContext, User } from './contexts';
import { SmartMatch_smartMatch } from './graphql/queries/smartMatch/__generated__/SmartMatch';

declare global {
  interface Window {
    Intercom: any;
    analytics: any;
    gapi: any;
    google: any;
  }
}

const App: React.FC = () => {
  const [user, _setUser] = useState<User | null>(null);
  const [isLoggedIn, _setIsLoggedIn] = useState<boolean>(false);
  const [fetchPlans, setFetchPlans] = useState<boolean>(false);
  const [smartMatch, setSmartMatch] = useState<SmartMatch_smartMatch | null>(null);
  const [fetchSmartMatch, setFetchSmartMatch] = useState<Function | null>(null);
  const [unreadMessagesCount, _setUnreadMessagesCount] = useState<any>();
  const [showCollegeFilter, setShowCollegeFilter] = useState<boolean>(false);
  const [searchFromSaved, setSearchFromSaved] = useState<boolean>(false);

  const setUser = (user: User | null) => {
    _setUser(user);
  };

  const setUnreadMessagesCount = (data: any) => {
    _setUnreadMessagesCount(data);
  };

  const setIsLoggedIn = (isLoggedIn: boolean) => {
    _setIsLoggedIn(isLoggedIn);
  };

  return (
    <AppContext.Provider
      value={{
        user,
        isLoggedIn,
        unreadMessagesCount,
        showCollegeFilter,
        setShowCollegeFilter,
        searchFromSaved,
        setUser,
        setIsLoggedIn,
        setUnreadMessagesCount,
        setSearchFromSaved,
        fetchPlans,
        setFetchPlans,
        smartMatch,
        fetchSmartMatch,
        setSmartMatch,
        setFetchSmartMatch
      }}
    >
      {(!process.env.REACT_APP_ACTIVATE_ANALYTICS ||
        process.env.REACT_APP_ACTIVATE_ANALYTICS == 'false') && (
        <Helmet>
          <meta name="robots" content="noindex" />
          <meta name="googlebot" content="noindex" />
        </Helmet>
      )}
      <ApolloProvider client={client as any}>
        <AppContainer />
      </ApolloProvider>
    </AppContext.Provider>
  );
};

export default App;
