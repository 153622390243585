import styled from 'styled-components';

import { Button } from 'react-bootstrap';

export const ButtonCollegeAdded = styled(Button)`
  background: transparent;
  border: none;
  padding: 0px;
  position: absolute;
  right: 11px;
  top: 11px;
  box-shadow: none;
  outline: none;
  border-radius: 50px;
  line-height: 0px;
  cursor: initial !important;

  &:hover {
    background: transparent;
    border-color: transparent;
  }
`;

export const CollegeLogo = styled.div`
  display: flex;

  @media only screen and (max-width: 991px) {
    clear: both;
    display: block;
  }
`;