import { Box, Typography } from '@material-ui/core';

import { useStyles } from './styles';

import LOGO from '../../img/logo-us-news-light.png';
import PersonImg1 from '../../img/PersonImg1.png';
import PersonImg2 from '../../img/PersonImg2.png';
import PersonImg3 from '../../img/PersonImg3.png';
import PersonImg4 from '../../img/PersonImg4.png';
import HeaderLoginRegister from '../../components/layout/HeaderLoginRegister';

const MBARightSidePage = () => {
  const classes = useStyles();
  return (
    <Box className={classes.registerRight}>
      <Box className="mobile-only">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <img src={LOGO} alt="College advisor big logo" />

          <HeaderLoginRegister />
        </Box>
      </Box>

      <Box
        maxWidth={660}
        margin="0px auto"
        textAlign="center"
        className="desktop-only"
      >
        <Box mb={1.25}>
          <Typography variant="h3" className="registerHeading">
            Personalized and Effective
          </Typography>
          <Typography
            variant="h3"
            className="registerHeading registerHeadingOpacity"
          >
            MBA Admissions Guidance
          </Typography>
        </Box>

        <Box
          mb={6}
          flexWrap="wrap"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <img src={PersonImg1} alt="avatar" />
          <img src={PersonImg2} alt="avatar" />
          <img src={PersonImg3} alt="avatar" />
          <img src={PersonImg4} alt="avatar" />
        </Box>

        <a href="https://collegeadvisor.com/" target="_blank">
          <img src={LOGO} alt="" width="250" />
        </a>
      </Box>
    </Box>
  );
};

export default MBARightSidePage;
