import { makeStyles, Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';

import Fonts from '../../common/fonts';
import Colors from '../../common/colors';

import ERROR_IMG from '../../img/error-ic.svg';
import Arrow_Img from '../../img/custom-arrow.svg';
import fonts from '../../common/fonts';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    consentCheckbox: {
      paddingTop: 32,
      borderTop: '1px solid #D4D4D4',

      "& [class*='MuiTypography-body1']": {
        color: '#525454',
        marginBottom: 0,
      },

      "& [class*='MuiTypography-body2']": {
        color: '#A9A9A9',
        marginBottom: 26,
      },

      "& [class*='MuiTypography-body1'], & [class*='MuiTypography-body2']": {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '20px',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.072px',
        textAlign: 'justify',
      },

      "& [class*='MuiFormControlLabel-root']": {
        marginRight: 0,
        marginBottom: 8,
        alignItems: 'flex-start',
      },

      "& [class*='MuiSvgIcon-root'] path": {
        fill: `${Colors.GRAY_TWENTYTWO} !important`,
      },

      "& [class*='MuiCheckbox-colorPrimary']": {
        bottom: 5,
        position: 'relative',
      },
    },

    createPasswordUpdated: {
      "& [class*='MuiFormControl-fullWidth']": {
        maxWidth: '100% !important',
      },
    },

    fieldContainerWidth: {
      maxWidth: '100% !important',
    },

    graduatedCollegeAlready: {
      fontSize: 10,
      fontWeight: 400,
      color: '#7D7F7F',
      fontFamily: Fonts.INTER,
      letterSpacing: '-0.06px',

      '& a': {
        color: '#2F80ED',
      },
    },

    buttonProgress: {
      color: '#ffffff',
      width: '20px !important',
      height: '20px !important',
      marginLeft: 12,

      '& svg': {
        marginRight: 0,
      },
    },

    textfield: {
      marginBottom: 28,
      fontFamily: Fonts.INTER,

      '& .PhoneInputInput': {
        color: Colors.BLACK_TEN,
        border: `1px solid ${Colors.BLACK_TEN}`,
        height: 33,
        padding: '0 10px',
        fontSize: 14,
        background: Colors.WHITE_ONE,
        borderRadius: '5px !important',
      },

      '& .MuiInputBase-root': {
        fontFamily: Fonts.INTER,
      },

      '& .MuiFormHelperText-root.Mui-error': {
        fontFamily: Fonts.INTER,
      },

      '& .MuiInputLabel-shrink': {
        fontFamily: Fonts.INTER,
        color: Colors.BLUE_SEVEN,
      },

      '& .MuiInputLabel-animated': {
        fontFamily: Fonts.INTER,
      },

      '& label.Mui-focused': {
        color: Colors.BLUE_SEVEN,
      },

      '& .MuiInput-underline:after': {
        borderBottomColor: Colors.BLUE_SEVEN,
      },

      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: Colors.BLUE_SEVEN,
        },
        '&:hover fieldset': {
          borderColor: Colors.BLUE_SEVEN,
        },
        '&.Mui-focused fieldset': {
          borderColor: Colors.BLUE_SEVEN,
        },
      },
    },

    FormBottomSection: {
      marginBottom: '15px',
      textAlign: 'center',
    },

    FormBottomText: {
      fontSize: '14px',
      fontFamily: Fonts.INTER,
      color: Colors.BLACK_THREE,
      margin: '20px 0px 0px',
      '& a': {
        color: Colors.BLUE_SEVEN,
        fontWeight: 600,
        marginLeft: 7,
      },
    },

    cardModal: {
      background:
        'linear-gradient(162.13deg, #4381E5 0.68%, #0D2765 99.21%), #0A2463',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '10px 18px 29px',
      maxWidth: 500,
      height: '80vh',
      overflowY: 'auto',

      '&.custom-card': {
        borderRadius: 0,
        background: 'none',
        boxShadow: 'none',
        padding: 0,
        maxWidth: 'none',
        height: 'auto',
      },

      '@media only screen and (max-width: 767px)': {
        padding: '29px 15px',
      },

      '& .MuiTypography-h4': {
        fontWeight: 600,
        fontSize: 24,
        color: Colors.WHITE_ONE,
        fontFamily: Fonts.INTER,
        paddingBottom: 26,

        '@media only screen and (max-width: 767px)': {
          fontSize: 18,
        },
      },

      '& .MuiTypography-h5': {
        fontWeight: 600,
        fontSize: 16,
        color: Colors.WHITE_ONE,
        marginBottom: 12,
      },

      '& .formHeading': {
        fontSize: 20,
        fontWeight: 600,
        color: Colors.WHITE_ONE,
        fontFamily: Fonts.INTER,
        marginBottom: 20,
      },

      '& .MuiInputBase-input': {
        background: Colors.WHITE_ONE,
        borderRadius: 5,
      },

      '& .MuiSvgIcon-root': {
        '& path': {
          fill: Colors.WHITE_ONE,
        },
      },

      '& .MuiFilledInput-input': {
        padding: '10px 12px 10px',
      },

      '& .MuiInputLabel-filled': {
        color: Colors.BLACK_TEN,
        fontSize: 16,
        transform: 'translate(12px, 11px) scale(1)',
      },

      '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
        transform: 'translate(0px, -15px) scale(0.75)',
        color: `${Colors.WHITE_ONE}`,
      },

      '& .MuiFormHelperText-root.Mui-error': {
        margin: '5px 0px 0px',
        color: Colors.WHITE_ONE,
        background: `url(${ERROR_IMG}) no-repeat center left`,
        paddingLeft: 18,
        lineHeight: '12px',
        fontFamily: Fonts.INTER,

        '&:after': {
          borderBottomColor: Colors.RED_ONE,
        },
      },

      '& .Mui-error + div > input': {
        color: Colors.RED_ONE,
      },

      '& .react-phone-number-input__input': {
        height: 39,
        padding: '0px 15px',
        borderRadius: 3,
      },

      '& .MuiFormControl-fullWidth': {
        marginBottom: 28,
      },

      '& .MuiSelect-select.MuiSelect-select': {
        lineHeight: '38px',
        height: 39,
        padding: '0px 15px',
        borderRadius: 3,
        color: Colors.BLACK_TEN,
        fontSize: 16,
      },

      '& .MuiFormControl-root': {
        '& .MuiInputBase-root.MuiInput-root': {
          '& .MuiSelect-icon': {
            display: 'block',
          },

          '& .MuiSvgIcon-root path': {
            fill: Colors.GRAY_TWELVE,
          },
        },
      },

      '& .MuiInputLabel-shrink': {
        color: Colors.WHITE_ONE,
      },

      '& .loggingText': {
        fontWeight: 400,
        fontSize: 12,
        textAlign: 'center',
        color: '#A9A9A9',
        fontFamily: Fonts.INTER,
        margin: '0px auto',
        marginBottom: 25,

        '& a': {
          color: '#A9A9A9',
        },
      },
    },

    radioGroup: {
      color: Colors.WHITE_ONE,
    },

    createPassword: {
      margin: '12px 0 0 12px',

      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },

      '& .MuiTypography-body2': {
        textAlign: 'left',
        color: `${Colors.WHITE_ONE}`,
      },

      '& .MuiInputBase-input': {
        paddingLeft: 15,
      },

      '& .MuiFormControl-fullWidth': {
        marginBottom: `42px !important`,
      },
    },

    textfieldMobile: {
      '@media only screen and (max-width: 767px)': {
        marginBottom: '0px !important',
      },
    },

    leadRegisterGraduation: {
      display: 'flex',
      marginBottom: 36,

      '& .MuiFormControl-fullWidth': {
        marginBottom: '0px !important',
      },
    },

    textfieldGraduation: {
      '& .MuiFormControl-fullWidth': {
        marginBottom: '0px !important',
      },

      '& .MuiFormLabel-root.MuiInputLabel-root': {
        display: 'none',
      },

      '& .MuiInputBase-input': {
        height: '35px !Important',
      },

      '& .MuiFormHelperText-root.Mui-error': {
        wordBreak: 'break-word',
      },

      '& .MuiTypography-body1': {
        lineHeight: '15px',
        color: '#62636B',
        fontSize: 10,
        fontWeight: 500,
        marginBottom: 3,
      },
    },

    selectDropdownMeeting: {
      '& .MuiInputBase-root': {
        maxWidth: 175,
        width: '100%',
      },
    },

    selectDropdown: {
      backgroundColor: Colors.WHITE_ONE,
      borderRadius: 3,
      position: 'relative',

      '& .MuiTypography-body2': {
        color: '#62636B',
        fontSize: 10,
        fontWeight: 500,
        marginBottom: 4,
      },

      '& .MuiSelect-select.MuiSelect-select': {
        fontSize: '13px ​!important',
        background: `url(${Arrow_Img}) no-repeat !important`,
        backgroundPosition: 'center right 15px !important',
      },

      '& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input':
        {
          color: `${Colors.GRAY_SIX} !important`,
        },

      '& .MuiInputLabel-root': {
        fontSize: 16,
        fontFamily: Fonts.INTER,
        color: Colors.BLACK_TEN,
      },

      '& .MuiFormHelperText-root.Mui-error': {
        display: 'none',
      },

      '& .display-error .MuiFormHelperText-root.Mui-error': {
        display: 'inline-flex',
      },

      '& label + .MuiInput-formControl': {
        margin: 0,
      },

      '& .MuiInputLabel-formControl': {
        transform: 'translate(15px, 11px) scale(1)',
      },

      '& .MuiInputLabel-shrink': {
        transform: 'translate(1px, -15px) scale(1)',
        color: Colors.WHITE_ONE,
        fontSize: 12,
      },

      '& .MuiInputBase-input': {
        background: 'transparent',
      },

      '& .MuiFormLabel-root.Mui-focused': {
        color: `${Colors.WHITE_ONE}`,
      },

      '& .MuiSelect-icon': {
        display: 'none',
      },
    },

    fieldContainer: {
      display: 'flex',
      justifyContent: 'space-between',

      '&.custom-margin': {
        '& .MuiFormControl-fullWidth': {
          marginBottom: `7px !important`,
        },
      },

      '& .MuiFormControl-root': {
        width: '48.1%',
      },
    },

    radioButton: {
      margin: '0 !important',

      '&:not(:last-child)': {
        marginRight: `16px !important`,
      },

      '& .MuiButtonBase-root': {
        display: 'none',

        '& + span': {
          width: 110,
          height: 32,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 14,
          fontWeight: 500,
          color: Colors.BLACK_TWELVE,
          border: `1px solid ${Colors.BLACK_TWELVE}`,
          borderRadius: 25,

          '&:hover': {
            opacity: 0.5,
          },
        },

        '&.Mui-checked + span': {
          backgroundColor: `rgba(47, 128, 237, 0.25)`,
          color: Colors.BLACK_TWELVE,
          opacity: `1 !important`,
          border: 'none',
        },
      },
    },

    registerContainer: {
      display: 'flex',
      minHeight: '100vh',

      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column-reverse',
        minHeight: 'initial',
      },

      '& .hide-mobile': {
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
    },

    registerLeft: {
      width: '42%',
      display: 'flex',
      padding: '65px 20px',
      position: 'relative',
      flexDirection: 'column',
      justifyContent: 'center',
      background: Colors.WHITE_ONE,
      minHeight: 'calc(100vh - 72px)',

      '& label + .MuiInput-formControl': {
        margin: 0,
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minHeight: 'calc(100vh - 88px)',
      },

      '& h4': {
        margin: 0,
        fontSize: 24,
        fontWeight: 700,
        letterSpacing: '-0.456px',
        color: Colors.BLUE_TWELVE,
        textTransform: 'capitalize',
        fontFamily: Fonts.POPPINS_FONT,

        [theme.breakpoints.down('sm')]: {
          fontSize: 20,
        },
      },

      '& .MuiFilledInput-root': {
        borderRadius: 5,
      },

      '& input.MuiInputBase-input.MuiFilledInput-input::placeholder': {
        opacity: 1,
        color: '#8F8F8F',
      },

      '& .MuiInputBase-input': {
        background: Colors.WHITE_ONE,
        border: `1px solid ${Colors.BLACK_TEN}`,
        borderRadius: `5px !important`,
        padding: '0 10px',
        height: 33,
        fontSize: 14,
        color: Colors.BLACK_TEN,

        '&.MuiFilledInput-underline': {
          border: 'none',
        },

        '&:disabled': {
          background: Colors.GRAY_NINETEEN,
          border: `1px solid ${Colors.GRAY_NINETEEN}`,
          color: Colors.GRAY_TWENTYONE,
          cursor: 'not-allowed',
          borderRadius: 5,
        },
      },

      '& .MuiFormLabel-root.MuiInputLabel-root': {
        fontSize: 10,
        fontWeight: 500,
        color: Colors.BLACK_TEN,
        transform: 'none !important',
        top: -15,
      },

      '& .MuiFilledInput-underline': {
        '&:after': {
          display: 'none',
        },

        '&:before': {
          display: 'none',
        },
      },

      '& .MuiInput-underline': {
        '&:after': {
          display: 'none',
        },

        '&:before': {
          display: 'none',
        },
      },

      '& .MuiFormHelperText-root': {
        '&.Mui-error': {
          color: Colors.BLACK_ELEVEN,
          backgroundSize: 12,
        },
      },

      '& .MuiFormControl-fullWidth': {
        marginBottom: 36,
      },

      '& .Mui-error': {
        '& .MuiInputBase-input': {
          border: `1px solid ${Colors.RED_ONE} !important`,
        },

        '& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input':
          {
            color: '#F8BBBA !important',
          },

        '&:after': {
          display: 'none',
        },

        '&:before': {
          display: 'none',
        },
      },

      '& .MuiSelect-select.MuiSelect-select': {
        background: Colors.WHITE_ONE,
        border: `1px solid ${Colors.BLACK_TEN}`,
        borderRadius: 5,
        padding: '0 10px',
        height: 35,
        fontSize: 14,
        color: Colors.GRAY_TWENTYONE,
        lineHeight: '36px',
      },
    },

    isLoggedin: {
      background: 'rgba(47, 128, 237, 0.25)',
      maxWidth: 290,
      height: 34,
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      fontFamily: Fonts.INTER,
      paddingLeft: 30,
      position: 'absolute',
      top: 33,
      right: 0,
      width: '100%',
      color: '#62636B',

      '& a': {
        color: Colors.BLUE_SEVEN,
        fontWeight: 600,
        marginLeft: 7,
      },

      '&.customized': {
        position: 'relative',
        background: 'none',
        paddingLeft: 0,
        top: 'auto',
        right: 'auto',
        justifyContent: 'center',
        margin: '0 auto',
      },

      '&.loginCustomizedText': {
        fontSize: 16,
        color: '#62636B',
        fontWeight: 400,
        letterSpacing: '-0.011em',
      },
    },

    sectionHeadingContainer: {
      [theme.breakpoints.down('xs')]: {
        display: 'block',

        '& label': {
          marginTop: `10px !important`,
        },
      },
    },

    sectionHeading: {
      display: 'flex',
      fontSize: 16,
      color: Colors.BLACK_TEN,
      fontWeight: 700,
      fontFamily: Fonts.INTER,

      '& span': {
        marginRight: 11,
      },
    },

    registerRight: {
      padding: '42px 50px',
      width: '58%',
      background: `linear-gradient(122.67deg, ${Colors.BLUE_SEVEN} 1.86%, #0A2463 98.27%)`,
      height: '100vh',
      position: 'fixed',
      right: 0,

      '& .desktop-only': {
        fontFamily: Fonts.INTER,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },

      '& .mobile-only': {
        display: 'none',
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: 'auto',
        position: 'relative',
        padding: 20,

        '& .mobile-only': {
          display: 'block',
        },

        '& .desktop-only': {
          display: 'none',
        },
      },
    },

    forgotPass: {
      fontSize: 14,
      letterSpacing: '-0.006em',
      color: '#7D7F7F',
      fontWeight: 400,
      margin: `31px 0 14px 0`,
      display: 'inline-flex',
      fontFamily: Fonts.INTER,
      textDecoration: 'underline !important',
    },

    phoneInputNew: {
      position: 'relative',
      paddingBottom: 30,

      '& > label': {
        position: 'absolute',
        fontSize: 10,
        fontWeight: 500,
        color: Colors.BLACK_TEN,
        transform: 'none !important',
        fontFamily: Fonts.INTER,
        top: -17,
        left: 40,
      },

      '& .PhoneInputInput': {
        color: Colors.BLACK_TEN,
        border: `1px solid ${Colors.BLACK_TEN}`,
        height: 33,
        padding: '0 10px',
        fontSize: 14,
        background: Colors.WHITE_ONE,
        borderRadius: '5px !important',

        '&::placeholder': {
          color: '#8F8F8F',
          fontFamily: Fonts.INTER,
        },
      },

      '& .PhoneInputCountryIcon': {
        color: Colors.BLACK_TEN,
      },

      '& .PhoneInputCountrySelectArrow': {
        borderColor: Colors.BLACK_ONE,
        border: '1px solid #1b1b1b !important',
        borderTopWidth: '0px !important',
        borderLeftWidth: '0px !important',
      },

      '& .react-phone-number-input__icon': {
        border: 'none',
        width: 27,
        height: 'auto',
      },

      '& .react-phone-number-input__country--native': {
        marginRight: 19,
      },

      '& .react-phone-number-input__input': {
        background: Colors.WHITE_ONE,
        border: `1px solid ${Colors.BLACK_TEN}`,
        borderRadius: 5,
        padding: '0 10px',
        height: 35,
        fontSize: 14,
        color: Colors.BLACK_TEN,
        fontFamily: Fonts.INTER,
      },

      '& .react-phone-number-input__input::placeholder': {
        color: `${Colors.GRAY_SIX} !important`,
      },

      '&.error': {
        '& .PhoneInputInput': {
          background: Colors.WHITE_ONE,
          border: `1px solid ${Colors.RED_ONE}`,
          borderRadius: 5,
          padding: '0 10px',
          height: 35,
          fontSize: 14,
          color: Colors.RED_ONE,
        },

        '& .PhoneInputInput::placeholder': {
          fontFamily: Fonts.INTER,
          color: `#F8BBBA !important`,
        },
      },

      '& .Mui-error': {
        marginLeft: `38px !important`,
      },

      '& .react-phone-number-input__error': {
        margin: '5px 0px 0px',
        background: `url(${ERROR_IMG}) no-repeat center left`,
        lineHeight: '12px',
        paddingLeft: 18,
        marginLeft: 53,
        fontSize: '0.75rem',
        fontFamily: Fonts.INTER,
        color: Colors.BLACK_ELEVEN,
      },
    },

    aidOptions: {
      '& .MuiFormControlLabel-root': {
        margin: 0,
        display: 'block',
      },

      '& .MuiFormControlLabel-root .MuiFormControlLabel-label': {
        background: Colors.WHITE_ONE,
        border: `1px solid ${Colors.BLACK_TEN}`,
        borderRadius: 25,
        color: Colors.BLACK_TEN,
        height: 38,
        justifyContent: 'center',
        padding: '0 10px',
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        maxWidth: 345,
        margin: '0 auto 14px',

        '&:hover': {
          opacity: 0.5,
        },
      },

      '& .MuiIconButton-colorPrimary': {
        display: 'none',
      },

      '& .MuiFormControlLabel-root .Mui-checked + .MuiFormControlLabel-label': {
        backgroundColor: `rgba(47, 128, 237, 0.25) !important`,
        opacity: `1 !important`,
        color: Colors.BLACK_TWELVE,
        border: 'none',
      },
    },

    termsText: {
      '& a': {
        color: Colors.BLACK_TEN,
        textDecoration: 'underline !important',
        marginLeft: 4,
      },
    },

    checkListHeadingDone: {
      fontSize: 18,
      fontWeight: 600,
      color: '#525454',
      fontFamily: fonts.INTER,
      letterSpacing: '-0.252px',
    },

    checkListHeading: {
      fontSize: 15,
      color: Colors.BLACK_TEN,
      fontWeight: 600,
      fontFamily: Fonts.INTER,

      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },

    checkList: {
      maxWidth: 430,
      display: 'flex',
      margin: '0 auto',
      color: Colors.BLACK_TEN,
      alignItems: 'flex-start',

      '& p': {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '20px',
        fontFamily: Fonts.INTER,
        letterSpacing: '-0.084px',
      },

      '& img': {
        marginRight: 12,
      },
    },

    passwordIndecator: {
      display: 'flex',
      alignItems: 'center',
      color: Colors.BLACK_TEN,
      fontSize: 10,
      fontWeight: 300,
      fontFamily: Fonts.INTER,

      '& .cross': {
        display: 'none',

        '& svg': {
          color: Colors.RED_ONE,
        },
      },

      '&.error': {
        color: Colors.RED_ONE,

        '& .tick': {
          display: 'none',
        },

        '& .cross': {
          display: 'block',
          color: Colors.RED_ONE,
        },
      },

      '& svg': {
        height: 11,
        width: 11,
        marginRight: 5,
        color: '#ECEBEA',
      },

      '&.success': {
        '& svg': {
          color: '#45CE93',
        },
      },
    },
    authDialog: {
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0,.8)',
      },
    },

    collegeAidProBooking: {
      "& [class*='MuiTypography-h3']": {
        fontSize: 24,
        fontWeight: 700,
        marginBottom: 7,
        color: Colors.BLUE_TWELVE,
        letterSpacing: '-0.456px',
      },

      "& [class*='MuiTypography-body1']": {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '20px',
        fontFamily: Fonts.INTER,
        letterSpacing: '-0.072px',
        color: Colors.BLACK_FOURTEEN,
      },

      '& .capSubtitle': {
        marginBottom: 32,
      },

      "& [class*='MuiTypography-h6']": {
        fontSize: 12,
        fontWeight: 600,
        marginBottom: 10,
        color: Colors.BLUE_SEVEN,
        letterSpacing: '0.216px',
        textTransform: 'uppercase',
      },

      "& [class*='MuiTypography-h5'], & [class*='MuiTypography-h6']": {
        fontFamily: Fonts.POPPINS_FONT,
      },

      '& .textFieldLabel': {
        "& [class*='MuiFormLabel-root']": {
          color: Colors.GRAY_TWENTYTWO,
        },
      },

      '& .textFieldLabelSpacing': {
        "& [class*='MuiFormControl-fullWidth']": {
          marginBottom: '15px !important',
        },
      },
    },

    advisorConsultantCard: {
      "& [class*='MuiTypography-h6']": {
        fontSize: 14,
        fontWeight: 700,
        marginBottom: 0,
        textTransform: 'initial',
        fontFamily: Fonts.INTER,
        letterSpacing: '-0.084px',
        color: Colors.BLACK_TWELVE,
      },

      "& [class*='MuiTypography-body2']": {
        fontSize: 14,
        fontWeight: 400,
        fontFamily: Fonts.INTER,
        letterSpacing: '-0.084px',
        color: Colors.GRAY_TWENTYTWO,
      },
    },
  })
);
