import {
  Box,
  Button,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { FC, useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Footer from '../../components/layout/Footer';
import Header from '../../components/layout/Header';

import { AppContext } from '../../contexts';

import { CONTENT_MIN_HEIGHT } from '../../common/constants';
import { REDIRECT_TO_LOGIN, USER_TOKEN } from '../../constants';

import Colors from '../../common/colors';
import Fonts from '../../common/fonts';

import TUTORS_BG from '../../img/tutors_desktop.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      alignItems: 'center',
      paddingTop: 50,

      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },

    contentBoxHeight: {
      [theme.breakpoints.up('sm')]: {
        minHeight: CONTENT_MIN_HEIGHT,
      },
    },

    description: {
      opacity: 0.6,
    },

    signButton: {
      padding: '9px 33px',
      fontSize: '16px',
      fontWeight: 600,
      background: Colors.BLUE_SEVEN,
      fontFamily: Fonts.POPPINS_FONT,
      color: Colors.WHITE_ONE,
      textTransform: 'capitalize',
      width: 'auto',
      marginTop: '10px',
      borderRadius: '3px',
      boxShadow: 'none',

      '&:hover': {
        background: Colors.BLUE_SEVEN,
        opacity: 0.9,
      },
    },

    imageContainer: {
      [theme.breakpoints.down('sm')]: {
        margin: '30px 0 40px',
      },
    },
  })
);

const HomePage: FC = () => {
  const {
    mainContainer,
    contentBoxHeight,
    description,
    signButton,
    imageContainer,
  } = useStyles();

  const { user } = useContext(AppContext);
  if (true) {
    localStorage.removeItem(USER_TOKEN);
    localStorage.removeItem(REDIRECT_TO_LOGIN);
    localStorage.removeItem('userRole');
  }
  const queryParams = new URLSearchParams(window.location.search);
  const cjevent = queryParams.get('cjevent');

  const appendParam = () => {
    if (cjevent) {
      return `?cjevent=${cjevent}`;
    } else {
      return '';
    }
  };

  return (
    <>
      {user ? (
        user.isParent ? (
          <Redirect to="/account/student-accounts?parent=true" />
        ) : (
          <Redirect to={`/home${appendParam()}`} />
        )
      ) : (
        <Redirect to={`/login${appendParam()}`} />
      )}
      <Header />
      123
      <Container className={contentBoxHeight} maxWidth={false}>
        <Grid container className={mainContainer}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box component="h1">Level Up Your College Applications</Box>

            <Box
              fontSize={15}
              letterSpacing="0.3375px"
              color={Colors.BLACK_THREE}
              lineHeight="26px"
              className={description}
            >
              CollegeAdvisor.com provides high school students the competitive
              edge they need to get accepted by their target schools.
            </Box>

            <Link to="/register">
              <Button variant="contained" type="submit" className={signButton}>
                Sign Up
              </Button>
            </Link>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} className={imageContainer}>
            <img src={TUTORS_BG} alt="banner" />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default HomePage;
