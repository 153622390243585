import { Box, Card, Container, Grid, Typography } from '@material-ui/core';
import { FC, useContext, useEffect } from 'react';
import { cjConfig } from '../../../common/utility';

import FooterNew from '../../../components/layout/FooterNew';

import OldAccountMenu from '../../../components/OldAccountMenu';
import { AppContext } from '../../../contexts';
import FreeUser from './FreeUser';
import MyPlan from './MyPlan';
import OldBilling from './OldBilling';
import ParentPlanBiling from './ParentPlanBiling';
import SelectCardAccount from './SelectCardAccount';
import { useStyles } from './styles';
import AccountMenu from '../../../components/AccountMenu';

const OldBillingPage: FC = () => {
  const classes = useStyles();
  const { user } = useContext(AppContext);

  useEffect(() => {
    cjConfig({ userId: user?.id });
  }, []);

  if (!user) return null;
  const { premiumPackageType } = user;

  return (
    <Box>
      <Container maxWidth={false}>
        <Box className={classes.mainContainer}>
          <Box className={classes.myAccountHeader}>
            <Typography variant="h3">My Account</Typography>
          </Box>

          <Grid container spacing={3}>
            <Grid item lg={3} md={4} sm={12} xs={12}>
              {user.isMBAGrad ? <AccountMenu /> : <OldAccountMenu />}
            </Grid>

            {user.isParent ? (
              <Grid item lg={9} md={8} sm={12} xs={12}>
                <ParentPlanBiling />
              </Grid>
            ) : premiumPackageType ? (
              <Grid item lg={9} md={8} sm={12} xs={12}>
                {user?.userSubscription && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card className={classes.myAccountCard}>
                      <MyPlan />
                    </Card>
                  </Grid>
                )}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card className={classes.myAccountCard}>
                    <OldBilling />
                  </Card>
                </Grid>

                {/* Following condition is for the new/old users as old users will not have userSubscriptions */}
                {user?.userSubscription && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card className={classes.myAccountCard}>
                      <SelectCardAccount isBillingPage={true} />
                    </Card>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid item lg={9} md={8} sm={12} xs={12}>
                <Card className={classes.myAccountCard}>
                  <FreeUser />
                </Card>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>

      <FooterNew />
    </Box>
  );
};

export default OldBillingPage;
