import { FormEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Field } from 'formik';
import PhoneInput from 'react-phone-number-input';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';

import { useStyles } from './Styles';
import { TextField } from './../../components/fields';
import { getYearsArray } from './lib';
import Dropdown from './../../components/Dropdown';

const StudentInformation = ({
  setFieldValue,
  parentorStudent,
  yearValue,
  setYearValue,
  errors,
  touched,
  setExisting,
  leadData,
}: {
  setFieldValue: Function;
  setYearValue: Function;
  parentorStudent: string;
  yearValue: string;
  errors: any;
  touched: any;
  setExisting: Function;
  leadData: any;
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (leadData) {
      setChecked(false);
      setExisting(false);
    }
  }, [leadData]);

  return (
    <>
      <Grid container className={classes.studentParentForm} spacing={3}>
        <Grid item md={6} sm={12} xs={12}>
          <Typography variant="caption">First Name</Typography>
          <Field
            variant="filled"
            name="studentFirstName"
            component={TextField}
            label="First Name"
            placeholder="First Name"
            onChange={(e: FormEvent<HTMLInputElement>) => {
              const {
                currentTarget: { value },
              } = e;
              setFieldValue('studentFirstName', value);
            }}
            InputLabelProps={{ shrink: true }}
            fullWidth
            className={clsx(classes.textfield, classes.textfieldMobile)}
          />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Typography variant="caption">Last Name</Typography>
          <Field
            variant="filled"
            name="studentLastName"
            component={TextField}
            label="Last Name"
            placeholder="Last Name"
            onChange={(e: FormEvent<HTMLInputElement>) => {
              const {
                currentTarget: { value },
              } = e;

              setFieldValue('studentLastName', value);
            }}
            InputLabelProps={{ shrink: true }}
            fullWidth
            className={classes.textfield}
          />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Typography variant="caption">Student's Email</Typography>
          <Field
            variant="filled"
            name="studentEmail"
            component={TextField}
            label="Student's Email"
            placeholder="Student's Email"
            onChange={(e: FormEvent<HTMLInputElement>) => {
              const {
                currentTarget: { value },
              } = e;

              setFieldValue('studentEmail', value);
            }}
            InputLabelProps={{ shrink: true }}
            fullWidth
            className={classes.textfield}
          />
          {checked && (
            <Grid className={classes.parentInformationSection}>
              <Typography variant="body1">
                NOTE: Using the student’s real email is highly recommended to
                prevent downstream issues.
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Typography variant="caption">Student's Phone Number</Typography>

          <Field
            name="studentPhone"
            placeholder="Student's Phone Number"
          >
            {({
              field,
              form: { errors, handleBlur, ...rest },
              ...otherProps
            }: any) => {
              return (
                <Box className="phoneInputMasterResolve">
                  <Box
                    className={`${classes.phoneInputNew} ${
                      errors.studentPhone ? 'error' : ''
                    }`}
                    textAlign="left"
                  >
                    <PhoneInput
                      placeholder="Student's Phone Number"
                      value={field.value}
                      defaultCountry="US"
                      {...rest}
                      {...otherProps}
                      onBlur={handleBlur}
                      onChange={(value) => {
                        setFieldValue(field.name, value);
                      }}
                      error={errors.studentPhone}
                    />

                    {errors.studentPhone && (
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                        {errors.studentPhone}
                      </p>
                    )}
                  </Box>
                </Box>
              );
            }}
          </Field>
        </Grid>

        <Grid item md={4} sm={12} xs={12} className={classes.graduationYear}>
          <Typography variant="body1" className="fieldCaption">
            High School Grad Year
          </Typography>
          <Box maxWidth={148}>
            <Dropdown
              label="High School"
              errorValue={
                touched.highSchoolYear && !yearValue && errors.highSchoolYear
              }
              selectedValue={yearValue || ''}
              options={getYearsArray()}
              isError={touched.highSchoolYear && !yearValue}
              name="year"
              emptyDisplay={true}
              onSelect={(evt: any) => {
                const {
                  target: { value },
                } = evt;
                setYearValue(value);
                if (value !== 'Other')
                  setFieldValue('highSchoolYear', value.toString());
              }}
            />
          </Box>
        </Grid>

        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          className={classes.associatedCheckbox}
        >
          <FormControlLabel
            control={
              <Checkbox
                name="associated"
                color="primary"
                onChange={({ target: { checked } }) => {
                  setExisting(checked);
                  setChecked(checked);
                }}
                checked={checked}
              />
            }
            label="This student is associated with an existing lead / parent."
          />
        </Grid>

        {yearValue === 'Other' && (
          <Grid
            item
            md={5}
            sm={12}
            xs={12}
            className={classes.textfieldGraduation}
          >
            <Field
              variant="filled"
              name="highSchoolYear"
              component={TextField}
              label="Grad Year"
              placeholder="Grad Year"
              onChange={(e: FormEvent<HTMLInputElement>) => {
                const {
                  currentTarget: { value },
                } = e;

                const numberArray: RegExpMatchArray | null =
                  value.match(/\d+/g);
                if (numberArray) {
                  setFieldValue('highSchoolYear', numberArray.toString());
                } else {
                  setFieldValue('highSchoolYear', '');
                }
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              className={classes.textfield}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default StudentInformation;
