import ReactDOM from 'react-dom';
import * as FullStory from '@fullstory/browser';
import TagManager from 'react-gtm-module';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.scss';

const tagManagerArgs = {
  gtmId: `${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}`,
};

TagManager.initialize(tagManagerArgs);

FullStory.init({ orgId: '11R4HD' });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
