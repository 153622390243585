import { FC, useEffect } from 'react';
import MentorsCard from './MentorsCard';

import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import { MentorDetailQuery_Mentor_otherMentors } from './__generated__/MentorDetailQuery';

import { CollegeDetailsQuery_College_mentors } from '../colleges/__generated__/CollegeDetailsQuery';

import Colors from '../../../common/colors';
import Fonts from '../../../common/fonts';
import { isValueBetween } from '../../../common/utility';
import { GlobalStyle } from '../../../styles/baseStyle';
import { AllMentorsListQuery_allMentors_mentors } from './__generated__/AllMentorsListQuery';

const MentorsMedia = styled.div`
  .MuiCardHeader-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: -0.5px;
    color: ${Colors.BLACK_THREE};
    font-family: ${Fonts.POPPINS_FONT};
    @media only screen and (max-width: 991px) {
      font-size: 20px;
    }
  }
  .MuiCardHeader-subheader {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.3375px;
    color: ${Colors.BLACK_ONE};
    font-family: ${Fonts.POPPINS_FONT};
    @media only screen and (max-width: 1200px) {
      font-size: 13px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .MuiCardHeader-avatar {
      margin-right: 0px;
    }
  }
`;

interface Props {
  loadMore?: Function;
  mentors: (AllMentorsListQuery_allMentors_mentors | null)[];
  isLoadingMore?: boolean;
  isDetailPage?: boolean;
}

let timeoutId: any = 0;
const MentorsList: FC<Props> = ({
  mentors,
  loadMore,
  isLoadingMore,
  isDetailPage,
}) => {
  const useEffectCallBack = () => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  };

  useEffect(useEffectCallBack, [isLoadingMore]);

  const handleScroll = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      const {
        documentElement: { offsetHeight },
        scrollingElement,
      } = document;
      const scrollHeight = scrollingElement ? scrollingElement.scrollTop : 0;
      if (
        isValueBetween(
          scrollHeight + window.innerHeight,
          offsetHeight - window.innerHeight,
          offsetHeight + 50
        ) &&
        loadMore &&
        !isLoadingMore
      ) {
        loadMore();
      }
    }, 200);
  };

  const filteredMentors = mentors
    ? mentors.filter((mentor: any) => !!mentor && (mentor.advisorLevel === 'Former Admissions Officer' || !!mentor.currentCollege))
    : [];

  return (
    <GlobalStyle>
      <MentorsMedia>
        <Grid container spacing={2}>
          {filteredMentors &&
            filteredMentors
              .filter(
                (mentor: any, i: any, arr: any) =>
                  arr.findIndex(
                    (obj: any) => !!obj && !!mentor && obj.id === mentor.id
                  ) === i
              )
              .map((mentor) => {
                if (!mentor) return null;
                return (
                  <MentorsCard
                    key={mentor.id}
                    mentor={mentor}
                    isDetailPage={isDetailPage}
                  />
                );
              })}
        </Grid>
      </MentorsMedia>
    </GlobalStyle>
  );
};

MentorsList.defaultProps = {
  isDetailPage: false,
};

export default MentorsList;
