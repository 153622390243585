import { FC, useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Query } from 'react-apollo';
import {
  makeStyles,
  createStyles,
  Container,
  Box,
  Collapse,
} from '@material-ui/core';

import IntakeFormHeader from './IntakeFormHeader';
import IntakeFormInfo from './IntakeFormInfo';
import IntakeFormUser from './IntakeFormUser';
import IntakeFormStepper from './IntakeFormStepper';
import IntakeFormStepBar from './IntakeFormStepBar';
import { Error404 } from '../../errorPages';
import Loader from '../../../components/Loader';
import {
  intakeFormQuestionsForStudent,
  intakeFormQuestionsForParent,
} from './Questions/static';

import { GET_USER_FROM_INTAKE_TOKEN } from './gql';
import { getUserFromIntakeTokenQuery } from './gql/__generated__/getUserFromIntakeTokenQuery';
import { cjConfig } from '../../../common/utility';

const useStyles = makeStyles(() =>
  createStyles({
    intakeMainContainer: {
      padding: '67px 0 50px 0',
      minHeight: 'calc(100vh - 121px)',
    },
  })
);
interface TParams {
  token: string;
}

const IntakeForm: FC<RouteComponentProps<TParams>> = ({
  match: {
    params: { token },
  },
  history,
}) => {
  const [intakeFlow, setIntakeFlow] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const classes = useStyles();
  const handleDashBoard = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const userToken: any =  urlParams.get('userToken');
    if(userToken) {
      // block: when enrollment done as guest using userToken
      history.push(`/reset-password/${userToken}?step=2`);
    } else {
      // block: when enrollment done while logged in
      history.replace('/');
    }
  };

  useEffect(() => {
    cjConfig({});
  }, []);

  return (
    <Box>
      <Query<getUserFromIntakeTokenQuery>
        fetchPolicy="network-only"
        query={GET_USER_FROM_INTAKE_TOKEN}
        variables={{ intakeFormToken: token }}
        onCompleted={(data) => {
          if (data.getUserFromIntakeToken?.intakeResponse) {
            const value = JSON.parse(
              data.getUserFromIntakeToken?.intakeResponse
            );
            if (value?.step) {
              let step = +value?.step;
              if (
                (data.getUserFromIntakeToken.roles?.includes('Student') &&
                  value?.step === 6) ||
                (data.getUserFromIntakeToken.roles?.includes('Parent') &&
                  value?.step === 3)
              )
                step = step + 1;
              setCurrentStep(step);
            }
          }
        }}
      >
        {({ data, loading }) => {
          if (loading) return <Loader />;
          if (!data?.getUserFromIntakeToken) return <Error404 />;

          const user = data.getUserFromIntakeToken;
          const role = user.roles?.includes('Student')
            ? 'Student'
            : user.roles?.includes('Parent')
            ? 'Parent'
            : null;
          if (!role) return <h3>Its not a Parent or Student</h3>;
          const Questions =
            role === 'Student'
              ? intakeFormQuestionsForStudent
              : intakeFormQuestionsForParent;
          let initialValues = data.getUserFromIntakeToken.intakeResponse;
          if (initialValues) {
            initialValues = JSON.parse(initialValues);
          }
          const intakeForProps = {
            title: initialValues
              ? `Continue ${role} Intake Form`
              : `${role} Intake Form`,
            description:
              'Please take a few minutes to fill out this form so we can better help you achieve your college goals!',
            instructions: initialValues
              ? 'Welcome back — we remembered where you left off.'
              : "Finish as much as you can and come back later – we'll remember where you left off.",
            continueInstructions:
              "Finish as much as you can and come back later – we'll remember where you left off.",
            buttonText: initialValues ? 'Continue' : 'Let’s Go!',
            titleMaxWidth: true,
            estimatedTime: initialValues
              ? '15 min / Estimated Total Completion Time'
              : `15 min - Estimated Completion Time`,
            letsGoClick: setIntakeFlow,
          };
          if (initialValues) intakeForProps.description = '';
          else {
            intakeForProps.titleMaxWidth = false;
            intakeForProps.continueInstructions = '';
          }

          return (
            <>
              <IntakeFormHeader title={`${role} Intake Form`} />
              <Container maxWidth={false}>
                <Box className={classes.intakeMainContainer}>
                  {currentStep <= Questions.length && (
                    <Collapse in={!intakeFlow} mountOnEnter unmountOnExit>
                      <Box>
                        <IntakeFormUser
                          userImageUrl={`${user.pictureUrl || ''}`}
                          userName={`${user.firstName || ''} ${
                            user.lastName || ''
                          }`}
                        />

                        {initialValues && (
                          <Box maxWidth={550} margin="auto">
                            <IntakeFormStepBar
                              totalSteps={Questions.length}
                              currentStep={currentStep}
                              isContinue={true}
                            />
                          </Box>
                        )}

                        <IntakeFormInfo {...intakeForProps} />
                      </Box>
                    </Collapse>
                  )}

                  {currentStep <= Questions.length && (
                    <Collapse in={intakeFlow} mountOnEnter unmountOnExit>
                      <IntakeFormStepper
                        totalSteps={Questions.length}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        isContinue={false}
                        stepHeading={Questions[currentStep - 1].stepperHeading}
                        token={token}
                        initialValues={initialValues}
                        role={role}
                        user={user}
                      />
                    </Collapse>
                  )}

                  <Collapse
                    in={currentStep > Questions.length}
                    mountOnEnter
                    unmountOnExit
                  >
                    <Box
                      flex={1}
                      display="flex"
                      justifyContent="center"
                      minHeight="calc(100vh - 200px)"
                    >
                      <IntakeFormInfo
                        title="Complete!"
                        description={`Thank you for completing the ${role} Intake Form and helping us better understand your needs. We are excited to help you on your journey to apply to colleges!`}
                        buttonText="Go to Dashboard"
                        letsGoClick={handleDashBoard}
                      />
                    </Box>
                  </Collapse>
                </Box>
              </Container>
            </>
          );
        }}
      </Query>
    </Box>
  );
};

export default withRouter(IntakeForm);
