export const TOKEN = 'token';
export const SHOW_PARENT_MODAL = 'showParentMddal';
export const PARENT_TOKEN = 'parentToken';
export const APP_VERSION = 'app_version';
export const USER_TOKEN = 'userToken';
export const REDIRECT_TO_LOGIN = 'redirectToLogin';
export const MEETING_SET = 'meeting_set';
export const CALENDLY_MEETING_URL_FOR_MBA_STUDENT =
  'https://calendly.com/usnca-mba/college-admissions-strategy-session?hide_gdpr_banner=1';
export const CALENDLY_MEETING_URL_FOR_STUDENT =
  'https://calendly.com/usnca-students/college-admissions-strategy-session?hide_gdpr_banner=1';

export const CALENDLY_MEETING_URL_FOR_PARENT =
  'https://calendly.com/usnca-parents/college-admissions-strategy-session?hide_gdpr_banner=1';

export const ASSOCIATE_TYPE_ALA_CARTE = 'Ala-carte';

// constants to track partners in from the product registration
export const PARTNER_PRODUCT_REGISTRATION = 'PARTNER_PRODUCT_REGISTRATION';
export const PARTNER_WEBINAR_REGISTRATION = 'PARTNER_WEBINAR_REGISTRATION';
export const PARTNER_SUMMER_OPPORTUNITY = 'PARTNER_SUMMER_OPPORTUNITY';
export const MEETING_CANCEL_REASONS = [
  {
    label: 'Student conflict',
    value: 'student-conflict',
  },
  {
    label: 'Not prepared',
    value: 'not-prepared',
  },
  {
    label: 'Meeting no longer needed',
    value: 'no-needed',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const ALL_MEETING_CANCEL_REASONS = [
  {
    label: 'Student conflict ',
    value: 'student-conflict',
  },
  {
    label: 'Advisor conflict',
    value: 'advisor-conflict',
  },
  {
    label: 'Student emergency',
    value: 'student-emergency',
  },
  {
    label: 'Advisor emergency',
    value: 'advisor-emergency',
  },
  {
    label: 'Student not prepared',
    value: 'not-prepared',
  },
  {
    label: 'Meeting no longer needed',
    value: 'no-needed',
  },
  {
    label: 'Student No-Show',
    value: 'no-show',
  },
  {
    label: 'Conflicting appointment',
    value: 'conflicting-appointment',
  },
  {
    label: 'Other',
    value: 'other',
  },
  {
    label: 'Student Called To Cancel',
    value: 'called-to-cancel',
  },
  {
    label: 'Rescheduled',
    value: 'rescheduled',
  },
];

export const optionsDecision = [
  { value: 'Accepted', label: 'Accepted' },
  { value: 'Denied', label: 'Denied' },
  { value: 'Deferred', label: 'Deferred' },
  { value: 'Waitlisted', label: 'Waitlisted' },
  { value: 'Withdraw', label: 'Withdraw' },
  { value: 'Did not apply', label: 'Did not apply' },
];

export const GRADUATE_ESSAY_EDITING = 'graduateessayediting';
