import { useContext } from 'react';
import { Box, Card } from '@material-ui/core';
import { Redirect } from 'react-router-dom';

import { AppContext } from '../../contexts';
import { useStyles } from './styles';
import NewLoginForm from './NewLoginForm';
import RightSidePage from './LeadRegistration/RightSidePage';
import { getFullGoBackUrl } from '../../common/utility';

const NewLoginPage = (): JSX.Element => {
  const { user } = useContext(AppContext);
  const urlParams = new URLSearchParams(window.location.search);
  let goBack;
  goBack = urlParams.get('_goback');
  if (goBack?.includes('ranking')) {
    goBack = getFullGoBackUrl();
  }
  const classes = useStyles();

  return (
    <Box>
      {user &&
        (goBack ? (
          <Redirect to={goBack} />
        ) : !user.isParent ? (
          /* 
             used search for opening Calendar Dialog
           */
          <Redirect to={`/home${window.location.search}`} />
        ) : (
          <Redirect to="/account/student-accounts?parent=true" />
        ))}

      <Card className={`${classes.cardModal} custom-card`}>
        <Box className={classes.registerContainer}>
          <Box className={classes.registerLeft}>
            <NewLoginForm _goback={goBack ? window.location.search : goBack} />
          </Box>

          <RightSidePage />
        </Box>
      </Card>
    </Box>
  );
};

export default NewLoginPage;
