import { FC } from 'react';

import {
  Button,
  createStyles,
  Grid,
  Theme,
  Typography,
} from '@material-ui/core';

import moment from 'moment';
import momentTz from 'moment-timezone';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnailCardOverlay: {
      background: '#272929',
      opacity: 0.9,
      width: 192,
      height: 192,
      padding: '20px 13px 15px',
      borderRadius: 20,
      position: 'absolute',

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },

      '& .overlayText, & .webinarText': {
        fontSize: 12,
        color: '#ECEBEA',
        marginBottom: 8,
        lineHeight: '20px',
      },

      '& .overlayDate': {
        fontWeight: 'bold',
        fontSize: 10,
        color: '#F8F6F4',
        padding: 0,
        textAlign: 'left',
        margin: '15px 0px',
        lineHeight: 'initial',
      },
    },
  })
);

interface Props {
  title?: string | null;
  date?: string | null;
  recording_url?: string | null;
}

const ThumbnailCardOverlaySmall: FC<Props> = ({ title, date }) => {
  const classes = useStyles();

  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <>
      <Grid className={classes.thumbnailCardOverlay}>
        {!isSafari &&
          <Typography variant="body1" className="overlayText overlayTextSmall">
            {title}
          </Typography>
        }

        {isSafari &&
          <Typography variant="body1" className="webinarText">
            {title &&
              (title?.length > 90 ? title?.substring(0, 70) + ' ...' : title)}
          </Typography>
        }

        <Typography variant="h6" className="overlayDate">
          {moment(date).format('MMM DD, YYYY - h:mmA ')}
          {`${momentTz.tz(momentTz.tz.guess()).format('z')}`}
        </Typography>
        <Button fullWidth variant="contained" color='secondary'>
          View Details
        </Button>
      </Grid>
    </>
  );
};

export default ThumbnailCardOverlaySmall;
