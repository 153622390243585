import { Box } from '@material-ui/core';
import TickIcon from './layout/TickIcon';
import CrossIcon from './layout/CrosssIcon';
import { useStyles } from '../pages/login/styles';

const ValidatationCheckMark = ({
  type = '',
  helperText,
}: {
  type: 'error' | 'success' | '';
  helperText: string;
}) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.passwordIndecator} ${type}`}>
      <Box className="tick">
        <TickIcon />
      </Box>
      <Box className="cross">
        <CrossIcon />
      </Box>
      {helperText}
    </Box>
  );
};

export default ValidatationCheckMark;
