import { FC } from 'react';
import { Box, CircularProgress } from '@material-ui/core';

import { ILoader } from '../../../common/interfaces';

import { loaderStyles } from './MBALoaderStyles';

import colors from '../../../common/colors';

const MBALoader: FC<ILoader> = ({
  isSmallLoader,
  isXSLoader,
  filerZindex,
  loaderText,
  isTransparent,
}) => {
  const classes = loaderStyles();

  return (
    <Box
      height={!!isSmallLoader ? '100%' : '100vh'}
      minHeight={isXSLoader ? 'unset' : 200}
      width="100%"
      position={!!isSmallLoader ? 'relative' : 'absolute'}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      left={0}
      top={0}
      zIndex={filerZindex ? 0 : 99}
      className={
        isTransparent ? classes.tLoaderContainer : classes.loaderContainer
      }
    >
      <CircularProgress
        className={isXSLoader ? classes.smallProgress : classes.progress}
      />

      <Box
        fontWeight={600}
        fontSize={14}
        letterSpacing="0.3375px"
        color={colors.BLUE_SEVEN}
        display="block"
        marginTop={1.5}
        className={classes.loaderText}
      >
        {!isTransparent
          ? loaderText
            ? loaderText
            : 'Loading, PLEASE WAIT...'
          : null}
      </Box>
    </Box>
  );
};

export default MBALoader;
