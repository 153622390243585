import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Grid, Box, Button, Modal, Container } from '@material-ui/core';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Mustache from 'mustache';
import { Link } from 'react-router-dom';

import { AppContext } from '../../../contexts';

import AccountMenu from '../../../components/AccountMenu';
import FooterNew from '../../../components/layout/FooterNew';
import Header from '../../../components/layout/Header';
import Loader from '../../../components/Loader';

import Colors from '../../../common/colors';
import Fonts from '../../../common/fonts';
import { ButtonOutlineDark } from '../../../styles/common';
import { useStyles } from './styles';

import { UserSubscriptionQuery } from './__generated__/UserSubscriptionQuery';
import {
  CancelUserSubscription,
  CancelUserSubscription_cancelUserSubscription,
} from './__generated__/CancelUserSubscription';
import { cjConfig } from '../../../common/utility';

const ModalPaper = styled.div`
  position: absolute;
  max-width: 528px;
  width: 100%;
  padding: 10px 20px 0;
  background-color: ${Colors.WHITE_ONE};
  outline: none;
  overflow-y: auto;
  opacity: 1;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 767px) {
    height: 100vh;
    border-radius: 0px;
  }
`;

const ModalBody = styled.div`
  padding: 11px 0px 47px;

  @media only screen and (max-width: 767px) {
    padding: 62px 0px 40px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.3375px;
    color: ${Colors.BLACK_SIX};
    margin: 14px 0px 0px;
  }

  .delete_button {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.77px;
    color: ${Colors.WHITE_ONE} !important;
    border-radius: 100px;
    background: ${Colors.RED_ONE};
    padding: 8px 18px;
    text-decoration: none;
    margin-right: 10px;

    &:hover {
      opacity: 0.9;
      background: ${Colors.RED_ONE};
    }
  }

  button {
    width: 142px;

    @media only screen and (max-width: 767px) {
      width: 100%;
      margin: 0 0 20px !important;
    }
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${Colors.RED_ONE};
  }

  .MuiCheckbox-root {
    color: ${Colors.GRAY_NINE};
  }

  .MuiIconButton-colorSecondary:hover {
    background-color: rgba(39, 174, 96, 0.08);
  }

  .MuiTypography-body1 {
    font-family: ${Fonts.POPPINS_FONT};
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.3375px;
    color: ${Colors.BLACK_SIX};
  }

  .checkbox_form_group {
    padding-left: 35px;
    margin-bottom: 41px;
    text-align: left;

    @media only screen and (max-width: 480px) {
      padding-left: 0px;
    }
  }
`;

const ModalContentTitle = styled.div`
  margin-bottom: 23px;

  @media only screen and (max-width: 767px) {
    margin-bottom: 17px;
  }

  h3 {
    font-weight: 600;
    font-size: 45px;
    line-height: 84px;
    text-align: center;
    letter-spacing: -2.5px;
    color: ${Colors.BLACK_THREE};
    margin: 0px;

    @media only screen and (max-width: 767px) {
      font-size: 26px;
      line-height: 30px;
      letter-spacing: 0px;
      margin-bottom: 7px;
    }
  }

  p {
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.3375px;
    color: ${Colors.BLACK_THREE};
    margin: 0px;

    @media only screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

const USER_SUBSCRIPTION_QUERY = gql`
  query UserSubscriptionQuery {
    UserSubscription {
      id
      SubscriptionPlan {
        amount
        interval
        SubscriptionProduct {
          name
          description
          advisingHours
        }
      }
    }
    UserAdmissionPackage {
      id
      SubscriptionPlan {
        SubscriptionProduct {
          name
          description
          advisingHours
        }
      }
    }
  }
`;

const CANCEL_USER_SUBSCRIPTION_MUTATION = gql`
  mutation CancelUserSubscription {
    cancelUserSubscription {
      canceled
    }
  }
`;

const SubscriptionPage: FC = () => {
  useEffect(() => {
    cjConfig({ userId: user?.id });
  }, []);
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const { user, setUser } = useContext(AppContext);
  if (!user) return null;

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const planData = (product: any) => (
    <>
      <Box display="flex" marginBottom={2} alignItems="center">
        <Box fontWeight={600} fontSize={18}>
          {product.name || ''}
        </Box>

        {product.amount && (
          <Box fontWeight={600} fontSize={16} marginLeft={2}>
            ${product.amount || 0}
            <Box fontWeight={400} fontSize={12} component="span">
              /Month
            </Box>
          </Box>
        )}
      </Box>

      <Box marginBottom={3}>
        <ul>
          {product.description &&
            product.description.split('--').map((feature: any, i: number) => {
              if (i < 0) return null;

              return (
                <li key={`plan-feature-${i}`}>
                  <span>{Mustache.render(feature, product)}</span>
                </li>
              );
            })}
        </ul>
      </Box>
    </>
  );

  return (
    <Grid>
      <Header />

      <Container maxWidth={false}>
        <Box className={classes.mainContainer}>
          <Box component="h2" margin="50px 0px 39px">
            My Account
          </Box>

          <Grid container spacing={3}>
            <Grid item lg={3} md={4} sm={12} xs={12}>
              <AccountMenu />
            </Grid>

            <Grid item lg={9} md={8} sm={12} xs={12}>
              <Box className={classes.profileForm}>
                <Box className="info-fields">
                  <h4>Subscription</h4>

                  <Query<UserSubscriptionQuery>
                    query={USER_SUBSCRIPTION_QUERY}
                    fetchPolicy="no-cache"
                  >
                    {({ data, loading, refetch, networkStatus }) => {
                      if (loading && networkStatus !== 4) return <Loader />;
                      if (
                        !data ||
                        (!data.UserSubscription && !data.UserAdmissionPackage)
                      ) {
                        return (
                          <Box fontSize={16}>
                            <Box marginBottom={2} fontWeight={600}>
                              No Active Subscription
                            </Box>

                            <Box marginBottom={3}>
                              You don't have any active subscription. Click on
                              the link below to explore our pricing options.
                            </Box>

                            <Link to="/pricing">
                              <Button color="primary" variant="contained">
                                View Plans
                              </Button>
                            </Link>
                          </Box>
                        );
                      }

                      let SUBSCRIPTION_PLAN = null;
                      let SUBSCRIPTION_PRODUCT: any = null;
                      let ADMISSION_PACKAGE = null;
                      let ADMISSION_PRODUCT: any = null;

                      const { UserSubscription, UserAdmissionPackage } = data;
                      if (UserSubscription) {
                        SUBSCRIPTION_PLAN = UserSubscription?.SubscriptionPlan;
                        SUBSCRIPTION_PRODUCT =
                          SUBSCRIPTION_PLAN?.SubscriptionProduct;
                      }
                      if (UserAdmissionPackage) {
                        ADMISSION_PACKAGE =
                          UserAdmissionPackage.SubscriptionPlan;
                        ADMISSION_PRODUCT =
                          ADMISSION_PACKAGE.SubscriptionProduct;
                      }

                      return (
                        <Grid>
                          {UserSubscription &&
                            SUBSCRIPTION_PLAN &&
                            SUBSCRIPTION_PRODUCT && (
                              <>
                                {planData(SUBSCRIPTION_PRODUCT)}

                                <Box
                                  display="flex"
                                  className={classes.subscriptionButtons}
                                >
                                  <Box marginRight={2}>
                                    <Link to="/pricing">
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={toggleModal}
                                      >
                                        Upgrade
                                      </Button>
                                    </Link>
                                  </Box>

                                  <Button
                                    variant="outlined"
                                    onClick={toggleModal}
                                  >
                                    Cancel Subscription
                                  </Button>
                                </Box>
                              </>
                            )}

                          {UserAdmissionPackage &&
                            ADMISSION_PACKAGE &&
                            ADMISSION_PRODUCT &&
                            planData(ADMISSION_PRODUCT)}

                          <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={openModal}
                            onClose={toggleModal}
                            disableBackdropClick={true}
                          >
                            <ModalPaper>
                              <ModalBody>
                                <ModalContentTitle className="text-center">
                                  <h3>Cancel Subscription</h3>
                                  <p>
                                    Are you sure you want to cancel your
                                    Subscription?
                                  </p>
                                </ModalContentTitle>

                                <Box textAlign="center" marginTop={6}>
                                  <Mutation<
                                    CancelUserSubscription,
                                    CancelUserSubscription_cancelUserSubscription
                                  >
                                    mutation={CANCEL_USER_SUBSCRIPTION_MUTATION}
                                  >
                                    {(cancelUserSubscription: Function) => {
                                      return (
                                        <ButtonOutlineDark
                                          className="m-3"
                                          onClick={async (variables) => {
                                            const result =
                                              await cancelUserSubscription();
                                            const {
                                              data: {
                                                cancelUserSubscription: {
                                                  canceled,
                                                },
                                              },
                                            } = result;

                                            if (canceled) {
                                              setUser(user);
                                              refetch();
                                            }
                                          }}
                                        >
                                          Yes
                                        </ButtonOutlineDark>
                                      );
                                    }}
                                  </Mutation>

                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    className="delete_button m-0"
                                    onClick={toggleModal}
                                  >
                                    No
                                  </Button>
                                </Box>
                              </ModalBody>
                            </ModalPaper>
                          </Modal>
                        </Grid>
                      );
                    }}
                  </Query>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <FooterNew />
    </Grid>
  );
};

export default SubscriptionPage;
