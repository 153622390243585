import React from 'react';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {}

const s3Mapping = {
  public: {
    s3Url: 'https://college-advisor-public-content.s3.us-east-2.amazonaws.com',
    folder: 's3',
  },
  private: {
    s3Url: 'https://bullseye-production.s3.us-east-2.amazonaws.com',
    folder: 's3-private',
  },
  privateStage: {
    s3Url: 'https://bullseye-stage.s3.us-east-2.amazonaws.com',
    folder: 's3-private-stage',
  },
};

const Image: React.FC<ImageProps> = ({
  src,
  loading = 'lazy',
  ...otherProps
}) => {
  let processedSrc = src;

  if (
    processedSrc &&
    processedSrc.match(new RegExp(s3Mapping.public.s3Url, 'i'))
  ) {
    let manipulations = 'f_auto';

    if (otherProps.width) {
      manipulations += ',w_' + otherProps.width;
    }

    if (otherProps.height) {
      manipulations += ',h_' + otherProps.height;
    }

    if (otherProps.width || otherProps.height) {
      manipulations += ',c_fill';
    }

    processedSrc = processedSrc.replace(
      new RegExp(s3Mapping.public.s3Url, 'i'),
      'https://res.cloudinary.com/collegeadvisor/image/upload/' +
        manipulations +
        '/' +
        s3Mapping.public.folder
    );
  }

  return <img src={processedSrc} loading={loading} {...otherProps} />;
};

export default Image;
