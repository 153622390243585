import gql from 'graphql-tag';

export const PLANS_LIST = gql`
  query getAllPlans(
    $userToken: String
    $highSchoolYear: String
    $page: String
    $packageType: String
  ) {
    getPlans(
      input: {
        userToken: $userToken
        highSchoolYear: $highSchoolYear
        page: $page
        packageType: $packageType
      }
    ) {
      id
      name
      color
      advisingHours
      totalEssayReviews
      shortName
      year
      packageType
      SubscriptionPlans {
        id
        interval
        amount
        installments
      }
    }
  }
`;

export const ONE_PLAN = gql`
  query getPlan($planId: Int!, $page: String) {
    getOnePlan(input: { planId: $planId, page: $page }) {
      id
      interval
      amount
      installments
      APR
      financingCharges

      AdmissionPackage {
        id
        name
        color
        advisingHours
        totalEssayReviews
        shortName
        packageType
      }
    }
  }
`;

export const PLACE_ORDER = gql`
  mutation placeOrder(
    $planId: Int!
    $source: String!
    $username: String
    $id: Int
    $StudentId: Int
    $page: String
    $SmartMatchId: Int
    $FullName: String
    $Html: String
    $initials: String
    $signedDate: Date
    $packageId: Int
    $discountCouponId: Int
  ) {
    placeOrder(
      input: {
        planId: $planId
        source: $source
        username: $username
        id: $id
        StudentId: $StudentId
        page: $page
        SmartMatchId: $SmartMatchId
        FullName: $FullName
        Html: $Html
        initials: $initials
        signedDate: $signedDate
        packageId: $packageId
        discountCouponId: $discountCouponId
      }
    ) {
      message
      success
      actionRequired
      clientSecret
      stripeSubscriptionId
      id
    }
  }
`;

export const SAVE_PROMO_CODE = gql`
  mutation SavePromoCode($promoCode: String!, $id: Int, $planIds: [Int]) {
    savePromo(promoCode: $promoCode, id: $id, planIds: $planIds) {
      message
      success
      userCoupon {
        id
        name
        percent_off
        amount_off
        stripeCouponId
        currency
        AdmissionPackageIds
      }
    }
  }
`;

export const ADD_USER_CREDIT_CARD_WITH_ADDRESS_MUTATION = gql`
  mutation AddUserCreditCardWithAddressMutation(
    $sourceId: String!
    $zip: String!
    $id: Int
    $page: String
  ) {
    addUserCreditCardWithAddress(
      input: { sourceId: $sourceId, zip: $zip, id: $id, page: $page }
    ) {
      success
      message
    }
  }
`;

export const REMOVE_PROMO = gql`
  mutation RemovePromo($id: Int) {
    removePromo(id: $id) {
      message
      success
    }
  }
`;

export const UPDATE_OR_CREATE_PARENT = gql`
  mutation UpdateOrdCreateParent(
    $id: Int
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
    $isCheckout: Boolean
    $userToken: String
    $isAccountSettings: Boolean
    $StudentId: Int
    $page: String
  ) {
    updateOrCreateParent(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        phoneNumber: $phoneNumber
        isCheckout: $isCheckout
        userToken: $userToken
        isAccountSettings: $isAccountSettings
        StudentId: $StudentId
        page: $page
      }
    ) {
      id
      firstName
      lastName
      email
      parents {
        id
        firstName
        lastName
        email
        phoneNumber
        isPasswordSet
      }
    }
  }
`;

export const CREATE_USER_ENROLLMENT_ENTRIES = gql`
  mutation CreateUserEnrollmentEntries(
    $planId: Int!
    $paymentIntentId: String!
    $stripeSubscriptionId: String
    $username: String
    $id: Int
    $page: String
    $SmartMatchId: Int
    $FullName: String
    $Html: String
    $initials: String
    $signedDate: Date
    $packageId: Int
    $discountCouponId: Int
  ) {
    userEnrollmentEntries(
      input: {
        planId: $planId
        paymentIntentId: $paymentIntentId
        stripeSubscriptionId: $stripeSubscriptionId
        username: $username
        id: $id
        page: $page
        SmartMatchId: $SmartMatchId
        FullName: $FullName
        Html: $Html
        initials: $initials
        signedDate: $signedDate
        packageId: $packageId
        discountCouponId: $discountCouponId
      }
    ) {
      message
      success
    }
  }
`;
export const DELETE_USER_SUBSCRIPTION = gql`
  mutation DeleteUserSubscription(
    $stripeSubscriptionId: String
    $stripePaymentId: String!
    $id: Int
  ) {
    deleteUserSubscription(
      input: {
        stripeSubscriptionId: $stripeSubscriptionId
        stripePaymentId: $stripePaymentId
        id: $id
      }
    ) {
      message
      success
    }
  }
`;

export const GET_USER_BY_TOKEN = gql`
  query getUserByToken($userToken: String!) {
    getUserByToken(input: { userToken: $userToken }) {
      id
      firstName
      lastName
      email
      isParent
      pictureUrl
      phoneNumber
      highSchool
      highSchoolYear
      applyingYear
      invitesSent
      gpa
      sat
      act
      isPremium
      premiumPackageType
      premiumTotalEssayCount
      premiumEssayCountUsed
      premiumTotalVideoCount
      premiumVideoCountUsed
      isAppNotificationOn
      createdAt
      intakeFormToken
      intakeResponse
      initialKickOffDate
      userToken
      roles
      parents {
        id
        firstName
        lastName
        email
        phoneNumber
        isPasswordSet
      }
      childrens {
        id
      }
      lastLocation {
        city
        country
        region
        timezone
      }
      userSubscription {
        id
        stripeSubscriptionId
        createdAt
        subscriptionPlanId
        SubscriptionPlan {
          id
          amount
          SubscriptionProduct {
            id
            shortName
            color
            name
          }
          AdmissionPackage {
            id
            shortName
            color
            name
          }
        }
      }
      lastLoginAt
      origin
      createdInSalesforce
      userCoupon {
        id
        name
        percent_off
        amount_off
        stripeCouponId
        currency
        AdmissionPackageIds
      }
      isOnline
      timezone
      isMBAGrad
      SmartMatchId
    }
  }
`;

export const UPDATE_STUDENT_BY_USERTOKEN = gql`
  mutation UpdateStudentByUserToken(
    $userToken: String!
    $highSchoolYear: String!
  ) {
    updateStudentByUserToken(
      input: { userToken: $userToken, highSchoolYear: $highSchoolYear }
    ) {
      success
      message
    }
  }
`;

export const REMOVE_STRIPE_SOURCE = gql`
  mutation RemoveStripeSource($source: String!) {
    removeStripeSource(source: $source) {
      success
      message
    }
  }
`;

export const HAS_STUDENTS = gql`
  query HasStudents($ParentId: Int!) {
    hasStudents(ParentId: $ParentId) {
      id
      firstName
      lastName
      email
      phoneNumber
      highSchoolYear
      isPremium
      userToken
      roles
    }
  }
`;

export const UPDATE_SELF = gql`
  mutation UpdateSelfUser(
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
    $userToken: String
    $page: String
    $highSchoolYear: String
  ) {
    updateSelfUser(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phoneNumber: $phoneNumber
        highSchoolYear: $highSchoolYear
        userToken: $userToken
        page: $page
      }
    ) {
      id
      firstName
      lastName
      email
      phoneNumber
      userToken
      highSchoolYear
    }
  }
`;

export const GET_PREMIUM_STUDENT_OF_PARENT = gql`
  query GetStudentPackageAndParentToken($StudentId: Int!, $ParentId: Int!) {
    getStudentPackageAndParentToken(
      StudentId: $StudentId
      ParentId: $ParentId
    ) {
      admissionPackage {
        color
        shortName
      }
      parentIntakeFormToken
    }
  }
`;

export const SEND_EMAIL_TO_SPECIALISTS = gql`
  mutation sendEmailToSpecialistForMeeting($userToken: String) {
    sendEmailToSpecialistForMeeting(input: { userToken: $userToken }) {
      success
      message
    }
  }
`;
export const SIGN_CONTRACT = gql`
  mutation SignContract(
    $FullName: String
    $Html: String
    $initials: String
    $signedDate: Date
    $packageId: Int
    $discountCouponId: Int
    $userToken: String
  ) {
    SignContract(
      input: {
        FullName: $FullName
        Html: $Html
        initials: $initials
        signedDate: $signedDate
        packageId: $packageId
        discountCouponId: $discountCouponId
        userToken: $userToken
      }
    ) {
      id
      contractUrl
      FullName
      Initials
      DateSigned
      packageId
      discountCouponId
    }
  }
`;

export const SAVE_BANK_ACCOUNT = gql`
  mutation SaveBank($UserId: Int!) {
    saveBank(input: { UserId: $UserId }) {
      success
      clientSecret
      name
      email
    }
  }
`;

export const ALL_PAYMENT_SOURCES_QUERY = gql`
  query Q_AllPaymentSourcesQuery(
    $userId: Int
    $page: String
    $getBanks: Boolean
  ) {
    allPaymentSources(userId: $userId, page: $page, getBanks: $getBanks) {
      id
      object
      card {
        country
        brand
        last4
      }
      owner {
        name
        address {
          city
          state
          postal_code
          line1
        }
      }
      isDefault
    }
  }
`;
