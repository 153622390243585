import { Box, Typography } from '@material-ui/core';
import colors from './colors';

export default function ApplicationsTooltipContent() {
  return (
    <Box
      alignItems={'right'}
      justifyContent={'right'}
      color={colors.BLACK_FOURTEEN}
    >
      <Typography color="inherit" variant="body1" className="tooltipHeading">
        BS/MD Program, Dual-Degree Program and University of California system
        applications will count as (2) two applications worth of essay editing.{' '}
      </Typography>
    </Box>
  );
}
