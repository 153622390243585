import { IconButton, Menu, MenuItem, Paper } from '@material-ui/core';
import { FC, SyntheticEvent, useState } from 'react';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
const SpreadMenu: FC<{ handleDelete: () => void }> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { handleDelete } = props;
  const open = Boolean(anchorEl);

  const handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        aria-label="more"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Paper>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            console.log('helo there');
            setAnchorEl(null);
          }}
        >
          <MenuItem
            onClick={() => {
              handleDelete();
              setAnchorEl(null);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </Paper>
    </div>
  );
};
export default SpreadMenu;
