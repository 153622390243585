import { useState, useEffect } from 'react';
import { Box, Divider, Grid, IconButton, Typography } from '@material-ui/core';

import { useStyles } from './Styles';

import { GetPartnersFromLeadApiQuery_getPartnersFromLeadApi_partners } from './gql/__generated__/GetPartnersFromLeadApiQuery';

import Dropdown from './../../components/Dropdown';
import { QgetLeadsUsers_getLeadsUsersQuery } from './gql/__generated__/QgetLeadsUsers';

import CheckIcon from '../../img/checked-circle-green.svg';
import CloseIcon from '../../img/dialog-close-updated.svg';

const PartnerInformation = ({
  partners,
  setFieldValue,
  touched,
  errors,
  users,
  resetForm,
  setResetValues,
  leadData,
}: {
  partners:
    | (GetPartnersFromLeadApiQuery_getPartnersFromLeadApi_partners | null)[]
    | null;
  setFieldValue: Function;
  resetForm: Boolean;
  touched: any;
  errors: any;
  users: (QgetLeadsUsers_getLeadsUsersQuery | null)[] | null | undefined;
  setResetValues: Function;
  leadData: any;
}): JSX.Element => {
  const classes = useStyles();
  const [partner, setPartner] = useState('');
  const [source, setSource] = useState('');
  const [salesPersonId, setSalesPersonId] = useState('');
  const [eventName, setEventName] = useState('');
  const [close, setClose] = useState(false);

  const getSourceArray = () => {
    return [
      { label: 'Inbound Phone Call', value: 'InboundPhoneCall' },
      { label: 'Conference', value: 'Conference' },
    ];
  };
  const getEventArray = () => {
    //events
    return [
      {
        label: '2024 NSHSS DC Scholars Day',
        value: '2024 NSHSS DC Scholars Day',
      },
      {
        label: '2024 BPA National Leadership Convention',
        value: '2024 BPA National Leadership Convention',
      },
      {
        label: '2024 HOSA International Leadership Conference',
        value: '2024 HOSA International Leadership Conference',
      },
      {
        label: '2024 NSHSS Atlanta Scholars Day',
        value: '2024 NSHSS Atlanta Scholars Day',
      },
      {
        label: '2024 NSHSS UC Berkeley Event',
        value: '2024 NSHSS UC Berkeley Event',
      },
      {
        label: '2024 NSHSS University of Michigan Event',
        value: '2024 NSHSS University of Michigan Event',
      },
    ];
  };
  const UsersTobeMapped = users?.map((user) => {
    return { value: user?.id, label: user?.name };
  });
  const partnerTobeMapped = partners?.map((partner) => {
    return { value: partner?.id, label: partner?.name };
  });

  useEffect(() => {
    if (resetForm) {
      setPartner('');
      setSource('');
      setEventName('');
      setSalesPersonId('');
      setResetValues(false);
      console.log('dasda', resetForm);
    }
  }, [resetForm]);
  return (
    <Grid className={classes.hearAboutUs}>
      {!close && leadData?.primaryLead && (
        <Box
          p={2}
          pb={2.5}
          mb={6}
          display="flex"
          borderRadius={15}
          bgcolor={'#EDFBF5'}
          className="successNotification"
        >
          <Box mr={2} position="relative" top="8px">
            <img src={CheckIcon} alt="check" />
          </Box>

          <Box width="100%">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6" className="notificationHeading">
                Lead Successfully Created
              </Typography>

              <IconButton
                onClick={() => {
                  setClose(true);
                }}
              >
                <img src={CloseIcon} alt="close" />
              </IconButton>
            </Box>

            <Typography variant="body1" className="notificationSubHeading">
              Expect the lead to be available in Salesforce within ten minutes.
            </Typography>

            <Typography variant="body1" className="notificationCaption">
              <b>Student Name:</b> {leadData?.studentName || '--'}
            </Typography>

            <Typography variant="body1" className="notificationCaption">
              <b>Parent Name: </b>
              {leadData?.parentName || '--'}
            </Typography>

            <Typography variant="body1" className="notificationCaption">
              <b>Grad Year:</b> {leadData?.gradYear || '--'}
            </Typography>

            <Typography variant="body1" className="notificationCaption">
              <b>Enrollment Link:</b>{' '}
              {leadData?.magicLink ? (
                <a href={leadData?.magicLink} target="_blank">
                  {leadData?.magicLink}
                </a>
              ) : (
                <>--</>
              )}
            </Typography>
          </Box>
        </Box>
      )}

      <Grid container>
        <Grid item md={6} sm={12} xs={12}>
          <Dropdown
            label="Salesperson"
            defaultSelectClassName="placeholder"
            selectedValue={salesPersonId}
            options={UsersTobeMapped}
            isError={touched.salesPersonId && !salesPersonId}
            errorValue={
              touched.salesPersonId && !salesPersonId && errors.salesPersonId
            }
            name="salesPersonId"
            emptyDisplay={true}
            onSelect={(evt: any) => {
              const {
                target: { value },
              } = evt;
              console.log('va', value);
              setSalesPersonId(value);
              setFieldValue('salesPersonId', value.toString());
            }}
          />
        </Grid>
      </Grid>

      <Grid>
        <Typography variant="h5">How did you hear about us?</Typography>

        <Grid container spacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <Dropdown
              label="Creation Source"
              defaultSelectClassName="placeholder"
              selectedValue={source}
              options={getSourceArray()}
              isError={touched.source && !source}
              errorValue={touched.source && !source && errors.source}
              name="source"
              emptyDisplay={true}
              onSelect={(evt: any) => {
                const {
                  target: { value },
                } = evt;
                setSource(value);
                setFieldValue('source', value.toString());
                if (value.toString() !== 'Conference') {
                  setEventName('');
                  setFieldValue('eventName', '');
                }
              }}
            />
          </Grid>

          <Grid item md={6} sm={12} xs={12}>
            <Dropdown
              label="Partner"
              defaultSelectClassName="placeholder"
              selectedValue={partner}
              options={partnerTobeMapped}
              isError={touched.partnerKey && !partner}
              errorValue={touched.partnerKey && !partner && errors.partnerKey}
              name="partnerKey"
              emptyDisplay={true}
              onSelect={(evt: any) => {
                const {
                  target: { value },
                } = evt;
                setPartner(value);
                const partner = partners?.find(
                  (partner) => partner?.id === value
                );
                setFieldValue('partnerKey', partner?.token);
              }}
            />
          </Grid>
          {source === 'Conference' && (
            <Grid item md={6} sm={12} xs={12}>
              <Dropdown
                label="Event"
                defaultSelectClassName="placeholder"
                selectedValue={eventName}
                options={getEventArray()}
                isError={touched.eventName && !eventName}
                errorValue={touched.eventName && !eventName && errors.eventName}
                name="eventName"
                emptyDisplay={true}
                onSelect={(evt: any) => {
                  const {
                    target: { value },
                  } = evt;
                  setEventName(value);
                  setFieldValue('eventName', value.toString());
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Divider className={classes.dividerSection} />
    </Grid>
  );
};

export default PartnerInformation;
