import {
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import { FC, useEffect, useState } from 'react';
import { Query } from 'react-apollo';

import OtherColleges from './OtherColleges';
import PopularColleges from './PopularColleges';

import { AllCollegesQuery } from './__generated__/AllCollegesQuery';

import Loader from '../../../../components/Loader';

import Colors from '../../../../common/colors';
import fonts from '../../../../common/fonts';

import DRAWER_CLOSE_ICON from '../../../../img/drawer_close.svg';
import SEARCH_LIGHT_IMG from '../../../../img/search-light.svg';

import { useLocation } from 'react-router-dom';
import {
  UserActivityActions,
  UserActivityType,
} from '../../../../common/constants';
import { logUserActivity } from '../../../../common/utility';
import { LocationInterface } from '../../../webinar/interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    genericHeaderComponent: {
      padding: '65px 0px 53px',

      [theme.breakpoints.down('sm')]: {
        padding: '0px 0px 36px',
      },

      '& .MuiTypography-h3': {
        fontWeight: 'bold',
        fontSize: 36,
        color: '#272929',
        letterSpacing: '-0.022em',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .drawerHeader': {
        marginBottom: 53,

        [theme.breakpoints.down('sm')]: {
          marginBottom: 24,
        },
      },

      '& .drawerSearch': {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },

        '& input': {
          width: 440,
          fontWeight: 400,
          fontSize: 14,
          color: '#525454',
          height: 40,
          borderRadius: 6,
          boxSizing: 'border-box',
          letterSpacing: '-0.006em',
          padding: '11px 12px 11px 40px',
          backgroundRepeat: 'no-repeat',
          border: '1px solid #d4d4d4',
          backgroundPosition: 'center left 12px',
          backgroundImage: `url(${SEARCH_LIGHT_IMG})`,

          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },

          '&::placeholder': {
            opacity: 1,
            color: '#A9A9A9',
            fontFamily: fonts.INTER,
          },

          '& .MuiOutlinedInput-root': {
            borderRadius: 6,
          },
        },

        '& .MuiFormControl-root': {
          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },
        },
      },

      '& .drawerHeadingText': {
        fontWeight: 'bold',
        fontSize: 36,
        color: '#272929',
        letterSpacing: '-0.022em',
        fontFamily: fonts.POPPINS_FONT,

        [theme.breakpoints.down('sm')]: {
          fontSize: 24,
          fontWeight: 700,
        },
      },
    },
  })
);

const ALL_COLLEGES_QUERY = gql`
  query AllCollegesQuery(
    $limit: Int
    $page: Int
    $searchQuery: String
    $topHundred: Boolean
  ) {
    allColleges(
      input: {
        limit: $limit
        page: $page
        searchQuery: $searchQuery
        topHundred: $topHundred
      }
    ) {
      nextRecord
      colleges {
        id
        name
        logo
        applicationCreated
        address
        topHundred
        studentApplication {
          id
        }
        applicationRequirement {
          id
          lastYearAdmissionRate
          gradeOverall
          acceptRate
        }
      }
    }

    allPopularColleges {
      id
      name
      logo
      applicationCreated
      address
      topHundred
      studentApplication {
        id
      }
      applicationRequirement {
        id
        lastYearAdmissionRate
        gradeOverall
        acceptRate
      }
    }
  }
`;

interface Props {
  variation?: string;
  isCollegeAdded?: Function;
  setPopularColleges?: Function;
  isDrawerOpen?: boolean;
  onCloseDrawer?: Function;
}

let timeoutId: any = 0;
let currentPage = 1;

const AllColleges: FC<Props> = ({
  variation,
  isCollegeAdded,
  setPopularColleges,
  isDrawerOpen,
  onCloseDrawer,
}) => {
  const { pathname, search, state }: LocationInterface = useLocation();

  useEffect(() => {
    currentPage = 1;
    const query = new URLSearchParams(search);
    const origin = query.get('origin');
    const payload = {
      pageName: pathname,
      action: UserActivityActions.VIEW,
      type: UserActivityType.COLLEGE_HUB,
      origin: origin || state?.from || null,
    };
    logUserActivity(payload);
    return () => {
      currentPage = 1;
    };
  }, []);

  const [searchQuery, setSearchQuery] = useState('');
  const classes = useStyles();
  const isDrawer = variation === 'drawer';
  const isOnboard = variation === 'onboard';
  const isPage = variation === 'page';

  const [loadingMore, setLoadingMore] = useState(false);

  return (
    <>
      <Grid className={classes.genericHeaderComponent}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className="drawerHeader"
        >
          <Typography variant="h3" className="drawerHeadingText">
            {isPage ? 'Colleges' : 'Add Colleges'}
          </Typography>
          {isDrawer && (
            <IconButton
              onClick={() => {
                onCloseDrawer && onCloseDrawer();
              }}
            >
              <img src={DRAWER_CLOSE_ICON} alt="close" />
            </IconButton>
          )}
        </Box>

        <Grid className="drawerSearch">
          <TextField
            variant="outlined"
            placeholder="Search Colleges"
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              clearTimeout(timeoutId);
              timeoutId = setTimeout(() => {
                setSearchQuery(value);
              }, 500);
            }}
          />
        </Grid>
      </Grid>

      <Grid>
        <Query<AllCollegesQuery>
          query={ALL_COLLEGES_QUERY}
          variables={{ page: 1, limit: 12, searchQuery }}
          onCompleted={(data) => {
            if (!data || !data.allColleges) return true;
            const { allColleges } = data;

            if (setPopularColleges && allColleges)
              setPopularColleges(allColleges.colleges);
          }}
          fetchPolicy="network-only"
        >
          {({ data, fetchMore, refetch, loading }) => {
            if (loading) return <Loader isSmallLoader={true} />;
            if (!data || !data.allColleges) return null;
            const {
              allPopularColleges,
              allColleges: { colleges, nextRecord },
            } = data;
            const AllColleges = colleges || [];

            const loadMore = () => {
              setLoadingMore(true);
              currentPage++;

              fetchMore({
                variables: {
                  page: currentPage,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  setLoadingMore(false);
                  if (
                    !fetchMoreResult ||
                    !fetchMoreResult.allColleges ||
                    !fetchMoreResult.allColleges.colleges
                  )
                    return prev;

                  const prevColleges =
                    (prev.allColleges && prev.allColleges.colleges) || [];

                  const {
                    allColleges: { colleges, nextRecord },
                  } = fetchMoreResult;
                  return Object.assign({}, prev, {
                    allColleges: {
                      colleges: [...prevColleges, ...colleges],
                      nextRecord,
                      __typename: 'allCollegesPayload',
                    },
                  });
                },
              });
            };

            return (
              <Grid>
                {isPage && (
                  <PopularColleges
                    isSearching={!!searchQuery}
                    colleges={AllColleges}
                    refetch={refetch}
                    loadMore={loadMore}
                  />
                )}

                {isOnboard && (
                  <PopularColleges
                    isSearching={!!searchQuery}
                    colleges={searchQuery ? AllColleges : allPopularColleges}
                    refetch={() => {
                      if (setPopularColleges) setPopularColleges(AllColleges);
                    }}
                    loadMore={false}
                  />
                )}

                {isDrawer && (
                  <OtherColleges
                    isCollegeAdded={isCollegeAdded}
                    isPage={isPage}
                    colleges={AllColleges}
                    isSearching={!!searchQuery}
                    refetch={refetch}
                    isDrawerOpen={isDrawerOpen}
                  />
                )}

                {nextRecord && loadingMore && (
                  <Box
                    textAlign="center"
                    padding="2px 0px 32px"
                    fontSize={24}
                    color={Colors.BLUE_SEVEN}
                  >
                    Loading...
                  </Box>
                )}
              </Grid>
            );
          }}
        </Query>
      </Grid>
    </>
  );
};

AllColleges.defaultProps = {
  variation: 'page',
};

export default AllColleges;
