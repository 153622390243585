import { useContext, FormEvent } from 'react';
import { Mutation } from 'react-apollo';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import { Button, Box, CircularProgress, ThemeProvider } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';

import { useStyles } from '../styles';
import { AppContext } from '../../../contexts';
import { TOKEN, SHOW_PARENT_MODAL } from '../../../constants';
import { LoginFormSchema } from './lib/validationSchemas';
import { LOGIN_WITH_EMAIL_MUTATION } from './gql';
import SmallLogo from '../../../components/layout/SmallLogo';
import { handleMutationError } from '../../../common/utility';
import { TextField } from '../../../components/fields';
import {
  SignInWithEmailMutation,
  SignInWithEmailMutationVariables,
} from './gql/__generated__/SignInWithEmailMutation';
import { customTheme } from '../../MaterializeCss';

interface Props extends RouteComponentProps {
  closeModal?: Function;
  _goback?: string;
  toggleAlert: Function;
}

const LoginViewForm = ({
  closeModal,
  _goback,
  toggleAlert,
  history,
}: Props): JSX.Element => {
  const { setIsLoggedIn } = useContext(AppContext);
  const classes = useStyles();
  const loginInitialValues = {
    email: '',
    password: '',
  };

  return (
    <>
      <Mutation<SignInWithEmailMutation, SignInWithEmailMutationVariables>
        mutation={LOGIN_WITH_EMAIL_MUTATION}
      >
        {(signInWithEmailMutation: Function) => {
          return (
            <Formik
              initialValues={loginInitialValues}
              validationSchema={LoginFormSchema}
              onSubmit={async (variables, { setSubmitting }) => {
                try {
                  const result = await signInWithEmailMutation({
                    variables,
                  });
                  const {
                    data: {
                      signInWithEmail: { token },
                    },
                  } = result;
                  localStorage.setItem(TOKEN, token);
                  setIsLoggedIn(true);
                  if (_goback) {
                    history.goBack();
                  }
                  localStorage.setItem(SHOW_PARENT_MODAL, 'true');
                  closeModal && closeModal();
                } catch (error) {
                  setSubmitting(false);
                  handleMutationError(error, (errorMessage) => {
                    toggleAlert('danger', errorMessage.message);
                  });
                }
              }}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form>
                  <Box maxWidth={390} margin="0 auto" width="100%">
                    <Box display="flex" alignItems="center" marginBottom={7.5}>
                      <Box maxWidth={48} marginRight={2}>
                        <SmallLogo />
                      </Box>

                      <Box component="h4">Login to your account!</Box>
                    </Box>

                    <Box paddingBottom={5} className={classes.checkListHeading}>
                      Looks like you already have an account with us. Please
                      login below.
                    </Box>
                  </Box>

                  <Box maxWidth={370} margin="0px auto">
                    <Field
                      type="email"
                      name="email"
                      component={TextField}
                      label="Email"
                      fullWidth
                      variant="filled"
                      className={classes.textfield}
                      onChange={(e: FormEvent<HTMLInputElement>) => {
                        const {
                          currentTarget: { value },
                        } = e;
                        setFieldValue('email', value);
                      }}
                    />

                    <Field
                      type="password"
                      name="password"
                      component={TextField}
                      label="Password"
                      fullWidth
                      variant="filled"
                      className={classes.textfield}
                      onChange={(e: FormEvent<HTMLInputElement>) => {
                        const {
                          currentTarget: { value },
                        } = e;
                        setFieldValue('password', value);
                      }}
                    />
                    <ThemeProvider theme={customTheme}>
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting}
                        color='secondary'
                        fullWidth
                      >
                        Login
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </Button>
                    </ThemeProvider>
                  </Box>
                </Form>
              )}
            </Formik>
          );
        }}
      </Mutation>
      <Box textAlign="center">
        <Link to="/forgot-password" className={classes.forgotPass}>
          Forgot password?
        </Link>
      </Box>
    </>
  );
};

export default withRouter(LoginViewForm);
