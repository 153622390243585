import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import { Fragment, useContext } from 'react';
import { AppContext } from '../../contexts';
import GRADUATE_CAP_ICON from '../../img/graduated-icon-cap.svg';
import INFO__GREEN_ICON from '../../img/info-green.svg';
import { homePageStyles } from './homePagestyles';
import WebinarsList from './webinars/WebinarsList';
import FooterNew from '../../components/layout/FooterNew';

const MBAFreeHomePage = () => {
  const { user } = useContext(AppContext);
  const classes = homePageStyles();

  return (
    <Fragment>
      <Container maxWidth={false}>
        <Grid className={classes.upperClassHeader}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            className="graduateResponsive"
          >
            <Box
              display="flex"
              alignItems="center"
              className="graduateResponsivePadding"
            >
              {user && (
                <Typography variant="h3">{` Hi, ${user?.firstName}!`}</Typography>
              )}

              {user?.graduated && (
                <Box className={classes.graduatedBox} ml="24px">
                  <img src={GRADUATE_CAP_ICON} alt="cap" />
                  <Typography variant="body1">Graduated</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Card className={clsx(classes.activityPendingCard, classes.mbaProgram)}>
          <Grid container>
            <Box mr={2}>
              <img src={INFO__GREEN_ICON} alt="caution" className="img" />
            </Box>

            <Grid>
              <Typography variant="h5">
                Continue Your Pursuit of Your Top MBA Program!
              </Typography>
              <Typography variant="body1">
                Click the link below to learn more on how CollegeAdvisor can
                deliver personalized guidance for your MBA journey ahead.
              </Typography>
              <Box>
                <a
                  href={`https://go.collegeadvisor.com/mba-admissions?utm_source=product`}
                  target="_blank"
                >
                  <Button
                    color="secondary"
                    variant="contained"
                    className="buttonContained"
                  >
                    Learn More
                  </Button>
                </a>
                <span className="callus">Or call us at</span>{' '}
                <a href="tel:+1 (844) 343-6272" className="telephone">
                  +1 (844) 343-6272
                </a>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Container>
      <WebinarsList />
      <FooterNew />
    </Fragment>
  );
};

export default MBAFreeHomePage;
