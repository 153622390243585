import { FC, useRef } from 'react';

import { FieldProps, getIn } from 'formik';
import { Omit } from './utils';

import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core/TextField';

export interface TextFieldProps
  extends FieldProps,
    Omit<MuiTextFieldProps, 'error' | 'name' | 'onChange' | 'value'> {}

export const TextField: FC<TextFieldProps> = ({
  field,
  form,
  variant,
  ...props
}) => {
  const { name } = field;
  const { touched, errors } = form;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;
  const ref = useRef();

  return (
    <MuiTextField
      {...field}
      {...props}
      inputRef={ref}
      variant={variant as any}
      error={showError}
      helperText={showError ? fieldError : props.helperText}
    />
  );
};
