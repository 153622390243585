import styled from 'styled-components';
import Colors from '../common/colors';

export const GlobalStyle = styled.div`
  @media only screen and (max-width: 767px) {
    h2 {
      width: 100% !important;
      font-size: 26px !important;
      font-weight: 600 !important;
      line-height: 30px !important;
      letter-spacing: 0px !important;
      margin-bottom: 22px;
    }

    .makeStyles-paper-2 {
      padding: 15px;
      flex-direction: column;

      h2 {
        font-size: 30px !important;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    h2 {
      font-size: 26px !important;
      letter-spacing: 0px !important;
      line-height: 30px !important;
    }
  }

  .flex-direction-column {
    @media only screen and (max-width: 991px) {
      flex-direction: column;
    }
  }

  .flex-direction-column-xs {
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .mentors_col {
      padding: 0px;
    }
  }

  .mb-xs-2 {
    @media only screen and (max-width: 991px) {
      margin-bottom: 16px;
    }
  }

  button:focus,
  input:focus {
    outline: 0px !important;
  }

  a:hover {
    text-decoration: none;
  }

  @media only screen and (min-width: 768px) {
    .container {
      padding: 0px;
    }

    .hidden-checklist {
      display: none;
    }
  }

  .content-box-height-empty {
    min-height: calc(100vh - 139px);
  }

  .content-box-height {
    min-height: calc(100vh - 130px);
  }

  .content-box-height-forgot {
    min-height: calc(100vh - 132px);
  }

  @media only screen and (min-height: 1000px) {
    .content-box-height {
      min-height: calc(99vh - 142px);
    }
  }

  .card-header-bg-harvard {
    background: ${Colors.RED_THREE_APP};
  }

  .card-header-bg-ny {
    background: ${Colors.BLUE_NINE_APP} 0%;
  }

  h2 {
    font-weight: 600;
    font-size: 56px;
    letter-spacing: -3px;
    color: ${Colors.BLACK_THREE};
    line-height: 82px;
  }

  .onboardSearch {
    padding: 0px;
  }

  .onboardBorder {
    border-bottom: 0px;
  }

  .checkcollegesPadding {
    padding: 0px;
  }

  .badge:empty {
    display: block;
  }

  .application-card {
    .MuiCard-root {
      height: 310px;
    }
  }

  .disable-college-card {
    background: ${Colors.GRAY_NINE};
    cursor: not-allowed;
    opacity: 0.5;
  }

  .your-applications-box-height {
    min-height: calc(100vh - 538px);
  }

  .welcome-heading {
    padding: 0px 20px;
  }

  .select_mentor_error {
    background: ${Colors.RED_ONE};
    border-radius: 4px;
    padding: 15px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.3375px;
    color: ${Colors.WHITE_ONE};
    margin: 10px 0px;
  }

  .MuiRadio-colorPrimary svg path {
    stroke: ${Colors.GRAY_NINE};
    stroke-width: 1px;
  }

  .MuiRadio-colorPrimary.Mui-checked svg path {
    stroke: Colors.BLUE_SEVEN;
    stroke-width: 1px;
  }

  .MuiRadio-colorPrimary.Mui-checked {
    color: ${Colors.BLUE_SEVEN};
  }
`;

export const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: 14,
    }),

    placeholder: (provided, state) => ({
      ...provided,
      color: '#A9A9A9',
    }),
  };