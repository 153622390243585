import gql from 'graphql-tag';
import React, { SetStateAction } from 'react';
import {
  updateDecisionProp,
} from '../../../../common/interfaces';

export const ALL_ACCEPTED_APPLICATIONS_QUERY = gql`
  query AllAcceptedApplicationsQuery($page: Int, $limit: Int) {
    allAcceptedApplications(page: $page, limit: $limit) {
      StudentApps {
        id
        decisionStatus
        decisionDeadline
        applicationStatus
        ApplicationRequirementId
        isFinal
        decision
        ApplicationCampusesSurvey {
          id
          name
          logo
          decision
          isFinal
        }
        studentApplicationCampuses {
          id
          logo
          name
          styleConfig {
            bgDark
          }
        }
        applicationRequirement {
          CollegeId
          college {
            logo
            name
            styleConfig {
              bgDark
            }
          }
        }
      }
      success
    }
  }
`;

export const FINAL_DECISION_MUTATION = gql`
  mutation toggleFinalDecisionMutation($applicationId: Int, $collegeId: Int) {
    toggleFinalDecision(applicationId: $applicationId, collegeId: $collegeId) {
      message
      success
    }
  }
`;

export const ON_DELETE_UPDATE_FINAL_DECISION_MUTATION = gql`
  mutation removeOnDeleteFinalDecisionMutation(
    $applicationId: Int
    $collegeId: Int
  ) {
    removeOnDeleteFinalDecision(
      applicationId: $applicationId
      collegeId: $collegeId
    ) {
      message
      success
    }
  }
`;

export interface AcceptancePropTypes {
  successStatus: boolean;
  setSuccessStatus: React.Dispatch<SetStateAction<boolean>>;
  shouldUpdate: boolean;
  setUpdateDecision: React.Dispatch<SetStateAction<updateDecisionProp | null>>;
  updateDecision: updateDecisionProp | null;
}

export const ALL_STUDENT_APPLICATIONS_QUERY = gql`
  query allStudentApplicationsQuery($limit: Int, $page: Int) {
    allStudentApplications(page: $page, limit: $limit) {
      StudentApplications {
        id
        decisionStatus
        decisionDeadline
        completionPercentage
        applicationStatus
        decision
        otherApplicationDeadlines {
          id
          DeadlineName
          DeadlineDate
        }
        ApplicationCampusesSurvey {
          id
          name
          logo
          decision
        }
        studentApplicationCampuses {
          id
          name
          logo
          applicationRequirement {
            id
            acceptRate
          }
        }

        applicationRequirement {
          id
          offersEarlyAction
          offersEarlyDecision
          earlyActionDeadline
          earlyDecisionDeadline
          regularDecisionDeadline
          lastYearAdmissionRate
          acceptRate
          college {
            parentMetaData
            id
            name
            logo
            styleConfig {
              bgDark
              bgLight
            }
            applicationRequirement {
              id
              earlyActionDeadline
              earlyDecisionDeadline
              regularDecisionDeadline
            }
          }
        }
      }
      commonCoalitionApplicationEssays {
        id
        name
        documentUrl
        essayStatus
        essayTemplate {
          id
          required
          commonEssay
          longDescription
          shortDescription
        }
      }
      commonAppId
      coalitionAppId
      acceptsCommonAppEssay {
        id
        name
        logo
        parentMetaData
      }
      acceptCoalitionEssay {
        id
        name
        logo
        parentMetaData
      }
      studentApplicationTotalCount
    }
  }
`;

export const STUDENT_APPLICATIONS_QUERY_WITH_ESSAY_PROGRESS = gql`
  query allStudentApplicationsQueryWithEssayProgress {
    getStudentApplicationWithEssaysProgress {
      stuck {
        applicationId
        name
        school {
          id
          name
          logo
          parentMetaData
        }
        dueDate
        description
        maxWord
      }
      reviewReady {
        applicationId
        name
        school {
          id
          name
          logo
          parentMetaData
        }
        dueDate
        description
        maxWord
      }
      inProgress {
        applicationId
        name
        school {
          id
          name
          logo
          parentMetaData
        }
        dueDate
        description
        maxWord
      }
      NotStarted {
        applicationId
        name
        school {
          id
          name
          logo
          parentMetaData
        }
        dueDate
        description
        maxWord
      }
      completed {
        applicationId
        name
        school {
          id
          name
          logo
          parentMetaData
        }
        dueDate
        description
        maxWord
      }
    }
  }
`;

