import { Box } from '@material-ui/core';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AppContext } from '../contexts';

import { ClientStatus } from '../common/constants';

import Images from '../img';

import CAUTION_ICON from '../img/caution-icon-url.svg';
import FAILURE_ICON from '../img/failure-payment.svg';
import MyProfileIcon from '../img/my-profile-icon.svg';
import ParentAccountIcon from '../img/parent-account.svg';
import PlanBillingIcon from '../img/plan-billing.svg';
import UserProfileIcon from '../img/user-profile.svg';
import { accountMenuStyles } from './styles';

const PROFILE_NAV: {
  title: string;
  linkTo: string;
  icon: string;
  optionalIcon?: string | null;
}[] = [
  {
    title: 'Account Information',
    linkTo: '/account/profile',
    icon: UserProfileIcon,
  },
  {
    title: 'My Profile',
    linkTo: '/account/my-profile',
    icon: MyProfileIcon,
  },
];

const AccountMenu: React.FC = () => {
  const { accountNav, navLink, navLinkIcon } = accountMenuStyles();
  const { user } = useContext(AppContext);
  const isTrial =
    user?.studentTrial?.trialStartedAt && !user?.studentTrial?.trialCompletedAt
      ? true
      : false;

  if (!user) return null;
  if (!(user?.isPremium && !user?.userSubscription)) {
    const find = PROFILE_NAV.find((nav) => {
      if (nav.title === 'Plan & Billing') {
        return nav;
      }
    });
    if (!find && !isTrial) {
      PROFILE_NAV.push({
        title: 'Plan & Billing',
        linkTo: '/account/billing',
        icon: PlanBillingIcon,
        optionalIcon:
          (user.planCancelled &&
            (user.clientStatus === ClientStatus.BAD_DEBT
              ? FAILURE_ICON
              : CAUTION_ICON)) ||
          null,
      });
    }
  }

  const { isParent } = user;

  return (
    <Box borderRadius={4} padding="20px 0" className={accountNav}>
      {PROFILE_NAV.map((item, index) => (
        <NavLink to={item.linkTo} key={item.title} className={navLink}>
          <img
            src={item.icon}
            alt={String(index)}
            className={navLinkIcon}
          ></img>
          {item.title}
          {item.optionalIcon && (
            <Box paddingLeft="20px">
              <img src={item.optionalIcon} width="15px" alt="caution" />
            </Box>
          )}
          {item.title.toLowerCase() === 'plan & billing' &&
            user &&
            user?.lastPaymentStatus === 'failure' && (
              <img src={Images.EXC_VECTOR} className="ml-2" alt="" />
            )}
        </NavLink>
      ))}

      {!user.isMBAGrad && (
        <NavLink
          to={
            !isParent ? '/account/parent-accounts' : '/account/student-accounts'
          }
          className={navLink}
        >
          <img
            src={ParentAccountIcon}
            alt="parent"
            className={navLinkIcon}
          ></img>
          {!isParent ? 'Parent/Guardian Accounts' : 'Student Accounts'}
        </NavLink>
      )}
    </Box>
  );
};

export default AccountMenu;
