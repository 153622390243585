import { Box, Button, Card, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import fonts from '../../../common/fonts';
import SnackbarComponent from '../../../common/SnackBar';
import { AppContext } from '../../../contexts';
import NO_IMAGE from '../../../img/empty-badge.svg';

import moment from 'moment-timezone';

import client from '../../../apollo';
import { ClientStatus } from '../../../common/constants';
import { DELETE_BOOKING } from '../home/BookingMeeting/gql';
import { allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings } from './BookingMeeting/gql/__generated__/allAdvisorBookingsQuery';

import CancelBookAppointment from '../../../pages/main/home/BookingMeeting/CancelBookAppointment';
import CancelMeetingModel from './CancelMeetingModel';

import colors from '../../../common/colors';

import CALENDAR_ICON from '../../../img/meeting-date-icon.svg';
import { GET_ALL_STUDENT_MENTORS } from '../../../graphql/queries/studentMentors';
import { getAllStudentMentors_allStudentMentors } from '../../../graphql/queries/studentMentors/__generated__/getAllStudentMentors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    applicationsCard: {
      marginBottom: 32,
      borderRadius: 25,

      [theme.breakpoints.down('sm')]: {
        marginBottom: 17,
        borderRadius: 15,
      },
    },

    applicationsHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '24px 30px',
      borderBottom: `1px solid ${colors.WHITE_SIX}`,

      [theme.breakpoints.down('sm')]: {
        padding: '15px 20px',
      },
    },

    mentorsListContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '19px 29px',
      borderBottom: '1px solid #ECEBEA',

      '& .deleteButton': {
        visibility: 'hidden',
      },

      '& .menuButton': {
        minWidth: 35,
        height: 35,
        borderRadius: '50%',
        padding: 0,

        '& img': {
          margin: 0,
        },
      },

      '&:hover': {
        backgroundColor: '#f4f4f4',

        '& .deleteButton': {
          visibility: 'visible',
        },
      },
    },

    avatarPlusUser: {
      width: 24,
      height: 24,
      borderRadius: '50%',
      backgroundColor: '#F4F4F4',
      fontWeight: 600,
      fontSize: 12,
      color: '#525454',
      position: 'absolute',
      right: 9,
      bottom: -5,
    },

    mentorsList: {
      display: 'flex',
      alignItems: 'center',

      '& a': {
        display: 'flex',
        alignItems: 'center',

        '&:not(:last-child)': {
          marginBottom: 10,
        },
      },
    },

    advisorAvatar: {
      height: 48,
      width: 48,
      borderRadius: '50%',
      overflow: 'hidden',
      marginRight: 21,
      backgroundColor: '#e1e1e1',
      position: 'relative',

      '& img': {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
      },

      '@media only screen and (max-width: 480px)': {
        marginRight: 10,
      },
    },

    avatarContentGrid: {
      width: 220,

      '& .subtitleText': {
        fontFamily: fonts.INTER,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#7D7F7F',
      },

      '& .anchorText': {
        fontSize: 16,
        fontWeight: 400,
        width: 90,
        display: 'block',
        letterSpacing: '-0.011em',
        color: '#2F80ED',
        borderBottom: '1px solid #2F80ED',
        lineHeight: '18px',
      },
    },

    cardFooter: {
      padding: '21px 15px',
      textAlign: 'center',

      '& a': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: '#2F80ED',
      },
    },

    noMeetings: {
      padding: '33px 31px 34px',
      borderBottom: '1px solid #ECEBEA',

      '& .noMeetingText': {
        fontSize: 18,
        fontWeight: 600,
        color: '#525454',
        fontFamily: fonts.INTER,
        textTransform: 'initial',
        letterSpacing: '-0.014em',
      },
    },
  })
);

const MeetingsCard = ({
  advisorBookings,
  refetchMeeting,
  isMeetingBooked,
  setIsMeetingBooked,
  setCalendarAdvisorId,
  setOpenCalendar,
  setDefaultAdvisorIds,
}: {
  refetchMeeting: Function;
  advisorBookings:
    | (allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings | null)[]
    | null
    | undefined;
  isMeetingBooked: boolean;
  setIsMeetingBooked: Function;
  setCalendarAdvisorId: Function;
  setOpenCalendar: Function;
  setDefaultAdvisorIds?: Function;
}) => {
  const { user } = useContext(AppContext);
  const classes = useStyles();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [meetingId, setMeetingId] = useState<number>();
  const [advisorId, setAdvisorId] = useState<number>();
  const [bookingInfo, setBookingInfo] = useState<object>({});
  const [reason, setReason] = useState<string>('');
  const [otherReason, setOtherReason] = useState<string>('');
  const [allStudentMentors, setAllStudentMentors] = useState<
    (getAllStudentMentors_allStudentMentors | null)[] | null
  >(null);

  useEffect(() => {
    const fetchAllStudentMentors = async () => {
      const { data } = await client.query({
        query: GET_ALL_STUDENT_MENTORS,
        fetchPolicy: 'network-only',
      });
      if (data && data.allStudentMentors) {
        setAllStudentMentors(data.allStudentMentors);
      }
    };
    fetchAllStudentMentors();
  }, []);

  const handleCancelMeeting = async (reason: string, other: string) => {
    try {
      setLoading(true);
      if (meetingId && advisorId) {
        await client.mutate({
          mutation: DELETE_BOOKING,
          variables: {
            eventId: meetingId,
            AdvisorId: advisorId,
            reason: reason,
            ...(other && {
              otherReason: other,
            }),
          },
        });
        setOpenDeleteConfirmation(false);
        await refetchMeeting();
        setOpenSnackbar(true);
        setSnackbarMessage('Meeting Canceled');
        setOtherReason('');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleNotificationClose = () => {
    setOpenSnackbar(false);
    setSnackbarMessage('');
  };

  const handleBookMeeting = () => {
    if (setDefaultAdvisorIds?.length) {
      const defaultAdvisorsToShow =
        allStudentMentors
          ?.filter((adv) => !adv?.isExtraMatch)
          ?.map((advisor) => advisor?.mentor?.id) ||
        allStudentMentors?.map((advisor) => advisor?.mentor?.id);
      setDefaultAdvisorIds(defaultAdvisorsToShow);
      setOpenCalendar(true);
    } else {
      const advisor =
        allStudentMentors?.find(
          (adv) => adv?.mentor?.advisorLevel !== 'Former Admissions Officer'
        )?.mentor || allStudentMentors?.[0]?.mentor;

      if (advisor) {
        setCalendarAdvisorId(advisor.id);
        setOpenCalendar(true);
      }
    }
  };

  const handleRefetchMeetings = () => {
    const refetchMeetings = async () => {
      if (isMeetingBooked) {
        await refetchMeeting();
        setIsMeetingBooked(false);
      }
    };
    refetchMeetings();
  };

  const disableVideoChat =
    (user?.initialKickOffDate
      ? false
      : user?.firstMeetingBookedDate &&
        user.firstMeetingBookedDate?.endTime > Date.now() / 1000) ||
    (user?.premiumVideoCountUsed || 0) >= (user?.premiumTotalVideoCount || 0) ||
    false;

  useEffect(handleRefetchMeetings, [isMeetingBooked]);

  const isPremiumUser =
    user?.isPremium &&
    user?.clientStatus !== ClientStatus.BAD_DEBT &&
    user?.clientStatus !== ClientStatus.CANCELED;

  return (
    <>
      <SnackbarComponent
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={3000}
        handleClose={handleNotificationClose}
        message={snackbarMessage}
        severity="success"
      />
      <CancelBookAppointment
        open={openDeleteConfirmation}
        onClose={() => {
          setOpenDeleteConfirmation(!openDeleteConfirmation);
          setReason('');
          setOtherReason('');
        }}
        title="Are You Sure You Want to Cancel Your Meeting?"
        firstButtonText="Cancel Meeting"
        secondButtonText="Keep"
        handleCancelBtn={handleCancelMeeting}
        loading={loading}
        bookingInfo={bookingInfo}
        reason={reason}
        setReason={setReason}
        setOtherReason={setOtherReason}
        otherReason={otherReason}
      />
      <Card className={classes.applicationsCard}>
        <Box className={classes.applicationsHeader}>
          <Typography variant="h4">Upcoming Meetings</Typography>
        </Box>

        {advisorBookings?.length ? (
          <Box>
            {advisorBookings?.slice(0, 5)?.map((item, index) => {
              const advisor = item?.allParticipants?.find(
                (u) => u?.id === item?.AdvisorId
              );

              const otherAdvisors = item?.allParticipants?.filter(
                (p) => p?.roles?.includes('Mentor') && p.id !== advisor?.id
              );

              const name = `${advisor?.firstName || ''} ${
                advisor?.lastName || ''
              }${
                otherAdvisors?.length
                  ? `, ${otherAdvisors
                      .map((a) => `${a?.firstName} ${a?.lastName}`)
                      .join(', ')}`
                  : ``
              }`;

              return (
                <Box
                  key={`Upcoming Meetings-${index}`}
                  className={classes.mentorsListContainer}
                >
                  <Box className={classes.mentorsList}>
                    <Box position="relative">
                      <Box className={classes.advisorAvatar}>
                        {advisor?.pictureUrl ? (
                          <img src={advisor?.pictureUrl} />
                        ) : (
                          <img src={NO_IMAGE} alt="dummy user" />
                        )}
                      </Box>
                      {otherAdvisors?.length ? (
                        <Box
                          className={classes.avatarPlusUser}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          +1
                        </Box>
                      ) : null}
                    </Box>
                    <Box className={classes.avatarContentGrid}>
                      <Typography variant="h5">{name}</Typography>
                      <Typography variant="caption" className="subtitleText">
                        {moment((item?.startTime || 0) * 1000).format(
                          'ddd MMM DD, LT'
                        )}{' '}
                        {moment((item?.startTime || 0) * 1000)
                          .tz(moment.tz.guess())
                          .format('z')}
                      </Typography>
                      <Box className={classes.avatarContentGrid}>
                        {item?.zoomLink && (
                          <a
                            href={`${item?.zoomLink}`}
                            target="_blank"
                            rel="noreferrer"
                            className="anchorText"
                          >
                            Meeting Link
                          </a>
                        )}
                      </Box>
                    </Box>
                  </Box>

                  <Box>
                    <CancelMeetingModel
                      item={item}
                      setMeetingId={setMeetingId}
                      setAdvisorId={setAdvisorId}
                      openDeleteConfirmation={openDeleteConfirmation}
                      setOpenDeleteConfirmation={setOpenDeleteConfirmation}
                      setBookingInfo={setBookingInfo}
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>
        ) : (
          <Box className={classes.noMeetings}>
            <Typography variant="body1" className="noMeetingText">
              No upcoming meetings!
            </Typography>

            <Box marginTop={2}>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !isPremiumUser || user?.graduated || disableVideoChat || false
                }
                onClick={isPremiumUser ? handleBookMeeting : () => {}}
              >
                <img src={CALENDAR_ICON} alt="date" />
                Book Meeting
              </Button>
            </Box>
          </Box>
        )}

        <Box className={classes.cardFooter}>
          <Link to="/meetings">View All Meetings</Link>
        </Box>
      </Card>
    </>
  );
};
export default MeetingsCard;
