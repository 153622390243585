import { Tooltip, TooltipProps } from '@material-ui/core';
import { useStylesBootstrap } from './styles';

interface ITooltipProps extends TooltipProps {
  customWidth?: string;
}

export function BootstrapTooltip(props: ITooltipProps) {
  const classes = useStylesBootstrap(props.customWidth)();

  return <Tooltip arrow classes={classes} {...props} />;
}
