import React, { FC, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box } from '@material-ui/core';

import CollegeCard from './CollegeCard';
import Colors from '../../../../common/colors';
import Fonts from '../../../../common/fonts';

import { AllCollegesQuery_allColleges_colleges } from './__generated__/AllCollegesQuery';
import { isValueBetween } from '../../../../common/utility';

interface Props {
  colleges: (AllCollegesQuery_allColleges_colleges | null)[] | null;
  title?: string;
  isSearching?: boolean;
  refetch?: Function;
  loadMore?: Function | false;
}

const useStyles = makeStyles(() =>
  createStyles({
    searchHeading: {
      fontSize: '30px',
      lineHeight: '43px',
      letterSpacing: '-2px',
      color: Colors.BLUE_SEVEN,
      marginBottom: '24px',
      marginTop: '24px',
      fontFamily: Fonts.POPPINS_FONT,
    },
  })
);

let timeoutId: any = 0;
const PopularColleges: FC<Props> = ({ colleges, title, refetch, loadMore }) => {
  const { searchHeading } = useStyles();

  const scrollHandler = () => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  };

  useEffect(scrollHandler, []);

  const handleScroll = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      const {
        documentElement: { offsetHeight },
        scrollingElement,
      } = document;
      const scrollHeight = scrollingElement ? scrollingElement.scrollTop : 0;
      if (
        isValueBetween(
          scrollHeight + window.innerHeight,
          offsetHeight - window.innerHeight,
          offsetHeight + 50
        ) &&
        loadMore
      ) {
        loadMore();
      }
    }, 200);
  };

  if (!colleges || !colleges.length)
    return <p className="text-center p-2">No Colleges Found</p>;

  return (
    <Box>
      {title && (
        <Typography variant="h3" component="h3" className={searchHeading}>
          {title}
        </Typography>
      )}

      <Grid container spacing={1}>
        {colleges.map((college, i) => {
          if (!college) return null;
          return <CollegeCard key={i} college={college} refetch={refetch} />;
        })}
      </Grid>
    </Box>
  );
};

export default PopularColleges;
