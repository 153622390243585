import { FC } from 'react';
import { Box, Button, ThemeProvider } from '@material-ui/core';
import { Link } from 'react-router-dom';

import LOGO from '../../img/logo-header.png';
import ERROR from '../../img/sad-face.svg';

import { useStyles } from './style';
import { customTheme } from '../MaterializeCss';
interface ErrorPageProps {
  hideLogo?: boolean;
}
const Error404: FC<ErrorPageProps> = ({ hideLogo }) => {
  const classes = useStyles();
  return (
    <>
      {!hideLogo && (
        <Box maxWidth={250} position="absolute" left={30} top={20}>
          <img src={LOGO} alt="main logo" />
        </Box>
      )}

      <Box className={classes.mainContainer}>
        <img src={ERROR} alt="sad face" />

        <h2>404 - Page not found!</h2>

        <p>The page that you are looking for does not exists.</p>

        <ThemeProvider theme={customTheme}>
          <Link to="/home">
            <Button variant="contained" color="primary">
              Back to home
            </Button>
          </Link>
        </ThemeProvider>
      </Box>
    </>
  );
};

export default Error404;
