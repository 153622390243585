import { makeStyles, Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';

import Fonts from '../../common/fonts';
import Colors from '../../common/colors';

import ERROR_IMG from '../../img/error-ic.svg';
import Arrow_Img from '../../img/custom-arrow.svg';
import SEARCH_DARK_IMG from '../../img/search-icon.svg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dividerParentInformation: {},

    associatedParentInformation: {
      marginBottom: '16px !important',
    },

    parentInformationSection: {
      '& .MuiTypography-h4': {
        fontSize: 18,
        fontWeight: 600,
        marginBottom: 16,
        fontFamily: Fonts.INTER,
        letterSpacing: '-0.252px',
        color: '#272929 !important',
        paddingBottom: '0px !important',
      },

      '& .MuiTypography-body2': {
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 4,
        color: '#272929',
        fontFamily: Fonts.INTER,
        letterSpacing: '-0.084px',
      },

      '& .MuiTypography-body1': {
        fontSize: 12,
        marginTop: 4,
        fontWeight: 400,
        color: '#A9A9A9',
        marginBottom: 4,
        fontFamily: Fonts.INTER,
      },

      '& .associatedParentInfoText': {
        marginBottom: 37,
      },

      '& .react-select__indicator, & .react-select__indicator-separator': {
        display: 'none',
      },

      '& .react-select__control': {
        borderRadius: 6,
        border: '1px solid #D4D4D4',
        fontFamily: Fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        padding: '5px 15px 5px 35px',
        backgroundPosition: 'center left 12px',
        backgroundImage: `url(${SEARCH_DARK_IMG})`,
        backgroundRepeat: 'no-repeat !important',
        height: 40,
      },

      '& .react-select__placeholder': {
        fontSize: 14,
        fontWeight: 400,
        color: '#A9A9A9',
        fontFamily: Fonts.INTER,
        transform: 'translateY(-50%)',
      },

      '& .react-select__value-container': {
        fontFamily: Fonts.INTER,

        '& div:nth-child(2)': {
          margin: '0px !important',
          padding: '0px !important',
        },
      },
    },

    associatedCheckbox: {
      '& .MuiTypography-body1': {
        fontSize: 14,
        fontWeight: 400,
        color: '#7D7F7F',
        fontFamily: Fonts.INTER,
        letterSpacing: '-0.084px',
      },

      '& .MuiSvgIcon-root path': {
        fill: '#7D7F7F !important',
      },

      '& .MuiFormControlLabel-root': {
        margin: '0px 0px 0px -11px',
      },
    },

    buttonProgress: {
      color: '#ffffff',
      width: '20px !important',
      height: '20px !important',
      marginLeft: 12,
    },

    textfield: {
      marginBottom: 28,
      fontFamily: Fonts.INTER,

      '& .PhoneInputInput': {
        color: Colors.BLACK_TEN,
        border: `1px solid ${Colors.BLACK_TEN}`,
        height: 33,
        padding: '0 10px',
        fontSize: 14,
        background: Colors.WHITE_ONE,
        borderRadius: '5px !important',
      },

      '& .MuiInputBase-root': {
        fontFamily: Fonts.INTER,
      },

      '& .MuiFormHelperText-root.Mui-error': {
        fontFamily: Fonts.INTER,
      },

      '& .MuiInputLabel-shrink': {
        fontFamily: Fonts.INTER,
        color: Colors.BLUE_SEVEN,
      },

      '& .MuiInputLabel-animated': {
        fontFamily: Fonts.INTER,
      },

      '& label.Mui-focused': {
        color: Colors.BLUE_SEVEN,
      },

      '& .MuiInput-underline:after': {
        borderBottomColor: Colors.BLUE_SEVEN,
      },

      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: Colors.BLUE_SEVEN,
        },
        '&:hover fieldset': {
          borderColor: Colors.BLUE_SEVEN,
        },
        '&.Mui-focused fieldset': {
          borderColor: Colors.BLUE_SEVEN,
        },
      },
    },

    cardModal: {
      background:
        'linear-gradient(162.13deg, #4381E5 0.68%, #0D2765 99.21%), #0A2463',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '10px 18px 29px',
      maxWidth: 500,
      height: '80vh',
      overflowY: 'auto',

      '&.custom-card': {
        borderRadius: 0,
        background: 'none',
        boxShadow: 'none',
        padding: 0,
        maxWidth: 'none',
        height: 'auto',
      },

      '@media only screen and (max-width: 767px)': {
        padding: '29px 15px',
      },

      '& .MuiTypography-h4': {
        fontWeight: 600,
        fontSize: 24,
        color: Colors.WHITE_ONE,
        fontFamily: Fonts.INTER,
        paddingBottom: 26,

        '@media only screen and (max-width: 767px)': {
          fontSize: 18,
        },
      },

      '& .MuiTypography-h5': {
        fontWeight: 600,
        fontSize: 16,
        color: Colors.WHITE_ONE,
        marginBottom: 12,
      },

      '& .formHeading': {
        fontSize: 20,
        fontWeight: 600,
        color: Colors.WHITE_ONE,
        fontFamily: Fonts.INTER,
        marginBottom: 20,
      },

      '& .MuiInputBase-input': {
        background: Colors.WHITE_ONE,
        borderRadius: 5,
      },

      '& .MuiSvgIcon-root': {
        '& path': {
          fill: Colors.WHITE_ONE,
        },
      },

      '& .MuiFilledInput-input': {
        padding: '10px 12px 10px',
      },

      '& .MuiInputLabel-filled': {
        color: Colors.BLACK_TEN,
        fontSize: 16,
        transform: 'translate(12px, 11px) scale(1)',
      },

      '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
        transform: 'translate(0px, -15px) scale(0.75)',
        color: `${Colors.WHITE_ONE}`,
      },

      '& .MuiFormHelperText-root.Mui-error': {
        margin: '5px 0px 0px',
        color: Colors.WHITE_ONE,
        background: `url(${ERROR_IMG}) no-repeat center left`,
        paddingLeft: 18,
        lineHeight: '12px',
        fontFamily: Fonts.INTER,

        '&:after': {
          borderBottomColor: Colors.RED_ONE,
        },
      },

      '& .Mui-error + div > input': {
        color: Colors.RED_ONE,
      },

      '& .react-phone-number-input__input': {
        height: 39,
        padding: '0px 15px',
        borderRadius: 3,
      },

      '& .MuiFormControl-fullWidth': {
        marginBottom: 28,
      },

      '& .MuiSelect-select.MuiSelect-select': {
        lineHeight: '38px',
        height: 39,
        padding: '0px 15px',
        borderRadius: 3,
        color: Colors.BLACK_TEN,
        fontSize: 16,
      },

      '& .MuiFormControl-root': {
        '& .MuiInputBase-root.MuiInput-root': {
          '& .MuiSelect-icon': {
            display: 'block',
          },

          '& .MuiSvgIcon-root path': {
            fill: Colors.GRAY_TWELVE,
          },
        },
      },

      '& .MuiInputLabel-shrink': {
        color: Colors.WHITE_ONE,
      },

      '& .MuiButtonBase-root.MuiButton-root': {
        background: Colors.GREEN_SEVEN,
        borderRadius: 12,
        fontWeight: 600,
        fontSize: 16,
        textTransform: 'uppercase',
        color: Colors.WHITE_ONE,
        padding: '12px 15px',
        marginTop: 33,
        fontFamily: Fonts.INTER,

        '&.blue-button': {
          background: Colors.BLUE_SEVEN,
          fontFamily: Fonts.INTER,
        },

        '&.green-button': {
          background: '#45CE93',
          fontFamily: Fonts.INTER,
        },
      },
    },

    textfieldMobile: {
      '@media only screen and (max-width: 767px)': {
        marginBottom: '0px !important',
      },
    },

    textfieldGraduation: {
      paddingBottom: '0px !important',

      '& .MuiFormControl-fullWidth': {
        marginBottom: '12px !important',
      },

      '& .MuiFilledInput-root .MuiInputBase-input': {
        maxWidth: 170,
      },
    },

    radioButton: {
      margin: '0 !important',

      '&:not(:last-child)': {
        marginRight: `16px !important`,
      },

      '& .MuiButtonBase-root': {
        display: 'none',

        '& + span': {
          width: 110,
          height: 32,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 14,
          fontWeight: 500,
          color: Colors.BLACK_TWELVE,
          border: `1px solid ${Colors.BLACK_TWELVE}`,
          borderRadius: 25,

          '&:hover': {
            opacity: 0.5,
          },
        },

        '&.Mui-checked + span': {
          backgroundColor: `rgba(47, 128, 237, 0.25)`,
          color: Colors.BLACK_TWELVE,
          opacity: `1 !important`,
          border: 'none',
        },
      },
    },

    phoneInputNew: {
      position: 'relative',
      paddingBottom: 30,
      maxWidth: 370,

      '& > label': {
        position: 'absolute',
        fontSize: 10,
        fontWeight: 500,
        color: Colors.BLACK_TEN,
        transform: 'none !important',
        fontFamily: Fonts.INTER,
        top: -17,
        left: 40,
      },

      '& .PhoneInputInput': {
        color: Colors.BLACK_TEN,
        border: `1px solid ${Colors.BLACK_TEN}`,
        height: 33,
        padding: '0 10px',
        fontSize: 14,
        background: Colors.WHITE_ONE,
        borderRadius: '5px !important',

        '&::placeholder': {
          color: '#8F8F8F',
          fontFamily: Fonts.INTER,
        },
      },

      '& .PhoneInputCountryIcon': {
        color: Colors.BLACK_TEN,
      },

      '& .PhoneInputCountrySelectArrow': {
        borderColor: Colors.BLACK_ONE,
        border: '1px solid #1b1b1b !important',
        borderTopWidth: '0px !important',
        borderLeftWidth: '0 !important',
        borderStyle: 'solid !important',
      },

      '& .react-phone-number-input__icon': {
        border: 'none',
        width: 27,
        height: 'auto',
      },

      '& .react-phone-number-input__country--native': {
        marginRight: 19,
      },

      '& .react-phone-number-input__input': {
        background: Colors.WHITE_ONE,
        border: `1px solid ${Colors.BLACK_TEN}`,
        borderRadius: 5,
        padding: '0 10px',
        height: 35,
        fontSize: 14,
        color: Colors.BLACK_TEN,
        fontFamily: Fonts.INTER,
      },

      '& .react-phone-number-input__input::placeholder': {
        color: `${Colors.GRAY_SIX} !important`,
      },

      '&.error': {
        '& .PhoneInputInput': {
          background: Colors.WHITE_ONE,
          border: `1px solid ${Colors.RED_ONE}`,
          borderRadius: 5,
          padding: '0 10px',
          height: 35,
          fontSize: 14,
          color: Colors.RED_ONE,
        },

        '& .PhoneInputInput::placeholder': {
          fontFamily: Fonts.INTER,
          color: `#F8BBBA !important`,
        },
      },

      '& .Mui-error': {
        marginLeft: `38px !important`,
      },

      '& .react-phone-number-input__error': {
        margin: '5px 0px 0px',
        background: `url(${ERROR_IMG}) no-repeat center left`,
        lineHeight: '12px',
        paddingLeft: 18,
        marginLeft: 53,
        fontSize: '0.75rem',
        fontFamily: Fonts.INTER,
        color: Colors.BLACK_ELEVEN,
      },
    },

    leadFormHeader: {
      '& .MuiTypography-h2': {
        margin: '26px 0px 66px',
        fontWeight: 'bold',
        fontSize: 36,
        letterSpacing: '-0.022em',
        color: '#0A2463',
        fontFamily: Fonts.POPPINS_FONT,
      },
    },

    salesLeadForm: {
      maxWidth: 818,
      padding: '36px 33px',
      margin: '60px auto',
      borderRadius: 25,
      border: '1px solid #D4D4D4',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('sm')]: {
        maxWidth: '90%',
        padding: '36px 15px',
      },

      '& .MuiFormHelperText-root.Mui-error': {
        color: '#f44336',
      },

      '& .MuiFormControl-root': {
        width: '100%',
      },

      '& .MuiInputBase-input': {
        width: 362,
        height: 40,
        borderRadius: 6,
        border: '1px solid #D4D4D4',
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        padding: '8px 16px',
        boxSizing: 'border-box',
        fontFamily: Fonts.INTER,

        '&::placeholder': {
          color: '#A9A9A9',
          opacity: 1,
          fontFamily: Fonts.INTER,
        },

        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },

      '& .MuiTypography-caption': {
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        marginBottom: 4,
        display: 'block',
        lineHeight: '20px',
      },

      '& .MuiButton-root.MuiButton-containedPrimary': {
        minWidth: 263,
        height: 40,
        background: '#2F80ED',
        borderRadius: 12,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#ffffff',
        textTransform: 'capitalize',

        [theme.breakpoints.down('xs')]: {
          minWidth: '100%',
        },
      },
    },

    callerRadioForm: {
      '& .MuiTypography-h5': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#272929',
        marginBottom: 33,
      },

      '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
        display: 'none',
      },

      '& input.PhoneInputInput': {
        fontFamily: Fonts.INTER,
        color: '#A9A9A9',
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#D4D4D4',
        borderRadius: 6,
      },
    },

    callerRadioGroup: {
      paddingBottom: 43,

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },

      '& .MuiTypography-h5': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#272929',
        margin: '0px 18px 0px 0px',

        [theme.breakpoints.down('xs')]: {
          marginBottom: 20,
        },
      },

      '& .MuiButtonBase-root': {
        display: 'none',

        '& + span': {
          width: 131,
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 14,
          fontWeight: 600,
          color: Colors.BLUE_SEVEN,
          border: `1px solid ${Colors.BLUE_SEVEN}`,
          borderRadius: 25,
          fontFamily: Fonts.POPPINS_FONT,

          '&:hover': {
            opacity: 0.5,
          },
        },

        '&.Mui-checked + span': {
          backgroundColor: '#2F80ED',
          color: Colors.WHITE_ONE,
          opacity: `1 !important`,
          border: 'none',
        },
      },
    },

    hearAboutUs: {
      '& .successNotification': {
        '& .notificationHeading': {
          lineHeight: 0,
          color: '#272929',
          fontFamily: Fonts.INTER,
          fontSize: 14,
          fontWeight: 600,
          letterSpacing: '-0.084px',
        },

        '& .notificationSubHeading': {
          color: '#7D7F7F',
          fontSize: 14,
          fontWeight: 400,
          marginBottom: 30,
          fontFamily: Fonts.INTER,
          letterSpacing: '-0.084px',
        },

        '& .notificationCaption': {
          fontSize: 12,
          fontWeight: 400,
          color: '#7D7F7F',
          fontFamily: Fonts.INTER,
          letterSpacing: '-0.072px',

          '& b': {
            marginRight: 2,
            fontWeight: 700,
            color: '#272929',
          },

          '& a': {
            color: '#2F80ED',
            fontFamily: Fonts.INTER,
            fontSize: 12,
            fontWeight: 500,
            letterSpacing: '-0.072px',
            borderBottom: '1px solid #2F80ED',
          },
        },
      },

      '& .MuiTypography-caption': {
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        marginBottom: 4,
        display: 'block',
      },

      '& .MuiFormControl-fullWidth': {
        marginBottom: 0,
      },

      '& .MuiTypography-h5': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#272929',
        margin: '52px 0px 16px',
      },

      '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInputLabel-shrink, .MuiSelect-icon, .MuiInputLabel-formControl':
        {
          display: 'none !important',
        },

      '& .MuiSelect-select.MuiSelect-select': {
        width: '100%',
        height: 40,
        borderRadius: 6,
        border: 0,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        background: `url(${Arrow_Img}) no-repeat`,
        backgroundPosition: 'center right 20px',

        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },

      '& .placeholder': {
        color: '#A9A9A9',
      },

      '& .MuiFormControl-root': {
        width: '100%',
      },

      '& .MuiInputBase-input': {
        width: 362,
        height: 40,
        borderRadius: 6,
        border: '1px solid #D4D4D4',
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#A9A9A9',
        padding: '8px 16px',
        boxSizing: 'border-box',

        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },

      '& .MuiInputBase-root': {
        margin: 0,
      },

      '& .MuiTypography-body2': {
        marginBottom: 4,
        fontFamily: Fonts.INTER,
      },
    },

    dividerSection: {
      margin: '65px 0px',

      '& .MuiDivider-root': {
        background: '#D4D4D4',
      },
    },

    dividerSectionForm: {
      margin: '31px 0px 39px',
    },

    studentParentForm: {
      '& .MuiBox-root': {
        padding: 0,
      },

      '& .MuiFilledInput-root': {
        backgroundColor: 'transparent',

        '& .MuiInputBase-input': {
          width: '100%',
        },
      },

      '& .MuiFilledInput-underline:after, .MuiFilledInput-underline:before': {
        display: 'none',
      },

      '& input.PhoneInputInput': {
        width: '100%',
        height: 40,
        borderRadius: 6,
        border: '1px solid #D4D4D4',
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        padding: '8px 16px',

        '&::placeholder': {
          fontSize: 14,
          color: '#A9A9A9',
        },
      },

      '& .MuiFormControl-fullWidth': {
        marginBottom: 0,
      },
    },

    graduationYear: {
      '& .fieldCaption': {
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 4,
        color: '#272929',
        fontFamily: Fonts.INTER,
        letterSpacing: '-0.084px',
      },

      '& .MuiTypography-body2:first-child': {
        display: 'none',
      },

      '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInputLabel-shrink, .MuiSelect-icon, .MuiInputLabel-formControl':
        {
          display: 'none !important',
        },

      '& .MuiSelect-select.MuiSelect-select': {
        height: 40,
        borderRadius: 6,
        border: '1px solid #D4D4D4',
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        background: `url(${Arrow_Img}) no-repeat`,
        backgroundPosition: 'center right 20px',
        fontFamily: Fonts.INTER,
      },

      '& .MuiInputBase-root': {
        margin: 0,
      },

      '& .MuiTypography-body2': {
        marginBottom: 4,
        fontFamily: Fonts.INTER,
      },
    },

    financialAid: {
      '& .MuiTypography-h5': {
        margin: 0,
      },
    },

    aidOptionsLeadForm: {
      padding: '33px 0px 17px',

      '& .MuiFormControlLabel-root': {
        margin: 0,
        display: 'block',
      },

      '& .MuiFormControlLabel-root .MuiFormControlLabel-label': {
        background: Colors.WHITE_ONE,
        border: `1px solid ${Colors.BLUE_SEVEN}`,
        borderRadius: 50,
        color: Colors.BLUE_SEVEN,
        height: 38,
        justifyContent: 'center',
        padding: '0 10px',
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        maxWidth: 526,
        marginBottom: 10,
        fontFamily: Fonts.POPPINS_FONT,

        [theme.breakpoints.down('xs')]: {
          fontSize: 12,
        },

        '&:hover': {
          opacity: 0.5,
        },
      },

      '& .MuiIconButton-colorPrimary': {
        display: 'none',
      },

      '& .MuiFormControlLabel-root .Mui-checked + .MuiFormControlLabel-label': {
        backgroundColor: 'rgba(47, 128, 237, 0.25)',
        opacity: `1 !important`,
        color: '#272929',
        border: 'none',
      },
    },
  })
);
