import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { AppContext } from '../../../contexts';
import {
  GetUserInvoices,
  GetUserInvoices_getUserInvoices,
} from './__generated__/GetUserInvoices';

import {
  DisputeStatus,
  disputeStatusAlt,
  InstallmentStatus,
  NewRefundStatus,
  OriginalRefundStatus,
  PaymentStatus,
} from '../../../common/constants';
import PaymentRow, { Invoice } from './PaymentRow';
import ReprocessPaymentModal from './ReprocessPaymentModal';
import { useStyles } from './styles';

export const GET_USER_INVOICES = gql`
  query GetUserInvoices($StudentId: Int) {
    getUserInvoices(StudentId: $StudentId) {
      stripeSubscriptionId
      UserSubscriptionId
      PaymentId
      startDate
      endDate
      stripeInvoiceId
      amount
      status
      shouldRetry
      isDisputed
      payments {
        id
        totalAmount
        amountCaptured
        studentId
        hosted_invoice_url
        invoice_pdf
        invoiceId
        retriedAt
        retriedBy
        RetriedById
        createdAt
        status
        shouldRetry
        disputes {
          id
          amount
          status
          stripeId
          createdAt
          DisputeStatuses {
            status
            id
            createdAt
          }
        }
        refunds {
          id
          amountRefunded
          stripeRefundId
          amountRefundedAt
          status
        }
        cardBrand
        cardLast4
        hasDisputes
        errorCode
        errorMessage
      }
    }
  }
`;

const currentdate = new Date();
const currentYear = currentdate.getFullYear();
const temporaryArray = [1, 2, 3, 4, 5, 6];
const YEARS_ARRAY: any = [];
temporaryArray.map((val) => {
  YEARS_ARRAY.push({ label: val + currentYear, value: val + currentYear });
});
YEARS_ARRAY.push({ label: currentYear, value: currentYear });
YEARS_ARRAY.push({ label: 'Other', value: 'Other' });

const Billing = () => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [InvoiceId, setInvoiceId] = useState('');
  const [amount, setAmount] = useState<number | null>(null);

  const { user } = useContext(AppContext);

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Query<GetUserInvoices> query={GET_USER_INVOICES}>
        {({ data, refetch }) => {
          const invoices = data?.getUserInvoices?.sort(
            (a: any, b: any) => a.createdAt - b.createdAt
          );

          return (
            <Grid container>
              <Grid container lg={12} md={12} xs={12} alignItems="center">
                <Grid item lg={12} md={12} xs={12}>
                  <Typography variant="h4">Payment History</Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={12}></Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <Divider></Divider>
                </Grid>
              </Grid>

              <Box className={classes.paymentsTableStyle} id="payments">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  className="paymentTableHeader"
                >
                  <Box className="paymentsTableHead paymentsTableHeadZero">
                    &nbsp;
                  </Box>
                  <Box className="paymentsTableHead paymentsTableHeadFirst">
                    Payment Method
                  </Box>
                  <Box className="paymentsTableHead paymentsTableHeadSecond">
                    Date
                  </Box>
                  <Box className="paymentsTableHead paymentsTableHeadThird">
                    Amount
                  </Box>
                  <Box className="paymentsTableHead paymentsTableHeadFour">
                    Status
                  </Box>
                  {/* <Box className="paymentsTableHead paymentsTableHeadFive">
                    INVOICE ID
                  </Box> */}
                  <Box className="paymentsTableHead paymentsTableHeadSix">
                    &nbsp;
                  </Box>
                </Box>

                {invoices &&
                  invoices.map(
                    (bill: GetUserInvoices_getUserInvoices | null, index) => {
                      if (bill && bill.status === InstallmentStatus.CANCELED) {
                        return null;
                      }

                      let _invoices: Invoice[] = [];
                      bill?.payments?.forEach((payment) => {
                        if (payment) {
                          payment?.refunds?.forEach((refund) => {
                            if (refund) {
                              const refundStatus =
                                refund.status === OriginalRefundStatus.PENDING
                                  ? NewRefundStatus.PENDING
                                  : refund.status ===
                                    OriginalRefundStatus.SUCCESS
                                  ? NewRefundStatus.SUCCESS
                                  : refund.status;
                              _invoices.push({
                                id: payment.invoiceId,
                                date: refund?.amountRefundedAt
                                  ? refund?.amountRefundedAt * 1000
                                  : null,
                                status: refundStatus,
                                amount: refund.amountRefunded,
                                cardBrand: payment.cardBrand,
                                cardLast4: payment.cardLast4,
                                type: 'refund',
                                invoice_pdf: payment.invoice_pdf,
                                sign: '-',
                                errorMessage: '',
                              });
                            }
                          });
                          payment?.disputes?.forEach((dispute) => {
                            if (dispute) {
                              dispute?.DisputeStatuses?.forEach((dStatus) => {
                                const disputeStatus = dStatus?.status
                                  ? dStatus.status === DisputeStatus.WON
                                    ? disputeStatusAlt[DisputeStatus.LOST]
                                    : dStatus.status === DisputeStatus.LOST
                                    ? disputeStatusAlt[DisputeStatus.WON]
                                    : disputeStatusAlt[dStatus.status]
                                    ? disputeStatusAlt[dStatus.status]
                                    : dStatus.status
                                  : '--';
                                const desc =
                                  dStatus?.status === DisputeStatus.WON ||
                                  dStatus?.status === DisputeStatus.LOST
                                    ? ' by student'
                                    : '';
                                if (dStatus) {
                                  _invoices.push({
                                    id: payment.invoiceId,
                                    date: dStatus.createdAt,
                                    status: disputeStatus + desc,
                                    amount: payment.totalAmount,
                                    cardBrand: payment.cardBrand,
                                    cardLast4: payment.cardLast4,
                                    type: 'dispute',
                                    invoice_pdf: payment.invoice_pdf,
                                    sign:
                                      dStatus?.status === DisputeStatus.OPENED
                                        ? '-'
                                        : dStatus?.status === DisputeStatus.WON
                                        ? '+'
                                        : '',
                                    errorMessage: '',
                                  });
                                }
                              });
                            }
                          });
                          _invoices.push({
                            id: payment.invoiceId,
                            date: payment.createdAt,
                            status: payment.status,
                            amount: payment.totalAmount,
                            cardBrand: payment.cardBrand,
                            cardLast4: payment.cardLast4,
                            type:
                              payment.status === PaymentStatus.FAILURE
                                ? 'failed'
                                : payment.status === PaymentStatus.PENDING
                                ? 'pending'
                                : 'success',
                            invoice_pdf: payment.invoice_pdf,
                            sign: '',
                            errorMessage: payment.errorMessage || '',
                          });
                        }
                      });

                      _invoices.sort(
                        (a: any, b: any) =>
                          new Date(b.date).getTime() -
                          new Date(a.date).getTime()
                      );
                      const firstInvoice = _invoices[0];

                      return (
                        <Box className={classes.paymentsTableBodyContainer}>
                          <PaymentRow
                            installment={bill}
                            refetchInvoices={refetch}
                            invoice={firstInvoice}
                          />
                        </Box>
                      );
                    }
                  )}
              </Box>
              {user?.id ? (
                <ReprocessPaymentModal
                  showModal={showModal}
                  onClose={onClose}
                  StudentId={user.id}
                  amount={amount}
                  InvoiceId={InvoiceId}
                  refetch={refetch}
                />
              ) : null}
            </Grid>
          );
        }}
      </Query>
    </>
  );
};

export default withRouter(Billing);
