export default {
  BLACK_ONE: '#1b1b1b',
  BLACK_TWO: '#4f4f4f',
  BLACK_THREE: '#192433',
  BLACK_FOUR: '#303030',
  BLACK_FIVE: '#000000',
  BLACK_SIX: '#333333',
  BLACK_SEVEN: '#444d59',
  BLACK_EIGHT: '#BEBEBE',
  BLACK_NINE: '#212529',
  BLACK_TEN: '#62636B',
  BLACK_ELEVEN: '#010101',
  BLACK_TWELVE: '#272929',
  BLACK_THIRTEEN: '#525454',
  BLACK_FOURTEEN: '#7d7f7f',
  BLACK_BORDER: '#47505c',
  BLACK_ALPHA: '#9a9a9a',
  WHITE_ONE: '#ffffff',
  WHITE_TWO: '#fbfbfb',
  WHITE_THREE: '#fefefe',
  WHITE_FOUR: '#fdfdfd',
  WHITE_FIVE: '#f9feff',
  WHITE_SIX: '#ECEBEA',
  WHITE_SEVEN: '#D4D4D4',
  WHITE_EIGHT: '#EBF3FE',
  WHITE_RGBA: 'rgba(255, 255, 255, 0.25)',
  GRAY_ONE: '#d8d8d8',
  GRAY_TWO: '#828282',
  GRAY_THREE: '#979797',
  GRAY_FOUR: '#d8dde6',
  GRAY_FIVE: '#f3f3f3',
  GRAY_SIX: '#b3b3b3',
  GRAY_SEVEN: '#cccccc',
  GRAY_EIGHT: '#EAEAEA',
  GRAY_NINE: '#e0e0e0',
  GRAY_TEN: '#F2F2F2',
  GRAY_ELEVEN: '#BDBDBD',
  GRAY_TWELVE: '#C4C4C4',
  GRAY_THIRTEEN_MENTORS: '#f8f8f8',
  GRAY_FOURTEEN: '#999999',
  GRAY_FIFTEEN: '#9b9b9b',
  GRAY_SIXTEEN: '#fa755a',
  GRAY_SEVENTEEN: '#fefde5',
  GRAY_EIGHTEEN: '#9c9c9c',
  GRAY_NINETEEN: '#e4e4e4',
  GRAY_TWENTY: '#E5E5E5',
  GRAY_TWENTYONE: '#8F8F8F',
  GRAY_TWENTYTWO: '#A9A9A9',
  GRAY_TWENTYTHREE: '#F6F8F9',
  GRAY_TWENTYFOUR: '#EDEDED',
  GRAY_RGBA: 'rgba(0, 0, 0, 0.42)',
  GRAY_BOREDER: '#f4f4f4',
  GRAY_ALPHA: '#7E7E7E',
  GRAY_BETA: '#FCFCFC',
  LIGHT_GRAY: 'rgb(204, 204, 204)',
  SKYBLUE_TWO: '#edebeb',
  SKYBLUE_THREE: '#56CCF2',
  SKYBLUE_FOUR: 'rgb(0, 110, 144)',
  SKYBLUE_FIVE: '#e4f7fd',
  SKYBLUE_SIX: '#2668C1',
  GREEN_ONE: '#40be65',
  GREEN_TWO: '#219653',
  GREEN_THREE: '#27AE60',
  GREEN_FOUR: '#6fcf97',
  GREEN_FIVE: '#86F3B4',
  GREEN_SIX: '#4cd964',
  GREEN_SEVEN: '#6BCC96',
  GREEN_EIGHT: '#45CE93',
  GREEN_NINE: '#38A878',
  GREEN_TEN: '#2C845E',
  GREEN_ELEVEN: '#A1E6C8',
  LIGHT_GREEN: '#BDDFD1',
  LIGHT_GREEN_ONE: '#52c9bb',
  RED_ONE: '#eb5757',
  RED_TWO: '#f44336',
  RED_THREE_APP: '#a51c30',
  RED_FOUR_APP: '#d92a43',
  RED_FIVE: '#ff5900',
  RED_SIX: '#F9BAB9',
  RED_SEVEN: '#E94444',
  BLUE_ONE: '#006e90',
  BLUE_TWO: '#6baab9',
  BLUE_THREE: 'rgb(70, 147, 171)',
  BLUE_FOUR: '#4d61fc',
  BLUE_FIVE: '#006390',
  BLUE_SIX: '#2D9CDB',
  BLUE_SEVEN: '#2F80ED',
  BLUE_SEVEN_DARK: '#2668C1',
  BLUE_EIGHT: '#558ba4',
  BLUE_NINE: '#00A0CF',
  BLUE_NINE_APP: '#52247e',
  BLUE_TEN_APP: '#7133ac',
  BLUE_ELEVEN: '#00356b',
  BLUE_TWELVE: '#0A2463',
  BLUE_THIRTEEN: '#63A0F2',
  BLUE_FOURTEEN: '#6776FF',
  BLUE_FIFTEEN: '#8D98FF',
  BLUE_ALPHA: '#4384E6',
  BLUE_BETA: '#1E5298',
  BLUE_OPACITY: '#C2DCFD',
  BOX_SHADOW_ONE: 'rgba(0, 0, 0, 0.5)',
  BOX_SHADOW_TWO: 'rgba(0, 0, 0, 0.25)',
  BOX_SHADOW_THREE: 'rgba(0, 0, 0, 0.1)',
  BOX_SHADOW_FOUR: '0px 1px 6px 1px rgba(0,0,0,0.1)',
  BOX_SHADOW_BTN_ONE: 'rgba(136, 144, 195, 0.22)',
  BOX_SHADOW_BTN_TWO: 'rgba(37, 44, 97, 0.17)',
  INPUT_BG: 'rgba(246, 246, 246, 0.3)',
  INPUT_BG_FOCUS: 'rgba(246, 246, 246, 0.2)',
  CONTENT_BG: 'rgba(107, 170, 185, 0.6)',
  TOGGLE_SPLIT: '#ecf4f6',
  INPUT_PLACEHOLDER: '#dadada',
  BUTTON_BORDER: '#f4f4f4',
  YELLOW: '#F2994A',
  YELLOW_ONE: '#edc054',
  YELLOW_TWO: '#FFB675',
  YELLOW_THREE: '#FDBD4E',
  YELLOW_FOUR: '#FCA713',
  BLACK_RGBA_ONE: 'rgba(196, 196, 196, 0.5)',
  COLOR_RGBA: 'rgba(0, 0, 0, 0.6)',
  COLOR_PRIMARY: '#43ABE6',
  COLOR_SECONDARY: '#E66A43',
  RGBA_COLOR_ONE: 'rgba(68, 68, 68, 0.14)',
  PREMIUM: '#00A0CF',
  ELITE: '#0A2463',
  PLATINUM: '#2F80ED',
  STANDARD: '#45CE93',
  BUTTON_FOCUS_BORDER: 'rgba(47, 128, 237, 0.5)',
  IVY: '#45CE93',
  BLUE_DARK: '#2668C1',
  RED_DARK_HOVER: '#C63A3A',
  SELECT_OPTION_ACTIVE: '#DEEBFF',
  TOOLTIP_SHADOW_ONE: 'rgba(26, 32, 36, 0.32)',
  TOOLTIP_SHADOW_TWO: 'rgba(91, 104, 113, 0.24)',
  OUTLINED_TRANSPARENT_BUTTON: 'rgba(255, 255, 255, 0.2)',
  OUTLINED_TRANSPARENT_HOVER_BUTTON: 'rgba(255, 255, 255, 0.4)',
  green_hover_color: '#4AB5A8',
  BLUE_SHADE_ONE: '#f7f8ff',
  DARK_BLUE_COLOR: '#475B8A',
};
