import React from 'react';
import clsx from 'clsx';
import {
  Card,
  Typography,
  Theme,
  createStyles,
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  Box,
} from '@material-ui/core';
import fonts from '../../../common/fonts';

import DIALOG_CLOSE_ICON from '../../../img/close-dialog.svg';
import VERIFY_CHECK_ICON from '../../../img/verify-review-check.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reviewMentorCardDialog: {
      border: '0px !important',
      borderRadius: 0,
      padding: '0px !important',
      maxWidth: '100% !important',
      minHeight: 'auto !important',
      margin: '0px !important',
    },

    reviewReadMoreDialog: {
      '& .readMoreContent': {
        padding: '28px 26px 44px 38px',
        overflowY: 'initial !important',
      },

      '& [role=dialog]': {
        border: '1px solid #D4D4D4',
        borderRadius: '20px',
        maxWidth: 580,
        width: '100%',

        '& .closeButton': {
          opacity: 0.3,
          padding: '2px 12px',
        },

        '& .college': {
          margin: 0,
        },
      },
    },

    reviewMentorCard: {
      borderRadius: 20,
      maxWidth: 406,
      width: '100%',
      minHeight: 209,
      marginRight: 20,
      padding: "24px 20px 24px 24px",
      border: '1px solid #D4D4D4',
      boxShadow: 'none !important',
      backgroundColor: 'transparent',

      [theme.breakpoints.down('sm')]: {
        margin: '0px 0px 20px',
      },

      '& .detailReadMoreButton': {
        color: '#2F80ED',
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        fontFamily: fonts.INTER,
        cursor: 'pointer',
      },

      '& .name': {
        fontWeight: 600,
        color: '#272929',
        fontFamily: fonts.INTER,
      },

      '& .college': {
        fontWeight: 400,
        color: '#7D7F7F',
        marginBottom: 15,
        textTransform: 'initial',
        '& .capitalize': {
          textTransform: 'capitalize',
        },
      },

      '& .description': {
        fontWeight: 400,
        color: '#525454',
        lineHeight: '20px',
        marginBottom: 21,
      },

      '& .date': {
        fontWeight: 400,
        color: '#7D7F7F',

        '& img': {
          marginRight: 15,
        },
      },

      '& .commonTextStyle': {
        fontSize: 14,
        letterSpacing: '-0.006em',
        fontFamily: fonts.INTER,
        '& .parent': {
          fontWeight: 400,
          color: '#7D7F7F',
        },
      },
    },
  })
);

interface ReviewCardProps {
  name: string;
  college: string;
  description: string;
  verifiedDate: string;
  gradYear: number | null;
  parentReview: boolean;
}

export default function MentorsReviewCard({
  name,
  college,
  description,
  verifiedDate,
  gradYear,
  parentReview,
}: ReviewCardProps) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card
        className={classes.reviewMentorCard}
        onClick={() => {
          description?.length > 200 && handleClickOpen();
        }}
        style={{
          cursor: description?.length > 200 ? "pointer" : "initial"
        }}
      >
        <Typography variant="h5" className="name commonTextStyle">
          {name}. <span className="parent">{parentReview && `(Parent)`}</span>
        </Typography>
        <Typography variant="h6" className="college commonTextStyle">
          {gradYear ? (
            <>
              Class of {gradYear}{' '}
              {college ? (
                <>
                  - Accepted to <span className="capitalize">{college}</span>
                </>
              ) : (
                ''
              )}
            </>
          ) : college ? (
            <>
              Accepted to <span className="capitalize">{college}</span>
            </>
          ) : (
            ''
          )}
          {!gradYear && !college ? `Former client of ${name}` : ''}
        </Typography>

        <Typography
          variant="body1"
          className="description commonTextStyle descriptionReviewText"
        >
          {description &&
            (description?.length > 180
              ? description?.substring(0, 180) + ' ...'
              : description)}

          {' '}
          {description && description?.length > 185 && (
            <Box
              component="span"
              onClick={handleClickOpen}
              className="detailReadMoreButton"
            >
              Read more
            </Box>
          )}
        </Typography>

        <Typography variant="body2" className="date commonTextStyle">
          <img src={VERIFY_CHECK_ICON} alt="verify" />
          {verifiedDate}
        </Typography>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.reviewReadMoreDialog}
      >
        <DialogContent className="readMoreContent">
          <div
            className={clsx(
              classes.reviewMentorCard,
              classes.reviewMentorCardDialog
            )}
          >
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
              paddingBottom={2}
            >
              <Box>
                <Typography variant="h5" className="name commonTextStyle">
                  {name}.{' '}
                  <span className="parent">{parentReview && `(Parent)`}</span>
                </Typography>
                <Typography variant="h6" className="college commonTextStyle">
                  {gradYear ? (
                    <>
                      Class of {gradYear}{' '}
                      {college ? (
                        <>
                          - Accepted to{' '}
                          <span className="capitalize">{college}</span>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  ) : college ? (
                    <>
                      Accepted to <span className="capitalize">{college}</span>
                    </>
                  ) : (
                    ''
                  )}
                  {!gradYear && !college ? `Former client of ${name}` : ''}
                </Typography>
              </Box>

              <IconButton className="closeButton" onClick={handleClose}>
                <img src={DIALOG_CLOSE_ICON} alt="close" />
              </IconButton>
            </Box>

            <Typography variant="body1" className="description commonTextStyle">
              {description}
            </Typography>

            <Typography variant="body2" className="date commonTextStyle">
              <img src={VERIFY_CHECK_ICON} alt="verify" />
              {verifiedDate}
            </Typography>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
