import { createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import Dropdown, { OptionType } from './TimeZoneDropDown';

import { zones } from './TimeZonePicker';

interface PropsType {
  getValue: Function;
  value?: OptionType | OptionType[] | null;
  isMultiSelect?: boolean;
  label?: string;
  id?: number | null;
  disabled?: boolean;
}

const SelectTimeZone: FC<PropsType> = ({
  getValue,
  value,
  isMultiSelect,
  label,
  id,
  disabled,
}) => {
  let zoneArray: any = [];
  zones.forEach((val) => {
    zoneArray.push({ label: val, value: val });
  });
  const useStyles = makeStyles(() =>
    createStyles({
      texfieldLabels: {
        marginBottom: '4px',
        paddingLeft: '2px',
      },
    })
  );
  const classes = useStyles();
  return (
    <>
      <Typography variant="body2" className={classes.texfieldLabels}>
        Time Zone
      </Typography>
      <Dropdown
        label="Time Zone"
        suggestionsList={zoneArray}
        getValue={getValue}
        disabled={disabled}
        value={value}
        isMultiSelect={!!isMultiSelect}
        id={id}
      />
    </>
  );
};

export default SelectTimeZone;
