import { createMuiTheme } from '@material-ui/core';

import Colors from '../../../common/colors';
import Fonts from '../../../common/fonts';

export const mainTheme = createMuiTheme({
  overrides: {
    MuiContainer: {
      root: {
        maxWidth: 1328,
      },
    },

    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
        },

        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0,
        },

        body: {
          fontFamily: Fonts.POPPINS_FONT,
          margin: 0,
        },

        img: {
          maxWidth: '100%',
        },

        a: {
          textDecoration: 'none',
          color: Colors.BLUE_SEVEN,
        },
      },
    },

    MuiFormLabel: {
      root: {
        '&$focused': {
          color: Colors.BLUE_SEVEN,
        },
      },
    },
  },
});
