import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import colors from '../../../../common/colors';
import fonts from '../../../../common/fonts';

export const useStylesRating = makeStyles((theme: Theme) =>
  createStyles({
    rating: {
      position: 'relative',
      top: 2,

      '& label': {
        margin: 0,
      },

      '& .feedbackButton': {
        margin: '-6px 0px 0px 10px',
      },

      '& svg': {
        width: 19,
      },

      '& .Mui-disabled': {
        opacity: 1,
      },
    },

    ratingDialog: {
      '& .MuiDialogContentRoot': {
        padding: '33px 35px 30px',

        [theme.breakpoints.down('xs')]: {
          padding: '30px 16px',
        },

        '& .profileAvatar': {
          width: 57,
          height: 57,
          marginRight: 17,
        },

        '& .closeDialogButton': {
          margin: '-4px -12px 0px 0px',
        },

        '& .feedbackBox': {
          marginBottom: 15,
        },

        '& .dialogHeadingFeedback': {
          fontWeight: 600,
          fontSize: 24,
          letterSpacing: '-0.019em',
          color: '#272929',
          fontFamily: fonts.POPPINS_FONT,
        },

        '& .dialogTextFeedback': {
          fontWeight: 400,
          fontSize: 14,
          letterSpacing: '-0.006em',
          color: '#525454',
          fontFamily: fonts.INTER,
        },

        '& .ratingDialogHeader': {
          marginBottom: 37,

          [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
          },
        },

        '& .ratingDialogHeaderMeeting': {
          marginBottom: 17,
        },

        '& .dialogHeading': {
          fontWeight: 600,
          fontSize: 24,
          lineHeight: '32px',
          letterSpacing: '-0.019em',
          color: '#272929',
          fontFamily: fonts.POPPINS_FONT,

          [theme.breakpoints.down('xs')]: {
            fontSize: 20,
            lineHeight: '24px',
            marginTop: 15,
          },
        },

        '& .dialogSubtitle': {
          fontWeight: 400,
          fontSize: 14,
          letterSpacing: '-0.006em',
          color: '#7D7F7F',
          fontFamily: fonts.INTER,
          maxWidth: 345,
        },

        '& .dialogText': {
          fontSize: 14,
          fontWeight: 600,
          letterSpacing: '-0.006em',
          color: '#525454',
          fontFamily: fonts.INTER,
          textTransform: 'initial',
          padding: '0px 0px 22px',
        },

        '& .infoText': {
          fontWeight: 400,
          fontSize: 12,
          letterSpacing: '-0.006em',
          color: '#A9A9A9',
          fontFamily: fonts.INTER,

          '& img': {
            marginRight: 10,
          },
        },

        '& textarea': {
          padding: '6px 12px',
          marginBottom: 23,
        },

        '& .textareaField': {
          '& div': {
            padding: 0,
          },
        },
      },

      '& .submitButton': {
        color: colors.WHITE_ONE,
        background: colors.BLUE_SEVEN,
        minWidth: 151,
        borderRadius: 12,
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '-0.006em',
        boxShadow: 'none',
        height: 40,
        textTransform: 'capitalize',

        [theme.breakpoints.down('xs')]: {
          minWidth: 130,
          padding: 0,
        },
      },
    },

    ratingModule: {
      '& label': {
        margin: 0,
      },

      '& span': {
        height: 30,
      },

      '& .ratingText': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 16,
        color: '#7D7F7F',
        letterSpacing: '-0.011em',
        marginLeft: 12,

        '& span': {
          fontWeight: 400,
        },
      },
    },
  })
);
