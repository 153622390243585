import { createStyles, makeStyles, Theme } from '@material-ui/core';

import Colors from '../../../common/colors';
import fonts from '../../../common/fonts';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentHeight: {
      minHeight: 'calc(100vh - 481px)',
    },

    reviewsWrapperDivider: {
      paddingTop: '0px !Important',
      borderBottom: '0px !important',
    },

    breadlink: {
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '26px',
      letterSpacing: '0.3375px',
      textTransform: 'uppercase',
      color: Colors.WHITE_ONE,
      display: 'flex',
      alignItems: 'center',
      fontFamily: `Poppins`,

      '&:hover': {
        color: Colors.WHITE_ONE,
      },
    },

    mentorGridFlex: {
      padding: '28px 0px 200px',

      '& .experienceTagsWidth': {
        maxWidth: 720,
        width: '100%',

        [theme.breakpoints.down('md')]: {
          maxWidth: '100%',
        },
      },

      [theme.breakpoints.down('md')]: {
        padding: '26px 0px',
        flexDirection: 'column',
      },

      '& .roleDivider': {
        marginBottom: 24,
      },

      '& .experienceText': {
        '& .Mui-disabled:before, & .Mui-disabled:after': {
          display: 'none !important',
          borderBottom: '0px !important',
        },

        '& .Mui-disabled': {
          padding: 0,
        },

        '& textarea': {
          fontWeight: 400,
          lineHeight: '30px',
          letterSpacing: '-0.011em',
          color: '#525454',
          marginTop: 17,
        },
      },

      '& .tagsDivider': {
        margin: '31px 0px 37px',
        backgroundColor: '#D4D4D4',

        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },

      '& .webinarsDivider': {
        margin: '31px 0px 0px 0px',
        backgroundColor: '#D4D4D4',

        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },

      '& .seniorAdvisor': {
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: '#A9A9A9',
        fontFamily: fonts.POPPINS_FONT,
        marginBottom: 18,
      },

      '& .seniorAdvisorSpacing': {
        marginBottom: 18,
      },

      '& .wantToWork': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 24,
        lineHeight: '32px',
        textAlign: 'center',
        letterSpacing: '-0.019em',
        color: '#272929',
        marginBottom: 32,
      },
    },

    startOrder: {
      width: 253,
      height: 40,
      fontSize: 14,
      borderRadius: 12,
      boxShadow: 'none',
      fontWeight: 'bold',
      marginBottom: 17,
      letterSpacing: '-0.006em',
      color: Colors.WHITE_ONE,
      textTransform: 'capitalize',
      fontFamily: fonts.POPPINS_FONT,
      background: Colors.GREEN_EIGHT,

      '& .typographyLink': {
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: '-0.006em',
        color: Colors.WHITE_ONE,
        textTransform: 'capitalize',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& a': {
        color: '#fff',
      },

      '&:hover': {
        opacity: 0.9,
        background: Colors.GREEN_EIGHT,

        '& a': {
          color: '#fff',
        },
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginRight: '0px',
      },
    },

    mentorProfileDetail: {
      '& .advisorCollegeName': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 12,
        lineHeight: '24px',
        letterSpacing: '0.018em',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        paddingTop: 3,

        [theme.breakpoints.down('sm')]: {
          paddingTop: 0,
        },
      },
      '& .advisorPronoun': {
        fontFamily: fonts.POPPINS_FONT,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
        color: '#FFFFFF',
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
      },

      '& .advisorName': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 700,
        fontSize: 58,
        lineHeight: '75px',
        color: '#FFFFFF',
        width: '18ch',

        [theme.breakpoints.down('sm')]: {
          fontSize: 36,
          fontWeight: 700,
          lineHeight: '42px',
          width: 'initial',
          whiteSpace: 'break-spaces',
        },
      },
    },

    mentorAcceptBadge: {
      width: 54,
      height: 54,
      backgroundColor: Colors.WHITE_ONE,
      border: `1px solid ${Colors.GRAY_NINE}`,
      borderRadius: '50px',
      padding: '0px',
      margin: '0px 11px 12px 0px',
      lineHeight: '39px',
      backgroundSize: 35,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    advisingList: {
      '& p': {
        fontSize: 14,
        fontWeight: 600,
        color: '#272929',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
      },

      '& img': {
        marginRight: 13,
      },
    },

    addMoreCollege: {
      fontWeight: 600,
      fontSize: 24,
      letterSpacing: '-0.019em',
      color: '#A9A9A9',
      fontFamily: fonts.POPPINS_FONT,

      [theme.breakpoints.down('sm')]: {
        fontSize: 34.087,
      },
    },

    paperWrapper: {
      borderRadius: 0,
      padding: '26px 15px',

      [theme.breakpoints.down('sm')]: {
        padding: '25px 0px 29px',
      },
    },

    advisorAvatar: {
      width: 97,
      height: 97,
      borderRadius: '50%',
      backgroundPosition: 'center top',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#fff',

      [theme.breakpoints.down('xs')]: {
        width: 88,
        height: 88,
      },
    },

    advisorCollegeAvatar: {
      width: 32,
      height: 32,
      borderRadius: '50%',
      backgroundPosition: 'center',
      backgroundSize: 20,
      backgroundColor: '#fff',
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      right: -7,
      top: 0,

      [theme.breakpoints.down('xs')]: {
        right: -5,
      },
    },

    advisorAvatarWrapper: {
      position: 'relative',
      marginRight: 26,
      paddingLeft: 25,

      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },

    advisorInformation: {
      [theme.breakpoints.down('xs')]: {
        marginTop: 21,
      },
    },

    callNowBox: {
      borderRadius: 25,
      padding: '26px 37px 34px',
      background: Colors.WHITE_ONE,
      marginTop: -119,
      marginBottom: 56,
      border: '1px solid #D4D4D4',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('md')]: {
        margin: '0px 0px 44px 0px',
      },

      '& .callBoxHeading': {
        marginBottom: 18,
      },

      '& .textReachOut': {
        color: '#797979',
        marginBottom: 18,
        fontFamily: fonts.INTER,
      },

      '& .callBoxSubHeading': {
        fontSize: 16,
        textTransform: 'initial',
        fontWeight: 400,
        marginBottom: 24,
      },
    },

    advisorProfileBadge: {
      position: 'absolute',
      right: '-20px',

      [theme.breakpoints.down('xs')]: {
        right: 0,
        top: -5,
      },

      '& img': {
        position: 'relative',
        width: 48,
      },

      '& .badgeRoleText': {
        fontSize: 12,
        fontWeight: 'bold',
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: Colors.WHITE_ONE,
        transform: 'rotate(4.88deg)',
        fontFamily: fonts.POPPINS_FONT,
        position: 'absolute',
        top: 9,
        right: 6,
      },
    },

    advisorRoleCard: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      border: 0,

      '& .advisorRoleFlex': {
        marginBottom: 24,
      },

      '& .advisorRole': {
        fontFamily: fonts.INTER,
        fontWeight: 600,
        fontSize: 12,
        lineHeight: '20px',
        letterSpacing: '-0.006em',
        color: '#272929',
        textTransform: 'capitalize',
      },

      '& .advisorCollege': {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '20px',
        letterSpacing: '-0.006em',
        color: '#272929',
        fontFamily: fonts.INTER,
      },
    },

    advisorRoleAvatar: {
      width: 40,
      height: 40,
      borderRadius: '50%',
      backgroundPosition: 'center',
      backgroundSize: 25,
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#fff',
      border: '0.57971px solid #D4D4D4',
      marginRight: 12,
    },

    aboutContent: {
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        marginBottom: 31,
      },
    },

    advisorInfoCard: {
      minHeight: 34,
      maxHeight: 300,
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'stretch',
      justifyContent: 'space-between',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },

    themeCard: {
      border: 0,
      width: '49%',
      borderRadius: 6,
      boxShadow: 'none',
      padding: '4px 12px',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '0px 0px 20px',
      },

      '& .roleTitle': {
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '-0.006em',
        fontFamily: fonts.INTER,
        color: '#FFFFFF',
      },

      '& .roleCollege': {
        fontWeight: 400,
      },

      '& .roleTitleDark': {
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#525454',
        fontFamily: fonts.INTER,
        lineHeight: '19px',
      },

      '& .roleCollegeDark': {
        fontWeight: 400,
      },

      '& .roleCollegeDarkHeading': {
        fontWeight: 600,
        fontSize: 14,
      },
    },

    themeCardTransparent: {
      margin: 0,
      border: '1px solid #D4D4D4',
      backgroundColor: 'transparent',

      [theme.breakpoints.down('sm')]: {
        margin: '0px !important',
      },
    },

    roleWrapper: {
      '& .roleDivider': {
        margin: '34px 0px 37px',
        background: '#D4D4D4',

        [theme.breakpoints.down('sm')]: {
          margin: '33px 0px 34px',
        },
      },
    },

    advisedAcceptColleges: {
      '& .advisorTitleText': {
        fontSize: 16,
        letterSpacing: '-0.011em',
        marginBottom: 23,
        textTransform: 'initial',
      },

      '& .advisorTitleTextSpacing': {
        margin: '36px 0px 27px',

        [theme.breakpoints.down('sm')]: {
          margin: '0px 0px 27px',
        },
      },

      '& .mobileCollegeName': {
        fontWeight: 400,
        fontSize: 12,
        textAlign: 'center',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        width: 95,
        margin: '0px auto',
        marginRight: 11,
        marginBottom: 20,
      },

      '& .mobileCollegesHorizontal': {
        [theme.breakpoints.down('xs')]: {
          flexWrap: 'nowrap',
          overflowX: 'auto',
          width: '91vw',
        },
      },

      '& .mobileCollegesHorizontalSpacing': {
        [theme.breakpoints.down('sm')]: {
          marginBottom: 24,
        },
      },
    },

    mentorAcceptBadgeAdvisor: {
      width: 69,
      height: 69,
      backgroundColor: 'transparent !important',
      border: '1px solid #D4D4D4',
      backgroundSize: 45,

      [theme.breakpoints.down('sm')]: {
        minWidth: 95,
        height: 95,
        backgroundSize: 65,
      },
    },

    tagsWrapper: {
      margin: '43px 0px 0px',

      [theme.breakpoints.down('sm')]: {
        margin: '11px 0px 0px',
      },

      '& .tagsWrapperFlex': {
        display: 'flex',
        alignItems: 'flex-start',
      },

      '& .tagsDescription': {
        fontWeight: 400,
        fontSize: 12,
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        marginTop: 10,
        maxWidth: 700,

        [theme.breakpoints.down('sm')]: {
          marginBottom: 34,
        },
      },

      '& .specialtiesText': {
        minWidth: 99,
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#272929',
        fontFamily: fonts.INTER,
        textTransform: 'capitalize',

        [theme.breakpoints.down('sm')]: {
          minWidth: 47,
        },
      },

      '& .degreeBadge': {
        background: '#EAEAEA',
        borderRadius: 6,
        padding: '2px 8px',
        margin: '0px 0px 10px 10px',
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
      },
    },

    reviewsWrapper: {
      padding: '37px 0px 51px',
      borderBottom: '1px solid #D4D4D4',

      [theme.breakpoints.down('xs')]: {
        padding: 0,
        borderBottom: 0,
      },

      '& .reviewsHeading': {
        color: '#272929',
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        marginBottom: 41,

        [theme.breakpoints.down('xs')]: {
          margin: '24px 0px',
        },
      },
    },

    webinarsWrapper: {
      borderBottom: 0,
    },

    reviewCardMobile: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
  })
);

export const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: Colors.BLACK_THREE,
    boxShadow: `0px 2px 10px ${Colors.BOX_SHADOW_THREE}`,
    borderRadius: '3px',
    padding: '15px',
    fontFamily: fonts.POPPINS_FONT,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.3375px',
    color: Colors.WHITE_ONE,
  },
}));
