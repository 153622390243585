import React from 'react';

import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { MenuProps } from '../styles/commonComponentStyles';
import { selectColor } from '../common/utility';
interface PropsType {
  isError?: boolean;
  label: string;
  onSelect: any;
  selectedValue: any;
  options: any;
  name: string;
  errorValue?: string;
  emptyDisplay?: boolean;
  defaultSelectClassName?: string;
  className?: string;
  gradYear?: Boolean;
  placeholder?: string;
}

const Dropdown: React.FC<PropsType> = ({
  isError,
  errorValue,
  label,
  selectedValue,
  onSelect,
  options,
  name,
  emptyDisplay,
  defaultSelectClassName,
  className,
  gradYear,
  placeholder,
}) => {
  return (
    <FormControl error={isError} fullWidth className={className}>
      <Box>
        <Typography variant="body2">{label}</Typography>
      </Box>

      <Select
        value={selectedValue}
        onChange={onSelect}
        name={name}
        displayEmpty={emptyDisplay}
        IconComponent={KeyboardArrowDownIcon}
        variant="outlined"
        placeholder={placeholder}
        MenuProps={MenuProps}
        className={selectColor(selectedValue)}
      >
        <MenuItem disabled value="">
          <span className={defaultSelectClassName}>
            {gradYear ? 'Select Grad Year' : label}
          </span>
        </MenuItem>

        {options.map((option: any, i: number) => {
          return (
            <MenuItem key={i} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
      {isError && errorValue && (
        <FormHelperText style={{ display: 'inline-flex' }}>
          {errorValue}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Dropdown;
