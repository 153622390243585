import { Button, Drawer, ThemeProvider } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { FC, MouseEvent, useState } from 'react';

import AllColleges from '../pages/main/shared/colleges';

import { customTheme } from '../pages/MaterializeCss';

import PLUS_ADD_IMG from '../img/add-plus-icon.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPadding: {
      padding: '0px 119px',
      height: '630px',

      [theme.breakpoints.down('sm')]: {
        padding: '49px 27px 50px',
        height: 570,
      },

      [theme.breakpoints.down('xs')]: {
        padding: '49px 16px 50px',
      },
    },

    documentDrawer: {
      '& .MuiDrawer-paper': {
        border: '1px solid #D4D4D4',
        borderRadius: '50px 50px 0px 0px',
        boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

        [theme.breakpoints.down('sm')]: {
          borderRadius: '25px 25px 0px 0px',
        },
      },
    },
  })
);

interface Props {
  refetch: Function;
  showButton?: boolean;
}

const CustomDrawer: FC<Props> = ({ refetch, showButton = true }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    bottom: false,
  });

  const toggleDrawer = () => (event: MouseEvent) => {
    setState({ ...state, bottom: !state.bottom });
  };

  return (
    <>
      {showButton ? (
        <ThemeProvider theme={customTheme}>
          <Button variant="contained" color="primary" onClick={toggleDrawer()}>
            <img src={PLUS_ADD_IMG} alt="add" /> Add to Applications
          </Button>
        </ThemeProvider>
      ) : (
        <span onClick={toggleDrawer()} className="hyperlink">
          Add to Applications
        </span>
      )}

      <Drawer
        anchor="bottom"
        open={state.bottom}
        onClose={toggleDrawer()}
        className={classes.documentDrawer}
      >
        <div role="presentation" className={classes.drawerPadding}>
          <AllColleges
            isCollegeAdded={(val: boolean) => {
              setState({ ...state, bottom: !state.bottom });
              refetch();
            }}
            variation="drawer"
            isDrawerOpen={!!state.bottom}
            onCloseDrawer={toggleDrawer()}
          />
        </div>
      </Drawer>
    </>
  );
};

export default CustomDrawer;
