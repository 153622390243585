// imports
import React, { Fragment } from 'react';
import { Typography, Grid, Box } from '@material-ui/core';
// constants
import { ACT_SAT_LIST } from '../../../common/constants';

const ActSatTooltipContent = () => {
  return (
    <Fragment>
      <Typography variant="body1" className="tooltipHeading">
        <b>Online ACT or SAT Test Prep by Magoosh</b> - Gain access to a
        self-guided study platform for either the ACT or SAT featuring:{' '}
      </Typography>

      <ul>
        <Grid container>
          {ACT_SAT_LIST.map((item) => (
            <Grid item md={12} sm={12} xs={12}>
              <li>
                <Box display="flex" alignItems="center">
                  <Box
                    mr={2}
                    width={6}
                    height={6}
                    bgcolor="#525454"
                    borderRadius="50%"
                  ></Box>
                  <Typography variant="body2" className="listText">
                    {item.title}
                  </Typography>
                </Box>
              </li>
            </Grid>
          ))}
        </Grid>
      </ul>
    </Fragment>
  );
};

export default ActSatTooltipContent;
