import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core';

import { helpSupportStyle } from './MBAHelpSupportStyles';

import HelpSupportIcon from '../../../img/help-support.svg';

export default function MBAHelpSupport() {
  const classes = helpSupportStyle();

  return (
    <Grid>
      <Box position="relative" className={classes.helpBadgeContainer}>
        <IconButton className="helpButton">
          <img src={HelpSupportIcon} alt="help" />
        </IconButton>

        <Box
          top={45}
          zIndex={999}
          position="absolute"
          id="profileBadgeMenu"
          className={classes.helpBadgeMenu}
        >
          <Grid className={classes.helpPopover}>
            <Typography variant="h4" className="title">
              Help & Support
            </Typography>

            <Typography variant="body1" className="subTitle">
              Questions? Reach out to us by calling{' '}
              <a href="tel:(855) 412-6272" className="phoneNumber">
                (855) 412-6272
              </a>{' '}
              or send us an email to <br />
              <a href="mailto:success@collegeadvisor.com" className="emailto">
                success@collegeadvisor.com
              </a>
            </Typography>

            <Typography variant="h5" className="subHeading">
              Hours of Operation
            </Typography>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.collegeadvisor.com/contact-us/"
            >
              <Typography variant="h6" className="dateText">
                Monday - Friday: 8:00am - 5:00pm CT
              </Typography>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.collegeadvisor.com/contact-us/"
            >
              <Button variant="contained" color="secondary">
                Contact Us
              </Button>
            </a>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
}
