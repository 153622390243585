import { FC, useContext, useState } from 'react';

import { Form, Formik } from 'formik';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';

import { Button, Fab, Grid } from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core/styles';

import { APP_COLLEGE_QUERY } from '../../../../graphql/queries/applicationsPage';
import { AppCollegeQuery } from '../../../../graphql/queries/applicationsPage/__generated__/AppCollegeQuery';

import {
  CreateStudentApplicationMutation,
  CreateStudentApplicationMutationVariables,
} from './__generated__/CreateStudentApplicationMutation';

import { ButtonCollegeAdded } from '../../../../styles/onBoard';

import client from '../../../../apollo';
import Colors from '../../../../common/colors';
import Fonts from '../../../../common/fonts';
import { getPremiumInfo } from '../../../../common/utility';
import { AppContext } from '../../../../contexts';
import COLLEGE_ADD_IMG from '../../../../img/college-checked.svg';
import PLUS_ADD_IMG from '../../../../img/plus-btn.svg';
import { GlobalStyle } from '../../../../styles/baseStyle';
import AddApplicationDialog from './AddApplicationDialog';

import moment from 'moment';
import { customTheme } from '../../../MaterializeCss';

const PLUS_ICON = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 0C5.26522 0 5.51957 0.105357 5.70711 0.292893C5.89464 0.48043 6 0.734784 6 1V4H9C9.26522 4 9.51957 4.10536 9.70711 4.29289C9.89464 4.48043 10 4.73478 10 5C10 5.26522 9.89464 5.51957 9.70711 5.70711C9.51957 5.89464 9.26522 6 9 6H6V9C6 9.26522 5.89464 9.51957 5.70711 9.70711C5.51957 9.89464 5.26522 10 5 10C4.73478 10 4.48043 9.89464 4.29289 9.70711C4.10536 9.51957 4 9.26522 4 9V6H1C0.734784 6 0.48043 5.89464 0.292893 5.70711C0.105357 5.51957 0 5.26522 0 5C0 4.73478 0.105357 4.48043 0.292893 4.29289C0.48043 4.10536 0.734784 4 1 4H4V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const CREATE_STUDENT_APPLICATION_MUTATION = gql`
  mutation CreateStudentApplicationMutation(
    $collegeIds: [Int!]!
    $intakeToken: String
    $decision: String
    $decisionDate: String
    $userToken: String
  ) {
    createStudentApplication(
      input: {
        collegeIds: $collegeIds
        intakeToken: $intakeToken
        decision: $decision
        decisionDate: $decisionDate
        userToken: $userToken
      }
    ) {
      status
      applicationId
      isCampusAdded
      collegeId
      studentApplication {
        id
        createdInSurvey
      }
    }
  }
`;
export enum DecisionStatus {
  RegularDecision = 'RegularDecision',
  EarlyDecision = 'EarlyDecision',
  EarlyAction = 'EarlyAction',
}
interface Props {
  collegeId: number;
  applicationCreated?: boolean;
  isPage?: boolean;
  setIsMyApplication?: Function;
  deadlines?: any;
  refetch: Function;
  openDialogAdd?: boolean;
  handleCloseDialog?: Function;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      fontFamily: Fonts.POPPINS_FONT,
      backgroundColor: `${Colors.BLUE_SEVEN} !important`,
      fontWeight: 'bold',
      fontSize: '12px',
      height: '22px',
      position: 'absolute',
      right: '11px',
      top: '11px',
      color: Colors.WHITE_ONE,
      letterSpacing: '0.3375px',
      padding: '0px 12px',
      boxShadow: 'none',
    },

    addCollegeCard: {
      '& .Mui-disabled': {
        color: Colors.GRAY_TWELVE,
        backgroundColor: `${Colors.GRAY_EIGHT} !important`,
      },
    },
  })
);

const AddCollegeForm: FC<Props> = ({
  collegeId,
  setIsMyApplication,
  applicationCreated,
  refetch,
  isPage,
  deadlines,
  openDialogAdd,
  handleCloseDialog,
}) => {
  const { fab, addCollegeCard } = useStyles();
  const initialValues = { collegeIds: [collegeId] };
  const [openDialog, setOpenDialog] = useState(false);
  const { user } = useContext(AppContext);
  const [isAdded, setIsAdded] = useState(applicationCreated ? collegeId : -1);
  const [applicationId, setApplicationId] = useState(-1);
  const isCollegeSelected = applicationCreated || isAdded === collegeId;
  const onClose = (state: boolean) => {
    setOpenDialog(state);
    if (handleCloseDialog) {
      handleCloseDialog();
    }
  };
  const onSubmit = async (decision = DecisionStatus.RegularDecision) => {
    const decisionDate =
      deadlines?.find((deadline: any) => deadline?.key === decision)?.value ||
      null;
    const highSchoolYear = user?.highSchoolYear
      ? parseInt(user?.highSchoolYear)
      : new Date().getFullYear();
    const gapYearValue = user?.gapYear ? 1 : 0;
    const deadline = moment(decisionDate);
    const deadlineMonth = deadline.month() + 1;
    if (!user?.graduated) {
      if (deadline?.year() < highSchoolYear) {
        if (deadlineMonth > 9 && deadlineMonth < 13) {
          deadline.year(highSchoolYear + gapYearValue - 1);
        } else {
          deadline.year(highSchoolYear + gapYearValue);
        }
      }
    }

    const response = await client.mutate({
      mutation: CREATE_STUDENT_APPLICATION_MUTATION,
      variables: {
        collegeIds: [collegeId],
        decision,
        decisionDate: deadline.utc().toDate(),
      },
    });
    if (response) {
      const {
        data: {
          createStudentApplication: {
            studentApplication: { id },
          },
        },
      } = response;
      setOpenDialog(false);

      if (setIsMyApplication) setIsMyApplication(true);
      else if (id) await refetch(id);
    }
  };
  if (!user) return null;
  const { id: userId } = user;
  const { essaysLeft, videosLeft, premiumPackageType } = getPremiumInfo(user);

  return (
    <GlobalStyle>
      {isCollegeSelected && (
        <ButtonCollegeAdded className="btn btn-success">
          <img src={COLLEGE_ADD_IMG} alt="" />
        </ButtonCollegeAdded>
      )}
      {!isCollegeSelected && (
        <Mutation<
          CreateStudentApplicationMutation,
          CreateStudentApplicationMutationVariables
        >
          mutation={CREATE_STUDENT_APPLICATION_MUTATION}
        >
          {(createStudentApplicationMutation: Function) => {
            return (
              <Formik
                initialValues={initialValues}
                onSubmit={async (variables, { setSubmitting }) => {
                  try {
                    const result = await createStudentApplicationMutation({
                      variables: { collegeIds: [collegeId] },
                    });
                    const {
                      data: {
                        createStudentApplication: {
                          studentApplication: { id },
                        },
                      },
                    } = result;
                    setApplicationId(id);
                    setIsAdded(collegeId);
                    refetch(id);
                  } catch (error) {
                    setSubmitting(false);
                    refetch();
                    console.log('error: ', error);
                  }
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    {!isPage && (
                      <Grid className={addCollegeCard}>
                        <Fab
                          type="submit"
                          variant="extended"
                          disabled={isSubmitting}
                          aria-label="Delete"
                          className={fab}
                        >
                          <img className="mr-2" src={PLUS_ADD_IMG} alt="" />
                          ADD
                        </Fab>
                      </Grid>
                    )}

                    {isPage && (
                      <>
                        <ThemeProvider theme={customTheme}>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              setOpenDialog(true);
                            }}
                            disabled={isSubmitting}
                            className="addToMyApplications"
                          >
                            <span className="mr-2">{PLUS_ICON()}</span>
                            Add To My Applications
                          </Button>
                        </ThemeProvider>

                        <AddApplicationDialog
                          open={openDialog}
                          onClose={onClose}
                          onSubmit={onSubmit}
                          deadlines={deadlines || []}
                        />
                      </>
                    )}

                    {openDialogAdd && (
                      <>
                        <AddApplicationDialog
                          open={openDialogAdd}
                          onClose={onClose}
                          onSubmit={onSubmit}
                          deadlines={deadlines || []}
                        />
                      </>
                    )}
                  </Form>
                )}
              </Formik>
            );
          }}
        </Mutation>
      )}

      {applicationId >= 0 && window.analytics && (
        <Query<AppCollegeQuery>
          query={APP_COLLEGE_QUERY}
          fetchPolicy="no-cache"
          variables={{ limit: 10000 }}
          onCompleted={(data) => {
            if (!data || !data.allStudentApplications) return null;

            let collegeNames: string[] = [];
            let combinedCollegeNames: string = '';
            // changed from filter to forEach
            data.allStudentApplications.StudentApplications?.forEach(
              (application) => {
                if (!application || !application.applicationRequirement)
                  return false;

                const {
                  applicationRequirement: { college },
                } = application;
                if (!college) return false;
                collegeNames.push(college.name);

                combinedCollegeNames = collegeNames.join(' || ');
              }
            );
            if (window.analytics) {
              window.analytics.identify(userId, {
                'Count of Colleges Added': collegeNames.length,
                'Names of Colleges Added': collegeNames,
                'Combined Names of Colleges Added': combinedCollegeNames,
                'Package Type': premiumPackageType,
                'Number of Essay Credits Left': essaysLeft,
                'Number of Video Chat Sessions Left': videosLeft,
                name: `${user.firstName || ''} ${user.lastName || ''}`,
                email: user.email,
              });
            }
            if (isPage) refetch(applicationId);
            setApplicationId(-1);
          }}
        >
          {() => {
            return null;
          }}
        </Query>
      )}
    </GlobalStyle>
  );
};

export default AddCollegeForm;
