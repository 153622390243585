import gql from 'graphql-tag';

export const GET_USER_FROM_INTAKE_TOKEN = gql`
  query getUserFromIntakeTokenQuery($intakeFormToken: String!) {
    getUserFromIntakeToken(input: { intakeFormToken: $intakeFormToken }) {
      id
      firstName
      lastName
      email
      pictureUrl
      roles
      phoneNumber
      intakeResponse
    }
  }
`;

export const GET_USER_FROM_USER_TOKEN = gql`
  query getUserFromUserTokenQuery($userToken: String!, $limit: Int) {
    getUserFromUserToken(input: { userToken: $userToken }) {
      user {
        id
        firstName
        lastName
        email
        roles
        isPremium
        highSchoolYear
        isPasswordSet
        studentApplicationSurvey {
          earlyActionColleges {
            id
            name
          }
          earlyDecisionCollege {
            id
            name
          }
          finalDecisionCollege {
            id
            name
            collegeId
          }
          finalCollegeDecided
          studentId
          scholarshipInfo
          appliedForEarlyAction
          appliedForEarlyDecision
          appliedForScholarship
          completedAt
        }
        studentApplications(limit: $limit) {
          id
          decision
          isFinal
          graduatedEarlyActionDeadline
          graduatedEarlyDecisionDeadline
          graduatedRegularDecisionDeadline

          ApplicationCampusesSurvey {
            id
            name
            decision
            logo
            isFinal
          }
          studentApplicationCampuses {
            id
          }
          applicationRequirement {
            college {
              id
              name
              logo
              ParentCollegeId
            }
          }
        }
        isMBAGrad
        isSmartMatch
        intakeFormToken
        SmartMatchId
      }
    }
  }
`;

export const DELETE_APPLICATION_DECISION = gql`
  mutation deleteApplicationDecisionMutation($id: Int!, $studentId: Int) {
    deleteApplicationDecision(input: { id: $id, studentId: $studentId }) {
      status
    }
  }
`;

export const DELETE_APPLICATION_DECISION_CAMPUSES = gql`
  mutation DeleteApplicationDecisionCampuses(
    $applicationId: Int!
    $collegeId: Int!
    $studentId: Int
  ) {
    deleteApplicationDecisionCampuses(
      input: {
        applicationId: $applicationId
        collegeId: $collegeId
        studentId: $studentId
      }
    ) {
      status
    }
  }
`;

export const CREATE_APPLICATION_ACCEPTANCE_SURVEY = gql`
  mutation createApplicationSurveyMutation(
    $id: Int!
    $decision: [Decisions]
    $decisionCampuses: [DecisionsCampuses]
    $earlyActionColleges: [Int]
    $earlyDecisionCollege: Int
    $scholarshipsInfo: String
    $appliedForEarlyAction: String
    $appliedForEarlyDecision: String
    $finalCollegeDecided: String
    $admissionCollegeId: Int
    $applicationId: Int
    $appliedForScholarship: String
    $completedAt: String
    $autoSaveAt: Date
    $earlyDecisionDate: Date
    $collegeId: Int
  ) {
    createApplicationSurvey(
      input: {
        id: $id
        decision: $decision
        decisionCampuses: $decisionCampuses
        earlyActionColleges: $earlyActionColleges
        earlyDecisionCollege: $earlyDecisionCollege
        scholarshipsInfo: $scholarshipsInfo
        appliedForEarlyAction: $appliedForEarlyAction
        finalCollegeDecided: $finalCollegeDecided
        admissionCollegeId: $admissionCollegeId
        appliedForEarlyDecision: $appliedForEarlyDecision
        appliedForScholarship: $appliedForScholarship
        applicationId: $applicationId
        completedAt: $completedAt
        autoSaveAt: $autoSaveAt
        earlyDecisionDate: $earlyDecisionDate
        collegeId: $collegeId
      }
    ) {
      success
      message
    }
  }
`;

export const SAVE_INTAKE_RESPONSE = gql`
  mutation saveIntakeResponseMutation($formData: JSON!, $token: String!) {
    saveIntakeResponse(input: { formData: $formData, token: $token }) {
      success
      message
    }
  }
`;
export const ALL_COLLEGES = gql`
  query allCollegesQuery($searchQuery: String) {
    allColleges(input: { searchQuery: $searchQuery }) {
      colleges {
        name
        id
      }
    }
  }
`;
export const ALL_HIGH_SCHOOLS = gql`
  query getHighSchoolsQuery($searchQuery: String) {
    getHighSchools(input: { searchQuery: $searchQuery }) {
      highSchools {
        id
        high_school_name
      }
    }
  }
`;
