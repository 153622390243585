import { Box, Card, CardContent, Tooltip, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  AllMentorsListQuery_allMentors_mentors,
  AllMentorsListQuery_allMentors_mentors_colleges,
  AllMentorsListQuery_allMentors_mentors_previouslyWorkedColleges,
} from './__generated__/AllMentorsListQuery';

import CustomTooltip from '../../../components/CustomTooltip';
import { ASSOCIATE_TYPE_ALA_CARTE } from '../../../constants';
import { AppContext } from '../../../contexts';
import { themeSchoolImageSelection } from './MentorDetailPage';

import Colors from '../../../common/colors';
import fonts from '../../../common/fonts';

import CollegeDefaultImg from '../../../img/college-default-img.svg';
import PROFILE_BADGE_IMG from '../../../img/profile-badge.png';

import client from '../../../apollo';
import { GET_ALL_STUDENT_MENTORS } from '../../../graphql/queries/studentMentors';
import { getAllStudentMentors_allStudentMentors } from '../../../graphql/queries/studentMentors/__generated__/getAllStudentMentors';
import { CollegeDetailsQuery_College_mentors } from '../colleges/__generated__/CollegeDetailsQuery';
import { MentorDetailQuery_Mentor_otherMentors } from './__generated__/MentorDetailQuery';

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: Colors.BLACK_THREE,
    boxShadow: `0px 2px 10px ${Colors.BOX_SHADOW_THREE}`,
    borderRadius: 3,
    padding: 10,
    fontFamily: fonts.POPPINS_FONT,
    fontSize: 12,
    lineHeight: '20px',
    letterSpacing: '0.3375px',
    color: Colors.WHITE_ONE,
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: 306,
      boxShadow: 'none',
      borderRadius: 15,
      marginBottom: 18,
      border: '1px solid #D4D4D4',

      [`@media only screen and (min-width: 1280px)`]: {
        maxWidth: 400,
      },

      '& .acceptedText': {
        fontWeight: 400,
        fontSize: 12,
        color: '#A9A9A9',
        margin: '16px 0px 10px',
        fontFamily: fonts.INTER,
      },
    },

    cardHeader: {
      padding: '16px 15px',
      display: 'flex',
      alignItems: 'flex-start',
      background: '#F4F4F4',
      minHeight: 100,
      borderRadius: '14px 14px 0px 0px',

      '& .MuiGrid-container': {
        alignItems: 'flex-start',
      },
    },

    cardContent: {
      padding: '16px 15px 25px !important',
    },

    mentorCard: {
      height: 84,

      '& .MuiTypography-body2': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#62636B',
        fontFamily: fonts.INTER,
        marginBottom: 12,

        [theme.breakpoints.down('sm')]: {
          fontSize: '12px',
        },
      },

      '& a': {
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '0.018em',
        color: '#2F80ED',
        fontFamily: fonts.POPPINS_FONT,
        textTransform: 'uppercase',
      },
    },

    avatarProfile: {
      width: 50,
      height: 50,
      marginRight: 14,
      borderRadius: '50%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundColor: Colors.GRAY_TWELVE,
    },

    cardAction: {
      padding: '23px 0 0',
      margin: '27px 0 0',
      borderTop: `1px solid ${Colors.GRAY_BOREDER}`,

      '& > * + *': {
        margin: 0,
      },
    },

    reviewTitle: {
      fontWeight: 600,
      fontSize: 18,
      letterSpacing: '-0.011em',
      color: '#272929',
      fontFamily: fonts.INTER,
    },

    reviewSubTitle: {
      fontFamily: fonts.INTER,
      fontSize: 14,
      letterSpacing: '-0.006em',
      color: '#7D7f7f',
      fontWeight: 400,
      minWidth: '20%',
      maxWidth: '100%',

      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial',
      },

      [theme.breakpoints.down('xs')]: {
        minWidth: '27%',
        maxWidth: '100%',
      },
    },

    subtitleWithoutYear: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
    },

    avatarAccepted: {
      width: 35,
      height: 35,
      display: 'block',
      margin: '0px 17px 0px 0px',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
    },

    currentCollegeStyle: {
      width: 45,
      height: 45,
      padding: '0px',
      margin: '0px auto',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
    },

    cardHeaderInfo: {
      '& .MuiTypography-h3': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },

      [theme.breakpoints.down('sm')]: {
        padding: '0px 5px 0px 15px',
      },
    },

    acceptEarlyAction: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '25px',
      letterSpacing: '0.3375px',
      color: Colors.BLACK_THREE,
      fontFamily: fonts.POPPINS_FONT,
    },

    firstCardAction: {
      marginRight: 20,
    },

    advisorProfileBadge: {
      position: 'relative',
      width: 51,

      '& .badgeRoleText': {
        fontSize: 13.52,
        fontWeight: 'bold',
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: Colors.WHITE_ONE,
        transform: 'rotate(4.88deg)',
        fontFamily: fonts.POPPINS_FONT,
        position: 'absolute',
        top: 7,
        right: 6,
      },
    },
  })
);

interface Props {
  isDetailPage?: Boolean;
  mentor:
    | AllMentorsListQuery_allMentors_mentors
    | CollegeDetailsQuery_College_mentors
    | MentorDetailQuery_Mentor_otherMentors;
}

const MentorsCard: FC<Props> = ({ mentor, isDetailPage }) => {
  const {
    avatarAccepted,
    avatarProfile,
    card,
    cardAction,
    cardContent,
    cardHeader,
    cardHeaderInfo,
    mentorCard,
    reviewSubTitle,
    subtitleWithoutYear,
    reviewTitle,
    currentCollegeStyle,
    acceptEarlyAction,
    firstCardAction,
    advisorProfileBadge,
  } = useStyles();
  const {
    id,
    firstName,
    lastName,
    pictureUrl,
    bio,
    colleges,
    currentCollege,
    graduateSchool,
    isAcceptedTo,
    advisorLevel,
    uuid,
    previouslyWorkedColleges,
    Theme,
  } = mentor;

  const { user } = useContext(AppContext);
  const [allStudentMentors, setAllStudentMentors] = useState<
    (getAllStudentMentors_allStudentMentors | null)[] | null
  >(null);

  let isMentorAvailableForStudent: boolean = false;
  if (user && allStudentMentors && allStudentMentors.length) {
    let filteredStudents = allStudentMentors.filter(
      (studentMentor) =>
        studentMentor &&
        studentMentor.mentor &&
        mentor.id === studentMentor.mentor.id &&
        studentMentor.associateType !== ASSOCIATE_TYPE_ALA_CARTE
    );
    if (filteredStudents.length) {
      isMentorAvailableForStudent = true;
    }
  }
  useEffect(() => {
    const fetchAllStudentMentors = async () => {
      const { data } = await client.query({
        query: GET_ALL_STUDENT_MENTORS,
        fetchPolicy: 'network-only',
      });
      if (data && data.allStudentMentors) {
        setAllStudentMentors(data.allStudentMentors);
      }
    };
    fetchAllStudentMentors();
  }, []);

  const mentorLink = `/advisors/${id}/${uuid}`;

  const currentCollegeBackgorundImage = `url( ${
    currentCollege?.logo || CollegeDefaultImg
  } )`;
  const graduateCollegeBgImage = `url( ${
    graduateSchool?.logo || CollegeDefaultImg
  } )`;

  const backgroundImage = Theme
    ? themeSchoolImageSelection(
        Theme,
        graduateCollegeBgImage,
        currentCollegeBackgorundImage
      )
    : graduateSchool
    ? graduateCollegeBgImage
    : currentCollegeBackgorundImage || CollegeDefaultImg;

  const mentorName = `${firstName || ''} ${lastName || ''}`;

  const getAcceptedTo = (
    graduateSchool: AllMentorsListQuery_allMentors_mentors_colleges | null,
    currentId: number,
    acceptedToColleges:
      | (AllMentorsListQuery_allMentors_mentors_colleges | null)[]
      | null
  ) => {
    const allCollges = acceptedToColleges?.length
      ? [
          ...(acceptedToColleges?.filter(
            (college) => college?.id !== currentCollege?.id
          ) || []),
        ]
      : [];

    if (
      !allCollges.find((college) => college?.id === graduateSchool?.id) &&
      graduateSchool
    ) {
      allCollges.push(graduateSchool);
    }
    return allCollges.reverse();
  };
  const allAcceptedToColleges = getAcceptedTo(
    graduateSchool,
    currentCollege?.id || 0,
    colleges
  );
  const tooltipClasses = useTooltipStyles();

  return (
    <Grid item lg={4} md={6} sm={12} xs={12}>
      <Card className={card}>
        <Link to={mentorLink}>
          <Grid className={cardHeader}>
            <Grid container>
              <Grid item lg={2} md={2} sm={1} xs={2}>
                <Grid
                  className={avatarProfile}
                  style={{
                    backgroundImage: 'url(' + pictureUrl + ')',
                  }}
                />
              </Grid>

              <Grid item lg={8} md={8} sm={10} xs={8}>
                <Grid className={cardHeaderInfo}>
                  <Typography variant="h3" className={reviewTitle}>
                    {mentorName}
                  </Typography>
                  {!(advisorLevel === 'Former Admissions Officer') &&
                    graduateSchool && (
                      <Tooltip
                        classes={tooltipClasses}
                        title={`${graduateSchool.name}`}
                      >
                        <Box display="flex">
                          <Typography
                            variant="h6"
                            className={reviewSubTitle}
                            noWrap
                          >
                            {graduateSchool.name}
                          </Typography>
                        </Box>
                      </Tooltip>
                    )}
                  {!(advisorLevel === 'Former Admissions Officer') &&
                    currentCollege && (
                      <Tooltip
                        classes={tooltipClasses}
                        title={`${currentCollege.name}`}
                      >
                        <Box display="flex">
                          <Typography
                            variant="h6"
                            className={reviewSubTitle}
                            noWrap
                          >
                            {currentCollege.name}
                          </Typography>
                        </Box>
                      </Tooltip>
                    )}
                  {advisorLevel === 'Former Admissions Officer' && (
                    <Typography
                      variant="h6"
                      className={clsx(reviewSubTitle, subtitleWithoutYear)}
                    >
                      Former Admissions Officer
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid item lg={2} md={2} sm={1} xs={2}>
                {advisorLevel !== 'Former Admissions Officer' && (
                  <Link
                    to={`/colleges/${
                      graduateSchool ? graduateSchool.id : currentCollege?.id
                    }`}
                  >
                    <Grid
                      className={currentCollegeStyle}
                      style={{
                        backgroundImage,
                      }}
                    />
                  </Link>
                )}
                {advisorLevel === 'Former Admissions Officer' && (
                  <Box className={advisorProfileBadge}>
                    <img src={PROFILE_BADGE_IMG} alt="badge" />
                    <Typography variant="caption" className="badgeRoleText">
                      AO
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Link>

        <CardContent className={cardContent}>
          {bio ? (
            <Box className={mentorCard}>
              <Typography variant="body2" className="detailLineCLamp">
                {bio}
              </Typography>

              <Link to={mentorLink}>READ MORE</Link>
            </Box>
          ) : (
            <Box className={mentorCard}>
              <Typography variant="body2">No Details</Typography>
            </Box>
          )}
          {!isDetailPage &&
          advisorLevel !== 'Former Admissions Officer' &&
          allAcceptedToColleges?.length > 0 ? (
            <>
              <Typography variant="body1" className="acceptedText">
                Also Accepted to
              </Typography>

              <Grid className="d-flex align-items-center">
                {!!(allAcceptedToColleges && allAcceptedToColleges.length) ? (
                  allAcceptedToColleges &&
                  allAcceptedToColleges.map((college, i) => {
                    if (!college || i > 3) return null;
                    const { id, logo, name } = college;
                    const backgroundImage = `url( ${
                      logo || CollegeDefaultImg
                    } )`;
                    return (
                      <CustomTooltip title={name} key={name}>
                        <Link
                          key={id}
                          to={`/colleges/${id}`}
                          className={avatarAccepted}
                          style={{
                            backgroundImage,
                          }}
                        />
                      </CustomTooltip>
                    );
                  })
                ) : (
                  <Typography
                    variant="h6"
                    component="h6"
                    className={acceptEarlyAction}
                  >
                    {isAcceptedTo && 'Accepted Early Decision'}
                  </Typography>
                )}
              </Grid>

              <Grid className="clearfix"></Grid>
            </>
          ) : (
            <div />
          )}
          {advisorLevel === 'Former Admissions Officer' && (
            <>
              <Typography variant="body1" className="acceptedText">
                Previous Employers
              </Typography>

              <Grid className="d-flex align-items-center">
                {!!(
                  previouslyWorkedColleges && previouslyWorkedColleges.length
                ) &&
                  previouslyWorkedColleges &&
                  previouslyWorkedColleges?.map(
                    (
                      previouslyWorked: AllMentorsListQuery_allMentors_mentors_previouslyWorkedColleges | null
                    ) => {
                      if (!previouslyWorked?.college) return null;
                      const { college } = previouslyWorked;
                      const { id, logo, name } = college;
                      if (id === currentCollege?.id) return null;
                      const backgroundImage = `url( ${
                        logo || CollegeDefaultImg
                      } )`;
                      return (
                        <CustomTooltip title={name} key={name}>
                          <Link
                            key={id}
                            to={`/colleges/${id}`}
                            className={avatarAccepted}
                            style={{
                              backgroundImage,
                            }}
                          />
                        </CustomTooltip>
                      );
                    }
                  )}
              </Grid>

              <Grid className="clearfix"></Grid>
            </>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default MentorsCard;
