import { FC, useState, useEffect } from 'react';
import { Card } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { useStyles } from './Styles';
import NewUserForm from './UserForm';

interface Props extends RouteComponentProps {
  closeModal?: Function;
  title?: string;
  partnerKey?: string;
  showBackButton?: Function;
  setBackClick?: Function;
  backClick?: Boolean;
}

const RegisterForm: FC<Props> = ({
  closeModal,
  title,
  partnerKey,
  showBackButton,
  setBackClick,
  backClick,
}): JSX.Element => {
  const classes = useStyles();
  const [optInSMS, setOptInSMS] = useState(true);
  const [passwordSetView, setPasswordSetView] = useState(false);
  const [loginView, setLoginView] = useState(false);
  const [leadViewData, setLeadViewData] = useState<any>();
  const [createdLeadId, setCreatedLeadId] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [parentorStudent, setParentOrStudent] = useState<'Student' | 'Parent'>(
    'Student'
  );
  const [initialValues, setInitialValues] = useState({
    studentFirstName: '',
    studentLastName: '',
    studentEmail: '',
    studentPhone: '',
    highSchoolYear: '',
    parentFirstName: '',
    parentLastName: '',
    parentEmail: '',
    parentPhone: '',
    financialAidOption1: '',
    aidOption: '' || null,
    gpa: '',
    partnerKey: '',
    source: '',
    salesPersonId: '',
    optInSMS,
  });

  if (!passwordSetView && !loginView) showBackButton && showBackButton(false);
  if (backClick) {
    if (passwordSetView) setPasswordSetView(false);
    if (loginView) setLoginView(false);
    setBackClick && setBackClick(false);
  }

  useEffect(() => {
    if (window.location.host === 'app.collegeadvisor.com') {
      if (window.ttq) {
        console.log('triggering ttq viewForm...');
        window.ttq.instance('C2CJLF7MU8Q03RAIPRC0').track('ViewForm');
      }
    }
  }, []);
  return (
    <Card className={`${classes.cardModal} custom-card`}>
      {(passwordSetView || loginView) === false && (
        <>
          <NewUserForm
            {...{
              parentorStudent,
              partnerKey,
              setLoginView,
              showBackButton,
              setCreatedLeadId,
              setLeadViewData,
              setPasswordSetView,
              title,
              setParentOrStudent,
              isLoading,
              setIsLoading,
              initialValues,
              setInitialValues,
              optInSMS,
              setOptInSMS,
            }}
          />
        </>
      )}
    </Card>
  );
};

export default withRouter(RegisterForm);
