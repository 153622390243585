import gql from 'graphql-tag';

export const GET_PROFILE_DATA = gql`
  query getProfileData($userId: Int!) {
    getProfileData(userId: $userId) {
      highSchoolName
      act
      apCourses
      IbCourses
      sat
      gpa
      gpaWeighted
      gpaNonWeighted
      highSchoolYear
      highSchoolState
      areasOfInterest
      undergraduateGradYear
      undergraduateCollege
    }
    getApCourses {
      id
      courseName
    }
    getIbCourses {
      id
      courseName
    }
    getAreasOfInterest {
      id
      displayName
      shortName
    }
  }
`;

export const GET_STUDENT_ACTIVITIES = gql`
  query getStudentActivitiesData($userId: Int!) {
    getProfileData(userId: $userId) {
      studentExtraCurricularActivities {
        id
        StudentId
        details
        title
        startedAt
        endedAt
        currentlyActive
        extraCurricularActivity
        leadershipExperience
        role
      }
    }
    getExtraCurricularCategories {
      id
      category
    }
  }
`;

export const AP_COURSES = gql`
  query getApCourses {
    getApCourses {
      id
      courseName
    }
  }
`;

export const IB_COURSES = gql`
  query getIbCourses {
    getIbCourses {
      id
      courseName
    }
  }
`;

export const GET_EXTRA_CURRICUALR_CATEGORIES = gql`
  query getExtraCurricularCategories {
    getExtraCurricularCategories {
      id
      category
    }
  }
`;

export const ALL_LANGUAGES = gql`
  query getAllLanguagesQuery($UserId: Int) {
    getAllLanguages(UserId: $UserId) {
      id
      name
    }
  }
`;
