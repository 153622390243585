// imports
import { Theme, createStyles, makeStyles } from '@material-ui/core';
// colors, fonts
import fonts from '../../common/fonts';
import colors from '../../common/colors';

export const useStylesSmartMatch = makeStyles((theme: Theme) =>
  createStyles({
    smartMatchIntroDialog: {
      '& [role=dialog]': {
        width: 642,
        maxWidth: 642,
        padding: '47px 43px 52px 55px',

        [theme.breakpoints.down('xs')]: {
          padding: '20px 16px',
        },
      },

      '& .description': {
        marginBottom: 30,
        maxWidth: '278px',
        letterSpacing: '-0.252px',
        color: colors.BLACK_FOURTEEN,
      },

      '& .closeButton': {
        width: 42,
      },
    },

    purchaseHeader: {
      display: 'flex',
      marginBottom: 55,

      [theme.breakpoints.down('sm')]: {
        marginBottom: 20,
      },

      '& .smartMatchDialogClick': {
        cursor: 'pointer',
      },
    },

    smartMatchGetStartedDialog: {
      '& .returnQuestion': {
        cursor: 'pointer',
        color: `${colors.GRAY_TWENTYTWO} !important`,
        borderBottom: `1px solid ${colors.GRAY_TWENTYTWO} !important`,
      },

      '& .packageText': {
        fontSize: 24,
        fontWeight: 700,
        fontFamily: fonts.POPPINS_FONT,
      },

      "& [class*='MuiSelect-selectMenu']": {
        color: colors.BLACK_TWELVE,
      },

      '& .optionalTextField': {
        width: '100%',
        marginBottom: 20,

        "& [class*='MuiInput-underline']": {
          fontSize: 16,
          fontWeight: 600,
          letterSpacing: '-0.176px',
          color: colors.BLACK_FOURTEEN,

          '&:after, &:before': {
            display: 'none',
          },
        },
      },

      '& .react-select-container': {
        '& .react-select__control': {
          maxHeight: 110,
          borderRadius: 6,
          overflowY: 'auto',
          borderColor: colors.WHITE_SEVEN,
        },

        '& .react-select__placeholder': {
          fontSize: 14,
        },

        '& .react-select__multi-value': {
          borderRadius: 6,
          background: `${colors.BLUE_THIRTEEN} !important`,
        },

        '& .react-select__indicator-separator, & .react-select__indicator': {
          display: 'none',
        },

        '& .react-select__multi-value__remove': {
          '& svg': {
            width: 16,
            height: 16,
            opacity: 0.5,
            fill: colors.WHITE_ONE,
          },

          '&:hover': {
            borderRadius: '0px 6px 6px 0px',
            background: `${colors.BLUE_THIRTEEN} !important`,
          },
        },

        '& .react-select__multi-value__label': {
          fontSize: 14,
          fontWeight: 600,
          padding: '3px 6px',
          color: colors.WHITE_ONE,
          fontFamily: fonts.INTER,
          letterSpacing: '-0.084px',
        },

        '& .react-select__dropdown-indicator': {
          display: 'block !important',
        },

        '& .react-select__indicators': {
          alignItems: 'flex-start',
        },
      },

      "& [class*='MuiDialog-paper']": {
        width: '100%',
        borderRadius: 20,
        margin: '0px 10px',
        overflowY: 'visible',
        border: `1px solid ${colors.WHITE_SEVEN}`,
        boxShadow: `0px 4px 14px ${colors.RGBA_COLOR_ONE}`,

        [theme.breakpoints.up('sm')]: {
          maxWidth: 'max-content',
        },

        [theme.breakpoints.down('xs')]: {
          borderRadius: 10,
        },

        '& .skip': {
          fontSize: 14,
          fontWeight: 400,
          cursor: 'pointer',
          fontFamily: fonts.INTER,
          letterSpacing: '-0.084px',
          color: colors.BLACK_FOURTEEN,
          borderBottom: `1px solid ${colors.BLACK_FOURTEEN}`,
        },

        "& [class*='MuiButton-containedPrimary']": {
          '&:hover': {
            backgroundColor: colors.SKYBLUE_SIX,
          },
        },

        '& .contentWidthMd': {
          width: 688,
          [theme.breakpoints.down('md')]: {
            width: 560,
          },
          [theme.breakpoints.down('sm')]: {
            width: "100%",
          },
        },

        '& .contentWidthLg': {
          width: 941,
        },

        '& .contentWidthMd, & .contentWidthLg': {
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
        },

        "& [class*='MuiDialogContent-root']": {
          marginLeft: 7,
          overflowY: 'visible',
          padding: '50px 47px',

          [theme.breakpoints.down('sm')]: {
            padding: '20px 16px 45px',
          },

          "& [class*='MuiMobileStepper-root']": {
            marginBottom: 0,
          },

          '& .dialogHeadingText': {
            marginBottom: 6,
            letterSpacing: '-0.792px',
            color: colors.BLACK_TWELVE,
            fontFamily: fonts.POPPINS_FONT,
          },

          '& .stepperDesc': {
            fontWeight: 400,
            marginBottom: 21,
            letterSpacing: '-0.084px',
            color: colors.BLACK_FOURTEEN,
          },

          '& .subtitleBold': {
            fontSize: 16,
            fontWeight: 600,
            letterSpacing: '-0.176px',
          },

          '& .searchHeading': {
            color: colors.BLACK_THIRTEEN,
          },
        },
      },
    },

    fieldInterested: {
      '& .fieldBadge': {
        maxWidth: 260,
        borderRadius: 15,
        padding: '4px 12px',
        margin: '0px 11px 13px 0px',
        border: `1px solid ${colors.BLUE_SEVEN}`,

        "& [class*='MuiFormControlLabel-label']": {
          fontSize: 14,
          fontWeight: 600,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          fontFamily: fonts.INTER,
          color: colors.BLUE_SEVEN,
          letterSpacing: '-0.084px',
        },

        '&.disableCheckbox': {
          opacity: 0.3,
          cursor: 'not-allowed',
        },

        '&.checkboxChecked': {
          background: colors.BLUE_SEVEN,

          "& [class*='MuiFormControlLabel-label']": {
            color: colors.WHITE_ONE,
          },
        },

        "& [class*='MuiIconButton-label']": {
          display: 'none',
        },

        "& [class*='MuiIconButton-root']": {
          padding: 0,
        },
      },
    },

    preferencesForm: {
      padding: 0,
    },

    loader: {
      margin: theme.spacing(1),
      color: colors.BLUE_SEVEN,
      width: '86px !important',
      height: '86px !important',
    },

    admissionExpertCardSelected: {
      marginTop: 20,

      "& h5[class*='MuiTypography-h5']": {
        fontSize: 16,
        letterSpacing: '-0.176px',
        color: colors.BLACK_TWELVE,
      },

      "& [class*='MuiTypography-caption']": {
        fontWeight: 400,
        display: 'block',
        letterSpacing: '-0.072px',
        color: `${colors.GRAY_TWENTYTWO} !important`,
      },

      "& [class*='MuiList-root']": {
        padding: '17px 0px',

        "& span[class*='MuiTypography-caption']": {
          color: colors.BLACK_THIRTEEN,
        },
      },
    },

    admissionExpertCard: {
      height: '100%',
      borderRadius: 10,
      boxShadow: 'none',
      textAlign: 'center',
      position: 'relative',
      padding: '17px 15px 23px',

      '& .spanText': {
        fontWeight: 400,
      },

      "& [class*='MuiButton-contained']": {
        left: 0,
        right: 0,
        width: 93,
        minWidth: 93,
        margin: '0px auto',
        position: 'absolute',
        bottom: theme.spacing(2),
      },

      "& [class*='MuiTypography-h5']": {
        marginTop: 12,
        fontWeight: 700,
        letterSpacing: '-0.252px',
        fontFamily: fonts.POPPINS_FONT,
      },

      "& [class*='MuiTypography-caption']": {
        fontWeight: 400,
        display: 'block',
        letterSpacing: '-0.072px',
        color: colors.BLACK_FOURTEEN,
      },

      '& .graduateSchool': {
        minHeight: 32,
      },

      "& [class*='MuiDivider-root']": {
        marginTop: 11,
        background: colors.WHITE_SEVEN,
      },

      "& [class*='MuiList-root']": {
        minHeight: 48,
        padding: '17px 0px 0px',

        "& [class*='MuiTypography-caption']": {
          color: colors.GRAY_TWENTYTWO,

          '& .anchorText': {
            fontSize: 12,
            cursor: 'pointer',
            display: 'inline-block',
            color: colors.BLACK_THIRTEEN,
            borderBottom: `1px solid ${colors.BLACK_THIRTEEN}`,
          },
        },
      },

      "& [class*='MuiListItem-root']": {
        padding: 0,
        marginBottom: 8,

        '&:last-of-type': {
          margin: 0,
        },
      },

      "& [class*='MuiListItemText-root']": {
        margin: 0,
      },

      "& [class*='MuiListItemIcon-root']": {
        minWidth: 26,
      },

      '& .collegeLogoBadge': {
        top: 0,
        right: 24,
        width: 45,
        height: 75,
        padding: '0px 6px 16px',
        position: 'absolute',
        borderRadius: '0px 0px 10px 10px',
      },

      '& .collegeLogo': {
        filter: `drop-shadow(0px 4px 4px ${colors.BOX_SHADOW_TWO})`,
      },
    },

    listContainer: {
      marginBottom: 50,
      position: 'relative',
    },

    plusFormerAdmissionOfficer: {
      marginTop: 12,
      borderRadius: 15,
      border: `1px solid ${colors.WHITE_SEVEN}`,

      '& .spanText': {
        fontWeight: 400,
      },

      "& [class*='MuiTypography-caption']": {
        letterSpacing: '0.072px',
        color: `${colors.GRAY_TWENTYTWO} !important`,
      },

      "& [class*='MuiTypography-h5']": {
        fontSize: 16,
        letterSpacing: '-0.176px',
        color: colors.BLACK_TWELVE,
      },
    },

    updateProfile: {
      display: 'flex',
      borderRadius: 15,
      marginBottom: 35,
      alignItems: 'flex-start',
      padding: '16px 16px 20px 16px',
      background: colors.WHITE_EIGHT,

      '& img': {
        marginRight: 20,
      },

      "& [class*='MuiTypography-h4']": {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '24px',
      },

      "& [class*='MuiTypography-body1']": {
        fontSize: 14,
        fontWeight: 400,
        margin: '4px 0px 12px',
        letterSpacing: '-0.006em',
        color: colors.BLACK_FOURTEEN,
      },

      "& [class*='MuiIconButton-root']": {
        padding: 6,
      },
    },

    languageSelect: {
      '& .react-select-container': {
        '& .react-select__indicators': {
          alignItems: 'center',
        },

        '& .react-select__placeholder': {
          opacity: 0.8,
          fontFamily: fonts.INTER,
          color: colors.BLACK_ALPHA,
        },

        '& .react-select__value-container': {
          height: 42,
          paddingLeft: 16,
          alignItems: 'baseline',
        },

        '& .react-select__indicator': {
          '& svg': {
            width: 16,
          },
        },

        '& .react-select__control': {
          minHeight: 44,
          maxHeight: 44,
          paddingRight: 4,
        },

        '& .react-select__multi-value': {
          marginTop: 6,
        },
      },
    },

    smartMatchComplete: {
      "& [class*='MuiTypography-body1']": {
        '& span': {
          fontSize: 14,
          fontWeight: 400,
          fontFamily: fonts.INTER,
          letterSpacing: '-0.084px',
          color: colors.BLUE_SEVEN,
          borderBottom: `1px solid ${colors.BLUE_SEVEN}`,
        },
      },

      "& [class*='MuiDivider-root']": {
        marginBottom: 25,
        background: colors.WHITE_SEVEN,
      },
    },

    completeSmartMatchBox: {
      marginBottom: '22px !important',
    },

    smartMatchOverflowY: {
      "& [class*='MuiDialog-paper'], & [class*='MuiDialogContent-root']": {
        overflowY: 'auto !important',
        overflowX: 'hidden !important',
      },
    },

    advisorProfileBadge: {
      top: -6,
      right: '-20px',
      position: 'absolute',

      [theme.breakpoints.down('xs')]: {
        right: 0,
        top: -5,
      },

      '& img': {
        position: 'relative',
        width: 48,
      },

      '& .badgeRoleText': {
        top: 9,
        right: 6,
        fontSize: 12,
        fontWeight: 'bold',
        position: 'absolute',
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        transform: 'rotate(4.88deg)',
        fontFamily: fonts.POPPINS_FONT,
        color: `${colors.WHITE_ONE} !important`,
      },
    },

    advisorProfileBadgeFAO: {
      top: 20,
      right: 30,
    },

    flipCardWrapper: {
      perspective: "1000px",
      backgroundColor: "transparent",
      transition: 'transform 0.5s ease',
    },

    flipCard: {
      position: "relative",
      width: "100%",
      height: "100%",
      textAlign: "center",
      transition: "transform 0.6s",
      transformStyle: "preserve-3d",
      
    },
    
    flipped: {
      transform: 'rotateY(180deg)',
    },

    advisorCard: {
      minHeight: 340,
      position: "absolute",
      width: "100%",
      height: "fit-content",
      backfaceVisibility: "hidden",
    },

    advisorCardBio: {
      padding: '17px 23px 23px',
      cursor: "pointer",
      transform: "rotateY(180deg)",
      overflowY: "auto",
      "&::-webkit-scrollbar-thumb": {
        background: colors.GRAY_EIGHT,
      },
      "&::-webkit-scrollbar-thumb:hover": {
        cursor: 'default'
      }
    },

    flipButton: {
      position: "absolute",
      top: "2%",
      left: "2%",
      minWidth: 'unset', 
      minHeight: 'unset'
    },

    shortBio: {
      marginTop: "4%",
      fontSize: "small",
      textAlign: "left",
      [theme.breakpoints.down('md')]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: "16px",
      },
    },

    shortBioNotAvailable: {
      width: "100%",
      backgroundColor: colors.GRAY_BOREDER,
      padding: "4px 6px",
      borderRadius: "5px"
    },

    iconCenter: {
      margin: "0 !important",
    }

  })
);
