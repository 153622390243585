import {
  Box,
  Button,
  Card,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { useState } from 'react';

import fonts from '../../../common/fonts';

import { useHistory } from 'react-router-dom';
import { preMeetingTexts } from '../../../common/constants';
import LINK_COPY_ICON from '../../../img/link-copied-icon.png';
import LINK_ICON from '../../../img/link-copy.png';
import VideoMeetingIcon from '../../../img/video-meeting-icon.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    preMeetingCard: {
      background: '#EBF3FE',
      borderRadius: 15,
      padding: 20,
      marginBottom: 5,
      border: 0,
      boxShadow: 'none',

      [theme.breakpoints.down('sm')]: {
        padding: '20px 16px',
      },

      '& .iconBox': {
        [theme.breakpoints.down('sm')]: {
          width: 41,
        },
      },

      '& .meetingTitle': {
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        textTransform: 'capitalize',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .meetingSubTitle': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        marginBottom: 20,
      },

      '& .meetingSubTitleSpecialist': {
        margin: '12px 0px 0px',

        '& a': {
          color: '#7D7F7F',
        },
      },

      '& .meetingDate': {
        fontWeight: 600,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#2F80ED',
        margin: '20px 0px 22px',
        fontFamily: fonts.INTER,
      },
    },

    LinkButtonText: {
      textTransform: 'capitalize',
    },

    linkFormCopied: {
      background: '#2668C1 !important',
    },

    joinMeeting: {
      minWidth: '156px !important',
    },

    linkForm: {
      minWidth: 215,
      height: 32,
      background: '#2F80ED',
      borderRadius: 12,
      fontWeight: 700,
      fontSize: 14,
      letterSpacing: '-0.006em',
      color: '#FFFFFF',
      fontFamily: fonts.POPPINS_FONT,

      '&:hover': {
        backgroundColor: '#2668C1',
      },

      '& img': {
        marginRight: 10,
      },
    },
  })
);

type preMeetingPropsType = {
  meetingStart: boolean;
  followUp: boolean;
  dateTime: string;
  link: string;
  specialistName?: string;
  cancelled?: boolean;
  completed?: boolean;
  phoneNumber?: string;
  email?: string;
  coordinator?: {
    name: string;
    email: string;
    phone: string;
  } | null;
};

export default function PreMeeting({
  meetingStart,
  followUp,
  specialistName,
  dateTime,
  link,
  cancelled,
  completed,
  phoneNumber,
  email,
  coordinator,
}: preMeetingPropsType) {
  const classes = useStyles();
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const history = useHistory();

  const coordinatorEmail = coordinator?.email;
  const coordinatorPhone = coordinator?.phone;
  const renderHeading = () => {
    if (cancelled) {
      return preMeetingTexts.meetingCancel;
    } else if (completed) {
      return preMeetingTexts.meetingEnd;
    } else if (meetingStart) {
      return preMeetingTexts.meetingJoin;
    } else if (followUp) {
      return `Your upcoming follow-up meeting ${
        specialistName ? 'with ' + specialistName : ''
      }`;
    } else return preMeetingTexts.upcomingMeeting;
  };
  const renderText = () => {
    if (cancelled) {
      return preMeetingTexts.meetingRescheduleText;
    } else if (meetingStart) {
      return preMeetingTexts.meetingJoinText;
    } else if (completed) {
      return preMeetingTexts.meetingEndText;
    } else return preMeetingTexts.upcomingMeetingText;
  };
  return (
    <Card className={classes.preMeetingCard}>
      <Box display="flex" alignItems="flex-start">
        <Box paddingTop="2px" className="iconBox">
          <img src={VideoMeetingIcon} alt="meeting" />
        </Box>

        <Box paddingLeft="20px">
          <Typography variant="h6" className="meetingTitle">
            {renderHeading()}
          </Typography>
          <Typography variant="body1" className="meetingSubTitle">
            {renderText()}
          </Typography>
          {!cancelled && !completed && (
            <Typography variant="h5" className="meetingDate">
              {dateTime}
            </Typography>
          )}

          {meetingStart && (
            <Button
              variant="contained"
              className={clsx(classes.linkForm, classes.joinMeeting)}
              onClick={() => {
                window.location.href = link;
              }}
            >
              Join Meeting
            </Button>
          )}

          {!meetingStart && !cancelled && !completed && (
            <Button
              onClick={() => {
                setIsLinkCopied(true);
                setTimeout(() => {
                  setIsLinkCopied(false);
                }, 1000);
                navigator.clipboard.writeText(link);
              }}
              variant="contained"
              className={
                isLinkCopied
                  ? clsx(classes.linkForm, classes.linkFormCopied)
                  : classes.linkForm
              }
            >
              {isLinkCopied ? (
                <span className={classes.LinkButtonText}>
                  <img src={LINK_ICON} alt="link" />
                  Link Copied
                </span>
              ) : (
                <span className={classes.LinkButtonText}>
                  <img src={LINK_COPY_ICON} alt="link" />
                  Copy Meeting Link
                </span>
              )}
            </Button>
          )}

          {cancelled && (
            <Button
              variant="contained"
              className={clsx(classes.linkForm, classes.joinMeeting)}
              onClick={() => {
                history.push('/schedule');
              }}
            >
              Reschedule Meeting
            </Button>
          )}
          {completed && (
            <Button
              variant="contained"
              className={clsx(classes.linkForm, classes.joinMeeting)}
              onClick={() => {
                history.push('/client-enrollment');
              }}
            >
              Select Your Package
            </Button>
          )}
          {((!meetingStart && !cancelled && !completed && followUp) ||
            completed) &&
            specialistName &&
            phoneNumber &&
            email && (
              <Typography
                variant="body1"
                className="meetingSubTitle meetingSubTitleSpecialist"
              >
                Any questions? Reach out to your Specialist, {specialistName} at{' '}
                <a href={`tel:${phoneNumber}`}>{phoneNumber}</a> or{' '}
                <a href={`mailto:${email}`}>{email}</a>.
              </Typography>
            )}
          {!followUp &&
            coordinatorPhone &&
            coordinatorEmail &&
            !meetingStart &&
            !cancelled &&
            !completed && (
              <Typography
                variant="body1"
                className="meetingSubTitle meetingSubTitleSpecialist"
              >
                Any questions? Please contact us at {coordinatorPhone} or{' '}
                {coordinatorEmail}.
              </Typography>
            )}
        </Box>
      </Box>
    </Card>
  );
}
