import { FC, useState } from 'react';

import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';

import {
  DeleteUserCreditCardMutation,
  DeleteUserCreditCardMutationVariables,
} from './__generated__/DeleteUserCreditCardMutation';
import { deleteCardModalStyles } from './styles';

const DELETE_USER_CREDIT_CARD_MUTATION = gql`
  mutation DeleteUserCreditCardMutation($cardId: String!) {
    deleteCreditCard(input: { cardId: $cardId }) {
      status
      message
    }
  }
`;

interface Props {
  open: boolean;
  onClose: () => void;
  id: string;
  refetch: Function;
}

const DeleteCardModal: FC<Props> = ({ open, onClose, id, refetch }) => {
  const classes = deleteCardModalStyles();
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableBackdropClick={true}
        className={classes.deleteDialog}
      >
        <Typography variant="h5" className={classes.dialogHeading}>
          Delete Payment Method?
        </Typography>

        <DialogContent>
          <Typography variant="body1" className={classes.actionDescription}>
            This action cannot be undone.
          </Typography>
        </DialogContent>

        <DialogActions className={classes.dialogActionRoot}>
          <Box width="100%">
            <Mutation<
              DeleteUserCreditCardMutation,
              DeleteUserCreditCardMutationVariables
            >
              mutation={DELETE_USER_CREDIT_CARD_MUTATION}
            >
              {(deleteCardMutation: Function) => {
                return (
                  <Button
                    variant="contained"
                    color="primary"
                    className="dangerButton"
                    onClick={async () => {
                      try {
                        setLoading(true);
                        await deleteCardMutation({
                          variables: {
                            cardId: id,
                          },
                        });
                        await refetch();
                        setLoading(false);
                      } catch (error) {
                        console.log('error: ', error);
                        setLoading(false);
                      }
                      onClose();
                    }}
                    disabled={loading}
                  >
                    Delete
                  </Button>
                );
              }}
            </Mutation>
            <Button onClick={onClose} variant="outlined" className="ml-3">
              Keep Method
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteCardModal;
