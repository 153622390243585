import { FC, useEffect } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Box, Typography, Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import IntakeFormStepBar from './IntakeFormStepBar';
import IntakeFormQuestion from './IntakeFormQuestion';
import {
  checkIfDisable,
  intakeFormQuestionsForParent,
  intakeFormQuestionsForStudent,
} from './Questions/static';

import Colors from '../../../common/colors';
import client from '../../../apollo';

import { SAVE_INTAKE_RESPONSE } from './gql';
import { getUserFromIntakeTokenQuery_getUserFromIntakeToken } from './gql/__generated__/getUserFromIntakeTokenQuery';
import { CREATE_STUDENT_APPLICATION_MUTATION } from '../shared/colleges/AddCollegeForm';
import { UPDATE_STUDENT_ACCOUNT_MUTATION } from '../../../components/TimeZoneDialog';

const useStyles = makeStyles(() =>
  createStyles({
    buttonsContainer: {
      paddingTop: 50,

      '& button': {
        minWidth: 140,
      },
    },
  })
);
interface Props {
  totalSteps: number;
  currentStep: number;
  setCurrentStep: Function;
  stepHeading: string;
  isContinue?: boolean;
  token: string;
  initialValues: any;
  role: string;
  user: getUserFromIntakeTokenQuery_getUserFromIntakeToken;
}
interface innerQuestionInterface {
  question: string;
  options: (string | never)[];
  type: string;
}
export interface questionsInterface {
  question: string;
  extraNote?: string;
  options: (string | never)[];
  type: string;
  innerQuestion: (innerQuestionInterface | never)[];
  query?: string;
  required: boolean;
  className?: string;
  classNameStyle?: string;
  classNameCaption?: string;
}
export interface selectOption {
  value: string;
  label: string;
}
interface collegesInterface extends selectOption {
  id: number;
}
interface StudentInfoInterface {
  email: string;
  highSchoolYear?: number;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
}
interface ParentInfoInterface {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
}

const IntakeFormStepper: FC<Props> = ({
  totalSteps,
  currentStep,
  setCurrentStep,
  stepHeading,
  isContinue,
  token,
  initialValues,
  role,
  user,
}) => {
  const classes = useStyles();
  const Questions =
    role === 'Student'
      ? intakeFormQuestionsForStudent
      : intakeFormQuestionsForParent;
  const schema = Questions[currentStep - 1].data;
  const obj: any = {};
  schema.map((item: questionsInterface) => {
    const question = item.question.replaceAll('.', '');
    obj[question] =
      item.type === 'email'
        ? Yup.string().required('Required').email('Email must be valid email')
        : item.required &&
          item.type !== 'async-multi-select' &&
          item.type !== 'async-select' &&
          item.type !== 'simple-select' &&
          item.type !== 'simple-multi-select' &&
          item.type !== 'email'
        ? Yup.string().required('Required')
        : item.required &&
          (item.type === 'async-multi-select' ||
            item.type === 'async-select' ||
            item.type === 'simple-select' ||
            item.type === 'simple-multi-select')
        ? Yup.array().min(1, 'Select at least one option').required('Required')
        : item.type === 'async-multi-select' ||
          item.type === 'async-select' ||
          item.type === 'simple-select' ||
          item.type === 'simple-multi-select'
        ? Yup.array()
        : Yup.string();
    item.innerQuestion.length &&
      item.innerQuestion
        .filter(
          (innerQuestion) =>
            !innerQuestion.question.includes('Language Preference')
        )
        ?.map(
          (inner) =>
            (obj[inner.question] = Yup.string().when(`${question}`, {
              is: (ans: string | [selectOption]) => {
                if (Array.isArray(ans))
                  return ans.find((item) => item.value === 'other');
                return (
                  (question !== 'Is this your cell phone number?' &&
                    (ans === 'Yes' || ans === 'other')) ||
                  (question === 'Is this your cell phone number?' &&
                    ans === 'No')
                );
              },
              then: Yup.string().required('Required'),
            }))
        );
  });

  const IntakeFormSchema = Yup.object().shape(obj);

  useEffect(() => window.scrollTo(0, 0), [currentStep]);

  return (
    <Box component="section" maxWidth={550} margin="auto" px={1}>
      <IntakeFormStepBar
        totalSteps={totalSteps}
        currentStep={currentStep}
        stepHeading={stepHeading}
        isContinue={isContinue}
      />

      <Box pb={4} color={Colors.BLACK_TEN}>
        <Typography color="inherit" variant="body2">
          {Questions[currentStep - 1].title}
        </Typography>
      </Box>

      <Formik
        initialValues={initialValues || {}}
        validationSchema={IntakeFormSchema}
        onSubmit={async (variables, { setSubmitting }) => {
          console.log('variables', variables);
          try {
            setSubmitting(true);
            const formData = JSON.stringify({
              ...variables,
              step: variables?.step
                ? variables.step < currentStep
                  ? currentStep
                  : variables.step
                : currentStep,
            });
            await client.mutate({
              mutation: SAVE_INTAKE_RESPONSE,
              variables: { formData, token },
            });
            if (user.roles?.includes('Student') && currentStep === 6) {
              const fullName: string = variables['What is Your Full Name?'];
              const index = fullName.lastIndexOf(' ');
              const firstName = fullName.slice(0, index);
              const lastName = index !== -1 ? fullName.slice(index + 1) : '';
              let studentInfo: StudentInfoInterface = {
                firstName,
                lastName,
                email: variables['What is Your Email Address?'],
                // highSchoolYear: variables['Grad Year'],
              };
              if (variables['What is Your Phone Number?']) {
                studentInfo = {
                  ...studentInfo,
                  phoneNumber: variables['What is Your Phone Number?'],
                };
              }
              await client.mutate({
                mutation: UPDATE_STUDENT_ACCOUNT_MUTATION,
                variables: { ...studentInfo, intakeToken: token },
              });
              const colleges =
                variables[
                  'Are there any colleges you are currently considering?'
                ];
              if (colleges?.length) {
                const collegeIds = colleges.map(
                  (item: collegesInterface) => item.id
                );
                await client.mutate({
                  mutation: CREATE_STUDENT_APPLICATION_MUTATION,
                  variables: { collegeIds, intakeToken: token },
                });
              }
            } else if (user.roles?.includes('Parent') && currentStep === 3) {
              const fullName: string = variables['What is Your Full Name?'];
              const index = fullName.lastIndexOf(' ');
              const firstName = fullName.slice(0, index);
              const lastName = index !== -1 ? fullName.slice(index + 1) : '';
              let parentInfo: ParentInfoInterface = {
                firstName,
                lastName,
                email: variables['What is Your Email Address?'],
              };
              if (variables['What is Your Phone Number?']) {
                parentInfo = {
                  ...parentInfo,
                  phoneNumber: variables['What is Your Phone Number?'],
                };
              }
              await client.mutate({
                mutation: UPDATE_STUDENT_ACCOUNT_MUTATION,
                variables: { ...parentInfo, intakeToken: token },
              });
            }

            setSubmitting(false);
            setCurrentStep(currentStep + 1);
          } catch (ex) {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values, errors, setFieldValue }) => {
          const isNotValid = checkIfDisable(
            values,
            Questions[currentStep - 1].data
          );
          return (
            <Form autoComplete="off">
              <Box minHeight="calc(100vh - 460px)">
                {Questions[currentStep - 1].data.map(
                  (item: questionsInterface, index: number) => (
                    <IntakeFormQuestion
                      key={`${index + 1} question `}
                      questionData={item}
                      setFieldValue={setFieldValue}
                      values={values}
                      questionNumber={index + 1}
                      user={user}
                    />
                  )
                )}
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                className={classes.buttonsContainer}
              >
                <Box mr={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setCurrentStep(currentStep - 1)}
                    disabled={currentStep === 1 ? true : false}
                  >
                    Previous
                  </Button>
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={
                    isNotValid ||
                    isSubmitting ||
                    !!Object.entries(errors).length
                  }
                  className="blueBtnHover"
                >
                  {(user?.roles?.includes('Student') && currentStep === 6) ||
                  (user?.roles?.includes('Parent') && currentStep === 3)
                    ? 'Submit'
                    : 'Next'}
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default IntakeFormStepper;
