import { useEffect, useState } from 'react';
import { Mutation } from 'react-apollo';
import { Formik, Form } from 'formik';
import {
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  ThemeProvider,
} from '@material-ui/core';

import { useStyles } from './styles';
import MBAStudentInformation from './MBAStudentInformation';
import { SignupMBAUserSchema } from '../login/LeadRegistration/lib/validationSchemas';
import { cjConfig, handleMutationError } from '../../common/utility';
import { ErrorFocus } from '../login/LeadRegistration/lib';
import MBA_LOGO from '../../img/logo-us-news.png';
import {
  CreateLeadAccountMutation,
  CreateLeadAccountMutationVariables,
} from '../login/LeadRegistration/gql/__generated__/CreateLeadAccountMutation';

import { sha256 } from 'js-sha256';
import {
  PARTNER_SUMMER_OPPORTUNITY,
  PARTNER_WEBINAR_REGISTRATION,
} from '../../constants';
import { customTheme } from '../MaterializeCss';
import { CREATE_MBA_ACCOUNTS_MUTATION } from '../../graphql/mutations/mbaGraduateProgram';

const GradMBARegistration = ({
  partnerKey,
  setLoginView,
  showBackButton,
  setCreatedLeadId,
  setLeadViewData,
  toggleAlert,
  setPasswordSetView,
  title,
  isLoading,
  setIsLoading,
  initialValuesForMBA,
  setInitialValuesForMBA,
}: {
  title?: string;
  partnerKey?: string;
  setLoginView: Function;
  showBackButton?: Function;
  setCreatedLeadId: Function;
  setLeadViewData: Function;
  toggleAlert: Function;
  setPasswordSetView: Function;
  setIsLoading: Function;
  isLoading: boolean;
  initialValuesForMBA: any;
  setInitialValuesForMBA: Function;
}): JSX.Element => {
  const classes = useStyles();
  const [yearValue, setYearValue] = useState(
    initialValuesForMBA.undergraduateGradYear
  );
  let salesRegitration = false;
  if (window.location.pathname.includes('sales')) {
    salesRegitration = true;
  }
  const [openDialog, setOpenDialog] = useState(salesRegitration);
  const [keyValue, setKeyValue] = useState('');

  const handleCloseDialog = () => {
    if (process.env.REACT_APP_SALES_REGISTRATION_AUTH === keyValue)
      setOpenDialog(false);
    setKeyValue('');
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const currentdate = new Date();
  const currentYear = currentdate.getFullYear();

  useEffect(() => {
    cjConfig({});
    if (params.StudentParent === 'Student') {
      if (
        parseInt(params.GradYear) > currentYear + 6 ||
        parseInt(params.GradYear) < currentYear
      ) {
        setYearValue('Other');
      } else {
        setYearValue(params.GradYear);
      }
      setInitialValuesForMBA({
        ...initialValuesForMBA,
        studentFirstName: params.firstname,
        studentLastName: params.lastname,
        studentEmail: params.email,
        studentPhone:
          params.phone[0] === '+' ? params.phone : '+1' + params.phone,
        undergraduateGradYear: params.GradYear,
      });
    }
  }, []);

  return (
    <>
      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        className={classes.authDialog}
      >
        <DialogTitle id="form-dialog-title">Authentication</DialogTitle>
        <DialogContent>
          <DialogContentText>Password.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="auth-key"
            label="Key"
            type="text"
            fullWidth
            onChange={(e) => setKeyValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCloseDialog}>
            Authenticate
          </Button>
        </DialogActions>
      </Dialog>

      <Mutation<CreateLeadAccountMutation, CreateLeadAccountMutationVariables>
        mutation={CREATE_MBA_ACCOUNTS_MUTATION}
      >
        {(createLeadAccountMutation: Function) => {
          return (
            <Formik
              enableReinitialize
              initialValues={{ ...initialValuesForMBA }}
              validationSchema={SignupMBAUserSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={async (values, { setSubmitting }) => {
                setIsLoading(true);
                let _partnerKey = partnerKey;
                const params = new URLSearchParams(window.location.search);
                const _goback = params.get('_goback');
                const webinar = params.get('webinar');
                if (
                  _goback &&
                  (_goback.includes('opportunity-detail') ||
                    _goback.includes('extracurricular-opportunities'))
                ) {
                  _partnerKey = PARTNER_SUMMER_OPPORTUNITY;
                }
                if (
                  _goback &&
                  (_goback.includes('webinar') || _goback.includes('webinars'))
                ) {
                  _partnerKey = PARTNER_WEBINAR_REGISTRATION;
                }
                try {
                  setInitialValuesForMBA({ ...values });
                  const variables: any = {
                    ...values,
                    userRole: 'Student',
                    partnerKey: _partnerKey,
                    salesRegitration,
                    registrationType: 'Product Registration',
                  };
                  if (
                    _goback &&
                    (_goback.includes('webinar') ||
                      _goback.includes('webinars'))
                  ) {
                    Object.assign(variables, { webinar: webinar });
                  }
                  let _params = window.localStorage.user_analytics_details;
                  if (_params) {
                    _params = JSON.parse(_params);
                    variables.userAnalyticParams = {
                      ..._params,
                      utm_source:
                        _params?.utm_source ||
                        localStorage.getItem('utm_source'),
                      first_submission_url:
                        _params._fsu ||
                        `${window.location.origin}${_params._goback}` ||
                        `${window.location.origin}${window.location.pathname}`,
                    };
                  }
                  const result = await createLeadAccountMutation({
                    variables,
                  });
                  if (window.location.host === 'app.collegeadvisor.com') {
                    console.log('Triggering CUSTOM_EVENT_1...');
                    setTimeout(() => {
                      if (window.snaptr) {
                        console.log(
                          '%c Triggering snaptr init...',
                          'color: Green'
                        );
                        window.snaptr(
                          'init',
                          process.env.REACT_APP_SNAPCHAT_PIXEL_API,
                          {
                            user_phone_number: values.studentPhone,
                            user_email: values.studentEmail,
                          }
                        );
                        window.snaptr('track', 'CUSTOM_EVENT_1');
                        window.snaptr('track', 'SIGN_UP');
                      }
                      if (window.fbq) {
                        window.fbq('trackCustom', 'HighQualityLead');
                        window.fbq('trackCustom', 'Lead');
                      }
                      console.log('window.gtag::::', window.gtag);
                      if (window.gtag) {
                        console.log('triggering event_conversion for gtag...');
                        window.gtag('event', 'conversion', {
                          send_to: 'AW-724553338/gw_OCKjXwv0BEPqcv9kC',
                        });
                      }
                      if (window.ttq) {
                        console.log(
                          '%c Triggering ttq indentify...',
                          'color: Green'
                        );
                        window.ttq.identify({
                          external_id: result?.data?.createLeadAccount?.LeadId,
                          sha256_email: sha256(values.studentEmail),
                          sha256_phone_number: sha256(values.studentPhone),
                        });
                        window.ttq
                          .instance('C2CJLF7MU8Q03RAIPRC0')
                          .track('SubmitForm');
                        console.log('triggering tik tok event...');
                        window.ttq
                          .instance('C1LLB3HT0U322RQQ6J10')
                          .track('SubmitForm');
                      }
                    }, 0);
                  } else {
                    console.log(
                      'Non production env. Not triggering custom events...'
                    );
                  }
                  const {
                    data: {
                      createMBAAccount: {
                        redirectToLogin,
                        redirectToPassword,
                        LeadId,
                      },
                    },
                  } = result;

                  if (redirectToLogin && !salesRegitration) {
                    setLoginView(true);
                    showBackButton && showBackButton(true);
                  }
                  if (redirectToPassword && !salesRegitration) {
                    setLeadViewData(values);
                    setCreatedLeadId(LeadId);
                    setPasswordSetView(true);
                    showBackButton && showBackButton(true);
                  }
                  if (redirectToPassword && salesRegitration) {
                    toggleAlert('success', 'Student and Parent is Created.');
                  }
                  if (redirectToLogin && salesRegitration) {
                    toggleAlert('danger', 'User Already Exist.');
                  }

                  setIsLoading(false);
                  setSubmitting(false);
                } catch (error: any) {
                  setSubmitting(false);
                  setIsLoading(false);
                  handleMutationError(error, (errorMessage) => {
                    toggleAlert('danger', errorMessage.message);
                  });
                }
              }}
            >
              {({
                isSubmitting,
                setFieldValue,
                errors,
                touched,
                submitCount,
              }) => {
                return (
                  <Form>
                    <Box maxWidth={394} margin="0px auto">
                      <Box textAlign="center" mb={5}>
                        <img width="275px" src={MBA_LOGO} alt="logo" />
                      </Box>

                      <Box
                        maxWidth={370}
                        margin="0px auto"
                        className={classes.radioGroup}
                      >
                        <MBAStudentInformation
                          setFieldValue={setFieldValue}
                          setYearValue={setYearValue}
                          parentorStudent={'Student'}
                          yearValue={yearValue}
                          errors={errors}
                          touched={touched}
                        />

                        <ThemeProvider theme={customTheme}>
                          <Box textAlign="center" width="100%">
                            <Button
                              fullWidth
                              type="submit"
                              variant="contained"
                              onClick={() => {
                                if (
                                  window.location.host ===
                                  'app.collegeadvisor.com'
                                ) {
                                  if (window.ttq) {
                                    console.log('triggering ttq clickForm...');
                                    window.ttq
                                      .instance('C2CJLF7MU8Q03RAIPRC0')
                                      .track('ClickForm');
                                  }
                                }
                              }}
                              id="reg-continue"
                              color="secondary"
                              disabled={isSubmitting}
                            >
                              Create Account
                              {isLoading && (
                                <CircularProgress
                                  size={24}
                                  className={classes.buttonProgress}
                                />
                              )}
                            </Button>
                          </Box>
                        </ThemeProvider>
                      </Box>
                    </Box>
                    <ErrorFocus errors={errors} />
                  </Form>
                );
              }}
            </Formik>
          );
        }}
      </Mutation>
    </>
  );
};

export default GradMBARegistration;
