import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

import {
  Box,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';

import { logUserActivity } from '../../../common/utility';

import fonts from '../../../common/fonts';

import {
  UserActivityActions,
  UserActivityType,
} from '../../../common/constants';
import DIALOG_CLOSE_ICON from '../../../img/dialog-close-icon.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    demoDialog: {
      maxWidth: 950,
      margin: 0,
      position: 'absolute',
      width: '100%',
      top: '50%',
      left: '50%',
      padding: '47px 109px 20px',
      background: '#fff',
      border: '1px solid #d4d4d4',
      borderRadius: 20,
      boxShadow: '0px 4px 14px rgb(68 68 68 / 14%)',
      transform: 'translate(-50%, -50%) !important',

      '&:focus, &:visited': {
        boxShadow: 'none',
        outline: 'none',
      },

      [theme.breakpoints.down('md')]: {
        padding: '20px 16px',
        width: '95%',
      },

      '& .dailogHeading': {
        color: '#272929',
        fontSize: 36,
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 700,
        letterSpacing: '-0.022em',
        textAlign: 'center',
        lineHeight: 'initial',
        textTransform: 'inherit',

        [theme.breakpoints.down('sm')]: {
          fontSize: 28,
          paddingTop: 50,
        },

        [theme.breakpoints.down('xs')]: {
          fontSize: 20,
        },
      },

      '& .closeButton': {
        position: 'absolute',
        top: 30,
        right: 50,
        opacity: 0.4,

        [theme.breakpoints.down('md')]: {
          right: 16,
        },
      },

      '& .videoDemoHeight': {
        '& div:nth-child(1)': {
          height: '500px !important',

          [theme.breakpoints.down('xs')]: {
            height: '270px !important',
          },
        },
      },
    },
  })
);

const VideoModal = ({ setShowDemo }: { setShowDemo?: Function }) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(true);
  const handleClose = async () => {
    if (setShowDemo) setShowDemo(false);
    localStorage.setItem('firstTimeLogin', 'false');
    setOpenDialog(false);
  };

  const onVideoOpen = async () => {
    await logUserActivity({
      pageName: 'HomePage',
      action: UserActivityActions.DEMO_PLAY,
      type: UserActivityType.PRODUCT_DEMO,
      origin: '/home',
    });
  };

  const onFullWatched = async () => {
    await logUserActivity({
      pageName: 'HomePage',
      action: UserActivityActions.DEMO_WATCH,
      type: UserActivityType.PRODUCT_DEMO,
      origin: '/home',
    });
    localStorage.setItem('firstTimeLogin', 'false');
  };

  const trackDisplayDemo = async () => {
    await logUserActivity({
      pageName: 'HomePage',
      action: UserActivityActions.DEMO,
      type: UserActivityType.PRODUCT_DEMO,
      origin: '/home',
    });
    onVideoOpen();
  };

  useEffect(() => {
    trackDisplayDemo();
  }, []);

  const body = (
    <Grid className={classes.demoDialog}>
      <Box textAlign="right" className="closeButton">
        <IconButton onClick={handleClose}>
          <img src={DIALOG_CLOSE_ICON} alt="close" />
        </IconButton>
      </Box>

      <Typography variant="h6" className="dailogHeading">
        Welcome to CollegeAdvisor.com!
      </Typography>

      <Grid className="videoDemoHeight">
        <ReactPlayer
          onEnded={onFullWatched}
          playing={true}
          onStart={() => localStorage.setItem('firstTimeLogin', 'false')}
          width="100%"
          controls={true}
          url="https://vimeo.com/699449932/5e322fd7fd"
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid>
      <Modal open={openDialog} onClose={handleClose}>
        {body}
      </Modal>
    </Grid>
  );
};

export default VideoModal;
