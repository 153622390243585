import React, { useEffect, useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  ThemeProvider,
  Typography,
  withStyles,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import client from '../../../../apollo';
import { ADD_MENTOR_REVIEW_RATING } from './gql';

import { BootstrapTooltip } from '../../../../common/BootstrapTooltip';
import DRAWER_CLOSE_ICON from '../../../../img/drawer-close-icon.svg';
import FeedbackIcon from '../../../../img/feedback-icon.svg';
import RatingInfoIcon from '../../../../img/rating-info-icon.svg';
import { customTheme } from '../../../MaterializeCss';
import { useStylesRating } from './MeetingRatingStyle';

const StyledRating = withStyles({
  iconFilled: {
    color: '#FDBD4E',
    marginRight: 3,
  },

  iconEmpty: {
    color: '#EAEAEA',
    marginRight: 3,
  },
})(Rating);

const StyledRatingDialog = withStyles({
  iconFilled: {
    fontSize: 30,
    marginRight: 5,
    color: '#FDBD4E',
  },

  iconEmpty: {
    fontSize: 30,
    marginRight: 5,
    color: '#A9A9A9',
  },
})(Rating);

export const MeetingRatingFeedbackDialog = ({
  onClose,
  open,
  meetingTime,
  mentorPictureUrl,
  advisorFirstName,
  onSubmit,
  submitting,
  feedbackSubmitted = false,
  handleCloseFeedbackSubmitted,
  ratingFeedback,
}: {
  open: boolean;
  onClose: Function;
  advisorFirstName: string;
  meetingTime: string;
  mentorPictureUrl: string;
  onSubmit: Function;
  submitting: boolean;
  feedbackSubmitted?: boolean;
  ratingFeedback?: number;
  handleCloseFeedbackSubmitted?: Function;
}) => {
  const classes = useStylesRating();
  const [rating, setRating] = useState(ratingFeedback || 0);
  const [description, setDescription] = useState('');
  useEffect(() => {
    if (ratingFeedback) {
      setRating(ratingFeedback);
    }
  }, [ratingFeedback]);
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      className={classes.ratingDialog}
    >
      <DialogContent className="MuiDialogContentRoot">
        {feedbackSubmitted ? (
          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className="feedbackBox"
            >
              <Typography variant="h5" className="dialogHeadingFeedback">
                {`${meetingTime ? 'Meeting' : 'Advisor'}`} Feedback Submitted
              </Typography>

              <IconButton onClick={() => onClose()}>
                <img src={DRAWER_CLOSE_ICON} alt="close" />
              </IconButton>
            </Box>

            <Typography variant="body2" className="dialogTextFeedback">
              Thank you for your helpful feedback!
            </Typography>
          </Box>
        ) : (
          <>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                className={
                  meetingTime
                    ? 'ratingDialogHeaderMeeting ratingDialogHeader'
                    : 'ratingDialogHeader'
                }
              >
                <Avatar
                  src={mentorPictureUrl}
                  className="profileAvatar"
                ></Avatar>

                <Grid>
                  {meetingTime ? (
                    <Typography variant="h5" className="dialogHeading">
                      How was your meeting with {advisorFirstName}?
                    </Typography>
                  ) : (
                    <Typography variant="h5" className="dialogHeading">
                      Leave a Review for {advisorFirstName}.
                    </Typography>
                  )}
                  {!meetingTime && (
                    <Typography variant="body1" className="dialogSubtitle">
                      How is your experience with CollegeAdvisor.com thus far?
                      Let us know below!
                    </Typography>
                  )}
                </Grid>
              </Box>

              <IconButton
                onClick={() => onClose()}
                className="closeDialogButton"
              >
                <img src={DRAWER_CLOSE_ICON} alt="close" />
              </IconButton>
            </Box>

            {meetingTime && (
              <Typography variant="h6" className="dialogText">
                Occurred on {meetingTime}
              </Typography>
            )}

            <Grid>
              <Box
                display="flex"
                alignItems="center"
                paddingBottom={2}
                className={classes.ratingModule}
              >
                <StyledRatingDialog
                  name="customized"
                  defaultValue={ratingFeedback}
                  value={rating}
                  precision={0.5}
                  onChange={(e, newValue) => setRating(newValue || 0)}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                />

                <Typography variant="body1" className="ratingText">
                  <span>{rating}</span>/ 5
                </Typography>
              </Box>

              <Grid className="textareaField">
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  rowsMax="8"
                  rows="8"
                  placeholder={`Leave a review ${
                    meetingTime ? '(Optional)' : ''
                  }`}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
            </Grid>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body1" className="infoText">
                <img src={RatingInfoIcon} alt="info" />
                Your advisor will not be notified of this review.
              </Typography>
              <ThemeProvider theme={customTheme}>
                <Button
                  onClick={async () => {
                    await onSubmit(rating, description);
                  }}
                  variant="contained"
                  color="primary"
                  disabled={
                    submitting ||
                    (meetingTime ? !rating : !rating || !description)
                  }
                >
                  Submit
                </Button>
              </ThemeProvider>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default function MeetingRating({
  meetingTime,
  advisorFirstName,
  mentorPicture,
  review,
  studentId,
  advisorIds,
  meetingId,
  refetch,
  disabled,
}: {
  meetingTime: string;
  advisorFirstName: string;
  mentorPicture: string;
  review: any;
  studentId: number;
  advisorIds: number[];
  meetingId: number;
  refetch?: Function;
  disabled?: boolean;
}) {
  const classes = useStylesRating();

  const [open, setOpen] = React.useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [ratingFeedback, setRating] = useState(0);
  const handleClickOpen = (value: number) => {
    setOpen(true);
    setRating(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitMeetingRating = async (rating: number, description: string) => {
    try {
      setSubmitting(true);
      const response = await client.mutate({
        mutation: ADD_MENTOR_REVIEW_RATING,
        variables: {
          studentId,
          mentorIds: advisorIds,
          rating,
          description,
          reviewableId: meetingId,
          ReviewType: meetingId ? 'Meeting' : 'General',
          active: false,
        },
      });
      if (response) {
        refetch && (await refetch());
        setSubmitting(false);
        setFeedbackSubmitted(true);
      }
    } catch (err) {
      setSubmitting(false);
      setOpen(false);
      console.log('Error,', err);
    }
  };
  return (
    <Grid className={classes.rating}>
      <Box component="fieldset" borderColor="transparent">
        <Box display="flex" alignItems="center">
          {disabled && !review?.rating ? (
            <BootstrapTooltip
              placement="top"
              title="Rating a meeting is unavailable after 14 days"
            >
              <StyledRating
                name={`customized-empty-${meetingId}`}
                precision={0.5}
                emptyIcon={<StarIcon fontSize="inherit" />}
                value={0}
              />
            </BootstrapTooltip>
          ) : (
            <StyledRating
              name={`customized-empty-${meetingId}`}
              precision={0.5}
              emptyIcon={<StarIcon fontSize="inherit" />}
              disabled={review?.rating ? true : false}
              onChange={(e, newValue) => {
                if (!review || !review.rating) {
                  handleClickOpen(newValue || 0);
                }
              }}
              value={review?.rating || 0}
            />
          )}

          {review?.message ? (
            <BootstrapTooltip placement="top" title={review.message}>
              <IconButton className="feedbackButton">
                <img src={FeedbackIcon} alt="feedback" />
              </IconButton>
            </BootstrapTooltip>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <MeetingRatingFeedbackDialog
        open={open}
        onClose={handleClose}
        advisorFirstName={advisorFirstName}
        mentorPictureUrl={mentorPicture}
        meetingTime={meetingTime}
        onSubmit={(rating: number, description: string) =>
          onSubmitMeetingRating(rating, description)
        }
        submitting={submitting}
        feedbackSubmitted={feedbackSubmitted}
        handleCloseFeedbackSubmitted={() => setFeedbackSubmitted(false)}
        ratingFeedback={ratingFeedback}
      />
    </Grid>
  );
}
