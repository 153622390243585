import * as Yup from 'yup';

export const SignupSchema = ({
  parentorStudent,
  isSalesRegistration,
}: {
  parentorStudent: string;
  isSalesRegistration?: boolean;
}) => {
  const currentdate = new Date();
  const currentYear = currentdate.getFullYear();
  let yupObj: any = {
    studentFirstName: Yup.string().required('Required'),
    studentLastName: Yup.string().required('Required'),
    aidOption: Yup.number().required('Required'),
    studentEmail: Yup.string()
      .required('Required')
      .email('Email must be a valid email'),
    highSchoolYear: Yup.number()
      .min(currentYear, `Please enter value greater than ${currentYear - 1}`)
      .max(currentYear + 10, `Please enter value less than ${currentYear + 11}`)
      .required('Required')
      .nullable(),
    parentFirstName: Yup.string().when(
      'parentEmail',
      (parentEmail: any, passSchema: any) => {
        return isSalesRegistration
          ? Boolean(parentEmail)
            ? passSchema.required('Required')
            : passSchema.nullable()
          : passSchema.required('Required');
      }
    ),
    parentLastName: Yup.string().when(
      'parentEmail',
      (parentEmail: any, passSchema: any) => {
        return isSalesRegistration
          ? Boolean(parentEmail)
            ? passSchema.required('Required')
            : passSchema.nullable()
          : passSchema.required('Required');
      }
    ),
    source: Yup.string().required('Required').nullable(),
    partnerKey: Yup.string().required('Required').nullable(),
    salesPersonId: Yup.string().required('Required').nullable(),
    eventName: Yup.string().when('source', (source: any, passSchema: any) => {
      return source === 'Conference'
        ? passSchema.required('Required')
        : passSchema.nullable();
    }),
  };
  if (isSalesRegistration) {
    yupObj = { ...yupObj, parentEmail: Yup.string().nullable() };
  } else {
    yupObj = { ...yupObj, parentEmail: Yup.string().required('Required') };
  }
  return Yup.object().shape(yupObj);
};