import { FC, useContext, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Theme,
  ThemeProvider,
  Typography,
  makeStyles,
} from '@material-ui/core';

import fonts from '../../../../common/fonts';

import {
  getStudentApplicationDeadline,
  selectColor,
} from '../../../../common/utility';
import { AppContext } from '../../../../contexts';
import CLOSE_ICON from '../../../../img/dialog-close-gray.svg';
import { MenuProps } from '../../../../styles/commonComponentStyles';
import { customTheme } from '../../../MaterializeCss';
const useStyles = makeStyles((theme: Theme) => ({
  addNewEssayDialog: {
    '& .MuiDialog-paper': {
      maxWidth: 551,
      width: '100%',
      borderRadius: 25,
      margin: '0px 10px',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('xs')]: {
        margin: '0px 10px !important',
        borderRadius: 10,
      },

      '& .MuiDialogContent-root': {
        padding: '31px 34px 37px',

        [theme.breakpoints.down('xs')]: {
          padding: '20px 16px 45px',
        },
      },

      '& .MuiTypography-h4': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        color: '#000000',
      },

      '& .MuiTypography-body1': {
        fontFamily: fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#A9A9A9',
        margin: '7px 0px 21px',
        maxWidth: 400,
      },

      '& .MuiInputBase-root': {
        padding: '11px 16px',
      },

      '& .fieldCaption': {
        fontFamily: fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        display: 'block',
        marginBottom: 4,
      },
    },
  },
}));

interface Props {
  open: boolean;
  onClose: Function;
  onSubmit: Function;
  deadlines: any;
}

const AddApplicationDialog: FC<Props> = ({
  open,
  onClose,
  onSubmit,
  deadlines,
}) => {
  const [decisionStatus, setDecisionStatus] = useState<any>(
    deadlines[0]?.key || null
  );
  const { user } = useContext(AppContext);
  const classes = useStyles();
  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose(false)}
        className={classes.addNewEssayDialog}
      >
        <DialogContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4">Start New Application</Typography>

            <IconButton onClick={() => onClose(false)}>
              <img src={CLOSE_ICON} alt="close" />
            </IconButton>
          </Box>

          <Typography variant="body1">
            Select your application deadline to begin your application. We
            recommend starting out with regular decision and making adjustments
            as needed.
          </Typography>

          <Grid container style={{ alignItems: 'flex-end' }}>
            {deadlines?.length > 0 && (
              <Grid item md={8} sm={12} xs={12}>
                <Typography variant="caption" className="fieldCaption">
                  Deadline
                </Typography>

                <FormControl>
                  <ThemeProvider theme={customTheme}>
                    <Select
                      variant="outlined"
                      MenuProps={MenuProps}
                      value={decisionStatus}
                      onChange={(event) =>
                        setDecisionStatus(event.target.value)
                      }
                      className={`selectLarge ${selectColor(decisionStatus)}
                      `}
                    >
                      {deadlines &&
                        deadlines?.map((deadline: any) => (
                          <MenuItem
                            key={deadline?.key}
                            value={deadline?.key || 0}
                          >
                            <Box display="flex">
                              <span> {deadline?.display}</span>-
                              <span>
                                {getStudentApplicationDeadline(
                                  user?.highSchoolYear,
                                  deadline?.value,
                                  user?.graduated,
                                  user?.gapYear
                                )}
                              </span>
                            </Box>
                          </MenuItem>
                        ))}
                    </Select>
                  </ThemeProvider>
                </FormControl>
              </Grid>
            )}

            <Grid item md={4} sm={12} xs={12}>
              <ThemeProvider theme={customTheme}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => onSubmit(decisionStatus)}
                >
                  Start
                </Button>
              </ThemeProvider>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddApplicationDialog;
