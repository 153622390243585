import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Theme,
  makeStyles,
  createStyles,
  Container,
} from '@material-ui/core';

import { CURRENT_YEAR } from '../../common/constants';

import Fonts from '../../common/fonts';
import Colors from '../../common/colors';

import TWITTER from '../../img/twitter.svg';
import FACEBOOK from '../../img/facebook.svg';
import INSTAGRAM from '../../img/instagram.svg';
import YOUTUBE from '../../img/youtube.svg';
import LINKEDIN from '../../img/linkedin.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerWrapper: {
      background: Colors.BLUE_FIVE,
      borderTop: `2px solid ${Colors.BLUE_EIGHT}`,
      fontSize: 14,

      [theme.breakpoints.down('sm')]: {
        '& br': {
          display: 'none',
        },
      },
    },

    footerContainer: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },

    footerLinks: {
      '& a, & > div': {
        fontWeight: 600,
        letterSpacing: 2,
        color: Colors.SKYBLUE_TWO,
        margin: '0 15px',
        cursor: 'pointer',

        '&:hover': {
          textDecoration: 'none',
          opacity: 0.5,
          transition: 'all .4s ease',
        },
      },

      [theme.breakpoints.down('sm')]: {
        margin: '15px 0',
      },

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        textAlign: 'center',
      },
    },

    socialIcons: {
      '& a': {
        '&:not(:last-child)': {
          marginRight: 30,
        },

        '&:hover': {
          opacity: 0.5,
          transition: 'all .4s ease',
        },
        '& img': {
          height: 20,
        },
      },
    },
  })
);
const SOCIAL_LINKS = [
  {
    image: TWITTER,
    alt: 'twitter icon',
    link: 'https://twitter.com/_collegeadvisor',
  },
  {
    image: FACEBOOK,
    alt: 'facebook icon',
    link: 'https://facebook.com/joincollegeadvisor',
  },
  {
    image: INSTAGRAM,
    alt: 'instagram icon',
    link: 'http://instagram.com/joincollegeadvisor',
  },
  {
    image: YOUTUBE,
    alt: 'youtube icon',
    link: 'https://www.youtube.com/c/CollegeAdvisor',
  },
  {
    image: LINKEDIN,
    alt: 'linkedin icon',
    link: 'http://linkedin.com/company/joincollegeadvisor',
  },
];

const Footer: FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footerWrapper}>
      <Container maxWidth={false}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="15px 0px"
          className={classes.footerContainer}
        >
          <Box
            color={Colors.SKYBLUE_TWO}
            fontFamily={Fonts.POPPINS_FONT}
            maxWidth={250}
          >
            &copy; {CURRENT_YEAR} USN College Advisor, Inc.
            <br />
            All Rights Reserved
          </Box>

          <Box
            display="flex"
            flex={1}
            justifyContent="center"
            className={classes.footerLinks}
          >
            <div
              onClick={() => {
                if (window.Intercom) window.Intercom('show');
              }}
            >
              CONTACT
            </div>

            <Link to="/help">HELP</Link>
            <a
              href="https://collegeadvisor.com/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            <a
              href="https://www.imgacademy.com/privacy-policy?website=collegeadvisor.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            <a
              href="https://privacyportal.onetrust.com/webform/889c435d-64b4-46d8-ad05-06332fe1d097/77f406d9-cba7-49d1-b62b-0af539f8141e?website=collegeadvisor.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Do not Sell My Personal Information
            </a>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            className={classes.socialIcons}
          >
            {SOCIAL_LINKS.map((item) => (
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                key={item.alt}
              >
                <img src={item.image} alt={item.alt} loading="lazy" />
              </a>
            ))}
          </Box>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
