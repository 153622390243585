import gql from 'graphql-tag';

export const ALL_STUDENT_APPLICATIONS_QUERY = gql`
  query AllStudentApplicationsQuery($limit: Int) {
    allStudentApplications(limit: $limit) {
      StudentApplications {
        id
        decisionStatus
        decisionDeadline
        completionPercentage
        applicationRequirement {
          id
          offersEarlyAction
          offersEarlyDecision
          earlyActionDeadline
          earlyDecisionDeadline
          regularDecisionDeadline
          lastYearAdmissionRate
          college {
            id
            name
            styleConfig {
              bgDark
              bgLight
            }
            logo
          }
        }
      }
    }
  }
`;
export const ALL_BLOGS = gql`
  query allBlogsQuery($name: String, $forStudent: Boolean) {
    allBlogUrl(input: { name: $name, forStudent: $forStudent }) {
      Blogs {
        id
        Classification
        blogUrl
        text
        heading
        isFeatured
        imgUrl
      }
      count
    }
  }
`;
