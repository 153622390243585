import { Mutation } from 'react-apollo';
import { Fragment, useEffect, useState } from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import {
  Box,
  Hidden,
  Dialog,
  Button,
  Checkbox,
  TextField,
  RadioGroup,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  ThemeProvider,
  FormControlLabel,
  CircularProgress,
  DialogContentText,
} from '@material-ui/core';

import { useStyles } from '../styles';
import Colors from '../../../common/colors';
import ParentInformation from './ParentInformation';
import StudentInformation from './StudentInformation';
import { SignupSchema } from './lib/validationSchemas';
import SmallLogo from '../../../components/layout/SmallLogo';
import { cjConfig, handleMutationError } from '../../../common/utility';
import { CREATE_LEAD_ACCOUNTS_MUTATION } from './gql/index';
import { ErrorFocus, RadioButtonOption } from './lib';
import {
  MCreateLeadAccountMutation,
  MCreateLeadAccountMutationVariables,
} from './gql/__generated__/MCreateLeadAccountMutation';

import { sha256 } from 'js-sha256';
import {
  PARTNER_SUMMER_OPPORTUNITY,
  PARTNER_WEBINAR_REGISTRATION,
} from '../../../constants';
import { customTheme } from '../../MaterializeCss';
import colors from '../../../common/colors';

const NewUserForm = ({
  parentorStudent,
  partnerKey,
  setLoginView,
  showBackButton,
  setCreatedLeadId,
  setLeadViewData,
  toggleAlert,
  setPasswordSetView,
  title,
  setParentOrStudent,
  isLoading,
  setIsLoading,
  initialValues,
  setInitialValues,
  optInSMS,
  setOptInSMS,
}: {
  title?: string;
  partnerKey?: string;
  setLoginView: Function;
  showBackButton?: Function;
  setCreatedLeadId: Function;
  setLeadViewData: Function;
  toggleAlert: Function;
  setPasswordSetView: Function;
  setParentOrStudent: Function;
  parentorStudent: 'Parent' | 'Student';
  setIsLoading: Function;
  isLoading: boolean;
  initialValues: any;
  setInitialValues: Function;
  optInSMS: boolean;
  setOptInSMS: Function;
}): JSX.Element => {
  const classes = useStyles();
  const [yearValue, setYearValue] = useState(initialValues.highSchoolYear);
  let salesRegitration = false;
  if (window.location.pathname.includes('sales')) {
    salesRegitration = true;
  }
  const [openDialog, setOpenDialog] = useState(salesRegitration);
  const [keyValue, setKeyValue] = useState('');

  const handleCloseDialog = () => {
    if (process.env.REACT_APP_SALES_REGISTRATION_AUTH === keyValue)
      setOpenDialog(false);
    setKeyValue('');
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const currentdate = new Date();
  const currentYear = currentdate.getFullYear();

  useEffect(() => {
    cjConfig({});
    if (params.StudentParent === 'Student') {
      if (
        parseInt(params.GradYear) > currentYear + 6 ||
        parseInt(params.GradYear) < currentYear
      ) {
        setYearValue('Other');
      } else {
        setYearValue(params.GradYear);
      }
      setInitialValues({
        ...initialValues,
        studentFirstName: params.firstname,
        studentLastName: params.lastname,
        studentEmail: params.email,
        studentPhone:
          params.phone[0] === '+' ? params.phone : '+1' + params.phone,
        highSchoolYear: params.GradYear,
        optInSMS,
      });
    }
  }, []);

  return (
    <>
      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        className={classes.authDialog}
      >
        <DialogTitle id="form-dialog-title">Authentication</DialogTitle>
        <DialogContent>
          <DialogContentText>Password.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="auth-key"
            label="Key"
            type="text"
            fullWidth
            onChange={(e) => setKeyValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCloseDialog}>
            Authenticate
          </Button>
        </DialogActions>
      </Dialog>

      <Mutation<MCreateLeadAccountMutation, MCreateLeadAccountMutationVariables>
        mutation={CREATE_LEAD_ACCOUNTS_MUTATION}
      >
        {(createLeadAccountMutation: Function) => {
          return (
            <Formik
              enableReinitialize
              initialValues={{ ...initialValues }}
              validationSchema={SignupSchema({
                parentorStudent,
              })}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={async (values, { setSubmitting }) => {
                setIsLoading(true);
                let _partnerKey = partnerKey;
                const params = new URLSearchParams(window.location.search);
                const _goback = params.get('_goback');
                const webinar = params.get('webinar');
                if (
                  _goback &&
                  (_goback.includes('opportunity-detail') ||
                    _goback.includes('extracurricular-opportunities'))
                ) {
                  _partnerKey = PARTNER_SUMMER_OPPORTUNITY;
                }
                if (
                  _goback &&
                  (_goback.includes('webinar') || _goback.includes('webinars'))
                ) {
                  _partnerKey = PARTNER_WEBINAR_REGISTRATION;
                }
                try {
                  setInitialValues({ ...values });
                  const variables: any = {
                    ...values,
                    userRole: parentorStudent,
                    partnerKey: _partnerKey,
                    salesRegitration,
                    registrationType: 'Product Registration',
                    optInSMS: values.optInSMS,
                  };
                  if (
                    _goback &&
                    (_goback.includes('webinar') ||
                      _goback.includes('webinars'))
                  ) {
                    Object.assign(variables, { webinar: webinar });
                  }
                  let _params = window.localStorage.user_analytics_details;
                  if (_params) {
                    _params = JSON.parse(_params);
                    variables.userAnalyticParams = {
                      ..._params,
                      utm_source:
                        _params?.utm_source ||
                        localStorage.getItem('utm_source'),
                      first_submission_url:
                        _params._fsu ||
                        `${window.location.origin}${_params._goback}` ||
                        `${window.location.origin}${window.location.pathname}`,
                    };
                  }
                  const result = await createLeadAccountMutation({
                    variables,
                  });
                  if (window.location.host === 'app.collegeadvisor.com') {
                    console.log('Triggering CUSTOM_EVENT_1...');
                    setTimeout(() => {
                      if (window.snaptr) {
                        console.log(
                          '%c Triggering snaptr init...',
                          'color: Green'
                        );
                        window.snaptr(
                          'init',
                          process.env.REACT_APP_SNAPCHAT_PIXEL_API,
                          {
                            user_phone_number:
                              values.studentPhone || values.parentPhone,
                            user_email: values.studentEmail,
                          }
                        );
                        window.snaptr('track', 'CUSTOM_EVENT_1');
                        window.snaptr('track', 'SIGN_UP');
                      }
                      if (window.fbq) {
                        window.fbq('trackCustom', 'HighQualityLead');
                        window.fbq('trackCustom', 'Lead');
                      }
                      console.log('window.gtag::::', window.gtag);
                      if (window.gtag) {
                        console.log('triggering event_conversion for gtag...');
                        window.gtag('event', 'conversion', {
                          send_to: 'AW-724553338/gw_OCKjXwv0BEPqcv9kC',
                        });
                      }
                      if (window.ttq) {
                        console.log(
                          '%c Triggering ttq indentify...',
                          'color: Green'
                        );
                        window.ttq.identify({
                          external_id: result?.data?.createLeadAccount?.LeadId,
                          sha256_email: sha256(values.studentEmail),
                          sha256_phone_number: sha256(
                            values.studentPhone || values.parentPhone
                          ),
                        });
                        window.ttq
                          .instance('C2CJLF7MU8Q03RAIPRC0')
                          .track('SubmitForm');
                        console.log('triggering tik tok event...');
                        window.ttq
                          .instance('C1LLB3HT0U322RQQ6J10')
                          .track('SubmitForm');
                      }
                    }, 0);
                  } else {
                    console.log(
                      'Non production env. Not triggering custom events...'
                    );
                  }
                  const {
                    data: {
                      createLeadAccount: {
                        redirectToLogin,
                        redirectToPassword,
                        LeadId,
                      },
                    },
                  } = result;

                  if (redirectToLogin && !salesRegitration) {
                    setLoginView(true);
                    showBackButton && showBackButton(true);
                  }
                  if (redirectToPassword && !salesRegitration) {
                    setLeadViewData(values);
                    setCreatedLeadId(LeadId);
                    setPasswordSetView(true);
                    showBackButton && showBackButton(true);
                  }
                  if (redirectToPassword && salesRegitration) {
                    toggleAlert('success', 'Student and Parent is Created.');
                  }
                  if (redirectToLogin && salesRegitration) {
                    toggleAlert('danger', 'User Already Exist.');
                  }

                  setIsLoading(false);
                  setSubmitting(false);
                } catch (error: any) {
                  setSubmitting(false);
                  setIsLoading(false);
                  handleMutationError(error, (errorMessage) => {
                    toggleAlert('danger', errorMessage.message);
                  });
                }
              }}
            >
              {({
                values,
                isSubmitting,
                setFieldValue,
                errors,
                touched,
                submitCount,
              }) => {
                return (
                  <Form>
                    <Box
                      display="flex"
                      alignItems="center"
                      maxWidth={439}
                      margin="0 auto 55px"
                    >
                      <Box
                        maxWidth={48}
                        marginRight={2}
                        className="hide-mobile"
                      >
                        <SmallLogo />
                      </Box>

                      <Box component="h4">
                        {title || 'Let’s get to know you better'}
                      </Box>
                    </Box>

                    <Box maxWidth={394} margin="0px auto">
                      <Box className={classes.radioGroup}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          paddingBottom={4.5}
                          className={classes.sectionHeadingContainer}
                        >
                          <Box className={classes.sectionHeading}>
                            <span className="heading-number">1.</span>I am a{' '}
                            <Hidden xsDown>...</Hidden>
                          </Box>

                          <RadioGroup
                            name="studentOrParent1"
                            value={parentorStudent}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              marginRight={3.2}
                            >
                              <RadioButtonOption
                                role="Student"
                                label="Student"
                                className={classes.radioButton}
                                setParentOrStudent={setParentOrStudent}
                              />
                              <RadioButtonOption
                                role="Parent"
                                label="Parent"
                                className={classes.radioButton}
                                setParentOrStudent={setParentOrStudent}
                              />
                            </Box>
                          </RadioGroup>
                        </Box>

                        {parentorStudent === 'Student' && (
                          <>
                            <StudentInformation
                              setFieldValue={setFieldValue}
                              setYearValue={setYearValue}
                              parentorStudent={parentorStudent}
                              yearValue={yearValue}
                              errors={errors}
                              touched={touched}
                            />
                            <Box
                              margin="19px -20px 45px"
                              borderBottom={`1px solid ${Colors.BLACK_TEN}`}
                            ></Box>
                            <ParentInformation
                              setFieldValue={setFieldValue}
                              parentorStudent={parentorStudent}
                            />
                          </>
                        )}
                        {parentorStudent === 'Parent' && (
                          <>
                            <ParentInformation
                              setFieldValue={setFieldValue}
                              parentorStudent={parentorStudent}
                            />
                            <Box
                              margin="46px -20px 51px"
                              borderBottom={`1px solid ${Colors.BLACK_TEN}`}
                            ></Box>
                            <StudentInformation
                              setFieldValue={setFieldValue}
                              setYearValue={setYearValue}
                              parentorStudent={parentorStudent}
                              yearValue={yearValue}
                              errors={errors}
                              touched={touched}
                            />
                          </>
                        )}
                        <Box className={classes.consentCheckbox}>
                          <Field name="optInSMS">
                            {({ form: { values } }: FieldProps) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.optInSMS}
                                    onChange={(e) => {
                                      setOptInSMS(e.target.checked);
                                      setFieldValue(
                                        'optInSMS',
                                        e.target.checked
                                      );
                                    }}
                                    name="optInSMS"
                                    color="primary"
                                  />
                                }
                                label={
                                  <Fragment>
                                    By creating an account, I consent to receive
                                    promotional calls and texts (e.g. webinar
                                    reminders, promos, etc.) from College
                                    Advisor, including through automated
                                    technology. Consent is not a condition of
                                    purchase. Msg & data rates may apply.{' '}
                                    <a
                                      rel="noreferrer"
                                      target="_blank"
                                      style={{
                                        borderBottom: `1px solid ${colors.BLUE_SEVEN}`,
                                      }}
                                      href="https://www.collegeadvisor.com/terms-and-conditions/"
                                    >
                                      Terms
                                    </a>{' '}
                                    apply.
                                  </Fragment>
                                }
                              />
                            )}
                          </Field>
                        </Box>

                        <ThemeProvider theme={customTheme}>
                          <Box marginTop={1.2}>
                            <Button
                              type="submit"
                              color="secondary"
                              variant="contained"
                              onClick={() => {
                                if (
                                  window.location.host ===
                                  'app.collegeadvisor.com'
                                ) {
                                  if (window.ttq) {
                                    console.log('triggering ttq clickForm...');
                                    window.ttq
                                      .instance('C2CJLF7MU8Q03RAIPRC0')
                                      .track('ClickForm');
                                  }
                                }
                              }}
                              id="reg-continue"
                              disabled={isSubmitting}
                              fullWidth
                            >
                              Continue
                              {isLoading && (
                                <CircularProgress
                                  size={24}
                                  className={classes.buttonProgress}
                                />
                              )}
                            </Button>
                          </Box>
                        </ThemeProvider>
                      </Box>
                    </Box>
                    <ErrorFocus errors={errors} />
                  </Form>
                );
              }}
            </Formik>
          );
        }}
      </Mutation>
    </>
  );
};

export default NewUserForm;
