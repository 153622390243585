import React from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';
import { connect } from 'formik';

/**
 * @author Wajeeh Aslam
 * @returns [{label, value}]
 */
export const getYearsArray = (num: number | undefined) => {
  const currentdate = new Date();
  const currentYear = currentdate.getFullYear();
  const YEARS_ARRAY: {
    label: string | number;
    value: string | number;
  }[] = Array(num || 7)
    .fill('')
    .map((val, index) => {
      return {
        label: currentYear + (index + 1),
        value: currentYear + (index + 1),
      };
    });
  const arr = [...YEARS_ARRAY, { label: currentYear, value: currentYear }];
    arr.push({ label: 'Other', value: 'Other' });

  return arr;
};

/**
 *
 * @param param0 {label: string, value:string}
 * @returns JSX.Element
 */
export const FinancialAidOptionButton = ({
  label,
  value,
}: {
  label: string;
  value: number;
}): JSX.Element => (
  <FormControlLabel
    value={value}
    control={<Radio color="primary" />}
    label={label}
  />
);

/**
 * props.formik
 */
export const ErrorFocus = connect((props: any) => {
  React.useEffect(() => {
    const { isSubmitting, isValidating, errors } = props.formik;
    const keys = Object.keys(errors);
    if (keys.length && isSubmitting && !isValidating) {
      if (keys.length) {
        const elem =
          document.querySelector(`[name='${keys[0]}']`) ||
          document.querySelector(`[name='studentFirstName']`);
        if (elem) {
          elem.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }
    }
  });
  return <></>;
});

/**
 * @author Wajeeh Aslam
 * @param param0 {label, role, setStudentParent}
 * @returns JSX.Element
 */
export const RadioButtonOption = ({
  label,
  role,
  setParentOrStudent,
  className,
}: {
  label: string;
  role: string;
  className?: string;
  setParentOrStudent: Function;
}): JSX.Element => (
  <FormControlLabel
    value={role}
    control={<Radio color="primary" />}
    label={label}
    className={className}
    onChange={() => {
      setParentOrStudent(role);
    }}
  />
);
