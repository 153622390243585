import { createStyles, makeStyles, Theme } from '@material-ui/core';

import fonts from '../../../common/fonts';

export const helpSupportStyle = makeStyles((theme: Theme) =>
  createStyles({
    helpBadgeContainer: {
      '&:hover': {
        '& #profileBadgeMenu': {
          display: 'block',
        },
      },

      '& .helpButton': {
        marginLeft: 12,
      },
    },

    helpBadgeMenu: {
      display: 'none',
      cursor: 'auto',
      right: 0,
      paddingTop: 14,

      '& a': {
        maxWidth: 'fit-content',
      },
    },

    helpPopover: {
      width: 351,
      borderRadius: 15,
      background: '#FFFFFF',
      padding: '30px 25px 25px',
      border: '1px solid #D4D4D4',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },

      '& .title, & .subTitle': {
        color: '#272929',
      },

      '& .title': {
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .subTitle': {
        maxWidth: 280,
        fontWeight: 400,
        fontSize: 14,
        margin: '14px 0px',
        letterSpacing: '-0.006em',
        fontFamily: fonts.INTER,
      },

      '& .phoneNumber': {
        fontWeight: 600,
        color: '#272929',
      },

      '& .emailto': {
        color: '#2F80ED',
        fontWeight: 600,
      },

      '& .subHeading': {
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '-0.006em',
        color: '#272929',
        fontFamily: fonts.INTER,
      },

      '& .dateText': {
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        marginBottom: 19,
        fontFamily: fonts.INTER,
      },

      '& .contactUs': {
        minWidth: 111,
        height: 40,
        background: '#45CE93',
        borderRadius: 12,
        fontWeight: 700,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'capitalize',
        fontFamily: fonts.POPPINS_FONT,
      },
    },
  })
);
