import {
  Avatar,
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import fonts from '../../../common/fonts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    matchReason: {
      '& .matchReason': {
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        fontWeight: 400,
      },
    },

    root: {
      display: 'flex',
      alignItems: 'center',

      [`@media only screen and (max-width: 414px)`]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },

    mentorImage: {
      height: 122,
      width: 122,
    },
  })
);

const GetStartConsultantCard = ({
  consultantName,
  consultantCollege,
  consultantImage,
  consultantLevel,
}: {
  consultantName: string;
  consultantCollege: string;
  consultantImage: string | null;
  consultantLevel: string;
}) => {
  const classes = useStyles();

  return (
    <Grid className={classes.matchReason}>
      <Box className={classes.root}>
        <Grid>
          <Box display="flex" alignItems="flex-start" paddingBottom="18px">
            <Box mr="20px" position="relative">
              {consultantImage ? (
                <Avatar src={consultantImage} className={classes.mentorImage} />
              ) : (
                <Avatar src="" className={classes.mentorImage} />
              )}
            </Box>

            <Box>
              <Typography variant="h4" component="h4">
                {consultantName}
              </Typography>
              <Typography variant="body1">{consultantLevel}</Typography>
              <Typography variant="body1" className="matchReason">
                {consultantCollege?.length > 58
                  ? `${consultantCollege.substring(0, 38)}...`
                  : consultantCollege}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default GetStartConsultantCard;
