import { createStyles } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Colors from '../../common/colors';
import Fonts from '../../common/fonts';

import fonts from '../../common/fonts';
import ERROR_IMG from '../../img/error-ic.svg';
import colors from '../../common/colors';
import PICKER_ARROW_ICON from '../../img/arrow-icon-picker.svg';
import PICKER_ICON from '../../img/picker-date-icon.svg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonProgress: {
      color: '#ffffff',
      width: '20px !important',
      height: '20px !important',
      marginLeft: 12,

      '& svg': {
        marginRight: 0,
      },
    },

    textfield: {
      marginBottom: 28,
      fontFamily: Fonts.INTER,

      '& .PhoneInputInput': {
        color: Colors.BLACK_TEN,
        border: `1px solid ${Colors.BLACK_TEN}`,
        height: 33,
        padding: '0 10px',
        fontSize: 14,
        background: Colors.WHITE_ONE,
        borderRadius: '5px !important',
      },

      '& .MuiInputBase-root': {
        fontFamily: Fonts.INTER,
      },

      '& .MuiFormHelperText-root.Mui-error': {
        fontFamily: Fonts.INTER,
      },

      '& .MuiInputLabel-shrink': {
        fontFamily: Fonts.INTER,
        color: Colors.BLUE_SEVEN,
      },

      '& .MuiInputLabel-animated': {
        fontFamily: Fonts.INTER,
      },

      '& label.Mui-focused': {
        color: Colors.BLUE_SEVEN,
      },

      '& .MuiInput-underline:after': {
        borderBottomColor: Colors.BLUE_SEVEN,
      },

      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: Colors.BLUE_SEVEN,
        },
        '&:hover fieldset': {
          borderColor: Colors.BLUE_SEVEN,
        },
        '&.Mui-focused fieldset': {
          borderColor: Colors.BLUE_SEVEN,
        },
      },
    },

    radioGroup: {
      color: Colors.WHITE_ONE,
    },

    createPassword: {
      margin: '12px 0 0 12px',

      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },

      '& .MuiTypography-body2': {
        textAlign: 'left',
        color: `${Colors.WHITE_ONE}`,
      },

      '& .MuiInputBase-input': {
        paddingLeft: 15,
      },

      '& .MuiFormControl-fullWidth': {
        marginBottom: `42px !important`,
      },
    },

    textfieldMobile: {
      '@media only screen and (max-width: 767px)': {
        marginBottom: '0px !important',
      },
    },

    leadRegisterGraduation: {
      display: 'flex',
      marginBottom: 30,

      '& .MuiFormControl-fullWidth': {
        marginBottom: '0px !important',
      },
    },

    fieldContainer: {
      display: 'flex',
      justifyContent: 'space-between',

      '&.custom-margin': {
        '& .MuiFormControl-fullWidth': {
          marginBottom: `7px !important`,
        },
      },

      '& .MuiFormControl-root': {
        width: '48.1%',
      },
    },

    registerRight: {
      right: 0,
      width: '58%',
      height: '100vh',
      position: 'fixed',
      padding: '42px 50px',
      background: `linear-gradient(123deg, #2F80ED 1.86%, #0A2463 98.27%)`,

      '& .desktop-only': {
        fontFamily: Fonts.INTER,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },

      '& .registerHeading': {
        fontSize: 36,
        color: '#fff',
        fontWeight: 700,
        letterSpacing: '-0.792',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .registerHeadingOpacity': {
        color: '#96BFF5',
      },

      '& .mobile-only': {
        display: 'none',
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: 'auto',
        position: 'relative',
        padding: 20,

        '& .mobile-only': {
          display: 'block',
        },

        '& .desktop-only': {
          display: 'none',
        },
      },
    },

    phoneInputNew: {
      position: 'relative',
      paddingBottom: 30,

      '& > label': {
        position: 'absolute',
        fontSize: 10,
        fontWeight: 500,
        color: Colors.BLACK_TEN,
        transform: 'none !important',
        fontFamily: Fonts.INTER,
        top: -17,
        left: 40,
      },

      '& .PhoneInputInput': {
        color: Colors.BLACK_TEN,
        border: `1px solid ${Colors.BLACK_TEN}`,
        height: 33,
        padding: '0 10px',
        fontSize: 14,
        background: Colors.WHITE_ONE,
        borderRadius: '5px !important',

        '&::placeholder': {
          color: '#8F8F8F',
          fontFamily: Fonts.INTER,
        },
      },

      '& .PhoneInputCountryIcon': {
        color: Colors.BLACK_TEN,
      },

      '& .PhoneInputCountrySelectArrow': {
        borderColor: Colors.BLACK_ONE,
        border: '1px solid #1b1b1b !important',
        borderTopWidth: '0px !important',
        borderLeftWidth: '0px !important',
      },

      '& .react-phone-number-input__icon': {
        border: 'none',
        width: 27,
        height: 'auto',
      },

      '& .react-phone-number-input__country--native': {
        marginRight: 19,
      },

      '& .react-phone-number-input__input': {
        background: Colors.WHITE_ONE,
        border: `1px solid ${Colors.BLACK_TEN}`,
        borderRadius: 5,
        padding: '0 10px',
        height: 35,
        fontSize: 14,
        color: Colors.BLACK_TEN,
        fontFamily: Fonts.INTER,
      },

      '& .react-phone-number-input__input::placeholder': {
        color: `${Colors.GRAY_SIX} !important`,
      },

      '&.error': {
        '& .PhoneInputInput': {
          background: Colors.WHITE_ONE,
          border: `1px solid ${Colors.RED_ONE}`,
          borderRadius: 5,
          padding: '0 10px',
          height: 35,
          fontSize: 14,
          color: Colors.RED_ONE,
        },

        '& .PhoneInputInput::placeholder': {
          fontFamily: Fonts.INTER,
          color: '#bdbdc1 !important',
        },
      },

      '& .Mui-error': {
        marginLeft: `38px !important`,
      },

      '& .react-phone-number-input__error': {
        margin: '5px 0px 0px',
        background: `url(${ERROR_IMG}) no-repeat center left`,
        lineHeight: '12px',
        paddingLeft: 18,
        marginLeft: 53,
        fontSize: '0.75rem',
        fontFamily: Fonts.INTER,
        color: Colors.BLACK_ELEVEN,
      },
    },

    checkListHeading: {
      fontSize: 15,
      color: Colors.BLACK_TEN,
      fontWeight: 600,
      fontFamily: Fonts.INTER,

      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },

    checkList: {
      display: 'flex',
      maxWidth: 340,
      margin: '0 auto',
      alignItems: 'center',
      color: Colors.BLACK_TEN,

      '& p': {
        fontSize: 12,
        lineHeight: '20px',
        letterSpacing: '0.38px',
      },

      '& img': {
        marginRight: 12,
      },
    },

    authDialog: {
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0,.8)',
      },
    },
  })
);

export const blogStyles = makeStyles((theme: Theme) =>
  createStyles({
    applicationsCard: {
      marginBottom: 43,
      borderRadius: 25,

      '@media only screen and (max-width: 767px)': {
        marginBottom: 17,
      },

      [theme.breakpoints.down('sm')]: {
        borderRadius: 15,
      },
    },

    applicationsHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '24px 30px',
      borderBottom: `1px solid ${Colors.WHITE_SIX}`,

      [theme.breakpoints.down('sm')]: {
        padding: '15px 20px',
      },
    },

    collegeInfoWrapper: {
      display: 'flex',
      padding: '24px 30px',
      borderBottom: `1px solid ${Colors.WHITE_SIX}`,

      '&:hover': {
        background: Colors.GRAY_BOREDER,
      },

      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        padding: '20px 15px',
      },
    },

    collegeLogo: {
      width: 280,
      height: 163,
      borderRadius: 15,
      marginRight: 30,
      overflow: 'hidden',

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'top',
      },
    },

    blogText: {
      maxWidth: 282,

      '@media only screen and (max-width: 767px)': {
        paddingTop: 15,
      },
    },

    blogInfoWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '21px 30px',
      borderBottom: `1px solid ${Colors.WHITE_SIX}`,

      [theme.breakpoints.down('sm')]: {
        padding: '20px 15px',
      },

      '&:hover': {
        background: Colors.GRAY_BOREDER,
      },

      '& p': {
        [theme.breakpoints.down('sm')]: {
          paddingRight: 25,
        },
      },
    },
  })
);

export const MBACEStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      maxWidth: 1130,
      margin: theme.spacing(0, 'auto'),

      '& .Mui-expanded': {
        borderBottom: 0,
      },
    },

    enrollmentContainer: {
      paddingBottom: 50,
    },

    pageHeading: {
      padding: '56px 0 70px',

      [theme.breakpoints.down('xs')]: {
        padding: '40px 0px 25px',
      },

      '& h3': {
        fontSize: 36,
        fontWeight: 700,
        color: '#272929',
        paddingBottom: 11,
        lineHeight: '42px',
        letterSpacing: '-0.022em',

        [theme.breakpoints.down('xs')]: {
          maxWidth: 265,
          fontSize: 28,
          lineHeight: '36px',
          textAlign: 'center',
          letterSpacing: 'initial',
        },
      },
    },

    enrollmentFeature: {
      backgroundColor: Colors.WHITE_ONE,
    },

    plansPriceDivider: {
      marginTop: 62,

      '& .divider': {
        width: '100%',
        background: '#D4D4D4',
      },
    },

    packageOptionHeading: {
      fontSize: '28px',
      linHeight: '',
    },

    headingContainer: {
      '& button': {
        border: 'none',
        background: 'none',

        '&:hover': {
          background: 'none',
        },
      },

      '& button.diamondButton': {
        height: 49,
        opacity: 0.5,
      },
    },
  })
);

export const MBAPackageDetailsStyles = makeStyles((theme: Theme) =>
  createStyles({
    //   PackageDetails
    packageDetailsContainer: {
      padding: '48px 0',
    },

    pageHeader: {
      [theme.breakpoints.up('md')]: {
        paddingBottom: 48,
      },
    },

    summaryHeader: {
      padding: '21px 24px',
      borderBottom: `1px solid ${Colors.WHITE_SEVEN}`,
    },

    packageItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    innerGrid: {
      padding: '27px 24px 26px',
      backgroundColor: Colors.GRAY_BOREDER,
      borderBottom: `1px solid ${Colors.WHITE_SEVEN}`,

      '& .chargedAgain': {
        fontSize: 12,
        marginTop: 5,
        fontWeight: 400,
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.072px',
      },

      '& .seniorResourceProgram': {
        '& .captionText': {
          fontSize: 12,
          fontWeight: 400,
          color: '#7D7F7F',
          fontFamily: fonts.INTER,
          letterSpacing: '-0.072px',
        },
      },

      '& .promoAmount': {
        fontSize: 16,
        fontWeight: 400,
        marginRight: 15,
        color: '#A9A9A9',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.176px',
        textDecoration: 'line-through',
      },

      '& .originalAmount': {
        fontSize: 16,
        fontWeight: 700,
        color: '#525454',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.176px',

        '& span': {
          padding: 0,
          fontWeight: 400,
        },
      },

      '& .paymentText': {
        fontWeight: 400,
        lineHeight: '15px',
        padding: '0px !important',
      },

      '& .promoCodeSection': {
        marginTop: 20,
        paddingTop: 24,
        borderTop: '1px solid #D4D4D4',

        '& .promoCodeOff': {
          fontSize: 12,
          fontWeight: 400,
          marginRight: 19,
          color: '#7D7F7F',
          fontFamily: fonts.INTER,
          letterSpacing: '-0.072px',
        },

        '& .removeLink': {
          fontSize: 12,
          fontWeight: 400,
          color: '#A9A9A9',
          cursor: 'pointer',
          fontFamily: fonts.INTER,
          letterSpacing: '-0.072px',
          borderBottom: '1px solid #A9A9A9',
        },
      },
    },

    removePromo: {
      fontSize: 12,
      fontWeight: 400,
      color: '#A9A9A9',
      cursor: 'pointer',
      fontFamily: fonts.INTER,
      letterSpacing: '-0.072px',
      borderBottom: '1px solid #A9A9A9',

      '&:hover': {
        color: '#A9A9A9 !important',
        borderBottom: '1px solid #A9A9A9 !important',
      },
    },

    summaryBody: {
      padding: '28px 25px 41px',

      '& .dueTodayAmount': {
        '& .dueAmountText': {
          color: '#2F80ED',
          fontSize: 24,
          fontWeight: 700,
          letterSpacing: '-0.456px',
          fontFamily: fonts.POPPINS_FONT,
        },
      },

      '& .paymentFailedText': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 12,
        color: '#E94444',
        lineHeight: '16px',
      },

      '& .paymentFailed': {
        marginTop: 24,
        display: 'flex',
        alignItems: 'flex-start',

        '& img': {
          marginRight: 12,
        },
      },

      '& .moneyBack': {
        marginTop: 20,
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,

        '& img': {
          marginRight: 11,
        },
      },
    },

    mainCardContainer: {
      padding: '28px 24px',
      marginBottom: 17,
    },

    checkboxContainer: {
      padding: '28px 0px 22px',

      '& a': {
        fontSize: 12,
        marginLeft: 5,
        fontWeight: 400,
        color: '#2F80ED',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.072px',
        borderBottom: '1px solid #2F80ED',
      },

      '& [class*="MuiFormControlLabel-label"]': {
        fontSize: 12,
        fontWeight: 400,
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.072px',
      },

      '& [class*="MuiCheckbox-root"]': {
        bottom: 6,
        position: 'relative',
      },
    },

    checkboxElement: {
      paddingTop: '0px',
      "& [class*='MuiSvgIcon-root']": {
        width: 24,
        height: 24,
      },
    },

    packageTitle: {
      fontSize: 24,
      fontWeight: 700,
      letterSpacing: '-0.456px',
      fontFamily: fonts.POPPINS_FONT,
    },
    contractFullName: {
      marginBottom: 27,
    },
    contractContainerCheckbox: {
      marginLeft: -9,
    },

    contractContainer: {
      '& .fullName': {
        maxWidth: 260,
      },

      "& [class*='MuiSvgIcon-root']": {
        width: 26,
        height: 26,

        '& path': {
          color: '#A9A9A9',
        },
      },

      '& .electronicFields': {
        marginRight: '20px',
        width: '100%',

        '& .Mui-disabled': {
          cursor: 'not-allowed',
          background: '#F4F4F4',
        },
      },
      '& .inputBaseCaptionDisabled': {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      '& .inputBaseCaption': {
        fontSize: 12,
        fontWeight: 400,
        color: '#272929',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.072px',

        [theme.breakpoints.down('xs')]: {
          marginTop: 15,
        },
      },

      '& .electronicSubTitle': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
      },

      '& .widthInputBase': {
        width: '100%',
        border: `1px solid ${colors.WHITE_SEVEN}`,
        padding: '7px 12px',
        color: '#272929',
        fontFamily: fonts.INTER,
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: '-0.084px',

        '& input': {
          padding: 0,
        },
      },

      '& .datePickerField': {
        maxWidth: 170,
      },
      '& .datePicker': {
        margin: 0,
        background: '#FFFFFF',
        borderRadius: 6,
        border: '1px solid #D4D4D4',
        padding: '0px 12px',
        height: 32.63,
        maxWidth: 183,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right 17px',
        backgroundImage: `url(${PICKER_ARROW_ICON})`,

        '& .Mui-disabled': {
          opacity: 0.9,
          cursor: 'not-allowed',
          color: '#A9A9A9 !important',
        },

        '&.datePickerN': {
          '& .Mui-disabled': {
            color: '#272929 !important',
          },
        },

        '& ::after, & ::before': {
          display: 'none',
        },

        '& input': {
          border: 0,
          padding: 0,
          height: 32,
          fontWeight: 400,
          fontSize: 14,
          letterSpacing: '-0.084px',
          color: '#A9A9A9',
          fontFamily: fonts.INTER,
        },

        '& button': {
          padding: 0,
          width: 20,
          height: 32,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${PICKER_ICON})`,

          '& svg': {
            display: 'none',
          },
        },
      },
      '& .datePickerDisabled': {
        opacity: 0.9,
        color: '#A9A9A9 !important',
        cursor: 'not-allowed !important',
        backgroundColor: '#F4F4F4 !important',
      },
    },
  })
);
