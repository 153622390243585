import { Box } from '@material-ui/core';

import Colors from '../../../common/colors';
import { useStyles } from '../../login/styles';
import REGISTER_IMG from '../../../img/register-big.png';
import LOGO from '../../../img/logo-white.png';
import LOGO_BIG from '../../../img/logo-us-news-light.png';
import HeaderLoginRegister from '../../../components/layout/HeaderLoginRegister';

const RightSidePage = () => {
  const classes = useStyles();
  return (
    <Box className={classes.registerRight}>
      <Box className="mobile-only">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <img src={LOGO_BIG} alt="College advisor big logo" width="145px" />

          <HeaderLoginRegister />
        </Box>
      </Box>

      <Box className="desktop-only">
        <Box maxWidth={660} width="100%" margin="0 auto">
          <img src={REGISTER_IMG} alt="banner" />
        </Box>

        <Box
          fontSize={30}
          color={Colors.WHITE_ONE}
          paddingBottom={9}
          textAlign="center"
        >
          Turn your <strong>college dreams</strong> into reality.
        </Box>

        <Box maxWidth={200} margin="0 auto">
          <a href="https://collegeadvisor.com/" target="_blank">
            <img src={LOGO} alt="" />
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default RightSidePage;
