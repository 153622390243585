import gql from 'graphql-tag';

export const GET_ALL_STUDENT_MENTORS = gql`
  query getAllStudentMentors($id: Int) {
    allStudentMentors(id: $id) {
      id
      associateType
      matchReason
      isExtraMatch
      isPrimaryAdvisor
      mentor {
        id
        firstName
        lastName
        email
        pictureUrl
        advisorLevel
        uuid
        intakeYear
        sendbirdId
        Theme
        currentCollege {
          id
          name
          logo
          styleConfig {
            bgDark
            bgLight
          }
        }
        graduateSchool {
          id
          name
          logo
          styleConfig {
            bgDark
            bgLight
          }
        }
        visible
        uuid
        bio
      }
      consultant {
        id
        firstName
        lastName
        imageUrl
      }
      deletedAt
    }
  }
`;
