import moment from 'moment-timezone';
import { AdvisorAvailableTimeslots_advisorAvailableTimeslots_time_slots } from '../gql/__generated__/AdvisorAvailableTimeslots';

export const convertToUserSpecificTimeslots = (
  timeslots: (AdvisorAvailableTimeslots_advisorAvailableTimeslots_time_slots | null)[]
) => {
  let availableDays: Date[] = [];
  if (timeslots.length) {
    timeslots.map((t) => {
      if (
        t?.start &&
        !availableDays.find(
          (item) =>
            moment(item).format('MM-DD-YYY') ===
            moment(new Date(t.start * 1000)).format('MM-DD-YYY')
        ) &&
        moment(t.start * 1000).format('MM-DD-YYYY') !==
          moment(new Date()).format('MM-DD-YYYY') &&
        t.start * 1000 > new Date().getTime()
      ) {
        availableDays.push(new Date(t.start * 1000));
      }
    });
  }
  return availableDays;
};
