import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { FC, Fragment, useContext, useEffect, useState } from 'react';

import { AppContext } from '../../../contexts';
import { useStyles } from './styles';

import moment from 'moment-timezone';
import { formatter, isDiamondPackage } from '../../../common/utility';
import { getPlan_getOnePlan } from '../../../pages/main/clientEnrollment/gql/__generated__/getPlan';

import client from '../../../apollo';
import { cancel_values } from '../../../common/constants';
import { UserContract } from '../../../graphql/queries/clientEnrollment';
import { UserContract_userContract } from '../../../graphql/queries/clientEnrollment/__generated__/UserContract';

import DownloadIcon from '../../../img/download-agreement.svg';

import Images from '../../../img';
import Advisors from './Advisors';
import { Query } from 'react-apollo';
import Loader from '../../../components/Loader';
import { GET_ALL_STUDENT_MENTORS } from '../../../graphql/queries/studentMentors';
import { getAllStudentMentors } from '../../../graphql/queries/studentMentors/__generated__/getAllStudentMentors';

type creditType = number | null | undefined;

interface Props {
  plan?: getPlan_getOnePlan | null | undefined;
}

const MyPlan: FC<Props> = ({ plan }) => {
  const classes = useStyles();
  const [signedContract, setSignedContract] =
    useState<UserContract_userContract | null>(null); // UserContract
  const { user } = useContext(AppContext);

  const fetchUserContract = async (userId: number) => {
    const apiCall = async () => {
      const { data } = await client.query({
        query: UserContract,
        fetchPolicy: 'no-cache',
      });
      if (data.userContract) {
        setSignedContract(data.userContract);
      }
    };
    await apiCall();
  };

  useEffect(() => {
    if (user) {
      fetchUserContract(user.id);
    }
  }, [user]);
  if (!user) {
    return null;
  }
  const {
    premiumPackageType,
    premiumTotalVideoCount,
    premiumTotalEssayCount,
    userSubscription,
    userCoupon,
    planDetail,
    markedPremiumAt,
    planCancelled,
  } = user;
  if (planDetail) {
    var {
      discountCode,
      totalAmountPaid,
      amountRefunded,
      amountDue,
      totalAmount,
    } = planDetail;
  }

  const admissionPackage =
    user?.userSubscription?.SubscriptionPlan?.AdmissionPackage;

  const discountDescription = user?.userCoupon?.amount_off
    ? formatter.format(user?.userCoupon?.amount_off / 100)
    : user?.userCoupon?.percent_off
    ? user?.userCoupon?.percent_off + '% off'
    : null;
  let amount = userSubscription?.SubscriptionPlan?.amount;
  let installments = userSubscription?.SubscriptionPlan?.installments;
  let planPrice: any = null;
  let actualAmount: any = amount / 100;
  let savings: any = !userCoupon
    ? null
    : userCoupon?.amount_off
    ? userCoupon?.amount_off / 100
    : userCoupon.percent_off
    ? (amount * userCoupon?.percent_off) / 100 / 100
    : null;
  if (savings) {
    if (installments) {
      planPrice = actualAmount - savings;
    } else {
      planPrice = actualAmount - savings;
    }
  } else {
    planPrice = actualAmount;
  }
  actualAmount = planPrice;

  if (installments) {
    amount = amount * installments;
    actualAmount = actualAmount * installments;
  }

  amount = formatter.format(amount / 100);
  actualAmount = formatter.format(actualAmount);
  if (planPrice) {
    planPrice = formatter.format(planPrice);
  }
  const includedInPackage = (
    videoCredits: creditType,
    essayReviews: creditType
  ) => {
    const packageInfo: (string | React.ReactNode)[] = [
      `${videoCredits} hours of one-on-one admissions coaching`,
      `${essayReviews} applications supported for essay editing`,
    ];
    if (admissionPackage?.packageType?.toLowerCase() === 'polygence') {
      packageInfo.push(
        <Fragment>
          "Build Your Own Research Project” Add-On with <i>Polygence</i>
        </Fragment>
      );
    }
    if (
      premiumPackageType?.toLowerCase() === 'platinum' ||
      premiumPackageType?.toLowerCase() === 'ivy plus'
    ) {
      packageInfo.push(
        'Personal Admissions Expert with 2+ years of experience',
        'Former College Admissions Officer'
      );
    }
    if (premiumPackageType?.toLowerCase() === 'diamond') {
      packageInfo.push(
        'Entire journey led by an admissions officer from a top school'
      );
      packageInfo.push('Specialty advisor included for additional support');
    }
    return packageInfo;
  };

  let financingCharges: any = plan?.financingCharges
    ? plan.financingCharges / 100
    : '';
  if (financingCharges && userCoupon?.percent_off) {
    financingCharges =
      financingCharges - (financingCharges * userCoupon.percent_off) / 100;
  }

  const enableCER1 = process.env.REACT_APP_ENABLE_CE_R1 === 'TRUE';

  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box>
            <Typography variant="h4">My Plan</Typography>
          </Box>
          <Divider />
        </Grid>
      </Grid>
      <Grid
        className={classes.myPlanDetail}
        style={user.planCancelled ? { opacity: '50%' } : {}}
      >
        <Grid>
          <Box
            color={user?.premiumPackageColor || ''}
            display="flex"
            alignContent="center"
          >
            <Typography
              color="inherit"
              variant={!user?.isMBAGrad ? 'h6' : 'body1'}
              className={classes.planTypography}
            >
              {user?.isMBAGrad ? (
                <>
                  <Box
                    component="span"
                    color={admissionPackage?.color?.split(',')?.[0]}
                  >
                    <Typography variant="h3" color="inherit">
                      {premiumPackageType?.split(' ')?.[0]}
                    </Typography>
                  </Box>
                  <Box
                    component="span"
                    color={admissionPackage?.color?.split(',')?.[1]}
                    marginLeft={1}
                  >
                    <Typography variant="h3" color="inherit">
                      {premiumPackageType?.split(' ')?.[1]?.toLowerCase()}
                    </Typography>
                  </Box>
                </>
              ) : (
                premiumPackageType
              )}
            </Typography>
            {isDiamondPackage(premiumPackageType) ? (
              <img
                className="ml-2 mb-1"
                width={'32px'}
                src={Images.DiamondIcon2}
              />
            ) : null}
          </Box>
          <Typography variant="body2" className="enrolledSince">
            Enrolled Since:{' '}
            {moment(userSubscription?.createdAt).format('MMM Do, YYYY')}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {enableCER1 ? (
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.planDetailEnroll}
            >
              <Typography variant="body1" className="planEnrollText">
                <span>Plan: </span>
                {`${
                  userSubscription?.SubscriptionPlan?.AdmissionPackage
                    ? userSubscription?.SubscriptionPlan?.AdmissionPackage?.name?.split(
                        ' - '
                      )[1]
                    : ''
                } - ${
                  userSubscription?.SubscriptionPlan?.installments
                    ? userSubscription?.SubscriptionPlan?.installments
                    : 1
                } Payments`}
              </Typography>
              <Typography variant="body1" className="planEnrollText">
                <span>Total Amount: </span>

                {totalAmount ? ` $${(totalAmount / 100).toFixed(2)} ` : '$0'}
              </Typography>
              <Typography variant="body1" className="planEnrollText">
                <span>Enrollment Date: </span>

                {markedPremiumAt
                  ? moment(markedPremiumAt).format('MM/DD/YYYY')
                  : 'Unavailable'}
              </Typography>
              {discountCode && (
                <Typography variant="body1" className="planEnrollText">
                  <span>Discount Code:</span>
                  {discountCode}
                  {discountDescription ? `(${discountDescription})` : null}
                </Typography>
              )}
              <Typography variant="body1" className="planEnrollText">
                <span>Total Amount Paid: </span>

                {totalAmountPaid ? ` $${totalAmountPaid / 100} ` : '$0'}
              </Typography>
              {amountRefunded && (
                <Typography variant="body1" className="planEnrollText">
                  <span>Amount Refunded:</span> {`$${amountRefunded / 100} `}
                </Typography>
              )}
              {planCancelled &&
              user?.cancelAction &&
              user.cancelAction.ARStatus ===
                cancel_values['Bad Debt Cancel'] ? (
                <Typography variant="body1" className="planEnrollText">
                  <span>Amount Due: </span>
                  {user.cancelAction.amount
                    ? formatter.format(user.cancelAction.amount / 100)
                    : '$0'}
                </Typography>
              ) : amountDue && !planCancelled ? (
                <Typography variant="body1" className="planEnrollText mb-2">
                  <span>Amount Due: </span>
                  {formatter.format(amountDue / 100)}
                </Typography>
              ) : null}
              <Box pt={1}>
                {signedContract && signedContract.contractUrl && (
                  <a
                    href={signedContract.contractUrl}
                    className="downloadAgreement"
                  >
                    <img src={DownloadIcon} alt="download" />
                    <span>Download agreement</span>
                  </a>
                )}{' '}
              </Box>
            </Grid>
          ) : null}

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              className={
                enableCER1
                  ? `${classes.packageIncludeBox} enabledPackageBox`
                  : classes.packageIncludeBox
              }
            >
              <Typography variant="h6">Includes:</Typography>
              <ul>
                {includedInPackage(
                  premiumTotalVideoCount,
                  premiumTotalEssayCount
                ).map((details) => (
                  <li>
                    <Typography variant="body1">{details}</Typography>
                  </li>
                ))}
              </ul>
            </Box>
          </Grid>
        </Grid>

        <Query<getAllStudentMentors>
          query={GET_ALL_STUDENT_MENTORS}
          fetchPolicy="network-only"
        >
          {({ data, loading }) => {
            if (loading) return <Loader isSmallLoader={true} isTransparent={true} forceLoaderText={true} />;
            return data?.allStudentMentors?.length ? (
              <Grid className={classes.advisorsSection}>
                <Typography variant="h6">ADVISORS</Typography>
                <Grid container spacing={2}>
                  {data?.allStudentMentors?.map((advisor) => (
                    <Grid item md={6} sm={12} xs={12}>
                      <Advisors
                        firstName={advisor?.mentor?.firstName}
                        lastName={advisor?.mentor?.lastName}
                        pictureUrl={advisor?.mentor?.pictureUrl}
                        college={advisor?.mentor?.currentCollege?.name}
                      ></Advisors>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : null;
          }}
        </Query>
      </Grid>
    </Box>
  );
};

export default MyPlan;
