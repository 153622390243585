import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useContext } from 'react';
import { Mutation, Query } from 'react-apollo';
import Loader from '../../../components/Loader';
import { IMPERSONATE_MUTATION, STUDENT_PARENTS_QUERY } from './ParentAccounts';

import Colors from '../../../common/colors';

import { useStyles } from './styles';
import { StudentParentsQuery } from './__generated__/StudentParentsQuery';
import {
  ImpersonateMutation,
  ImpersonateMutationVariables,
} from './__generated__/ImpersonateMutation';
import { PARENT_TOKEN, TOKEN } from '../../../constants';
import { AppContext } from '../../../contexts';

const ParentPlanBiling = () => {
  const { user } = useContext(AppContext);

  const classes = useStyles();

  const {
    tableHead,
    tableCell,
  } = useStyles();

  return (
    <>
      <Card className={classes.cardTitle}>
        <Typography variant="h4">Plan &#38; Billing</Typography>

        <Divider />

        <Query<StudentParentsQuery>
          query={STUDENT_PARENTS_QUERY}
          fetchPolicy="network-only"
        >
          {({ data, loading, refetch, networkStatus }) => {
            if (loading && networkStatus !== 4) return <Loader isSmallLoader={true} isTransparent={true} forceLoaderText={true} />;
            if (!data || !data.studentParents) {
              return <Box fontSize={16}>No Parent Found!</Box>;
            }

            const { studentParents } = data;

            return (
              <Grid className={classes.planBillingTable}>
                <Grid className="planBillingTableResponsive">
                  <Table className={classes.billingTableWrapper}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={tableHead}>
                          <Typography variant="h6">First Name</Typography>
                        </TableCell>
                        <TableCell className={tableHead}>
                          <Typography variant="h6"> Last Name</Typography>
                        </TableCell>
                        <TableCell className={tableHead}>
                          <Typography variant="h6"> Package</Typography>
                        </TableCell>
                        <TableCell className={tableHead}>
                          <Typography variant="h6">Status</Typography>
                        </TableCell>
                        <TableCell className={tableHead} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {studentParents &&
                        studentParents.map((parent: any) => {
                          const {
                            id,
                            firstName,
                            lastName,
                            isPremium,
                            paymentBy,
                            premiumPackageType,
                          } = parent;

                          return (
                            <TableRow
                              key={id}
                              className={classes.billingTableRow}
                            >
                              <TableCell className={tableCell}>
                                <Typography variant="body2">
                                  {firstName || '--'}
                                </Typography>
                              </TableCell>

                              <TableCell className={tableCell}>
                                <Typography variant="body2">
                                  {lastName || '--'}
                                </Typography>
                              </TableCell>
                              <TableCell className={tableCell}>
                                <Typography variant="body2">
                                  {premiumPackageType}
                                </Typography>
                              </TableCell>
                              <TableCell className={tableCell}>
                                <Typography variant="body2">
                                  Active User
                                </Typography>
                              </TableCell>

                              <Mutation<
                                ImpersonateMutation,
                                ImpersonateMutationVariables
                              >
                                mutation={IMPERSONATE_MUTATION}
                              >
                                {(impersonateMutation: Function) => (
                                  <TableCell
                                    className={tableCell}
                                    style={{ textAlign: 'right' }}
                                  >
                                    {isPremium ? (
                                      paymentBy === 'ADMIN_PANEL' ||
                                      paymentBy === null ? (
                                        <Typography
                                          variant="body1"
                                          className="dialogText"
                                        >
                                          Please call{' '}
                                          <a href='tel:"+1 (855) 412-6272"'>
                                            +1 (855) 412-6272
                                          </a>{' '}
                                          for details.
                                        </Typography>
                                      ) : (
                                        <Button
                                          className={classes.selectButton}
                                          variant="outlined"
                                          style={{
                                            background: Colors.GREEN_SEVEN,
                                          }}
                                          onClick={async () => {
                                            try {
                                              const result =
                                                await impersonateMutation({
                                                  variables: { studentId: id },
                                                });

                                              const {
                                                data: {
                                                  impersonate: {
                                                    token,
                                                    status,
                                                  },
                                                },
                                              } = result;
                                              if (status === true) {
                                                if (user?.isParent === true) {
                                                  const oldToken =
                                                    await localStorage.getItem(
                                                      TOKEN
                                                    );
                                                  oldToken &&
                                                    (await localStorage.setItem(
                                                      PARENT_TOKEN,
                                                      oldToken
                                                    ));
                                                }
                                                await localStorage.setItem(
                                                  TOKEN,
                                                  token
                                                );
                                                window.location.pathname =
                                                  '/account/billing';
                                                // setIsLoggedIn(true);
                                              }
                                            } catch (error) {
                                              console.log('error: ', error);
                                            }
                                          }}
                                        >
                                          View Details
                                        </Button>
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </TableCell>
                                )}
                              </Mutation>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            );
          }}
        </Query>
      </Card>
    </>
  );
};

export default ParentPlanBiling;
