import client from '../../../../apollo';
import { ALL_COLLEGES, ALL_HIGH_SCHOOLS } from '../gql';
import {
  AP_COURSES,
  IB_COURSES,
  GET_EXTRA_CURRICUALR_CATEGORIES,
  ALL_LANGUAGES,
} from '../../account/graphql/queries/myProfile';
import _ from 'lodash';

export const intakeFormQuestionsForStudent = [
  {
    step: 1,
    title: "Let's start by telling us a bit about yourself.",
    stepperHeading: 'Information',
    data: [
      {
        question: 'What is Your Full Name?',
        extraNote: ' Please ensure you input your full name correctly.',
        options: [],
        type: 'text',
        innerQuestion: [],
        required: true,
      },
      {
        question: 'Do You Have a Preferred Name or Nickname?',
        extraNote: '',
        options: ['Yes', 'No'],
        type: 'radio',
        innerQuestion: [
          {
            question: 'What is Your Preferred Name / Nickname?',
            options: [],
            type: 'text',
          },
        ],
        required: false,
      },
      {
        question: 'What are your personal pronouns?',
        extraNote: '',
        options: ['She/Her/Hers', 'He/Him/His', 'They/Them/Theirs', 'other'],
        type: 'radio',
        innerQuestion: [
          {
            question: 'What are your preferred pronouns?',
            options: [],
            type: 'text',
          },
        ],
        required: false,
      },
      {
        question: 'What Grade Are You In?',
        extraNote: '',
        type: 'radio',
        options: ['9', '10', '11', '12', 'other'],
        innerQuestion: [
          {
            question: 'Enter Your Grade',
            options: [],
            type: 'number',
          },
        ],
        required: true,
      },
      {
        question: 'Is this your cell phone number?',
        extraNote: '',
        type: 'radio',
        options: ['Yes', 'No'],
        innerQuestion: [
          {
            question: 'What is Your Phone Number?',
            options: [],
            type: 'phone',
          },
        ],
        required: true,
      },
      {
        question: 'What is Your Email Address?',
        extraNote: '',
        type: 'email',
        options: [],
        innerQuestion: [],
        required: true,
      },
      {
        question: 'Where are you located?',
        extraNote: '',
        type: 'simple-select',
        options: [],
        innerQuestion: [],
        required: true,
      },
      {
        question: 'City',
        extraNote: '',
        type: 'text',
        options: [],
        innerQuestion: [],
        required: true,
        classNameStyle: 'fieldCaptionText',
      },
      {
        question: 'State',
        extraNote: '',
        type: 'simple-select',
        options: [],
        innerQuestion: [],
        required: false,
        classNameStyle: 'fieldCaptionText',
      },
      {
        question: 'Zip Code',
        extraNote: '',
        type: 'number',
        options: [],
        innerQuestion: [],
        required: false,
        classNameStyle: 'fieldCaptionText',
      },
      {
        question:
          'What time zone will you be in while working with your advisor?',
        extraNote: '',
        options: [],
        type: 'simple-select',
        innerQuestion: [],
        required: false,
        query: 'timezone',
      },
    ],
  },
  {
    step: 2,
    title:
      'Next, please tell us a bit about your academic history to help us prepare your personalized college application strategy',
    stepperHeading: 'Academic Information',
    data: [
      {
        question:
          'What high school do you currently attend? Please include the Name, City, State, and Country',
        extraNote: '',
        options: [],
        type: 'text',
        innerQuestion: [
          {
            question: 'Others (HighSchool)',
            options: [],
            type: 'text',
          },
        ],
        required: true,
      },

      {
        question: 'Grad Year',
        extraNote: '',
        options: [],
        type: 'simple-select',
        innerQuestion: [],
        required: true,
        classNameStyle: 'fieldCaptionText gradYearPlaceholder',
      },
      {
        question: 'Unweighted GPA',
        extraNote: '',
        options: [],
        type: 'number',
        innerQuestion: [],
        required: true,
        classNameStyle: 'fieldCaptionText',
      },
      {
        question: 'Weighted GPA',
        extraNote: '',
        options: [],
        type: 'number',
        innerQuestion: [],
        required: false,
        classNameStyle: 'fieldCaptionText',
      },
      {
        question: 'Please List All High Schools You Have Previously Attended.',
        extraNote: '',
        options: [],
        type: 'async-multi-select',
        innerQuestion: [],
        required: false,
        query: 'highschool',
      },
      {
        question:
          'Please list any AP classes you have taken, or are currently taking.',
        extraNote: '',
        options: [],
        type: 'simple-multi-select',
        innerQuestion: [],
        required: false,
        className: 'coursesSelect',
      },
      {
        question:
          'Please list any IB classes you have taken, or are currently taking.',
        extraNote: '',
        options: [],
        type: 'simple-multi-select',
        innerQuestion: [],
        required: false,
        className: 'coursesSelect',
      },
      {
        question:
          'List any other honors classes you have taken or are currently taking.',
        extraNote: '',
        options: [],
        type: 'textarea',
        innerQuestion: [],
        required: false,
      },
      {
        question:
          'What Extracurricular Activites are you involved in? (e.g. Clubs, Volunteering, Internships, etc.)',
        extraNote: '',
        options: [],
        type: 'button',
        innerQuestion: [],
        required: false,
      },
    ],
  },
  {
    step: 3,
    title: `Please Provide Your Standardized Test Scores in the Following Questions. Skip any questions for which you don't have scores`,
    stepperHeading: 'Scores',
    data: [
      {
        question: 'PSAT Score',
        extraNote: '',
        options: [],
        type: 'number',
        innerQuestion: [],
        required: false,
      },
      {
        question: 'SAT Score',
        extraNote: '',
        options: [],
        type: 'number',
        innerQuestion: [],
        required: false,
      },
      {
        question: 'ACT Score',
        extraNote: '',
        options: [],
        type: 'number',
        innerQuestion: [],
        required: false,
      },
      {
        question:
          'Please List All Your SAT Subject Test Scores Format: Subject Test Name, Score.',
        extraNote: '',
        options: [],
        type: 'text',
        innerQuestion: [],
        required: false,
      },
      {
        question: 'Please List All Your AP Scores Format: Course Name, Score.',
        extraNote: '',
        options: [],
        type: 'text',
        innerQuestion: [],
        required: false,
      },
      {
        question: 'TOEFL Score',
        extraNote: '',
        options: [],
        type: 'number',
        innerQuestion: [],
        required: false,
      },
      {
        question: 'Are there any colleges you are currently considering?',
        extraNote: '',
        options: [],
        type: 'async-multi-select',
        innerQuestion: [],
        query: 'college',
        required: false,
        className: 'coursesSelect',
      },
    ],
  },

  {
    step: 4,
    title:
      "In the following questions, please provide your parent's contact information so we can work with them to ensure your success throughout the college application process",
    stepperHeading: "Parent's Information",
    data: [
      {
        question: 'Full Name of Parent or Guardian.',
        extraNote: '',
        options: [],
        type: 'text',
        innerQuestion: [],
        required: true,
      },
      {
        question: "What is your Parent's / Guardian's Relationship to You?",
        extraNote: '',
        options: [],
        type: 'text',
        innerQuestion: [],
        required: true,
      },
      {
        question: 'What is the Best Phone Number We Can Use To Reach Them?',
        extraNote: '',
        options: [],
        type: 'phone',
        innerQuestion: [],
        required: true,
      },
      {
        question: 'What is their Email Address?',
        extraNote: '',
        options: [],
        type: 'email',
        innerQuestion: [],
        required: true,
      },
    ],
  },
  {
    step: 5,
    title:
      "Alright, let's talk about how we can support you during your college application journey!",
    stepperHeading: 'Your Interests',
    data: [
      {
        question:
          'What are your Top 3 priorities as you begin your CollegeAdvisor experience?',
        extraNote: '',
        options: [
          'Identifying a list of target schools',
          'Creating a final, well balanced list of schools',
          'Building a personalized timeline of application tasks to accomplish',
          'Organizing all of your application related tasks',
          'Developing a personal strategy to help you stand out in the college admission process',
          'Brainstorming, crafting, and editing the main Common Application essay, Coalition Application essay, and school specific essays as well as any supplemental essays',
          'Learning how to conduct college research and make the most of college visits',
          'Preparing for college interviews',
          'Learning about and beginning the financial aid process',
          'Identifying and applying for scholarships',
          'other',
        ],
        type: 'simple-multi-select',
        innerQuestion: [
          {
            question: 'Other (fill in answer)',
            options: [],
            type: 'text',
          },
        ],
        required: true,
        validationRequired: true,
      },
      {
        question:
          'What major are you interested in studying? What academic areas would you like to explore or are open to?',
        extraNote: '',
        options: [
          'Agricultural/Animal/Plant/Veterinary Science and Related Fields',
          'Architecture and Related Services',
          'Area, Ethnic, Cultural, Gender, and Group Studies',
          'Basic Skills and Developmental/Remedial Education',
          'Biological and Biomedical Sciences',
          'Business, Management, Marketing, and Related Support Services',
          'Citizenship Activities',
          'Communication, Journalism, and Related Programs',
          'Communications Technologies/Technicians and Support Services',
          'Computer and Information Sciences and Support Services',
          'Construction Trades',
          'Culinary, Entertainment, and Personal Services',
          'Education',
          'English Language and Literature/Letters',
          'Family and Consumer Sciences/Human Sciences',
          'Foreign Languages, Literatures, and Linguistics',
          'Health Professions and Related Programs',
          'History',
          'Homeland Security, Law Enforcement, Firefighting and Related Protective Services',
          'Interpersonal and Social Skills',
          'Leisure and Recreational Activities',
          'Liberal Arts and Sciences, General Studies and Humanities',
          'Library Science',
          'Mathematics and Statistics',
          'Mechanic and Repair Technologies/Technicians',
          'Military Technologies and Applied Sciences',
          'Multi/Interdisciplinary Studies',
          'Natural Resources and Conservation',
          'Parks, Recreation, Leisure, Fitness, and Kinesiology',
          'Personal Awareness and Self-Improvement',
          'Philosophy and Religious Studies',
          'Pre-Med/Health',
          'Pre-Law',
          'Psychology',
          'Public Administration and Social Service Professions',
          'STEM',
          'Science Technologies/Technicians',
          'Theology and Religious Vocations',
          'Transportation and Materials Moving',
          'Visual and Performing Arts',
          'other',
        ],
        type: 'simple-multi-select',
        innerQuestion: [
          {
            question: 'What other topics are you interested in?',
            options: [],
            type: 'text',
          },
        ],
        required: true,
        validationRequired: true,
      },
    ],
  },
  {
    step: 6,
    title:
      "We're almost done! These final questions will help us match you with an advisor tailored to your needs.",
    stepperHeading: 'Information to Match Best Advisor',
    data: [
      {
        question: 'Do You Have a Language Preference For Your Advisor?',
        extraNote:
          ' We have Advisors who have fluency in many different languages. Please let us know if you or your family would like to work with someone who speaks a language other than English. We will do our best to accommodate you.',
        options: ['Yes', 'No'],
        type: 'radio',
        innerQuestion: [
          {
            title: 'What is Your Language Preference?',
            question: 'What is Your Language Preference?',
            options: [],
            type: 'simple-multi-select',
          },
        ],
        required: true,
        className: 'languagesSelect',
      },
      {
        question:
          'Is There Anything Else You Would Like Us to Know About Your College Application Needs?',
        extraNote: '',
        options: [],
        type: 'text',
        innerQuestion: [],
        required: false,
      },
      {
        question:
          'What are your ideal expectations for the CollegeAdvisor program as you embark on your admissions journey?',
        extraNote: '',
        options: [],
        type: 'textarea',
        innerQuestion: [],
        required: true,
      },
    ],
  },
];

export const intakeFormQuestionsForParent = [
  {
    step: 1,
    title: "Let's start by telling us a bit about yourself",
    stepperHeading: 'Information',
    data: [
      {
        question: 'What is Your Full Name?',
        extraNote: ' Please ensure you input your full name correctly.',
        options: [],
        type: 'text',
        innerQuestion: [],
        required: true,
      },
      {
        question: "What is Your child's Full Name?",
        extraNote: " Please ensure you input your child's full name correctly.",
        options: [],
        type: 'text',
        innerQuestion: [],
        required: true,
      },
      {
        question: 'Is this your cell phone number?',
        extraNote: '',
        type: 'radio',
        options: ['Yes', 'No'],
        innerQuestion: [
          {
            question: 'What is Your Phone Number?',
            options: [],
            type: 'phone',
          },
        ],
        required: true,
      },
      {
        question: 'What is Your Email Address?',
        extraNote: '',
        type: 'email',
        options: [],
        innerQuestion: [],
        required: true,
      },
      {
        question: 'Do You Have a Language Preference For Your Advisor?',
        extraNote:
          ' We have Advisors who have fluency in many different languages. Please let us know if you or your family would like to work with someone who speaks a language other than English. Although we cannot guarantee availability, we will do our best to accommodate you.',
        type: 'text',
        options: [],
        innerQuestion: [],
        required: true,
      },
      {
        question:
          'Do You Have Other Children That Have Been Through The College Application Process Before?',
        extraNote: '',
        type: 'radio',
        options: ['Yes', 'No'],
        innerQuestion: [],
        required: true,
      },
    ],
  },
  {
    step: 2,
    title:
      "Alright, let's talk about how we can support you during your college application journey!",
    stepperHeading: 'Child Information',
    data: [
      {
        question: "What Do You Think Your Child's Strengths Are?",
        extraNote: '',
        type: 'text',
        options: [],
        innerQuestion: [],
        required: true,
      },
      {
        question: "What Do You Think Your Child's Weaknesses Are?",
        extraNote: '',
        type: 'text',
        options: [],
        innerQuestion: [],
        required: true,
      },

      {
        question:
          'What are your Top 3 priorities as you begin your CollegeAdvisor experience?',
        extraNote: '',
        type: 'simple-multi-select',
        options: [
          ' Identifying a list of target schools',
          'Creating a final, well balanced list of schools',
          'Building a personalized timeline of application tasks to accomplish',
          'Organizing all of your application related tasks',
          'Developing a personal strategy to help you stand out in the college admission process',
          'Brainstorming, crafting, and editing the main Common Application essay, Coalition Application essay, and school specific essays as well as any supplemental essays',
          'Learning how to conduct college research and make the most of college visits',
          'Preparing for college interviews',
          'Learning about and beginning the financial aid process',
          'Identifying and applying for scholarships',
          'other',
        ],
        innerQuestion: [
          {
            question: 'Others (fill in answer)',
            options: [],
            type: 'text',
          },
        ],
        required: true,
      },

      {
        question:
          'Please Share Any Other Information About Your Child That You Think Will Help Us Craft Their Personalized Application Strategy.',
        extraNote:
          ' Responses can include, but are not limited to: legacy status, high performance athlete, outstanding musical talent.',
        type: 'text',
        options: [],
        innerQuestion: [],
        required: false,
      },
    ],
  },
  {
    step: 3,
    title:
      "Alright, let's talk about how we can support you during your college application journey!",
    stepperHeading: 'Extra Details',
    data: [
      {
        question:
          'What are your hopes for the process? What are you trying to get?',
        extraNote: '',
        type: 'text',
        options: [],
        innerQuestion: [],
        required: false,
      },
      {
        question:
          'If you have begun the college application process, please tell us what you have worked on so far.',
        extraNote: '',
        type: 'text',
        options: [],
        innerQuestion: [],
        required: false,
      },
      {
        question:
          'Will financial need be a factor in the college application process?',
        extraNote: '',
        type: 'text',
        options: [],
        innerQuestion: [],
        required: false,
      },
      {
        question:
          'What are your ideal expectations for the CollegeAdvisor program as you embark on your admissions journey?',
        extraNote: '',
        options: [],
        type: 'textarea',
        innerQuestion: [],
        required: true,
      },
    ],
  },
];

export const checkIfInnerQuestionIsRequired = (title, ans) => {
  if (
    (title === 'Do You Have a Preferred Name or Nickname?' ||
      title === 'Do You Have a Gender Preference For Your Advisor?' ||
      title === 'Do You Have a Language Preference For Your Advisor?') &&
    ans === 'Yes'
  )
    return true;
  else if (
    (title === 'What are your personal pronouns?' ||
      title === 'What Grade Are You In?' ||
      title ===
        'In Which of the Following Areas are you Interested in Getting Assistance?' ||
      title ===
        'In Which of the Following Areas Do You Think Your Child Needs Assistance?' ||
      title ===
        'What major are you interested in studying? What academic areas would you like to explore or are open to?' ||
      title ===
        'What High School Do You Currently Attend? Please Include the Name, City, State and Country') &&
    ans === 'other'
  )
    return true;
  else if (title === 'Is this your cell phone number?' && ans === 'No')
    return true;
  else if (Array.isArray(ans) && ans.length) {
    const isOther = ans.find((item) => item.value === 'other');
    if (isOther) return true;
  } else return false;
};

export const loadOptionsforSpecificQuery = (inputValue, query) => {
  const queryName = query === 'college' ? ALL_COLLEGES : ALL_HIGH_SCHOOLS;
  return new Promise((resolve) => {
    // API call
    client
      .query({
        query: queryName,
        variables: { searchQuery: inputValue },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        if (query === 'college') {
          if (res?.data?.allColleges?.colleges?.length) {
            const options = res.data.allColleges.colleges.map((item) => ({
              id: item.id,
              value: item.name,
              label: item.name,
            }));
            resolve(options);
          } else resolve([]);
        } else if (query === 'highschool') {
          if (res?.data?.getHighSchools?.highSchools?.length) {
            const options = res.data.getHighSchools.highSchools.map((item) => ({
              id: item.id,
              value: item.high_school_name,
              label: item.high_school_name,
            }));
            resolve(options);
          } else
            resolve([
              {
                id: Math.random(),
                value: 'other',
                label: 'Other',
              },
            ]);
        } else {
          resolve([]);
        }
      });
  });
};
export const checkIfDisable = (enteredValues, questions) => {
  if (Object.entries(enteredValues).length) {
    let obj = {};
    const values = Object.entries(enteredValues);
    const requiredQuestions = questions.filter((item) => item.required);
    requiredQuestions.map((item) => {
      let found = false;
      for (const [key, val] of values) {
        if (item?.question.replaceAll('.', '') === key && val) {
          if (item?.validationRequired) {
            if (!_.isEmpty(val[0])) found = true;
          } else {
            found = true;
          }
        }
      }
      if (found) {
        obj[item?.question.replaceAll('.', '')] = 'filled';
      } else {
        obj[item?.question.replaceAll('.', '')] = 'empty';
      }
    });
    let disable = false;
    for (const [key, val] of Object.entries(obj)) {
      if (val === 'empty') disable = true;
    }
    return disable;
  } else return true;
};

export const getApCourses = async () => {
  const { data } = await client.query({
    query: AP_COURSES,
    fetchPolicy: 'network-only',
  });

  return data?.getApCourses;
};

export const getIbCourses = async () => {
  const { data } = await client.query({
    query: IB_COURSES,
    fetchPolicy: 'network-only',
  });

  return data?.getIbCourses;
};
export const getAllLanguages = async () => {
  const { data } = await client.query({
    query: ALL_LANGUAGES,
    fetchPolicy: 'network-only',
  });
  return data?.getAllLanguages;
};

export const getExtraCurricularCategories = async () => {
  const { data } = await client.query({
    query: GET_EXTRA_CURRICUALR_CATEGORIES,
    fetchPolicy: 'network-only',
  });

  return data?.getExtraCurricularCategories;
};
