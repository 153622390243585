import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Link } from 'react-router-dom';

import Carousel from 'react-multi-carousel';
import WebinarThumbnailCard from './WebinarThumbnailCard';

import Left_Icon from '../../img/left.svg';
import Right_Icon from '../../img/right.svg';
import { allHomePageSeries_allHomePageSeries_upcoming } from './gql/__generated__/allHomePageSeries';
import { allWebinars_allWebinars_upcoming } from './gql/__generated__/allWebinars';
import {
  getUserUpcomingAndPastWebinarsQuery_getUserUpcomingAndPastWebinars_past,
  getUserUpcomingAndPastWebinarsQuery_getUserUpcomingAndPastWebinars_upcoming,
} from './gql/__generated__/getUserUpcomingAndPastWebinarsQuery';
import { UpcomingLiveSeries_allHomePageSeries_upcoming } from './gql/__generated__/UpcomingLiveSeries';
import 'react-multi-carousel/lib/styles.css';

const useStyles = makeStyles((theme) =>
  createStyles({
    thumbaniCarousel: {
      '&:nth-child(4)': {
        marginRight: 0,
      },

      '& .react-multiple-carousel__arrow': {
        backgroundColor: 'rgba(255,255,255,0.5) !important',

        [`@media only screen and (max-width: 767px)`]: {
          display: 'none !important',
        },
      },

      '& .react-multiple-carousel__arrow--left': {
        backgroundImage: `url( ${Left_Icon}) !important`,
        left: 'calc(0% + 0px) !important',
      },

      '& .react-multiple-carousel__arrow--right': {
        backgroundImage: `url( ${Right_Icon}) !important`,
        right: 'calc(0% + 17px) !important',

        [`@media only screen and (max-width: 767px)`]: {
          right: 'calc(0% + 0px) !important',
        },
      },
    },
  })
);

const WebinarThumbnailCarousel = ({
  webinars,
  fromAdvisor,
}: {
  webinars:
    | (
        | getUserUpcomingAndPastWebinarsQuery_getUserUpcomingAndPastWebinars_upcoming
        | getUserUpcomingAndPastWebinarsQuery_getUserUpcomingAndPastWebinars_past
        | (allWebinars_allWebinars_upcoming | null)
        | allHomePageSeries_allHomePageSeries_upcoming
        | UpcomingLiveSeries_allHomePageSeries_upcoming
        | null
        | null
      )[]
    | null;
  fromAdvisor: Boolean | null;
}): JSX.Element => {
  const classes = useStyles();

  const responsive = {
    largedesktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 5,
      slidesToSlide: 3,
    },
    desktop: {
      breakpoint: { max: 1199, min: 1025 },
      items: 4,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1,
    },
    ipad: {
      breakpoint: { max: 767, min: 600 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 599, min: 376 },
      items: 2,
      slidesToSlide: 1,
      partialVisibilityGutter: 5,
    },
    iphone: {
      breakpoint: { max: 375, min: 320 },
      items: 2,
      slidesToSlide: 1,
      partialVisibilityGutter: 5,
    },
  };

  return (
    <Grid className="webinarCarousel">
      <Grid className={classes.thumbaniCarousel}>
        <Carousel
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          customTransition="transform 300ms ease-in-out"
          transitionDuration={500}
          containerClass="carousel-container"
          partialVisible={true}
        >
          {webinars?.map((item, index) => {
            return (
              <React.Fragment key={`webinar-card-${index}`}>
                <Link
                  to={{
                    pathname: `/webinar/${item?.id}${window.location.search}`,
                  }}
                >
                  <WebinarThumbnailCard
                    title={item?.title}
                    about={item?.about}
                    coverTileMaster={item?.coverTileMaster || ''}
                    date={item?.startTime || ''}
                  />
                </Link>
              </React.Fragment>
            );
          })}
        </Carousel>
      </Grid>
    </Grid>
  );
};

export default WebinarThumbnailCarousel;
