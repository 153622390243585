import {
  Box,
  Button,
  Card,
  Grid,
  TextField as MaterialField,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import gql from 'graphql-tag';
import { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Mutation } from 'react-apollo';
import { Link, RouteComponentProps } from 'react-router-dom';

import { TextField } from '../../components/fields';
import SmallLogo from '../../components/layout/SmallLogo';
import CustomAlert from '../../components/NewCustomAlert';
import ValidatationCheckMark from './../../components/ValidationCheckMark';
import { PasswordSchema } from './LeadRegistration/lib/validationSchemas';
import RightSidePage from './LeadRegistration/RightSidePage';
import { useStyles } from './styles';

import client from '../../apollo';
import Loader from '../../components/Loader';
import { TOKEN } from '../../constants';
import { AppContext } from '../../contexts';
import { GET_USER_FROM_USER_TOKEN } from '../main/intakeForm/gql';
import { getUserFromUserTokenQuery } from '../main/intakeForm/gql/__generated__/getUserFromUserTokenQuery';
import { customTheme } from '../MaterializeCss';
import {
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from './__generated__/ResetPasswordMutation';

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation(
    $token: String!
    $password: String!
    $step: String
  ) {
    resetPassword(input: { token: $token, password: $password, step: $step }) {
      status
      jwtToken
      role
      lastLoginAt
      isSmartMatch
      intakeFormToken
      SmartMatchId
    }
  }
`;

type TParams = { token: string };
const NewResetPasswordPage: FC<RouteComponentProps<TParams>> = ({
  history,
  match: { params },
}) => {
  const initialValues = { password: '', confirmPassword: '' };
  const classes = useStyles();
  const [variant, setVariant] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [userToken, setUserToken] = useState('');
  const [parentorStudent, setParentorStudent] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const goBack = urlParams.get('_goback');
  const step = urlParams.get('step');

  function toggleAlert(variant?: string, message?: string) {
    setVariant(variant);
    setMessage(message);
  }

  const fetchUser = async () => {
    const { data }: { data: getUserFromUserTokenQuery } = await client.query({
      query: GET_USER_FROM_USER_TOKEN,
      variables: {
        userToken: userToken,
      },
      fetchPolicy: 'network-only',
    });
    if (data && data?.getUserFromUserToken?.user) {
      const {
        email,
        roles,
        isPasswordSet,
        isSmartMatch,
        intakeFormToken,
        SmartMatchId,
      } = data?.getUserFromUserToken?.user;
      if (isPasswordSet && step) {
        const emailEncoded = encodeURIComponent(email); // encode the email
        if (roles?.includes('Parent') && isSmartMatch && intakeFormToken) {
          window.location.replace(`/intake-form/${intakeFormToken}`);
        } else if (roles?.includes('Student') && SmartMatchId) {
          // block: smart-match students with password set
          window.location.replace(`/home?schedule=true&email=${emailEncoded}`);
        } else {
          window.location.replace(`/login?email=${emailEncoded}`);
        }
      } else {
        setLoading(false);
      }
      setEmail(email);
      if (roles?.includes('Student')) {
        setParentorStudent('Student');
      } else {
        setParentorStudent('Parent');
      }
    } else {
      window.location.replace('/login');
    }
  };

  const mountEffectOnce = () => {
    setUserToken(params.token);
    if (userToken) fetchUser();
  };

  const { setIsLoggedIn } = useContext(AppContext);

  useEffect(mountEffectOnce, [userToken]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Card className={`${classes.cardModal} custom-card`}>
          <Box className={classes.registerContainer}>
            <Box className={classes.registerLeft}>
              <CustomAlert
                variant={variant}
                message={message}
                toggleAlert={toggleAlert}
              />

              <Mutation<ResetPasswordMutation, ResetPasswordMutationVariables>
                mutation={RESET_PASSWORD_MUTATION}
              >
                {(resetPasswordMutation: Function) => {
                  return (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={PasswordSchema}
                      onSubmit={async (values, { setSubmitting }) => {
                        try {
                          const { password, confirmPassword } = values;
                          if (password !== confirmPassword) {
                            toggleAlert(
                              'danger',
                              'Password and Confirm Password not same'
                            );

                            return setSubmitting(false);
                          }
                          const { token } = params;
                          const result = await resetPasswordMutation({
                            variables: { password, token, step },
                          });
                          const {
                            data: {
                              resetPassword: {
                                status,
                                jwtToken,
                                role,
                                lastLoginAt,
                                isSmartMatch,
                                intakeFormToken,
                                SmartMatchId,
                              },
                            },
                          } = result;

                          setSubmitting(false);

                          const firstTimeLogin = lastLoginAt ? false : true;
                          localStorage.setItem(
                            'firstTimeLogin',
                            String(firstTimeLogin)
                          );
                          localStorage.setItem(TOKEN, jwtToken);
                          setIsLoggedIn(true);

                          if (!step && status) {
                            toggleAlert('success', 'Password updated,');
                            history.push('/');
                          } else {
                            toggleAlert(
                              'danger',
                              step
                                ? 'An error occurred while setting password'
                                : 'Unable to reset password, may be password reset token expired, request another one.'
                            );
                          }

                          if (step && status) {
                            if (role === 'Student') {
                              if (SmartMatchId) {
                                history.push('/home?schedule=true');
                              } else history.push('/');
                            } else if (role === 'Parent') {
                              if (isSmartMatch && intakeFormToken) {
                                history.push(`/intake-form/${intakeFormToken}`);
                              } else history.push('/');
                            }
                          }
                        } catch (error) {
                          setSubmitting(false);
                          toggleAlert(
                            'danger',
                            'Something went wrong, Please try again'
                          );
                          console.log('error: ', error);
                        }
                      }}
                    >
                      {({
                        isSubmitting,
                        touched,
                        values,
                        isValid,
                        setFieldValue,
                      }) => {
                        const letters = /[a-zA-Z]/g;
                        const numbers = /[0-9]/g;
                        return (
                          <Form>
                            <Box
                              maxWidth={390}
                              margin="0  auto"
                              marginBottom={10}
                              width="100%"
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                marginBottom={7.5}
                              >
                                <Box maxWidth={48} marginRight={2}>
                                  <SmallLogo />
                                </Box>

                                <Box component="h4">
                                  {step ? 'Set Password' : 'Reset Password'}
                                </Box>
                              </Box>
                            </Box>
                            <Box maxWidth={370} margin="10px auto">
                              {step && (
                                <Field
                                  variant="filled"
                                  value={email}
                                  name="userEmail"
                                  component={TextField}
                                  label={
                                    !loading
                                      ? `${
                                          parentorStudent === 'Student'
                                            ? "Student's"
                                            : "Parent's"
                                        } Email`
                                      : 'Email'
                                  }
                                  placeholder="Email"
                                  disabled={true}
                                  onChange={(
                                    e: FormEvent<HTMLInputElement>
                                  ) => {
                                    const {
                                      currentTarget: { value },
                                    } = e;
                                    setFieldValue('studentEmail', value);
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  className={classes.textfield}
                                />
                              )}

                              <Field name="aidOption" type="password">
                                {({ field }: any) => (
                                  <MaterialField
                                    label={'Password'}
                                    type={'password'}
                                    placeholder={'Password'}
                                    variant="filled"
                                    value={field.value}
                                    onChange={(e: any) => {
                                      const {
                                        currentTarget: { value },
                                      } = e;
                                      setFieldValue('password', value);
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    className={classes.textfield}
                                  />
                                )}
                              </Field>

                              <Field
                                type="password"
                                name="confirmPassword"
                                component={TextField}
                                variant="filled"
                                label="Confirm Password"
                                placeholder="Confirm Password"
                                onChange={(e: FormEvent<HTMLInputElement>) => {
                                  const {
                                    currentTarget: { value },
                                  } = e;
                                  setFieldValue('confirmPassword', value);
                                }}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                className={classes.textfield}
                              />

                              <Box
                                marginTop={0}
                                display="flex"
                                justifyContent="space-between"
                              >
                                <ValidatationCheckMark
                                  helperText="8 character minimum"
                                  type={
                                    !touched.password &&
                                    values.password.length === 0
                                      ? ``
                                      : values.password &&
                                        values.password.length >= 8
                                      ? `success`
                                      : `error`
                                  }
                                />
                                <ValidatationCheckMark
                                  helperText="Contain 1 letter"
                                  type={
                                    !touched.password &&
                                    values.password.length === 0
                                      ? ''
                                      : letters.test(values.password)
                                      ? `success`
                                      : `error`
                                  }
                                />
                                <ValidatationCheckMark
                                  helperText="Contain 1 number"
                                  type={
                                    !touched.password &&
                                    values.password.length === 0
                                      ? ''
                                      : numbers.test(values.password)
                                      ? `success`
                                      : `error`
                                  }
                                />
                              </Box>
                              <ThemeProvider theme={customTheme}>
                                <Box marginTop={3.2} textAlign="center">
                                  <Button
                                    fullWidth
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                    className="goDashboard"
                                    disabled={!isValid || isSubmitting}
                                  >
                                    {step
                                      ? 'Go To My Dashboard'
                                      : 'Reset Password'}
                                  </Button>
                                </Box>
                              </ThemeProvider>
                            </Box>
                          </Form>
                        );
                      }}
                    </Formik>
                  );
                }}
              </Mutation>
              {!step && (
                <Grid className={classes.FormBottomSection}>
                  <Typography
                    variant="body1"
                    className={classes.FormBottomText}
                  >
                    Remembered your password? Return to{' '}
                    <Link
                      to={`${
                        goBack ? `/login${window.location.search}` : `/login`
                      }`}
                    >
                      Log In
                    </Link>
                  </Typography>
                </Grid>
              )}
            </Box>
            <RightSidePage />
          </Box>
        </Card>
      )}
    </>
  );
};

export default NewResetPasswordPage;
