import { FormEvent } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

import { Field } from 'formik';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import { Box, Grid, Typography } from '@material-ui/core';

import { getYearsArray } from './lib';

import Dropdown from '../../../components/Dropdown';
import { TextField } from '../../../components/fields';

import { useStyles } from '../styles';

import colors from '../../../common/colors';
import fonts from '../../../common/fonts';

const StudentInformation = ({
  setFieldValue,
  parentorStudent,
  yearValue,
  setYearValue,
  errors,
  touched,
  fromMeetingForm,
}: {
  setFieldValue: Function;
  setYearValue: Function;
  parentorStudent: string;
  yearValue: string;
  errors: any;
  touched: any;
  fromMeetingForm?: boolean;
}) => {
  const classes = useStyles();
  const location = useLocation();
  const isMeetingSetPage = location.pathname.includes('schedule');

  return (
    <>
      {!fromMeetingForm && parentorStudent === 'Parent' && (
        <Box className={classes.sectionHeading} paddingBottom={5}>
          <span className="heading-number">2.</span>Student Information
        </Box>
      )}
      <Grid className={classes.fieldContainer}>
        <Field
          variant="filled"
          name="studentFirstName"
          component={TextField}
          label="First Name"
          placeholder="First Name"
          onChange={(e: FormEvent<HTMLInputElement>) => {
            const {
              currentTarget: { value },
            } = e;
            setFieldValue('studentFirstName', value);
          }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          className={clsx(classes.textfield, classes.textfieldMobile)}
        />

        <Field
          variant="filled"
          name="studentLastName"
          component={TextField}
          label="Last Name"
          placeholder="Last Name"
          onChange={(e: FormEvent<HTMLInputElement>) => {
            const {
              currentTarget: { value },
            } = e;

            setFieldValue('studentLastName', value);
          }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          className={classes.textfield}
        />
      </Grid>
      <Field
        variant="filled"
        name="studentEmail"
        component={TextField}
        label={isMeetingSetPage ? 'Student Email Address' : "Student's Email"}
        placeholder={
          isMeetingSetPage ? 'Student Email Address' : "Student's Email"
        }
        onChange={(e: FormEvent<HTMLInputElement>) => {
          const {
            currentTarget: { value },
          } = e;
          setFieldValue('studentEmail', value);
        }}
        InputLabelProps={{ shrink: true }}
        fullWidth
        className={classes.textfield}
      />
      <Field
        name="studentPhone"
        placeholder="Student's Phone Number"
        validate={(value: any | string) => {
          if (value && !isValidPhoneNumber(value)) {
            return 'Invalid Phone Number';
          }
        }}
      >
        {({
          field,
          form: { errors, handleBlur, ...rest },
          ...otherProps
        }: any) => {
          return (
            <Box className="phoneInputMasterResolve">
              <Box
                className={`${classes.phoneInputNew} ${
                  errors.studentPhone ? 'error' : ''
                }`}
                textAlign="left"
              >
                <label htmlFor="phoneNumber">
                  {isMeetingSetPage
                    ? 'Student Phone Number'
                    : "Student's Phone Number"}
                </label>
                <PhoneInput
                  name="studentPhone"
                  placeholder={
                    isMeetingSetPage
                      ? 'Student Phone Number'
                      : "Student's Phone Number"
                  }
                  value={field.value}
                  defaultCountry="US"
                  {...rest}
                  {...otherProps}
                  onBlur={handleBlur}
                  onChange={(value) => {
                    setFieldValue(field.name, value);
                  }}
                  error={errors.studentPhone}
                />

                {touched.studentPhone && errors.studentPhone && (
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                    {errors.studentPhone}
                  </p>
                )}
              </Box>
            </Box>
          );
        }}
      </Field>

      <Box maxWidth={370}>
        <Grid
          spacing={2}
          container
          className={isMeetingSetPage ? '' : classes.leadRegisterGraduation}
        >
          <Grid
            item
            xs={isMeetingSetPage ? 12 : 7}
            className={
              isMeetingSetPage
                ? clsx(classes.selectDropdown, classes.selectDropdownMeeting)
                : classes.selectDropdown
            }
          >
            <Dropdown
              label={isMeetingSetPage ? 'Grad Year' : 'High School Grad Year'}
              errorValue={
                touched.highSchoolYear && !yearValue && errors.highSchoolYear
              }
              selectedValue={yearValue || ''}
              options={getYearsArray(4)}
              isError={
                touched.highSchoolYear &&
                (!yearValue || Number(yearValue) > 2027)
              }
              name="year"
              emptyDisplay={true}
              onSelect={(evt: any) => {
                const {
                  target: { value },
                } = evt;
                setYearValue(value);
                if (value !== 'Other')
                  setFieldValue('highSchoolYear', value.toString());
              }}
            />

            <Box
              color={colors.GRAY_TWENTYTWO}
              width={340}
              fontSize={10}
              mt={1}
              fontFamily={fonts.INTER}
            >
              <Typography variant="caption">
                {parentorStudent === 'Parent'
                  ? '*Your student must be at least 13 years of age to have an account.'
                  : '*You must be at least 13 years of age to create an account.'}
              </Typography>
            </Box>

            <Box
              color={colors.GRAY_TWENTYTWO}
              width={220}
              mt={1}
              fontSize={10}
              fontFamily={fonts.INTER}
            >
              <Typography
                variant="caption"
                className={classes.graduatedCollegeAlready}
              >
                Already graduated college?{' '}
                <a href="/register?mba=true">Click here</a>
              </Typography>
            </Box>
          </Grid>

          {yearValue === 'Other' && (
            <Grid item xs={5} className={classes.textfieldGraduation}>
              <Typography variant="body1">Grad Year</Typography>
              <Field
                variant="filled"
                name="highSchoolYear"
                component={TextField}
                label="Grad Year"
                placeholder="Grad Year"
                onChange={(e: FormEvent<HTMLInputElement>) => {
                  const {
                    currentTarget: { value },
                  } = e;

                  const numberArray: RegExpMatchArray | null =
                    value.match(/\d+/g);
                  if (numberArray) {
                    setFieldValue('highSchoolYear', numberArray.toString());
                  } else {
                    setFieldValue('highSchoolYear', '');
                  }
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                className={classes.textfield}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default StudentInformation;
