import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

import colors from '../common/colors';
import fonts from '../common/fonts';
import SELECT_ARROW_ICON from '../../src/img/select-arrow-icon.svg';
export const foo = 'foo';
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalPaper: {
      position: 'absolute',
      maxWidth: 528,
      width: '100%',
      padding: '0px 20px',
      backgroundColor: colors.WHITE_ONE,
      outline: 'none',
      overflowY: 'auto',
      opacity: 1,
      borderRadius: 4,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',

      '@media only screen and (max-width: 767px)': {
        height: '100vh',
        borderRadius: 0,
      },
    },

    modalBody: {
      padding: '11px 0px 47px',

      '@media only screen and (max-width: 767px)': {
        padding: '62px 0px 40px',
      },

      '& p': {
        fontSize: 16,
        lineHeight: '20px',
        letterSpacing: '0.3375px',
        color: colors.BLACK_SIX,
        margin: '14px 0px 0px',
      },

      '& .delete_button': {
        fontWeight: 600,
        fontSize: 12,
        lineHeight: '16px',
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: '0.77px',
        color: `${colors.WHITE_ONE} !important`,
        borderRadius: 100,
        background: colors.RED_ONE,
        padding: '8px 18px',
        textDecoration: 'none',
        marginRight: 10,

        '&:hover': {
          opacity: 0.9,
          background: colors.RED_ONE,
        },
      },

      '& button': {
        width: 142,

        '@media only screen and (max-width: 767px)': {
          width: '100%',
          marginBottom: 20,
        },
      },

      '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: colors.RED_ONE,
      },

      '& .MuiCheckbox-root': {
        color: colors.GRAY_NINE,
      },

      '& .MuiIconButton-colorSecondary:hover': {
        backgroundColor: 'rgba(39, 174, 96, 0.08)',
      },

      '& .MuiTypography-body1': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: '20px',
        letterSpacing: '0.3375px',
        color: colors.BLACK_SIX,
      },

      '& .checkbox_form_group': {
        paddingLeft: 35,
        marginBottom: 41,
        textAlign: 'left',

        '@media only screen and (max-width: 480px)': {
          paddingLeft: 0,
        },
      },
    },

    modalHeader: {
      marginBottom: 23,

      '@media only screen and (max-width: 767px)': {
        marginBottom: 17,
      },

      '& h3': {
        fontWeight: 600,
        fontSize: 45,
        lineHeight: '84px',
        textAlign: 'center',
        letterSpacing: '-2.5px',
        color: colors.BLACK_THREE,
        margin: 0,

        '@media only screen and (max-width: 767px)': {
          fontSize: 26,
          lineHeight: '30px',
          letterSpacing: '0px',
          marginBottom: 7,
        },
      },

      '& p': {
        fontSize: 20,
        lineHeight: '26px',
        textAlign: 'center',
        letterSpacing: '0.3375px',
        color: colors.BLACK_THREE,
        margin: 0,

        '@media only screen and (max-width: 767px)': {
          fontSize: 16,
          lineHeight: '20px',
        },
      },
    },

    buttonDark: {
      backgroundColor: 'transparent',
      fontSize: 12,
      height: 32,
      color: colors.BLACK_SIX,
      padding: '0px 16px',
      border: `1px solid ${colors.BLACK_SIX}`,
      lineHeight: '16px',

      '&:hover': {
        color: colors.WHITE_ONE,
        background: colors.BLACK_SIX,
        opacity: 0.9,
      },
    },
    confirmationDialog: {
      '& [role="dialog"]': {
        width: 488,
        maxWidth: 488,
        border: `1px solid ${colors.WHITE_SEVEN}`,
        borderRadius: 25,
        padding: '35px 34px 31px',
        margin: 10,
        overflowY: 'unset',

        [theme.breakpoints.down('xs')]: {
          padding: 16,
        },
      },

      '& .subTitleText': {
        fontSize: 14,
        fontWeight: 400,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        color: colors.BLACK_FOURTEEN,
      },

      '& .dangerButton': {
        '&:hover': {
          backgroundColor: `${colors.RED_DARK_HOVER} !important`,
        },

        '&.Mui-disabled': {
          opacity: 0.5,
          color: colors.WHITE_ONE,
        },
      },

      '& .spinner-border-sm': {
        fontSize: 10,
      },
    },

    accountInformationForm: {
      '& .mobileFieldSpacing': {
        [theme.breakpoints.down('xs')]: {
          marginBottom: '18px !important',
        },
      },

      '& .fieldCaption': {
        marginBottom: 4,
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: '14px !important',
        letterSpacing: '-0.006em',
        color: colors.BLACK_TWELVE,
      },

      '& .mobileFlex': {
        [theme.breakpoints.down('xs')]: {
          alignItems: 'flex-start',
          flexDirection: 'column',
          margin: 0,
        },

        '& .MuiInputBase-root, & .MuiBox-root': {
          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },
        },
      },

      '& .editButton': {
        marginLeft: 15,
      },

      '& .infoCardHeaderChild': {
        padding: '10px 0px',
        marginBottom: 20,
      },

      '& .textFieldCaptionDialog': {
        margin: 0,
      },

      '& .textFieldSubtitleDialog': {
        fontWeight: 400,
        fontSize: 12,
        color: '#A9A9A9',
        marginBottom: 6,
      },

      '& .calendarFields': {
        marginRight: 20,

        '& .MuiInputBase-root': {
          fontWeight: 400,
          fontSize: 14,
          color: '#272929',
          letterSpacing: '-0.006em',
          fontFamily: fonts.INTER,
          borderRadius: 6,
          padding: '11px 16px',
          border: '1px solid #D4D4D4',
        },

        '& .MuiInputBase-input': {
          padding: 0,
        },
      },

      '& .zoomLink': {
        maxWidth: 384,
        width: '100%',
        marginRight: 25,

        '& .MuiInputBase-inputMultiline': {
          fontWeight: 400,
          fontSize: 14,
          color: '#272929',
          letterSpacing: '-0.006em',
          fontFamily: fonts.INTER,
          borderRadius: 6,
          padding: '11px 16px',
          border: '1px solid #D4D4D4',
        },

        '& .MuiFormControl-root': {
          width: '100%',
        },
      },

      '& .MuiInputBase-multiline': {
        padding: 0,
      },

      '& .MuiFormControlLabel-root': {
        marginBottom: 0,

        '& .MuiFormControlLabel-label': {
          fontWeight: 400,
          fontSize: 14,
          letterSpacing: '-0.011em',
          color: '#7D7F7F',
          fontFamily: fonts.INTER,
        },
      },

      '& .MuiCheckbox-colorPrimary': {
        color: '#7D7F7F',
      },

      '& .acceptedToDropdown': {
        width: '100% !important',

        '& .MuiInputBase-root': {
          padding: '6px 11px',
          maxHeight: '200px !important',
          overflowY: 'auto',
          height: 'auto',
          display: 'flex',
          alignItems: 'baseline',

          [`@media only screen and (max-height: 500px)`]: {
            height: 110,
          },
        },
      },

      '& .timeZoneSelect': {
        width: 521,

        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },

        '& .MuiInputBase-root': {
          width: '100%',
        },

        '& .react-select__indicator-separator, & .react-select__indicator': {
          display: 'none',
        },
      },

      '& .genderDropdownIcon': {
        '& .MuiSvgIcon-root': {
          display: 'none',
        },
      },

      '& .genderDropdown': {
        marginRight: 20,

        '& .MuiInputBase-root': {
          width: 113,
          borderRadius: 6,
          padding: '0px 16px',
          border: '1px solid #D4D4D4',
          fontWeight: 400,
          fontSize: 14,
          color: '#272929',
          letterSpacing: '-0.006em',
          fontFamily: fonts.INTER,
          margin: 0,
          height: 41,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center right 16px',
          backgroundImage: `url(${SELECT_ARROW_ICON})`,
        },

        '& .MuiChip-deleteIcon': {
          width: 16,
          color: '#96BFF5',
        },

        '& .MuiSelect-select:focus': {
          backgroundColor: 'transparent',
        },
      },

      '& .fieldsBoxSpacing': {
        marginBottom: 18,
      },

      '& .captionSpacing': {
        margin: '0px !important',
      },

      '& .captionMargin': {
        marginBottom: 4,
      },

      '& .cautionIcon': {
        padding: 0,
        marginLeft: 15,
        width: 15,
      },

      '& .nameField': {
        width: 182,
        marginRight: 20,

        [theme.breakpoints.down('xs')]: {
          width: '100%',
          margin: '0px 0px 10px',
        },
      },

      '& .emailField': {
        width: 384,
        marginRight: 25,

        [theme.breakpoints.down('xs')]: {
          marginRight: 0,
        },

        [theme.breakpoints.down('md')]: {
          width: '95%',
        },
      },

      '& .infoText': {
        fontSize: 12,
        fontWeight: 500,
        fontFamily: fonts.POPPINS_FONT,
        color: colors.BLUE_SEVEN,
      },

      '& .textFieldCaption': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        fontFamily: fonts.INTER,
        marginBottom: 4,
      },

      '& .styleTextField': {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },

        '& .MuiInputBase-root': {
          fontWeight: 400,
          fontSize: 14,
          color: '#272929',
          letterSpacing: '-0.006em',
          fontFamily: fonts.INTER,
          borderRadius: 6,
          padding: '11px 16px',
          border: '1px solid #D4D4D4',
        },

        '& .MuiInputBase-input': {
          padding: 0,
        },
      },

      '& .MuiInput-underline::after, & .MuiInput-underline::before': {
        display: 'none',
      },

      '& .saveChanges': {
        minWidth: 135,
        height: 40,
        background: '#2F80ED',
        borderRadius: 12,
        fontWeight: 700,
        fontSize: 14,
        color: '#ffffff',
        letterSpacing: '-0.006em',
        textTransform: 'capitalize',
        fontFamily: fonts.POPPINS_FONT,
        marginTop: 30,
        padding: '0px 10px',

        '&:hover': {
          backgroundColor: '#2668C1 !important',
        },
      },
    },

    advisorSelectDropDown: {
      '& .react-select__control': {
        borderRadius: 6,
        padding: '3px 16px',
        fontWeight: 400,
        fontSize: 14,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        color: colors.BLACK_TWELVE,
        backgroundRepeat: 'no-repeat',
        borderColor: colors.WHITE_SEVEN,
        backgroundPosition: 'center right 16px',
        backgroundImage: `url(${SELECT_ARROW_ICON})`,
      },

      '& .react-select__indicator, & .react-select__indicator-separator': {
        display: 'none',
      },

      '& .react-select__placeholder': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        fontFamily: fonts.INTER,
        color: colors.GRAY_TWENTYTWO,
      },

      '& .react-select__value-container': {
        padding: 0,
      },

      '& .react-select__menu': {
        margin: 0,
        zIndex: 999999,
        overflow: 'hidden',
        borderRadius: '0px 0px 6px 6px',
        background: colors.WHITE_ONE,
      },

      '& .react-select__option': {
        fontSize: 14,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        paddingLeft: 19,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontWeight: 'normal',
        textOverflow: 'ellipsis',
        color: colors.BLACK_THIRTEEN,

        '&--is-focused, &--is-selected': {
          backgroundColor: colors.SELECT_OPTION_ACTIVE,
        },

        '&:hover': {
          cursor: 'pointer',
          background: colors.GRAY_BOREDER,
        },
      },
    },
    otherReason: {
      width: '100%',
      borderColor: colors.WHITE_SEVEN,
      borderRadius: '6px',
      padding: '6px 12px',
      marginBottom: 25,
      fontFamily: fonts.INTER,
      fontWeight: 400,
      fontSize: 14,
      color:colors.BLACK_TWELVE,
      
      "&::placeholder":{
        color: colors.GRAY_TWENTYTWO,
      },
      
      '&:focus-visible': {
        outline: 'none',
      }
    }
  })
);
