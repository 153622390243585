import {
  Box,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import colors from '../../../common/colors';

import ArrowIcon from '../../../img/light-arrow-next.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    webinarsCard: {
      borderRadius: 25,
      marginBottom: 15,
      padding: '25px 37px',
      backgroundColor: colors.LIGHT_GREEN_ONE,

      '&:hover': {
        backgroundColor: colors.green_hover_color,
      },

      '& h4': {
        fontSize: 24,
        fontWeight: 700,
        color: colors.WHITE_ONE,
        letterSpacing: '-0.019em',
      },
    },
  })
);

export const WebinarRouteCard = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.webinarsCard}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4">Webinars</Typography>

        <IconButton>
          <img src={ArrowIcon} alt="icon" />
        </IconButton>
      </Box>
    </Grid>
  );
};
