import { FC } from 'react';
import {
  makeStyles,
  createStyles,
  Box,
  Typography,
  Button,
} from '@material-ui/core';

import Colors from '../../../common/colors';

const useStyles = makeStyles(() =>
  createStyles({
    intakeInfoMain: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      margin: 'auto',
      maxWidth: 550,
      flex: 1,
    },
  })
);

interface Props {
  title: string;
  description?: string | null;
  instructions?: string;
  buttonText: string;
  estimatedTime?: string;
  letsGoClick: Function;
  titleMaxWidth?: boolean | null;
  continueInstructions?: string | null;
}

const IntakeFormInfo: FC<Props> = ({
  title,
  description,
  instructions,
  buttonText,
  estimatedTime,
  letsGoClick,
  continueInstructions,
  titleMaxWidth,
}) => {
  const classes = useStyles();

  return (
    <Box component="section" className={classes.intakeInfoMain}>
      <Box>
        <Box pb={2} maxWidth={titleMaxWidth ? 330 : 'none'} margin="auto">
          <Typography variant="h3" component="h3">
            {title}
          </Typography>
        </Box>

        {description &&
          description.split('--').map((item, index) => (
            <Box
              key={`description--${index}`}
              pb={description.includes('--') && index === 0 ? 2 : 3}
              paddingX={1.5}
              color={Colors.BLACK_TEN}
            >
              <Typography variant="body1" component="p" color="inherit">
                {item}
              </Typography>
            </Box>
          ))}

        {instructions && (
          <Box pb={4.7} color={Colors.BLACK_TEN}>
            <Typography variant="body2" component="p" color="inherit">
              {instructions}
            </Typography>
          </Box>
        )}

        <Box
          pt={description && description.includes('--') ? 2 : 0}
          pb={continueInstructions ? 5 : 2}
          maxWidth={220}
          margin="auto"
        >
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            onClick={() => letsGoClick(true)}
          >
            {buttonText}
          </Button>
        </Box>

        {estimatedTime && (
          <Box
            color={
              continueInstructions ? Colors.BLACK_TWELVE : Colors.GRAY_TWENTYONE
            }
            pb={continueInstructions ? 0.5 : 0}
          >
            <Typography variant="caption" component="p" color="inherit">
              {continueInstructions ? (
                <strong>{estimatedTime}</strong>
              ) : (
                estimatedTime
              )}
            </Typography>
          </Box>
        )}

        {continueInstructions && (
          <Box color={Colors.BLACK_TEN}>
            <Typography variant="body2" component="p" color="inherit">
              {continueInstructions}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default IntakeFormInfo;
