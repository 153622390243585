import { FC } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog,
  Box,
  Typography,
  Button,
  makeStyles,
  createStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    confirmationModal: {
      padding: 50,
    },
  })
);

interface Props {
  description: string;
  closeBtnText: string;
  confirmBtnText: string;
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const SelectCardDialog: FC<Props> = ({
  description,
  closeBtnText,
  confirmBtnText,
  open,
  onConfirm,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <Box className={classes.confirmationModal}>
        <Box maxWidth={406} pb={3.5}>
          <Typography variant="h4">{description}</Typography>
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <Box mr={2}>
            <Button
              onClick={() => onClose()}
              variant="text"
              color="secondary"
              className="dangerButton"
              startIcon={<CloseIcon />}
            >
              {closeBtnText}
            </Button>
          </Box>

          <Button variant="contained" color="primary" onClick={onConfirm}>
            {confirmBtnText}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default SelectCardDialog;
