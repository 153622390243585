import gql from 'graphql-tag';
import { useContext, useEffect } from 'react';
import { PARENT_TOKEN } from '../constants';
import { AppContext } from '../contexts';

const UPDATE_STUDENT_LAST_LOGIN_LOCATION_MUTATION = gql`
  mutation UpdateStudentMutation {
    updateStudent(input: {}) {
      lastLoginAt
    }
  }
`;

const UpdateLastLoginLocation = ({ client }: { client: any }) => {
  const { user } = useContext(AppContext);
  const mountEffect = () => {
    const updateLastLoginAt_Location = async () => {
      try {
        if (user && user.id) {
          await client.mutate({
            mutation: UPDATE_STUDENT_LAST_LOGIN_LOCATION_MUTATION,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!localStorage.getItem(PARENT_TOKEN)) updateLastLoginAt_Location();
  };
  useEffect(mountEffect, []);
  return <></>;
};

export default UpdateLastLoginLocation;
