import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Grid, Typography, Box, Hidden } from '@material-ui/core';
import { useStyles } from './mentorDetailPageStyle';

import {
  getAdvisorTagsQuery,
  getAdvisorTagsQueryVariables,
} from './__generated__/getAdvisorTagsQuery';

const ADVISOR_TAGS = gql`
  query getAdvisorTagsQuery($userId: Int) {
    getAdvisorTags(input: { userId: $userId }) {
      tags {
        name
        id
      }
    }
  }
`;

const AdvisorSpecialities = ({ advisorId }: { advisorId: number }) => {
  const { tagsWrapper } = useStyles();

  return (
    <Grid className={tagsWrapper}>
      <Query<getAdvisorTagsQuery, getAdvisorTagsQueryVariables>
        query={ADVISOR_TAGS}
        variables={{ userId: advisorId }}
      >
        {({ data, loading }) => {
          if (loading) return <>Loading...</>;
          if (!data?.getAdvisorTags?.tags.length) return <></>;
          return (
            <>
              <Grid className="tagsWrapperFlex">
                <Hidden only={['xs', 'sm']}>
                  <Typography variant="h6" className="specialtiesText">
                    Specialties:
                  </Typography>
                </Hidden>

                <Hidden only={['xl', 'lg', 'md']}>
                  <Typography variant="h6" className="specialtiesText">
                    Tags:
                  </Typography>
                </Hidden>
                <Grid>
                  <Box display="flex" flexWrap="wrap" alignItems="center">
                    {data?.getAdvisorTags?.tags?.map((item) => (
                      <Box className="degreeBadge" key={item?.id}>
                        {item?.name}
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
              <Typography variant="subtitle1" className="tagsDescription">
                All advisors at CollegeAdvisor.com are trained to provide expert
                assistance for core aspects of the college admissions process.
                Specialties shown above represent areas of expertise beyond core
                admissions.
              </Typography>
            </>
          );
        }}
      </Query>
    </Grid>
  );
};

export default AdvisorSpecialities;
