import gql from 'graphql-tag';

export const PROMOTED_WEBINAR_SERIES = gql`
  query allPromotedSeriesAndWebinars {
    promotedWebinarSeries {
      series {
        id
        name
        description
        imageUrl
        promoted_rank
        tagline
      }
      webinars {
        id
        WebinarId
        title
        tagline
        promoted_rank
        coverTileMaster
        about
        background_image_url
        promoGraphicDesktop
        promoGraphicMobile
      }
    }
  }
`;

export const SEARCH_ALL_WEBINARS = gql`
  query allWebinars(
    $searchQuery: String
    $limit: Int
    $isStudentPortal: Boolean
  ) {
    allWebinars(
      input: {
        searchQuery: $searchQuery
        limit: $limit
        isStudentPortal: $isStudentPortal
      }
    ) {
      webinars {
        id
        title
        coverTileMaster
        coverTileNoDate
        startTime
        conferenceURL
        recording_url
        about
      }
      upcoming {
        id
        title
        coverTileMaster
        coverTileNoDate
        conferenceURL
        startTime
        about
      }
      count
    }
  }
`;

export const HOME_PAGE_SERIES_QUERY = gql`
  query allHomePageSeries(
    $searchQuery: String
    $isMBAGraduateProgram: Boolean
  ) {
    allHomePageSeries(
      input: {
        searchQuery: $searchQuery
        isMBAGraduateProgram: $isMBAGraduateProgram
      }
    ) {
      series {
        id
        name
        # isFeatured
        isPromoted
        description
        imageUrl
        webinars {
          id
          WebinarId
          title
          startTime
          endTime
          about
          room_logo
          agenda_topics
          coverTileMaster
          coverTileNoDate
          recording_url
          presentors {
            presenter_image_url
            display_name
          }
          background_image_url
          exit_url
        }
      }

      upcoming {
        id
        WebinarId
        title
        startTime
        endTime
        about
        room_logo
        agenda_topics
        coverTileMaster
        coverTileNoDate
        recording_url
        presentors {
          presenter_image_url
          display_name
        }
        background_image_url
        exit_url
      }
      recentlyAdded {
        id
        WebinarId
        coverTileMaster
        coverTileNoDate
        title
        startTime
        endTime
        about
        room_logo
        agenda_topics
        recording_url
        presentors {
          presenter_image_url
          display_name
        }
        background_image_url
        exit_url
      }
    }
  }
`;

export const WEBINAR_DETAIL_QUERY = gql`
  query WebinarDetailQuery($id: Int!) {
    webinarDetail(input: { id: $id }) {
      webinar {
        id
        WebinarId
        title
        startTime
        endTime
        duration
        conferenceURL
        about
        recording_url
        isPremium
        mp4_url
        timezone
        who_can_watch_recording
        background_image_url
        coverTileMaster
        webinarIcon
        isMBA
        advisors {
          id
          firstName
          lastName
          isActive
          visible
          bio
          highSchoolYear
          uuid
          pictureUrl
          isMBAGrad
          previouslyWorkedColleges {
            college {
              name
              logo
            }
            CurrentlyWorking
          }
          currentCollege {
            name
            logo
            description
          }
        }
        presentors {
          display_name
          presenter_image_url
        }
      }
    }
  }
`;

export const REGISTER_TO_WEBINAR_MUTATION = gql`
  mutation registerToWebinarMutation(
    $conferenceId: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $phone: String
  ) {
    registerToWebinar(
      input: {
        conferenceId: $conferenceId
        email: $email
        firstName: $firstName
        lastName: $lastName
        phone: $phone
      }
    ) {
      success
      message
      webinars {
        upcoming {
          id
          WebinarId
          title
          startTime
          endTime
          duration
          conferenceURL
          about
          recording_url
          isPremium
          mp4_url
          timezone
          who_can_watch_recording
          background_image_url
          coverTileMaster
          coverTileNoDate
          presentors {
            display_name
            presenter_image_url
          }
        }
        past {
          id
          WebinarId
          title
          startTime
          endTime
          duration
          conferenceURL
          about
          recording_url
          isPremium
          mp4_url
          timezone
          who_can_watch_recording
          background_image_url
          coverTileMaster
          coverTileNoDate
          presentors {
            display_name
            presenter_image_url
          }
        }
      }
    }
  }
`;

export const FETCH_SERIES = gql`
  query fetchSeriesQuery($id: Int!) {
    fetchSeries(input: { id: $id }) {
      Series {
        id
        name
        description
        webinars {
          id
          WebinarId
          title
          startTime
          about
          room_logo
          coverTileMaster
          coverTileNoDate
          agenda_topics
          presentors {
            presenter_image_url
            display_name
          }
          background_image_url
          exit_url
        }
      }
    }
  }
`;

export const UPCOMING_LIVE_SEREIES = gql`
  query UpcomingLiveSeries(
    $searchQuery: String
    $isMBAGraduateProgram: Boolean
  ) {
    allHomePageSeries(
      input: {
        searchQuery: $searchQuery
        isMBAGraduateProgram: $isMBAGraduateProgram
      }
    ) {
      upcoming {
        id
        WebinarId
        title
        startTime
        about
        room_logo
        agenda_topics
        coverTileMaster
        presentors {
          presenter_image_url
          display_name
        }
        background_image_url
        exit_url
      }
    }
  }
`;

export const GET_WEBINARS = gql`
  query getUserUpcomingAndPastWebinarsQuery {
    getUserUpcomingAndPastWebinars {
      upcoming {
        id
        WebinarId
        title
        startTime
        endTime
        duration
        conferenceURL
        isRegistered
        about
        recording_url
        isPremium
        mp4_url
        timezone
        who_can_watch_recording
        background_image_url
        coverTileMaster
        coverTileNoDate
        presentors {
          display_name
          presenter_image_url
        }
      }
      past {
        id
        WebinarId
        title
        startTime
        endTime
        duration
        conferenceURL
        isRegistered
        about
        recording_url
        isPremium
        mp4_url
        timezone
        who_can_watch_recording
        background_image_url
        coverTileMaster
        coverTileNoDate
        presentors {
          display_name
          presenter_image_url
        }
      }
    }
  }
`;
