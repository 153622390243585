import { makeStyles, createStyles, Theme } from '@material-ui/core';
import fonts from '../../../common/fonts';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textArea: {
      width: '100%',
      padding: '10px',
      boxShadow: 'none !important',
      minHeight: '100px',
      borderColor: '#D4D4D4 !important',
      borderRadius: '6px',
      outline: 'none',
    },

    preferAdvisor: {
      margin: '30px 0px 18px',

      "& [class*='MuiSvgIcon-root']": {
        width: 30,
        height: 30,
        color: '#7D7F7F',
      },

      "& [class*='MuiTypography-body1']": {
        fontSize: 16,
        fontWeight: 400,
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.176px',
      },
    },
  })
);
