import { makeStyles } from '@material-ui/styles';
import momentTz from 'moment-timezone';
import { FC } from 'react';

import {
  Button,
  createStyles,
  Grid,
  Theme,
  ThemeProvider,
  Typography,
} from '@material-ui/core';

import moment from 'moment';
import { customTheme } from '../MaterializeCss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnailCardOverlay: {
      background: '#272929',
      opacity: 0.9,
      width: '100%',
      height: 233,
      padding: '20px 14px 15px',
      borderRadius: 20,
      position: 'absolute',

      [theme.breakpoints.down('sm')]: {
        height: 192,
      },

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },

      '& .overlayText': {
        fontSize: 12,
        color: '#ECEBEA',
        marginBottom: 8,
        lineHeight: '20px',

        [theme.breakpoints.down('sm')]: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },

      '& .overlayDate': {
        fontWeight: 'bold',
        fontSize: 12,
        color: '#F8F6F4',
        padding: 0,
        textAlign: 'left',
        marginBottom: 20,
      },
    },
  })
);

interface Props {
  title?: string | null;
  date?: string | null;
}

const ThumbnailCardOverlay: FC<Props> = ({ title, date }) => {
  const classes = useStyles();
  return (
    <>
      <Grid className={classes.thumbnailCardOverlay}>
        <Typography variant="body1" className="overlayText">
          {title}
        </Typography>

        <Typography variant="h6" className="overlayDate">
          {moment(date).format('MMM DD, YYYY - h:mmA ')}
          {`${momentTz.tz(momentTz.tz.guess()).format('z')}`}
        </Typography>
        <ThemeProvider theme={customTheme}>
          <Button variant="contained" fullWidth color="secondary">
            View Details
          </Button>
        </ThemeProvider>
      </Grid>
    </>
  );
};

export default ThumbnailCardOverlay;
