import { FC, useState } from 'react';
import {
  FormControlLabel,
  Grid,
  Radio,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core';
import DeleteCardModal from '../../../pages/main/orders/DeleteCardModal';
import { AllPaymentSourcesQuery_allPaymentSources } from '../../../pages/main/orders/__generated__/AllPaymentSourcesQuery';

import { useStyles } from './styles';

import CreditCardIcon from '../../../img/credit-card.svg';
import Delete_Card_Img from '../../../img/delete-card.svg';

interface MyCreditCardProps {
  isBillingPage: Boolean;
  brand: string;
  last4: string;
  id: string;
  cardNumber: string;
  allPaymentSources: (AllPaymentSourcesQuery_allPaymentSources | null)[];
  refetch: Function;
  isDefault: boolean | null;
  cardHolderName: string | null | undefined;
}

const MyCardAccount: FC<MyCreditCardProps> = (props) => {
  const {
    isBillingPage,
    brand,
    last4,
    id,

    cardNumber,
    allPaymentSources,
    refetch,
    isDefault,
    cardHolderName,
  } = props;
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      className={classes.visaCardComponent}
    >
      <Box display="flex">
        <Grid>
          <img
            width="16px"
            src={CreditCardIcon}
            alt="card"
            className="cardImage"
          />
        </Grid>
        <Grid>
          {isBillingPage ? (
            <Typography variant="body1" className="visaNumber">
              <strong>{brand}</strong> {` ending in ${last4}`}
            </Typography>
          ) : (
            <FormControlLabel
              value={id}
              checked={Boolean(isDefault)}
              control={<Radio />}
              label={cardNumber}
            />
          )}
          {cardHolderName && (
            <>
              <Typography variant="body2">Cardholder Name</Typography>
              <Typography variant="body2">{cardHolderName}</Typography>
            </>
          )}
          {isDefault && (
            <Typography variant="h6" className="cardTypeDefult">
              DEFAULT
            </Typography>
          )}
        </Grid>
      </Box>

      {allPaymentSources.length > 1 && isBillingPage && (
        <IconButton
          onClick={isDefault ? () => {} : toggleModal}
          className={isDefault ? 'deleteButton defaultButton' : 'deleteButton'}
        >
          <img src={Delete_Card_Img} alt="delete" />
        </IconButton>
      )}

      <DeleteCardModal
        id={id}
        open={openModal}
        onClose={toggleModal}
        refetch={refetch}
      />
    </Box>
  );
};

export default MyCardAccount;
