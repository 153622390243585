import {  createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Colors from '../../common/colors';
import fonts from '../../common/fonts';

export const thumbnailCardStyles = makeStyles((theme: Theme) =>
createStyles({
  thumbnailCarousel: {
    width: 233,
    height: 233,
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      width: 192,
      height: 192,
    },

    [theme.breakpoints.down('xs')]: {
      width: 182,
      height: 182,
    },

    [`@media only screen and (max-width: 400px)`]: {
      width: 165,
      height: 165,
    },

    [`@media only screen and (max-width: 360px)`]: {
      width: 135,
      height: 135,
    },

    '& img': {
      borderRadius: 20,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },

    "& [class*='MuiTypography-root']": {
      color: Colors.WHITE_ONE,
    },

    '& h6': {
      padding: '0px 15px',
      textAlign: 'center',
      fontFamily: fonts.POPPINS_FONT,

      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },

    '& .onhoverBox': {
      width: '100%',
      top: 0,
      position: 'absolute',
      visibility: 'hidden',
    },

    '&:hover': {
      '& .onhoverBox': {
        visibility: 'visible',
      },
    },
  },
})
);