import React, { FC, useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import clsx from 'clsx';
import moment from 'moment';

import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  MuiThemeProvider,
  SwipeableDrawer,
  ThemeProvider,
  Toolbar,
  Typography,
  createMuiTheme,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import Logo from '../../../components/layout/Logo';
import VideoModal from '../../main/home/StudentFirstTimeLoginVideoModal';
import MBALoader from '../components/MBALoader';
import MBAHelpSupport from './MBAHelpSupport';

import { Query } from 'react-apollo';
import client from '../../../apollo';
import {
  ClientStatus,
  meetingStages,
  meetingStatuses,
  preMeetingTexts,
} from '../../../common/constants';
import { MEETING_SET, PARENT_TOKEN, TOKEN } from '../../../constants';
import { AppContext } from '../../../contexts';
import { GET_MEETING_TIME } from '../../../graphql/queries/preMeetingBanner';
import { GET_USER_BY_TOKEN } from '../../main/clientEnrollment/gql';
import { getMeetingTime } from '../../../graphql/queries/preMeetingBanner/__generated__/getMeetingTime';

import { customTheme } from '../../MaterializeCss';
import { useStylesHeader } from './MBAHeaderStyle';

import Colors from '../../../common/colors';
import Fonts from '../../../common/fonts';

import LINK_COPY_ICON from '../../../img/link-copied-icon.png';
import LINK_ICON from '../../../img/link-copy.png';
import LogoDark from '../../../img/logo-dark.svg';
import MEETING from '../../../img/meeting.svg';
import MEETINGS_LOCKED_ICON from '../../../img/meetings-locked.svg';
import UPGRADE_ICON from '../../../img/upgrade-icon.svg';
import {
  ARROW_IC,
  CHAT_IC,
  EX_LINK,
  LOCKED_CHAT,
  PHONE_IC,
  PROFILE_IC,
} from './MBAHeaderIcons';

interface Props {
  navigateTo?: (path: string) => void;
}

const MOBILE_USER_NAV_ITEMS = [
  {
    title: 'My Account',
    link: '/account/profile',
    hasIcon: false,
  },
  {
    title: 'My Profile',
    link: '/account/my-profile',
    hasIcon: false,
  },
];

const MBAHeaderFree: FC<Props> = () => {
  const classes = useStylesHeader();
  const { user, setUser, setIsLoggedIn } = useContext(AppContext);
  const isPremium = user?.isPremium;
  const [showDemo, setShowDemo] = useState(false);
  const { unreadMessagesCount } = useContext(AppContext);
  const urlParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  const isSurveyForm = location.pathname.includes('survey-form');
  const isMeetingSetPage = location.pathname.includes('schedule');
  const [name, setName] = useState('');
  const [openParentAccountAccess, setOpenParentAccountAccess] = useState(false);
  const [drawer, setDrawer] = useState(false);

  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [isPreMeeting, setIsPreMeeting] = useState(false);

  const goBack = urlParams.get('_goback');
  const history = useHistory();

  const isTrial =
    user?.studentTrial?.trialStartedAt && !user?.studentTrial?.trialCompletedAt
      ? true
      : false;

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const getUserByToken = () => {
    if (params.userToken) {
      try {
        const apiCalling = async () => {
          const { data } = await client.query({
            query: GET_USER_BY_TOKEN,
            fetchPolicy: 'no-cache',
            variables: { userToken: params.userToken },
          });

          if (data && data.getUserByToken) {
            const { firstName, lastName } = data.getUserByToken;
            setName(firstName + ' ' + lastName);
          }
        };
        apiCalling();
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(getUserByToken, []);

  useEffect(() => {}, [unreadMessagesCount]);

  let mobileNavItems = user ? [...MOBILE_USER_NAV_ITEMS] : [];

  if (process.env.REACT_APP_ACTIVATE_ANALYTICS && window.Intercom)
    window.Intercom('update');
  const isCanceled =
    user?.clientStatus === ClientStatus.BAD_DEBT ||
    user?.clientStatus === ClientStatus.CANCELED;
  const premiumPackageType = user && user.premiumPackageType;
  const toggleParentAccountAccessModel = () => {
    setOpenParentAccountAccess(!openParentAccountAccess);
  };

  if (isCanceled) {
    mobileNavItems = mobileNavItems.filter(
      (item) => item?.title !== 'My Meetings'
    );
  }

  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setDrawer(!drawer);
    };

  const packageColor = () => {
    if (premiumPackageType === 'Ivy Plus') {
      return Colors.GREEN_EIGHT;
    }
    if (premiumPackageType === 'Starter' || premiumPackageType === 'Scholar') {
      return Colors.BLACK_THIRTEEN;
    }
    if (premiumPackageType === 'Elite') {
      return Colors.BLUE_TWELVE;
    }
    if (premiumPackageType === 'Premium') {
      return Colors.LIGHT_GREEN_ONE;
    }
    if (premiumPackageType === 'Platinum') {
      return Colors.BLUE_SEVEN;
    }
    if (premiumPackageType === 'My Plan') {
      return Colors.BLUE_THIRTEEN;
    }
    if (premiumPackageType === 'Scholars') {
      return Colors.BLACK_THIRTEEN;
    }
    if (premiumPackageType === 'CWS') {
      return Colors.GRAY_TWENTYTWO;
    }
  };

  const theme = createMuiTheme({
    overrides: {
      MuiContainer: {
        root: {
          maxWidth: 1328,
        },
      },

      MuiAppBar: {
        positionRelative: {
          minHeight: 95,
          zIndex: 'auto',
          padding: '16px 0px',
          borderBottom: '1px solid #ECEBEA',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: !isSurveyForm ? '' : 'none !important',

          [`@media only screen and (max-width: 959px)`]: {
            padding: 0,
            minHeight: 'auto',
          },
        },

        root: {
          '@global': {
            a: {
              textDecoration: 'none !important',
            },

            button: {
              outline: 'none',
            },
          },
        },
      },

      MuiPaper: {
        elevation4: {
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05) !important',
          background: `${Colors.WHITE_ONE} !important`,
        },
      },

      MuiToolbar: {
        regular: {
          minHeight: 'auto !important',
          fontSize: 18,
        },

        gutters: {
          padding: '6px 0 !important',

          '@media only screen and (max-width: 960px)': {
            padding: '9px 0 !important',
          },
        },
      },

      MuiDrawer: {
        paper: {
          paddingTop: 20,

          '@global': {
            a: {
              textDecoration: 'none !important',
            },
          },
        },
      },

      MuiPopover: {
        paper: {
          '@global': {
            a: {
              textDecoration: 'none !important',
            },
          },
        },
      },
    },
  });

  const notUpdatedSection = () => {
    const currentYear = new Date().getFullYear();
    return (
      <Box className={clsx(classes.graySection, classes.upgradeThemeSection)}>
        <Box className={classes.upGradeHeading}>
          <a href="/client-enrollment" className="anchorCursor">
            <img src={UPGRADE_ICON} alt="upgrade" />
          </a>
          Upgrade Today
        </Box>
        {(user?.highSchoolYear === (currentYear + 1).toString() ||
          user?.highSchoolYear === (currentYear + 2).toString() ||
          user?.highSchoolYear === (currentYear + 3).toString()) &&
        !isPremium &&
        !user.isMBAGrad ? (
          <>
            <Typography variant="body1" className="upgradeSubtitle">
              Schedule a free strategy meeting with an Admissions Specialist to
              learn more about our admissions packages.
            </Typography>
            <Box>
              <a href="/schedule">
                <ThemeProvider theme={customTheme}>
                  <Button className={classes.callButton} variant="contained">
                    <img
                      src={MEETING}
                      alt=""
                      style={{ paddingRight: '10px' }}
                    />
                    Schedule a Meeting
                  </Button>
                </ThemeProvider>
              </a>
            </Box>

            <a href="tel:+1 (844) 343-6272">
              <Typography variant="body2" className="phoneNumberUpgrade">
                or call +1 (844) 343-6272
              </Typography>
            </a>
          </>
        ) : (
          <>
            <Typography variant="body1" className="upgradeSubtitle">
              Talk to one of our Admissions Coordinators to learn more about our
              admissions packages.
            </Typography>
            <Box>
              <a href="tel: +1 (844) 343-6272">
                <ThemeProvider theme={customTheme}>
                  <Button className={classes.callButton} variant="contained">
                    <Box mr={1}>{PHONE_IC()}</Box> Call Now
                  </Button>
                </ThemeProvider>
              </a>
            </Box>

            <a href="tel:+1 (844) 343-6272">
              <Typography variant="body2" className="phoneNumberUpgrade">
                +1 (844) 343-6272
              </Typography>
            </a>
          </>
        )}
      </Box>
    );
  };

  return (
    <MuiThemeProvider theme={theme}>
      <AppBar position="relative" color="inherit">
        <Container maxWidth={false}>
          <ThemeProvider theme={customTheme}>
            {showDemo && <VideoModal setShowDemo={setShowDemo} />}
            <Toolbar
              className={
                isMeetingSetPage
                  ? classes.appBarSchedule
                  : classes.appBarGeneric
              }
            >
              <Box>
                {user ? (
                  user.isParent ? (
                    <Link to="/account/student-accounts">
                      <Logo />
                    </Link>
                  ) : (
                    <Link to="/">
                      <Logo />
                    </Link>
                  )
                ) : (
                  <a href="https://www.collegeadvisor.com">
                    <Logo />
                  </a>
                )}
              </Box>

              <Box
                display="flex"
                flex={1}
                fontFamily={Fonts.POPPINS_FONT}
                className={
                  isMeetingSetPage ? classes.navItemsFree : classes.navItems
                }
              ></Box>

              {/* TODO: will uncomment after calendly confirmation */}
              {/* <a href={`/schedule`}>
                <ThemeProvider theme={customTheme}>
                  <Button variant="contained" color="secondary">
                    Work with an Advisor Today!
                  </Button>
                </ThemeProvider>
              </a> */}

              {user && !user.isParent && !isSurveyForm && !isMeetingSetPage && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Box
                    position="relative"
                    marginLeft={0}
                    className={classes.profileBadgeContainer}
                  >
                    <Box className={classes.profileAvtar}>
                      <Box className={classes.premiumPopupContainer}>
                        <Button
                          variant="contained"
                          className={`${classes.programButtonMBA} with-profilePic`}
                        >
                          <Box
                            className={`${classes.profileBadge} profile-picture`}
                          >
                            {user?.pictureUrl ? (
                              <img src={user.pictureUrl} alt="" />
                            ) : (
                              PROFILE_IC()
                            )}
                          </Box>
                        </Button>
                      </Box>

                      <Box
                        top={37}
                        zIndex={999}
                        position="absolute"
                        id="profileBadgeMenu"
                        className={classes.profileBadgeMenu}
                      >
                        <Grid className={classes.profilePopover}>
                          <Grid className={classes.profileCard}>
                            <Box width="100%">
                              <Box
                                className={classes.userName}
                                paddingBottom={2}
                              >
                                <Box component="h3" paddingBottom={1}>
                                  {`${user.firstName || ''} ${
                                    user.lastName || ''
                                  }`}
                                </Box>

                                {user.isParent && (
                                  <Box
                                    onClick={() => {
                                      toggleParentAccountAccessModel();
                                    }}
                                    className={classes.changeAccount}
                                    color={packageColor() || Colors.BLUE_SEVEN}
                                  >
                                    CHANGE ACCOUNT {ARROW_IC()}
                                  </Box>
                                )}

                                {localStorage.getItem(PARENT_TOKEN) && (
                                  <Box
                                    onClick={async () => {
                                      const oldToken =
                                        localStorage.getItem(PARENT_TOKEN);

                                      if (oldToken) {
                                        await localStorage.setItem(
                                          TOKEN,
                                          oldToken
                                        );
                                        localStorage.removeItem(PARENT_TOKEN);
                                        if (
                                          (window.location.pathname = '/home')
                                        )
                                          window.location.replace(
                                            '/account/student-accounts?parent=true'
                                          );
                                        else
                                          window.location.pathname =
                                            '/account/student-accounts';
                                      }
                                    }}
                                    className={classes.changeAccount}
                                    color={packageColor() || Colors.BLUE_SEVEN}
                                  >
                                    Continue to My Account {ARROW_IC()}
                                  </Box>
                                )}
                              </Box>

                              {!isPremium &&
                                !user?.planCancelled &&
                                !isPreMeeting &&
                                !isTrial &&
                                notUpdatedSection()}

                              {user?.planCancelled && (
                                <Box
                                  className={clsx(
                                    classes.graySection,
                                    classes.upgradeThemeSection
                                  )}
                                >
                                  <Typography
                                    className={classes.upGradeHeading}
                                  >
                                    Restore Premium Access
                                  </Typography>

                                  <Typography
                                    variant="body1"
                                    className="upgradeSubtitle upgradeSubtitleRestore"
                                  >
                                    Regain access to your advisor and membership
                                    features by calling us at <br /> +1 (855)
                                    412-6272.
                                  </Typography>
                                </Box>
                              )}

                              {(process.env.REACT_APP_PRE_MEETING_FEATURE ===
                                'TRUE' ||
                                (user &&
                                  [919783, 964808, 966649].includes(
                                    user?.id
                                  ))) &&
                                !isPremium && (
                                  <Query<getMeetingTime>
                                    query={GET_MEETING_TIME}
                                    fetchPolicy="network-only"
                                    variables={{
                                      zoom: true,
                                      userId: user?.id,
                                    }}
                                  >
                                    {({ data, loading }) => {
                                      if (loading) return <MBALoader />;
                                      if (
                                        !data?.getMeetingTime?.timeRemaining
                                      ) {
                                        return null;
                                      }
                                      if (data?.getMeetingTime) {
                                        const {
                                          timeRemaining,
                                          dateWithTimeZone,
                                          followUp,
                                          specialistName,
                                          link,
                                          meetingStatus,
                                          phoneNumber,
                                          email,
                                          stage,
                                          coordinator,
                                        } = data?.getMeetingTime;

                                        const coordinatorEmail =
                                          coordinator?.email;
                                        const coordinatorPhone =
                                          coordinator?.phone;

                                        if (timeRemaining) {
                                          setIsPreMeeting(true);
                                          const meetingStartTime =
                                            moment(timeRemaining);
                                          const meetingEndTime = moment(
                                            timeRemaining
                                          ).add(1, 'hour');
                                          if (
                                            stage === meetingStages.closedLost
                                          ) {
                                            return (
                                              <Box
                                                className={clsx(
                                                  classes.preMeetingCard,
                                                  classes.graySection
                                                )}
                                              >
                                                <Box>
                                                  <Typography
                                                    variant="h6"
                                                    className="meetingTitle"
                                                  >
                                                    Upgrade Today
                                                  </Typography>

                                                  <Typography
                                                    variant="body1"
                                                    className="meetingSubTitle"
                                                  >
                                                    {
                                                      preMeetingTexts.closedLostText
                                                    }
                                                  </Typography>

                                                  <Button
                                                    variant="contained"
                                                    className={clsx(
                                                      classes.linkForm,
                                                      classes.joinMeeting
                                                    )}
                                                    onClick={() => {
                                                      history.push(
                                                        '/client-enrollment'
                                                      );
                                                    }}
                                                  >
                                                    Select Your Package
                                                  </Button>

                                                  <Typography
                                                    variant="body1"
                                                    className="meetingSubTitle anyQuestionContact"
                                                  >
                                                    Any questions? Reach out to
                                                    your Specialist,{' '}
                                                    {specialistName} at{' '}
                                                    {phoneNumber} or {email}.
                                                  </Typography>
                                                </Box>
                                              </Box>
                                            );
                                          } else if (
                                            meetingStatuses.showRescheduleBanner(
                                              meetingStatus || ''
                                            ) ||
                                            (meetingStatus ===
                                              meetingStatuses.actualMeeting &&
                                              (stage ===
                                                meetingStages.followUpTentative ||
                                                stage ===
                                                  meetingStages.followUpScheduled) &&
                                              meetingEndTime.isBefore(moment()))
                                          ) {
                                            return (
                                              <Box
                                                className={clsx(
                                                  classes.preMeetingCard,
                                                  classes.graySection
                                                )}
                                              >
                                                <Box>
                                                  <Typography
                                                    variant="h6"
                                                    className="meetingTitle"
                                                  >
                                                    <a
                                                      href="/client-enrollment"
                                                      style={{
                                                        cursor: 'default',
                                                      }}
                                                    >
                                                      <img
                                                        src={LogoDark}
                                                        alt="logo"
                                                      />
                                                    </a>

                                                    {meetingStatuses.showRescheduleBanner(
                                                      meetingStatus || ''
                                                    )
                                                      ? preMeetingTexts.meetingCancel
                                                      : preMeetingTexts.meetingEnd}
                                                  </Typography>

                                                  <Typography
                                                    variant="body1"
                                                    className="meetingSubTitle"
                                                  >
                                                    {meetingStatuses.showRescheduleBanner(
                                                      meetingStatus || ''
                                                    )
                                                      ? preMeetingTexts.meetingRescheduleText
                                                      : preMeetingTexts.meetingEndText}
                                                  </Typography>

                                                  <Button
                                                    variant="contained"
                                                    className={clsx(
                                                      classes.linkForm,
                                                      classes.joinMeeting
                                                    )}
                                                    onClick={() => {
                                                      // window.location.href = link;
                                                      meetingStatuses.showRescheduleBanner(
                                                        meetingStatus || ''
                                                      )
                                                        ? history.push(
                                                            '/schedule'
                                                          )
                                                        : history.push(
                                                            '/client-enrollment'
                                                          );
                                                    }}
                                                  >
                                                    {meetingStatuses.showRescheduleBanner(
                                                      meetingStatus || ''
                                                    )
                                                      ? 'Reschedule Meeting'
                                                      : 'Select Your Package'}
                                                  </Button>
                                                  {meetingStatus ===
                                                    meetingStatuses.actualMeeting &&
                                                    stage ===
                                                      meetingStages.followUpTentative && (
                                                      <Typography
                                                        variant="body1"
                                                        className="meetingSubTitle anyQuestionContact"
                                                      >
                                                        Any questions? Reach out
                                                        to your Specialist,{' '}
                                                        {specialistName} at{' '}
                                                        {phoneNumber} or {email}
                                                        .
                                                      </Typography>
                                                    )}
                                                </Box>
                                              </Box>
                                            );
                                          } else if (
                                            moment().isBetween(
                                              meetingStartTime,
                                              meetingEndTime
                                            ) &&
                                            link
                                          ) {
                                            return (
                                              <Box
                                                className={clsx(
                                                  classes.preMeetingCard,
                                                  classes.graySection
                                                )}
                                              >
                                                <Box>
                                                  <Typography
                                                    variant="h6"
                                                    className="meetingTitle"
                                                  >
                                                    <a
                                                      href="/client-enrollment"
                                                      style={{
                                                        cursor: 'default',
                                                      }}
                                                    >
                                                      <img
                                                        src={LogoDark}
                                                        alt="logo"
                                                      />
                                                    </a>
                                                    {
                                                      preMeetingTexts.meetingJoin
                                                    }
                                                  </Typography>

                                                  <Typography
                                                    variant="body1"
                                                    className="meetingSubTitle"
                                                  >
                                                    {
                                                      preMeetingTexts.meetingJoinText
                                                    }
                                                  </Typography>

                                                  <Typography
                                                    variant="h5"
                                                    className="meetingDate"
                                                  >
                                                    {dateWithTimeZone}
                                                  </Typography>

                                                  <Button
                                                    variant="contained"
                                                    className={clsx(
                                                      classes.linkForm,
                                                      classes.joinMeeting
                                                    )}
                                                    onClick={() => {
                                                      window.location.href =
                                                        link;
                                                    }}
                                                  >
                                                    Join Meeting
                                                  </Button>
                                                </Box>
                                              </Box>
                                            );
                                          } else if (
                                            meetingStatus !==
                                              meetingStatuses.complete &&
                                            meetingStartTime.isAfter(
                                              moment()
                                            ) &&
                                            link
                                          ) {
                                            return (
                                              <Box
                                                className={clsx(
                                                  classes.preMeetingCard,
                                                  classes.graySection
                                                )}
                                              >
                                                <Box>
                                                  <Typography
                                                    variant="h6"
                                                    className="meetingTitle"
                                                  >
                                                    <a
                                                      href="/client-enrollment"
                                                      style={{
                                                        cursor: 'default',
                                                      }}
                                                    >
                                                      <img
                                                        src={LogoDark}
                                                        alt="logo"
                                                      />
                                                    </a>
                                                    {followUp
                                                      ? `Your upcoming follow-up meeting ${
                                                          specialistName
                                                            ? 'with ' +
                                                              specialistName
                                                            : ''
                                                        }`
                                                      : preMeetingTexts.upcomingMeeting}
                                                  </Typography>

                                                  <Typography
                                                    variant="body1"
                                                    className="meetingSubTitle"
                                                  >
                                                    {
                                                      preMeetingTexts.upcomingMeetingText
                                                    }
                                                  </Typography>

                                                  <Typography
                                                    variant="h5"
                                                    className="meetingDate"
                                                  >
                                                    {dateWithTimeZone}
                                                  </Typography>

                                                  <Button
                                                    onClick={() => {
                                                      setIsLinkCopied(true);
                                                      setTimeout(() => {
                                                        setIsLinkCopied(false);
                                                      }, 1000);
                                                      navigator.clipboard.writeText(
                                                        link
                                                      );
                                                    }}
                                                    variant="contained"
                                                    className={
                                                      isLinkCopied
                                                        ? clsx(
                                                            classes.linkForm,
                                                            classes.linkFormCopied
                                                          )
                                                        : classes.linkForm
                                                    }
                                                  >
                                                    {isLinkCopied ? (
                                                      <span
                                                        className={
                                                          classes.LinkButtonText
                                                        }
                                                      >
                                                        <img
                                                          src={LINK_ICON}
                                                          alt="link"
                                                        />
                                                        Link Copied
                                                      </span>
                                                    ) : (
                                                      <span
                                                        className={
                                                          classes.LinkButtonText
                                                        }
                                                      >
                                                        <img
                                                          src={LINK_COPY_ICON}
                                                          alt="link"
                                                        />
                                                        Copy Meeting Link
                                                      </span>
                                                    )}
                                                  </Button>
                                                  {!followUp &&
                                                    coordinatorPhone &&
                                                    coordinatorEmail && (
                                                      <Typography
                                                        variant="body1"
                                                        className="meetingSubTitle anyQuestionContact"
                                                      >
                                                        Any questions? Please
                                                        contact us at{' '}
                                                        {coordinatorPhone} or{' '}
                                                        {coordinatorEmail}.
                                                      </Typography>
                                                    )}
                                                  {followUp &&
                                                    specialistName &&
                                                    phoneNumber &&
                                                    email && (
                                                      <Typography
                                                        variant="body1"
                                                        className="meetingSubTitle anyQuestionContact"
                                                      >
                                                        Any questions? Reach out
                                                        to your Specialist,{' '}
                                                        {specialistName} at{' '}
                                                        {phoneNumber} or {email}
                                                        .
                                                      </Typography>
                                                    )}
                                                </Box>
                                              </Box>
                                            );
                                          } else {
                                            setIsPreMeeting(false);
                                            return null;
                                          }
                                        }
                                      }
                                    }}
                                  </Query>
                                )}
                            </Box>
                          </Grid>

                          <Box padding="10px 6px 0">
                            <Box className={classes.linkMenuItem}>
                              <Link to="/account/profile">My Account</Link>
                            </Box>
                            <Box className={classes.linkMenuItem}>
                              <Link to="/account/my-profile">My Profile</Link>
                            </Box>
                            {window.location.hostname !==
                            'app.collegeadvisor.com' ? (
                              <Box paddingTop={5.2}>
                                <Link
                                  to="/"
                                  className={classes.popoverSignOut}
                                  onClick={() => {
                                    localStorage.removeItem(TOKEN);
                                    localStorage.removeItem(PARENT_TOKEN);
                                    localStorage.removeItem(MEETING_SET);
                                    setIsLoggedIn(false);
                                    setUser(null);
                                    document.cookie =
                                      '_caLoggedIn=false;domain=collegeadvisor.com';
                                  }}
                                >
                                  <Box
                                    component="span"
                                    color={packageColor() || Colors.BLUE_SEVEN}
                                  >
                                    SIGN OUT
                                  </Box>
                                </Link>
                              </Box>
                            ) : (
                              <Box
                                paddingTop={5.2}
                                color={packageColor() || Colors.BLACK_ONE}
                              >
                                <a
                                  href="https://www.collegeadvisor.com"
                                  className={classes.popoverSignOut}
                                  onClick={() => {
                                    localStorage.removeItem(TOKEN);
                                    localStorage.removeItem(PARENT_TOKEN);
                                    localStorage.removeItem(MEETING_SET);
                                    setIsLoggedIn(false);
                                    setUser(null);
                                    document.cookie =
                                      '_caLoggedIn=false;domain=collegeadvisor.com';
                                  }}
                                >
                                  <Box
                                    component="span"
                                    color={packageColor() || Colors.BLUE_SEVEN}
                                  >
                                    SIGN OUT
                                  </Box>
                                </a>
                              </Box>
                            )}
                          </Box>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>

                  {!isTrial
                    ? user &&
                      !user.isParent &&
                      !isSurveyForm &&
                      !isMeetingSetPage &&
                      (isPremium ? (
                        <Grid className={classes.chatDisplay}>
                          <Box position="relative" marginLeft={2.5}>
                            <Link to={'/chat'}>
                              <Badge
                                badgeContent={unreadMessagesCount}
                                color="secondary"
                              >
                                {CHAT_IC()}
                              </Badge>
                            </Link>
                          </Box>
                        </Grid>
                      ) : (
                        <Grid className={classes.chatDisplay}>
                          <Box marginLeft={2.5}>
                            <Link to={'/chat'}>{LOCKED_CHAT()}</Link>
                          </Box>
                        </Grid>
                      ))
                    : ''}
                </Box>
              )}

              <Hidden only={['xs', 'sm']}>
                {premiumPackageType && !isSurveyForm ? (
                  <MBAHelpSupport />
                ) : (
                  <></>
                )}
              </Hidden>

              {user && user.isParent && !isSurveyForm && !isMeetingSetPage && (
                <Grid className={classes.userNameSwitchFlex}>
                  <Typography className={classes.userNameSwitch}>
                    {user.firstName + ' ' + user.lastName}
                  </Typography>

                  <Box
                    display="flex"
                    alignItems="center"
                    className="logoutLink"
                  >
                    <Box className="logoutLinkSpacing">
                      <Link
                        to="/"
                        onClick={() => {
                          localStorage.removeItem(TOKEN);
                          localStorage.removeItem(PARENT_TOKEN);
                          localStorage.removeItem(MEETING_SET);
                          setIsLoggedIn(false);
                          setUser(null);
                          document.cookie =
                            '_caLoggedIn=false;domain=collegeadvisor.com';
                        }}
                        className={classes.parentStudentLink}
                      >
                        Sign Out
                      </Link>
                    </Box>
                    <Box
                      onClick={() => {
                        toggleParentAccountAccessModel();
                      }}
                      className={classes.parentStudentLink}
                    >
                      Switch to Student
                    </Box>
                  </Box>
                </Grid>
              )}

              <Hidden only={['xs', 'sm']}>
                {user && user.isParent ? <MBAHelpSupport /> : <></>}
              </Hidden>

              {!user && name && (
                <Grid className={classes.userNameSwitchFlex}>
                  <Grid>
                    <Typography className={classes.surveyUserName}>
                      {name}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {isMeetingSetPage && user && (
                <Typography className={classes.scheduleUserName}>
                  {user.firstName + ' ' + user.lastName}
                </Typography>
              )}

              {!user?.isParent && !isSurveyForm && !isMeetingSetPage && (
                <Box marginLeft="auto" className={classes.hamburgerMenu}>
                  <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer()}
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
              )}
            </Toolbar>
          </ThemeProvider>
        </Container>

        <SwipeableDrawer
          anchor="left"
          open={drawer}
          onClose={toggleDrawer()}
          onOpen={toggleDrawer()}
        >
          <div
            role="presentation"
            onClick={toggleDrawer()}
            onKeyDown={toggleDrawer()}
            className={classes.mobileDrawer}
          >
            <List className="listGutter">
              {user && (
                <>
                  <Box
                    mb={3}
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <Typography className="drawerUserName">
                      {`${user.firstName || ''} ${user.lastName || ''}`}
                    </Typography>

                    {!user.isParent && (
                      <Box position="relative">
                        {isPremium ? (
                          <Link to={'/chat'} className={classes.navLink}>
                            <Badge
                              badgeContent={unreadMessagesCount}
                              color="secondary"
                            >
                              {CHAT_IC()}
                            </Badge>
                          </Link>
                        ) : (
                          <Link to={'/chat'} className={classes.navLink}>
                            {LOCKED_CHAT()}
                          </Link>
                        )}
                      </Box>
                    )}
                  </Box>
                </>
              )}

              {!user && (
                <ListItem>
                  <Link to="/applications" className={classes.navLink}>
                    Home
                  </Link>
                </ListItem>
              )}

              {localStorage.getItem(PARENT_TOKEN) && (
                <Box
                  onClick={async () => {
                    const oldToken = localStorage.getItem(PARENT_TOKEN);

                    if (oldToken) {
                      await localStorage.setItem(TOKEN, oldToken);
                      localStorage.removeItem(PARENT_TOKEN);
                      if ((window.location.pathname = '/home'))
                        window.location.replace(
                          '/account/student-accounts?parent=true'
                        );
                      else
                        window.location.pathname =
                          '/account/student-accounts?parent=true';
                    }
                  }}
                  className={classes.switchParentLink}
                >
                  Switch to Parent
                </Box>
              )}

              <Divider className={classes.navLinkDivider} />

              {mobileNavItems.map((item) => (
                <>
                  <ListItem
                    key={item.title}
                    className="navListItem"
                    style={
                      isTrial && item.title != 'Home'
                        ? { opacity: '40%', pointerEvents: 'none' }
                        : {}
                    }
                  >
                    {item?.title === 'My Meetings' && !isPremium ? (
                      <Box
                        className={clsx(
                          classes.linkMenuItem,
                          classes.meetingsDisable
                        )}
                      >
                        <Link
                          to="#"
                          onClick={(event) => event.preventDefault()}
                        >
                          <img src={MEETINGS_LOCKED_ICON} alt="lock" />
                          My Meetings
                        </Link>
                      </Box>
                    ) : item.hasIcon ? (
                      <Link
                        to={{
                          pathname: item.link,
                          state: { from: window.location.pathname },
                        }}
                        className={classes.navLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Box display="flex" alignItems="center">
                          {item.title}

                          <Box
                            marginLeft="11px"
                            position="relative"
                            bottom="4px"
                          >
                            {EX_LINK()}
                          </Box>
                        </Box>
                      </Link>
                    ) : item.title === 'Product Demo' ? (
                      <Link
                        to={item.link}
                        className={classes.navLink}
                        onClick={() => setShowDemo(true)}
                      >
                        Product Demo
                      </Link>
                    ) : (
                      <Link to={item.link} className={classes.navLink}>
                        {item.title}
                      </Link>
                    )}
                  </ListItem>
                  {item.title === 'Product Demo' && (
                    <Divider className="essayGuideDivider" />
                  )}
                </>
              ))}

              {user && (
                <>
                  <ListItem className={classes.signoutListItem}>
                    <Link
                      to="/"
                      onClick={() => {
                        localStorage.removeItem(TOKEN);
                        localStorage.removeItem(PARENT_TOKEN);
                        localStorage.removeItem(MEETING_SET);
                        setUser(null);
                        document.cookie =
                          '_caLoggedIn=false;domain=collegeadvisor.com';
                      }}
                      className={classes.navLinkSignout}
                      style={{ color: `${packageColor()} !important` }}
                    >
                      Sign Out
                    </Link>
                  </ListItem>
                </>
              )}

              {!user && !isSurveyForm && !isMeetingSetPage && (
                <ThemeProvider theme={customTheme}>
                  <Box>
                    <ListItem>
                      <Link
                        style={{ width: '100%' }}
                        to={`${
                          window.location.pathname !== '/login' &&
                          window.location.pathname !== '/forgot-password'
                            ? `/login?_goback=${window.location.pathname}`
                            : '/login'
                        }`}
                      >
                        <Button variant="outlined" fullWidth>
                          Log In
                        </Button>
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link
                        style={{ width: '100%' }}
                        to={`${
                          goBack
                            ? `/register${window.location.search}`
                            : window.location.pathname !== '/login' &&
                              window.location.pathname !== '/forgot-password'
                            ? `/register?_goback=${window.location.pathname}`
                            : '/register'
                        }`}
                      >
                        <Button variant="contained" color="secondary" fullWidth>
                          Register for FREE
                        </Button>
                      </Link>
                    </ListItem>
                  </Box>
                </ThemeProvider>
              )}
            </List>
          </div>
        </SwipeableDrawer>
      </AppBar>
    </MuiThemeProvider>
  );
};

export default MBAHeaderFree;
