import { FC, useContext, FormEvent, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { Formik, Form, Field } from 'formik';
import {
  Grid,
  Button,
  Card,
  Box,
  TextField as MaterialField,
  CircularProgress,
  ThemeProvider,
} from '@material-ui/core';

import { AppContext } from '../../../contexts';
import { useStyles } from '../../login/styles';
import { TextField } from '../../../components/fields';
import CustomAlert from './../../../components/NewCustomAlert';
import Colors from '../../../common/colors';
import Fonts from '../../../common/fonts';
import { TOKEN, SHOW_PARENT_MODAL } from '../../../constants';
import RightSidePage from '../../login/LeadRegistration/RightSidePage';
import SmallLogo from '../../../components/layout/SmallLogo';
import ValidatationCheckMark from '../../../components/ValidationCheckMark';
import Loader from '../../../components/Loader';

import client from '../../../apollo';
import {
  CreateParentMutation,
  CreateParentMutationVariables,
} from './__generated__/CreateParentMutation';
import { USER_WITH_INVITE_TOKEN } from './__generated__/gql';
import { customTheme } from '../../MaterializeCss';

export const CREATE_PARENT_MUTATION = gql`
  mutation CreateParentMutation($invitationToken: String!, $password: String!) {
    createParent(
      input: { invitationToken: $invitationToken, password: $password }
    ) {
      status
      token
      studentId
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        highSchool
        highSchoolYear
        gpa
        sat
        act
        premiumPackageType
        isParent
      }
    }
  }
`;

export const ACCOUNT_CONFIRMATION = gql`
  mutation AccountConfirmation($invitationToken: String!, $password: String!) {
    accountConfirmation(
      input: { invitationToken: $invitationToken, password: $password }
    ) {
      status
      token
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        highSchool
        highSchoolYear
        gpa
        sat
        act
        premiumPackageType
        isParent
      }
    }
  }
`;

type TParams = {
  invitationToken: string;
};
const NewParentRegisterPage: FC<RouteComponentProps<TParams>> = ({
  history,
  match: {
    params: { invitationToken },
  },
}) => {
  const [name, setName] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [variant, setVariant] = useState('');
  const [message, setMessage] = useState('');
  const [backendError, setBackendError] = useState<string | undefined>();
  const { setIsLoggedIn, user } = useContext(AppContext);
  user && history.push('/');

  const url = new URL(window.location.href);
  const senderFirstName = url.searchParams.get('n');
  const senderRole = url.searchParams.get('r') || 's';
  const accountConfirm = url.searchParams.get('accountConfirm');

  const initialValues = { password: '', confirmPassword: '', invitationToken };
  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .required('Please Enter your password')
      .matches(/^(?=.*[A-Za-z])/, 'MIN_ONE_CHAR')
      .matches(/^(?=.*\d)/, 'MIN_ONE_NUM')
      .min(8, 'MIN_8_CHAR'),
    confirmPassword: Yup.string().test(
      'passwords-match',
      'Passwords must match',
      function (value) {
        return this.parent.password === value;
      }
    ),
  });

  const classes = useStyles();

  const mountEffect = () => {
    const apiCalling = async () => {
      try {
        const result = await client.query({
          query: USER_WITH_INVITE_TOKEN,
          variables: { token: invitationToken },
        });
        const { firstName, lastName, email } = result.data.userWithInviteToken;
        firstName ? setName(`${firstName} ${lastName}`) : setName(undefined);
        setEmail(email);
      } catch (err: any) {
        setBackendError(err.message.split(':')[1]);
      }
    };
    apiCalling();
  };
  useEffect(mountEffect, []);
  const toggleAlert = (variant?: string, message?: string) => {
    setVariant(variant || '');
    setMessage(message || '');
  };

  if (!email && !backendError) return <Loader />;

  return (
    <>
      <Card className={`${classes.cardModal} custom-card`}>
        <Box className={classes.registerContainer}>
          <Box className={classes.registerLeft}>
            <CustomAlert
              variant={variant}
              message={message}
              toggleAlert={toggleAlert}
            />
            <Mutation<CreateParentMutation, CreateParentMutationVariables>
              mutation={CREATE_PARENT_MUTATION}
            >
              {(createParentMutation: Function) => {
                return (
                  <Mutation mutation={ACCOUNT_CONFIRMATION}>
                    {(accountConfirmation: Function) => {
                      return (
                        <Formik
                          initialValues={initialValues}
                          validationSchema={LoginSchema}
                          onSubmit={async (variables, { setSubmitting }) => {
                            try {
                              const {
                                password,
                                confirmPassword,
                                invitationToken,
                              } = variables;
                              if (password !== confirmPassword) {
                                toggleAlert(
                                  'danger',
                                  'Password and Confirm Password not same'
                                );

                                return setSubmitting(false);
                              }

                              let result: any = '';
                              let status, token, user, studentId: any;
                              if (accountConfirm) {
                                result = await accountConfirmation({
                                  variables: { password, invitationToken },
                                });
                                if (
                                  result.data &&
                                  result.data.accountConfirmation
                                ) {
                                  let accountConfirm =
                                    result.data.accountConfirmation;
                                  status = accountConfirm.status;
                                  token = accountConfirm.token;
                                  user = accountConfirm.user;
                                  studentId = accountConfirm.studentId;
                                }
                              } else {
                                result = await createParentMutation({
                                  variables: { password, invitationToken },
                                });
                                let createParent = result.data.createParent;
                                if (
                                  result &&
                                  result.data &&
                                  result.data.createParent
                                )
                                  status = createParent.status;
                                token = createParent.token;
                                user = createParent.user;
                                studentId = createParent.studentId;
                              }

                              if (status) {
                                console.log(
                                  'user...',
                                  user,
                                  'student',
                                  studentId
                                );
                                if (window.analytics) {
                                  window.analytics.identify(studentId, {
                                    ...(user.isParent
                                      ? { parentConfirmed: true }
                                      : { studentConfirmed: true }),
                                    name: user.firstName || '',
                                    email: user.email || '',
                                  });
                                }

                                const {
                                  email,
                                  firstName,
                                  lastName,
                                  phoneNumber,
                                  highSchool,
                                  highSchoolYear,
                                  gpa,
                                  sat,
                                  act,
                                  premiumPackageType,
                                } = user;
                                if (window.analytics) {
                                  window.analytics.identify(user.id, {
                                    email: email || '',
                                    'First Name': firstName || '',
                                    'Last Name': lastName || '',
                                    'Full Name': `${firstName || ''} ${
                                      lastName || ''
                                    }`,
                                    'Phone Number': phoneNumber || '',
                                    'High School': highSchool || '',
                                    'High School Year': highSchoolYear || '',
                                    Parent: user.isParent ? 'Yes' : 'No',
                                    GPA: gpa || '',
                                    SAT: sat || '',
                                    ACT: act || '',
                                    'Package Type': premiumPackageType,
                                    name: `${firstName || ''} ${
                                      lastName || ''
                                    }`,
                                  });
                                }
                                await localStorage.setItem(TOKEN, token);
                                localStorage.setItem(SHOW_PARENT_MODAL, 'true');
                                setIsLoggedIn(true);
                                history.push('/');
                              } else {
                                setSubmitting(false);
                                toggleAlert(
                                  'danger',
                                  'Invalid or Expired token'
                                );
                              }
                            } catch (error) {
                              console.log('error: ', error);
                              setSubmitting(false);
                              toggleAlert(
                                'danger',
                                'Something went wrong, Please try again'
                              );
                            }
                          }}
                        >
                          {({
                            isSubmitting,
                            touched,
                            values,
                            isValid,
                            setFieldValue,
                          }) => {
                            if (backendError) {
                              toggleAlert('danger', backendError);
                            }

                            const letters = /[a-zA-Z]/g;
                            const numbers = /[0-9]/g;
                            return (
                              <Form>
                                <Box
                                  maxWidth={390}
                                  margin="0 auto"
                                  width="100%"
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    marginBottom={7.5}
                                  >
                                    <Box
                                      maxWidth={48}
                                      marginRight={2}
                                      className="hide-mobile"
                                    >
                                      <SmallLogo />
                                    </Box>

                                    <Box component="h4">
                                      Welcome{name ? <>, {name}</> : ''}!
                                    </Box>
                                  </Box>
                                </Box>

                                <Box maxWidth={390} margin="0px auto">
                                  <Grid className={classes.createPassword}>
                                    <Box
                                      paddingBottom={1.5}
                                      className={classes.checkListHeading}
                                    >
                                      {` Create a password to access your student${
                                        senderRole === 's' ? "'s" : ''
                                      }
                                      portal`}
                                    </Box>
                                    <Box
                                      fontSize={12}
                                      paddingBottom={5.6}
                                      fontFamily={Fonts.INTER}
                                    ></Box>
                                    {false && !accountConfirm && (
                                      <>
                                        {senderRole === 's' ? (
                                          <Box
                                            fontSize={12}
                                            paddingBottom={5.6}
                                            fontFamily={Fonts.INTER}
                                          >
                                            You have been invited
                                            {senderFirstName ? (
                                              <>
                                                {' '}
                                                by your child,{' '}
                                                <strong>
                                                  {senderFirstName}
                                                </strong>
                                                ,
                                              </>
                                            ) : (
                                              ''
                                            )}{' '}
                                            to view their CollegeAdvisor.com
                                            Admissions account. Create your
                                            account to view your child's
                                            progress
                                          </Box>
                                        ) : (
                                          <Box fontSize={12} marginBottom={5.6}>
                                            You have been invited
                                            {senderFirstName ? (
                                              <>
                                                {' '}
                                                by your parent,{' '}
                                                <strong>
                                                  {senderFirstName}
                                                </strong>
                                                ,
                                              </>
                                            ) : (
                                              ''
                                            )}{' '}
                                            to create your CollegeAdvisor.com
                                            account. Create your account to
                                            explore different colleges and
                                            connect with expert advisors.
                                          </Box>
                                        )}
                                      </>
                                    )}
                                    <Field
                                      variant="filled"
                                      value={email ? email : ''}
                                      name="userEmail"
                                      component={TextField}
                                      label={`Your Email`}
                                      placeholder={`Your Email`}
                                      disabled={true}
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                      className={classes.textfield}
                                    />

                                    <Grid
                                      className={`${classes.fieldContainer} custom-margin`}
                                    >
                                      <Field name="aidOption" type="password">
                                        {({ field }: any) => (
                                          <MaterialField
                                            label={'Password'}
                                            type={'password'}
                                            placeholder={'Password'}
                                            variant="filled"
                                            value={field.value}
                                            disabled={
                                              backendError ? true : false
                                            }
                                            onChange={(e: any) => {
                                              const {
                                                currentTarget: { value },
                                              } = e;
                                              setFieldValue('password', value);
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            className={classes.textfield}
                                          />
                                        )}
                                      </Field>

                                      <Field
                                        type="password"
                                        name="confirmPassword"
                                        component={TextField}
                                        variant="filled"
                                        label="Confirm Password"
                                        placeholder="Confirm Password"
                                        disabled={backendError ? true : false}
                                        onChange={(
                                          e: FormEvent<HTMLInputElement>
                                        ) => {
                                          const {
                                            currentTarget: { value },
                                          } = e;
                                          setFieldValue(
                                            'confirmPassword',
                                            value
                                          );
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        className={classes.textfield}
                                      />
                                    </Grid>

                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                    >
                                      <ValidatationCheckMark
                                        helperText="8 character minimum"
                                        type={
                                          !touched.password &&
                                          values.password.length === 0
                                            ? ``
                                            : values.password &&
                                              values.password.length >= 8
                                            ? `success`
                                            : `error`
                                        }
                                      />
                                      <ValidatationCheckMark
                                        helperText="Contain 1 letter"
                                        type={
                                          !touched.password &&
                                          values.password.length === 0
                                            ? ''
                                            : letters.test(values.password)
                                            ? `success`
                                            : `error`
                                        }
                                      />
                                      <ValidatationCheckMark
                                        helperText="Contain 1 number"
                                        type={
                                          !touched.password &&
                                          values.password.length === 0
                                            ? ''
                                            : numbers.test(values.password)
                                            ? `success`
                                            : `error`
                                        }
                                      />
                                    </Box>
                                  </Grid>
                                  <ThemeProvider theme={customTheme}>
                                    <Box
                                      marginTop={3.2}
                                      maxWidth={370}
                                      margin="0 auto"
                                    >
                                      <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={
                                          !isValid ||
                                          isSubmitting ||
                                          (backendError ? true : false)
                                        }
                                        color="secondary"
                                      >
                                        Accept Invite
                                        {isSubmitting && (
                                          <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                          />
                                        )}
                                      </Button>
                                    </Box>
                                  </ThemeProvider>
                                </Box>
                              </Form>
                            );
                          }}
                        </Formik>
                      );
                    }}
                  </Mutation>
                );
              }}
            </Mutation>
            <Box
              className={classes.termsText}
              paddingTop={5}
              textAlign="center"
              fontSize={12}
              color={Colors.BLACK_TEN}
              fontFamily={Fonts.INTER}
            >
              By creating an account, you agree to the
              <a
                href="https://www.collegeadvisor.com/terms-of-service"
                target="_blank"
              >
                Terms of Service
              </a>{' '}
              and
              <a
                href="https://www.imgacademy.com/privacy-policy?website=collegeadvisor.com"
                target="_blank"
              >
                Privacy Policy
              </a>
            </Box>
          </Box>

          <RightSidePage />
        </Box>
      </Card>
    </>
  );
};

export default NewParentRegisterPage;
