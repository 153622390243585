const linearGradients = [
  {
    start: '#3660B9',
    end: '#2F7DE8',
  },
  {
    start: '#FDA918',
    end: '#F5BD60',
  },
  {
    start: '#08B3A1',
    end: '#61BBEE',
  },
  {
    start: '#E04FAE',
    end: '#EFAD7E',
  },
];

export const randomLinerGradiant = () => {
  const gradient =
    linearGradients[Math.floor(Math.random() * linearGradients.length)];
  return `linear-gradient(to bottom left, ${gradient.start} 0%, ${gradient.end} 100%)`;
};
