import gql from 'graphql-tag';

export const CREATE_MBA_ACCOUNTS_MUTATION = gql`
  mutation CreateMBAAccountMutation(
    $studentFirstName: String!
    $studentLastName: String!
    $studentEmail: String
    $studentPhone: String
    $undergraduateGradYear: String
    $undergraduateCollege: String
    $userRole: String!
    $userAnalyticParams: JSON
    $partnerKey: String
    $source: String
    $salesRegitration: Boolean
    $salesPersonId: String
    $registrationType: String
  ) {
    createMBAAccount(
      input: {
        studentFirstName: $studentFirstName
        studentLastName: $studentLastName
        studentEmail: $studentEmail
        studentPhone: $studentPhone
        userRole: $userRole
        undergraduateGradYear: $undergraduateGradYear
        undergraduateCollege: $undergraduateCollege
        userAnalyticParams: $userAnalyticParams
        partnerKey: $partnerKey
        source: $source
        salesRegitration: $salesRegitration
        salesPersonId: $salesPersonId
        registrationType: $registrationType
      }
    ) {
      status
      redirectToLogin
      redirectToPassword
      token
      LeadId
    }
  }
`;

export const CREATE_MBA_USER_MUTATION = gql`
  mutation CreateMBAUserMutation(
    $studentFirstName: String
    $studentLastName: String
    $studentEmail: String
    $studentPhone: String
    $undergraduateGradYear: String
    $undergraduateCollege: String
    $userRole: String
    $password: String
    $LeadId: Int
    $partnerKey: String
  ) {
    createMBAUser(
      input: {
        studentFirstName: $studentFirstName
        studentLastName: $studentLastName
        studentEmail: $studentEmail
        studentPhone: $studentPhone
        undergraduateGradYear: $undergraduateGradYear
        undergraduateCollege: $undergraduateCollege
        userRole: $userRole
        password: $password
        LeadId: $LeadId
        partnerKey: $partnerKey
      }
    ) {
      status
      redirectToLogin
      token
    }
  }
`;
