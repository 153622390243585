import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from '@material-ui/core';
import { loadStripe } from '@stripe/stripe-js';
import { useContext, useState } from 'react';
import { Query } from 'react-apollo';
import client from '../../../apollo';
import fonts from '../../../common/fonts';
import SnackbarComponent, { Color } from '../../../common/SnackBar';
import { formatter } from '../../../common/utility';
import { AppContext } from '../../../contexts';
import crossIcon from '../../../img/close-dialog.svg';
import { CURRENT_USER_QUERY } from '../layouts/MainLayout';
import { GET_STUDENT_SOURCES, REPROCESS_PAYMENT } from './gql';
import { GetStudentSources } from './gql/__generated__/GetStudentSources';

const STRIPE_API_KEY =
  process.env.REACT_APP_STRIPE_CLIENT_KEY ||
  'pk_test_z08FEiGVjS2wpEVQiKxurepa00coYG4e6E';
const stripePromise = loadStripe(STRIPE_API_KEY);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelPlanDialog: {
      "& div[role='dialog']": {
        borderRadius: 20,
        width: '30vw !important',
        maxWidth: 'unset',
        minWidth: 400,
        padding: '20px 45px 30px 45px',
      },

      '& form': {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',

        '& .MuiTextField-root': {
          width: '50%',
        },
      },
    },
    colTitle: {
      fontWeight: 600,
      minWidth: '140px',
      fontSize: '16px',
    },
    dialogContent: {
      overflow: 'hidden',
      padding: 0,
    },
    dialogTitle: {
      fontWeight: 600,
      fontSize: '24px',
      textTransform: 'capitalize',
    },

    selectBox: {
      height: '32px',
      border: '1px solid #D4D4D4',
      '& *': {
        border: 'none !important',
      },
    },
    cancelBtn: {
      padding: '10px !important',
      borderRadius: '12px !important',
      fontSize: '14px !important',
      width: '150px',
      fontStyle: fonts.POPPINS_FONT,
    },
    dialogAction: {
      border: 'none',
      padding: '0px',
      marginTop: '15px',
      justifyContent: 'flex-start',
      '& button': {
        width: '120px',
        maxHeight: '40px',
        '&:nth-child(1)': {
          color: 'white',
          backgroundColor: '#45CE93',
        },
        '&:nth-child(2)': {
          color: '#272929',
          backgroundColor: '#FFFFFF',
          border: '1px solid #D4D4D4',
        },
      },
    },
  })
);

const ReprocessPaymentModal = ({
  showModal,
  onClose,
  StudentId,
  amount,
  InvoiceId,
  refetch,
}: {
  showModal: boolean;
  onClose: Function | any;
  StudentId: number | null;
  amount: number | null;
  InvoiceId: String;
  refetch: Function;
}) => {
  const { setUser } = useContext(AppContext);
  const [sourceId, setSourceId] = useState('');
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<Color | undefined>('success');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const handleSourceId = (e: any) => {
    setSourceId(e.target.value);
  };

  const fetchUser = async () => {
    const { data } = await client.query({
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'no-cache',
    });
    if (data && data.currentUser) {
      setUser(data.currentUser);
    }
  };

  const reprocessPayment = async () => {
    const fetchInvoices = async () => {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          refetch()
            .then(() => resolve('An error occured'))
            .catch((err: any) => reject(err.mesage));
        }, 2000);
      });
    };
    try {
      setLoading(true);
      const response = await client.mutate({
        mutation: REPROCESS_PAYMENT,
        variables: { StudentId, InvoiceId, SourceId: sourceId },
        fetchPolicy: 'no-cache',
      });
      if (response?.data?.reprocessPayment?.actionRequired) {
        const stripe = await stripePromise;
        if (stripe) {
          const { paymentIntent, error } = await stripe.confirmCardPayment(
            response?.data?.reprocessPayment?.clientSecret
          );
          if (error) {
            setOpen(true);
            setMessage(
              error.message || 'Error in payment, please try again later'
            );
            setSeverity('error');
            await fetchInvoices();
            onClose();
          }

          if (paymentIntent?.status === 'succeeded') {
            setOpen(true);
            setMessage('Successfully Processed');
            setSeverity('success');
            setSourceId('');
            await fetchInvoices();
            await fetchUser();

            onClose();
          }
          setLoading(false);
          return;
        }
      }

      setOpen(true);
      setMessage(
        response?.data?.reprocessPayment?.message || 'Successfully Processed'
      );
      setSeverity(
        response?.data?.reprocessPayment?.success ? 'success' : 'error'
      );
      setLoading(false);

      await fetchInvoices();

      if (response?.data?.reprocessPayment?.success) {
        onClose();
        setSourceId('');
      }
    } catch (err: any) {
      await fetchInvoices();
      onClose();
      setLoading(false);
    }
  };
  const formattedAmount = formatter.format(amount ? amount / 100 : 0);
  console.log('SourceId', sourceId);
  return (
    <>
      <Dialog
        open={showModal}
        onClose={() => {
          setSourceId('');
          setSeverity('success');
          setMessage('');
          onClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={`${classes.cancelPlanDialog}`}
      >
        <Box>
          <Box display={'flex'} justifyContent="space-between" className="mb-2">
            <Typography
              variant="h6"
              className={`pb-0 mt-4 ${classes.dialogTitle}`}
            >
              Reprocess Payment?
            </Typography>
            <img
              onClick={onClose}
              className="mt-4"
              height="12"
              width="12"
              src={crossIcon}
            />
          </Box>
        </Box>
        <DialogContent className={`dialogContent ${classes.dialogContent}`}>
          <Box className="my-3" display="flex" alignItems="center">
            <Typography className={classes.colTitle + ' mr-2'}>
              Payment Method:
            </Typography>
            <Query<GetStudentSources>
              query={GET_STUDENT_SOURCES}
              fetchPolicy="no-cache"
              variables={{ StudentId }}
            >
              {({ data, loading }) => {
                const getStudentSources = data?.getStudentSources;

                if (!sourceId && showModal && !loading) {
                  setSourceId(getStudentSources?.data?.[0]?.id || '');
                }
                return loading ? (
                  <Typography variant="body1">loading...</Typography>
                ) : getStudentSources?.data?.length ? (
                  <Select
                    onChange={handleSourceId}
                    className={classes.selectBox}
                    variant="outlined"
                    fullWidth
                    defaultValue={getStudentSources?.data?.[0]?.id || ''}
                  >
                    {getStudentSources?.data?.map((source: any) => {
                      return (
                        <MenuItem value={source.id}>
                          {source.card.brand} ending in {source.card.last4}
                        </MenuItem>
                      );
                    })}
                  </Select>
                ) : null;
              }}
            </Query>
          </Box>
          <Box className="my-3" display={'flex'} alignItems="center">
            <Typography className={classes.colTitle}>Amount:</Typography>
            {formattedAmount}
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            variant="contained"
            color="inherit"
            className={classes.cancelBtn}
            onClick={reprocessPayment}
            disabled={!(sourceId && InvoiceId) || loading}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            color="inherit"
            className={classes.cancelBtn}
            onClick={onClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarComponent
        open={open}
        handleClose={() => {
          setOpen(false);
          setMessage('');
          setSeverity('success');
        }}
        message={message}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        severity={severity}
      />
    </>
  );
};

export default ReprocessPaymentModal;
