import { Box, Grid, Typography } from '@material-ui/core';
import { FC } from 'react';
import Image from '../../components/Image';
import ThumbnailCardOverlay from './ThumbnailCardOverlay';
import { thumbnailCardStyles } from './styles';
import { randomLinerGradiant } from './helper';

interface Props {
  coverTileMaster: string;
  title?: string | null;
  about?: string | null;
  date?: any;
}

const WebinarThumbnailCard: FC<Props> = ({
  coverTileMaster,
  title,
  about,
  date,
}) => {
  const classes = thumbnailCardStyles();

  return (
    <>
      <Grid
        className={classes.thumbnailCarousel}
        style={{
          background: randomLinerGradiant(),
        }}
      >
        {coverTileMaster ? (
          <Image src={coverTileMaster} width="400" height="400" />
        ) : (
          <>
            <Typography variant="h6">
              {title && title?.length > 50
                ? title?.substring(0, 40) + ' ...'
                : title}
            </Typography>
          </>
        )}

        <Box className="onhoverBox">
          <ThumbnailCardOverlay title={about} date={date} />
        </Box>
      </Grid>
    </>
  );
};

export default WebinarThumbnailCard;
