import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core/styles';
import gql from 'graphql-tag';
import { FC, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';

import Colors from '../common/colors';
import Fonts from '../common/fonts';
import { PARENT_TOKEN, TOKEN } from '../constants';
import { AppContext } from '../contexts';
import Loader from './Loader';
import {
  ImpersonateMutation,
  ImpersonateMutationVariables,
} from './__generated__/ImpersonateMutation';
import { ParentAccessableAccountsQuery } from './__generated__/ParentAccessableAccountsQuery';

import CLOSE_GRAY_IMG from '../img/close-modal-icon.svg';
import PROFILE_IMAGE from '../img/empty-badge.svg';

import { Mutation, Query } from 'react-apollo';
import fonts from '../common/fonts';
import { customTheme } from '../pages/MaterializeCss';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalPaper: {
      '& .subtitleText': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#A9A9A9',
        fontFamily: fonts.INTER,
      },
    },

    modalContent: {
      padding: '40px 43px 29px',
      background: '#FFFFFF',
      boxShadow: '0px 4px 14px rgb(68 68 68 / 14%)',
      borderRadius: 25,
      maxWidth: 672,
      margin: '0px auto',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 672,
      transform: 'translate(-50%, -50%) !important',

      '&:focus-visible': {
        outline: 'none',
      },

      [theme.breakpoints.down('sm')]: {
        padding: 16,
        width: 550,
      },

      [theme.breakpoints.down('xs')]: {
        width: '97%',
      },
    },

    signButton: {
      fontSize: 14,
      fontWeight: 600,
      color: Colors.WHITE_ONE,
      textTransform: 'capitalize',
      boxShadow: 'none',
      minWidth: 126,
      height: 32,
      background: '#45CE93',
      border: '1px solid #45CE93',
      borderRadius: 12,
      textDecoration: 'none',
      fontFamily: fonts.POPPINS_FONT,

      '&.Mui-disabled': {
        background: '#45CE93',
        opacity: 0.5,
        color: '#ffffff',
        border: '1px solid #45CE93',
        cursor: 'not-allowed !important',
      },

      '&:focus': {
        outline: '0px',
      },

      '&:hover': {
        color: '#FFFFFF',
        background: '#38A878',
        border: '1px solid #38A878',
        boxShadow: 'none',
      },
    },

    fabClose: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      width: '35px',
      height: '35px',
      outline: '0px',
      padding: 0,

      '&:focus': {
        outline: '0px',
      },
    },

    modalHeading: {
      fontWeight: 600,
      fontSize: 24,
      letterSpacing: '-0.019em',
      color: '#272929',
      fontFamily: `${Fonts.POPPINS_FONT}`,

      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
        lineHeight: '30px',
        letterSpacing: 0,
      },
    },

    billingTableRow: {
      border: '1px solid #D4D4D4',
    },

    accountProfile: {
      width: 32,
      height: 32,
      backgroundColor: Colors.GRAY_TWELVE,
      borderRadius: '50%',
      padding: 0,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'block !important',
    },

    noAccount: {
      fontSize: 16,
      fontFamily: Fonts.INTER,
      color: Colors.BLACK_FOURTEEN,
    },

    billingTableWrapper: {
      borderCollapse: 'separate',
      borderSpacing: '0px 11px',

      '& tbody': {
        '& tr': {
          '& td': {
            padding: '9px 15px',
            border: `1px solid ${Colors.WHITE_SEVEN}`,
            borderLeft: 0,
            borderRight: 0,
            whiteSpace: 'nowrap',

            '& button': {
              padding: 0,
            },
            '&:first-child': {
              borderRadius: '12px 0px 0px 12px',
              borderLeft: `1px solid #d4d4d4`,
            },

            '&:last-child': {
              borderRadius: '0px 12px 12px 0px',
              borderRight: `1px solid #d4d4d4`,
            },
          },
        },
      },
    },

    tableHead: {
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      padding: '0px 22px 0px',
      fontFamily: Fonts.POPPINS_FONT,
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: '0.3375px',
      color: '#525454',
      border: 0,
    },

    tableCell: {
      fontFamily: Fonts.INTER,
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: '-0.006em',
      color: '#525454',
    },

    seeYourAccount: {
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: '-0.006em',
      color: '#A9A9A9',
      borderBottom: '1px solid #A9A9A9',

      '&:hover': {
        color: '#2F80ED',
        borderBottom: '1px solid #2F80ED',
      },
    },
  })
);

const PARENT_ACCESSABLE_ACCOUNTS_QUERY = gql`
  query ParentAccessableAccountsQuery {
    parentAccessableAccounts {
      id
      firstName
      lastName
      email
      pictureUrl
      isPasswordSet
    }
  }
`;

export const IMPERSONATE_MUTATION = gql`
  mutation AccessImpersonateMutation($studentId: Int!) {
    impersonate(input: { studentId: $studentId }) {
      status
      token
    }
  }
`;

interface Props extends RouteComponentProps {
  open: boolean;
  onClose: () => void;
  setOpenParentAccountAccess?: Function;
}

const ScoreCardModal: FC<Props> = ({
  open,
  onClose: close,
  setOpenParentAccountAccess,
  history,
}) => {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const [openAddStudentModal, setOpenAddStudentModal] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [heading, setHeading] = useState('Select a Student Account');
  const [activeButton, setActiveButton] = useState(false);

  function onCloseStudentModal() {
    setOpenAddStudentModal(false);
  }

  function renderText() {
    return (
      <Box>
        <Typography variant="h5" component="h5" className={classes.noAccount}>
          Create and connect a student account to access the Student Portal.
        </Typography>
      </Box>
    );
  }

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const parent = queryParams.get('parent');

  const onClose = () => {
    setOpenModel(false);
    if (setOpenParentAccountAccess) {
      setOpenParentAccountAccess(false);
    }
    close();
  };
  useEffect(() => {
    if (parent) {
      setOpenModel(true);
      if (setOpenParentAccountAccess) {
        setOpenParentAccountAccess(true);
      }
    }
  }, []);

  useEffect(() => {
    if (open === true) {
      setOpenModel(open);
    }
  }, [open]);

  if (!user) return null;
  const { isParent } = user;

  return (
    <>
      {user && isParent && (parent || openModel) && (
        <>
          <Modal
            open={openModel}
            onClose={onClose}
            className={classes.modalPaper}
          >
            <Grid className={classes.modalContent}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom={1}
              >
                <Typography
                  variant="h3"
                  component="h3"
                  className={classes.modalHeading}
                >
                  {heading}
                </Typography>

                <IconButton onClick={onClose} className={classes.fabClose}>
                  <img src={CLOSE_GRAY_IMG} alt="close" />
                </IconButton>
              </Box>

              <Mutation<ImpersonateMutation, ImpersonateMutationVariables>
                mutation={IMPERSONATE_MUTATION}
              >
                {(impersonateMutation: Function) => (
                  <Query<ParentAccessableAccountsQuery>
                    query={PARENT_ACCESSABLE_ACCOUNTS_QUERY}
                    fetchPolicy="network-only"
                  >
                    {({ data, loading }) => {
                      if (loading) return <Loader isSmallLoader />;
                      if (!data || !data.parentAccessableAccounts) {
                        setHeading('Create Student Account');
                        setActiveButton(true);
                        return <Box marginBottom={3}>{renderText()}</Box>;
                      }
                      const { parentAccessableAccounts } = data;
                      if (!parentAccessableAccounts.length) {
                        setHeading('Create Student Account');
                        setActiveButton(true);
                        return <Box marginBottom={3}>{renderText()}</Box>;
                      }
                      return (
                        <>
                          <Typography variant="body1" className="subtitleText">
                            You will be directed to their Student Portal.
                          </Typography>
                          <Box overflow="auto">
                            <Table className={classes.billingTableWrapper}>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    className={classes.tableHead}
                                    width="10%"
                                  ></TableCell>
                                  <TableCell
                                    width="40%"
                                    className={classes.tableHead}
                                  >
                                    Name
                                  </TableCell>
                                  <TableCell
                                    width="30%"
                                    className={classes.tableHead}
                                  >
                                    Status
                                  </TableCell>
                                  <TableCell
                                    width="20%"
                                    className={classes.tableHead}
                                  ></TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {data?.parentAccessableAccounts?.map(
                                  (parentAccessableAccount, index) => {
                                    if (!parentAccessableAccount) return null;
                                    const {
                                      firstName,
                                      lastName,
                                      id: studentId,
                                      pictureUrl,
                                      isPasswordSet,
                                    } = parentAccessableAccount;

                                    const backgroundImage = `url( ${
                                      pictureUrl || PROFILE_IMAGE
                                    } )`;

                                    return (
                                      <TableRow
                                        key={index}
                                        className={classes.billingTableRow}
                                      >
                                        <TableCell
                                          className={classes.tableCell}
                                        >
                                          <Grid
                                            className={classes.accountProfile}
                                            style={{ backgroundImage }}
                                          />
                                        </TableCell>

                                        <TableCell
                                          className={classes.tableCell}
                                        >
                                          {firstName + ' ' + lastName}
                                        </TableCell>

                                        <TableCell
                                          className={classes.tableCell}
                                        >
                                          Active User
                                        </TableCell>

                                        <TableCell
                                          className={classes.tableCell}
                                        >
                                          <Button
                                            className={classes.signButton}
                                            variant="contained"
                                            onClick={async () => {
                                              try {
                                                const result =
                                                  await impersonateMutation({
                                                    variables: {
                                                      studentId,
                                                    },
                                                  });

                                                const {
                                                  data: {
                                                    impersonate: {
                                                      token,
                                                      status,
                                                    },
                                                  },
                                                } = result;
                                                if (status === true) {
                                                  if (user.isParent === true) {
                                                    const oldToken =
                                                      await localStorage.getItem(
                                                        TOKEN
                                                      );
                                                    oldToken &&
                                                      (await localStorage.setItem(
                                                        PARENT_TOKEN,
                                                        oldToken
                                                      ));
                                                  }
                                                  await localStorage.setItem(
                                                    TOKEN,
                                                    token
                                                  );
                                                  window.location.pathname =
                                                    '/home';
                                                  onClose();
                                                }
                                              } catch (error) {
                                                console.log('error: ', error);
                                              }
                                            }}
                                          >
                                            Select
                                          </Button>

                                          {false && !isPasswordSet && (
                                            <Box display="inline-block">
                                              Pending approval
                                            </Box>
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  }
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </>
                      );
                    }}
                  </Query>
                )}
              </Mutation>

              <Box paddingTop={1}>
                {activeButton ? (
                  <ThemeProvider theme={customTheme}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        onCloseStudentModal();
                        window.location.replace(
                          '/account/student-accounts?add=new'
                        );
                      }}
                    >
                      Get Started
                    </Button>
                  </ThemeProvider>
                ) : (
                  <a
                    href="/account/student-accounts?add=new"
                    onClick={() => {
                      onCloseStudentModal();
                    }}
                    className={classes.seeYourAccount}
                  >
                    Don't see your student? Add them to your account.
                  </a>
                )}
              </Box>
            </Grid>
          </Modal>
        </>
      )}
    </>
  );
};

export default withRouter(ScoreCardModal);
