import { makeStyles } from '@material-ui/core';
import fonts from './fonts';
import colors from './colors';

export const useStylesBootstrap = (
  width?: string,
  color?: string,
  border?: string,
  background?: string
) =>
  makeStyles((theme) => ({
    arrow: {
      color: color ? '#fff' : colors.BLACK_THIRTEEN,
      bottom: '-9px !important',
      marginLeft: -4,
      width: 32,
      height: '1.71em',
    },
    image: {
      cursor: 'pointer',
      marginLeft: 14,
    },
    tooltip: {
      fontFamily: fonts.INTER,
      padding: '10px 13px 13px',
      fontWeight: 400,
      fontSize: 12,
      letterSpacing: '-0.006em',
      color: color ? '#7D7F7F' : colors.WHITE_ONE,
      background: background ? '#FFFFFF' : colors.BLACK_THIRTEEN,
      border: border
        ? '1px solid #F6F8F9'
        : `1px solid ${colors.BLACK_THIRTEEN}`,
      borderRadius: 6,
      boxShadow:
        '0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24) !important',
      maxWidth: width ? width : '300px',
    },

    tooltipText: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: '-0.006em',
      color: colors.GRAY_TWENTYTWO,
    },
  }));
