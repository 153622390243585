import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Grid,
  IconButton,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { FC, useContext, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import client from '../apollo';
import SnackbarComponent, { Color } from '../common/SnackBar';
import { renderFormikField } from '../common/components';
import { IDialog } from '../common/interfaces';
import { getHighSchoolYearsArray } from '../common/utility';
import { AppContext } from '../contexts';
import Images from '../img';
import { customTheme } from '../pages/MaterializeCss';
import { UPDATE_SELF } from '../pages/main/clientEnrollment/gql';
import Dropdown from './Dropdown';
import { editUserInfoDialog } from './styles';
import { roles } from '../common/constants';

type TinitialValues = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  highSchoolYear?: string;
};

const EditUserInfoDialog: FC<IDialog> = ({ open, setOpen, user }) => {
  const classes = editUserInfoDialog();
  const [snackOpen, setSnackOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<Color | undefined>();
  const { fetchPlans, setFetchPlans } = useContext(AppContext);

  if (!user) return null;

  const { firstName, lastName, email, phoneNumber, highSchoolYear } = user;
  const uPhoneNumber = phoneNumber
    ? phoneNumber?.includes('+')
      ? phoneNumber
      : `+1${phoneNumber}`
    : '';
  let initialValues: TinitialValues = {
    firstName,
    lastName,
    email,
    phoneNumber: uPhoneNumber,
  };

  const onClose = () => setOpen(false);

  const highSchoolYearArray = getHighSchoolYearsArray();
  const hasChangeOccured = (
    _firstName: string,
    _lastName: string,
    _email: string,
    _phoneNumber: string,
    _highSchoolYear?: string
  ) => {
    if (
      _firstName === firstName &&
      _lastName === lastName &&
      _email === email &&
      _phoneNumber === uPhoneNumber &&
      _highSchoolYear === highSchoolYear
    ) {
      return false;
    }
    return true;
  };
  const hasAnyUndefined = (values: any) => {
    let hasAnyUndefinedValue = false;
    for (const key in values) {
      if (
        (key === 'highSchoolYear' && userType === roles.Parent) ||
        key === 'phoneNumber'
      ) {
      } else if (!values[key]) {
        hasAnyUndefinedValue = true;
      }
    }
    return hasAnyUndefinedValue;
  };
  const userType = user.roles[0];

  if (userType === roles.STUDENT) {
    initialValues = { ...initialValues, highSchoolYear };
  }

  return (
    <>
      <Dialog
        className={classes.dialog}
        open={open}
        onClose={onClose}
        maxWidth="sm"
      >
        <Box>
          <Box display={'flex'} justifyContent={'space-between'} mb={3}>
            <Typography variant="h5" className={classes.heading}>
              Edit {userType}
            </Typography>
            <Typography onClick={onClose} variant="button">
              <IconButton>
                <img src={Images.DIALOG_CLOSE_GRAY_ICON} />
              </IconButton>
            </Typography>
          </Box>
          <ThemeProvider theme={customTheme}>
            <Formik
              enableReinitialize={true}
              initialValues={{ ...initialValues }}
              onSubmit={async (variables, { setSubmitting }) => {
                try {
                  const {
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    highSchoolYear,
                  } = variables;
                  const { data } = await client.mutate({
                    mutation: UPDATE_SELF,
                    variables: {
                      firstName,
                      lastName,
                      email,
                      phoneNumber: phoneNumber || '',
                      highSchoolYear: highSchoolYear
                        ? String(highSchoolYear)
                        : null,
                      userToken: user.userToken,
                    },
                  });
                  if (data.updateSelfUser) {
                    if (highSchoolYear !== user.highSchoolYear) {
                      setFetchPlans(!fetchPlans);
                    }
                    setFetchPlans(!fetchPlans);
                    setSnackOpen(true);
                    setSeverity('success');
                    setMessage('Successfully Updated');
                    setOpen(false);
                  }
                } catch (error: any) {
                  setSnackOpen(true);
                  setMessage(
                    error?.graphQLErrors?.[0]?.message || error?.message
                  );
                  setSeverity('error');
                }
              }}
            >
              {({
                isSubmitting,
                values,
                setFieldValue,
                setFieldTouched,
                errors,
                touched,
              }) => {
                const {
                  firstName,
                  lastName,
                  email,
                  phoneNumber,
                  highSchoolYear,
                } = values;

                const isDisabled =
                  !hasChangeOccured(
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    highSchoolYear
                  ) ||
                  Object.keys(errors).length ||
                  hasAnyUndefined(values);

                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography variant="caption">First Name</Typography>
                        {renderFormikField(
                          'firstName',
                          'First Name',
                          values['firstName'],
                          errors,
                          touched,
                          setFieldValue,
                          true,
                          undefined
                        )}
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography variant="caption">Last Name</Typography>
                        {renderFormikField(
                          'lastName',
                          'Last Name',
                          values['lastName'],
                          errors,
                          touched,
                          setFieldValue,
                          true,
                          undefined
                        )}
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography variant="caption">Email</Typography>
                        {renderFormikField(
                          'email',
                          'Email',
                          values['email'],
                          errors,
                          touched,
                          setFieldValue,
                          true,
                          values.email
                        )}
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography variant="caption">Phone Number</Typography>
                        <Field
                          name={`phoneNumber`}
                          placeholder={`Phone Number`}
                          validate={(value: any | string) => {
                            if (value && !isValidPhoneNumber(value)) {
                              return 'Invalid Phone Number';
                            }
                          }}
                        >
                          {({ field, ...otherProps }: any) => {
                            return (
                              <Box>
                                <Box className="phoneInputMasterResolve">
                                  <PhoneInput
                                    placeholder={`Phone Number`}
                                    value={phoneNumber}
                                    defaultCountry="US"
                                    {...otherProps}
                                    onBlur={() => {
                                      setFieldTouched(field.name, true);
                                    }}
                                    onChange={(value) => {
                                      setFieldValue(field.name, value);
                                    }}
                                    className={
                                      errors['phoneNumber'] &&
                                      touched['phoneNumber']
                                        ? `inputError`
                                        : ''
                                    }
                                  />
                                </Box>
                              </Box>
                            );
                          }}
                        </Field>
                      </Grid>
                      {userType === roles.STUDENT ? (
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography variant="caption">Grad Year</Typography>
                          <Dropdown
                            label={``}
                            errorValue={`High School Grad Year`}
                            selectedValue={values.highSchoolYear || ''}
                            options={highSchoolYearArray}
                            name="highSchoolYear"
                            onSelect={(evt: any) => {
                              const {
                                target: { value },
                              } = evt;
                              setFieldValue('highSchoolYear', value);
                            }}
                          />
                        </Grid>
                      ) : null}
                    </Grid>

                    <DialogActions className={classes.dialogActions}>
                      <Button
                        type="submit"
                        disabled={Boolean(isSubmitting || isDisabled)}
                        color="primary"
                        variant="contained"
                      >
                        Save
                      </Button>
                    </DialogActions>
                  </Form>
                );
              }}
            </Formik>
          </ThemeProvider>
        </Box>
      </Dialog>
      <SnackbarComponent
        open={snackOpen}
        handleClose={() => setSnackOpen(false)}
        message={message}
        autoHideDuration={2000}
        severity={severity}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    </>
  );
};

export default EditUserInfoDialog;
