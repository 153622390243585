import { Box, createStyles, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import Colors from '../../common/colors';
import fonts from '../../common/fonts';
import Image from '../../components/Image';
import ThumbnailCardOverlaySmall from './ThumbnailCardOverlaySmall';
import { randomLinerGradiant } from './helper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnailCarousel: {
      width: 192,
      height: 192,
      borderRadius: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',

      [theme.breakpoints.down('xs')]: {
        width: 182,
        height: 182,
      },

      [`@media only screen and (max-width: 400px)`]: {
        width: 165,
        height: 165,
      },

      [`@media only screen and (max-width: 360px)`]: {
        width: 135,
        height: 135,
      },

      '& img': {
        borderRadius: 20,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },

      "& [class*='MuiTypography-root']": {
        color: Colors.WHITE_ONE,
      },

      '& h6': {
        padding: '0px 15px',
        textAlign: 'center',
        fontFamily: fonts.POPPINS_FONT,

        [theme.breakpoints.down('xs')]: {
          fontSize: 12,
        },
      },

      '& .onhoverBox': {
        width: '100%',
        top: 0,
        position: 'absolute',
        visibility: 'hidden',
      },

      '&:hover': {
        '& .onhoverBox': {
          visibility: 'visible',
        },
      },
    },
  })
);

interface Props {
  coverTileNoDate?: string | null;
  title?: string | null;
  about?: string | null;
  date?: any;
  recording_url?: string | null;
}

const WebinarThumbnailCardSmall: FC<Props> = ({
  coverTileNoDate,
  title,
  about,
  date,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        className={classes.thumbnailCarousel}
        style={{
          background: randomLinerGradiant(),
        }}
      >
        {coverTileNoDate ? (
          <Image src={coverTileNoDate} width="250" height="250" />
        ) : (
          <Typography variant="h6">
            {title && title?.length > 50
              ? title?.substring(0, 40) + ' ...'
              : title}
          </Typography>
        )}

        <Box className="onhoverBox">
          <ThumbnailCardOverlaySmall title={about} date={date} />
        </Box>
      </Grid>
    </>
  );
};

export default WebinarThumbnailCardSmall;
