import { FC, useContext, useEffect, useState } from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import moment from 'moment';

import {
  Box,
  Button,
  Card,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import GetStartAdvisorCard, { CALENDAR_IC, VIDEO_IC } from './GetStartAdvisorCard';

import client from '../../../apollo';
import {
  associationTypeOptions,
  ClientStatus,
} from '../../../common/constants';
import { sortAdvisorsWithLevel } from '../../../common/utility';
import { AppContext } from '../../../contexts';
import { allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings } from '../home/BookingMeeting/gql/__generated__/allAdvisorBookingsQuery';
import { CURRENT_USER_QUERY } from '../layouts/MainLayout';
import { UserQuery } from '../layouts/__generated__/UserQuery';

import Colors from '../../../common/colors';
import fonts from '../../../common/fonts';

import LINK_COPIED_ICON from '../../../img/link-copied-icon.png';
import LINK_ICON from '../../../img/link-copy.png';
import { getAllStudentMentors_allStudentMentors } from '../../../graphql/queries/studentMentors/__generated__/getAllStudentMentors';
import { GET_ALL_STUDENT_MENTORS } from '../../../graphql/queries/studentMentors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 32,
      padding: '33px 30px 16px',
      borderRadius: 25,
      border: '1px solid #D4D4D4',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('sm')]: {
        borderRadius: 15,
      },

      '& .scheduledDate': {
        fontWeight: 600,
        fontSize: 18,
        color: '#272929',
        letterSpacing: '-0.014em',
        marginBottom: 3,
        fontFamily: fonts.INTER,
      },

      '& .withAdvisorName': {
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
      },

      '& .heading': {
        marginBottom: 5,
      },

      '& .description': {
        marginBottom: 22,
        color: '#7D7F7F',
      },

      '& .orientationCall': {
        marginBottom: 33,

        '&:disabled, &.Mui-disabled': {
          pointerEvents: 'initial',
          cursor: 'not-allowed !important',
        },
      },

      '@media only screen and (max-width: 767px)': {
        marginBottom: 17,
        padding: '15px',
      },
    },

    rescheduleText: {
      marginBottom: 30,
      width: '430px',
      height: '40px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '-0.006em',
      color: '#A9A9A9',
      '& a': {
        color: '#A9A9A9',
        textDecoration: 'underline',
        textUnderlineOffset: '2px',
      },
    },

    myMeetingHeading: {
      fontSize: 14,
      fontWeight: 600,
      color: '#A9A9A9',
      letterSpacing: '0.006em',
      textTransform: 'uppercase',
      fontFamily: fonts.POPPINS_FONT,
    },

    joinMeeting: {
      padding: '14px 0px 43px',

      [theme.breakpoints.down('xs')]: {
        padding: '20px 0px',
      },

      '& .iconDisabled': {
        cursor: 'not-allowed',
      },
    },

    linkFormCopied: {
      opacity: '1 !important',
      color: '#ffffff !important',
      background: `${Colors.GREEN_TEN} !important`,
    },
  })
);

const getStepContent = (
  stepIndex: number,
  kickOffMeetingScheduled: boolean
) => {
  switch (stepIndex) {
    case 0:
      return {
        heading: 'Get Started',
        body: 'Complete your student and parent intake forms to get matched to your Advisor.',
      };
    case 1:
      return {
        heading: 'Your Advisor is On The Way!',
        body: 'Thank you for completing your intake forms. You will be notified when you are matched with an Advisor.',
      };
    case 2:
      return {
        heading: 'Schedule your first meeting',
        body: 'Get your college admissions journey started by telling us more about yourself and scheduling your first meeting!',
      };
    case 3:
      return {
        heading: "See you soon! Until then, let's learn more about you.",
        body: 'Return here at your meeting time and get ready for a great boost to your admissions journey. To make the most out of your meeting, please submit your responses to this questionnaire below!',
      };
    case 4:
      return {
        heading: 'Meeting Ready!',
        body: 'It’s meeting time! You’re one click away from a major boost to your admisisons journey. See you in a moment!',
      };
    case 5:
      return {
        heading:
          'Thanks for letting us join you on your college admissions journey !',
        body: 'Schedule a free strategy meeting to learn more about continuing your personalized advising experience.',
      };
    default:
      return {
        heading: 'Unknown stepIndex',
        body: 'Unknown stepIndex',
      };
  }
};

interface advisorsWithKickOffCallInterFace
  extends allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings {
  advisorName: string;
  pictureUrl: string;
}

interface Props extends RouteComponentProps {
  handleCalendarClick: Function;
  advisorBookings:
    | (allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings | null)[]
    | null
    | undefined;
  refetch: Function;
  setIsMeetingBooked: Function;
  isMeetingBooked: boolean;
  isTrialCompleted: boolean;
  isTrialMeetingBooked: boolean;
}

const MeetingForTrial: FC<Props> = ({
  handleCalendarClick,
  advisorBookings,
  history,
  refetch,
  setIsMeetingBooked,
  isMeetingBooked,
  isTrialCompleted,
  isTrialMeetingBooked,
}) => {
  const { user, setUser } = useContext(AppContext);
  const [isCopied, setIsCopied] = useState(false);
  const classes = useStyles();
  const [allStudentMentors, setAllStudentMentors] = useState<
    (getAllStudentMentors_allStudentMentors | null)[] | null
  >(null);

  const [activeStep, setActiveStep] = useState(!isTrialCompleted ? 2 : 5);

  const isKick_Off_Meeting_Schedule: boolean =
    !!allStudentMentors?.length &&
    !!!user?.initialKickOffDate &&
    !!advisorBookings?.length;

  const meetingAdvisorids: (number | null)[] = [];
  const advisorsWithKickOffCall:
    | (advisorsWithKickOffCallInterFace | null)[]
    | null = [];

  const isMeetingEnded =
    user?.firstMeetingBookedDate?.endTime &&
    user?.firstMeetingBookedDate?.endTime &&
    user?.firstMeetingBookedDate?.startTime * 1000 < new Date().getTime() &&
    user?.firstMeetingBookedDate?.endTime * 1000 < new Date().getTime();

  if (advisorBookings?.length) {
    advisorBookings.map((item) =>
      item?.allParticipants?.map((p) => {
        if (
          p &&
          !meetingAdvisorids.find((advId) => advId === p?.id) &&
          p?.id !== user?.id
        )
          meetingAdvisorids.push(p?.id);
      })
    );

    advisorBookings.map((item) => {
      if (item && !advisorsWithKickOffCall.find((x) => x?.id === item?.id)) {
        const firstName =
          item.allParticipants?.find((p) => p?.id === item.AdvisorId)
            ?.firstName || '_ _';
        const lastName =
          item.allParticipants?.find((p) => p?.id === item.AdvisorId)
            ?.lastName || '_ _';
        const pictureUrl =
          item.allParticipants?.find((p) => p?.id === item.AdvisorId)
            ?.pictureUrl || '';

        advisorsWithKickOffCall.push({
          ...item,
          advisorName: `${firstName} ${lastName}`,
          pictureUrl,
        });
      }
    });
  }

  const currentStepObject = getStepContent(
    activeStep,
    isKick_Off_Meeting_Schedule
  );

  useEffect(() => {
    if (
      user?.firstMeetingBookedDate?.startTime &&
      user?.firstMeetingBookedDate?.startTime * 1000 > new Date().getTime()
    ) {
      setActiveStep(3);
    }
    if (
      user?.firstMeetingBookedDate?.endTime &&
      user?.firstMeetingBookedDate?.endTime &&
      user?.firstMeetingBookedDate?.startTime * 1000 <= new Date().getTime() &&
      user?.firstMeetingBookedDate?.endTime * 1000 >= new Date().getTime()
    ) {
      setActiveStep(4);
    }
    if (
      user?.firstMeetingBookedDate?.endTime &&
      user?.firstMeetingBookedDate?.endTime &&
      user?.firstMeetingBookedDate?.startTime * 1000 < new Date().getTime() &&
      user?.firstMeetingBookedDate?.endTime * 1000 < new Date().getTime() &&
      isTrialCompleted
    ) {
      setActiveStep(5);
    }
  }, [user]);

  const mountEffect = () => {
    const userQuery = async () => {
      try {
        const { data }: { data: UserQuery } = await client.query({
          query: CURRENT_USER_QUERY,
          fetchPolicy: 'network-only',
        });
        const { data: dataStudentMentors } = await client.query({
          query: GET_ALL_STUDENT_MENTORS,
          fetchPolicy: 'network-only',
        });
        if (dataStudentMentors && dataStudentMentors.allStudentMentors) {
          setAllStudentMentors(dataStudentMentors.allStudentMentors);
        }
        if (
          data?.currentUser?.firstMeetingBookedDate !==
          user?.firstMeetingBookedDate
        ) {
          setUser(data.currentUser);
        }
      } catch (ex) {
        console.log('error while fetching user:::', ex);
      }
    };
    userQuery();
  };

  useEffect(mountEffect, [isTrialMeetingBooked]);

  const isPremiumUser =
    user?.isPremium &&
    user?.clientStatus !== ClientStatus.BAD_DEBT &&
    user?.clientStatus !== ClientStatus.CANCELED;
  const isTrial =
    user?.studentTrial?.trialStartedAt && !user?.studentTrial?.trialCompletedAt
      ? true
      : false;

  return (
    <Card className={classes.root}>
      <Typography variant="h4" className="heading">
        {currentStepObject.heading}
      </Typography>

      <Typography variant="body1" className="description">
        {getStepContent(activeStep, isKick_Off_Meeting_Schedule).body}
      </Typography>

      {activeStep === 4 && (
        <>
          <Box>
            {!!advisorsWithKickOffCall?.length &&
              advisorsWithKickOffCall[0]?.startTime &&
              advisorsWithKickOffCall[0]?.endTime && (
                <>
                  {moment
                    .unix(advisorsWithKickOffCall[0]?.startTime)
                    .isSameOrBefore(moment()) &&
                    moment
                      .unix(advisorsWithKickOffCall[0]?.endTime)
                      .isAfter(moment()) && (
                      <Box className={classes.joinMeeting}>
                        <a
                          href={advisorsWithKickOffCall[0]?.zoomLink || ''}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            color="secondary"
                            variant="contained"
                            disabled={!isPremiumUser && !isTrial}
                            className={
                              !isPremiumUser && !isTrial ? 'iconDisabled' : ''
                            }
                          >
                            {VIDEO_IC()}
                            Join Video Call
                          </Button>
                        </a>
                      </Box>
                    )}
                  {moment
                    .unix(advisorsWithKickOffCall[0]?.startTime)
                    .isAfter(moment()) && (
                    <Box className={classes.joinMeeting}>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={isCopied}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            advisorsWithKickOffCall[0]?.zoomLink
                              ? advisorsWithKickOffCall[0]?.zoomLink
                              : ''
                          );
                          setIsCopied(true);
                          setTimeout(() => {
                            setIsCopied(false);
                          }, 5000);
                        }}
                        className={isCopied ? classes.linkFormCopied : ''}
                      >
                        {isCopied ? (
                          <>
                            <img src={LINK_ICON} alt="copied" /> Link Copied
                          </>
                        ) : (
                          <>
                            <img src={LINK_COPIED_ICON} alt="copied" />
                            Copy Meeting Link
                          </>
                        )}
                      </Button>
                    </Box>
                  )}
                </>
              )}
          </Box>

          <Box height="1px" bgcolor="#d4d4d4" mb={3.25}></Box>

          {!!allStudentMentors?.length &&
            sortAdvisorsWithLevel(allStudentMentors)?.map(
              (item: any, index) => {
                return (
                  <>
                    <GetStartAdvisorCard
                      key={`start-card-${index}`}
                      advisorName={`${item?.mentor?.firstName || '--'} ${
                        item?.mentor?.lastName || '--'
                      }`}

                      advisorCollege={
                        item?.mentor?.Theme === 'graduation'
                          ? `${item?.mentor?.graduateSchool?.name || '--'}`
                          : `${item?.mentor?.currentCollege?.name || '--'}`
                      }
                      collegeYear={`${item?.mentor?.intakeYear || ''}`}
                      advisorImage={`${item?.mentor?.pictureUrl || ''}`}
                      collegeLogo={
                        item?.mentor?.Theme === 'graduation'
                        ? 
                        `${
                        item?.mentor?.graduateSchool?.logo ||
                        'https://global-uploads.webflow.com/5e157547d6f791d34ea4e2bf/6087f2b060c7a92408bac811_logo.svg'
                       }`
                       : `${
                        item?.mentor?.currentCollege?.logo ||
                        'https://global-uploads.webflow.com/5e157547d6f791d34ea4e2bf/6087f2b060c7a92408bac811_logo.svg'
                       }`

                      }
                      collegeColor={
                        item?.mentor?.Theme === 'graduation'
                        ? 
                        item?.mentor?.graduateSchool?.styleConfig?.bgDark ||
                        item?.mentor?.graduateSchool?.styleConfig?.bgLight ||
                        'blue'
                        :
                        item?.mentor?.currentCollege?.styleConfig?.bgDark ||
                        item?.mentor?.currentCollege?.styleConfig?.bgLight ||
                        'blue'
                      }
                      
                      disableSheduleBtn={!!advisorsWithKickOffCall.length}
                      matchReason={item?.matchReason || ''}
                      advisorId={item?.mentor?.id || null}
                      mentorUuid={item?.mentor?.uuid || null}
                      advisorLevel={
                        item?.isExtraMatch
                          ? item?.associateType
                            ? associationTypeOptions.find(
                                (option) => option.value === item.associateType
                              )?.label || ''
                            : ''
                          : item?.mentor?.advisorLevel
                      }
                      bio={item?.mentor.bio}
                    />
                  </>
                );
              }
            )}
        </>
      )}

      {activeStep === 5 && (
        <Box>
          <Button color="primary" variant="contained" size="small">
            <a href={`/schedule`} target="-blank" style={{ color: '#fff' }}>
              Schedule a Meeting
            </a>
          </Button>
          <Button color="primary">
            <a href={`/client-enrollment`} target="-blank">
              View available packages
            </a>
          </Button>
        </Box>
      )}

      {activeStep === 3 && (
        <Box>
          <Box m="36px 0px 43px">
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://airtable.com/shr0MNwjQXayNdnAQ'}
            >
              <Button color="secondary" variant="contained">
                Go to Questionnaire
              </Button>
            </a>
          </Box>

          <Box height="1px" bgcolor="#d4d4d4" mb={3.25} />

          <Typography variant="body1" className={classes.myMeetingHeading}>
            My Meeting
          </Typography>

          <Box mt={3.25}>
            {!!allStudentMentors?.length &&
              sortAdvisorsWithLevel(allStudentMentors)?.map(
                (item: any, index) => {
                  return (
                    <>
                      <GetStartAdvisorCard
                        key={`start-card-${index}`}
                        advisorName={`${item?.mentor?.firstName || '--'} ${
                          item?.mentor?.lastName || '--'
                        }`}

                        advisorCollege={
                          item?.mentor?.Theme === 'graduation'
                            ? `${item?.mentor?.graduateSchool?.name || '--'}`
                            : `${item?.mentor?.currentCollege?.name || '--'}`
                        }
                        collegeYear={`${item?.mentor?.intakeYear || ''}`}
                        advisorImage={`${item?.mentor?.pictureUrl || ''}`}
                        collegeLogo={
                          item?.mentor?.Theme === 'graduation'
                          ? 
                          `${
                          item?.mentor?.graduateSchool?.logo ||
                          'https://global-uploads.webflow.com/5e157547d6f791d34ea4e2bf/6087f2b060c7a92408bac811_logo.svg'
                         }`
                         : `${
                          item?.mentor?.currentCollege?.logo ||
                          'https://global-uploads.webflow.com/5e157547d6f791d34ea4e2bf/6087f2b060c7a92408bac811_logo.svg'
                         }`
  
                        }
                        collegeColor={
                          item?.mentor?.Theme === 'graduation'
                          ? 
                          item?.mentor?.graduateSchool?.styleConfig?.bgDark ||
                          item?.mentor?.graduateSchool?.styleConfig?.bgLight ||
                          'blue'
                          :
                          item?.mentor?.currentCollege?.styleConfig?.bgDark ||
                          item?.mentor?.currentCollege?.styleConfig?.bgLight ||
                          'blue'
                        }
                        disableSheduleBtn={!!advisorsWithKickOffCall.length}
                        matchReason={item?.matchReason || ''}
                        advisorId={item?.mentor?.id || null}
                        mentorUuid={item?.mentor?.uuid || null}
                        advisorLevel={
                          item?.isExtraMatch && !isTrial
                            ? item?.associateType
                              ? associationTypeOptions.find(
                                  (option) =>
                                    option.value === item.associateType
                                )?.label || ''
                              : ''
                            : item?.mentor?.advisorLevel
                        }
                        bio={item?.mentor.bio}
                      />
                    </>
                  );
                }
              )}
          </Box>
          <Typography variant="body1" className={classes.rescheduleText}>
            If you need to reschedule or cancel this meeting, please call{' '}
            <a href="tel:(855) 412-6272">(855) 412-6272</a>. Hours of operation:
            Monday - Friday 8:00am - 5:00pm CT
          </Typography>
        </Box>
      )}

      {activeStep === 2 && (
        <Box>
          {(!isPremiumUser || !advisorsWithKickOffCall.length) && (
            <Box mb={4.875} pt={1.375}>
              {!isMeetingEnded && (
                <Button
                  onClick={() => {
                    user &&
                      allStudentMentors &&
                      (isPremiumUser || isTrial) &&
                      handleCalendarClick(allStudentMentors[0]?.mentor?.id);
                  }}
                  color="secondary"
                  variant="contained"
                  disabled={
                    isTrial
                      ? !isTrial
                      : !isPremiumUser || !!advisorsWithKickOffCall.length
                  }
                >
                  {CALENDAR_IC()}
                  Book Meeting
                </Button>
              )}
            </Box>
          )}

          <Box height="1px" bgcolor="#d4d4d4" mb={4.75}></Box>

          {!!allStudentMentors?.length &&
            sortAdvisorsWithLevel(allStudentMentors)?.map(
              (item: any, index) => {
                return (
                  <>
                    <GetStartAdvisorCard
                      key={`start-card-${index}`}
                      advisorName={`${item?.mentor?.firstName || '--'} ${
                        item?.mentor?.lastName || '--'
                      }`}

                      advisorCollege={
                        item?.mentor?.Theme === 'graduation'
                          ? `${item?.mentor?.graduateSchool?.name || '--'}`
                          : `${item?.mentor?.currentCollege?.name || '--'}`
                      }
                      collegeYear={`${item?.mentor?.intakeYear || ''}`}
                      advisorImage={`${item?.mentor?.pictureUrl || ''}`}
                      collegeLogo={
                        item?.mentor?.Theme === 'graduation'
                        ? 
                        `${
                        item?.mentor?.graduateSchool?.logo ||
                        'https://global-uploads.webflow.com/5e157547d6f791d34ea4e2bf/6087f2b060c7a92408bac811_logo.svg'
                       }`
                       : `${
                        item?.mentor?.currentCollege?.logo ||
                        'https://global-uploads.webflow.com/5e157547d6f791d34ea4e2bf/6087f2b060c7a92408bac811_logo.svg'
                       }`

                      }
                      collegeColor={
                        item?.mentor?.Theme === 'graduation'
                        ? 
                        item?.mentor?.graduateSchool?.styleConfig?.bgDark ||
                        item?.mentor?.graduateSchool?.styleConfig?.bgLight ||
                        'blue'
                        :
                        item?.mentor?.currentCollege?.styleConfig?.bgDark ||
                        item?.mentor?.currentCollege?.styleConfig?.bgLight ||
                        'blue'
                      }
                      disableSheduleBtn={!!advisorsWithKickOffCall.length}
                      matchReason={item?.matchReason || ''}
                      advisorId={item?.mentor?.id || null}
                      mentorUuid={item?.mentor?.uuid || null}
                      advisorLevel={
                        item?.isExtraMatch && !isTrial
                          ? item?.associateType
                            ? associationTypeOptions.find(
                                (option) => option.value === item.associateType
                              )?.label || ''
                            : ''
                          : item?.mentor?.advisorLevel
                      }
                      bio={item?.mentor.bio}
                    />
                  </>
                );
              }
            )}
        </Box>
      )}
    </Card>
  );
};
export default withRouter(MeetingForTrial);
