import gql from 'graphql-tag';
export const USER_WITH_INVITE_TOKEN = gql`
  query userWithInviteTokenQuery($token: String!, $lead: Boolean) {
    userWithInviteToken(input: { token: $token, lead: $lead }) {
      firstName
      lastName
      email
    }
  }
`;

export const UPDATE_PROFILE_PICTURE_MUTATION = gql`
  mutation UpdateProfilePicture(
    $file: Upload!
    $UserId: Int!
    $isPublic: Boolean
    $directoryName: String
  ) {
    uploadProfilePicture(
      input: {
        file: $file
        UserId: $UserId
        isPublic: $isPublic
        directoryName: $directoryName
      }
    ) {
      source
      success
    }
  }
`;
