import { FC } from 'react';
import { Box, Typography, Avatar } from '@material-ui/core';

import { useStyles } from './styles';

interface AdvisorProps {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  pictureUrl: string | null | undefined;
  college: string | null | undefined;
}

const Advisors: FC<AdvisorProps> = (props) => {
  const classes = useStyles();
  const { firstName, lastName, pictureUrl, college } = props;
  return (
    <Box display="flex" alignItems="center">
      <Avatar src={`${pictureUrl}`} className={classes.avatarAdvisor} />
      <Box>
        <Typography variant="h5">{`${firstName} ${lastName}`}</Typography>
        <Typography variant="body1" className={classes.videoChatTypography}>
          {college}
        </Typography>
      </Box>
    </Box>
  );
};
export default Advisors;
