// imports
import clsx from 'clsx';
import { Field } from 'formik';
import { FormEvent, Fragment } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
// components
import { TextField } from '../../../components/fields';
// styles
import { useStyles } from '../styles';

const ParentInformation = ({
  setFieldValue,
  parentorStudent,
  fromMeetingForm,
}: {
  setFieldValue: Function;
  parentorStudent: string;
  fromMeetingForm?: boolean;
}) => {
  const classes = useStyles();
  const location = useLocation();
  const isMeetingSetPage = location.pathname.includes('schedule');

  return (
    <Fragment>
      {!fromMeetingForm && parentorStudent === 'Student' && (
        <Box className={classes.sectionHeading} paddingBottom={5}>
          <span className="heading-number">2.</span>Parent Information
        </Box>
      )}

      <Grid className={classes.fieldContainer}>
        <Field
          variant="filled"
          name="parentFirstName"
          component={TextField}
          label="First Name"
          placeholder="First Name"
          onChange={(e: FormEvent<HTMLInputElement>) => {
            const {
              currentTarget: { value },
            } = e;

            setFieldValue('parentFirstName', value);
          }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          className={clsx(classes.textfield, classes.textfieldMobile)}
        />

        <Field
          variant="filled"
          name="parentLastName"
          component={TextField}
          label="Last Name"
          placeholder="Last Name"
          onChange={(e: FormEvent<HTMLInputElement>) => {
            const {
              currentTarget: { value },
            } = e;

            setFieldValue('parentLastName', value);
          }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          className={classes.textfield}
        />
      </Grid>

      <Field
        variant="filled"
        name="parentEmail"
        component={TextField}
        label={isMeetingSetPage ? 'Email Address' : "Parent's Email"}
        placeholder="Parent's Email"
        onChange={(e: FormEvent<HTMLInputElement>) => {
          const {
            currentTarget: { value },
          } = e;

          setFieldValue('parentEmail', value);
        }}
        InputLabelProps={{ shrink: true }}
        fullWidth
        className={classes.textfield}
      />

      <Field
        name="parentPhone"
        placeholder="Parent’s Phone Number"
        validate={(value: any | string) => {
          if (value && !isValidPhoneNumber(value)) {
            return 'Invalid Phone Number';
          }
        }}
      >
        {({
          field,
          form: { errors, handleBlur, touched, setFieldTouched, ...rest },
          ...otherProps
        }: any) => {
          return (
            <Box className="phoneInputMasterResolve">
              <Box
                className={`${classes.phoneInputNew} ${
                  touched.parentPhone && errors.parentPhone ? 'error' : ''
                }`}
                textAlign="left"
              >
                <label>
                  {isMeetingSetPage
                    ? 'Parent Phone Number'
                    : 'Parent’s Phone Number'}
                </label>
                <PhoneInput
                  placeholder={
                    isMeetingSetPage
                      ? 'Parent Phone Number'
                      : 'Parent’s Phone Number'
                  }
                  value={field.value}
                  defaultCountry="US"
                  {...rest}
                  {...otherProps}
                  onBlur={() => {
                    setFieldTouched(field.name, true);
                  }}
                  onChange={(value) => {
                    setFieldValue(field.name, value);
                  }}
                  error={touched.parentPhone && errors.parentPhone}
                  name="parentPhone"
                />

                {touched.parentPhone && errors.parentPhone && (
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                    {errors.parentPhone}
                  </p>
                )}
              </Box>
            </Box>
          );
        }}
      </Field>
    </Fragment>
  );
};
export default ParentInformation;
