import { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Mutation, Query } from 'react-apollo';
import { Formik, Form, Field, FieldProps } from 'formik';
import {
  Button,
  RadioGroup,
  Box,
  CircularProgress,
  FormHelperText,
  Divider,
  Grid,
  Typography,
  Card,
  InputBase,
  ThemeProvider,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import { useStyles } from './Styles';
import Colors from './../../common/colors';
import ParentInformation from './ParentInformation';
import StudentInformation from './StudentInformation';
import { SignupSchema } from './lib/validationSchemas';
import { cjConfig, handleMutationError } from './../../common/utility';
import {
  CREATE_LEAD_ACCOUNTS_MUTATION,
  GET_LEADS_USERS,
  GET_PARTNERS_FROM_LEAD_API_QUERY,
  VALIDATE_SALE_AUTH_KEY_QUERY,
} from './gql/index';
import { ErrorFocus, FinancialAidOptionButton, RadioButtonOption } from './lib';
import { GetPartnersFromLeadApiQuery } from './gql/__generated__/GetPartnersFromLeadApiQuery';
import {
  CreateLeadAccountMutation,
  CreateLeadAccountMutationVariables,
} from './gql/__generated__/CreateLeadAccountMutation';

import { sha256 } from 'js-sha256';
import PartnerInformation from './PartnerInformation';
import client from './../../apollo';
import { QgetLeadsUsers } from './gql/__generated__/QgetLeadsUsers';

import Logo_Lead_Img from './../../img/logo-lead-form.svg';
import CustomAlert from './../../components/NewCustomAlert';

import { customTheme } from '../MaterializeCss';

const UserForm = ({
  parentorStudent,
  partnerKey,
  setLoginView,
  showBackButton,
  setCreatedLeadId,
  setLeadViewData,
  setPasswordSetView,
  title,
  setParentOrStudent,
  isLoading,
  setIsLoading,
  initialValues,
  setInitialValues,
}: {
  title?: string;
  partnerKey?: string;
  setLoginView: Function;
  showBackButton?: Function;
  setCreatedLeadId: Function;
  setLeadViewData: Function;
  toggleAlert?: Function;
  setPasswordSetView: Function;
  setParentOrStudent: Function;
  parentorStudent: 'Parent' | 'Student';
  setIsLoading: Function;
  isLoading: boolean;
  initialValues: any;
  setInitialValues: Function;
}): JSX.Element => {
  const classes = useStyles();
  const [yearValue, setYearValue] = useState(initialValues.highSchoolYear);
  let salesRegitration = false;
  if (window.location.pathname.includes('sales')) {
    salesRegitration = true;
  }

  const [authRequired, setAuthRequired] = useState(salesRegitration);
  const [variant, setVariant] = useState('');
  const [message, setMessage] = useState('');
  const [existing, setExisting] = useState(false);
  const [leadData, setLeadData] = useState<any>(null);

  const toggleAlert = (variant?: string, message?: string) => {
    setVariant(variant || '');
    setMessage(message || '');
  };
  useEffect(() => {
    if (variant && message) {
      setTimeout(() => {
        setVariant('');
        setMessage('');
      }, 5000);
    }
  }, [variant, message]);

  const [resetValues, setResetValues] = useState(false);
  const [keyValue, setKeyValue] = useState('');
  const validateAuthKey = async () => {
    try {
      const result = await client.query({
        query: VALIDATE_SALE_AUTH_KEY_QUERY,
        variables: { authKey: keyValue },
      });
      const {
        validateSalesAuthKey: { validKey },
      } = result.data;
      return validKey;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  interface RefObject {
    scrollIntoView: () => void;
  }

  const alertRef = useRef<RefObject>(null);

  const scrollToAlert = () => {
    if (alertRef.current) {
      alertRef?.current?.scrollIntoView();
    }
  };

  return (
    <Card ref={alertRef} className={classes.salesLeadForm}>
      <Box textAlign="center" className={classes.leadFormHeader}>
        <img src={Logo_Lead_Img} alt="logo" />
        <Typography variant="h2">Direct Lead Form</Typography>
      </Box>

      <Box>
        <CustomAlert
          variant={variant}
          message={message}
          toggleAlert={toggleAlert}
          fullWidth={true}
        />
      </Box>

      {authRequired ? (
        <Grid>
          <Box textAlign="center">
            <Box>
              <Typography variant="caption">Password</Typography>

              <InputBase
                id="auth-key"
                type="text"
                onChange={(e) => setKeyValue(e.target.value)}
                autoFocus
              />
            </Box>

            <ThemeProvider theme={customTheme}>
              <Box mt={3}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={async () =>
                    setAuthRequired(!(await validateAuthKey()))
                  }
                >
                  Authenticate
                </Button>
              </Box>
            </ThemeProvider>
          </Box>
        </Grid>
      ) : (
        <Mutation<CreateLeadAccountMutation, CreateLeadAccountMutationVariables>
          mutation={CREATE_LEAD_ACCOUNTS_MUTATION}
        >
          {(createLeadAccountMutation: Function) => {
            return (
              <Formik
                initialValues={{ ...initialValues }}
                validationSchema={SignupSchema({
                  parentorStudent,
                  isSalesRegistration: true,
                })}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  setIsLoading(true);
                  const params = new URLSearchParams(window.location.search);
                  const _goback = params.get('_goback');
                  if (
                    _goback &&
                    (_goback.includes('opportunity-detail') ||
                      _goback.includes('extracurricular-opportunities'))
                  ) {
                  }
                  try {
                    setInitialValues({ ...values });
                    const variables: any = {
                      ...values,
                      userRole: parentorStudent,
                      salesRegitration,
                      optInSMS: values.optInSMS,
                    };
                    let _params = window.localStorage.user_analytics_details;
                    if (_params) {
                      _params = JSON.parse(_params);
                      variables.userAnalyticParams = {
                        ..._params,
                        first_submission_url:
                          _params._fsu ||
                          `${window.location.origin}${_params._goback}` ||
                          `${window.location.origin}${window.location.pathname}`,
                      };
                    }
                    const result = await createLeadAccountMutation({
                      variables,
                    });

                    if (window.location.host === 'app.collegeadvisor.com') {
                      console.log('Triggering CUSTOM_EVENT_1...');
                      setTimeout(() => {
                        if (window.snaptr) {
                          console.log(
                            '%c Triggering snaptr init...',
                            'color: Green'
                          );
                          console.log(
                            'REACT_APP_SNAPCHAT_PIXEL_API',
                            process.env.REACT_APP_SNAPCHAT_PIXEL_API_KEY
                          );
                          window.snaptr(
                            'init',
                            process.env.REACT_APP_SNAPCHAT_PIXEL_API_KEY,
                            {
                              user_phone_number:
                                values.studentPhone || values.parentPhone,
                              user_email: values.studentEmail,
                            }
                          );
                          window.snaptr('track', 'CUSTOM_EVENT_1');
                          window.snaptr('track', 'SIGN_UP');
                        }
                        if (window.fbq) {
                          window.fbq('trackCustom', 'HighQualityLead');
                          window.fbq('trackCustom', 'Lead');
                        }
                        console.log('window.gtag::::', window.gtag);
                        if (window.gtag) {
                          console.log(
                            'triggering event_conversion for gtag...'
                          );
                          window.gtag('event', 'conversion', {
                            send_to: 'AW-724553338/gw_OCKjXwv0BEPqcv9kC',
                          });
                        }
                        if (window.ttq) {
                          console.log(
                            '%c Triggering ttq indentify...',
                            'color: Green'
                          );
                          window.ttq.identify({
                            external_id:
                              result?.data?.createLeadAccount?.LeadId,
                            sha256_email: sha256(values.studentEmail),
                            sha256_phone_number: sha256(
                              values.studentPhone || values.parentPhone
                            ),
                          });
                          window.ttq
                            .instance('C2CJLF7MU8Q03RAIPRC0')
                            .track('SubmitForm');
                          console.log('triggering tik tok event...');
                          window.ttq
                            .instance('C1LLB3HT0U322RQQ6J10')
                            .track('SubmitForm');
                        }
                      }, 0);
                    } else {
                      console.log(
                        'Non production env. Not triggering custom events...'
                      );
                    }
                    const {
                      data: {
                        createLeadAccount: {
                          status,
                          redirectToLogin,
                          redirectToPassword,
                          LeadId,
                          primaryLead,
                          magicLink,
                          studentName,
                          parentName,
                          gradYear,
                        },
                      },
                    } = result;

                    if(!status) {
                      toggleAlert(
                        'danger',
                        'Student Email and Parent Email can not have same values.'
                      );
                      scrollToAlert();
                      setIsLoading(false);
                      setSubmitting(false);
                    } else {
                      setLeadData({
                        magicLink,
                        studentName,
                        parentName,
                        gradYear,
                        primaryLead,
                      });
  
                      if (redirectToLogin && !salesRegitration) {
                        setLoginView(true);
                        showBackButton && showBackButton(true);
                      }
                      if (redirectToPassword && !salesRegitration) {
                        setLeadViewData(values);
                        setCreatedLeadId(LeadId);
                        setPasswordSetView(true);
                        showBackButton && showBackButton(true);
                      }
  
                      if (LeadId && primaryLead) {
                        //toggleAlert('success', 'Student and Parent is Created.');
                      }
                      if (LeadId && !primaryLead) {
                        toggleAlert(
                          'danger',
                          'Lead exists. Contact Your Manager for Assistance.'
                        );
                        scrollToAlert();
                        setIsLoading(false);
                        setSubmitting(false);
                      } else {
                        scrollToAlert();
                        resetForm();
                        setResetValues(true);
                        setYearValue('');
                        setIsLoading(false);
                        setSubmitting(false);
                      }
                    }
                  } catch (error: any) {
                    setSubmitting(false);
                    setIsLoading(false);
                    handleMutationError(error, (errorMessage) => {
                      scrollToAlert();
                      toggleAlert('danger', errorMessage.message);
                    });
                  }
                }}
              >
                {({
                  values,
                  isSubmitting,
                  setFieldValue,
                  errors,
                  touched,
                  submitCount,
                  status,
                  isValid,
                }) => {
                  return (
                    <Form>
                      <>
                        <Query<GetPartnersFromLeadApiQuery>
                          query={GET_PARTNERS_FROM_LEAD_API_QUERY}
                          fetchPolicy="cache-and-network"
                        >
                          {({ data, loading }) => {
                            if (!data || !data.getPartnersFromLeadApi)
                              return null;

                            const {
                              getPartnersFromLeadApi: { partners },
                            } = data;
                            if (loading) return null;
                            if (!partners || !partners.length) return null;
                            if (partners)
                              return (
                                <>
                                  <Query<QgetLeadsUsers>
                                    query={GET_LEADS_USERS}
                                    fetchPolicy="cache-and-network"
                                  >
                                    {({ data, loading }) => {
                                      if (loading) return null;
                                      if (
                                        !data?.getLeadsUsersQuery ||
                                        !data?.getLeadsUsersQuery.length
                                      )
                                        return null;
                                      return (
                                        <PartnerInformation
                                          resetForm={resetValues}
                                          setResetValues={setResetValues}
                                          users={data?.getLeadsUsersQuery}
                                          partners={partners}
                                          setFieldValue={setFieldValue}
                                          touched={touched}
                                          errors={errors}
                                          leadData={leadData}
                                        />
                                      );
                                    }}
                                  </Query>
                                </>
                              );
                          }}
                        </Query>
                      </>

                      <Grid>
                        <Box
                          display="flex"
                          alignItems="center"
                          className={classes.callerRadioGroup}
                        >
                          <Typography variant="h5">Caller is a</Typography>

                          <RadioGroup
                            name="studentOrParent1"
                            value={parentorStudent}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              marginRight={3.2}
                            >
                              <RadioButtonOption
                                role="Student"
                                label="Student"
                                className={classes.radioButton}
                                setParentOrStudent={setParentOrStudent}
                              />
                              <RadioButtonOption
                                role="Parent"
                                label="Parent"
                                className={classes.radioButton}
                                setParentOrStudent={setParentOrStudent}
                              />
                            </Box>
                          </RadioGroup>
                        </Box>

                        {parentorStudent === 'Student' && (
                          <>
                            <Grid className={classes.callerRadioForm}>
                              <Typography variant="h5">
                                Student Information
                              </Typography>
                              <StudentInformation
                                setFieldValue={setFieldValue}
                                setYearValue={setYearValue}
                                parentorStudent={parentorStudent}
                                yearValue={yearValue}
                                errors={errors}
                                touched={touched}
                                setExisting={setExisting}
                                leadData={leadData}
                              />

                              <Divider className={classes.dividerSectionForm} />

                              <Typography
                                variant="h5"
                                className={classes.associatedParentInformation}
                              >
                                Parent Information
                              </Typography>

                              <ParentInformation
                                setFieldValue={setFieldValue}
                                parentorStudent={parentorStudent}
                                existing={existing}
                                leadData={leadData}
                              />
                            </Grid>
                          </>
                        )}

                        {parentorStudent === 'Parent' && (
                          <>
                            <Grid className={classes.callerRadioForm}>
                              <Typography variant="h5">
                                Parent Information
                              </Typography>

                              <ParentInformation
                                setFieldValue={setFieldValue}
                                parentorStudent={parentorStudent}
                                existing={existing}
                                leadData={leadData}
                              />

                              <Divider className={classes.dividerSectionForm} />

                              <Typography variant="h5">
                                Student Information
                              </Typography>

                              <StudentInformation
                                setFieldValue={setFieldValue}
                                setYearValue={setYearValue}
                                parentorStudent={parentorStudent}
                                yearValue={yearValue}
                                errors={errors}
                                touched={touched}
                                setExisting={setExisting}
                                leadData={leadData}
                              />
                            </Grid>
                          </>
                        )}

                        <Divider
                          className={clsx(
                            classes.dividerSectionForm,
                            classes.dividerParentInformation
                          )}
                        />

                        <Grid
                          className={clsx(
                            classes.callerRadioForm,
                            classes.financialAid
                          )}
                        >
                          <Typography variant="h5">
                            To what extent will you require financial aid to
                            attend college?
                            <Box
                              component="span"
                              color={
                                errors.aidOption ? Colors.RED_ONE : 'inherit'
                              }
                              paddingLeft={0.5}
                            >
                              *
                            </Box>
                          </Typography>

                          <Field name="aidOption">
                            {({ field }: any) => (
                              <Box position="relative">
                                {errors.aidOption && (
                                  <FormHelperText error>
                                    Required
                                  </FormHelperText>
                                )}
                                <RadioGroup
                                  value={field.value}
                                  name="aidOption"
                                  className={classes.aidOptionsLeadForm}
                                  onChange={(event) => {
                                    setFieldValue(
                                      'aidOption',
                                      parseInt(event.target.value)
                                    );
                                  }}
                                >
                                  <>
                                    <FinancialAidOptionButton
                                      label="Definitely need financial aid"
                                      value={5}
                                    />
                                    <FinancialAidOptionButton
                                      label="Moderately need financial aid"
                                      value={4}
                                    />
                                    <FinancialAidOptionButton
                                      label="Don’t really need financial aid, but will apply"
                                      value={2}
                                    />
                                    <FinancialAidOptionButton
                                      label="Don’t need financial aid and won’t apply"
                                      value={1}
                                    />
                                    <FinancialAidOptionButton
                                      label="Not Sure"
                                      value={3}
                                    />
                                  </>
                                </RadioGroup>
                              </Box>
                            )}
                          </Field>

                          <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            onClick={() => {
                              if (
                                window.location.host ===
                                'app.collegeadvisor.com'
                              ) {
                                if (window.ttq) {
                                  console.log('triggering ttq clickForm...');
                                  window.ttq
                                    .instance('C2CJLF7MU8Q03RAIPRC0')
                                    .track('ClickForm');
                                }
                              }
                            }}
                            id="reg-continue"
                            disabled={isLoading}
                          >
                            Submit
                            {isLoading && (
                              <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                              />
                            )}
                          </Button>
                        </Grid>
                      </Grid>

                      <ErrorFocus errors={errors} />
                    </Form>
                  );
                }}
              </Formik>
            );
          }}
        </Mutation>
      )}
    </Card>
  );
};

export default UserForm;
