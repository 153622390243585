import React from 'react';

import { Box, ClickAwayListener, Hidden, IconButton } from '@material-ui/core';

import ApplicationsTooltipContent from './ApplicationsTooltip';

import InfoIcon from '../img/rating-info-icon.svg';
import { BootstrapTooltip } from './BootstrapTooltip';

export default function ApplicationsTooltip() {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Hidden only={['xs', 'sm']}>
        <BootstrapTooltip
          placement="top"
          title={<ApplicationsTooltipContent />}
          customWidth={'355px'}
        >
          <Box ml="12px">
            <IconButton className="tooltipHoverButton">
              <img src={InfoIcon} alt="info" />
            </IconButton>
          </Box>
        </BootstrapTooltip>
      </Hidden>

      <Hidden only={['md', 'lg', 'xl']}>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <BootstrapTooltip
            placement="top"
            PopperProps={{
              disablePortal: true,
            }}
            open={open}
            customWidth={'355px'}
            onClose={handleTooltipClose}
            title={<ApplicationsTooltipContent />}
          >
            <Box ml="12px">
              <IconButton
                className="tooltipHoverButton"
                onClick={handleTooltipOpen}
              >
                <img src={InfoIcon} alt="info" />
              </IconButton>
            </Box>
          </BootstrapTooltip>
        </ClickAwayListener>
      </Hidden>
    </>
  );
}
