import { FormEvent } from 'react';
import clsx from 'clsx';

import { Field } from 'formik';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import { Box, Grid } from '@material-ui/core';

import { TextField } from '../../components/fields';

import { useStyles } from './styles';

const MBAStudentInformation = ({
  setFieldValue,
  parentorStudent,
  yearValue,
  setYearValue,
  errors,
  touched,
}: {
  setFieldValue: Function;
  setYearValue: Function;
  parentorStudent: string;
  yearValue: string;
  errors: any;
  touched: any;
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.fieldContainer}>
        <Field
          variant="filled"
          name="studentFirstName"
          component={TextField}
          label="First Name"
          placeholder="First Name"
          onChange={(e: FormEvent<HTMLInputElement>) => {
            const {
              currentTarget: { value },
            } = e;
            setFieldValue('studentFirstName', value);
          }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          className={clsx(classes.textfield, classes.textfieldMobile)}
        />

        <Field
          variant="filled"
          name="studentLastName"
          component={TextField}
          label="Last Name"
          placeholder="Last Name"
          onChange={(e: FormEvent<HTMLInputElement>) => {
            const {
              currentTarget: { value },
            } = e;

            setFieldValue('studentLastName', value);
          }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          className={classes.textfield}
        />
      </Grid>

      <Field
        variant="filled"
        name="studentEmail"
        component={TextField}
        label={"Student's Email"}
        placeholder={"Student's Email"}
        onChange={(e: FormEvent<HTMLInputElement>) => {
          const {
            currentTarget: { value },
          } = e;
          setFieldValue('studentEmail', value);
        }}
        InputLabelProps={{ shrink: true }}
        fullWidth
        className={classes.textfield}
      />
      <Field
        name="studentPhone"
        placeholder="Student's Phone Number"
        validate={(value: any | string) => {
          if (value && !isValidPhoneNumber(value)) {
            return 'Invalid Phone Number';
          }
        }}
      >
        {({
          field,
          form: { errors, handleBlur, ...rest },
          ...otherProps
        }: any) => {
          return (
            <Box className="phoneInputMasterResolve">
              <Box
                className={`${classes.phoneInputNew} ${
                  errors.studentPhone ? 'error' : ''
                }`}
                textAlign="left"
              >
                <label htmlFor="phoneNumber">{"Student's Phone Number"}</label>
                <PhoneInput
                  name="studentPhone"
                  placeholder={"Student's Phone Number"}
                  value={field.value}
                  defaultCountry="US"
                  {...rest}
                  {...otherProps}
                  onBlur={handleBlur}
                  onChange={(value) => {
                    setFieldValue(field.name, value);
                  }}
                  error={errors.studentPhone}
                />

                {touched.studentPhone && errors.studentPhone && (
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                    {errors.studentPhone}
                  </p>
                )}
              </Box>
            </Box>
          );
        }}
      </Field>

      <Grid container className={classes.leadRegisterGraduation} spacing={1}>
        <Grid item xs={9}>
          <Field
            variant="filled"
            name="undergraduateCollege"
            component={TextField}
            label={'Undergraduate College'}
            placeholder={'Undergraduate College'}
            onChange={(e: FormEvent<HTMLInputElement>) => {
              const {
                currentTarget: { value },
              } = e;
              setFieldValue('undergraduateCollege', value);
            }}
            InputLabelProps={{ shrink: true }}
            fullWidth
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            variant="filled"
            name="undergraduateGradYear"
            component={TextField}
            label={'Grad Year'}
            placeholder={'YYYY'}
            onChange={(e: FormEvent<HTMLInputElement>) => {
              const {
                currentTarget: { value },
              } = e;
              setFieldValue('undergraduateGradYear', value);
            }}
            InputLabelProps={{ shrink: true }}
            fullWidth
            className={classes.textfield}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MBAStudentInformation;
