import { FormEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Field } from 'formik';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import PhoneInput from 'react-phone-number-input';

import { useStyles } from './Styles';
import { TextField } from './../../components/fields';

import AsyncSelect from 'react-select/async';

import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { GET_ALL_USERS } from './gql';
import client from '../../apollo';
import { IOptionType } from '../../common/interfaces';
import { getAllUsersQuery_listAllUsers } from './gql/__generated__/getAllUsersQuery';

const ParentInformation = ({
  setFieldValue,
  parentorStudent,
  existing,
  leadData,
}: {
  setFieldValue: Function;
  parentorStudent: string;
  existing: boolean;
  leadData: any;
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const [selectedValue, setSelectedValue] = useState<any>('');

  const selectStyles: any = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
        width: '320px !important',
        boxSizing: 'border-box',
        paddingTop: '30px',
      },
    }),
  };

  const loadOptions: any = (inputValue: string) => {
    return new Promise((resolve) => {
      // API call
      client
        .query({
          query: GET_ALL_USERS,
          variables: {
            searchQuery: inputValue,
            roles: ['Parent'],
            isDirectLeadForm: true,
          },
        })
        .then((res: any) => {
          if (res.data && res.data.listAllUsers?.length > 0) {
            const options: IOptionType[] = res.data.listAllUsers.map(
              (item: getAllUsersQuery_listAllUsers) => ({
                value: item.id,
                label: `${item.firstName || ''} ${item.lastName || ''}\n(${
                  item.email
                })`,
                children:
                  item.childrens?.map(
                    (child: any) =>
                      `${child?.firstName || ''} ${child?.lastName || ''}(${
                        child?.email
                      })`
                  ) || [],
                firstName: item.firstName || '',
                lastName: item.lastName || '',
                email: item.email || '',
                phoneNumber: item.phoneNumber || '',
              })
            );
            resolve(options);
          } else {
            resolve([]);
          }
        });
    });
  };
  const handleInputChange = (newValue: string) => newValue;

  useEffect(() => {
    setSelectedValue('');
    setFieldValue('parentFirstName', '');
    setFieldValue('parentLastName', '');
    setFieldValue('parentEmail', '');
    setFieldValue('parentPhone', '');
  }, [leadData, existing]);

  return (
    <>
      {existing ? (
        <Grid className={classes.parentInformationSection}>
          <Typography variant="body2">Associated Parent</Typography>

          <AsyncSelect
            cacheOptions
            defaultOptions
            isClearable={true}
            styles={selectStyles}
            loadOptions={loadOptions}
            classNamePrefix="react-select"
            className="react-select-container"
            onInputChange={handleInputChange}
            placeholder="Search parent by name or email"
            onChange={(value) => {
              if (!value) {
                setSelectedValue('');

                setFieldValue('parentFirstName', '');
                setFieldValue('parentLastName', '');
                setFieldValue('parentEmail', '');
                setFieldValue('parentPhone', '');
              } else {
                setSelectedValue(value);
                setFieldValue('parentFirstName', value?.firstName);
                setFieldValue('parentLastName', value?.lastName);
                setFieldValue('parentEmail', value?.email);
                setFieldValue('parentPhone', value?.phoneMumber);
              }
            }}
          />

          {selectedValue?.children?.length > 0 && (
            <Typography variant="body1" className="associatedParentInfoText">
              Other students: {selectedValue?.children?.join(',')}
            </Typography>
          )}
        </Grid>
      ) : (
        <Grid container className={classes.studentParentForm} spacing={3}>
          <Grid item md={6} sm={12} xs={12}>
            <Typography variant="caption">First Name</Typography>
            <Field
              variant="filled"
              name="parentFirstName"
              component={TextField}
              label="First Name"
              placeholder="First Name"
              onChange={(e: FormEvent<HTMLInputElement>) => {
                const {
                  currentTarget: { value },
                } = e;

                setFieldValue('parentFirstName', value);
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              className={clsx(classes.textfield, classes.textfieldMobile)}
            />
          </Grid>

          <Grid item md={6} sm={12} xs={12}>
            <Typography variant="caption">Last Name</Typography>
            <Field
              variant="filled"
              name="parentLastName"
              component={TextField}
              label="Last Name"
              placeholder="Last Name"
              onChange={(e: FormEvent<HTMLInputElement>) => {
                const {
                  currentTarget: { value },
                } = e;

                setFieldValue('parentLastName', value);
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              className={classes.textfield}
            />
          </Grid>

          <Grid item md={6} sm={12} xs={12}>
            <Typography variant="caption">Parent's Email</Typography>
            <Field
              variant="filled"
              name="parentEmail"
              component={TextField}
              label="Parent's Email"
              placeholder="Parent's Email"
              onChange={(e: FormEvent<HTMLInputElement>) => {
                const {
                  currentTarget: { value },
                } = e;

                setFieldValue('parentEmail', value);
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              className={classes.textfield}
            />
          </Grid>

          <Grid item md={6} sm={12} xs={12}>
            <Typography variant="caption">Parent’s Phone Number</Typography>
            <Field
              name="parentPhone"
              placeholder="Parent’s Phone Number"
            >
              {({
                field,
                form: { errors, handleBlur, touched, setFieldTouched, ...rest },
                ...otherProps
              }: any) => {
                return (
                  <Box className="phoneInputMasterResolve">
                    <Box
                      className={`${classes.phoneInputNew} ${
                        touched.parentPhone && errors.parentPhone ? 'error' : ''
                      }`}
                      textAlign="left"
                    >
                      <PhoneInput
                        placeholder="Parent’s Phone Number"
                        value={field.value}
                        defaultCountry="US"
                        {...rest}
                        {...otherProps}
                        onBlur={() => {
                          setFieldTouched(field.name, true);
                        }}
                        onChange={(value) => {
                          setFieldValue(field.name, value);
                        }}
                        error={touched.parentPhone && errors.parentPhone}
                      />

                      {errors.parentPhone && (
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                          {errors.parentPhone}
                        </p>
                      )}
                    </Box>
                  </Box>
                );
              }}
            </Field>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ParentInformation;
