import { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  Card,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';

import fonts from '../../common/fonts';

import DIALOG_CLOSE_ICON from '../../img/dialog-close-icon.svg';
import { useStylesMeeting } from './scheduleMeetingtyle';
import Loader from '../../components/Loader';
import { AppContext } from '../../contexts';
import { IS_USER_LEAD_EXISTS } from './gql';
import client from '../../apollo';
import { CALENDLY_MEETING_URL_FOR_STUDENT } from '../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    demoDialog: {
      maxWidth: 950,
      margin: 0,
      position: 'absolute',
      width: '100%',
      top: '50%',
      left: '50%',
      padding: '47px 0px 20px',
      background: '#fff',
      border: '1px solid #d4d4d4',
      borderRadius: 20,
      boxShadow: '0px 4px 14px rgb(68 68 68 / 14%)',
      transform: 'translate(-50%, -50%) !important',
      overflowY: 'auto',

      '&:focus, &:visited': {
        boxShadow: 'none',
        outline: 'none',
      },

      [theme.breakpoints.down('md')]: {
        padding: '20px 16px',
        width: '95%',
      },

      '& .dailogHeading': {
        color: '#272929',
        fontSize: 36,
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 700,
        letterSpacing: '-0.022em',
        textAlign: 'center',
        lineHeight: 'initial',
        textTransform: 'inherit',

        [theme.breakpoints.down('sm')]: {
          fontSize: 28,
          paddingTop: 50,
        },

        [theme.breakpoints.down('xs')]: {
          fontSize: 20,
        },
      },

      '& .closeButton': {
        position: 'absolute',
        top: 15,
        right: 30,
        opacity: 0.4,

        [theme.breakpoints.down('md')]: {
          right: 16,
        },
      },
    },
  })
);

const HomePageCalendlyModel = ({ setShowDemo }: { setShowDemo?: Function }) => {
  const { user } = useContext(AppContext);
  const classes = useStylesMeeting();
  const [loading, setLoading] = useState(true);
  const [caLeadId, setCaLeadId] = useState<number | null>(null);
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    head?.appendChild(script);
  });
  const classesPrev = useStyles();
  const [openDialog, setOpenDialog] = useState(true);
  const handleClose = async () => {
    if (setShowDemo) setShowDemo(false);
    localStorage.setItem('firstTimeLogin', 'false');
    setOpenDialog(false);
  };

  const getUserLead = async (paramsEmail?: string) => {
    try {
      const { data } = await client.query({
        query: IS_USER_LEAD_EXISTS,
        fetchPolicy: 'no-cache',
        variables: { email: paramsEmail },
      });
      if (data.isUserExists.success) {
        setCaLeadId(data.isUserExists.caLeadId);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserLead(user?.email);
  }, []);

  const body = (
    <Grid className={classesPrev.demoDialog} style={{ maxWidth: '1250px' }}>
      <Box textAlign="right" className="closeButton">
        <IconButton onClick={handleClose}>
          <img src={DIALOG_CLOSE_ICON} alt="close" />
        </IconButton>
      </Box>

      <Grid>
        <>
          {loading && <Loader />}
          <div
            onLoadCapture={() => {
              setLoading(false);
              localStorage.setItem('firstTimeLogin', 'false');
            }}
          >
            <Card className={classes.calendlyCard}>
              <div>
                <div id="schedule_form">
                  <div
                    className="calendly-inline-widget"
                    data-url={`${CALENDLY_MEETING_URL_FOR_STUDENT}?name=${
                      user?.firstName + ' ' + user?.lastName
                    }&email=${user?.email}&CollegeAdvisorLeadId=${caLeadId}`}
                    style={{ minWidth: '320px', height: '500px' }}
                  />
                </div>
              </div>
            </Card>
          </div>
        </>
      </Grid>
    </Grid>
  );

  return (
    <Grid>
      <Modal open={openDialog} onClose={handleClose} disableBackdropClick>
        {body}
      </Modal>
    </Grid>
  );
};

export default HomePageCalendlyModel;
