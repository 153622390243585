import gql from 'graphql-tag';

export const ADD_MENTOR_STUDENT = gql`
  mutation AddMentorStudent(
    $MentorId: Int!
    $StudentId: Int!
    $description: String
    $startAt: Date
    $endAt: Date
    $matchReason: String!
    $matchType: String
  ) {
    addMentorStudent(
      input: {
        matchReason: $matchReason
        MentorId: $MentorId
        StudentId: $StudentId
        description: $description
        startAt: $startAt
        endAt: $endAt
        matchType: $matchType
      }
    ) {
      message
    }
  }
`;

export const CLOSE_SMART_MATCH_BANNER = gql`
  mutation SmartMatchBannerClose {
    smartMatchBannerClose {
      success
      message
    }
  }
`;

export const UPDATE_SMART_MATCH = gql`
  mutation updateSmartMatch($userId: Int, $isActive: Boolean) {
    updateSmartMatch(input: { userId: $userId, isActive: $isActive }) {
      success
      message
    }
  }
`;

export const STUDENT_AREAS_OF_INTEREST = gql`
  query studentAreasOfInterest($userId: Int!) {
    getProfileData(userId: $userId) {
      areasOfInterest
    }
  }
`;
