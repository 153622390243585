import { Box, Card, Container, Grid, Typography } from '@material-ui/core';
import { FC, useContext, useEffect } from 'react';

import { ClientStatus } from '../../../common/constants';
import { cjConfig } from '../../../common/utility';
import AccountMenu from '../../../components/AccountMenu';
import FooterNew from '../../../components/layout/FooterNew';
import { AppContext } from '../../../contexts';
import Caution_Icon from '../../../img/caution-icon-url.svg';
import Failure_Icon from '../../../img/failure-payment.svg';
import Billing from './Billing';
import FreeUser from './FreeUser';
import MyPlan from './MyPlan';
import ParentPlanBiling from './ParentPlanBiling';
import SelectCardAccount from './SelectCardAccount';
import { useStyles } from './styles';

const BillingPage: FC = () => {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  useEffect(() => {
    cjConfig({ userId: user?.id });
  }, []);

  if (!user) return null;
  const { premiumPackageType } = user;
  const enableCER1 = process.env.REACT_APP_ENABLE_CE_R1 === 'TRUE';
  return (
    <Box>
      <Container maxWidth={false}>
        <Box className={classes.mainContainer}>
          <Box className={classes.myAccountHeader}>
            <Typography variant="h3">My Account</Typography>
          </Box>

          <Grid container spacing={3}>
            <Grid item lg={3} md={4} sm={12} xs={12}>
              <AccountMenu />
            </Grid>

            {user.isParent ? (
              <Grid item lg={9} md={8} sm={12} xs={12}>
                <ParentPlanBiling />
              </Grid>
            ) : premiumPackageType ? (
              <Grid item lg={9} md={8} sm={12} xs={12}>
                {user.planCancelled &&
                  (user?.clientStatus === ClientStatus.BAD_DEBT ? (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className={classes.cancelPlanBox + ' badDebtPlan'}>
                        <img src={Failure_Icon} alt="caution" width="15px" />

                        <Box>
                          <Typography className="cautionTitle">
                            Your plan has been paused due to unpaid
                            installments.
                          </Typography>
                          <Typography className="cautionDescription">
                            Your membership exclusive benefits are now frozen.
                            Meetings cannot be scheduled at this time. You can
                            regain access to your advisor and membership
                            features by calling us at +1 (855) 412-6272.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className={classes.cancelPlanBox}>
                        <img src={Caution_Icon} alt="caution" width="15px" />

                        <Box>
                          <Typography className="cautionTitle">
                            Your plan has been canceled.
                          </Typography>
                          <Typography className="cautionDescription">
                            Your membership exclusive benefits are now frozen.
                            Meetings cannot be scheduled at this time. You can
                            regain access to your advisor and membership
                            features by calling us at +1 (855) 412-6272.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                {user?.userSubscription && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card className={classes.myAccountCard}>
                      <MyPlan plan={user?.userSubscription?.SubscriptionPlan} />
                    </Card>
                  </Grid>
                )}
                {enableCER1 && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card className={classes.myAccountCard}>
                      <Billing />
                    </Card>
                  </Grid>
                )}

                {/* Following condition is for the new/old users as old users will not have userSubscriptions */}
                {user?.userSubscription && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card className={classes.myAccountCard}>
                      <SelectCardAccount isBillingPage={true} />
                    </Card>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid item lg={9} md={8} sm={12} xs={12}>
                <Card className={classes.myAccountCard}>
                  <FreeUser />
                </Card>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>

      <FooterNew />
    </Box>
  );
};

export default BillingPage;
