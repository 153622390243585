import gql from 'graphql-tag';

export const GET_MEETING_TIME = gql`
  query getMeetingTime($zoom: Boolean, $userId: Int!) {
    getMeetingTime(zoom: $zoom, userId: $userId) {
      timeRemaining
      zoomLink
      dateWithTimeZone
      followUp
      specialistName
      link
      meetingStatus
      phoneNumber
      email
      stage
      coordinator
    }
  }
`;
