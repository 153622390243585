import Colors from '../../common/colors';

export const DOWN_ARROW = () => {
  return (
    <svg
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.36886 7.22861C4.95097 7.95242 3.90624 7.95242 3.48835 7.22861L0.667581 2.34289C0.24969 1.61908 0.772053 0.71432 1.60784 0.71432L7.24937 0.714321C8.08516 0.714321 8.60752 1.61908 8.18963 2.34289L5.36886 7.22861Z"
        fill={Colors.BLACK_TWELVE}
      />
    </svg>
  );
};

export const CHAT_IC = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 0H2.5125C1.1375 0 0.0125 1.125 0.0125 2.5L0.00134228 22.5839C0.000847187 23.4751 1.07829 23.9217 1.70845 23.2915L4.41421 20.5858C4.78929 20.2107 5.29799 20 5.82843 20H22.5C23.875 20 25 18.875 25 17.5V2.5C25 1.125 23.875 0 22.5 0ZM23.017 16.9183C23.0172 17.4708 22.5694 17.9187 22.017 17.9187L5.08773 17.9186C4.56144 17.9186 4.05635 18.1261 3.68199 18.496L2.17599 19.9841L2.17618 3.08454C2.17618 2.53226 2.62389 2.08455 3.17617 2.08455L22.0125 2.08436C22.5647 2.08436 23.0123 2.53187 23.0125 3.08404L23.017 16.9183Z"
        fill={Colors.BLACK_TEN}
      />
    </svg>
  );
};

export const PHONE_IC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8766 9.1894C12.0195 9.1894 11.1779 9.05534 10.3804 8.79178C9.98956 8.65849 9.50915 8.78077 9.27064 9.02573L7.69648 10.2141C5.87091 9.23956 4.74639 8.11541 3.7852 6.30354L4.93856 4.77039C5.23821 4.47114 5.34569 4.03401 5.21692 3.62385C4.95223 2.82213 4.81777 1.98093 4.81777 1.12343C4.81781 0.503964 4.31385 0 3.69442 0H1.12339C0.503962 0 0 0.503964 0 1.12339C0 8.22365 5.77637 14 12.8766 14C13.496 14 14 13.496 14 12.8766V10.3127C14 9.69336 13.496 9.1894 12.8766 9.1894Z"
        fill={Colors.WHITE_ONE}
      />
    </svg>
  );
};

export const ARROW_IC = () => {
  return (
    <svg
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.416667 4.05006H8.72896L5.54604 0.7759C5.37937 0.604452 5.37292 0.319606 5.53167 0.139608C5.69021 -0.0401648 5.95396 -0.0473648 6.12083 0.124083L9.75583 3.86354C9.91313 4.03364 10 4.25953 10 4.50005C10 4.74035 9.91313 4.96647 9.74854 5.144L6.12063 8.8758C6.04 8.95883 5.93667 9 5.83333 9C5.72333 9 5.61333 8.9532 5.53146 8.86028C5.37271 8.68028 5.37917 8.39566 5.54583 8.22421L8.74208 4.95005H0.416667C0.186667 4.95005 0 4.74845 0 4.50005C0 4.25166 0.186667 4.05006 0.416667 4.05006Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const EX_LINK = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.29297 0.292893C7.48051 0.105357 7.73486 0 8.00008 0H13.0001C13.2653 0 13.5197 0.105357 13.7072 0.292893C13.8947 0.48043 14.0001 0.734783 14.0001 1V6C14.0001 6.26522 13.8947 6.51957 13.7072 6.70711C13.5197 6.89464 13.2653 7 13.0001 7C12.7349 7 12.4805 6.89464 12.293 6.70711C12.1054 6.51957 12.0001 6.26522 12.0001 6V3.414L5.70708 9.707C5.61483 9.80251 5.50449 9.87869 5.38249 9.9311C5.26048 9.98351 5.12926 10.0111 4.99648 10.0123C4.8637 10.0134 4.73202 9.9881 4.60913 9.93782C4.48623 9.88754 4.37458 9.81329 4.28069 9.71939C4.18679 9.6255 4.11254 9.51385 4.06226 9.39095C4.01198 9.26806 3.98668 9.13638 3.98783 9.0036C3.98898 8.87082 4.01657 8.7396 4.06898 8.6176C4.12139 8.49559 4.19757 8.38525 4.29308 8.293L10.5861 2H8.00008C7.73486 2 7.48051 1.89464 7.29297 1.70711C7.10544 1.51957 7.00008 1.26522 7.00008 1C7.00008 0.734783 7.10544 0.48043 7.29297 0.292893Z"
        fill="#A9A9A9"
      />
      <path
        d="M0.585786 2.58579C0.960859 2.21071 1.46957 2 2 2H5C5.26522 2 5.51957 2.10536 5.70711 2.29289C5.89464 2.48043 6 2.73478 6 3C6 3.26522 5.89464 3.51957 5.70711 3.70711C5.51957 3.89464 5.26522 4 5 4H2V12H10V9C10 8.73478 10.1054 8.48043 10.2929 8.29289C10.4804 8.10536 10.7348 8 11 8C11.2652 8 11.5196 8.10536 11.7071 8.29289C11.8946 8.48043 12 8.73478 12 9V12C12 12.5304 11.7893 13.0391 11.4142 13.4142C11.0391 13.7893 10.5304 14 10 14H2C1.46957 14 0.960859 13.7893 0.585786 13.4142C0.210714 13.0391 0 12.5304 0 12V4C0 3.46957 0.210714 2.96086 0.585786 2.58579Z"
        fill="#A9A9A9"
      />
    </svg>
  );
};

export const LOCKED_CHAT = () => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0147 21L23.0157 23.9183C23.0159 24.4708 22.5681 24.9187 22.0157 24.9187L5.08639 24.9186C4.5601 24.9186 4.05501 25.1261 3.68065 25.496L2.17465 26.9841L2.17484 10.0845C2.17484 9.5323 2.62255 9.0846 3.17483 9.0845L13.0027 9.08445C13.0176 8.50409 13.0666 7.77203 13.1937 7H2.51116C1.13616 7 0.0111579 8.125 0.0111579 9.5L0 29.5839C-0.000494922 30.4751 1.07695 30.9217 1.70711 30.2916L4.41287 27.5858C4.78795 27.2107 5.29665 27 5.82709 27H22.4987C23.8737 27 24.9987 25.875 24.9987 24.5V21H23.0147Z"
        fill={Colors.BLACK_TEN}
      />
      <path
        d="M28.125 6.33333H27.1875V4.52381C27.1875 2.02667 25.0875 0 22.5 0C19.9125 0 17.8125 2.02667 17.8125 4.52381V6.33333H16.875C15.8438 6.33333 15 7.14762 15 8.14286V17.1905C15 18.1857 15.8438 19 16.875 19H28.125C29.1562 19 30 18.1857 30 17.1905V8.14286C30 7.14762 29.1562 6.33333 28.125 6.33333ZM22.5 14.4762C21.4688 14.4762 20.625 13.6619 20.625 12.6667C20.625 11.6714 21.4688 10.8571 22.5 10.8571C23.5312 10.8571 24.375 11.6714 24.375 12.6667C24.375 13.6619 23.5312 14.4762 22.5 14.4762ZM19.6875 6.33333V4.52381C19.6875 3.0219 20.9437 1.80952 22.5 1.80952C24.0562 1.80952 25.3125 3.0219 25.3125 4.52381V6.33333H19.6875Z"
        fill="#FFB675"
      />
    </svg>
  );
};

export const PROFILE_IC = () => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1662 36.3324C28.1991 36.3324 36.3324 28.1991 36.3324 18.1662C36.3324 8.13329 28.1991 0 18.1662 0C8.13329 0 0 8.13329 0 18.1662C0 28.1991 8.13329 36.3324 18.1662 36.3324Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.3333 18.1667C36.3333 28.1998 28.1998 36.3333 18.1667 36.3333C8.13349 36.3333 0 28.1998 0 18.1667C0 8.13349 8.13349 0 18.1667 0C28.1998 0 36.3333 8.13349 36.3333 18.1667ZM28.5466 26.1611C29.1422 26.7688 29.1422 27.7314 28.5464 28.3393C25.9088 31.0303 22.2327 32.7 18.1669 32.7C14.1007 32.7 10.4247 31.0303 7.78689 28.3389C7.19118 27.7312 7.1912 26.7686 7.78691 26.1607C10.4247 23.4697 14.1006 21.8 18.1667 21.8C22.2327 21.8 25.9088 23.4699 28.5466 26.1611ZM18.1667 18.1667C21.1767 18.1667 23.6167 15.7267 23.6167 12.7167C23.6167 9.70672 21.1767 7.26667 18.1667 7.26667C15.1566 7.26667 12.7167 9.70672 12.7167 12.7167C12.7167 15.7267 15.1566 18.1667 18.1667 18.1667Z"
        fill="#D2D1D2"
      />
    </svg>
  );
};
