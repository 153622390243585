import gql from 'graphql-tag';

export const GET_LEADS_USERS = gql`
  query QgetLeadsUsers {
    getLeadsUsersQuery {
      id
      name
    }
  }
`;
export const CREATE_LEAD_ACCOUNTS_MUTATION = gql`
  mutation CreateLeadAccountMutation(
    $studentFirstName: String!
    $studentLastName: String
    $studentEmail: String
    $studentPhone: String
    $highSchoolYear: String
    $parentFirstName: String
    $parentLastName: String
    $parentEmail: String
    $parentPhone: String
    $userRole: String!
    $aidOption: Int
    $userAnalyticParams: JSON
    $partnerKey: String
    $source: String
    $gpa: String
    $salesRegitration: Boolean
    $salesPersonId: String
    $eventName: String
    $digitalSetMeeting: Boolean
    $registrationType: String
    $isMBAGrad: Boolean
    $undergraduateGradYear: String
    $undergraduateCollege: String
    $optInSMS: Boolean
  ) {
    createLeadAccount(
      input: {
        studentFirstName: $studentFirstName
        studentLastName: $studentLastName
        studentEmail: $studentEmail
        studentPhone: $studentPhone
        highSchoolYear: $highSchoolYear
        parentFirstName: $parentFirstName
        parentLastName: $parentLastName
        parentEmail: $parentEmail
        parentPhone: $parentPhone
        userRole: $userRole
        aidOption: $aidOption
        userAnalyticParams: $userAnalyticParams
        partnerKey: $partnerKey
        source: $source
        gpa: $gpa
        salesRegitration: $salesRegitration
        salesPersonId: $salesPersonId
        eventName: $eventName
        digitalSetMeeting: $digitalSetMeeting
        registrationType: $registrationType
        isMBAGrad: $isMBAGrad
        undergraduateGradYear: $undergraduateGradYear
        undergraduateCollege: $undergraduateCollege
        optInSMS: $optInSMS
      }
    ) {
      status
      redirectToLogin
      redirectToPassword
      token
      LeadId
      caLeadId
      userToken
      primaryLead
      magicLink
      studentName
      parentName
      gradYear
    }
  }
`;
export const GET_PARTNERS_FROM_LEAD_API_QUERY = gql`
  query GetPartnersFromLeadApiQuery {
    getPartnersFromLeadApi {
      partners {
        id
        name
        token
      }
    }
  }
`;
export const VALIDATE_SALE_AUTH_KEY_QUERY = gql`
  query ValidateSalesAuthKey($authKey: String) {
    validateSalesAuthKey(input: { authKey: $authKey }) {
      validKey
    }
  }
`;

export const GET_ALL_USERS = gql`
  query getAllUsersQuery(
    $searchQuery: String
    $roles: [String]
    $isDirectLeadForm: Boolean
  ) {
    listAllUsers(
      input: {
        searchQuery: $searchQuery
        roles: $roles
        isDirectLeadForm: $isDirectLeadForm
      }
    ) {
      id
      firstName
      lastName
      email
      roles
      phoneNumber
      childrens {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
