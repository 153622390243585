import React, { useContext } from 'react';
import { SendBirdProvider as SBProvider } from 'sendbird-uikit';

import { ApolloConsumer } from 'react-apollo';
import UpdateLastLoginLocation from './components/UpdateLastLoginLocation';
import { AppContext } from './contexts';
import Pages from './pages';

function AppContainer() {
  const { user } = useContext(AppContext);
  const nickName = `${user?.firstName} ${user?.lastName}`;
  const userId = user?.sendbirdId || user?.email;
  const pictureUrl = user?.pictureUrl;

  return user && !user.isParent && user.isPremium ? (
    <SBProvider
      appId={process.env.REACT_APP_SENDBIRD_APPLICATION_ID}
      userId={userId}
      nickname={nickName}
      profileUrl={pictureUrl}
    >
      {user && (
        <ApolloConsumer>
          {(client) => {
            return <UpdateLastLoginLocation client={client} />;
          }}
        </ApolloConsumer>
      )}
      <Pages />
    </SBProvider>
  ) : (
    <>
      {user && (
        <ApolloConsumer>
          {(client) => {
            return <UpdateLastLoginLocation client={client} />;
          }}
        </ApolloConsumer>
      )}
      <Pages />
    </>
  );
}

export default React.memo(AppContainer);
