import { Box, Card, Grid, Typography } from '@material-ui/core';
import { useContext } from 'react';
import { Query } from 'react-apollo';

import { AppContext } from '../../../contexts';

import { ALL_BLOGS } from './gql';
import {
  allBlogsQuery,
  allBlogsQuery_allBlogUrl_Blogs,
} from './gql/__generated__/allBlogsQuery';

import Blog_Link_Image from '../../../img/blog-link.svg';
import Next_Image from '../../../img/next-link.svg';
import { blogStyles } from './styles';

const BlogPostsCard = () => {
  const classes = blogStyles();
  const { user } = useContext(AppContext);
  const year = user?.highSchoolYear;
  const name = year
    ? +year === new Date().getFullYear() ||
      +year === new Date().getFullYear() + 1
      ? 'Senior'
      : +year === new Date().getFullYear() + 2
      ? 'Junior'
      : +year === new Date().getFullYear() + 3
      ? 'Sophomore'
      : 'Freshman'
    : 'Freshman';
  const removeFirstBlog = (
    blog: (allBlogsQuery_allBlogUrl_Blogs | null)[] | null
  ) => {
    blog && blog.shift();
    return blog;
  };

  return (
    <Query<allBlogsQuery>
      query={ALL_BLOGS}
      fetchPolicy="network-only"
      variables={{ name, forStudent: true }}
    >
      {({ data, loading }) => {
        if (loading) return null;
        if (!data || !data?.allBlogUrl?.Blogs?.length) return <></>;
        const blogs = [...data.allBlogUrl.Blogs];

        const firsFeaturedBlog: allBlogsQuery_allBlogUrl_Blogs | null = data
          .allBlogUrl.Blogs[0]?.isFeatured
          ? blogs[0]
          : null;
        const otherBLogs: (allBlogsQuery_allBlogUrl_Blogs | null)[] | null =
          firsFeaturedBlog ? removeFirstBlog(blogs) : blogs;

        return (
          <a
            href="https://www.collegeadvisor.com/resources/"
            target="_blank"
            rel="noreferrer"
          >
            <Card className={classes.applicationsCard}>
              <Box>
                <Grid className={classes.applicationsHeader}>
                  <Typography variant="h4">
                    Blog Posts For You
                    <img src={Blog_Link_Image} alt="link" />
                  </Typography>
                </Grid>
                {firsFeaturedBlog && (
                  <a
                    href={
                      firsFeaturedBlog?.blogUrl ||
                      'https://www.collegeadvisor.com/resources/'
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Grid className={classes.collegeInfoWrapper}>
                      <Grid className={classes.collegeLogo}>
                        <img
                          src={firsFeaturedBlog?.imgUrl || Blog_Link_Image}
                          alt="blog"
                        />
                      </Grid>

                      <Grid className={classes.blogText}>
                        <Typography variant="caption">FEATURED</Typography>
                        <Typography variant="h5">
                          {firsFeaturedBlog?.heading}
                        </Typography>
                        <Typography variant="body2">
                          {firsFeaturedBlog?.text.slice(0, 150)}...
                        </Typography>
                      </Grid>
                    </Grid>
                  </a>
                )}
                {otherBLogs &&
                  otherBLogs.map((item, index) => (
                    <a
                      key={`key-blog-${index}`}
                      href={
                        item?.blogUrl ||
                        'https://www.collegeadvisor.com/resources/'
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Grid>
                        <Grid className={classes.blogInfoWrapper}>
                          <Grid>
                            <Typography variant="body1" className="blogText">
                              {item?.text}
                            </Typography>
                          </Grid>

                          <img src={Next_Image} alt="next" />
                        </Grid>
                      </Grid>
                    </a>
                  ))}
                <Grid className={classes.exploreMore}>
                  <Typography variant="h6">VISIT BLOG</Typography>
                </Grid>
              </Box>
            </Card>
          </a>
        );
      }}
    </Query>
  );
};
export default BlogPostsCard;
