import { useState, FC, useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  Typography,
  ThemeProvider,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

import CustomAlert from '../../components/NewCustomAlert';
import { validateEmail } from '../../common/utility';
import { TextField } from '../../components/fields';
import { useStyles } from './styles';

import {
  InitResetPasswordMuation,
  InitResetPasswordMuationVariables,
} from './__generated__/InitResetPasswordMuation';
import { AppContext } from '../../contexts';
import SmallLogo from '../../components/layout/SmallLogo';
import { customTheme } from '../MaterializeCss';

export const INIT_RESET_PWD_MUTATION = gql`
  mutation InitResetPasswordMuation($email: String!, $portal: String!) {
    initResetPassword(input: { email: $email, portal: $portal }) {
      status
    }
  }
`;

const NewForgotPasswordForm: FC = () => {
  const { user } = useContext(AppContext);
  const initialValues: any = { email: '' };

  const urlParams = new URLSearchParams(window.location.search);
  const goBack = urlParams.get('_goback');

  const FormSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
  });

  const [variant, setVariant] = useState('');
  const [message, setMessage] = useState('');
  function toggleAlert(variant?: string, message?: string) {
    setVariant(variant || '');
    setMessage(message || '');
  }
  const classes = useStyles();

  return (
    <>
      {user &&
        (!user.isParent ? (
          <Redirect to="/home" />
        ) : (
          <Redirect to="/account/student-accounts" />
        ))}

      <CustomAlert
        variant={variant}
        message={message}
        toggleAlert={toggleAlert}
      />
      <Mutation<InitResetPasswordMuation, InitResetPasswordMuationVariables>
        mutation={INIT_RESET_PWD_MUTATION}
      >
        {(initResetPwdMutation: Function) => {
          return (
            <Formik
              initialValues={initialValues}
              validationSchema={FormSchema}
              onSubmit={async (variables, { setSubmitting, resetForm }) => {
                try {
                  const _variables = { ...variables, portal: 'Student' };
                  await initResetPwdMutation({ variables: _variables });

                  setSubmitting(false);
                  const variant = 'success';
                  const message =
                    'Email with instructions sent to your email address.';
                  toggleAlert(variant, message);
                  resetForm(initialValues);
                  setSubmitting(false);
                } catch (error) {
                  console.log('error: ', error);
                  setSubmitting(false);
                  const variant = 'error';
                  const message = 'Something went wrong, Please try again';
                  toggleAlert(variant, message);
                }
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Box
                    mt={2}
                    maxWidth={390}
                    margin="0  auto"
                    marginBottom={10}
                    width="100%"
                  >
                    <Box display="flex" alignItems="center" marginBottom={7.5}>
                      <Box
                        maxWidth={48}
                        marginRight={2}
                        className="hide-mobile"
                      >
                        <SmallLogo />
                      </Box>

                      <Box component="h4">Reset Password</Box>
                    </Box>
                  </Box>

                  <Box maxWidth={370} margin="10px auto">
                    <Field
                      type="email"
                      name="email"
                      validate={validateEmail}
                      component={TextField}
                      label="Email"
                      fullWidth
                      className={`${classes.textfield}`}
                    />
                    <ThemeProvider theme={customTheme}>
                      <Button
                        fullWidth
                        type="submit"
                        color="secondary"
                        variant="contained"
                        disabled={isSubmitting}
                      >
                        Send Password Reset Link
                      </Button>
                    </ThemeProvider>
                  </Box>
                </Form>
              )}
            </Formik>
          );
        }}
      </Mutation>

      <Grid className={classes.FormBottomSection}>
        <Typography variant="body1" className={classes.FormBottomText}>
          Remembered your password? Return to
          <Link to={`${goBack ? `/login${window.location.search}` : `/login`}`}>
            Log In
          </Link>
        </Typography>
      </Grid>
    </>
  );
};

export default NewForgotPasswordForm;
