import { Box, Collapse, Hidden, IconButton, Tooltip } from '@material-ui/core';
import moment from 'moment-timezone';
import { FC, useContext, useState } from 'react';
import {
  DisputeStatus,
  disputeStatusAlt,
  InstallmentStatus,
  NewRefundStatus,
  OriginalRefundStatus,
  PaymentStatus,
} from '../../../common/constants';
import { formatter } from '../../../common/utility';
import { AppContext } from '../../../contexts';
import Collapse_Arrow_Img from '../../../img/arrow-down.svg';
import CautionIcon from '../../../img/caution-icon-url.svg';
import {
  default as DownloadIcon,
  default as RECEIPT_DOWNLOAD_ICON,
} from '../../../img/download.png';
import DOWNLOAD_DISABLED from '../../../img/download_disabled.png';
import FAILURE_ICON from '../../../img/failure-payment.svg';
import REFUND_ICON from '../../../img/refund-icon.png';
import {
  default as REPROCESS,
  default as Reprocess,
} from '../../../img/reprocess.png';
import {
  default as ReprocessDisabled,
  default as REPROCESS_DISABLED,
} from '../../../img/reprocess_disabled.png';
import ReprocessPaymentModal from './ReprocessPaymentModal';
import { useStyles } from './styles';
import {
  GetUserInvoices_getUserInvoices,
  GetUserInvoices_getUserInvoices_payments,
  GetUserInvoices_getUserInvoices_payments_disputes,
  GetUserInvoices_getUserInvoices_payments_disputes_DisputeStatuses,
} from './__generated__/GetUserInvoices';

export interface Invoice {
  id: string | null;
  date: any;
  status: string | null;
  amount: number | null;
  cardBrand: string | null;
  cardLast4: string | null;
  type: string;
  invoice_pdf: string | null;
  sign: string;
  errorMessage: string;
}

interface PaymentRowInterface {
  installment: GetUserInvoices_getUserInvoices | null;
  refetchInvoices: Function;
  invoice: Invoice;
}

const PaymentRow: FC<PaymentRowInterface> = ({
  installment,
  refetchInvoices,
  invoice,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [processingAmount, setAmount] = useState<number>(0);
  const [InvoiceId, setInvoiceId] = useState<string>('');

  const { user } = useContext(AppContext);

  const classes = useStyles();
  const reprocessPayment = (amount: number, invoiceId: string) => {
    setShowModal(true);
    setAmount(amount);
    setInvoiceId(invoiceId);
  };

  const onClose = () => {
    setShowModal(false);
    setAmount(0);
    setInvoiceId('');
  };
  if (!installment) {
    return null;
  }

  const { shouldRetry, isDisputed } = installment;
  const shouldOpen = shouldRetry || isDisputed || open;

  if (!user) return null;
  const { userSubscription, userCoupon } = user;
  let planPrice: any = null;
  let amount = user?.userSubscription?.SubscriptionPlan?.amount;

  let actualAmount: any = amount / 100;
  let installments = userSubscription?.SubscriptionPlan?.installments;
  let shouldHidePendingPayment = false;
  let savings: any = !userCoupon
    ? null
    : userCoupon?.amount_off
    ? userCoupon?.amount_off / 100
    : userCoupon.percent_off
    ? (amount * userCoupon?.percent_off) / 100 / 100
    : null;
  if (savings) {
    if (installments) {
      planPrice = actualAmount - savings;
    } else {
      planPrice = actualAmount - savings;
    }
  } else {
    planPrice = actualAmount;
  }
  actualAmount = planPrice;

  if (installments) {
    amount = amount * installments;
    actualAmount = actualAmount * installments;
  }

  amount = formatter.format(amount / 100);
  actualAmount = formatter.format(actualAmount);
  if (planPrice) {
    planPrice = formatter.format(planPrice);
  }

  return (
    <>
      {installment?.payments?.length ? (
        installment.payments.map(
          (
            payment: GetUserInvoices_getUserInvoices_payments | null,
            ind: number
          ) => {
            if (!payment) {
              return null;
            }
            const paymentLength = installment.payments?.length || 0;

            const {
              totalAmount,
              invoice_pdf,
              invoiceId,
              createdAt,
              status,
              disputes,
              refunds,
              cardBrand,
              cardLast4,
            } = payment;

            const showCollapseIcon =
              !shouldRetry &&
              !isDisputed &&
              (paymentLength > 1 ||
                payment.refunds?.length ||
                payment.disputes?.length);

            if (status === PaymentStatus.FAILURE) {
              if (!(showCollapseIcon ? open : true)) {
                return null;
              }
            }
            if (
              status === PaymentStatus.PENDING &&
              (paymentLength > 1 ||
              payment.refunds?.length ||
              payment.disputes?.length
                ? !open
                : false)
            ) {
              shouldHidePendingPayment = true;
            }
            return (
              <Box className={classes.paymentsTableBody}>
                {showCollapseIcon && ind === 0 ? (
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    className={
                      !open
                        ? `${classes.collapseButton}  addPosition`
                        : classes.collapseButton
                    }
                    onClick={() => setOpen(!open)}
                  >
                    {open ? (
                      <img
                        className={classes.collapseUp}
                        src={Collapse_Arrow_Img}
                        alt="collapse"
                      />
                    ) : (
                      <img src={Collapse_Arrow_Img} alt="collapse" />
                    )}
                  </IconButton>
                ) : null}
                <Collapse in={shouldOpen} unmountOnExit timeout="auto">
                  {refunds?.map((refund: any) => {
                    if (!refund) return null;
                    const refundStatus =
                      refund.status === OriginalRefundStatus.PENDING
                        ? NewRefundStatus.PENDING
                        : refund.status === OriginalRefundStatus.SUCCESS
                        ? NewRefundStatus.SUCCESS
                        : refund.status;
                    return (
                      <>
                        {refunds.length ? (
                          <img
                            src={REFUND_ICON}
                            alt="refund"
                            className={refunds.length ? 'refund-img' : 'hidden'}
                          />
                        ) : null}
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          className={`paymenyMobileFlex ${classes.refundRow}`}
                        >
                          <Box className="paymentTableBodyCell paymentsTableHeadFirst">
                            <Hidden only={['xl', 'lg', 'md']}>
                              <span className="mobileHeading">
                                Payment Method:{' '}
                              </span>
                            </Hidden>
                            {status?.toLowerCase() === 'scheduled'
                              ? '--'
                              : `${
                                  cardBrand
                                    ? cardBrand + ' Ending in ' + cardLast4
                                    : '--'
                                }`}
                          </Box>

                          <Box className="paymentTableBodyCell paymentsTableHeadSecond">
                            <Hidden only={['xl', 'lg', 'md']}>
                              <span className="mobileHeading">Date: </span>
                            </Hidden>

                            {refund.amountRefundedAt
                              ? moment(refund.amountRefundedAt).format(
                                  'MM/DD/YYYY'
                                )
                              : '--'}
                          </Box>

                          <Box className="paymentTableBodyCell paymentsTableHeadThird">
                            <Hidden only={['xl', 'lg', 'md']}>
                              <span className="mobileHeading">Amount: </span>
                            </Hidden>
                            -{formatter.format(refund.amountRefunded / 100)}
                          </Box>

                          <Box className="capitalizeText paymentTableBodyCell paymentsTableHeadFour">
                            <Hidden only={['xl', 'lg', 'md']}>
                              <span className="mobileHeading">Status: </span>
                            </Hidden>
                            {refundStatus}
                          </Box>

                          <Box
                            textAlign="center"
                            className="paymentTableBodyCell paymentsTableHeadSix"
                          >
                            &nbsp;
                          </Box>
                        </Box>
                      </>
                    );
                  })}

                  {disputes?.map(
                    (
                      dispute: GetUserInvoices_getUserInvoices_payments_disputes | null
                    ) => {
                      if (!dispute) {
                        return null;
                      }
                      return dispute?.DisputeStatuses?.sort(
                        (a: any, b: any) => b.id - a.id
                      )?.map(
                        (
                          dStatus: GetUserInvoices_getUserInvoices_payments_disputes_DisputeStatuses | null
                        ) => {
                          return (
                            <Box className="cautionTableRow">
                              <img
                                src={CautionIcon}
                                className={'caution-img'}
                                alt="caution"
                              />
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                className="paymenyMobileFlex"
                              >
                                <Box className="paymentTableBodyCell paymentsTableHeadFirst">
                                  <Hidden only={['xl', 'lg', 'md']}>
                                    <span className="mobileHeading">
                                      Payment Method:{' '}
                                    </span>
                                  </Hidden>
                                  {status?.toLowerCase() === 'scheduled'
                                    ? '--'
                                    : `${
                                        cardBrand
                                          ? cardBrand +
                                            ' Ending in ' +
                                            cardLast4
                                          : '--'
                                      }`}
                                </Box>

                                <Box className="paymentTableBodyCell paymentsTableHeadSecond">
                                  <Hidden only={['xl', 'lg', 'md']}>
                                    <span className="mobileHeading">
                                      Date:{' '}
                                    </span>
                                  </Hidden>

                                  {dispute.createdAt
                                    ? moment(dispute.createdAt).format(
                                        'MM/DD/YYYY'
                                      )
                                    : '--'}
                                </Box>

                                <Box className="paymentTableBodyCell paymentsTableHeadThird">
                                  <Hidden only={['xl', 'lg', 'md']}>
                                    <span className="mobileHeading">
                                      Amount:{' '}
                                    </span>
                                  </Hidden>
                                  {dStatus?.status === DisputeStatus.OPENED &&
                                    '-'}
                                  {dStatus?.status === DisputeStatus.WON && '+'}
                                  {/* When a dispute is opened or lost by client then show the amount in disputes */}
                                  {dStatus?.status === DisputeStatus.OPENED ||
                                  dStatus?.status === DisputeStatus.WON
                                    ? formatter.format(
                                        dispute.amount
                                          ? dispute.amount / 100
                                          : 0
                                      )
                                    : '--'}
                                </Box>

                                <Box className="capitalizeText paymentTableBodyCell paymentsTableHeadFour">
                                  <Hidden only={['xl', 'lg', 'md']}>
                                    <span className="mobileHeading">
                                      Status:{' '}
                                    </span>
                                  </Hidden>

                                  {dStatus?.status
                                    ? dStatus.status === DisputeStatus.WON
                                      ? disputeStatusAlt[
                                          DisputeStatus.LOST
                                        ].replace(/_/g, ' ')
                                      : dStatus.status === DisputeStatus.LOST
                                      ? disputeStatusAlt[
                                          DisputeStatus.WON
                                        ].replace(/_/g, ' ')
                                      : disputeStatusAlt[dStatus.status]
                                      ? disputeStatusAlt[
                                          dStatus.status
                                        ].replace(/_/g, ' ')
                                      : dStatus.status.replace(/_/g, ' ')
                                    : '--'}
                                  {dStatus?.status === DisputeStatus.WON ||
                                  dStatus?.status === DisputeStatus.LOST
                                    ? ' by student'
                                    : ''}
                                </Box>

                                <Box
                                  textAlign="center"
                                  className="paymentTableBodyCell paymentsTableHeadSix"
                                >
                                  &nbsp;
                                </Box>
                              </Box>
                            </Box>
                          );
                        }
                      );
                    }
                  )}

                  <Box className="paymentsTableRow">
                    {status === PaymentStatus.FAILURE ? (
                      <img
                        src={FAILURE_ICON}
                        alt="FAILURE ICON"
                        className={'caution-img failure-img'}
                      />
                    ) : null}
                    <Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        className="paymenyMobileFlex"
                      >
                        <Box className="paymentTableBodyCell paymentsTableHeadFirst">
                          <Hidden only={['xl', 'lg', 'md']}>
                            <span className="mobileHeading">
                              Payment Method:{' '}
                            </span>
                          </Hidden>

                          {cardBrand
                            ? cardBrand + ' Ending in ' + cardLast4
                            : '--'}
                        </Box>

                        <Box className="paymentTableBodyCell paymentsTableHeadSecond">
                          <Hidden only={['xl', 'lg', 'md']}>
                            <span className="mobileHeading">Date: </span>
                          </Hidden>

                          {createdAt
                            ? moment(createdAt).format('MM/DD/YYYY')
                            : '--'}
                        </Box>

                        <Box className="paymentTableBodyCell paymentsTableHeadThird">
                          <Hidden only={['xl', 'lg', 'md']}>
                            <span className="mobileHeading">Amount: </span>
                          </Hidden>

                          {formatter.format(
                            totalAmount ? totalAmount / 100 : 0
                          )}
                        </Box>

                        <Box className="capitalizeText paymentTableBodyCell paymentsTableHeadFour">
                          <Hidden only={['xl', 'lg', 'md']}>
                            <span className="mobileHeading">Status: </span>
                          </Hidden>
                          {status !== PaymentStatus.FAILURE &&
                          status !== PaymentStatus.PENDING ? (
                            <span className={'statusBadge'}>Paid</span>
                          ) : (
                            <span>{status}</span>
                          )}
                          {payment.errorMessage
                            ? ' - ' + payment.errorMessage
                            : null}
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          className="paymentTableBodyCell paymentsTableHeadSix"
                        >
                          {invoice_pdf && ind == 0 ? (
                            invoice_pdf?.includes('pdf') ? (
                              <Tooltip title="RECEIPT" placement="top">
                                <a href={`${invoice_pdf}`}>
                                  <IconButton className="iconButtonPayment">
                                    <img
                                      src={DownloadIcon}
                                      alt="download"
                                      width="14px"
                                    />
                                  </IconButton>
                                </a>
                              </Tooltip>
                            ) : (
                              <Tooltip title="RECEIPT" placement="top">
                                <a
                                  download
                                  target="_blank"
                                  href={`${invoice_pdf}`}
                                  rel="noreferrer"
                                >
                                  <IconButton className="iconButtonPayment">
                                    <img src={DownloadIcon} alt="receipt" />
                                  </IconButton>
                                </a>
                              </Tooltip>
                            )
                          ) : null}
                          {shouldRetry && ind === 0 ? (
                            <Tooltip title="REPROCESS" placement="top">
                              <IconButton
                                className="iconButtonPayment"
                                onClick={() =>
                                  reprocessPayment(
                                    totalAmount || 0,
                                    invoiceId || ''
                                  )
                                }
                              >
                                <img
                                  src={Reprocess}
                                  alt="reprocess_disabled"
                                  width="19px"
                                />
                              </IconButton>
                            </Tooltip>
                          ) : ind == 0 ? (
                            <Tooltip title="REPROCESS" placement="top">
                              <IconButton className="iconButtonPayment">
                                <img
                                  src={ReprocessDisabled}
                                  alt="reprocess_disabled"
                                  width="19px"
                                />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Collapse>
                <Collapse
                  in={!shouldOpen && !shouldHidePendingPayment}
                  unmountOnExit
                  timeout="auto"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    className={`paymenyMobileFlex ${classes.refundRow}`}
                  >
                    {invoice?.type === 'failed' ? (
                      <img
                        src={FAILURE_ICON}
                        alt="FAILURE ICON"
                        className={'caution-img failure-img'}
                      />
                    ) : invoice?.type === 'refund' ? (
                      <img
                        src={REFUND_ICON}
                        alt="REFUND ICON"
                        className={'payment-img'}
                      />
                    ) : invoice?.type === 'dispute' ? (
                      <img
                        src={CautionIcon}
                        alt="CAUTION ICON"
                        className={'payment-img'}
                      />
                    ) : null}
                    <Box className="paymentTableBodyCell paymentsTableHeadFirst">
                      <Hidden only={['xl', 'lg', 'md']}>
                        <span className="mobileHeading">Payment Method: </span>
                      </Hidden>
                      {invoice?.cardBrand
                        ? invoice?.cardBrand +
                          ' Ending in ' +
                          invoice?.cardLast4
                        : '--'}
                    </Box>

                    <Box className="paymentTableBodyCell paymentsTableHeadSecond">
                      <Hidden only={['xl', 'lg', 'md']}>
                        <span className="mobileHeading">Date: </span>
                      </Hidden>

                      {invoice?.date
                        ? moment(invoice?.date).format('MM/DD/YYYY')
                        : '--'}
                    </Box>

                    <Box className="paymentTableBodyCell paymentsTableHeadThird">
                      <Hidden only={['xl', 'lg', 'md']}>
                        <span className="mobileHeading">Amount: </span>
                      </Hidden>
                      {invoice?.sign ? invoice.sign : ''}
                      {invoice?.amount
                        ? formatter.format(invoice.amount / 100)
                        : '--'}
                    </Box>

                    <Box className="capitalizeText paymentTableBodyCell paymentsTableHeadFour">
                      <Hidden only={['xl', 'lg', 'md']}>
                        <span className="mobileHeading">Status: </span>
                      </Hidden>

                      <span
                        className={
                          invoice.type === 'success' ? 'statusBadge' : ''
                        }
                      >
                        {invoice.type === 'success'
                          ? 'Paid'
                          : invoice?.status?.replace(/_/g, ' ')}
                      </span>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="centers"
                      className="paymentTableBodyCell paymentsTableHeadSix"
                    >
                      {invoice?.invoice_pdf && ind === 0 ? (
                        invoice?.invoice_pdf?.includes('pdf') ? (
                          <Tooltip title="RECEIPT" placement="top">
                            <a href={`${invoice?.invoice_pdf}`}>
                              <IconButton>
                                <img
                                  src={RECEIPT_DOWNLOAD_ICON}
                                  alt="receipt"
                                />
                              </IconButton>
                            </a>
                          </Tooltip>
                        ) : (
                          <Tooltip title="RECEIPT" placement="top">
                            <a
                              download
                              target="_blank"
                              href={`${invoice?.invoice_pdf}`}
                              rel="noreferrer"
                            >
                              <IconButton>
                                <img
                                  src={RECEIPT_DOWNLOAD_ICON}
                                  alt="receipt"
                                />
                              </IconButton>
                            </a>
                          </Tooltip>
                        )
                      ) : ind === 0 ? (
                        <Tooltip title="RECEIPT" placement="top">
                          <IconButton className="iconButtonPayment">
                            <img
                              src={DOWNLOAD_DISABLED}
                              alt="reprocess_disabled"
                            />
                          </IconButton>
                        </Tooltip>
                      ) : null}

                      {shouldRetry &&
                      ind === 0 &&
                      user?.roles?.includes('AR') &&
                      invoice?.id ? (
                        <Tooltip title="REPROCESS" placement="top">
                          <IconButton
                            onClick={() =>
                              reprocessPayment(
                                invoice.amount || 0,
                                invoice.id || ''
                              )
                            }
                          >
                            <img src={REPROCESS} alt="reprocess" />
                          </IconButton>
                        </Tooltip>
                      ) : ind === 0 ? (
                        <Tooltip title="REPROCESS" placement="top">
                          <IconButton className="iconButtonPayment">
                            <img src={REPROCESS_DISABLED} alt="reprocess" />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </Box>

                    {/* <Box
                      textAlign="center"
                      className="paymentTableBodyCell paymentsTableHeadSix"
                    >
                      &nbsp;
                    </Box> */}
                  </Box>
                </Collapse>
              </Box>
            );
          }
        )
      ) : (
        <Box className={classes.paymentsTableBody}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={`paymenyMobileFlex failedPayment paymentsTableRow`}
          >
            <Box className="paymentTableBodyCell paymentsTableHeadFirst">
              <Hidden only={['xl', 'lg', 'md']}>
                <span className="mobileHeading">Payment Method: </span>
              </Hidden>
              --
            </Box>

            <Box className="paymentTableBodyCell paymentsTableHeadSecond">
              <Hidden only={['xl', 'lg', 'md']}>
                <span className="mobileHeading">Date: </span>
              </Hidden>

              {/* {createdAt ? moment(createdAt).format('MM/DD/YYYY') : '--'} */}
              {installment.endDate
                ? moment(parseInt(installment.endDate)).format('MM/DD/YYYY')
                : null}
            </Box>

            <Box className="paymentTableBodyCell paymentsTableHeadThird">
              <Hidden only={['xl', 'lg', 'md']}>
                <span className="mobileHeading">Amount: </span>
              </Hidden>
              {planPrice}
              {/* {formatter.format(totalAmount ? totalAmount / 100 : 0)} */}
            </Box>

            <Box className="capitalizeText paymentTableBodyCell paymentsTableHeadFour">
              <Hidden only={['xl', 'lg', 'md']}>
                <span className="mobileHeading">Status: </span>
              </Hidden>
              {installment.status === InstallmentStatus.PENDING
                ? 'scheduled'
                : installment.status}
            </Box>

            {/* <Box className="capitalizeText paymentTableBodyCell paymentsTableHeadFive">
              <Hidden only={['xl', 'lg', 'md']}>
                <span className="mobileHeading">Invoice ID: </span>
              </Hidden>
              --
            </Box> */}
            <Box
              textAlign="center"
              className="paymentTableBodyCell paymentsTableHeadSix"
            >
              &nbsp;
            </Box>
          </Box>
        </Box>
      )}
      {user?.id ? (
        <ReprocessPaymentModal
          showModal={showModal}
          onClose={onClose}
          StudentId={user.id}
          amount={processingAmount}
          InvoiceId={InvoiceId}
          refetch={refetchInvoices}
        />
      ) : null}
    </>
  );
};
export default PaymentRow;
