import { FC, useEffect, useState } from 'react';

import { default as moment, default as momentTz } from 'moment-timezone';

import {
  Box,
  Button,
  Dialog,
  IconButton,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { Spinner } from 'react-bootstrap';

import AsyncSelect from 'react-select/async';

import { ICBProps } from '../../../../common/interfaces';
import { MEETING_CANCEL_REASONS } from '../../../../constants';

import { useStyles } from '../../../../styles/modalStyle';

import { checkForTime } from '../../../../common/utility';
import ModalCloseIcon from '../../../../img/dialog-close-updated.svg';
import { customTheme } from '../../../MaterializeCss';
import CancelAppointmentForOtherReason from './CancelAppointmentForOtherReason';

const CancelBookAppointment: FC<ICBProps> = ({
  title,
  firstButtonText,
  secondButtonText,
  open,
  onClose,
  loading,
  handleCancelBtn,
  bookingInfo,
  reason,
  setReason,
  otherReason,
  setOtherReason,
}) => {
  const classes = useStyles();
  const [hasTowHourPassed, setHasTwoHourPassed] = useState<boolean>(true);
  const loadOptions: any = () => {
    const options = MEETING_CANCEL_REASONS;
    return new Promise((resolve) => {
      resolve(options);
    });
  };
  const handleInputChange = (selectedValue: any) => {
    setReason(selectedValue.value);
  };
  const { createdAt, startTime } = bookingInfo;
  const startDate = startTime
    ? moment.unix(startTime).format('ddd MMM DD, YYYY')
    : 'N/A';
  const start = startTime ? moment.unix(startTime).format('LT') : 'NA';

  useEffect(() => {
    const result = checkForTime(createdAt);
    setHasTwoHourPassed(result);
  }, [createdAt]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.confirmationDialog}
      maxWidth="sm"
    >
      <Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Typography variant="h4">
            {hasTowHourPassed ? 'Cancel Meeting' : title}
          </Typography>
          {hasTowHourPassed && (
            <IconButton onClick={onClose}>
              <img src={ModalCloseIcon} alt="close" />
            </IconButton>
          )}
        </Box>
        <Typography
          variant="body1"
          className={hasTowHourPassed ? 'd-block subTitleText' : 'd-none'}
        >
          {`${startDate} - ${start}`}{' '}
          {momentTz(startDate).tz(momentTz.tz.guess()).format('z')}
        </Typography>
        <Typography
          variant="body1"
          className={!hasTowHourPassed ? 'd-block pt-3 pb-3' : 'd-none'}
        >
          This action cannot be undone.
        </Typography>

        {hasTowHourPassed ? (
          <>
            <Box className={classes.accountInformationForm} pt="21px">
              <Typography variant="body1" className="fieldCaption">
                Cancelation Reason
              </Typography>
              <Box pb="22px">
                <AsyncSelect
                  cacheOptions
                  placeholder="Select Cancelation Reason"
                  defaultOptions
                  loadOptions={loadOptions}
                  isClearable={true}
                  isSearchable={true}
                  classes={classes}
                  onChange={handleInputChange}
                  classNamePrefix="select"
                  className="customDropDown"
                />
              </Box>
            </Box>
            {reason === 'other' ? (
              <CancelAppointmentForOtherReason
                setOtherReason={setOtherReason}
                title="Note"
              />
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
        <ThemeProvider theme={customTheme}>
          <Box display="flex" justifyContent="flex-end">
            {secondButtonText && (
              <Button variant="outlined" className="mr-3" onClick={onClose}>
                {secondButtonText}
              </Button>
            )}
            <Button
              onClick={() =>
                handleCancelBtn && handleCancelBtn(reason, otherReason)
              }
              variant="contained"
              color="primary"
              disabled={
                loading ||
                (!reason && hasTowHourPassed) ||
                (reason === 'other' && !otherReason)
              }
              className="dangerButton"
              startIcon={loading && <Spinner animation="border" size="sm" />}
            >
              {loading
                ? firstButtonText === 'Cancel Meeting'
                  ? `canceling...`
                  : 'deleting...'
                : firstButtonText}
            </Button>
          </Box>
        </ThemeProvider>
      </Box>
    </Dialog>
  );
};

export default CancelBookAppointment;
