import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Typography,
} from '@material-ui/core';
import SnackbarComponent, { Color } from '../../../common/SnackBar';
import { CONNECT_ACCOUNT } from './gql';
import { Link } from 'react-router-dom';
import images from '../../../img';

import client from '../../../apollo';
import Loader from '../../../components/Loader';
import { cjConfig } from '../../../common/utility';

const ConnectAccount = () => {
  useEffect(() => {
    cjConfig({});
  }, []);

  const [names, setNames] = useState({
    SenderFullName: '',
    InviteeFirstName: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [severity, setSeverity] = useState<Color | undefined>('success');

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const sUUID = params.s;
  const iUUID = params.i;

  const connectAccount = () => {
    const runQuery = async () => {
      try {
        if (sUUID && iUUID) {
          setLoading(true);
          const { data } = await client.mutate({
            mutation: CONNECT_ACCOUNT,
            variables: { sUUID, iUUID },
          });
          let namesObj = {};
          if (data.connectAccount.SenderFullName) {
            namesObj = { SenderFullName: data.connectAccount.SenderFullName };
          }
          if (data.connectAccount.InviteeFirstName) {
            namesObj = {
              ...namesObj,
              InviteeFirstName: data.connectAccount.InviteeFirstName,
            };
          }

          setNames({ ...names, ...namesObj });

          setSeverity('success');
          setOpen(true);
          setMessage('Account Successfully Connected');
          setLoading(false);
        }
      } catch (err: any) {
        setSeverity('error');
        setOpen(true);
        setMessage(err?.graphQLErrors?.[0]?.message || err.message);
        setLoading(false);
      }
    };
    runQuery();
  };

  useEffect(connectAccount, []);

  const handleClose = () => {
    setOpen(false);
  };

  const { InviteeFirstName, SenderFullName } = names;

  const successHTML = `<b>${InviteeFirstName}</b>, you have successfully accepted your invitation to connect with <b>${SenderFullName}</b>.`;

  return loading ? (
    <Loader />
  ) : (
    <Container maxWidth={false}>
      <Box mt={4}>
        <Typography variant="h3">
          Invitation {severity === 'error' ? 'Rejected' : 'Accepted'}{' '}
          <img className="ml-2" src={images.MAIL_ICON} />
        </Typography>
        <Typography className="mt-4" variant="body1">
          {severity === 'error' ? (
            message
          ) : (
            <div dangerouslySetInnerHTML={{ __html: successHTML }}></div>
          )}
        </Typography>
        <Typography className="mt-4">
          <Link to="/applications"> Continue to your account</Link>
        </Typography>
      </Box>
      <SnackbarComponent
        message={message}
        open={open}
        handleClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={4000}
        severity={severity}
      />
    </Container>
  );
};

export default ConnectAccount;
