import { useContext } from 'react';
import { Box, Card } from '@material-ui/core';
import { Redirect } from 'react-router-dom';

import { AppContext } from '../../contexts';
import { useStyles } from './styles';
import RightSidePage from './LeadRegistration/RightSidePage';
import NewForgotPasswordForm from './NewForgotPasswordForm';

const NewForgotPasswordPage = (): JSX.Element => {
  const { user } = useContext(AppContext);
  const urlParams = new URLSearchParams(window.location.search);
  const goBack = urlParams.get('_goback');
  const classes = useStyles();

  return (
    <Box>
      {user &&
        (goBack ? (
          <Redirect to={goBack} />
        ) : !user.isParent ? (
          <Redirect to="/home" />
        ) : (
          <Redirect to="/" />
        ))}

      <Card className={`${classes.cardModal} custom-card`}>
        <Box className={classes.registerContainer}>
          <Box className={classes.registerLeft}>
            <NewForgotPasswordForm />
          </Box>
          <RightSidePage />
        </Box>
      </Card>
    </Box>
  );
};

export default NewForgotPasswordPage;
