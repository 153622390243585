import { Chip, MenuItem, NoSsr, Paper, Typography } from '@material-ui/core';
import {
  createStyles,
  emphasize,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { CSSProperties, FC, HTMLAttributes, useEffect, useState } from 'react';
import Select from 'react-select';
import { MultiValueProps } from 'react-select/src/components/MultiValue';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import colors from '../common/colors';

export interface OptionType {
  label: string;
  value: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    textField: {
      '& .css-1okebmr-indicatorSeparator': {
        display: 'none',
      },
    },
    input: {
      display: 'flex',
      padding: 0,
      height: '40px',
      '&:hover': {
        backgroundColor: colors.GRAY_BOREDER,
      },
      '&:active': {
        backgroundColor: colors.GRAY_EIGHT,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.GRAY_BOREDER,
      },
    },

    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
      paddingLeft: '10px',
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light'
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08
      ),
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2),
    },

    singleValue: {
      fontSize: 16,

      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        paddingRight: 20,
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',

        '& + div': {
          position: 'absolute',
        },
      },
    },

    paper: {
      backgroundColor: colors.WHITE_ONE,
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(0),
      left: 0,
      right: 0,
      borderRadius: '0px 0px 25px 25px',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',
    },
  })
);

function NoOptionsMessage(props: any) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
} as any;

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> &
  HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
} as any;

function Control(props: any) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      variant="outlined"
      fullWidth
      className={classes.textField}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
} as any;

function Option(props: any) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
} as any;

function Placeholder(props: any) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
} as any;

function SingleValue(props: any | SingleValueProps<OptionType>) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      noWrap
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
} as any;

function ValueContainer(props: any) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
} as any;

function MultiValue(props: any | MultiValueProps<OptionType>) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
} as any;

function Menu(props: any) {
  return (
    <Paper
      className={`timeZoneSelectCustom ${props.selectProps.classes.paper}`}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
} as any;

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

interface PropsType {
  suggestionsList: OptionType[];
  getValue: Function;
  label: string;
  value?: OptionType | OptionType[] | null;
  isMultiSelect: boolean;
  id?: number | null;
  disabled?: boolean;
  isClearable?: boolean;
}

const IntegrationReactSelect: FC<PropsType> = ({
  getValue,
  label,
  suggestionsList,
  isMultiSelect,
  value,
  id,
  disabled,
  isClearable,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [multi, setMulti] = useState<any>(value);

  const useEffectCallBack = () => {
    const optionValue = id
      ? suggestionsList.filter((option) => option.value === id.toString())
      : value;
    setMulti(optionValue);
  };

  useEffect(useEffectCallBack, [id]);

  function handleChangeMulti(value: any) {
    setMulti(value);
    if (getValue) getValue(value);
  }

  const selectStyles: any = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    <div className={classes.root}>
      <NoSsr>
        {
          <Select
            classes={classes}
            styles={selectStyles}
            inputId="react-select-multiple"
            options={suggestionsList}
            isDisabled={disabled}
            components={components}
            value={value ? multi : null}
            onChange={handleChangeMulti}
            isMulti={isMultiSelect}
            classNamePrefix="select"
            className="customDropDown"
            isClearable={!!isClearable}
          />
        }
      </NoSsr>
    </div>
  );
};

export default IntegrationReactSelect;
