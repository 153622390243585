import {
  Box,
  Container,
  Grid,
  Hidden,
  Theme,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FC, useEffect, useState } from 'react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import Image from '../../../components/Image';
import Loader from '../../../components/Loader';
import { GET_WEBINARS, HOME_PAGE_SERIES_QUERY } from '../../webinar/gql';
import {
  allHomePageSeries,
  allHomePageSeriesVariables,
} from '../../webinar/gql/__generated__/allHomePageSeries';
import WebinarThumbnailCarousel from '../../webinar/WebinarThumbnailCarousel';

import 'react-multi-carousel/lib/styles.css';
import { tealiumConstants } from '../../../common/constants';
import fonts from '../../../common/fonts';
import { createTealiumCall } from '../../../common/utility';
import Mobile_Arrow_Icon from '../../../img/arrow-icon-blue.svg';
import { getUserUpcomingAndPastWebinarsQuery_getUserUpcomingAndPastWebinars } from '../../webinar/gql/__generated__/getUserUpcomingAndPastWebinarsQuery';
import client from '../../../apollo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    myWebinarSliderSection: {
      backgroundColor: '#EBF3FE',
      padding: '30px',
      margin: '10px 0px 41px',

      [theme.breakpoints.down('md')]: {
        padding: '30px 0px',
      },

      '& h4': {
        paddingBottom: 25,
        display: 'flex',
        alignItems: 'center',

        '& p': {
          marginLeft: 20,
          color: '#62636B',
        },
      },
    },

    sliderSection: {
      margin: '20px 0px 50px',

      '& h4': {
        paddingBottom: 25,
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },

        '& img': {
          [theme.breakpoints.down('xs')]: {
            marginLeft: '8px !important',
          },
        },

        '& p': {
          marginLeft: 20,
          color: '#62636B',

          [theme.breakpoints.down('xs')]: {
            fontSize: 14,
            fontWeight: 600,
            textTransform: 'uppercase',
            color: '#2F80ED',
            fontFamily: fonts.POPPINS_FONT,
            margin: '10px 0px 0px',
          },
        },
      },
    },

    mobileText: {
      [theme.breakpoints.down('xs')]: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100% !important',
      },
    },

    mobileViewAll: {
      paddingBottom: 25,
      paddingRight: 10,

      '& img': {
        [theme.breakpoints.down('xs')]: {
          marginLeft: 10,
        },
      },

      '& h4': {
        padding: 0,
      },

      '& p': {
        [theme.breakpoints.down('xs')]: {
          fontSize: 14,
          fontWeight: 600,
          textTransform: 'uppercase',
          color: '#2F80ED',
          fontFamily: fonts.POPPINS_FONT,
          marginTop: 10,
        },
      },
    },

    mobileCarouselChopped: {
      [theme.breakpoints.down('xs')]: {
        paddingRight: '0px !important',
      },
    },
  })
);

const WebinarsList: FC = (): JSX.Element => {
  const classes = useStyles();
  const [searchQuery] = useState('');

  const urlParams = new URLSearchParams(window.location.search);
  const fromAdvisor = urlParams.get('adv');
  const [userWebinars, setUserWebinars] =
    useState<getUserUpcomingAndPastWebinarsQuery_getUserUpcomingAndPastWebinars | null>(
      null
    );

  const fetchUserWebinars =async()=>{
    try {
      const { data } = await client.query({
        query: GET_WEBINARS,
        fetchPolicy: 'network-only',
      });
      setUserWebinars(data?.getUserUpcomingAndPastWebinars);
    } catch (error) {
      console.log('Error in API calling:::', error);
    }
  }
  useEffect(() => {
    createTealiumCall({
      site_vertical: tealiumConstants.SITE,
      site_portal: tealiumConstants.WEBINARS,
      site_product: tealiumConstants.WEBINARS_PRODUCT,
      site_product_section: tealiumConstants.WEBINARS_PRODUCT_SECTION,
      page_type: tealiumConstants.WEBINARS_PAGE_TYPE,
      content_type: tealiumConstants.WEBINARS_CONTENT_TYPE,
    });
    fetchUserWebinars();
  }, []);

  return (
    <>
      <Query<allHomePageSeries, allHomePageSeriesVariables>
        query={HOME_PAGE_SERIES_QUERY}
        variables={{ searchQuery, isMBAGraduateProgram: true }}
        fetchPolicy="cache-and-network"
      >
        {({ data, loading, error }) => {
          if (loading) return <Loader />;
          if (error || !data || !data?.allHomePageSeries) return <>No Data</>;
          const {
            allHomePageSeries: { upcoming, recentlyAdded },
          } = data;
          return (
            <>
              {userWebinars?.upcoming?.length ? (
                <Grid className={classes.myWebinarSliderSection}>
                  <Container
                    maxWidth={false}
                    className={classes.mobileCarouselChopped}
                  >
                    <Hidden xsDown>
                      <Typography variant="h4">
                        <Box className={classes.mobileText}>
                          My Upcoming Webinars
                        </Box>

                        <Link to={`/my-webinars${window.location.search}`}>
                          <Typography variant="body1">View All</Typography>
                        </Link>
                      </Typography>
                    </Hidden>

                    <Hidden smUp>
                      <Box className={classes.mobileViewAll}>
                        <Typography variant="h4" className={classes.mobileText}>
                          My Upcoming Webinars
                        </Typography>
                        <Link to={`/my-webinars${window.location.search}`}>
                          <Typography variant="body1">
                            View All
                            <Image src={Mobile_Arrow_Icon} alt="arrow" />
                          </Typography>
                        </Link>
                      </Box>
                    </Hidden>

                    {userWebinars?.upcoming && (
                      <WebinarThumbnailCarousel
                        webinars={userWebinars?.upcoming}
                        fromAdvisor={!!fromAdvisor}
                      />
                    )}
                  </Container>
                </Grid>
              ) : (
                ''
              )}
              <Container
                maxWidth={false}
                className={classes.mobileCarouselChopped}
              >
                <Grid className={classes.sliderSection}>
                  <Typography variant="h4">
                    <Box className={classes.mobileText}>Upcoming Webinars</Box>
                    <Link to={`/webinars/upcoming${window.location.search}`}>
                      <Typography variant="body1">
                        View All
                        <Hidden smUp>
                          <Image
                            src={Mobile_Arrow_Icon}
                            alt="arrow"
                            loading="lazy"
                          />
                        </Hidden>
                      </Typography>
                    </Link>
                  </Typography>

                  <WebinarThumbnailCarousel
                    webinars={upcoming}
                    fromAdvisor={!!fromAdvisor}
                  />
                </Grid>

                <Grid className={classes.sliderSection}>
                  <Typography variant="h4">
                    <Box className={classes.mobileText}>Watch Previous</Box>
                    <Link
                      to={`/webinars/recently-added${window.location.search}`}
                    >
                      <Typography variant="body1">
                        View All
                        <Hidden smUp>
                          <Image
                            src={Mobile_Arrow_Icon}
                            alt="arrow"
                            loading="lazy"
                          />
                        </Hidden>
                      </Typography>
                    </Link>
                  </Typography>

                  <WebinarThumbnailCarousel
                    webinars={recentlyAdded}
                    fromAdvisor={!!fromAdvisor}
                  />
                </Grid>
              </Container>
            </>
          );
        }}
      </Query>
    </>
  );
};

export default WebinarsList;
