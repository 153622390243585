import React from 'react';

import {
  Box,
  ClickAwayListener,
  Hidden,
  IconButton,
  Theme,
  Tooltip,
  makeStyles,
} from '@material-ui/core';

import { TooltipProps } from '@material-ui/core/Tooltip';

import InfoIcon from '../../../img/rating-info-icon.svg';
import ActSatTooltipContent from './ActSatTooltipContent';
import colors from '../../../common/colors';
import fonts from '../../../common/fonts';

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    width: 22,
    height: 12,
    color: colors.WHITE_ONE,
    marginBottom: '-12px !important',
  },

  tooltip: {
    maxWidth: 427,
    borderRadius: 6,
    padding: '32px 33px',
    backgroundColor: colors.WHITE_ONE,
    border: `1px solid ${colors.GRAY_TWENTYTHREE}`,
    boxShadow: `0px 0px 1px ${colors.TOOLTIP_SHADOW_ONE}, 0px 40px 64px ${colors.TOOLTIP_SHADOW_TWO}`,

    '& .tooltipHeading': {
      '& b': {
        fontWeight: 700,
      },
    },

    '& .tooltipHeading, & .listText, & a': {
      fontSize: 16,
      fontWeight: 400,
      fontFamily: fonts.INTER,
      letterSpacing: '-0.011em',
      color: colors.BLACK_TWELVE,
    },

    '& ul': {
      margin: 0,
      padding: '20px 0px 0px 10px',

      '& li': {
        marginBottom: 9,
      },
    },
  },
}));

function BootstrapTooltip(props: TooltipProps) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export default function ActSatTooltip() {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Hidden only={['xs', 'sm']}>
        <BootstrapTooltip
          placement="top-start"
          title={<ActSatTooltipContent />}
        >
          <Box ml="12px" minWidth="26px">
            <IconButton className="tooltipHoverButton">
              <img src={InfoIcon} alt="info" />
            </IconButton>
          </Box>
        </BootstrapTooltip>
      </Hidden>

      <Hidden only={['md', 'lg', 'xl']}>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <BootstrapTooltip
            placement="top-start"
            PopperProps={{
              disablePortal: true,
            }}
            open={open}
            onClose={handleTooltipClose}
            title={<ActSatTooltipContent />}
          >
            <Box ml="12px" minWidth="26px">
              <IconButton
                className="tooltipHoverButton"
                onClick={handleTooltipOpen}
              >
                <img src={InfoIcon} alt="info" />
              </IconButton>
            </Box>
          </BootstrapTooltip>
        </ClickAwayListener>
      </Hidden>
    </>
  );
}
