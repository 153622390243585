import { FC, useState, useEffect, Fragment } from 'react';
import { Card, Box } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { useStyles } from '../styles';
import NewUserForm from './NewUserForm';
import Fonts from '../../../common/fonts';
import Colors from '../../../common/colors';
import LoginViewForm from './LoginViewForm';
import PasswordSetForm from './PasswordSetForm';
import CustomAlert from '../../../components/NewCustomAlert';
import GradMBARegistration from '../../graduateMBAProgram/gradMBARegistration';
import PasswordSetMBAForm from '../../graduateMBAProgram/PasswordSetMBAForm';

interface Props extends RouteComponentProps {
  closeModal?: Function;
  title?: string;
  partnerKey?: string;
  showBackButton?: Function;
  setBackClick?: Function;
  backClick?: Boolean;
  isMbaProgram?: Boolean;
}

const LeadRegisterForm: FC<Props> = ({
  closeModal,
  title,
  partnerKey,
  showBackButton,
  setBackClick,
  backClick,
  isMbaProgram = false,
}): JSX.Element => {
  const params = new URLSearchParams(window.location.search);
  const _goback = params.get('_goback');
  const isUpcoming = params.get('upcoming');
  const classes = useStyles();
  const [variant, setVariant] = useState('');
  const [message, setMessage] = useState('');
  const [passwordSetView, setPasswordSetView] = useState(false);
  const [loginView, setLoginView] = useState(false);
  const [leadViewData, setLeadViewData] = useState<any>();
  const [createdLeadId, setCreatedLeadId] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [parentorStudent, setParentOrStudent] = useState<'Student' | 'Parent'>(
    'Student'
  );
  const [optInSMS, setOptInSMS] = useState(true);

  const [initialValues, setInitialValues] = useState({
    studentFirstName: '',
    studentLastName: '',
    studentEmail: '',
    studentPhone: '',
    highSchoolYear: '',
    parentFirstName: '',
    parentLastName: '',
    parentEmail: '',
    parentPhone: '',
    gpa: '',
    optInSMS,
  });

  const [initialValuesForMBA, setInitialValuesForMBA] = useState({
    studentFirstName: '',
    studentLastName: '',
    studentEmail: '',
    studentPhone: '',
    undergraduateGradYear: '',
    undergraduateCollege: '',
  });

  const toggleAlert = (variant?: string, message?: string) => {
    setVariant(variant || '');
    setMessage(message || '');
  };

  if (!passwordSetView && !loginView) showBackButton && showBackButton(false);
  if (backClick) {
    if (passwordSetView) setPasswordSetView(false);
    if (loginView) setLoginView(false);
    setBackClick && setBackClick(false);
  }

  useEffect(() => {
    if (window.location.host === 'app.collegeadvisor.com') {
      if (window.ttq) {
        console.log('triggering ttq viewForm...');
        window.ttq.instance('C2CJLF7MU8Q03RAIPRC0').track('ViewForm');
      }
    }
  }, []);
  return (
    <Card className={`${classes.cardModal} custom-card`}>
      <CustomAlert
        variant={variant}
        message={message}
        toggleAlert={toggleAlert}
      />

      {(passwordSetView || loginView) === false && !isMbaProgram && (
        <NewUserForm
          {...{
            parentorStudent,
            partnerKey,
            setLoginView,
            showBackButton,
            setCreatedLeadId,
            setLeadViewData,
            toggleAlert,
            setPasswordSetView,
            title,
            setParentOrStudent,
            isLoading,
            setIsLoading,
            initialValues,
            setInitialValues,
            optInSMS,
            setOptInSMS,
          }}
        />
      )}

      {(passwordSetView || loginView) === false && isMbaProgram && (
        <GradMBARegistration
          {...{
            partnerKey,
            setLoginView,
            showBackButton,
            setCreatedLeadId,
            setLeadViewData,
            toggleAlert,
            setPasswordSetView,
            title,
            isLoading,
            setIsLoading,
            initialValuesForMBA,
            setInitialValuesForMBA,
          }}
        />
      )}

      {passwordSetView && isMbaProgram && (
        <PasswordSetMBAForm
          {...{
            toggleAlert,
            closeModal,
            parentorStudent,
            partnerKey,
            leadViewData,
            createdLeadId,
            _goback,
            isUpcoming,
          }}
        />
      )}

      {passwordSetView && !isMbaProgram && (
        <PasswordSetForm
          {...{
            toggleAlert,
            closeModal,
            parentorStudent,
            partnerKey,
            leadViewData,
            createdLeadId,
            _goback,
            isUpcoming,
            optInSMS,
          }}
        />
      )}

      {loginView && (
        <LoginViewForm
          closeModal={closeModal}
          _goback={_goback || ''}
          toggleAlert={toggleAlert}
        />
      )}

      {!loginView && (
        <Box
          fontSize={12}
          maxWidth={480}
          paddingTop={3.25}
          margin="0px auto"
          textAlign="center"
          fontFamily={Fonts.INTER}
          color={Colors.BLACK_ALPHA}
          className={classes.termsText}
        >
          By creating an account, you agree to the
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.collegeadvisor.com/terms-and-conditions/"
          >
            {isMbaProgram ? 'Terms of Service' : 'Terms and Conditions'}
          </a>{' '}
          {!isMbaProgram && (
            <Fragment>
              and
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.collegeadvisor.com/privacy-policy/"
              >
                Privacy Policy
              </a>
              .
            </Fragment>
          )}
        </Box>
      )}
    </Card>
  );
};

export default withRouter(LeadRegisterForm);
