import { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { AppContext } from '././../../contexts';
import RegisterForm from './RegisterForm';
import { PARTNER_PRODUCT_REGISTRATION } from '././../../constants';

const SalesRegisterForm = (): JSX.Element => {
  const { user } = useContext(AppContext);
  const [showBackBtn, setShowBackbtn] = useState(false);
  const [backBtnClick, setBackBtnClick] = useState(false);

  return (
    <Box>
      {user && (!user.isParent ? <Redirect to="/home" /> : <Redirect to="/" />)}
      <RegisterForm
        partnerKey={PARTNER_PRODUCT_REGISTRATION}
        showBackButton={setShowBackbtn}
        backClick={backBtnClick}
        setBackClick={setBackBtnClick}
      />
    </Box>
  );
};

export default SalesRegisterForm;
