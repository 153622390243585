import { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import gql from 'graphql-tag';
import client from '../../../apollo';
import { cjConfig } from '../../../common/utility';
interface TParams {
  id: string;
}

const MENTOR_CODE_QUERY = gql`
  query MentorCode($id: Int!) {
    mentorCode(input: { mentorId: $id }) {
      code
    }
  }
`;
const RedirectMentor: FC<RouteComponentProps<TParams>> = ({
  match: { params },
  history,
}) => {
  const search = window.location.search;
  const [code, setCode] = useState('');
  const id = parseInt(params?.id);
  const mountEffect = () => {
    const getMentorCode = async () => {
      try {
        if (id) {
          const query = {
            query: MENTOR_CODE_QUERY,
            variables: { id },
          };
          const response = await client.query(query);
          if (response?.data?.mentorCode?.code) {
            setCode(response?.data?.mentorCode?.code);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getMentorCode();
    cjConfig({});
  };
  useEffect(mountEffect, [id]);
  useEffect(() => {
    history.push(`/advisors/${id}/${code}${search}`);
  }, [code]);
  return <></>;
};

export default RedirectMentor;
