import { FC } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from '../../../../styles/modalStyle';
import { OtherReasonProps } from '../../../../common/interfaces';

const CancelAppointmentForOtherReason: FC<OtherReasonProps> = ({ setOtherReason, title }) => {
    const classes = useStyles();
    const handleOtherReason = (event: any) => {
        if (!!setOtherReason) {
            setOtherReason(event.target.value);
        }
    }
    return (
        <>
            <Box className={classes.accountInformationForm}>
                <Typography variant='body1' className='fieldCaption'>
                    {title}
                </Typography>
                <TextareaAutosize rowsMin={3} className={classes.otherReason} placeholder='Enter details here.' onChange={handleOtherReason} />

            </Box>
        </>
    )
}

export default CancelAppointmentForOtherReason;