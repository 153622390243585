import {
  createStyles,
  makeStyles,
  Grid,
  Theme,
  CircularProgress,
} from '@material-ui/core';
import Images from '../img';

const useStylesFacebook = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },

    bottom: {
      color: '#EAEAEA',
    },

    top: {
      color: '#2F80ED',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },

    top_completed: {
      color: '#E94444',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },

    circle: {
      strokeLinecap: 'round',
    },
    forIconPosition: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 20,

      '& img': {
        position: 'absolute',
        margin: '0px auto',
      },
    },
  })
);

export default function FacebookCircularProgress({
  progress,
}: {
  progress: number;
}) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <Grid className={classes.forIconPosition}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={56}
          thickness={4}
          value={100}
        />
        {progress === 100 ? (
          <img src={Images.RED_VIDEO_ICON} alt="monitor" />
        ) : (
          <img src={Images.VIDEO_ICON} alt="monitor" />
        )}
      </Grid>

      <CircularProgress
        variant="determinate"
        disableShrink
        className={progress === 100 ? classes.top_completed : classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={56}
        thickness={4}
        value={progress}
      />
    </div>
  );
}
