import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Colors from '../../common/colors';
import Fonts from '../../common/fonts';

export const footerStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerWrapper: {
      padding: '61px 0px',
      borderTop: '1px solid #D4D4D4',
      background: `${Colors.WHITE_ONE}`,

      [theme.breakpoints.down('sm')]: {
        padding: '24px 0px 0px',
      },
    },

    footerCopyRight: {
      fontWeight: 400,
      fontSize: 14,
      color: '#7D7F7F',
      letterSpacing: '-0.006em',
      fontFamily: Fonts.INTER,

      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },

    footerMobilePadding: {
      paddingLeft: 17,
    },

    footerNavLinks: {
      fontSize: 16,
      fontWeight: 400,
      color: '#2F80ED',
      marginBottom: 11,
      display: 'block',
      cursor: 'pointer',
      letterSpacing: '-0.011em',
      fontFamily: Fonts.INTER,

      '&:hover': {
        opacity: 0.8,
        transition: '0.5s',
        textDecoration: 'none',
        color: Colors.BLUE_SEVEN,
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },

    footerLogoMobile: {
      textAlign: 'center',
      padding: '32px 0px 30px',
      borderTop: `1px solid ${Colors.GRAY_EIGHT}`,

      [theme.breakpoints.down('xs')]: {
        padding: '15px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'right',
      },
    },

    footerLogo: {
      maxWidth: 157,
      marginBottom: 13,

      [theme.breakpoints.down('xs')]: {
        maxWidth: 140,
      },
    },

    socialIcons: {
      paddingTop: 15,

      '& a': {
        '&:not(:last-child)': {
          marginRight: 20,
        },

        '&:hover': {
          opacity: 0.5,
          transition: 'all .4s ease',
        },
      },

      '& img': {
        width: 20,
      },
    },

    helpPopover: {
      '& .title, & .subTitle': {
        color: '#272929',
      },

      '& .title': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        fontFamily: Fonts.INTER,
      },

      '& .subTitle': {
        maxWidth: 280,
        fontWeight: 400,
        fontSize: 14,
        margin: '14px 0px',
        letterSpacing: '-0.006em',
        fontFamily: Fonts.INTER,
      },

      '& .phoneNumber': {
        fontWeight: 600,
        color: '#272929',
      },

      '& .emailto': {
        color: '#2F80ED',
        fontWeight: 600,
      },

      '& .subHeading': {
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '-0.006em',
        color: '#272929',
        fontFamily: Fonts.INTER,
      },

      '& .dateText': {
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        marginBottom: 19,
        fontFamily: Fonts.INTER,
        textTransform: 'capitalize',
      },
    },
  })
);

export const helpSupportStyle = makeStyles((theme: Theme) =>
  createStyles({
    helpBadgeContainer: {
      '&:hover': {
        '& #profileBadgeMenu': {
          display: 'block',
        },
      },

      '& .helpButton': {
        marginLeft: 12,
      },
    },

    helpBadgeMenu: {
      display: 'none',
      cursor: 'auto',
      right: 0,
      paddingTop: 14,

      '& a': {
        maxWidth: 'fit-content',
      },
    },

    helpPopover: {
      width: 351,
      borderRadius: 15,
      background: '#FFFFFF',
      padding: '30px 25px 25px',
      border: '1px solid #D4D4D4',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },

      '& .title, & .subTitle': {
        color: '#272929',
      },

      '& .title': {
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        fontFamily: Fonts.POPPINS_FONT,
      },

      '& .subTitle': {
        maxWidth: 280,
        fontWeight: 400,
        fontSize: 14,
        margin: '14px 0px',
        letterSpacing: '-0.006em',
        fontFamily: Fonts.INTER,
      },

      '& .phoneNumber': {
        fontWeight: 600,
        color: '#272929',
      },

      '& .emailto': {
        color: '#2F80ED',
        fontWeight: 600,
      },

      '& .subHeading': {
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '-0.006em',
        color: '#272929',
        fontFamily: Fonts.INTER,
      },

      '& .dateText': {
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        marginBottom: 19,
        fontFamily: Fonts.INTER,
      },

      '& .contactUs': {
        minWidth: 111,
        height: 40,
        background: '#45CE93',
        borderRadius: 12,
        fontWeight: 700,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'capitalize',
        fontFamily: Fonts.POPPINS_FONT,
      },
    },
  })
);
