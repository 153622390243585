import {
  KeyboardEvent,
  MouseEvent,
  ChangeEvent,
  FC,
  useContext,
  useState,
  useMemo,
} from 'react';

import { Link } from 'react-router-dom';

import { Formik, Form } from 'formik';
import { Mutation } from 'react-apollo';

import {
  RadioGroup,
  FormControl,
  Checkbox,
  Grid,
  Button,
  makeStyles,
  createStyles,
  Theme,
  ThemeProvider,
  Box,
} from '@material-ui/core';

import { AppContext } from '../../../../contexts';
import {
  CreateStudentApplicationMutation,
  CreateStudentApplicationMutationVariables,
} from './__generated__/CreateStudentApplicationMutation';
import { CREATE_STUDENT_APPLICATION_MUTATION } from './AddCollegeForm';
import { AllCollegesQuery_allColleges_colleges } from './__generated__/AllCollegesQuery';

import { CollegeLogo } from '../../../../styles/onBoard';

import Fonts from '../../../../common/fonts';
import Colors from '../../../../common/colors';

import styled from 'styled-components';
import { customTheme } from '../../../MaterializeCss';
import { AddMyColleges, BadgeNumber } from '../../../../styles/common';

import PLUS_ADD_IMG from '../../../../img/add-plus-icon.svg';
import CollegeDefaultImg from '../../../../img/college-default-img.svg';
import { Spinner } from 'react-bootstrap';
import SnackbarComponent from '../../../../common/SnackBar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: 0,
      width: '100%',

      '& a': {
        fontFamily: Fonts.POPPINS_FONT,
      },
    },

    collegeIcon: {
      margin: '0px',
    },

    acceptedBadge: {
      width: 42,
      height: 42,
      backgroundColor: Colors.WHITE_ONE,
      border: `1px solid ${Colors.GRAY_NINE}`,
      borderRadius: '50px',
      padding: '0px',
      margin: '0px 15px 0px 0px',
      lineHeight: '39px',
      textAlign: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 30,
      backgroundPosition: 'center',
    },
  })
);

const CollegeCategory = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0px;
  margin-right: 23px;

  @media only screen and (max-width: 767px) {
    flex: 1;
  }

  &:last-child {
    border-bottom: none;
  }

  svg {
    width: 27px !important;
    height: 27px !important;

    & path {
      fill: #e0e0e0;
    }
  }

  .Mui-checked {
    svg {
      & path {
        fill: #2f80ed;
      }
    }
  }

  label {
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.3375px;
    color: ${Colors.BLACK_ONE};
    text-align: left;
    line-height: 23px;
  }

  .MuiSvgIcon-root {
    font-size: 27px;
  }

  .MuiCheckbox-root {
    color: ${Colors.GRAY_NINE};
    margin-left: -12px;
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: ${Colors.BLUE_SEVEN};
  }
`;

const CheckColleges = styled.div``;

const UniversityAdd = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Colors.GRAY_EIGHT};
  padding-left: 15px;

  .addCollegesButton {
    visibility: hidden;
  }

  &:hover {
    .addCollegesButton {
      visibility: visible;
    }
  }

  @media only screen and (max-width: 767px) {
    button {
      padding: 0px !important;
      margin: 0;
      height: 32px;
      width: 32px;
      min-width: auto;

      .button-text {
        display: none;
      }

      img {
        margin: 0 !important;
      }
    }
  }
`;

interface Props {
  colleges: (AllCollegesQuery_allColleges_colleges | null)[] | null;
  isPage: boolean;
  isCollegeAdded?: Function;
  isSearching?: boolean;
  refetch?: Function;
  isDrawerOpen?: boolean;
}

let emptyArray: number[] = [];

const OtherColleges: FC<Props> = ({
  colleges,
  isPage,
  isCollegeAdded,
  refetch,
  isDrawerOpen,
}) => {
  const classes = useStyles();

  const appContext = useContext(AppContext);
  const [snackOpen, setSnackOpen] = useState<boolean>(false);
  const [state, setState] = useState({
    collegeIds: emptyArray,
  });
  /**
   * @author Farhan Amin
   * @param  void
   */
  const addCollegesToState = useMemo(() => {
    return (collegeIds: number[], isSubmitting: boolean) => {
      if (state?.collegeIds?.length < 5) {
        isSubmitting
          ? setState({ ...state, collegeIds: [] })
          : setState({ collegeIds });
      } else {
        setSnackOpen(true);
      }
    };
  }, [state, setSnackOpen]);

  if (!colleges || !colleges.length)
    return <p className="text-center p-2">No Colleges Found</p>;

  const initialValues = { studentId: 1, collegeIds: [] };
  const currentUser = appContext && appContext.user;
  const { collegeIds } = state;
  emptyArray = !isDrawerOpen ? [] : collegeIds;
  const handleChange =
    (id: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { checked },
      } = event;
      const collegeIds = [...state.collegeIds];
      if (!checked) {
        const index = collegeIds.indexOf(id);
        if (index > -1) collegeIds.splice(index, 1);
      } else collegeIds.push(id);
      addCollegesToState(collegeIds, false);
    };

  const addCollegeToList =
    (id: number) => (event: KeyboardEvent | MouseEvent) => {
      const collegeIds = [...state.collegeIds];
      const index = collegeIds.indexOf(id);
      if (index < 0) collegeIds.push(id);
      addCollegesToState(collegeIds, false);
    };

  return (
    <>
      <Grid className="collegeScrollContent collegeScroll">
        <CheckColleges className={isPage ? 'checkcollegesPadding' : ''}>
          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup>
              {colleges.map((college, i) => {
                if (!college) return null;
                const { id, name, applicationCreated, logo } = college;
                const isChecked = collegeIds.indexOf(id) > -1;
                const backgroundImage = `url( ${logo || CollegeDefaultImg} )`;
                return (
                  <UniversityAdd
                    key={i}
                    className={applicationCreated ? 'disable-college-card' : ''}
                  >
                    <CollegeCategory>
                      {currentUser && (
                        <Checkbox
                          onChange={handleChange(id)}
                          value={id}
                          checked={isChecked || !!applicationCreated}
                          disabled={!!applicationCreated}
                          color="primary"
                        />
                      )}
                      <CollegeLogo className={classes.collegeIcon}>
                        <Grid
                          className={classes.acceptedBadge}
                          style={{
                            backgroundImage,
                          }}
                        />
                      </CollegeLogo>
                      <Link to={`colleges/${id}`}>{name}</Link>
                    </CollegeCategory>
                    {!isChecked && !applicationCreated && currentUser && (
                      <ThemeProvider theme={customTheme}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className="addCollegesButton"
                          onClick={addCollegeToList(id)}
                        >
                          <img src={PLUS_ADD_IMG} alt="" />
                          Add to Applications
                        </Button>
                      </ThemeProvider>
                    )}
                  </UniversityAdd>
                );
              })}
            </RadioGroup>
          </FormControl>
        </CheckColleges>
      </Grid>

      {!!collegeIds.length && currentUser && (
        <AddMyColleges style={{ marginLeft: 0 }}>
          <h6>
            <BadgeNumber>{collegeIds.length}</BadgeNumber> Colleges Selected
          </h6>

          <Mutation<
            CreateStudentApplicationMutation,
            CreateStudentApplicationMutationVariables
          >
            mutation={CREATE_STUDENT_APPLICATION_MUTATION}
          >
            {(createStudentApplicationMutation: Function) => {
              return (
                <Formik
                  initialValues={initialValues}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      const result = await createStudentApplicationMutation({
                        variables: { collegeIds },
                      });
                      const {
                        data: {
                          createStudentApplication: { status },
                        },
                      } = result;
                      setSubmitting(false);

                      if (status) {
                        isCollegeAdded && isCollegeAdded(true);
                        refetch && refetch();
                        addCollegesToState(collegeIds, true);
                      } else alert('Colleges not added');
                    } catch (error) {
                      isCollegeAdded && isCollegeAdded(true);
                      console.log('error: ', error);
                      setSubmitting(false);
                    }
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <SnackbarComponent
                        message={'You can add only 5 colleges at a time.'}
                        open={snackOpen}
                        severity={'error'}
                        autoHideDuration={2000}
                        handleClose={() => setSnackOpen(false)}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      />
                      <ThemeProvider theme={customTheme}>
                        <Box ml={2}>
                          <Button
                            type="submit"
                            variant="outlined"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <Spinner size="sm" animation="border" />
                            ) : (
                              <>Add to Applications</>
                            )}
                          </Button>
                        </Box>
                      </ThemeProvider>
                    </Form>
                  )}
                </Formik>
              );
            }}
          </Mutation>
        </AddMyColleges>
      )}
    </>
  );
};

export default OtherColleges;
