import { FC, useContext } from 'react';
import { Grid, Box, Typography, Divider, Button } from '@material-ui/core';
import CallIcon from '../../../img/call-icon.svg';
import { useStyles } from './styles';
import MEETING from '../../../img/meeting.svg';
import { AppContext } from '../../../contexts';
const FreeUser: FC = () => {
  const { user } = useContext(AppContext);
  const classes = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12} lg={12} xs={12}>
          <Box>
            <Typography variant="h4">My Plan</Typography>
          </Box>
          <Divider />
        </Grid>
        <Grid item md={12} lg={12} xs={12}>
          <Typography className={classes.freeUserUpgrade} variant="h4">
            Upgrade Today!
          </Typography>
        </Grid>
        <Grid item md={12} lg={12} xs={12}>
          <Box paddingLeft="20px">
            {(user?.highSchoolYear === (currentYear + 1).toString() ||
              user?.highSchoolYear === (currentYear + 2).toString() ||
              user?.highSchoolYear === (currentYear + 3).toString()) &&
            !user?.isPremium ? (
              <Typography variant="body1">
                Schedule a free strategy meeting with an Admissions Specialist
                to learn more
              </Typography>
            ) : (
              <Typography variant="body1">
                Talk to one of our Admissions Coordinators to learn more
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      {(user?.highSchoolYear === (currentYear + 1).toString() ||
        user?.highSchoolYear === (currentYear + 2).toString() ||
        user?.highSchoolYear === (currentYear + 3).toString()) &&
      !user?.isPremium &&
      !user.isMBAGrad ? (
        <Box className={classes.callNowGrid} style={{ maxWidth: '550px' }}>
          <a href="/schedule">
            <Button variant="contained" type="submit" className="warningButton">
              <img src={MEETING} alt="" style={{ paddingRight: '10px' }} />
              Schedule a Meeting
            </Button>
          </a>

          <a href="tel:+1 (844) 343-6272">
            <Typography variant="h5" className={classes.callNowNumber}>
              &nbsp; or call &nbsp; +1 (844) 343-6272
            </Typography>
          </a>
        </Box>
      ) : (
        <Box className={classes.callNowGrid}>
          <a href="tel:+1(844)343-6272">
            <Button
              variant="contained"
              type="submit"
              className="warningButton"
              fullWidth
            >
              <img src={CallIcon} alt={'Call now'}></img>
              Call Now
            </Button>
          </a>

          <Typography variant="h5" className={classes.callNowNumber}>
            +1 (844) 343-6272
          </Typography>
        </Box>
      )}
    </>
  );
};

export default FreeUser;
