import React, {
  useState,
  FC,
  useContext,
  useRef,
  useEffect,
  Fragment,
} from 'react';
import clsx from 'clsx';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import gql from 'graphql-tag';
import {
  Grid,
  CircularProgress,
  Button,
  Box,
  InputBase,
} from '@material-ui/core';
import { GoPayment } from '../../../styles/common';
import SECURED_IMG from '../../../img/lock-2.svg';
import Colors from '../../../common/colors';
import { TextFieldStyle } from '../../../styles/common';
import { MentorOrderContext } from '../../../contexts';
import {
  AddUserCreditCardMutation,
  AddUserCreditCardMutationVariables,
} from './__generated__/AddUserCreditCardMutation';
import { Mutation } from 'react-apollo';
import { scrollToBottom } from '../../../common/utility';
import CustomAlert from '../../../components/CustomAlert';
import Fonts from '../../../common/fonts';
import {
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js';
import { stripeFormStyles } from './styles';

const CreditCardInfo = styled.div`
  background: ${Colors.GRAY_TEN};
  border-radius: 4px;
  padding: 30px 30px 36px;

  @media only screen and (max-width: 767px) {
    padding: 15px 15px 25px;
  }

  h6 {
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.3375px;
    text-transform: uppercase;
    color: ${Colors.BLACK_THREE};
    opacity: 0.5;
    margin: 0px;
  }

  .StripeElement {
    background: ${Colors.YELLOW};
    height: 46px;

    iframe {
      height: 46px !important;
    }
  }

  .StripeElement {
    font-family: ${Fonts.POPPINS_FONT} !important;
    height: 41px;
    padding: 10px 0px;
    border: none;
    border-bottom: 1px solid ${Colors.GRAY_ELEVEN};
    border-radius: 0px;
    background-color: transparent;
    box-shadow: none;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    font-size: 24px;
    color: ${Colors.BLACK_SIX};
  }

  input {
    font-family: ${Fonts.POPPINS_FONT} !important;
    font-size: 24px;
    color: ${Colors.BLACK_SIX};
  }

  input:placeholder {
    font-family: ${Fonts.POPPINS_FONT} !important;
    font-size: 24px;
    color: ${Colors.BLACK_SIX};
  }

  .StripeElement--focus {
    border-bottom: 1px solid ${Colors.BLUE_SEVEN};
  }

  .StripeElement--invalid {
    border-color: ${Colors.GRAY_SIXTEEN};
  }

  .StripeElement--webkit-autofill {
    background-color: ${Colors.GRAY_SEVENTEEN} !important;
  }
`;

const Secured = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 21px;

  @media only screen and (max-width: 767px) {
    flex-direction: column !important;
    margin-top: 0;

    .add-btn-container {
      margin-top: 20px !important;
    }
  }

  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.3375px;
    text-transform: uppercase;
    color: ${Colors.BLACK_THREE};
    margin: 5px 0px 0px 10px;
  }
`;

const ADD_USER_CREDIT_CARD_MUTATION = gql`
  mutation AddUserCreditCardMutation($sourceId: String!) {
    addUserCreditCard(input: { sourceId: $sourceId }) {
      status
    }
  }
`;

interface Props {
  isBillingPage?: boolean;
  refetch: Function;
}

const StripeForm: FC<Props> = ({ isBillingPage, refetch }) => {
  const { orderItems, mentor, setMentor } = useContext(MentorOrderContext);

  const initialValues = {};

  const [cardNumber, setCardNumber] = useState<boolean>(false);
  const [zip, setZip] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cardExpiry, setCardExpiry] = useState<boolean>(false);
  const [cardCvc, setCardCvc] = useState<boolean>(false);

  const [cardNumberMessage, setCardNumberMessage] = useState<String>('');
  const [cardExpiryMessage, setCardExpiryMessage] = useState<String>('');
  const [cardCvcMessage, setCardCvcMessage] = useState<String>('');

  const [variant, setVariant] = useState('');
  const [message, setMessage] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  function toggleAlert(_variant?: string, _message?: string) {
    setVariant(_variant || '');
    setMessage(_message || '');
  }

  const onChange = (card: any) => {
    switch (card.elementType) {
      case 'card':
        setCardNumber(card.complete);
        card.error !== undefined
          ? setCardNumberMessage(card.error.message)
          : setCardNumberMessage('');
        break;
      case 'cardExpiry':
        setCardExpiry(card.complete);
        card.error !== undefined
          ? setCardExpiryMessage(card.error.message)
          : setCardExpiryMessage('');
        break;
      case 'cardCvc':
        setCardCvc(card.complete);
        card.error !== undefined
          ? setCardCvcMessage(card.error.message)
          : setCardCvcMessage('');
        break;
      default:
        break;
    }
  };

  const renderZipField = () => {
    return (
      <Box>
        <InputBase
          placeholder="Zip Code"
          className={classes.zipCodeField}
          value={zip}
          name="zip"
          onChange={(e: any) => {
            const {
              currentTarget: { value },
            } = e;
            setZip(value);
          }}
        />
      </Box>
    );
  };

  const classes = stripeFormStyles();
  const timer = useRef<number>();

  useEffect(() => {
    const timerCurrent = timer.current;
    return () => {
      clearTimeout(timerCurrent);
    };
  }, []);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: false,
  });

  return (
    <Mutation<AddUserCreditCardMutation, AddUserCreditCardMutationVariables>
      mutation={ADD_USER_CREDIT_CARD_MUTATION}
    >
      {(addCardMutation: Function) => {
        return (
          <Fragment>
            <Grid className="align-items-center">
              <CustomAlert
                variant={variant}
                message={message}
                toggleAlert={toggleAlert}
              />
            </Grid>
            <Formik
              initialValues={initialValues}
              onSubmit={async (values, { setSubmitting }) => {
                if (!stripe || !elements) {
                  // Stripe.js has not loaded yet. Make sure to disable
                  // form submission until Stripe.js has loaded.
                  return;
                }

                if (stripe) {
                  setIsLoading(true);
                  const cardElement = elements.getElement(CardElement);

                  if (cardElement) {
                    const paymentMethod = await stripe.createSource(
                      cardElement,
                      {
                        type: 'card',
                        currency: 'usd',
                        owner: {
                          address: {
                            postal_code: zip,
                          },
                        },
                      }
                    );

                    const { source, error } = paymentMethod;

                    if (error) {
                      setIsLoading(false);
                      setSubmitting(false);
                      toggleAlert('danger', error.message);
                    }

                    if (source) {
                      const { id: sourceId, card } = source;
                      await addCardMutation({
                        variables: { sourceId },
                      });

                      setIsLoading(false);
                      setSubmitting(false);

                      if (orderItems && card && !isBillingPage) {
                        const cardNumber = card.last4;
                        const cardBrand = card.brand;
                        setMentor(
                          {
                            ...mentor,
                            sourceId,
                            cardNumber,
                            cardBrand,
                          },
                          orderItems
                        );
                        scrollToBottom();
                      }
                      refetch();
                    }
                  }
                }
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <CreditCardInfo>
                    <TextFieldStyle>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          md={12}
                          sm={12}
                          xs={12}
                          justify="space-between"
                          wrap="nowrap"
                          container
                        >
                          <Grid className="mr-2" md={10} sm={10} xs={10}>
                            <CardElement
                              options={{ hidePostalCode: true }}
                              onChange={onChange}
                            />
                          </Grid>
                          <Grid
                            className="ml-2"
                            md={2}
                            sm={2}
                            xs={2}
                            justify="flex-end"
                          >
                            <Box>{renderZipField()}</Box>
                          </Grid>

                          <label>{cardNumberMessage}</label>
                        </Grid>
                      </Grid>
                    </TextFieldStyle>

                    <Secured>
                      <Grid item className="d-flex align-items-center">
                        <img src={SECURED_IMG} alt="" />
                        <label>Secured by Stripe</label>
                      </Grid>

                      <GoPayment className="m-0 add-btn-container">
                        <Button
                          variant="contained"
                          color="secondary"
                          className={buttonClassname}
                          disabled={isSubmitting || !cardNumber}
                          type="submit"
                        >
                          Add Card
                          {isLoading && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </Button>
                      </GoPayment>
                    </Secured>
                  </CreditCardInfo>
                </Form>
              )}
            </Formik>
          </Fragment>
        );
      }}
    </Mutation>
  );
};

export default StripeForm;
