export default {
  POPPINS_FONT: 'Poppins, sans-serif',
  INTER: 'Inter, sans-serif',
  RUBIK_FONT: 'Rubik',
  LATO_FONT: 'Lato',
  CABIN_FONT: 'Cabin',
  ARCHIVO_FONT: 'Archivo',
  METROPOLIS_FONT: 'Metropolis-Regular',
  METROPOLIS_BOLD_FONT: 'Metropolis-Bold',
  METROPOLIS_SEMI_BOLD_FONT: 'Metropolis-SemiBold',
};
