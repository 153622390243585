// imports
import { FC, Fragment, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
// styles
import { homePageStyles } from './styles';
// images
import InfoIcon from '../../../img/info-graduated-icon.svg';
import { MilestoneAlertProps } from '../../../common/interfaces';

const VideoChatHoursUsedAlert: FC<MilestoneAlertProps> = ({
  highSchoolYear,
}) => {
  const classes = homePageStyles();

  return (
    <Box className={classes.videoChatHoursUsed}>
      <img src={InfoIcon} alt="info" />

      <Box width="100%">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h4">
            You have no video chat hours remaining
          </Typography>
        </Box>

        <Typography variant="body1">
          Please contact your advisor to discuss adding more time to your
          package.
        </Typography>
      </Box>
    </Box>
  );
};

export default VideoChatHoursUsedAlert;
