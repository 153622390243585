import Button from '@material-ui/core/Button';
import { Badge } from 'react-bootstrap';
import styled from 'styled-components';
import Colors from '../common/colors';
import Fonts from '../common/fonts';

export const ButtonFiles = styled(Button)`
  && {
    background: ${Colors.BLUE_SEVEN};
    border-radius: 100px;
    padding: 8px 19px;
    font-family: ${Fonts.POPPINS_FONT};
    font-weight: 600;
    font-size: 12px;
    color: ${Colors.WHITE_ONE};
    box-shadow: none;
    line-height: 16px;
    margin-left: 25px;

    @media only screen and (max-width: 767px) {
      margin-left: 0;
    }

    &:hover {
      background: ${Colors.BLUE_SEVEN};
      opacity: 0.9;
    }

    &:disabled {
      color: ${Colors.GRAY_EIGHTEEN};
      background: ${Colors.GRAY_NINETEEN};
    }
  }
`;

type buttonProps = {
  bgColor?: string;
};

export const ButtonReview = styled(Button)`
  && {
    background: ${Colors.GREEN_THREE};
    background: ${(props: buttonProps) => props.bgColor || Colors.GREEN_THREE};
    border-radius: 100px;
    padding: 8px 18px;
    font-family: ${Fonts.POPPINS_FONT};
    font-weight: 600;
    font-size: 12px;
    color: ${Colors.WHITE_ONE} !important;
    box-shadow: none;
    line-height: 16px;
    margin-left: 10px;

    &:hover {
      background: ${Colors.GREEN_THREE};
      opacity: 0.9;
    }

    &:disabled,
    &.Mui-disabled {
      color: ${Colors.GRAY_TWELVE};
      background-color: ${Colors.GRAY_EIGHT};
    }
  }
`;

export const ButtonOutlineDark = styled(Button)`
  && {
    font-family: ${Fonts.POPPINS_FONT};
    background-color: transparent;
    font-weight: 600;
    font-size: 12px;
    height: 32px;
    color: ${Colors.BLACK_SIX};
    letter-spacing: 0.3375px;
    padding: 0px 16px;
    box-shadow: none;
    border-radius: 100px;
    border: 1px solid ${Colors.BLACK_SIX};
    line-height: 16px;

    &:hover {
      color: ${Colors.WHITE_ONE};
      background: ${Colors.BLACK_SIX};
      border: 1px solid ${Colors.BLACK_SIX};
      opacity: 0.9;
    }
  }
`;

export const AddMyColleges = styled.div`
  background-color: ${Colors.BLUE_SEVEN};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 21px;
  position: fixed;
  bottom: 0px;
  z-index: 99;
  width: 100vw;
  left: 0px;

  @media only screen and (max-width: 767px) {
    padding: 21px 15px;
  }

  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }

  h6 {
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.77px;
    color: ${Colors.WHITE_ONE};
    margin: 0px;

    @media only screen and (max-width: 420px) {
      margin-bottom: 10px;
    }

    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
`;

export const BadgeNumber = styled(Badge)`
  background: ${Colors.WHITE_ONE};
  border-radius: 20px;
  padding: 2px 11px;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.77px;
  color: ${Colors.BLACK_SIX};
  font-weight: normal;
  font-family: ${Fonts.POPPINS_FONT};
  margin-right: 3px;
`;

export const GoPayment = styled.div`
  margin-top: 24px;
  text-align: center;

  @media only screen and (max-width: 767px) {
    margin-top: 15px;
  }

  button {
    font-family: ${Fonts.POPPINS_FONT};
    background: ${Colors.GREEN_EIGHT};
    border-radius: 10px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    color: ${Colors.WHITE_ONE};
    text-transform: capitalize;
    margin: 0px;
  }

  .MuiButton-contained:hover {
    background: ${Colors.GREEN_EIGHT};
    opacity: 0.8;
  }

  .MuiButton-contained.Mui-disabled {
    color: ${Colors.GRAY_TWELVE}
    background-color: ${Colors.GRAY_EIGHT};
  }
`;

export const TextFieldStyle = styled.div`
  .MuiFormHelperText-root.Mui-error {
    font-family: ${Fonts.POPPINS_FONT};
  }

  .MuiInputLabel-animated {
    color: ${Colors.GRAY_TWO};
  }

  .MuiInputBase-input {
    line-height: 36px;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;

    .MuiFormControl-fullWidth {
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${Colors.BLUE_SEVEN};
  }

  .MuiInputLabel-formControl {
    font-family: ${Fonts.POPPINS_FONT};
    color: ${Colors.GRAY_TWO};
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.172549px;
    margin-top: -8px;

    @media only screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

  .MuiInputLabel-shrink {
    color: ${Colors.BLUE_SEVEN};
    font-family: ${Fonts.POPPINS_FONT};
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.172549px;
    margin: -5px 0 0;
  }

  .MuiInputBase-root {
    font-family: ${Fonts.POPPINS_FONT};
    font-size: 24px;
    height: 45px;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid ${Colors.GRAY_ELEVEN};
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid ${Colors.GRAY_ELEVEN};
  }

  .MuiInput-underline:after {
    border-bottom: 1px solid ${Colors.BLUE_SEVEN};
  }

  .MuiFilledInput-underline:after {
    border-bottom: 1px solid ${Colors.BLUE_SEVEN};
  }

  .MuiFilledInput-underline:before {
    border-bottom: 1px solid ${Colors.GRAY_ELEVEN};
  }
`;
