import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  ListItemIcon,
  MenuItem,
  Typography,
  withStyles,
} from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import React, { useState } from 'react';
import CLOSE_ICON from '../../../img/dialog-close-icon.svg';
import MENU_CIRCLE from '../../../img/menu-button.svg';
import VIEW_ICON from '../../../img/view-message.svg';
import DELETE_ICON from '../../../img/delete-meun.svg';
import { allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings } from './BookingMeeting/gql/__generated__/allAdvisorBookingsQuery';
import colors from '../../../common/colors';
import fonts from '../../../common/fonts';
import { useStyles } from './BookingMeeting/BookingMeetingStyle';

const StyledMenu = withStyles({
  paper: {
    borderRadius: 12,
    background: colors.WHITE_ONE,
    border: `1px solid ${colors.WHITE_SEVEN}`,
    boxShadow: `0px 4px 14px ${colors.RGBA_COLOR_ONE}`,

    '& ul': {
      padding: 11,
    },
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    borderRadius: 5,
    fontFamily: fonts.POPPINS_FONT,

    '& h6': {
      color: '#A9A9A9',
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: '0.006em',
    },

    '& .menu-item-style': {
      minWidth: 34,
    },
  },
}))(MenuItem);

const CancelMeetingModel = ({
  item,
  setMeetingId,
  setAdvisorId,
  setOpenDeleteConfirmation,
  openDeleteConfirmation,
  setBookingInfo,
}: {
  item: allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings | null;
  setMeetingId: Function;
  setAdvisorId: Function;
  setOpenDeleteConfirmation: Function;
  openDeleteConfirmation: Boolean;
  setBookingInfo: Function
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDialoag = () => {
    setOpen(false);
    handleClose();
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton onClick={handleClick} className="menuButton">
        <img src={MENU_CIRCLE} alt="menu" />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {item?.note?.length ? (
          <StyledMenuItem>
            <>
              <ListItemIcon className="menu-item-style">
                <img src={VIEW_ICON} alt="view" />
              </ListItemIcon>
              <Typography variant="h6" onClick={handleClickOpen}>
                VIEW DETAILS
              </Typography>
              <Dialog open={open} onClose={handleCloseDialoag}>
                <DialogContent className={classes.meetingNotesDialog}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom={1}
                  >
                    <Typography variant="h5" className="dialogHeading">
                      Meeting Note
                    </Typography>

                    <IconButton onClick={handleCloseDialoag}>
                      <img src={CLOSE_ICON} alt="close" />
                    </IconButton>
                  </Box>

                  <Typography variant="body1" className="dialogSubtitle">
                    {item?.note}
                  </Typography>
                </DialogContent>
              </Dialog>
            </>
          </StyledMenuItem>
        ) : null}

        <StyledMenuItem
          onClick={() => {
            setMeetingId(item?.id);
            setAdvisorId(item?.AdvisorId);
            setOpenDeleteConfirmation(!openDeleteConfirmation);
            setBookingInfo(item)
            handleClose();
          }}
        >
          <ListItemIcon className="menu-item-style">
            <img src={DELETE_ICON} alt="delete" />
          </ListItemIcon>
          <Typography variant="h6">CANCEL MEETING</Typography>
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
};

export default CancelMeetingModel;
