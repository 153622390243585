import { useContext, useState } from 'react';
import Helmet from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import { Box, IconButton } from '@material-ui/core';

import { useStyles } from './styles';
import { AppContext } from '../../contexts';
import LeadRegisterFormNew from './LeadRegistration/LeadRegisterFormNew';
import RightSidePage from './LeadRegistration/RightSidePage';
import { PARTNER_PRODUCT_REGISTRATION } from '../../constants';
import MBARightSidePage from '../graduateMBAProgram/MBARightSidePage';

const LeadRegisterPage = (): JSX.Element => {
  const { user } = useContext(AppContext);
  const [showBackBtn, setShowBackbtn] = useState(false);
  const [backBtnClick, setBackBtnClick] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const goBack = urlParams.get('_goback');
  const isMbaProgram = urlParams.get('mba') === 'true';
  const classes = useStyles();
  let salesRegitration = false;
  if (window.location.pathname.includes('sales')) {
    salesRegitration = true;
  }

  const backArrow = () => (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.19483 14.4864C7.99949 14.6817 7.73459 14.7914 7.45837 14.7914C7.18216 14.7914 6.91726 14.6817 6.72192 14.4864L0.471917 8.23639C0.276635 8.04105 0.166931 7.77615 0.166931 7.49994C0.166931 7.22372 0.276635 6.95882 0.471917 6.76348L6.72192 0.513477C6.91838 0.323729 7.1815 0.218735 7.45463 0.221109C7.72775 0.223482 7.98901 0.333033 8.18214 0.526166C8.37528 0.7193 8.48483 0.980563 8.4872 1.25368C8.48958 1.52681 8.38458 1.78993 8.19483 1.98639L3.72296 6.45827H15.7917C16.068 6.45827 16.3329 6.56802 16.5283 6.76337C16.7236 6.95872 16.8334 7.22367 16.8334 7.49994C16.8334 7.7762 16.7236 8.04115 16.5283 8.2365C16.3329 8.43186 16.068 8.5416 15.7917 8.5416H3.72296L8.19483 13.0135C8.39012 13.2088 8.49982 13.4737 8.49982 13.7499C8.49982 14.0261 8.39012 14.2911 8.19483 14.4864Z"
        fill="#D4D4D4"
      />
    </svg>
  );
  return (
    <Box>
      {user &&
        (!user.isParent ? (
          <Redirect to="/home" />
        ) : (
          <Redirect to="/account/student-accounts" />
        ))}

      <Helmet>
        <title>
          Create a Free Account to Manage All of Your College Applications and
          Checklists
        </title>
        <meta
          name="description"
          content="Sign up for a free Bullseye Admissions account, manage your college applications, get help with essays, scholarships, financial aid, and more."
        />
      </Helmet>

      <Box className={classes.registerContainer}>
        <Box className={classes.registerLeft}>
          {!showBackBtn && !salesRegitration && (
            <Box className={classes.isLoggedin}>
              Already have an account?
              <Link
                to={`${goBack ? `/login${window.location.search}` : '/login'}`}
              >
                Log In
              </Link>
            </Box>
          )}

          <Box
            maxWidth={435}
            margin="0 auto"
            width="100%"
            paddingBottom={showBackBtn ? 2 : 4}
          >
            {showBackBtn && (
              <Box
                display="inline-flex"
                alignItems="center"
                color="rgba(0, 0, 0, 0.15)"
                fontSize={15}
                fontWeight={700}
                onClick={() => setBackBtnClick(true)}
                style={{ cursor: 'pointer' }}
              >
                <IconButton style={{ height: 41 }}>{backArrow()}</IconButton>
              </Box>
            )}
          </Box>

          <LeadRegisterFormNew
            partnerKey={PARTNER_PRODUCT_REGISTRATION}
            showBackButton={setShowBackbtn}
            backClick={backBtnClick}
            setBackClick={setBackBtnClick}
            isMbaProgram={isMbaProgram}
          />
        </Box>

        {isMbaProgram ? <MBARightSidePage /> : <RightSidePage />}
      </Box>
    </Box>
  );
};

export default LeadRegisterPage;
