// imports
import { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
// graphql, apollo
import client from '../../apollo';
import { CLOSE_SMART_MATCH_BANNER } from '../../graphql/mutations/smartMatch/index';
// styles
import { useStylesSmartMatch } from './styles';
// images
import InfoIcon from '../../img/info-graduated-icon.svg';
import { DialogCloseIcon } from '../../common/assets/svgs';

const UpdateProfile = ({ refetch }: { refetch: Function }) => {
  const history = useHistory();
  const classes = useStylesSmartMatch();

  const [showBanner, setShowBanner] = useState(true);

  const goToProfile = () => {
    history.push('/account/my-profile');
  };

  return showBanner ? (
    <Box className={classes.updateProfile}>
      <img src={InfoIcon} alt="info" />

      <Box width="100%">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h4">Update Your Student Profile</Typography>

          <IconButton
            onClick={async () => {
              setShowBanner(false);
              const { data } = await client.query({
                query: CLOSE_SMART_MATCH_BANNER,
                fetchPolicy: 'no-cache',
              });

              if (data.success) await refetch();
            }}
          >
            <DialogCloseIcon />
          </IconButton>
        </Box>

        <Typography variant="body1">
          Help us deliver the best personalized guidance for you by letting us
          know your test scores, extracurriculars, and other interests.
        </Typography>

        <Button variant="contained" color="primary" onClick={goToProfile}>
          Go to Profile
        </Button>
      </Box>
    </Box>
  ) : (
    <Fragment />
  );
};

export default UpdateProfile;
