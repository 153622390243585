import {
  Avatar,
  Box,
  Button,
  Collapse,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Colors from '../../../common/colors';
import { convertUnixToDate } from '../../../common/constants';
import fonts from '../../../common/fonts';
import { AppContext } from '../../../contexts';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    matchReason: {
      '& .matchReason': {
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        fontWeight: 400,
      },

      '& .bioTextAdvisor': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,

        '& button': {
          '& svg': {
            margin: '0px 0px 0px 12px',
          },
        },
      },
    },

    root: {
      display: 'flex',
      alignItems: 'center',
      padding: '33px 0',
      borderTop: `1px solid ${Colors.WHITE_SEVEN}`,

      [`@media only screen and (max-width: 414px)`]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      '&.diamondPackageCard': {
        border: '0px !important',
      },
    },

    mentorImage: {
      height: 122,
      width: 122,
    },

    collegeIcon: {
      bottom: 0,
      right: 3,
      height: 30,
      width: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      overflow: 'hidden',
      borderRadius: '100%',
      background: '#FFFFFF',

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
    },
  })
);

export const CALENDAR_IC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0C3.73478 0 3.48043 0.105357 3.29289 0.292893C3.10536 0.48043 3 0.734784 3 1V2H2C1.46957 2 0.960859 2.21071 0.585786 2.58579C0.210714 2.96086 0 3.46957 0 4V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V4C16 3.46957 15.7893 2.96086 15.4142 2.58579C15.0391 2.21071 14.5304 2 14 2H13V1C13 0.734784 12.8946 0.48043 12.7071 0.292893C12.5196 0.105357 12.2652 0 12 0C11.7348 0 11.4804 0.105357 11.2929 0.292893C11.1054 0.48043 11 0.734784 11 1V2H5V1C5 0.734784 4.89464 0.48043 4.70711 0.292893C4.51957 0.105357 4.26522 0 4 0ZM4 5C3.73478 5 3.48043 5.10536 3.29289 5.29289C3.10536 5.48043 3 5.73478 3 6C3 6.26522 3.10536 6.51957 3.29289 6.70711C3.48043 6.89464 3.73478 7 4 7H12C12.2652 7 12.5196 6.89464 12.7071 6.70711C12.8946 6.51957 13 6.26522 13 6C13 5.73478 12.8946 5.48043 12.7071 5.29289C12.5196 5.10536 12.2652 5 12 5H4Z"
      fill="currentColor"
    />
  </svg>
);

export const VIDEO_IC = () => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H8C8.53043 0 9.03914 0.210714 9.41421 0.585786C9.78929 0.960859 10 1.46957 10 2V10C10 10.5304 9.78929 11.0391 9.41421 11.4142C9.03914 11.7893 8.53043 12 8 12H2C1.46957 12 0.960859 11.7893 0.585786 11.4142C0.210714 11.0391 0 10.5304 0 10V2ZM12.553 3.106C12.3869 3.18899 12.2472 3.31658 12.1496 3.47447C12.0519 3.63237 12.0001 3.81434 12 4V8C12.0001 8.18566 12.0519 8.36763 12.1496 8.52553C12.2472 8.68342 12.3869 8.81101 12.553 8.894L14.553 9.894C14.7054 9.97017 14.8748 10.0061 15.045 9.99845C15.2152 9.99079 15.3806 9.93975 15.5256 9.85019C15.6706 9.76064 15.7902 9.63552 15.8733 9.48673C15.9563 9.33793 15.9999 9.17039 16 9V3C15.9999 2.82961 15.9563 2.66207 15.8733 2.51327C15.7902 2.36448 15.6706 2.23936 15.5256 2.14981C15.3806 2.06025 15.2152 2.00921 15.045 2.00155C14.8748 1.99388 14.7054 2.02983 14.553 2.106L12.553 3.106Z"
      fill="white"
    />
  </svg>
);

const GetStartAdvisorCard = ({
  advisorName,
  advisorCollege,
  collegeYear,
  advisorImage,
  collegeLogo,
  collegeColor,
  disableSheduleBtn,
  matchReason,
  advisorId,
  mentorUuid,
  advisorLevel,
  bio,
  diamondPackageDisplay,
  isMBAProgram,
}: {
  advisorName: string;
  advisorCollege: string;
  collegeYear: string;
  advisorImage?: string | null;
  collegeLogo?: string | null;
  collegeColor?: string | null;
  disableSheduleBtn: boolean;
  matchReason: String;
  advisorId: number | null;
  mentorUuid: string | null;
  advisorLevel: string;
  bio: string;
  diamondPackageDisplay?: boolean;
  isMBAProgram?: boolean;
}) => {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const [open, setOpen] = React.useState(false);

  const isTrial =
    user?.studentTrial?.trialStartedAt && !user?.studentTrial?.trialCompletedAt
      ? true
      : false;

  const collegeName = `${advisorCollege || '_ _'}${
    collegeYear && !isTrial ? `, ${collegeYear}` : ``
  }`;

  return (
    <Grid className={classes.matchReason}>
      <Box
        className={
          classes.root + (diamondPackageDisplay && ' diamondPackageCard')
        }
      >
        <Grid>
          <Box display="flex" alignItems="flex-start" paddingBottom="18px">
            <Box mr="20px" position="relative">
              <Link to={`/advisors/${advisorId}/${mentorUuid}`}>
                {advisorImage ? (
                  <Avatar src={advisorImage} className={classes.mentorImage} />
                ) : (
                  <Avatar src="" className={classes.mentorImage} />
                )}

                {collegeLogo && (
                  <Box
                    className={classes.collegeIcon}
                    border="2px solid"
                    borderColor={collegeColor || Colors.BLUE_SEVEN}
                  >
                    <img src={collegeLogo} alt={advisorCollege} />
                  </Box>
                )}
              </Link>
            </Box>

            {isTrial && user?.firstMeetingBookedDate?.startTime ? (
              <Box>
                <Typography variant="h4" component="h4">
                  {user?.firstMeetingBookedDate?.startTime
                    ? convertUnixToDate(user?.firstMeetingBookedDate?.startTime)
                        .replace(' AM', 'am')
                        .replace(' PM', 'pm') +
                      ` ${moment.tz(moment.tz.guess()).format('z')}`
                    : '--'}
                </Typography>
                <Typography variant="body1" className="matchReason">
                  {collegeName?.length > 38
                    ? `w/ ${advisorName}, ${collegeName.substring(0, 38)}...`
                    : `w/ ${advisorName}, ${collegeName}`}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Link to={`/advisors/${advisorId}`}>
                  <Typography variant="h4" component="h4">
                    {advisorName}
                  </Typography>
                  <Typography variant="body1">{advisorLevel}</Typography>
                  <Typography variant="body1" className="matchReason">
                    {collegeName?.length > 58
                      ? `${collegeName.substring(0, 38)}...`
                      : collegeName}
                  </Typography>
                </Link>
              </Box>
            )}
          </Box>
          {matchReason && !isMBAProgram ? (
            <Typography variant="body1" className="bioTextAdvisor">
              <Button
                variant="text"
                color="primary"
                onClick={() => setOpen(!open)}
              >
                Match Reason
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Button>

              <Collapse in={open} timeout="auto" unmountOnExit>
                {matchReason}
              </Collapse>
            </Typography>
          ) : null}
        </Grid>
      </Box>
    </Grid>
  );
};

export default GetStartAdvisorCard;
