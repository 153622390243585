import {
  Box,
  Card,
  Grid,
  Theme,
  Tooltip,
  Typography,
  createStyles,
} from '@material-ui/core';
import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext, OnboardContext } from '../../../../contexts';
import AddCollegeForm from './AddCollegeForm';

import { AllCollegesQuery_allColleges_colleges } from './__generated__/AllCollegesQuery';

import { makeStyles } from '@material-ui/styles';

import Colors from '../../../../common/colors';
import fonts from '../../../../common/fonts';

import { collegeAddress } from '../../../../common/utility';
import COLLEGE_DEFAULT_IMG from '../../../../img/college-default-icon.svg';

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: `${Colors.BLACK_THREE}`,
    boxShadow: `0px 2px 10px ${Colors.BOX_SHADOW_THREE}`,
    borderRadius: '3px',
    padding: '10px',
    fontFamily: `${fonts.POPPINS_FONT}`,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.3375px',
    color: `${Colors.WHITE_ONE}`,
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    similarSchoolCard: {
      background: `${Colors.WHITE_ONE} !important`,
      position: 'relative',
      border: '1px solid #D4D4D4',
      borderRadius: 25,
      maxWidth: 298,
      marginBottom: 22,
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
    },

    similarSchoolCardFooter: {
      background: 'rgba(244, 244, 244, 0.5)',
      borderRadius: '0px 0px 24px 24px',
      padding: '11px 17px 16px',
      boxShadow: 'none',
      position: 'relative',
      minHeight: 132,

      '& .MuiTypography-h5, .MuiTypography-h6, .MuiTypography-body1': {
        fontFamily: fonts.INTER,
      },

      '& .MuiTypography-h6': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#272929',
        margin: 0,
      },

      '& .MuiTypography-body1': {
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#525454',
        fontWeight: 400,
        textTransform: 'capitalize',
      },
    },

    cardTop: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 4,
      padding: '10px 0px',
      minHeight: 148,
    },

    cardImage: {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      width: 100,
      height: 100,
    },

    avatarGrade: {
      width: 37,
      height: 37,
      borderRadius: '50%',
      background: '#45CE93',
      fontWeight: 'bold',
      fontSize: 20,
      color: Colors.WHITE_ONE,
      fontFamily: fonts.POPPINS_FONT,
      position: 'absolute',
      bottom: 18,
      right: 18,
    },
  })
);

interface Props {
  college: AllCollegesQuery_allColleges_colleges;
  refetch?: Function;
}

const AddCollege: FC<Props> = ({
  college: {
    id,
    logo,
    name,
    applicationCreated,
    studentApplication,
    applicationRequirement,
    address,
    topHundred,
  },
  refetch,
}) => {
  const { user } = useContext(AppContext);
  const { user: onboardUser } = useContext(OnboardContext);
  const collegeLink = `colleges/${id}`;
  const backgroundImage = `url( ${logo || COLLEGE_DEFAULT_IMG} )`;
  const { gradeOverall = '--', acceptRate } = applicationRequirement || {};
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();

  const parsedAddress = JSON.parse(address || '""');
  const { address_city, address_state } = parsedAddress || {};

  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <Box className={classes.similarSchoolCard}>
        <Box className={classes.cardTop}>
          {!onboardUser && (
            <Link to={collegeLink}>
              <Box className={classes.cardImage} style={{ backgroundImage }} />
            </Link>
          )}

          {onboardUser && (
            <Box className={classes.cardImage} style={{ backgroundImage }} />
          )}
        </Box>

        {user && refetch && (
          <AddCollegeForm
            collegeId={id}
            applicationCreated={!!applicationCreated}
            refetch={refetch}
          />
        )}

        <Card className={classes.similarSchoolCardFooter}>
          <Box>
            {onboardUser ? (
              <Typography variant="h6" className="collegeNameClamp">
                {name}
              </Typography>
            ) : (
              <Tooltip
                title={name}
                classes={tooltipClasses}
                placement="top-start"
              >
                <Link to={collegeLink}>
                  <Typography variant="h6" className="collegeNameClamp">
                    {name}
                  </Typography>
                </Link>
              </Tooltip>
            )}

            <Typography variant="body1">
              {collegeAddress(address_city, address_state) || '--'}
            </Typography>
            <Typography variant="body1">
              {!!acceptRate && acceptRate ? (
                <>{Math.round(acceptRate * 100)}% Acceptance Rate</>
              ) : (
                '--'
              )}
            </Typography>
          </Box>

          {gradeOverall && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={classes.avatarGrade}
            >
              {gradeOverall}
            </Box>
          )}
        </Card>
      </Box>
    </Grid>
  );
};

export default AddCollege;
