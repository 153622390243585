import { Snackbar } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import Alert from '@material-ui/lab/Alert';
import { FC } from 'react';

export type Color = 'error' | 'info' | 'success' | 'warning';

interface Placement {
  vertical: 'bottom' | 'top';
  horizontal: 'left' | 'right' | 'center';
}

interface Props {
  handleClose: () => void;
  message: string;
  open: boolean;
  anchorOrigin: Placement;
  autoHideDuration: number | null;
  severity?: Color | undefined;
  styles?: CSSProperties | undefined;
}

const SnackbarComponent: FC<Props> = ({
  open,
  handleClose,
  message,
  anchorOrigin,
  autoHideDuration,
  severity,
  styles,
}) => {
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      style={styles ? { ...styles } : {}}
    >
      <Alert severity={severity} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};
export default SnackbarComponent;
