import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { Grid, Tooltip, Box, Hidden, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useTooltipStyles, useStyles } from './mentorDetailPageStyle';
import COLLEGE_DEFULT_IMG from '../../../img/college-default-img.svg';

import { getAdvisorStudentsAcceptedCollegesQuery } from './__generated__/getAdvisorStudentsAcceptedCollegesQuery';
import { shuffleArray } from '../../../common/utility';
import { AssociatedColleges } from './AssociatedColleges';
import { useState } from 'react';

const ADVISOR_STUDENTS_ACCEPTED_COLLEGES = gql`
  query getAdvisorStudentsAcceptedCollegesQuery($userId: Int) {
    getAdvisorStudentsAcceptedColleges(input: { userId: $userId }) {
      colleges {
        name
        id
        logo
        styleConfig {
          bgDark
          bgLight
        }
      }
    }
  }
`;

const AdvisorStudentsAcceptedColleges = ({
  advisorId,
  advFirstName,
}: {
  advisorId: number;
  advFirstName: string | null;
}) => {
  const tooltipClasses = useTooltipStyles();
  const [showModel, setShowModel] = useState(false);
  const { mentorAcceptBadge, mentorAcceptBadgeAdvisor, addMoreCollege } =
    useStyles();

  return (
    <>
      <Query<getAdvisorStudentsAcceptedCollegesQuery>
        query={ADVISOR_STUDENTS_ACCEPTED_COLLEGES}
        fetchPolicy="network-only"
        variables={{ userId: advisorId }}
      >
        {({ data, loading }) => {
          if (loading) return <>Loading...</>;
          const colleges = data?.getAdvisorStudentsAcceptedColleges?.colleges;

          if (!colleges?.length) return <></>;

          const shuffleColleges = shuffleArray(colleges);
          const collegesToMap =
            shuffleColleges.length > 10
              ? shuffleColleges.slice(0, 9)
              : shuffleColleges;
          const remainingCollegesCount =
            shuffleColleges.length - collegesToMap.length;
          return (
            <>
              {showModel && (
                <AssociatedColleges
                  title={
                    advFirstName +
                      ' advised students who got into colleges such as:' || ''
                  }
                  showModel={showModel}
                  setShowModel={setShowModel}
                  collegesArray={colleges}
                />
              )}
              <Typography variant="h6" className="advisorTitleText">
                {advFirstName || ''} advised students who got into colleges such
                as:
              </Typography>
              <Box
                display="flex"
                alignItems="flex-start"
                flexWrap="wrap"
                className="mobileCollegesHorizontal mobileCollegesHorizontalSpacing"
              >
                {collegesToMap?.map((college) => {
                  const backgroundImage = `url( ${
                    college?.logo || COLLEGE_DEFULT_IMG
                  } )`;
                  return (
                    <Tooltip
                      classes={tooltipClasses}
                      title={college?.name || 'N/A'}
                      key={college?.id}
                    >
                      <Link to={`/colleges/${college?.id}`}>
                        <Grid
                          className={clsx(
                            mentorAcceptBadge,
                            mentorAcceptBadgeAdvisor
                          )}
                          key={college?.id}
                          style={{
                            backgroundImage,
                          }}
                        />
                        <Hidden only={['md', 'lg', 'xl']}>
                          <Typography
                            variant="body2"
                            className="mobileCollegeName"
                          >
                            {college?.name || '--'}
                          </Typography>
                        </Hidden>
                      </Link>
                    </Tooltip>
                  );
                })}
                {shuffleColleges?.length > 10 && (
                  <Tooltip classes={tooltipClasses} title="">
                    <Box
                      onClick={() => setShowModel(!showModel)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Grid
                        className={clsx(
                          mentorAcceptBadge,
                          mentorAcceptBadgeAdvisor
                        )}
                      >
                        <Typography variant="h4" className={addMoreCollege}>
                          +{remainingCollegesCount}
                        </Typography>
                      </Grid>
                      <Hidden only={['md', 'lg', 'xl']}>
                        <Typography
                          variant="body2"
                          className="mobileCollegeName"
                        >
                          And more!
                        </Typography>
                      </Hidden>
                    </Box>
                  </Tooltip>
                )}
              </Box>
            </>
          );
        }}
      </Query>
    </>
  );
};
export default AdvisorStudentsAcceptedColleges;
