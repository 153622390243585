import {
  Box,
  Card,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import Colors from '../../../common/colors';
import fonts from '../../../common/fonts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    collapsedCardHeader: {
      '& h6': {
        fontSize: '24px',
        fontWeight: 700,
        color: Colors.WHITE_ONE,
        letterSpacing: '-0.019em',
        width: '356px',
        height: '32px',
        textTransform: 'unset',
      },
    },
    collapsedCard: {
      height: '81px',
      width: '520px',
      marginBottom: 31,
      display: 'flex',
      borderRadius: 25,

      padding: '20px 25px',
      '& button': {
        position: 'relative',
        left: '57px',
        bottom: '2px',
      },
    },
    summerCard: {
      border: 0,
      padding: 0,
      minHeight: 278,
      marginBottom: 31,
      display: 'flex',
      borderRadius: 25,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom right',
      filter: 'drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.14))',

      '@media only screen and (max-width: 767px)': {
        marginBottom: 17,
      },

      '& .MuiCardContent-root': {
        padding: 0,
      },

      [theme.breakpoints.down('md')]: {
        backgroundSize: '185px !important',
      },

      '@media only screen and (max-width: 1024px)': {
        minHeight: 300,
      },

      '& .bgLayerImg': {
        width: '100%',
        zIndex: -1,
        padding: '22px 37px 34px',
        backgroundSize: '100% !important',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom center',

        [theme.breakpoints.down('xs')]: {
          padding: 16,
        },
      },
    },

    graphicCardHeader: {
      '& h6': {
        fontSize: 14,
        fontWeight: 600,
        color: Colors.WHITE_ONE,
        letterSpacing: '-0.019em',
        width: '356px',
        height: '32px',
      },
    },

    graphicCardBody: {
      [theme.breakpoints.down('md')]: {
        maxWidth: '65% !important',
      },

      [theme.breakpoints.down('xs')]: {
        maxWidth: '100% !important',
      },

      '& p': {
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '32px',
        color: Colors.WHITE_ONE,
        letterSpacing: '-0.019em',
        fontFamily: fonts.POPPINS_FONT,

        [theme.breakpoints.down('md')]: {
          fontSize: 20,
        },
      },
    },
  })
);

const SimpleCard = ({
  heading,
  body,
  img,
  className,
  BtnImg,
  bgColor,
  bgSize,
  textWidth,
  bgPosition,
  bgPositionLayer,
  bgLayerImg,
}: {
  heading?: string;
  body?: string;
  img?: string;
  className?: string;
  BtnImg?: string;
  bgColor?: string;
  bgSize?: string;
  textWidth?: string;
  bgPosition?: string;
  bgPositionLayer?: string;
  bgLayerImg?: string;
}) => {
  const classes = useStyles();

  return (
    <Card
      className={
        className !== 'collapsed' ? classes.summerCard : classes.collapsedCard
      }
      style={{
        backgroundImage: `url(${img})`,
        backgroundColor: `${bgColor}`,
        backgroundSize: `${bgSize}`,
        backgroundPosition: `${bgPosition}`,
      }}
    >
      <Grid
        className="bgLayerImg"
        style={{
          backgroundSize: `${bgSize}`,
          backgroundImage: `url(${bgLayerImg})`,
          backgroundPosition: `${bgPositionLayer}`,
        }}
      >
        <Box width="100%">
          <Box
            className={
              className !== 'collapsed'
                ? classes.graphicCardHeader
                : classes.collapsedCardHeader
            }
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">{heading}</Typography>

            <IconButton className={className}>
              <img src={BtnImg} alt="icon" />
            </IconButton>
          </Box>

          <Box
            className={classes.graphicCardBody}
            style={{ maxWidth: `${textWidth}` }}
          >
            <Typography variant="body1">{body}</Typography>
          </Box>
        </Box>
      </Grid>
    </Card>
  );
};
export default SimpleCard;
