import gql from 'graphql-tag';

export const CONNECT_ACCOUNT = gql`
  mutation ConnectAccount($sUUID: String!, $iUUID: String!) {
    connectAccount(input: { sUUID: $sUUID, iUUID: $iUUID }) {
      SenderFullName
      InviteeFirstName
    }
  }
`;
