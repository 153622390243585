import gql from 'graphql-tag';

export const CREATE_LEAD_ACCOUNTS_MUTATION = gql`
  mutation MCreateLeadAccountMutation(
    $studentFirstName: String!
    $studentLastName: String!
    $studentEmail: String
    $studentPhone: String
    $highSchoolYear: String!
    $parentFirstName: String!
    $parentLastName: String!
    $parentEmail: String!
    $parentPhone: String!
    $userRole: String!
    $aidOption: Int
    $userAnalyticParams: JSON
    $partnerKey: String
    $source: String
    $gpa: String
    $salesRegitration: Boolean
    $salesPersonId: String
    $webinar: String
    $registrationType: String
    $optInSMS: Boolean
  ) {
    createLeadAccount(
      input: {
        studentFirstName: $studentFirstName
        studentLastName: $studentLastName
        studentEmail: $studentEmail
        studentPhone: $studentPhone
        highSchoolYear: $highSchoolYear
        parentFirstName: $parentFirstName
        parentLastName: $parentLastName
        parentEmail: $parentEmail
        parentPhone: $parentPhone
        userRole: $userRole
        aidOption: $aidOption
        userAnalyticParams: $userAnalyticParams
        partnerKey: $partnerKey
        source: $source
        gpa: $gpa
        salesRegitration: $salesRegitration
        salesPersonId: $salesPersonId
        webinar: $webinar
        registrationType: $registrationType
        optInSMS: $optInSMS
      }
    ) {
      status
      redirectToLogin
      redirectToPassword
      token
      LeadId
    }
  }
`;

export const CREATE_LEAD_USER_MUTATION = gql`
  mutation CreateLeadUserMutation(
    $studentFirstName: String!
    $studentLastName: String!
    $studentEmail: String
    $studentPhone: String
    $highSchoolYear: String!
    $parentFirstName: String!
    $parentLastName: String!
    $parentEmail: String!
    $parentPhone: String!
    $gpa: String
    $userRole: String!
    $password: String!
    $aidOption: Int
    $LeadId: Int
    $partnerKey: String
    $optInSMS: Boolean
  ) {
    createLeadUser(
      input: {
        studentFirstName: $studentFirstName
        studentLastName: $studentLastName
        studentEmail: $studentEmail
        studentPhone: $studentPhone
        highSchoolYear: $highSchoolYear
        parentFirstName: $parentFirstName
        parentLastName: $parentLastName
        parentEmail: $parentEmail
        parentPhone: $parentPhone
        gpa: $gpa
        userRole: $userRole
        password: $password
        aidOption: $aidOption
        LeadId: $LeadId
        partnerKey: $partnerKey
        optInSMS: $optInSMS
      }
    ) {
      status
      redirectToLogin
      token
    }
  }
`;

export const LOGIN_WITH_EMAIL_MUTATION = gql`
  mutation SignInWithEmailMutation($email: String!, $password: String!) {
    signInWithEmail(input: { email: $email, password: $password }) {
      token
      isParent
      lastLoginAt
    }
  }
`;
