import React from 'react';
import clsx from 'clsx';
import {
  Dialog,
  InputBase,
  Typography,
  Grid,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  FormHelperText,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useStyles } from './styles';
import colors from '../../../common/colors';
import AddButtonIcon from '../../../img/plus-circle.svg';
import InfoEditIcon from '../../../img/pencil-edit.svg';
import { ADD_STUDENT_ACTIVITY } from './graphql/mutations/myProfile';
import client from '../../../apollo';
import { MenuProps } from '../../../styles/commonComponentStyles';
import { selectColor } from '../../../common/utility';

export default function AddNewActivityAwardDialog({
  edit,
  activity,
  categories,
  userId,
  refetch,
  type,
}: {
  edit?: boolean;
  activity?: any;
  categories?: any;
  userId?: number | undefined;
  refetch: Function;
  type?: string;
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState(activity?.title);
  const [detail, setDetail] = React.useState(activity?.details);
  const [isActive, setIsActive] = React.useState(activity?.currentlyActive);
  const [startedDate, setStartedDate] = React.useState(activity?.startedAt);
  const [endedDate, setEndedDate] = React.useState(activity?.endedAt);
  const [role, setRole] = React.useState(activity?.role);
  const [leadershipExp, setLeadershipExp] = React.useState(
    activity?.leadershipExperience
  );
  const [categoryId, setCategoryId] = React.useState(
    activity?.extraCurricularActivity?.id || activity?.ExtracurricularCategoryId
  );
  const [saveBtn, setSaveBtn] = React.useState(false);
  const [sDateError, setSDateError] = React.useState(false);
  const [eDateError, setEDateError] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setName(activity?.title);
    setDetail(activity?.details);
    setIsActive(activity?.currentlyActive);
    setStartedDate(activity?.startedAt || null);
    setEndedDate(activity?.endedAt || null);
    setCategoryId(
      activity?.extraCurricularActivity?.id ||
        activity?.ExtracurricularCategoryId
    );
    setRole(activity?.role);
    setLeadershipExp(activity?.leadershipExperience);
    saveBtnCheck();
    setSDateError(false);
    setEDateError(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCategoryId(event.target.value as string);
    saveBtnCheck();
  };

  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string);
    saveBtnCheck();
  };

  const handleRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRole(event.target.value as string);
    saveBtnCheck();
  };

  const handleDetailChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDetail(event.target.value as string);
    saveBtnCheck();
  };

  const handleIsActiveChange = (
    event: React.ChangeEvent<{ checked: Boolean }>
  ) => {
    setIsActive(event.target.checked);
    setEndedDate(null);
    setEDateError(false);
    saveBtnCheck();
  };

  const handleLeadershipExpChange = (
    event: React.ChangeEvent<{ checked: Boolean }>
  ) => {
    setLeadershipExp(event.target.checked);
    saveBtnCheck();
  };

  const handleStartDateChange = (value: any) => {
    setStartedDate(value);
    saveBtnCheck();
  };
  const handleEndDateChange = (value: any) => {
    setEndedDate(value);
    saveBtnCheck();
  };

  const isInvalidDate = () => {
    if (
      startedDate &&
      endedDate &&
      new Date(startedDate) > new Date(endedDate)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const saveBtnCheck = () => {
    if (
      categoryId &&
      name &&
      detail &&
      startedDate &&
      (endedDate || isActive)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const saveStudentActivity = () => {
    setSaveBtn(true);

    let category = '';
    categories.forEach((element: any) => {
      if (element.id === +categoryId) {
        category = element.category;
      }
    });

    const studentActivity = {
      title: name || null,
      details: detail || null,
      ExtracurricularCategoryId: categoryId ? +categoryId : null,
      startedAt: startedDate || null,
      endedAt: !isActive && endedDate ? endedDate : null,
      currentlyActive: isActive ?? false,
      leadershipExperience: leadershipExp ?? false,
      role: role && leadershipExp ? role : null,
      category,
      stActivityId: activity?.stActivityId ?? null,
      sync: false,
    };
    refetch(studentActivity, edit ? activity?.stActivityId : null);
    setOpen(false);
    setSaveBtn(false);
  };

  const saveExtraCurricularActivity = async () => {
    setSaveBtn(true);
    const { data } = await client.mutate({
      mutation: ADD_STUDENT_ACTIVITY,
      variables: {
        id: activity?.id,
        userId,
        title: name || null,
        details: detail || null,
        ExtracurricularCategoryId: categoryId ? +categoryId : null,
        startedAt: startedDate || null,
        endedAt: !isActive && endedDate ? endedDate : null,
        currentlyActive: isActive ?? false,
        leadershipExperience: leadershipExp ?? false,
        role: role && leadershipExp ? role : null,
      },
    });
    if (data.addStudentExtraCurricularActivity.success) {
      await refetch();
      setOpen(false);
      setSaveBtn(false);
    } else {
    }
  };

  return (
    <Grid>
      {edit ? (
        <IconButton className="iconButton" onClick={handleClickOpen}>
          <img src={InfoEditIcon} alt="edit" />
        </IconButton>
      ) : (
        <Button
          variant="text"
          className="iconTextButton"
          onClick={handleClickOpen}
          style={{ color: `${colors.BLUE_SEVEN}` }}
        >
          <img src={AddButtonIcon} alt="add" />
          ADD NEW ACTIVITY
        </Button>
      )}

      <Dialog
        open={open}
        onClose={() => handleClose()}
        className={classes.activityDialog}
      >
        <Grid className={classes.profileDialog}>
          <Typography variant="h4">Add New Activity</Typography>

          <Grid container spacing={2}>
            <Grid item md={8} sm={12} xs={12}>
              <Typography variant="body2" className="fieldCaption">
                Organization Name
              </Typography>

              <InputBase
                value={name || ''}
                fullWidth
                placeholder="e.g. NHS, Science Olympiad, Varsity Track & Field"
                onChange={handleNameChange}
                disabled={saveBtn}
              />
              {name?.length === 0 && (
                <span style={{ color: 'red' }}>Required!</span>
              )}
            </Grid>

            <Grid item md={4} sm={12} xs={12}>
              <Typography variant="body2" className="fieldCaption">
                Category
              </Typography>

              <FormControl className="w-100" error={!categoryId}>
                <Select
                  displayEmpty
                  disabled={saveBtn}
                  variant="outlined"
                  MenuProps={MenuProps}
                  value={categoryId || ''}
                  onChange={handleCategoryChange}
                  inputProps={{ 'aria-label': 'Without label' }}
                  className={`${selectColor(categoryId)}`}
                  onClose={() => {
                    if (!categoryId) {
                      setCategoryId('');
                    }
                  }}
                >
                  <MenuItem value="" className="disableText">
                    Select Category
                  </MenuItem>
                  {categories &&
                    categories.length > 0 &&
                    categories.map((item: any) => {
                      return (
                        <MenuItem value={item?.id || ''}>
                          {item?.category}
                        </MenuItem>
                      );
                    })}
                </Select>

                {!categoryId && (
                  <FormHelperText style={{ display: 'inline-flex' }}>
                    Required!
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="body2" className="fieldCaption">
                Description
              </Typography>

              <InputBase
                type="text"
                multiline
                fullWidth
                rows="5"
                rowsMax="5"
                className="textareaInputBase"
                placeholder="What did you do in this activity? Did you hold any positions? etc."
                value={detail || ''}
                onChange={handleDetailChange}
                disabled={saveBtn}
              />
              {detail?.length === 0 && (
                <span style={{ color: 'red' }}>Required!</span>
              )}
            </Grid>

            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              className={clsx(classes.checkboxReview, classes.leadership)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={leadershipExp ?? false}
                    onChange={handleLeadershipExpChange}
                    disabled={saveBtn}
                  />
                }
                label="I have leadership experience through this activity"
              />
            </Grid>

            {leadershipExp && (
              <Grid item md={8} sm={12} xs={12}>
                <InputBase
                  value={role || ''}
                  fullWidth
                  placeholder="Role title"
                  onChange={handleRoleChange}
                  disabled={saveBtn}
                  className="roleTitle"
                />
              </Grid>
            )}
          </Grid>

          <Box>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              className={classes.checkboxReview}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={isActive ?? false}
                    onChange={handleIsActiveChange}
                    disabled={saveBtn}
                  />
                }
                label="I am currently participating in this activity"
              />
            </Grid>
          </Box>

          <Box display="flex">
            <Grid className="datePickerInputBase">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Box>
                  <Typography variant="body2" className="fieldCaption">
                    Started
                  </Typography>

                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant="inline"
                    format="MM/yyyy"
                    views={['month', 'year']}
                    disabled={saveBtn}
                    placeholder="MM/YYYY"
                    value={startedDate}
                    onChange={handleStartDateChange}
                    onKeyUp={() => {
                      if (!endedDate) {
                        setSDateError(true);
                      } else {
                        setSDateError(false);
                      }
                    }}
                  />
                </Box>
                {((startedDate && !Date.parse(startedDate)) ||
                  isInvalidDate()) && (
                  <span style={{ color: 'red' }}>Invalid Date</span>
                )}
                {!startedDate && sDateError && (
                  <span style={{ color: 'red' }}>Required!</span>
                )}
              </MuiPickersUtilsProvider>
            </Grid>

            {!isActive && (
              <Grid className="datePickerInputBase">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Box>
                    <Typography variant="body2" className="fieldCaption">
                      Ending
                    </Typography>

                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      disabled={saveBtn}
                      placeholder="MM/YYYY"
                      value={endedDate}
                      format="MM/yyyy"
                      disableToolbar
                      views={['month', 'year']}
                      onChange={handleEndDateChange}
                      onKeyUp={() => {
                        if (!endedDate) {
                          setEDateError(true);
                        } else {
                          setEDateError(false);
                        }
                      }}
                    />
                  </Box>
                  {((endedDate && !Date.parse(endedDate)) ||
                    isInvalidDate()) && (
                    <span style={{ color: 'red' }}>Invalid Date</span>
                  )}
                  {!endedDate && eDateError && (
                    <span style={{ color: 'red' }}>Required!</span>
                  )}
                </MuiPickersUtilsProvider>
              </Grid>
            )}
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            pt={2}
          >
            <Button disabled={saveBtn} onClick={handleClose} variant="outlined">
              Cancel
            </Button>

            <Box ml={1.5}>
              <Button
                disabled={
                  (startedDate && !Date.parse(startedDate)) ||
                  isInvalidDate() ||
                  (endedDate && !Date.parse(endedDate))
                    ? true
                    : saveBtn || saveBtnCheck()
                }
                type="submit"
                color="primary"
                variant="contained"
                onClick={
                  type === 'intakeForm'
                    ? saveStudentActivity
                    : saveExtraCurricularActivity
                }
              >
                Save
              </Button>
            </Box>
          </Box>
        </Grid>
      </Dialog>
    </Grid>
  );
}
