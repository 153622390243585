import clsx from 'clsx';
import gql from 'graphql-tag';
import { FC, Fragment, useContext, useEffect, useState } from 'react';
import { Query } from 'react-apollo';
import { Helmet } from 'react-helmet';
import { Link, RouteComponentProps } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Hidden,
  Paper,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from '@material-ui/core';

import Loader from '../../../components/Loader';
import FooterNew from '../../../components/layout/FooterNew';
import { Error404 } from '../../errorPages';
import WebinarThumbnailCardSmall from '../../webinar/WebinarThumbnailCardSmall';
import { AdvisorBookingModal } from '../home/StudentHomePage';
import AdvisorSpecialities from './AdvisorSpecialities';
import AdvisorStudentsAcceptedColleges from './AdvisorStudentsAcceptedColleges';
import { AssociatedColleges } from './AssociatedColleges';
import MentorsReviewCard from './MentorsReviewCard';

import {
  CURRENT_YEAR,
  ClientStatus,
  tealiumConstants,
} from '../../../common/constants';
import {
  MeetingStatus,
  cjConfig,
  createTealiumCall,
  shuffleArray,
} from '../../../common/utility';
import { AppContext } from '../../../contexts';
import {
  MentorDetailQuery,
  MentorDetailQuery_Mentor_currentCollege,
  MentorDetailQuery_Mentor_graduateSchool,
  MentorDetailQuery_Mentor_previouslyWorkedColleges,
} from './__generated__/MentorDetailQuery';

import { customTheme } from '../../MaterializeCss';
import { useStyles, useTooltipStyles } from './mentorDetailPageStyle';

import Colors from '../../../common/colors';

import AdvisorListIcon from '../../../img/advisor-bullet.svg';
import BREADCRUMB_LINK_IMG from '../../../img/back-arrow-hub.svg';
import COLLEGE_DEFULT_IMG from '../../../img/college-default-img.svg';
import MEETING from '../../../img/meeting.svg';
import PROFILE_BADGE_IMG from '../../../img/profile-badge.png';
import { GET_ALL_STUDENT_MENTORS } from '../../../graphql/queries/studentMentors';
import { getAllStudentMentors_allStudentMentors } from '../../../graphql/queries/studentMentors/__generated__/getAllStudentMentors';
import client from '../../../apollo';
import { PHONE_IC } from '../../../components/layout/HeaderIcons';
import { CURRENT_USER_QUERY } from '../layouts/MainLayout';

export const MENTOR_DETAIL_QUERY = gql`
  query MentorDetailQuery($mentorId: Int, $uuid: String) {
    Mentor(mentorId: $mentorId, uuid: $uuid) {
      id
      firstName
      lastName
      email
      pictureUrl
      bio
      intakeYear
      gpa
      major
      minor
      degree
      isAcceptedTo
      graduateSchoolGradYear
      advisorLevel
      visible
      sendbirdId
      internationalStudentExperience
      userMajors {
        id
        educationType
        degreeType
        MajorId
        majorName
      }
      mathingPreference {
        userDetails {
          pronouns
        }
      }
      previouslyWorkedColleges {
        id
        college {
          id
          name
          logo

          styleConfig {
            bgDark
            bgLight
          }
        }
        Title
        CurrentlyWorking
      }
      graduateSchool {
        id
        name
        logo
        styleConfig {
          bgDark
          bgLight
        }
      }

      otherMentors {
        id
        firstName
        lastName
        pictureUrl
        bio
        uuid
        intakeYear
        gpa
        major
        minor
        advisorLevel
        previouslyWorkedColleges {
          college {
            id
            name
            logo
          }
          CurrentlyWorking
        }
        isAcceptedTo
        graduateSchool {
          id
          name
          logo
          styleConfig {
            bgDark
            bgLight
          }
        }
        graduateSchoolGradYear

        colleges {
          id
          logo
          name
        }

        mentorReviews {
          id
          rating
          message
          gradYear
          collegeName
          firstName
          lastInitial
          reviewMonth
          reviewYear
          parentReview
        }
        currentCollege {
          id
          name
          logo
        }
        currentStudents {
          id
        }
        uuid
        Theme
      }
      colleges {
        id
        logo
        name
      }
      mentorReviews {
        id
        rating
        message
        gradYear
        collegeName
        firstName
        lastInitial
        reviewMonth
        reviewYear
        parentReview
      }
      currentCollege {
        id
        name
        logo

        styleConfig {
          bgDark
          bgLight
        }
      }
      currentStudents {
        id
      }
      uuid
      webinars {
        id
        title
        about
        startTime
        coverTileNoDate
        recording_url
      }
      Theme
    }
  }
`;

export const themeSchoolImageSelection = (
  Theme: string | null,
  graduateCollegeBgImage: string | null,
  currentCollegeBackgorundImage: string | null
) => {
  if (Theme === 'underGraduation') {
    return currentCollegeBackgorundImage || '';
  } else if (Theme === 'graduation') {
    return graduateCollegeBgImage || '';
  }
  return '';
};

interface TParams {
  id: string;
  uuid: string;
}

const MentorDetailPage: FC<RouteComponentProps<TParams>> = ({
  match: { params },
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    cjConfig({ userId: user?.id });
  }, []);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [showModel, setShowModel] = useState(false);

  const mentorId = parseInt(params.id);
  const uuid = params.uuid;
  const [meeting, setMeeting] = useState<any>(null);
  const [loader, setLoader] = useState(true);
  const [disableVideoChat, setDisableVideoChat] = useState(false);

  const getMeetingStatus = () => {
    try {
      const apiCalling = async () => {
        const data = user?.id ? await MeetingStatus(user.id) : null;
        if (data) {
          setMeeting(data);
        }
        setLoader(false);
      };
      apiCalling();
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  useEffect(getMeetingStatus, []);

  const {
    breadlink,
    mentorGridFlex,
    advisorAvatar,
    advisorCollegeAvatar,
    advisorAvatarWrapper,
    startOrder,
    mentorProfileDetail,
    mentorAcceptBadge,
    paperWrapper,
    advisorInformation,
    callNowBox,
    advisorProfileBadge,
    advisorRoleCard,
    advisorRoleAvatar,
    aboutContent,
    contentHeight,
    themeCard,
    themeCardTransparent,
    roleWrapper,
    advisedAcceptColleges,
    mentorAcceptBadgeAdvisor,
    reviewsWrapper,
    reviewsWrapperDivider,
    webinarsWrapper,
    reviewCardMobile,
    addMoreCollege,
    advisingList,
    advisorInfoCard,
  } = useStyles();

  const { user, setUser } = useContext(AppContext);
  const tooltipClasses = useTooltipStyles();
  const [allStudentMentors, setAllStudentMentors] = useState<
    (getAllStudentMentors_allStudentMentors | null)[] | null
  >(null);

  const mentorIds = allStudentMentors?.map((item) => item?.mentor?.id);
  const notUserMentor = !mentorIds?.includes(mentorId);
  const allowedPremiumPackageTypesForOtherAdvisors = [
    'Elite',
    'Platinum',
    'Ivy Plus',
  ];

  // added previouslyWorkedColleges in the function signature
  const themeColorSelection = (
    Theme: string | null,
    currentCollege: MentorDetailQuery_Mentor_currentCollege | null,
    graduateSchool: MentorDetailQuery_Mentor_graduateSchool | null,
    previouslyWorkedColleges: any
  ) => {
    if (!Theme) return null;

    if (Theme === 'underGraduation') {
      return currentCollege?.styleConfig?.bgDark || null;
    } else if (Theme === 'graduation') {
      return graduateSchool?.styleConfig?.bgDark || null;
    } else if (Theme.includes('former_')) {
      // color selection rule for previously worked colleges
      let themeCollegeName = Theme.substring(7);
      if (previouslyWorkedColleges.length > 0) {
        let themeCollegeIndex = previouslyWorkedColleges.findIndex((previouslyWorkedCollege: any) => previouslyWorkedCollege?.college?.name === themeCollegeName);
        if (themeCollegeIndex >= 0) {
          const { id, college } = previouslyWorkedColleges[themeCollegeIndex];
          
          return college?.styleConfig?.bgDark || null;
        } else {
          const { id, college } = previouslyWorkedColleges[0];
          
          return college?.styleConfig?.bgDark || null;
        }
      }
      return '#20438f';
    }
    return null;
  };

  const themeSchoolSelection = (
    Theme: any,
    intakeYear: string | null,
    graduateSchoolGradYear: string | null,
    currentCollege: MentorDetailQuery_Mentor_currentCollege | null,
    graduateSchool: MentorDetailQuery_Mentor_graduateSchool | null,
    previouslyWorkedColleges: any
  ) => {
    if (Theme === 'underGraduation') {
      return <>{`${currentCollege?.name || ''}`}</>;
    } else if (Theme === 'graduation') {
      return <>{graduateSchool?.name}</>;
    } else if (Theme.includes('former_')) {
      // theme school card selection for previously worked colleges
      let themeCollegeName = Theme.substring(7);
      if (previouslyWorkedColleges.length > 0) {
        let themeCollegeIndex = previouslyWorkedColleges.findIndex((previouslyWorkedCollege: any) => previouslyWorkedCollege?.college?.name === themeCollegeName);
        if (themeCollegeIndex >= 0) {
          return previouslyWorkedColleges[themeCollegeIndex].college.name;
        } else {
          return <></>;
        }
      }
      return <></>;
    }
    return <></>;
  };

  useEffect(() => {
    const fetchAllStudentMentors = async () => {
      const { data } = await client.query({
        query: GET_ALL_STUDENT_MENTORS,
        fetchPolicy: 'network-only',
      });
      if (data && data.allStudentMentors) {
        setAllStudentMentors(data.allStudentMentors);
      }
    };
    createTealiumCall({
      site_vertical: tealiumConstants.SITE,
      site_portal: tealiumConstants.ADVISOR,
      site_product: tealiumConstants.ADVISOR_PRODUCT,
      site_product_section: tealiumConstants.ADVISOR_PRODUCT_SECTION,
      page_type: tealiumConstants.ADVISOR_PAGE_TYPE,
      content_type: tealiumConstants.ADVISOR_CONTENT_TYPE,
    });
    const searchParam = new URLSearchParams(window.location.search);
    if (searchParam.get('adv1') && searchParam.get('kickOff') === 'true') {
      setOpenCalendar(true);
    }
    fetchAllStudentMentors();
  }, []);

  const isActiveUser =
    user?.clientStatus !== ClientStatus.BAD_DEBT &&
    user?.clientStatus !== ClientStatus.CANCELED;

  const isPremiumUser = user?.isPremium && isActiveUser;

  const apiCalling = async () => {
    const { data } = await client.query({
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'no-cache',
    });
    if (data?.currentUser) {
      const { currentUser: user } = data;
      setUser(user);
      setDisableVideoChat(
        (user.initialKickOffDate
          ? false
          : user.firstMeetingBookedDate &&
          user.firstMeetingBookedDate?.endTime > Date.now() / 1000) ||
        (user.premiumVideoCountUsed || 0) >=
        (user.premiumTotalVideoCount || 0) ||
        false
      );
    }
  };

  const intakeWidgetCondition =
    isPremiumUser && !user?.initialKickOffDate && !user?.firstMeetingBookedDate;

  useEffect(() => {
    apiCalling();
  }, []);

  if (loader) {
    return <Loader isSmallLoader={true} isTransparent={true} forceLoaderText={true} />;
  }

  return (
    <>
      <Query<MentorDetailQuery>
        query={MENTOR_DETAIL_QUERY}
        variables={{ mentorId, uuid }}
        fetchPolicy="network-only"
      >
        {({ data, loading }) => {
          if (loading) return <Loader isSmallLoader={true} isTransparent={true} forceLoaderText={true} />;
          const mentor = data && data.Mentor;
          if (!mentor)
            return (
              <>
                <Helmet>
                  <title>404 Error Page</title>
                  <meta name="description" content="404 Error" />
                </Helmet>
                <Error404 hideLogo={true} />
              </>
            );

          // Not sure why there is ? if there is a condition above that will return if there is no mentor

          const advisorDetail = {
            __typename: mentor?.__typename || null,
            id: mentor.id,
            firstName: mentor?.firstName || null,
            lastName: mentor?.lastName || null,
            email: mentor?.email || null,
            pictureUrl: mentor?.pictureUrl || null,
            advisorLevel: mentor?.advisorLevel || null,
            uuid: mentor?.uuid || null,
            intakeYear: mentor?.intakeYear || null,
            sendbirdId: mentor?.sendbirdId || null,
            currentCollege: mentor?.currentCollege || null,
            graduateSchool: mentor?.graduateSchool || null,
            visible: mentor?.visible || null,
            bio: mentor?.bio || null,
            Theme: mentor?.Theme || null,
          };
          const {
            id,
            colleges,
            currentCollege,
            firstName,
            lastName,
            bio,
            pictureUrl,
            intakeYear,
            graduateSchool,
            mentorReviews,
            currentStudents,
            advisorLevel,
            previouslyWorkedColleges,
            graduateSchoolGradYear,
            webinars,
            Theme,
            internationalStudentExperience,
            userMajors,
          } = mentor;

          const currentCollegeBackgorundImage = `url( ${currentCollege?.logo || COLLEGE_DEFULT_IMG
            } )`;
          const graduateCollegeBgImage = `url( ${graduateSchool?.logo || COLLEGE_DEFULT_IMG
            } )`;
          const name = `${firstName || ''} ${lastName || ''}`;

          let mentorAvailable;

          let checkInfo =
            !!(currentStudents && currentStudents.length && user && user.id) &&
            currentStudents.some((student, i) => {
              if (!student) return false;
              const { id } = student;
              if (!id) return false;
              return id === user.id;
            });

          if (checkInfo) {
            mentorAvailable = true;
          }

          const currentlyWorkingAt =
            (previouslyWorkedColleges &&
              previouslyWorkedColleges.length > 0 &&
              previouslyWorkedColleges.find(
                (item) => item?.CurrentlyWorking === true
              )) ||
            null;
          const bgColorHeader =
            advisorLevel === 'Former Admissions Officer'
              ? Theme
                ? themeColorSelection(Theme, currentCollege, graduateSchool, previouslyWorkedColleges)
                : currentlyWorkingAt
                  ? currentlyWorkingAt?.college?.styleConfig?.bgDark
                  : 'rgb(32, 67, 143)'
              : themeColorSelection(Theme, currentCollege, graduateSchool, previouslyWorkedColleges) ||
              'rgb(32, 67, 143)';

          const education = [];
          if (currentCollege) {
            const undergraduateEducationMajors = userMajors
              ?.filter((_major) => _major?.educationType === 'underGraduation')
              .map((underGradMajor) => {
                const degree = underGradMajor?.degreeType;
                const major = underGradMajor?.majorName;

                if (degree && major) {
                  return `${degree}, ${major}`;
                } else {
                  return degree || major;
                }
              });
            education.push({
              name: currentCollege?.name || 'N/A',
              degree: undergraduateEducationMajors?.join('; ') || '',
              year: intakeYear || '',
              Theme: 'underGraduation',
            });
          }
          if (graduateSchool) {
            const graduateEducationMajor = userMajors
              ?.filter((gradMajor) => gradMajor?.educationType === 'graduation')
              .map((_major) => {
                const degree = _major?.degreeType;
                const major = _major?.majorName;

                if (degree && major) {
                  return `${degree}, ${major}`;
                } else {
                  return degree || major;
                }
              });

            education[Theme === 'graduation' ? 'unshift' : 'push']({
              name: graduateSchool?.name || 'N/A',
              degree: graduateEducationMajor?.join('; ') || '',
              year: graduateSchoolGradYear || '',
              Theme: 'graduation',
            });
          }

          const shuffleColleges = shuffleArray(colleges);
          const collegesToMap =
            shuffleColleges.length > 10
              ? shuffleColleges.slice(0, 9)
              : shuffleColleges;

          const remainingCollegesCount =
            shuffleColleges.length - collegesToMap.length;
          return (
            <>
              {showModel && (
                <AssociatedColleges
                  title={
                    mentor?.firstName + ' was accepted to colleges such as:' ||
                    ''
                  }
                  showModel={showModel}
                  setShowModel={setShowModel}
                  collegesArray={colleges}
                />
              )}
              {mentorId && (
                <AdvisorBookingModal
                  advisorId={mentorId}
                  openCalendar={openCalendar}
                  setOpenCalendar={setOpenCalendar}
                  intakeWidgetCondition={intakeWidgetCondition || false}
                  {...(notUserMentor && {
                    nonAssociatedAdvisorDetail: advisorDetail,
                  })}
                />
              )}
              <Grid className={contentHeight}>
                <Paper
                  elevation={0}
                  className={paperWrapper}
                  style={{
                    background: bgColorHeader || '',
                  }}
                >
                  <Container maxWidth={false}>
                    <Grid>
                      <Link
                        to={user?.isMBAGrad ? '/home' : '/advisors'}
                        className={breadlink}
                      >
                        <img src={BREADCRUMB_LINK_IMG} alt="arrow" />
                      </Link>
                    </Grid>

                    <Box
                      display="flex"
                      alignItems="flex-start"
                      className={advisorInformation}
                    >
                      <Grid className={advisorAvatarWrapper}>
                        {advisorLevel === 'Former Admissions Officer' ? (
                          <Box className={advisorProfileBadge}>
                            <img src={PROFILE_BADGE_IMG} alt="badge" />
                            <Typography
                              variant="caption"
                              className="badgeRoleText"
                            >
                              AO
                            </Typography>
                          </Box>
                        ) : (
                          <Link to={`/colleges/${currentCollege?.id}`}>
                            <Box
                              className={advisorCollegeAvatar}
                              style={{
                                backgroundImage: Theme
                                  ? themeSchoolImageSelection(
                                    Theme,
                                    graduateCollegeBgImage,
                                    currentCollegeBackgorundImage
                                  )
                                  : graduateSchool
                                    ? graduateCollegeBgImage
                                    : currentCollegeBackgorundImage,
                                border: `3px solid ${bgColorHeader || 'transparent'
                                  }`,
                              }}
                            />
                          </Link>
                        )}

                        <Box
                          className={advisorAvatar}
                          style={{
                            backgroundImage: 'url(' + pictureUrl + ')',
                          }}
                        />
                      </Grid>

                      <Grid className={mentorProfileDetail}>
                        <Typography
                          variant="body1"
                          className="advisorCollegeName"
                        >
                          {Theme ? (
                            themeSchoolSelection(
                              Theme,
                              intakeYear,
                              graduateSchoolGradYear,
                              currentCollege,
                              graduateSchool,
                              previouslyWorkedColleges
                            )
                          ) : graduateSchool ? (
                            <>{graduateSchool.name}</>
                          ) : (
                            <>{currentCollege?.name || ''}</>
                          )}
                        </Typography>

                        <Typography variant="h3" className="advisorName">
                          {name}
                        </Typography>
                      </Grid>
                    </Box>
                  </Container>
                </Paper>

                <Container maxWidth={false}>
                  <Grid container className={mentorGridFlex}>
                    <Grid lg={8} md={12}>
                      <Grid className="experienceTagsWidth">
                        <Grid container className="officerCollegeWrapper">
                          <Grid item xs={12} md={12} lg={12}>
                            <Typography
                              variant="h6"
                              className="seniorAdvisor seniorAdvisorSpacing"
                            >
                              {advisorLevel}
                            </Typography>
                          </Grid>
                          {advisorLevel === 'Former Admissions Officer' && (
                            <>
                              {previouslyWorkedColleges &&
                                previouslyWorkedColleges.map(
                                  (previousWorked, index) => {
                                    if (!previousWorked) return null;
                                    const { college, Title, CurrentlyWorking } =
                                      previousWorked;

                                    const workedAtCollegeBackgroundImage = `url( ${college?.logo || COLLEGE_DEFULT_IMG
                                      } )`;

                                    return (
                                      <Grid className={roleWrapper} item xs={12} sm={12} md={6} lg={6}>
                                        <Card
                                          className={advisorRoleCard}
                                        >
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            className="advisorRoleFlex"
                                          >
                                            <Box
                                              className={advisorRoleAvatar}
                                              style={
                                                (index >= 0 && Theme && Theme.includes(`${college?.name}`)) ?
                                                  {
                                                    backgroundImage:
                                                      workedAtCollegeBackgroundImage,
                                                    border: `2px solid ${themeColorSelection(
                                                      Theme,
                                                      currentCollege,
                                                      graduateSchool,
                                                      previouslyWorkedColleges)}`
                                                  } : {
                                                    backgroundImage:
                                                      workedAtCollegeBackgroundImage,
                                                    border: '0.57971px solid #D4D4D4',
                                                  }
                                              }
                                            />

                                            <Box>
                                              <Typography
                                                variant="h6"
                                                className="advisorRole"
                                              >
                                                {Title !==
                                                  'Former Admissions Officer' &&
                                                  !CurrentlyWorking
                                                  ? 'Former '
                                                  : ''}
                                                {Title}
                                              </Typography>
                                              <Typography
                                                variant="h4"
                                                className="advisorCollege"
                                              >
                                                {college?.name}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </Card>
                                      </Grid>
                                    );
                                  }
                                )}

                              <Grid item xs={12} md={12} lg={12}>
                                <Divider className="roleDivider" />
                              </Grid>
                            </>
                          )}
                        </Grid>
                        {education?.length > 0 && (
                          <Grid className={roleWrapper}>
                            <Grid className={advisorInfoCard}>
                              {education?.map((item, index) => (
                                <Card
                                  className={
                                    (index === 0 && Theme && Theme.includes('former_') === false)
                                      ? themeCard
                                      : clsx(themeCard, themeCardTransparent)
                                  }
                                  style={
                                    ((index === 0 && Theme && Theme.includes('former_') === false) && {
                                      background: Theme
                                        ? themeColorSelection(
                                          Theme,
                                          currentCollege,
                                          graduateSchool,
                                          previouslyWorkedColleges
                                        ) || 'rgb(32, 67, 143)'
                                        : themeColorSelection(
                                          item.Theme,
                                          currentCollege,
                                          graduateSchool,
                                          previouslyWorkedColleges
                                        ) || 'rgb(32, 67, 143)',
                                      border:
                                        `1px solid ${Theme && Theme.includes('former_') === false
                                          ? themeColorSelection(
                                            Theme,
                                            currentCollege,
                                            graduateSchool,
                                            previouslyWorkedColleges
                                          )
                                          : themeColorSelection(
                                            item.Theme,
                                            currentCollege,
                                            graduateSchool,
                                            previouslyWorkedColleges
                                          )
                                        }` || 'rgb(32, 67, 143)',
                                    }) ||
                                    undefined
                                  }
                                >
                                  <Typography
                                    variant="body1"
                                    className={
                                      (index === 0 && Theme && Theme.includes('former_') === false)
                                        ? 'roleTitle'
                                        : 'roleCollegeDarkHeading'
                                    }
                                  >
                                    {item.degree || '--'}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    className={
                                      (index === 0 && Theme && Theme.includes('former_') === false)
                                        ? 'roleTitle roleCollege'
                                        : 'roleTitleDark roleCollegeDark'
                                    }
                                  >
                                    {item?.name || ''}
                                  </Typography>
                                </Card>
                              ))}
                            </Grid>

                            <Divider className="roleDivider" />
                          </Grid>
                        )}

                        <Typography variant="h4">Experience</Typography>

                        <Box className={aboutContent}>
                          <Grid className="experienceText">
                            <TextField
                              value={bio || '--'}
                              fullWidth
                              multiline
                              disabled
                            />
                          </Grid>
                        </Box>

                        {internationalStudentExperience && (
                          <Box
                            mt={2}
                            display="flex"
                            alignItems="center"
                            className={advisingList}
                          >
                            <img src={AdvisorListIcon} alt="bullet" />

                            <Typography variant="body1">
                              This advisor has experience advising international
                              students.
                            </Typography>
                          </Box>
                        )}

                        <AdvisorSpecialities advisorId={id} />
                      </Grid>
                    </Grid>

                    <Hidden only={['xl', 'lg', 'md']}>
                      {(meeting?.status === 'End' &&
                        meeting?.showBtn &&
                        user &&
                        !isPremiumUser) ||
                        (meeting?.status &&
                          !meeting?.showBtn &&
                          user &&
                          !isPremiumUser) ? (
                        <></>
                      ) : (
                        <Grid className={callNowBox}>
                          <Typography variant="h4" className="callBoxHeading">
                            {user && isPremiumUser
                              ? `Want to work with ${firstName}?`
                              : `Want to work with Advisors like ${name}?`}{' '}
                          </Typography>
                          {isPremiumUser &&
                            notUserMentor &&
                            allowedPremiumPackageTypesForOtherAdvisors.includes(
                              user?.premiumPackageType || ''
                            ) && (
                              <Typography
                                variant="body2"
                                className="callBoxHeading textReachOut"
                              >
                                Reach out to your primary advisor to obtain{' '}
                                {firstName}'s booking link and arrange a
                                meeting.
                              </Typography>
                            )}

                          {user && isPremiumUser ? (
                            <>
                              {mentorAvailable &&
                                (!notUserMentor ||
                                  allowedPremiumPackageTypesForOtherAdvisors.includes(
                                    user?.premiumPackageType || ''
                                  )) ? (
                                <>
                                  <Grid>
                                    {
                                      <Button
                                        onClick={() => {
                                          setOpenCalendar(!openCalendar);
                                        }}
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        disabled={
                                          !isActiveUser ||
                                          user.graduated ||
                                          disableVideoChat ||
                                          (notUserMentor &&
                                            allowedPremiumPackageTypesForOtherAdvisors.includes(
                                              user?.premiumPackageType || ''
                                            )) ||
                                          false
                                        }
                                      >
                                        {intakeWidgetCondition
                                          ? 'Schedule Orientation Call'
                                          : 'Schedule Video Chat'}
                                      </Button>
                                    }
                                  </Grid>
                                </>
                              ) : (
                                <Fragment />
                              )}
                            </>
                          ) : (
                            <Box>
                              <Typography
                                variant="h6"
                                className="callBoxSubHeading"
                              >
                                Call us now to work 1-on-1 with this Advisor or
                                our extended network.
                              </Typography>

                              <Grid>
                                <a href="tel: +1 (844) 343-6272">
                                  <Button
                                    fullWidth
                                    color="secondary"
                                    variant="contained"
                                  >
                                    <Box marginRight={1}>{PHONE_IC()}</Box>
                                    CALL NOW
                                  </Button>
                                </a>
                              </Grid>

                              <Box
                                color={Colors.BLACK_TEN}
                                paddingTop={1.8}
                                textAlign="center"
                              >
                                +1 (844) 343-6272
                              </Box>
                            </Box>
                          )}
                        </Grid>
                      )}
                    </Hidden>

                    <Grid lg={4} md={12}>
                      {(meeting?.status === 'End' &&
                        meeting?.showBtn &&
                        user &&
                        !isPremiumUser) ||
                        (meeting?.status &&
                          !meeting?.showBtn &&
                          user &&
                          !isPremiumUser) ? (
                        <></>
                      ) : (
                        <Hidden only={['xs', 'sm']}>
                          <Grid className={callNowBox}>
                            <Typography variant="h4" className="callBoxHeading">
                              {user && isPremiumUser
                                ? `Want to work with ${firstName}?`
                                : `Want to work with Advisors like ${name}?`}{' '}
                            </Typography>
                            {isPremiumUser &&
                              notUserMentor &&
                              allowedPremiumPackageTypesForOtherAdvisors.includes(
                                user?.premiumPackageType || ''
                              ) && (
                                <Typography
                                  variant="body2"
                                  className="callBoxHeading textReachOut"
                                >
                                  Reach out to your primary advisor to obtain{' '}
                                  {firstName}'s booking link and arrange a
                                  meeting.
                                </Typography>
                              )}

                            {user && isPremiumUser ? (
                              <>
                                {mentorAvailable &&
                                  (!notUserMentor ||
                                    allowedPremiumPackageTypesForOtherAdvisors.includes(
                                      user?.premiumPackageType || ''
                                    )) ? (
                                  <>
                                    <Grid>
                                      {
                                        <Button
                                          onClick={() => {
                                            setOpenCalendar(!openCalendar);
                                          }}
                                          variant="contained"
                                          fullWidth
                                          color="secondary"
                                          disabled={
                                            !isActiveUser ||
                                            user.graduated ||
                                            disableVideoChat ||
                                            (notUserMentor &&
                                              allowedPremiumPackageTypesForOtherAdvisors.includes(
                                                user?.premiumPackageType || ''
                                              )) ||
                                            false
                                          }
                                        >
                                          Schedule Video Chat
                                        </Button>
                                      }
                                    </Grid>
                                  </>
                                ) : (
                                  <Fragment />
                                )}
                              </>
                            ) : (
                              <Box>
                                {!user ? (
                                  <>
                                    <Typography
                                      variant="h6"
                                      className="callBoxSubHeading"
                                    >
                                      Schedule a free admissions consultation to
                                      discuss ways to connect with this advisor
                                      or our extended network.
                                    </Typography>

                                    <Grid>
                                      <a href={`/schedule`}>
                                        <Button
                                          color="secondary"
                                          fullWidth
                                          variant="contained"
                                        >
                                          <img src={MEETING} alt="" />
                                          Schedule a Consultation
                                        </Button>
                                      </a>
                                    </Grid>
                                  </>
                                ) : (user?.highSchoolYear ===
                                  (CURRENT_YEAR + 1).toString() ||
                                  user?.highSchoolYear ===
                                  (CURRENT_YEAR + 2).toString() ||
                                  user?.highSchoolYear ===
                                  (CURRENT_YEAR + 3).toString()) &&
                                  !user?.isPremium ? (
                                  <>
                                    <Typography
                                      variant="h6"
                                      className="callBoxSubHeading"
                                    >
                                      Schedule a free admissions consultation to
                                      discuss ways to connect with this advisor
                                      or our extended network.
                                    </Typography>

                                    <Grid>
                                      <a href={`/schedule`}>
                                        <Button
                                          className={startOrder}
                                          variant="contained"
                                        >
                                          <img src={MEETING} alt="" />
                                          Schedule a Consultation
                                        </Button>
                                      </a>
                                    </Grid>

                                    <Box
                                      color={Colors.BLACK_TEN}
                                      paddingTop={1.8}
                                      textAlign="center"
                                    >
                                      <a
                                        href="tel: +1 (844) 343-6272"
                                        style={{
                                          textDecoration: 'none',
                                          color: 'inherit',
                                        }}
                                      >
                                        or call +1 (844) 343-6272
                                      </a>
                                    </Box>
                                  </>
                                ) : (
                                  <>
                                    <Typography
                                      variant="h6"
                                      className="callBoxSubHeading"
                                    >
                                      Call us now to work 1-on-1 with this
                                      Advisor or our extended network.
                                    </Typography>

                                    <Grid>
                                      <a href="tel: +1 (844) 343-6272">
                                        <Button
                                          fullWidth
                                          color="secondary"
                                          variant="contained"
                                        >
                                          <Box marginRight={1}>
                                            {PHONE_IC()}
                                          </Box>
                                          CALL NOW
                                        </Button>
                                      </a>
                                    </Grid>

                                    <Box
                                      color={Colors.BLACK_TEN}
                                      paddingTop={1.8}
                                      textAlign="center"
                                    >
                                      +1 (844) 343-6272
                                    </Box>
                                  </>
                                )}
                              </Box>
                            )}
                          </Grid>
                        </Hidden>
                      )}

                      <Grid className={advisedAcceptColleges}>
                        <AdvisorStudentsAcceptedColleges
                          advisorId={mentorId}
                          advFirstName={firstName}
                        />

                        <Typography
                          variant="h6"
                          className="advisorTitleText advisorTitleTextSpacing"
                        >
                          {firstName || ''} was accepted to colleges such as:
                        </Typography>

                        <Box
                          display="flex"
                          alignItems="flex-start"
                          flexWrap="wrap"
                          className="mobileCollegesHorizontal"
                        >
                          {!!(collegesToMap && collegesToMap.length) ? (
                            collegesToMap.map((college, i) => {
                              if (!college) return null;
                              const { id, logo } = college;
                              const backgroundImage = `url( ${logo || COLLEGE_DEFULT_IMG
                                } )`;
                              return (
                                <Tooltip
                                  classes={tooltipClasses}
                                  title={college.name}
                                  key={id}
                                >
                                  <Link to={`/colleges/${id}`}>
                                    <Grid
                                      className={clsx(
                                        mentorAcceptBadge,
                                        mentorAcceptBadgeAdvisor
                                      )}
                                      key={id}
                                      style={{
                                        backgroundImage,
                                      }}
                                    />

                                    <Hidden only={['md', 'lg', 'xl']}>
                                      <Typography
                                        variant="body2"
                                        className="mobileCollegeName"
                                      >
                                        {college.name}
                                      </Typography>
                                    </Hidden>
                                  </Link>
                                </Tooltip>
                              );
                            })
                          ) : (
                            <></>
                          )}

                          {shuffleColleges && shuffleColleges?.length > 10 && (
                            <Tooltip classes={tooltipClasses} title="">
                              <Box
                                onClick={() => setShowModel(!showModel)}
                                style={{ cursor: 'pointer' }}
                              >
                                <Grid
                                  className={clsx(
                                    mentorAcceptBadge,
                                    mentorAcceptBadgeAdvisor
                                  )}
                                >
                                  <Typography
                                    variant="h4"
                                    className={addMoreCollege}
                                  >
                                    +{remainingCollegesCount}
                                  </Typography>
                                </Grid>
                                <Hidden only={['md', 'lg', 'xl']}>
                                  <Typography
                                    variant="body2"
                                    className="mobileCollegeName"
                                  >
                                    And more!
                                  </Typography>
                                </Hidden>
                              </Box>
                            </Tooltip>
                          )}
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid lg={12} md={12} xs={12}>
                      {mentorReviews && mentorReviews.length > 0 && (
                        <>
                          <Divider className="tagsDivider" />
                          <Grid
                            className={clsx(
                              reviewsWrapper,
                              reviewsWrapperDivider
                            )}
                          >
                            <Typography variant="h5" className="reviewsHeading">
                              Reviews
                            </Typography>

                            <Box
                              display="flex"
                              alignItems="flex-start"
                              className={reviewCardMobile}
                            >
                              {mentorReviews.map(
                                (review, index) =>
                                  review && (
                                    <MentorsReviewCard
                                      key={review.id || index}
                                      name={`${review.firstName} ${review.lastInitial}`}
                                      college={review?.collegeName || ''}
                                      description={review.message || ''}
                                      verifiedDate={`Verified review from ${review.reviewMonth} ${review.reviewYear}`}
                                      gradYear={review.gradYear}
                                      parentReview={
                                        review.parentReview || false
                                      }
                                    />
                                  )
                              )}
                            </Box>
                          </Grid>
                        </>
                      )}

                      {!!webinars?.length && (
                        <>
                          <Divider className="webinarsDivider" />
                          <Grid
                            className={clsx(reviewsWrapper, webinarsWrapper)}
                          >
                            <Typography variant="h5" className="reviewsHeading">
                              Webinars Featuring {firstName || 'Advisor'}
                            </Typography>
                            <Box display={'flex'} flexWrap={'wrap'}>
                              {webinars?.map((webinar) => (
                                <Box
                                  key={`webinar-card-${webinar?.id}`}
                                  marginRight={2}
                                  marginBottom={2}
                                >
                                  <Link
                                    to={{
                                      pathname: `/webinar/${webinar?.id}`,
                                      state: { from: window.location.pathname },
                                    }}
                                  >
                                    <WebinarThumbnailCardSmall
                                      title={webinar?.title}
                                      about={webinar?.about}
                                      coverTileNoDate={
                                        webinar?.coverTileNoDate || ''
                                      }
                                      date={webinar?.startTime}
                                      recording_url={webinar?.recording_url}
                                    />
                                  </Link>
                                </Box>
                              ))}
                            </Box>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
            </>
          );
        }}
      </Query>

      <FooterNew />
    </>
  );
};

export default MentorDetailPage;
