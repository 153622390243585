import { FC } from 'react';
import {
  makeStyles,
  createStyles,
  Container,
  Box,
  Typography,
  Theme,
} from '@material-ui/core';
import {Link} from 'react-router-dom'

import Colors from '../../../common/colors';
import Images from '../../../img';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },

    intakeHeader: {
      background: Colors.WHITE_ONE,
      boxShadow: `0px 2px 8px rgba(0, 0, 0, 0.05)`,
      padding: '27px 0',

      [theme.breakpoints.down('sm')]: {
        padding: '27px 0',
      },
    },

    mainLogo: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      maxWidth: 144,

      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        transform: 'none',
      },
    },
  })
);

interface Props {
  title: string;
}

const IntakeFormHeader: FC<Props> = ({ title }) => {
  const classes = useStyles();
  return (
    <Box component="header" className={classes.intakeHeader}>
      <Container maxWidth={false}>
        <Box position="relative" className={classes.headerContainer}>
          <Link to="/home" className={classes.mainLogo}>
            <img src={Images.HEADER_LOGO} alt="main logo" />
          </Link>

          <Box textAlign="center">
            <Typography variant="body1">
              <strong>{title}</strong>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default IntakeFormHeader;
