import React, { useContext, FormEvent } from 'react';
import { useStyles } from './styles';
import { Mutation } from 'react-apollo';
import { AppContext } from '../../contexts';
import { Formik, Form, Field } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Button,
  Typography,
  Box,
  CircularProgress,
  Grid,
  TextField as MaterialField,
  ThemeProvider,
} from '@material-ui/core';

import { TextField } from '../../components/fields';
import SmallLogo from '../../components/layout/SmallLogo';
import ValidatationCheckMark from '../../components/ValidationCheckMark';

import { TOKEN, SHOW_PARENT_MODAL } from '../../constants';
import { handleMutationError } from '../../common/utility';
import { PasswordSchema } from '../login/LeadRegistration/lib/validationSchemas';
import MODAL_CHECK from '../../img/modal-check.svg';
import { customTheme } from '../MaterializeCss';
import { CREATE_MBA_USER_MUTATION } from '../../graphql/mutations/mbaGraduateProgram';
import {
  CreateMBAUserMutation,
  CreateMBAUserMutationVariables,
} from '../../graphql/mutations/mbaGraduateProgram/__generated__/CreateMBAUserMutation';

interface Props extends RouteComponentProps {
  toggleAlert: Function;
  parentorStudent: 'Parent' | 'Student';
  partnerKey?: string;
  closeModal?: Function;
  leadViewData: any;
  _goback?: string | null;
  isUpcoming?: string | null;
  createdLeadId: string;
}

const PasswordSetMBAForm = ({
  toggleAlert,
  history,
  closeModal,
  parentorStudent,
  partnerKey,
  leadViewData,
  createdLeadId,
  _goback,
  isUpcoming,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { setIsLoggedIn } = useContext(AppContext);
  const webinarId = _goback?.split('/')[2];

  return (
    <Mutation<CreateMBAUserMutation, CreateMBAUserMutationVariables>
      mutation={CREATE_MBA_USER_MUTATION}
    >
      {(createLeadUserMutation: Function) => {
        return (
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
            validateOnChange
            validationSchema={PasswordSchema}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const result = await createLeadUserMutation({
                  variables: {
                    password: values.password,
                    ...leadViewData,
                    userRole: parentorStudent,
                    LeadId: createdLeadId,
                    partnerKey,
                  },
                });

                const {
                  data: {
                    createMBAUser: { token, status },
                  },
                } = result;

                const firstTimeLogin = true;
                localStorage.setItem('firstTimeLogin', String(firstTimeLogin));
                localStorage.setItem(TOKEN, token);
                setIsLoggedIn(true);
                document.cookie = 'ca-cookie-notice=true';
                if (
                  status &&
                  _goback?.includes('webinar') &&
                  isUpcoming === 'true' &&
                  parentorStudent === 'Student'
                ) {
                  history.push(`webinar/${webinarId}?r=t`);
                }

                if (
                  (_goback && isUpcoming === 'false') ||
                  (_goback && !isUpcoming)
                ) {
                  history.goBack();
                }
                localStorage.setItem(SHOW_PARENT_MODAL, 'true');
                closeModal && closeModal();
              } catch (error: any) {
                setSubmitting(false);
                console.log('error: ', error);
                handleMutationError(error, (errorMessage) => {
                  console.log('errorMessage: ', errorMessage);
                  toggleAlert('danger', errorMessage.message);
                });
              }
            }}
          >
            {({
              isSubmitting,
              setFieldValue,
              values,
              touched,
              isValid,
              errors,
            }) => {
              const letters = /[a-zA-Z]/g;
              const numbers = /[0-9]/g;
              return (
                <Form>
                  <Box maxWidth={390} margin="0 auto" width="100%">
                    <Box display="flex" alignItems="center" marginBottom={7.5}>
                      <Box
                        maxWidth={48}
                        marginRight={2}
                        className="hide-mobile"
                      >
                        <SmallLogo />
                      </Box>

                      <Box component="h4">Almost Done!</Box>
                    </Box>

                    <Box
                      paddingBottom={2}
                      marginLeft={3}
                      className={classes.checkListHeading}
                    >
                      Create a password to access your student portal:
                    </Box>
                  </Box>

                  <Box maxWidth={370} margin="0px auto">
                    <Box className={classes.checkList}>
                      <img src={MODAL_CHECK} alt="check" />
                      <Typography variant="body2">
                        Register for upcoming webinars
                      </Typography>
                    </Box>

                    <Box className={classes.checkList}>
                      <img src={MODAL_CHECK} alt="check" />
                      <Typography variant="body2">
                        Watch webinar replays
                      </Typography>
                    </Box>

                    <Box className={classes.checkList} paddingBottom={4.5}>
                      <img src={MODAL_CHECK} alt="check" />
                      <Typography variant="body2">
                        Update your student profile (Coming Soon!)
                      </Typography>
                    </Box>

                    <Grid className={classes.createPassword}>
                      <Field
                        variant="filled"
                        value={
                          parentorStudent === 'Student'
                            ? leadViewData.studentEmail
                            : leadViewData.parentEmail
                        }
                        name="userEmail"
                        component={TextField}
                        label={`${
                          parentorStudent === 'Student'
                            ? "Student's"
                            : "Parent's"
                        } Email`}
                        placeholder={`${
                          parentorStudent === 'Student'
                            ? "Student's"
                            : "Parent's"
                        } Email`}
                        disabled={true}
                        onChange={(e: FormEvent<HTMLInputElement>) => {
                          const {
                            currentTarget: { value },
                          } = e;
                          setFieldValue('studentEmail', value);
                        }}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        className={classes.textfield}
                      />

                      <Grid
                        className={`${classes.fieldContainer} custom-margin`}
                      >
                        <Field name="aidOption" type="password">
                          {({ field }: any) => (
                            <MaterialField
                              label={'Password'}
                              type={'password'}
                              placeholder={'Password'}
                              name="password"
                              variant="filled"
                              value={field.value}
                              onChange={(e: any) => {
                                const {
                                  currentTarget: { value },
                                } = e;
                                setFieldValue('password', value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              className={classes.textfield}
                            />
                          )}
                        </Field>

                        <Field
                          type="password"
                          name="confirmPassword"
                          component={TextField}
                          variant="filled"
                          label="Confirm Password"
                          placeholder="Confirm Password"
                          onChange={(e: FormEvent<HTMLInputElement>) => {
                            const {
                              currentTarget: { value },
                            } = e;
                            setFieldValue('confirmPassword', value);
                          }}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          className={classes.textfield}
                        />
                      </Grid>

                      <Box display="flex" justifyContent="space-between">
                        <ValidatationCheckMark
                          helperText="8 character minimum"
                          type={
                            !touched.password && values.password.length === 0
                              ? ``
                              : values.password && values.password.length >= 8
                              ? `success`
                              : `error`
                          }
                        />
                        <ValidatationCheckMark
                          helperText="Contain 1 letter"
                          type={
                            !touched.password && values.password.length === 0
                              ? ''
                              : letters.test(values.password)
                              ? `success`
                              : `error`
                          }
                        />
                        <ValidatationCheckMark
                          helperText="Contain 1 number"
                          type={
                            !touched.password && values.password.length === 0
                              ? ''
                              : numbers.test(values.password)
                              ? `success`
                              : `error`
                          }
                        />
                      </Box>
                    </Grid>
                    <ThemeProvider theme={customTheme}>
                      <Box marginTop={3.2}>
                        <Button
                          variant="contained"
                          type="submit"
                          color="secondary"
                          fullWidth
                          disabled={!isValid || isSubmitting}
                          id="go-to-dashboard"
                          className="goDashboard"
                        >
                          Go To My Dashboard
                          {isSubmitting && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </Button>
                      </Box>
                    </ThemeProvider>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Mutation>
  );
};

export default withRouter(PasswordSetMBAForm);
