import { Typography } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FC } from 'react';

import Colors from '../common/colors';
import Fonts from '../common/fonts';

interface PropsType {
  variant?: string;
  message?: string;
  toggleAlert: () => void;
  fullWidth?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    fabClose: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      width: '35px',
      height: '30px',
      position: 'absolute',
      right: '2px',
      top: -1,

      '& img': {
        margin: '0px !important',
      },

      '&:hover': {
        backgroundColor: 'transparent',
      },

      '&:focus': {
        outline: 'none',
      },
    },

    alertBox: {
      padding: '8px 15px',
      position: 'relative',
      borderRadius: 5,
      textAlign: 'left',
      maxWidth: 375,
      width: '100%',
      margin: '0px auto',
      marginTop: 30,
    },

    fullWidth: {
      maxWidth: '100%',
      marginBottom: 20,
    },

    alertMessage: {
      fontFamily: Fonts.INTER,
      fontSize: 12,
    },
  })
);

const CLOSE_IC = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75694 1.42361C9.91916 1.26031 10.0005 1.06392 10 0.833336C10.0005 0.602752 9.91916 0.406364 9.75694 0.243058C9.59364 0.080836 9.39725 -0.000538966 9.16666 2.69989e-06C8.93608 -0.000540355 8.73969 0.0808402 8.57639 0.243058L5 3.81945L1.42361 0.243058C1.26031 0.080836 1.06392 -0.000538966 0.833336 2.69989e-06C0.602752 -0.000540355 0.406364 0.0808402 0.243058 0.243058C0.080836 0.406364 -0.000538966 0.602752 2.69989e-06 0.833336C-0.000540355 1.06392 0.0808402 1.26031 0.243058 1.42361L3.81945 5L0.243058 8.57639C0.080836 8.73969 -0.000538966 8.93608 2.69989e-06 9.16666C-0.000540355 9.39725 0.0808402 9.59364 0.243058 9.75694C0.406364 9.91916 0.602752 10.0005 0.833336 10C1.06392 10.0005 1.26031 9.91916 1.42361 9.75694L5 6.18055L8.57639 9.75694C8.73969 9.91916 8.93608 10.0005 9.16666 10C9.39725 10.0005 9.59364 9.91916 9.75694 9.75694C9.91916 9.59364 10.0005 9.39725 10 9.16666C10.0005 8.93608 9.91916 8.73969 9.75694 8.57639L6.18055 5L9.75694 1.42361Z"
      fill="currentColor"
    />
  </svg>
);

const NewCustomAlert: FC<PropsType> = ({
  variant,
  message,
  toggleAlert,
  fullWidth,
}) => {
  const classes = useStyles();

  const showAlert = !!(variant && message);
  const variantColor =
    variant === 'success'
      ? 'rgba(69, 206, 147, 0.25)'
      : 'rgba(235, 87, 87, 0.6)';
  const textColor = variant === 'success' ? Colors.BLACK_TEN : Colors.WHITE_ONE;

  return (
    <>
      {showAlert && (
        <Grid
          className={
            fullWidth
              ? clsx(classes.alertBox, classes.fullWidth)
              : classes.alertBox
          }
          style={{ backgroundColor: variantColor }}
        >
          <Fab
            onClick={toggleAlert}
            className={classes.fabClose}
            style={{ color: textColor }}
          >
            {CLOSE_IC()}
          </Fab>

          <Typography
            className={classes.alertMessage}
            style={{ color: textColor }}
          >
            {message}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default NewCustomAlert;
