import { FC } from 'react';
import { makeStyles, createStyles, Box, Typography } from '@material-ui/core';

import Colors from '../../../common/colors';

const useStyles = makeStyles(() =>
  createStyles({
    stepContainer: {
      margin: '0 -2.8px',
    },

    step: {
      flex: 1,
      height: 4,
      margin: '0 2.8px',
      borderRadius: 20,
    },
  })
);

interface Props {
  totalSteps: number;
  currentStep: number;
  stepHeading?: string;
  isContinue?: boolean;
}

const IntakeFormStepBar: FC<Props> = ({
  totalSteps,
  currentStep,
  stepHeading,
  isContinue,
}) => {
  const classes = useStyles();

  return (
    <Box pb={5}>
      <Box color={isContinue ? Colors.BLACK_TEN : Colors.BLUE_SEVEN} pb={2}>
        <Typography variant="overline" color="inherit">
          {isContinue ? 'Half-way there!' : stepHeading}
        </Typography>
      </Box>

      <Box display="flex" className={classes.stepContainer}>
        {new Array(totalSteps).fill('').map((item, index) => (
          <Box
            key={`intake-steps-${index}`}
            bgcolor={
              index + 1 <= currentStep ? Colors.BLUE_SEVEN : Colors.WHITE_SIX
            }
            className={classes.step}
          />
        ))}
      </Box>
    </Box>
  );
};

export default IntakeFormStepBar;
