import { Box, Card, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';

import Colors from '../../../common/colors';
import Loader from '../../../components/Loader';

import { ALL_STUDENT_APPLICATIONS_QUERY } from './gql';
import {
  AllStudentApplicationsQuery,
  AllStudentApplicationsQuery_allStudentApplications_StudentApplications,
} from './gql/__generated__/AllStudentApplicationsQuery';

import moment from 'moment';
import fonts from '../../../common/fonts';
import Drawer from '../../../components/CustomDrawer';
import COLLEGE_DEFAULT_IMG from '../../../img/college-default-icon.svg';
import Next_Image from '../../../img/next-link.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    applicationsCard: {
      marginBottom: 32,
      borderRadius: 25,

      [theme.breakpoints.down('sm')]: {
        marginBottom: 17,
        borderRadius: 15,
      },

      '& .drawerAddColleges': {
        padding: '33px 37px 44px',

        [theme.breakpoints.down('sm')]: {
          padding: '20px 16px',
        },

        '& .body1': {
          fontFamily: fonts.INTER,
          fontWeight: 600,
          fontSize: 18,
          letterSpacing: '-0.014em',
          color: '#525454',
          marginBottom: 11,

          [theme.breakpoints.down('sm')]: {
            fontSize: 16,
          },
        },

        '& .caption': {
          fontFamily: fonts.INTER,
          fontWeight: 'normal',
          fontSize: 16,
          letterSpacing: '-0.011em',
          color: '#7D7F7F',
          marginBottom: 23,
        },
      },
    },

    applicationsHeader: {
      padding: '24px 30px',
      borderBottom: `1px solid ${Colors.WHITE_SIX}`,

      [theme.breakpoints.down('sm')]: {
        padding: '15px 20px',
      },
    },

    collegeInfoWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '15px 30px',
      borderBottom: `1px solid ${Colors.WHITE_SIX}`,
      maxWidth: '100%',

      '&:hover': {
        background: Colors.GRAY_BOREDER,
      },

      [theme.breakpoints.down('sm')]: {
        padding: 15,
      },

      '& h5': {
        flex: 1,
        paddingRight: 10,
      },
    },

    collegeLogo: {
      height: 67,
      width: 67,
      borderRadius: '50%',
      marginRight: 29,

      '& img': {
        objectFit: 'contain',
        objectPosition: 'center',
      },

      [theme.breakpoints.down('xs')]: {
        height: 40,
        width: 40,
        minWidth: 40,
        marginRight: 10,
      },
    },

    exploreMore: {
      padding: 19,
      textAlign: 'center',
      color: Colors.BLUE_SEVEN,

      [theme.breakpoints.down('sm')]: {
        padding: 15,
      },
    },

    dangerSubtitle: {
      color: '#7D7F7F',
      textTransform: 'uppercase',
      fontWeight: 600,
    },
  })
);
const findDueDays = (
  data: AllStudentApplicationsQuery_allStudentApplications_StudentApplications
): string => {
  if (data && data?.applicationRequirement) {
    const { decisionDeadline } = data;

    if (!decisionDeadline) return '';

    const deadlineDueIn =
      decisionDeadline && moment(decisionDeadline).diff(moment(), 'days');

    return deadlineDueIn
      ? deadlineDueIn > 0
        ? `Due In ${deadlineDueIn} Days`
        : `Deadline Passed ${deadlineDueIn * -1} Days ago`
      : '';
  }
  return '';
};

const ApplicationsCard = () => {
  const classes = useStyles();

  return (
    <Query<AllStudentApplicationsQuery>
      query={ALL_STUDENT_APPLICATIONS_QUERY}
      fetchPolicy="network-only"
      variables={{ limit: 5 }}
    >
      {({ data, loading, networkStatus, refetch }) => {
        if (loading && networkStatus !== 4) return <Loader />;
        if (!data || !data.allStudentApplications) return null;
        if (!data?.allStudentApplications?.StudentApplications) return null;
        let {
          allStudentApplications: { StudentApplications },
        } = data;

        if (StudentApplications?.length > 5)
          StudentApplications = StudentApplications.slice(0, 5);

        return (
          <Card className={`${classes.applicationsCard} no-padding`}>
            <Box className={classes.applicationsHeader}>
              <Typography variant="h4">My Applications</Typography>
            </Box>
            {StudentApplications.length > 0 ? (
              <>
                {StudentApplications.map((item, index) => (
                  <Link
                    key={index}
                    to={`/applications/${item?.id}`}
                    className={classes.collegeInfoWrapper}
                  >
                    <Box display="flex" alignItems="center">
                      <Box className={classes.collegeLogo}>
                        {item.applicationRequirement?.college?.logo ? (
                          <img
                            width="100%"
                            height="100%"
                            src={item.applicationRequirement?.college?.logo}
                          />
                        ) : (
                          <img
                            width="75%"
                            height="100%"
                            src={COLLEGE_DEFAULT_IMG}
                            alt="dummy user"
                          />
                        )}
                      </Box>

                      <Box>
                        <Typography variant="h5">
                          {item.applicationRequirement?.college?.name}
                        </Typography>

                        <Typography
                          variant="body2"
                          className={classes.dangerSubtitle}
                        >
                          {item && findDueDays(item)}
                        </Typography>
                      </Box>
                    </Box>

                    <img src={Next_Image} alt="next" />
                  </Link>
                ))}
                <Link to="/applications">
                  <Grid className={classes.exploreMore}>
                    <Typography variant="h6" color="inherit">
                      VIEW ALL APPLICATIONS
                    </Typography>
                  </Grid>
                </Link>
              </>
            ) : (
              <Box className="drawerAddColleges">
                <Typography className="body1">
                  No applications have been added to your list!
                </Typography>
                <Typography className="caption">Get started below</Typography>

                <Box>
                  <Drawer refetch={refetch} />
                </Box>
              </Box>
            )}
          </Card>
        );
      }}
    </Query>
  );
};
export default ApplicationsCard;
