import gql from 'graphql-tag';

/**
 * Fetch advisors available timeslots by selected duration (internally get form nylas)
 */
export const FETCH_ADVISOR_AVAILABILITY_TIMESLOTS = gql`
  query AdvisorAvailableTimeslots(
    $AdvisorIds: [Int!]!
    $duration: Int!
    $date: String!
    $offset: Float!
    $monthOrDay: String!
    $studentCurrentTime: Date!
    $isUserInDSTtimeZone: Boolean!
  ) {
    advisorAvailableTimeslots(
      input: {
        AdvisorIds: $AdvisorIds
        duration: $duration
        date: $date
        offset: $offset
        monthOrDay: $monthOrDay
        studentCurrentTime: $studentCurrentTime
        isUserInDSTtimeZone: $isUserInDSTtimeZone
      }
    ) {
      time_slots {
        end
        start
        status
      }
      available_days
    }
  }
`;
export const SEND_ADVISOR_EMAIL_FOR_ZERO_AVAILABILITY = gql`
  mutation sendEmailToAdvisors($advisorId: Int) {
    sendAdvisorEmailForZeroAvailability(advisorId: $advisorId) {
      success
    }
  }
`;
export interface Participant {
  name: string;
  email: string;
  id: number;
  role: string;
}

export const MENTOR_FUTURE_AVAILABILITY = gql`
  query studentMentorAvailability($advisorIds: [Int]) {
    studentMentorAvailability(advisorIds: $advisorIds) {
      advisorId
      nextAvailabilty
    }
  }
`;

export const BOOK_TIMESLOT = gql`
  mutation createNylasCalendarEvent(
    $AdvisorId: Int!
    $start_time: Int!
    $end_time: Int!
    $participants: [Participant!]!
    $note: String
    $isTrial: Boolean
    $areasOfInterest: [String]
    $consultantId: Int
  ) {
    createNylasCalendarEvent(
      input: {
        AdvisorId: $AdvisorId
        start_time: $start_time
        end_time: $end_time
        participants: $participants
        note: $note
        isTrial: $isTrial
        areasOfInterest: $areasOfInterest
        consultantId: $consultantId
      }
    ) {
      success
      message
    }
  }
`;

export const ALL_ADVISOR_BOOKINGS = gql`
  query allAdvisorBookingsQuery(
    $status: AdvisorMeetingStatus
    $bookingStartTime: String
    $bookingEndTime: String
    $searchQuery: String
    $limit: Int
    $page: Int
    $sortByDate: Boolean
  ) {
    allAdvisorBookings(
      input: {
        status: $status
        bookingStartTime: $bookingStartTime
        bookingEndTime: $bookingEndTime
        searchQuery: $searchQuery
        limit: $limit
        page: $page
        sortByDate: $sortByDate
      }
    ) {
      advisorBookings {
        id
        startTime
        endTime
        StudentId
        event_id
        status
        title
        zoomLink
        note
        AdvisorId
        createdAt
        canceledReason
        otherReason
        student {
          firstName
          lastName
          pictureUrl
        }
        allParticipants {
          firstName
          id
          email
          lastName
          pictureUrl
          roles
          zoomLink
        }
        cancelledByUser {
          id
          firstName
          lastName
        }
        review {
          id
          rating
          message
        }
      }
      count
      nextRecord
    }
  }
`;

export const DELETE_BOOKING = gql`
  mutation deleteNylasEvent(
    $eventId: Int!
    $AdvisorId: Int!
    $reason: String!
    $otherReason: String
  ) {
    deleteNylasEvent(
      input: {
        eventId: $eventId
        AdvisorId: $AdvisorId
        reason: $reason
        otherReason: $otherReason
      }
    ) {
      message
      success
    }
  }
`;

export const CHECK_REVIEW_SUBMITTED_FOR_MEETING = gql`
  query checkReviewSubmittedForMeetingQuery($studentId: Int!, $mentorId: Int) {
    checkReviewSubmittedForMeeting(studentId: $studentId, mentorId: $mentorId) {
      advisorBooking {
        id
        allParticipants {
          id
          firstName
          lastName
          pictureUrl
          roles
        }
        AdvisorId
        startTime
      }
      mentorReview {
        id
        MentorId
      }
    }
  }
`;

export const ADD_MENTOR_REVIEW_RATING = gql`
  mutation addMentorReviewFeedback(
    $studentId: Int!
    $mentorIds: [Int]
    $rating: Float
    $description: String
    $reviewableId: Int
    $ReviewType: String
  ) {
    addMentorReview(
      input: {
        studentId: $studentId
        mentorIds: $mentorIds
        rating: $rating
        description: $description
        reviewableId: $reviewableId
        ReviewType: $ReviewType
      }
    ) {
      success
      message
    }
  }
`;

export const MENTO_REVIEW_DIALOG_CLOSED = gql`
  mutation mentorReviewDialogClosed($meetingId: Int!) {
    mentorReviewDialogClosed(meetingId: $meetingId) {
      success
      message
    }
  }
`;

export const GET_USER_AREA_OF_INTERESTS = gql`
  query getUserAreaOfInterests($userId: Int!) {
    getProfileData(userId: $userId) {
      areasOfInterest
    }
    getAreasOfInterest {
      id
      displayName
    }
  }
`;
