import clsx from 'clsx';
import {
  Box,
  Grid,
  Button,
  Typography,
  ThemeProvider,
  CircularProgress,
  TextField as MaterialField,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { FormEvent, useContext } from 'react';
import { Mutation } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppContext } from '../../../contexts';
import { useStyles } from '../styles';

import { TextField } from '../../../components/fields';
import SmallLogo from '../../../components/layout/SmallLogo';
import ValidatationCheckMark from '../../../components/ValidationCheckMark';

import { handleMutationError } from '../../../common/utility';
import { SHOW_PARENT_MODAL, TOKEN } from '../../../constants';
import { PasswordSchema } from './lib/validationSchemas';

import MODAL_CHECK from '../../../img/modal-check.svg';

import { customTheme } from '../../MaterializeCss';
import { CREATE_LEAD_USER_MUTATION } from './gql';
import {
  CreateLeadUserMutation,
  CreateLeadUserMutationVariables,
} from './gql/__generated__/CreateLeadUserMutation';
import { CheckListIcon } from '../../../common/assets/svgs';

interface Props extends RouteComponentProps {
  toggleAlert: Function;
  parentorStudent: 'Parent' | 'Student';
  partnerKey?: string;
  closeModal?: Function;
  leadViewData: any;
  _goback?: string | null;
  isUpcoming?: string | null;
  createdLeadId: string;
  optInSMS: Boolean;
}

const PasswordSetForm = ({
  toggleAlert,
  history,
  closeModal,
  parentorStudent,
  partnerKey,
  leadViewData,
  createdLeadId,
  _goback,
  isUpcoming,
  optInSMS,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { setIsLoggedIn } = useContext(AppContext);
  const webinarId = _goback?.split('/')[2];

  return (
    <Mutation<CreateLeadUserMutation, CreateLeadUserMutationVariables>
      mutation={CREATE_LEAD_USER_MUTATION}
    >
      {(createLeadUserMutation: Function) => {
        return (
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
              optInSMS,
            }}
            validateOnChange
            validationSchema={PasswordSchema}
            onSubmit={async (
              values,
              {
                setSubmitting,
              }: { setSubmitting: (isSubmitting: boolean) => void }
            ) => {
              try {
                const result = await createLeadUserMutation({
                  variables: {
                    password: values.password,
                    ...leadViewData,
                    userRole: parentorStudent,
                    LeadId: createdLeadId,
                    partnerKey,
                    optInSMS: values.optInSMS,
                  },
                });

                const {
                  data: {
                    createLeadUser: { token, status },
                  },
                } = result;

                const firstTimeLogin = true;
                localStorage.setItem('firstTimeLogin', String(firstTimeLogin));
                localStorage.setItem(TOKEN, token);
                setIsLoggedIn(true);
                document.cookie = 'ca-cookie-notice=true';
                if (
                  status &&
                  _goback?.includes('webinar') &&
                  isUpcoming === 'true' &&
                  parentorStudent === 'Student'
                ) {
                  history.push(`webinar/${webinarId}?r=t`);
                }

                if (
                  (_goback && isUpcoming === 'false') ||
                  (_goback && !isUpcoming)
                ) {
                  history.goBack();
                }
                localStorage.setItem(SHOW_PARENT_MODAL, 'true');
                closeModal && closeModal();
              } catch (error: any) {
                setSubmitting(false);
                console.log('error: ', error);
                handleMutationError(error, (errorMessage) => {
                  console.log('errorMessage: ', errorMessage);
                  toggleAlert('danger', errorMessage.message);
                });
              }
            }}
          >
            {({
              isSubmitting,
              setFieldValue,
              values,
              touched,
              isValid,
              errors,
            }) => {
              const letters = /[a-zA-Z]/g;
              const numbers = /[0-9]/g;
              return (
                <Form>
                  <Box maxWidth={435} margin="0 auto" width="100%">
                    <Box display="flex" alignItems="center" marginBottom={3}>
                      <Box
                        maxWidth={48}
                        marginRight={2}
                        className="hide-mobile"
                      >
                        <SmallLogo />
                      </Box>

                      <Box component="h4">Almost Done!</Box>
                    </Box>

                    <Box
                      paddingBottom={3}
                      className={classes.checkListHeadingDone}
                    >
                      Create your password to access the student portal.
                    </Box>
                  </Box>

                  <Box maxWidth={435} margin="0px auto">
                    <Box className={classes.checkList} pb={2}>
                      <Box minWidth={41}>
                        <CheckListIcon />
                      </Box>
                      <Typography variant="body2">
                        Connect with our network of 300+ admissions experts.
                      </Typography>
                    </Box>

                    <Box className={classes.checkList} pb={2}>
                      <Box minWidth={41}>
                        <CheckListIcon />
                      </Box>
                      <Typography variant="body2">
                        Boost your academic profile with our database of
                        thousands of extracurricular opportunities and programs.
                      </Typography>
                    </Box>

                    <Box className={classes.checkList} pb={2}>
                      <Box minWidth={41}>
                        <CheckListIcon />
                      </Box>
                      <Typography variant="body2">
                        Explore admissions and campus data for 1,200+ U.S.
                        colleges and universities.
                      </Typography>
                    </Box>

                    <Box className={classes.checkList} paddingBottom={4.5}>
                      <Box minWidth={41}>
                        <CheckListIcon />
                      </Box>
                      <Typography variant="body2">
                        Manage your college list and track your applications
                        online.
                      </Typography>
                    </Box>

                    <Grid
                      className={clsx(
                        classes.createPassword,
                        classes.createPasswordUpdated
                      )}
                    >
                      <Field
                        variant="filled"
                        value={
                          parentorStudent === 'Student'
                            ? leadViewData.studentEmail
                            : leadViewData.parentEmail
                        }
                        name="userEmail"
                        component={TextField}
                        label={`${
                          parentorStudent === 'Student'
                            ? "Student's"
                            : "Parent's"
                        } Email`}
                        placeholder={`${
                          parentorStudent === 'Student'
                            ? "Student's"
                            : "Parent's"
                        } Email`}
                        disabled={true}
                        onChange={(e: FormEvent<HTMLInputElement>) => {
                          const {
                            currentTarget: { value },
                          } = e;
                          setFieldValue('studentEmail', value);
                        }}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        className={classes.textfield}
                      />

                      <Grid
                        className={`${clsx(
                          classes.fieldContainer,
                          classes.fieldContainerWidth
                        )} custom-margin`}
                      >
                        <Field name="aidOption" type="password">
                          {({ field }: any) => (
                            <MaterialField
                              label={'Password'}
                              type={'password'}
                              placeholder={'Password'}
                              name="password"
                              variant="filled"
                              value={field.value}
                              onChange={(e: any) => {
                                const {
                                  currentTarget: { value },
                                } = e;
                                setFieldValue('password', value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              className={classes.textfield}
                            />
                          )}
                        </Field>

                        <Field
                          type="password"
                          name="confirmPassword"
                          component={TextField}
                          variant="filled"
                          label="Confirm Password"
                          placeholder="Confirm Password"
                          onChange={(e: FormEvent<HTMLInputElement>) => {
                            const {
                              currentTarget: { value },
                            } = e;
                            setFieldValue('confirmPassword', value);
                          }}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          className={classes.textfield}
                        />
                      </Grid>

                      <Box display="flex" justifyContent="space-between">
                        <ValidatationCheckMark
                          helperText="8 character minimum"
                          type={
                            !touched.password && values.password.length === 0
                              ? ``
                              : values.password && values.password.length >= 8
                              ? `success`
                              : `error`
                          }
                        />
                        <ValidatationCheckMark
                          helperText="Contain 1 letter"
                          type={
                            !touched.password && values.password.length === 0
                              ? ''
                              : letters.test(values.password)
                              ? `success`
                              : `error`
                          }
                        />
                        <ValidatationCheckMark
                          helperText="Contain 1 number"
                          type={
                            !touched.password && values.password.length === 0
                              ? ''
                              : numbers.test(values.password)
                              ? `success`
                              : `error`
                          }
                        />
                      </Box>
                    </Grid>
                    <ThemeProvider theme={customTheme}>
                      <Box marginTop={3.2}>
                        <Button
                          variant="contained"
                          type="submit"
                          color="secondary"
                          fullWidth
                          disabled={!isValid || isSubmitting}
                          id="go-to-dashboard"
                          className="goDashboard"
                        >
                          Go To My Dashboard
                          {isSubmitting && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </Button>
                      </Box>
                    </ThemeProvider>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Mutation>
  );
};

export default withRouter(PasswordSetForm);
