// imports
import {
  Box,
  Card,
  Grid,
  Button,
  Typography,
  ThemeProvider,
  TextField as MaterialField,
} from '@material-ui/core';
import { Mutation } from 'react-apollo';
import { Field, Form, Formik } from 'formik';
import { RouteComponentProps } from 'react-router-dom';
import { FC, FormEvent, useContext, useEffect, useState } from 'react';
// components
import Loader from '../../components/Loader';
import { TextField } from '../../components/fields';
import CustomAlert from '../../components/NewCustomAlert';
import RightSidePage from './LeadRegistration/RightSidePage';
import ValidatationCheckMark from '../../components/ValidationCheckMark';
// constants, interfaces, schema
import client from '../../apollo';
import { TOKEN } from '../../constants';
import { AppContext } from '../../contexts';
import {
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from './__generated__/ResetPasswordMutation';
import { GET_USER_FROM_USER_TOKEN } from '../main/intakeForm/gql';
import { PasswordSchema } from './LeadRegistration/lib/validationSchemas';
import { getUserFromUserTokenQuery_getUserFromUserToken_user } from '../main/intakeForm/gql/__generated__/getUserFromUserTokenQuery';
// images
import CAP_LOGO from '../../img/cap-book-meeting.png';
// styles
import { useStyles } from './styles';
import fonts from '../../common/fonts';
import colors from '../../common/colors';
import { customTheme } from '../MaterializeCss';
import { GET_ALL_STUDENT_MENTORS } from '../../graphql/queries/studentMentors';
import {
  getAllStudentMentors_allStudentMentors_consultant,
  getAllStudentMentors_allStudentMentors_mentor,
} from '../../graphql/queries/studentMentors/__generated__/getAllStudentMentors';
import { RESET_PASSWORD_MUTATION } from './NewResetPasswordPage';
import NO_IMAGE from '../../img/empty-badge.svg';

type TParams = { token: string };

const ResetPasswordBookingPage: FC<RouteComponentProps<TParams>> = ({
  history,
  match: { params },
}) => {
  const initialValues = { password: '', confirmPassword: '' };
  const classes = useStyles();
  const [variant, setVariant] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [userToken, setUserToken] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const step = urlParams.get('step');
  const [advisor, setAdvisor] =
    useState<getAllStudentMentors_allStudentMentors_mentor | null>(null);
  const [consultant, setConsultant] =
    useState<getAllStudentMentors_allStudentMentors_consultant | null>(null);
  const [userInfo, setUserInfo] =
    useState<getUserFromUserTokenQuery_getUserFromUserToken_user | null>(null);

  function toggleAlert(variant?: string, message?: string) {
    setVariant(variant);
    setMessage(message);
  }

  const fetchUser = async () => {
    const { data } = await client.query({
      query: GET_USER_FROM_USER_TOKEN,
      variables: {
        userToken: userToken,
      },
      fetchPolicy: 'network-only',
    });
    if (data?.getUserFromUserToken?.user) {
      setUserInfo(data.getUserFromUserToken.user);
      const { email, roles, isPasswordSet } = data?.getUserFromUserToken?.user;
      if (isPasswordSet && step) {
        if (roles?.includes('Student')) {
          window.location.replace('/home?schedule=true');
        } else {
          window.location.replace('/login');
        }
      } else {
        setLoading(false);
      }
      setEmail(email);
    } else {
      window.location.replace('/login');
    }
  };

  const mountEffectOnce = () => {
    setUserToken(params.token);
    if (userToken) fetchUser();
  };

  const { setIsLoggedIn } = useContext(AppContext);

  useEffect(() => {
    const fetchCapStudentAdvisingTeam = async () => {
      const { data } = await client.query({
        query: GET_ALL_STUDENT_MENTORS,
        fetchPolicy: 'network-only',
        variables: { id: userInfo?.id },
      });
      if (data && data.allStudentMentors) {
        setAdvisor(data.allStudentMentors[0].mentor);
        setConsultant(data.allStudentMentors[0].consultant);
      }
    };
    if (userInfo) {
      fetchCapStudentAdvisingTeam();
    }
  }, [userInfo]);

  useEffect(mountEffectOnce, [userToken]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Card className={`${classes.cardModal} custom-card`}>
          <Box className={classes.registerContainer}>
            <Box className={classes.registerLeft}>
              <CustomAlert
                variant={variant}
                message={message}
                toggleAlert={toggleAlert}
              />

              <Box
                maxWidth={370}
                margin="10px auto"
                className={classes.collegeAidProBooking}
              >
                <Box textAlign="right" mb={4}>
                  <img src={CAP_LOGO} alt="logo" width="145" />
                </Box>

                <ThemeProvider theme={customTheme}>
                  <Box>
                    <Typography variant="h3">
                      Hi, {userInfo?.firstName} {userInfo?.lastName}
                    </Typography>
                    <Typography variant="body1" className="capSubtitle">
                      Welcome to CollegeAdvisor.com! Please complete setting up
                      your account and proceed to scheduling your first session
                      with your Advisor!
                    </Typography>

                    {(advisor || consultant) && (
                      <>
                        <Typography variant="h6">YOUR ADVISING TEAM</Typography>

                        <Box
                          mb={3}
                          display="flex"
                          alignItems="center"
                          className={classes.advisorConsultantCard}
                        >
                          <Box
                            mr={1.75}
                            width={42}
                            height={42}
                            overflow="hidden"
                            borderRadius="50%"
                            className="advisorConsultantAvatar"
                          >
                            {advisor?.pictureUrl ? (
                              <img
                                src={advisor?.pictureUrl}
                                alt="avatar"
                                className="imgObjectFit"
                                loading="lazy"
                              />
                            ) : (
                              <img src={NO_IMAGE} alt="dummy user" />
                            )}
                          </Box>

                          <Box>
                            <Typography variant="h6">
                              {advisor?.firstName} {advisor?.lastName}
                            </Typography>
                            <Typography variant="body2">
                              {advisor?.advisorLevel},{' '}
                              {advisor?.currentCollege?.name}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          mb={5}
                          display="flex"
                          alignItems="center"
                          className={classes.advisorConsultantCard}
                        >
                          <Box
                            mr={1.75}
                            width={42}
                            height={42}
                            overflow="hidden"
                            borderRadius="50%"
                            className="advisorConsultantAvatar"
                          >
                            {consultant?.imageUrl ? (
                              <img
                                src={consultant?.imageUrl}
                                alt="avatar"
                                className="imgObjectFit"
                                loading="lazy"
                              />
                            ) : (
                              <img src={NO_IMAGE} alt="dummy user" />
                            )}
                          </Box>

                          <Box>
                            <Typography variant="h6">
                              {consultant?.firstName} {consultant?.lastName}
                            </Typography>
                            <Typography variant="body2">
                              Financial Aid Consultant, CollegeAidPro
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                </ThemeProvider>

                <Mutation<ResetPasswordMutation, ResetPasswordMutationVariables>
                  mutation={RESET_PASSWORD_MUTATION}
                >
                  {(resetPasswordMutation: Function) => {
                    return (
                      <Formik
                        initialValues={initialValues}
                        validationSchema={PasswordSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                          try {
                            const { password, confirmPassword } = values;
                            if (password !== confirmPassword) {
                              toggleAlert(
                                'danger',
                                'Password and Confirm Password not same'
                              );

                              return setSubmitting(false);
                            }
                            const { token } = params;
                            const result = await resetPasswordMutation({
                              variables: { password, token, step },
                            });
                            const {
                              data: {
                                resetPassword: {
                                  status,
                                  jwtToken,
                                  role,
                                  lastLoginAt,
                                },
                              },
                            } = result;

                            setSubmitting(false);

                            const firstTimeLogin = lastLoginAt ? false : true;
                            localStorage.setItem(
                              'firstTimeLogin',
                              String(firstTimeLogin)
                            );
                            localStorage.setItem(TOKEN, jwtToken);
                            setIsLoggedIn(true);

                            if (!step && status) {
                              toggleAlert('success', 'Password updated,');
                              history.push('/');
                            } else if (!status) {
                              toggleAlert(
                                'danger',
                                step
                                  ? 'An error occurred while setting password'
                                  : 'Unable to reset password, may be password reset token expired, request another one.'
                              );
                            }

                            if (step && status) {
                              if (role === 'Student') {
                                history.push('/home?schedule=true');
                              } else history.push('/');
                            }
                          } catch (error) {
                            setSubmitting(false);
                            toggleAlert(
                              'danger',
                              'Something went wrong, Please try again'
                            );
                            console.log('error: ', error);
                          }
                        }}
                      >
                        {({
                          isSubmitting,
                          touched,
                          values,
                          isValid,
                          setFieldValue,
                        }) => {
                          const letters = /[a-zA-Z]/g;
                          const numbers = /[0-9]/g;
                          return (
                            <Form>
                              <Box className="textFieldLabel">
                                <Field
                                  fullWidth
                                  value={email}
                                  disabled={true}
                                  variant="filled"
                                  name="userEmail"
                                  label="Your Email"
                                  component={TextField}
                                  placeholder="Your Email"
                                  className={classes.textfield}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={(
                                    e: FormEvent<HTMLInputElement>
                                  ) => {
                                    const {
                                      currentTarget: { value },
                                    } = e;
                                    setFieldValue('studentEmail', value);
                                  }}
                                />
                              </Box>

                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  className="textFieldLabelSpacing"
                                >
                                  <Field name="aidOption" type="password">
                                    {({ field }: any) => (
                                      <MaterialField
                                        label={'Create Password'}
                                        type={'password'}
                                        placeholder={'Create Password'}
                                        variant="filled"
                                        value={field.value}
                                        onChange={(e: any) => {
                                          const {
                                            currentTarget: { value },
                                          } = e;
                                          setFieldValue('password', value);
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        className={classes.textfield}
                                      />
                                    )}
                                  </Field>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  className="textFieldLabelSpacing"
                                >
                                  <Field
                                    type="password"
                                    name="confirmPassword"
                                    component={TextField}
                                    variant="filled"
                                    label="Confirm Password"
                                    placeholder="Confirm Password"
                                    onChange={(
                                      e: FormEvent<HTMLInputElement>
                                    ) => {
                                      const {
                                        currentTarget: { value },
                                      } = e;
                                      setFieldValue('confirmPassword', value);
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    className={classes.textfield}
                                  />
                                </Grid>
                              </Grid>

                              <Box
                                marginTop={0}
                                display="flex"
                                justifyContent="space-between"
                              >
                                <ValidatationCheckMark
                                  helperText="8 character minimum"
                                  type={
                                    !touched.password &&
                                    values.password.length === 0
                                      ? ``
                                      : values.password &&
                                        values.password.length >= 8
                                      ? `success`
                                      : `error`
                                  }
                                />
                                <ValidatationCheckMark
                                  helperText="Contain 1 letter"
                                  type={
                                    !touched.password &&
                                    values.password.length === 0
                                      ? ''
                                      : letters.test(values.password)
                                      ? `success`
                                      : `error`
                                  }
                                />
                                <ValidatationCheckMark
                                  helperText="Contain 1 number"
                                  type={
                                    !touched.password &&
                                    values.password.length === 0
                                      ? ''
                                      : numbers.test(values.password)
                                      ? `success`
                                      : `error`
                                  }
                                />
                              </Box>

                              <ThemeProvider theme={customTheme}>
                                <Box marginTop={4}>
                                  <Button
                                    fullWidth
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                    className="goDashboard"
                                    disabled={!isValid || isSubmitting}
                                  >
                                    Proceed to Scheduling
                                  </Button>
                                </Box>
                              </ThemeProvider>
                            </Form>
                          );
                        }}
                      </Formik>
                    );
                  }}
                </Mutation>

                <Box
                  paddingTop={5}
                  textAlign="center"
                  fontSize={12}
                  color={colors.BLACK_TEN}
                  fontFamily={fonts.INTER}
                  className={classes.termsText}
                >
                  By creating an account, you agree to the
                  <a
                    href="https://www.collegeadvisor.com/terms-of-service"
                    target="_blank"
                  >
                    Terms of Service
                  </a>{' '}
                </Box>
              </Box>
            </Box>

            <RightSidePage />
          </Box>
        </Card>
      )}
    </>
  );
};

export default ResetPasswordBookingPage;
