// imports
import {
  Box,
  Grid,
  Theme,
  Button,
  Container,
  makeStyles,
  Typography,
  createStyles,
} from '@material-ui/core';
import { FC, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// interfaces, constants
import {
  PackageShortNames,
  WELCOME_INSTRUCTIONS,
} from '../../../common/constants';
import client from '../../../apollo';
import { AppContext } from '../../../contexts';
import { cjConfig } from '../../../common/utility';
import { GET_PREMIUM_STUDENT_OF_PARENT, GET_USER_BY_TOKEN } from './gql';
// colors, fonts
import fonts from '../../../common/fonts';
import { default as Colors, default as colors } from '../../../common/colors';
// images
import Images from '../../../img';
import MBA_WELCOME_IMG from '../../../img/wlcom-mba.png';
import { DocumentThemeIcon } from '../../../common/assets/svgs';
import NEW_WELCOME_IMG from '../../../img/client-welcome-new.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    instructionsContainer: {
      padding: '30px 0 40px 0',

      '& > div': {
        '&:not(:last-child)': {
          paddingBottom: 37,
        },
      },
    },

    instructions: {
      display: 'flex',
      padding: '32px 0px',
      alignItems: 'flex-start',
      borderBottom: `1px solid ${colors.WHITE_SEVEN}`,

      '&:last-child': {
        borderBottom: 0,
      },

      '& h6': {
        textTransform: 'none',
        fontFamily: fonts.INTER,
        fontWeight: 700,

        '& span': {
          fontWeight: 'normal',
          marginLeft: 5,
        },
      },
    },

    instructionIcon: {
      minWidth: 24,
      width: 24,
      marginRight: 20,
    },

    buttonContainer: {
      paddingBottom: 40,

      '& button': {
        minWidth: 187,
      },
    },

    welcomeContainer: {
      paddingTop: 60,
    },

    packageName: {
      textTransform: 'uppercase',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    description: {
      color: Colors.BLACK_FOURTEEN,
    },

    bgWelcome: {
      backgroundImage: `url(${NEW_WELCOME_IMG})`,
      position: 'relative',
      minHeight: '800px',
      backgroundRepeat: 'no-repeat',
      backgroundPositionY: '80px',
      backgroundSize: 'contain',

      [theme.breakpoints.down('md')]: {
        width: '60vw',
        backgroundSize: 'contain',
        backgroundPositionX: 'center',
      },

      [theme.breakpoints.down('sm')]: {
        minHeight: 450,
        backgroundPosition: 'center top',
      },
    },
    bgMBAWelcome: {
      backgroundImage: `url(${MBA_WELCOME_IMG})`,
      maxWidth: '380px',
      marginLeft: '6vw',
      [theme.breakpoints.down('md')]: {
        marginLeft: '0px',
      },
    },
  })
);

const WelcomToPackage: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();

  const [user, setUser] = useState(useContext(AppContext)?.user);
  const [AdmissionPackage, setAdmissionPackage] = useState<any>(
    user?.userSubscription?.SubscriptionPlan?.AdmissionPackage
  );
  const [parentIntakeToken, setParentIntakeToken] = useState('');
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const goToHomePage = () => {
    if (params.userToken) {
      history.push(`/reset-password/${params.userToken}?step=2`);
    } else {
      history.push('/home?schedule=true');
    }
  };

  // MBA_CONSTANT: Also check the user of isMBAGrad.
  const isMBAGrad = Boolean(user?.isMBAGrad);
  const isSmartMatchId = Boolean(user?.SmartMatchId);

  const handleLetsGo = () => {
    const email: any = user?.email;
    const emailEncoded = encodeURIComponent(email); // encode the email
    if (isMBAGrad) {
      // block: MBAGrad without password
      history.push(`/reset-password/${params.userToken}?step=2`);
    } else {
      if(!params.userToken) {
        history.push(
          `/intake-form/${parentIntakeToken || user?.intakeFormToken}?email=${emailEncoded}`
        );
      } else {
        history.push(
          `/intake-form/${parentIntakeToken || user?.intakeFormToken}?userToken=${params.userToken}&email=${emailEncoded}`
        );
      }
    }
  };

  const getUserByToken = () => {
    if (params.userToken && !user) {
      try {
        const apiCalling = async () => {
          const { data } = await client.query({
            query: GET_USER_BY_TOKEN,
            variables: { userToken: params.userToken },
          });

          if (data && data.getUserByToken) {
            setUser(data.getUserByToken);
          }
        };
        apiCalling();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getPremiumStudentOfParent = (ParentId: number) => {
    try {
      const apiCalling = async () => {
        const StudentId = localStorage.getItem('PremiumStudentId');
        if (StudentId) {
          const { data } = await client.query({
            query: GET_PREMIUM_STUDENT_OF_PARENT,
            variables: { StudentId: parseInt(StudentId), ParentId },
          });

          if (data && data?.getStudentPackageAndParentToken) {
            const dataObj = data?.getStudentPackageAndParentToken;
            if (dataObj?.admissionPackage) {
              setAdmissionPackage(dataObj?.admissionPackage);
            }
            if (dataObj?.parentIntakeFormToken) {
              setParentIntakeToken(dataObj?.parentIntakeFormToken);
            }
          }
        }
      };
      apiCalling();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(getUserByToken, []);

  useEffect(() => {
    if (user?.isParent) {
      getPremiumStudentOfParent(user.id);
    } else {
      if (user?.userSubscription) {
        const aPackage =
          user?.userSubscription?.SubscriptionPlan?.AdmissionPackage;
        setAdmissionPackage(aPackage);
      }
    }
  }, [user]);

  //cj convertion tag
  useEffect(() => {
    if (user?.userSubscription?.SubscriptionPlan?.amount) {
      cjConfig({
        userId: user?.id,
        orderId: user?.userSubscription?.stripeSubscriptionId,
        amount: user?.userSubscription?.SubscriptionPlan?.amount / 100,
        itemId: user?.userSubscription?.SubscriptionPlan?.AdmissionPackage?.id,
        coupon: user?.userCoupon?.stripeCouponId || '',
        discount: user?.userCoupon?.amount_off
          ? user?.userCoupon?.amount_off / 100
          : user?.userCoupon?.percent_off
          ? (user?.userSubscription?.SubscriptionPlan?.amount *
              (user?.userCoupon?.percent_off / 100)) /
            100
          : 0,
        isInstallment:
          user?.userSubscription?.SubscriptionInstallments?.length > 0
            ? true
            : false,
      });
    }
  }, []);

  if (!AdmissionPackage) {
    return null;
  }

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={7}>
          <Box className={classes.welcomeContainer} pb={4}>
            <Box display="flex" alignItems="center" marginBottom={1.2}>
              <Typography variant="h3">Welcome to</Typography>
              {isMBAGrad ? (
                <>
                  <Box
                    component="span"
                    color={AdmissionPackage?.color?.split(',')?.[0]}
                    marginLeft={1}
                  >
                    <Typography variant="h3" color="inherit">
                      {AdmissionPackage?.shortName?.split(' ')?.[0]}
                    </Typography>
                  </Box>
                  <Box
                    component="span"
                    color={AdmissionPackage?.color?.split(',')?.[1]}
                    marginLeft={1}
                  >
                    <Typography variant="h3" color="inherit">
                      {AdmissionPackage?.shortName
                        ?.split(' ')?.[1]
                        ?.toLowerCase()}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box
                  component="span"
                  color={AdmissionPackage?.color}
                  marginLeft={1}
                  className={classes.packageName}
                >
                  <Typography variant="h3" color="inherit">
                    {AdmissionPackage?.shortName}
                  </Typography>
                  {AdmissionPackage?.shortName === PackageShortNames.DIAMOND ? (
                    <Box ml={1}>
                      <img height="26px" src={Images.DiamondIcon2} />
                    </Box>
                  ) : null}
                </Box>
              )}

              {AdmissionPackage?.shortName !== PackageShortNames.DIAMOND ? (
                isMBAGrad ? (
                  <Box color={Colors.GREEN_EIGHT} marginLeft={2}>
                    <img height="42px" width="42px" src={Images.MBA_CHECK} />
                  </Box>
                ) : (
                  <Box color={Colors.GREEN_EIGHT} marginLeft={2} maxWidth={24}>
                    <img height="26px" width="26px" src={Images.CHECKED_1} />
                  </Box>
                )
              ) : null}
            </Box>
            <Box color={Colors.BLACK_THIRTEEN}>
              <Box width={'500px'}>
                <Typography variant="body2" color="inherit">
                  Ready to maximize your admissions odds? Please complete the
                  following tasks to begin your {isMBAGrad ? '' : 'college'}{' '}
                  journey with us:
                </Typography>
              </Box>

              <Box maxWidth={640}>
                {user?.SmartMatchId && (
                  <Box className={classes.instructions}>
                    <Box className={classes.instructionIcon}>
                      <DocumentThemeIcon />
                    </Box>

                    <Box>
                      <Typography variant="h6">
                        Schedule your Orientation Call.
                        <span className={classes.description}>
                          Head to the Home Page and schedule a meeting with your
                          advisor selection(s).
                        </span>
                      </Typography>

                      <Box pt={3}>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={goToHomePage}
                        >
                          Schedule
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                )}

                {WELCOME_INSTRUCTIONS(isMBAGrad, isSmartMatchId).map((item) => (
                  <Box className={classes.instructions} key={item.title}>
                    <Box className={classes.instructionIcon}>{item.image}</Box>

                    <Box>
                      <Typography variant="h6">
                        {item.title}
                        <span className={classes.description}>
                          {item.description}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            {!user?.SmartMatchId && (
              <Box className={classes.buttonContainer}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleLetsGo}
                >
                  Let’s Go!
                </Button>
              </Box>
            )}

            <Box display="flex">
              <Box marginRight={2}>
                <img src={Images.QUESTIONS} alt="" />
              </Box>

              <Box>
                <Typography variant="body1">
                  <strong>Questions?</strong>
                </Typography>
                <Typography variant="body2">
                  Please call <a href="tel:+1 (855) 412-6272">(855) 412-6272</a>
                  , or feel free to email us at{' '}
                  <a href="mailto:success@collegeadvisor.com">
                    success@collegeadvisor.com
                  </a>
                  . We look forward to supporting you throughout your admissions
                  journey.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} lg={5} alignItems="center">
          <div
            className={
              (isMBAGrad ? classes.bgMBAWelcome + ' ' : '') + classes.bgWelcome
            }
          ></div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withRouter(WelcomToPackage);
