import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AppContext } from '../contexts';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}): JSX.Element => {
  const { user } = useContext(AppContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        user || window.localStorage.token ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/login${window.location.search}`} />
        )
      }
    />
  );
};

export default PrivateRoute;
