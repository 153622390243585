import { createStyles, makeStyles, Theme } from '@material-ui/core';

import colors from '../../../common/colors';

export const loaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(1),
      color: colors.BLUE_SEVEN,
      width: '86px !important',
      height: '86px !important',
    },

    smallProgress: {
      margin: theme.spacing(1),
      color: colors.BLUE_SEVEN,
      width: '25px !important',
      height: '25px !important',
    },

    loaderContainer: {
      background: colors.WHITE_ONE,
      opacity: 0.9,
      backgroundFilter: 'blur(5px)',

      '& .MuiCircularProgress-circleIndeterminate': {
        strokeWidth: 3,
      },
    },

    tLoaderContainer: {
      opacity: 0.9,
      backgroundFilter: 'blur(5px)',

      '& .MuiCircularProgress-circleIndeterminate': {
        strokeWidth: 3,
      },
    },

    loaderText: {
      textTransform: 'uppercase',
    },
  })
);
