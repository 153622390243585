import React from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Box,
  IconButton,
  Grid,
} from '@material-ui/core';

import { useStyles } from './styles';
import DeleteIcon from '../../../img/delete-card.svg';
import { DELETE_STUDENT_ACTIVITY } from './graphql/mutations/myProfile';
import client from '../../../apollo';
import { Spinner } from 'react-bootstrap';

export default function DeleteActivityDialog({
  activityId,
  userId,
  refetch,
  type,
  id,
}: {
  activityId?: number | undefined;
  userId?: number | undefined;
  refetch: Function;
  type?: string;
  id?: number | undefined;
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [saveBtn, setSaveBtn] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteStudentActivity = async () => {
    setSaveBtn(true);
    if (id && userId) {
      const { data } = await client.mutate({
        mutation: DELETE_STUDENT_ACTIVITY,
        variables: {
          id,
          userId,
        },
      });
    }
    refetch(activityId);
    setOpen(false);
    setSaveBtn(false);
  };

  const saveExtraCurricularActivity = async () => {
    setSaveBtn(true);
    const { data } = await client.mutate({
      mutation: DELETE_STUDENT_ACTIVITY,
      variables: {
        id: activityId,
        userId,
      },
    });
    if (data.deleteStudentExtraCurricularActivity.success) {
      await refetch();
      setOpen(false);
      setSaveBtn(false);
    } else {
    }
  };

  return (
    <Grid>
      <IconButton className="iconButton" onClick={handleClickOpen}>
        <img src={DeleteIcon} alt="delete" />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.deleteDialog}
      >
        <Typography variant="h5" className={classes.dialogHeading}>
          Delete Activity?
        </Typography>

        <DialogContent>
          <Typography variant="body1" className={classes.actionDescription}>
            This action cannot be undone.
          </Typography>
        </DialogContent>

        <DialogActions className={classes.dialogActionRoot}>
          <Box width="100%">
            <Button
              disabled={saveBtn}
              variant="contained"
              onClick={
                type === 'intakeForm'
                  ? deleteStudentActivity
                  : saveExtraCurricularActivity
              }
              className={`${classes.containedButton} dangerButton`}
              startIcon={saveBtn && <Spinner animation="border" size="sm" />}
            >
              Delete
            </Button>

            <Button
              variant="outlined"
              onClick={handleClose}
              className={classes.outlinedButton}
              disabled={saveBtn}
            >
              Keep Activity
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
