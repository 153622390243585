import {
  Typography,
  Grid,
  Box,
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  Modal,
} from '@material-ui/core';

import { MentorDetailQuery_Mentor_colleges } from './__generated__/MentorDetailQuery';

import fonts from '../../../common/fonts';

import DIALOG_CLOSE_ICON from '../../../img/dialog-close-icon.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    demoDialog: {
      maxWidth: 689,
      width: 689,
      margin: 0,
      position: 'absolute',
      top: '50%',
      left: '50%',
      padding: '40px 40px 44px 45px',
      background: '#fff',
      border: '1px solid #d4d4d4',
      borderRadius: 20,
      boxShadow: '0px 4px 14px rgb(68 68 68 / 14%)',
      transform: 'translate(-50%, -50%) !important',

      '&:focus, &:visited': {
        boxShadow: 'none',
        outline: 'none',
      },

      [theme.breakpoints.down('xs')]: {
        padding: '40px 16px 33px !important',
      },

      [theme.breakpoints.down('md')]: {
        padding: '40px 40px 33px',
        width: '95%',
      },

      '& .headerDialog': {
        paddingBottom: 14,
        borderBottom: '1px solid #D4D4D4',

        [theme.breakpoints.down('xs')]: {
          alignItems: 'flex-start',
        },

        '& button': {
          [theme.breakpoints.down('xs')]: {
            padding: 5,
          },
        },
      },

      '& ul': {
        listStyleType: 'none',
        margin: 0,

        '& li': {
          marginBottom: 11,
        },
      },

      '& .linkText': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#2F80ED',
        paddingBottom: 2,
        borderBottom: '1px solid #2F80ED',
      },

      '& .dailogHeading': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#272929',
        fontFamily: fonts.INTER,
        textTransform: 'capitalize',

        [theme.breakpoints.down('xs')]: {
          paddingRight: 15,
        },
      },

      '& .deskTopView': {
        display: 'flex',
        height: 415,
        overflow: 'auto',
        marginTop: 26,

        [`@media only screen and (max-height: 700px)`]: {
          height: 320,
        },

        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
        },

        '& .collegeList': {
          width: '50%',

          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },
        },
      },
    },
  })
);

export const AssociatedColleges = ({
  collegesArray,
  title,
  showModel,
  setShowModel,
}: {
  title: string;
  collegesArray: (MentorDetailQuery_Mentor_colleges | null)[] | null;
  setShowModel: Function;
  showModel: boolean;
}) => {
  const classes = useStyles();
  const handleClose = async () => {
    setShowModel(false);
  };
  let secondColumnStart;
  if (collegesArray?.length)
    secondColumnStart = Math.floor(collegesArray?.length / 2);

  const body = (
    <>
      <Grid className={classes.demoDialog}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className="headerDialog"
        >
          <Typography variant="h6" className="dailogHeading">
            {title}
          </Typography>

          <IconButton onClick={handleClose}>
            <img src={DIALOG_CLOSE_ICON} alt="close" />
          </IconButton>
        </Box>

        <Grid className="deskTopView">
          <div className="collegeList">
            <ul>
              {collegesArray &&
                collegesArray?.slice(secondColumnStart)?.map(
                  (item) =>
                    item && (
                      <li>
                        <a href={`/colleges/${item.id}`} className="linkText">
                          {item.name}
                        </a>
                      </li>
                    )
                )}
            </ul>
          </div>
          <div className="collegeList">
            <ul>
              {collegesArray &&
                collegesArray?.slice(0, secondColumnStart)?.map(
                  (item) =>
                    item && (
                      <li>
                        <a href={`/colleges/${item.id}`} className="linkText">
                          {item.name}
                        </a>
                      </li>
                    )
                )}
            </ul>
          </div>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Grid>
      <Modal open={showModel} onClose={handleClose}>
        {body}
      </Modal>
    </Grid>
  );
};
