import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import VideoHoursRemaining from '../../../components/VideoHoursRemaining';

import Colors from '../../../common/colors';
import fonts from '../../../common/fonts';
import { AppContext } from '../../../contexts';

import {
  associationTypeOptions,
  ClientStatus,
} from '../../../common/constants';
import { sortAdvisorsWithLevel } from '../../../common/utility';
import Calendar_Image from '../../../img/calendar-image.svg';
import Chat_Image from '../../../img/chat-image.svg';
import NO_IMAGE from '../../../img/empty-badge.svg';
import Expand_More from '../../../img/expand-icon.svg';
import { getAllStudentMentors_allStudentMentors } from '../../../graphql/queries/studentMentors/__generated__/getAllStudentMentors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    applicationsCard: {
      width: '100%',
      boxShadow: 'none',

      '&::before': {
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },

      '& .MuiCardContent-root': {
        padding: 0,
      },

      '& h6': {
        marginBottom: 18,

        [theme.breakpoints.down('sm')]: {
          marginBottom: 5,
        },
      },

      '& .MuiAccordion-rounded': {
        borderRadius: '15px',
      },

      '& .MuiAccordionSummary-root': {
        borderRadius: 15,
      },

      '& .MuiAccordionDetails-root': {
        boxShadow: '0px 1px 14px rgb(68 68 68 / 14%)',
        borderRadius: '0px 0px 15px 15px',
      },

      '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: 58,
        borderRadius: '15px 15px 0px 0px',
      },
    },
    packageTpesOpacity: {
      opacity: 0.5,
    },
    applicationsHeader: {
      width: '100%',
      padding: '9px 20px',
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: 8,
      background: Colors.GREEN_EIGHT,

      '&.Mui-expanded': {
        marginBottom: 20,
      },

      '& .MuiAccordionSummary-content': {
        margin: 0,
      },

      '& h5, h4': {
        color: Colors.WHITE_ONE,
      },
    },

    mentorsListContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 10,
    },

    mentorsList: {
      '& a': {
        display: 'flex',
        alignItems: 'center',

        '&:not(:last-child)': {
          marginBottom: 10,
        },
      },
      '& .collegeTypography': { color: '#7D7F7F' },
    },

    advisorAvatar: {
      height: 48,
      width: 48,
      borderRadius: '50%',
      overflow: 'hidden',
      marginRight: 21,

      '& img': {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
      },
    },

    advisorsWrapper: {
      padding: '26px 30px 39px',

      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    packageTypes: {
      padding: '0px 35px 35px',

      [theme.breakpoints.down('sm')]: {
        padding: '15px 0 0 0',
      },
    },

    packageTypes_h6: {
      fontFamily: fonts.INTER,
      fontWeight: 600,
      fontSize: 18,
      color: '#2F80ED',
      letterSpacing: '-0.014em',
      marginBottom: 4,
      textTransform: 'initial',

      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
        lineHeight: '19px',
      },
    },

    packageTypes_h6_completed: {
      color: '#E94444',
    },

    packageTypes_body1: {
      fontFamily: fonts.INTER,
      fontWeight: 400,
      fontSize: 16,
      color: '#A9A9A9',
      letterSpacing: '-0.011em',
    },

    timeSlots: {
      '& img': {
        marginRight: 20,
      },
      '& .iconDisabled': {
        opacity: 0.1,
        cursor: 'not-allowed',
      },
    },

    avatarContentGrid: {
      width: 175,

      '& p': {
        fontSize: 16,
        fontFamily: fonts.INTER,
      },
    },

    advisorsDisableButton: {
      '& .iconDisabled': {
        opacity: 0.1,
        cursor: 'not-allowed',
      },
    },
  })
);

const filterMentorValues = (item: getAllStudentMentors_allStudentMentors) => {
  let id: number = NaN;
  let uuid: string | null = '';
  let mentorImage = null;
  let mentorName = '';
  const collegeName = `${item?.mentor?.currentCollege?.name || '_ _'}${
    item?.mentor?.intakeYear ? `, ${item?.mentor?.intakeYear}` : ''
  }`;
  if (item && item.mentor) {
    id = item.mentor.id;
    uuid = item.mentor.uuid;
    mentorImage = item.mentor.pictureUrl;
    const firstName = item.mentor.firstName ? item.mentor.firstName : '--';
    const lastName = item.mentor.lastName ? item.mentor.lastName : '--';
    mentorName = `${firstName} ${lastName}`;
  }
  const mentorLevel = item?.isExtraMatch
    ? associationTypeOptions.find((el) => el.value === item?.associateType)
        ?.label || ''
    : item?.mentor?.advisorLevel;

  return {
    id,
    uuid,
    mentorImage,
    collegeName,
    mentorName,
    mentorLevel,
  };
};

interface IAdvisorsInnerCard {
  id: number;
  uuid: string | null;
  mentorImage: string | null;
  mentorName: string;
  mentorLevel?: string | null;
  collegeName: string;
  graduated?: boolean | null;
  isPremiumUser?: boolean | null;
  isVideoDisabled: boolean;
  mentorId?: number;
  openCalendar: Function;
  setAdvisorId: Function;
}

const AdvisorsInnerCard = ({ data }: { data: IAdvisorsInnerCard }) => {
  const classes = useStyles();
  const {
    id,
    uuid,
    mentorImage,
    mentorName,
    mentorLevel,
    collegeName,
    graduated,
    isPremiumUser,
    openCalendar,
    setAdvisorId,
    mentorId,
    isVideoDisabled,
  } = data;

  return (
    <Grid key={id} className={classes.mentorsListContainer}>
      <Grid className={classes.mentorsList}>
        <Link to={`/advisors/${id}/${uuid}`}>
          <Grid className={classes.advisorAvatar}>
            {mentorImage ? (
              <img src={mentorImage} alt={mentorName} />
            ) : (
              <img src={NO_IMAGE} alt="dummy user" />
            )}
          </Grid>

          <Grid className={classes.avatarContentGrid}>
            <Typography variant="h5">{mentorName}</Typography>
            <Typography variant="body1">{mentorLevel}</Typography>

            <Typography variant="caption" className="collegeTypography">
              {collegeName?.length > 38
                ? `${collegeName.substring(0, 38)}...`
                : collegeName}
            </Typography>
          </Grid>
        </Link>
      </Grid>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={
          graduated || !isPremiumUser ? classes.advisorsDisableButton : ''
        }
      >
        {
          <Grid className={classes.timeSlots}>
            <Tooltip
              title={
                isVideoDisabled
                  ? 'No video chat hours remaining'
                  : 'Available TimeSlots'
              }
            >
              <img
                src={Calendar_Image}
                alt="calendar"
                width="23px"
                className={
                  graduated || isVideoDisabled || !isPremiumUser
                    ? 'iconDisabled'
                    : ''
                }
                onClick={() => {
                  if (!graduated && !isVideoDisabled && isPremiumUser) {
                    openCalendar(true);
                    setAdvisorId(mentorId);
                  }
                }}
              />
            </Tooltip>
          </Grid>
        }

        <Link
          to={'/chat'}
          onClick={(e) => (graduated || !isPremiumUser) && e.preventDefault()}
          className={
            graduated || isVideoDisabled || !isPremiumUser ? 'iconDisabled' : ''
          }
        >
          <img src={Chat_Image} alt="chat" width="22px" />
        </Link>
      </Box>
    </Grid>
  );
};

const PackageCardMobile = ({
  openCalendar,
  setAdvisorId,
  allStudentMentors,
}: {
  openCalendar: Function;
  setAdvisorId: Function;
  allStudentMentors: (getAllStudentMentors_allStudentMentors | null)[] | null;
}) => {
  const { user } = useContext(AppContext);
  const classes = useStyles();
  const hasMentors = user && allStudentMentors && allStudentMentors.length > 0;
  const hasAdditionalAdvisors = hasMentors
    ? allStudentMentors?.filter((record) =>
        associationTypeOptions.find(
          (option) => option.value === record?.associateType
        )
      )?.length
    : false;
  const premiumPackageType = user?.premiumPackageType;
  const total_hours = user?.premiumTotalVideoCount || 0;
  const total_hour_used = user?.premiumVideoCountUsed || 0;
  const remainingHours =
    total_hours - total_hour_used < 0 ? 0 : total_hours - total_hour_used;
  const premiumText =
    remainingHours === 0
      ? 'You have no video chat hours remaining. Please contact your advisor to discuss adding additional hours.'
      : `${remainingHours.toFixed(2)} ${
          remainingHours === 1 ? 'hour' : 'hours'
        } remaining`;
  const progress =
    total_hour_used && total_hours
      ? (total_hour_used * 100) / total_hours > 100
        ? 100
        : (total_hour_used * 100) / total_hours
      : 0;
  const isVideoDisabled =
    (user?.premiumVideoCountUsed || 0) >= (user?.premiumTotalVideoCount || 0);

  const isPremiumUser =
    user?.isPremium &&
    user?.clientStatus !== ClientStatus.BAD_DEBT &&
    user?.clientStatus !== ClientStatus.CANCELED;

  return (
    <Accordion className={classes.applicationsCard}>
      <AccordionSummary
        expandIcon={<img src={Expand_More} alt="expand" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.applicationsHeader}
      >
        <Typography variant="h4">Package Details</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Card className={classes.applicationsCard}>
          <CardContent>
            {hasMentors && (
              <>
                <Grid className={classes.advisorsWrapper}>
                  <Grid>
                    <Typography variant="h6">Advisors</Typography>

                    {user &&
                      allStudentMentors &&
                      allStudentMentors.length > 0 &&
                      sortAdvisorsWithLevel(
                        allStudentMentors?.filter(
                          (record) =>
                            !associationTypeOptions.find(
                              (option) => option.value === record?.associateType
                            )
                        )
                      ).map((item) => {
                        const { ...rest } = filterMentorValues(item);
                        const data = {
                          ...rest,
                          isVideoDisabled,
                          graduated: user.graduated,
                          isPremiumUser,
                          setAdvisorId,
                          openCalendar,
                          mentorId: item.mentor?.id,
                        };
                        return <AdvisorsInnerCard data={data} />;
                      })}
                  </Grid>
                </Grid>

                {hasAdditionalAdvisors ? (
                  <Grid className={classes.advisorsWrapper}>
                    <Grid>
                      <Typography variant="h6">SPECIALTY ADVISORS</Typography>

                      {user &&
                        allStudentMentors &&
                        allStudentMentors?.length > 0 &&
                        sortAdvisorsWithLevel(
                          allStudentMentors.filter((record) =>
                            associationTypeOptions.find(
                              (option) => option.value === record?.associateType
                            )
                          )
                        ).map((item) => {
                          const { ...rest } = filterMentorValues(item);
                          const data = {
                            ...rest,
                            isVideoDisabled,
                            graduated: user.graduated,
                            isPremiumUser,
                            setAdvisorId,
                            openCalendar,
                            mentorId: item.mentor?.id,
                          };
                          return <AdvisorsInnerCard data={data} />;
                        })}
                    </Grid>
                  </Grid>
                ) : null}
              </>
            )}
            {premiumPackageType && +(user?.highSchoolYear || 0) >= 2023 && (
              <Grid
                className={user?.graduated ? classes.packageTpesOpacity : ''}
              >
                {!user?.graduated === true && (
                  <Grid className={classes.packageTypes} container>
                    <Box display="flex" alignItems="center">
                      <VideoHoursRemaining progress={progress || 0} />

                      <Box>
                        <Typography
                          variant="h6"
                          className={
                            progress === 100
                              ? classes.packageTypes_h6 +
                                ' ' +
                                classes.packageTypes_h6_completed
                              : classes.packageTypes_h6
                          }
                        >
                          {total_hour_used} of {total_hours} video chat hours
                          used
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.packageTypes_body1}
                        >
                          {premiumText}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            )}
          </CardContent>
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};
export default PackageCardMobile;
